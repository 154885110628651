import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { ACCOUNT_ROUTE } from "../../utils/routes";
import ApiService from "../../services/southern-api/apiService";
import { typeSupportTicketOptions } from "../../utils/constants";
import { currentDate } from "../../utils/helpers";
import useGeneralState from "../../Context/actions/useGeneralState";
import useUsersState from "../../Context/actions/useUsersState";
import techsupportStatusChangeNotification from "../../Components/utils/notifications/supportStatusChangeNotification";
import underwritingsupportStatusChangeNotification from "../../Components/utils/notifications/underwritingsupportStatusChangeNotification";

const useSupportTicket = () => {
  const apiService = new ApiService("southern");

  const [ loading, setLoading ] = useState(false)
  const [selectedTypeOption, setSelectedTypeOption] = useState(null);

  const navigate = useNavigate();

  const auth = getAuth();

  const [subject, setSubject] = useState("");
  const handleChangeSubject = (event) => {
    const { value } = event.target;
    setSubject(value);
  };

  const [message, setMessage] = useState("");
  const handleChangeMessage = (event) => {
    const { value } = event.target;
    setMessage(value);
  };

  const { controlToast } = useGeneralState()
  const { user } = useUsersState()

  const sendMessage = async () => {
    try {
      setLoading(true)
      const { key: optionKey, label: optionLabel } = selectedTypeOption
      let notificationPayload = []
      if (optionKey === 'underwriting-issue') {
        notificationPayload = await underwritingsupportStatusChangeNotification()
      } else {
        notificationPayload = await techsupportStatusChangeNotification()
      }
      const displayName =
        auth?.currentUser?.displayName ?
        auth?.currentUser?.displayName :
        user?.first ?
        `${user?.first} ${user?.last}` : undefined
      const uid = auth?.currentUser?.uid
      const text = {
        submitted: 'A new support ticket was submitted:',
        by: `By: ${displayName}`,
        id: `ID: ${uid}`,
        type: `Type: ${optionLabel}`,
        text: `Text: ${message}`
      }
      if (!displayName) {
        text['by'] = 'User was not authenticated'
        delete text.id
      }
      const payload = {
        date: currentDate(),
        senderID: uid,
        sender: displayName,
        status: "Unread",
        subject,
        message: Object.values(text).join(" "),
        type: optionKey,
        to: notificationPayload.to,
        cc: notificationPayload.cc
      }
      await apiService.sendSupportTicketEmail(payload);
      setLoading(false)
      controlToast(true, 'The notification was succesfully sent to the corresponding person', 'success')
      navigate(ACCOUNT_ROUTE);
    } catch (error) {
      controlToast(false, 'There was an error in the service', 'error')
      setLoading(false)
      throw error
    }
  };

  const handleChangeType = (event) => {
    const selectedId = event.target.value;
    const selected = typeSupportTicketOptions.find(
      (option) => option.id === Number(selectedId)
    );
    setSelectedTypeOption(selected);
  };

  const enabled = useMemo(() => {
    const isEnabled =
      subject &&
      selectedTypeOption &&
      message &&
      !loading
    return Boolean(isEnabled)
  }, [
    subject,
    selectedTypeOption,
    message,
    loading
  ])

  return {
    handleChangeSubject,
    handleChangeMessage,
    sendMessage,
    subject,
    message,
    selectedTypeOption,
    handleChangeType,
    disabled: !enabled
  };
};

export default useSupportTicket;