import readXlsxFile from "read-excel-file";
import { toCamelCase } from "../../utils/helpers";
import { addDoc, collection, deleteDoc, getDocs } from "firebase/firestore";
import { db } from "../../firebase";

const handleUploadExcelToFirestore = async (
  e,
  sheet,
  filterValues,
  changeThisValues,
  collectionPath,
  subCollectionName,
  entityName
) => {
  e.preventDefault();
  const file = e.target[0]?.files[0];
  if (!file) return;
  try {
    const rows = await readXlsxFile(file, { sheet });
    const columnNames = rows[0];
    const values = rows.slice(1);
    const newArray = values.map((value) => {
      const data = {};
      columnNames.forEach((columnName, index) => {
        if (columnName !== null) {
          let key = toCamelCase(columnName);
          const keysToChange = Object.keys(changeThisValues);
          const valuesToChange = Object.values(changeThisValues);
          keysToChange.forEach((keyToChange, keyToChangeIndex) => {
            if (key === keyToChange) {
              key = valuesToChange[keyToChangeIndex];
            }
          });
          const filterValue = filterValues.find((item) =>
            Object.keys(item).includes(key)
          );
          if (filterValue) {
            const filterKey = Object.keys(filterValue)[0];
            data[key] = filterValue[filterKey];
          } else {
            data[key] = value[index];
          }
        }
      });
      return data;
    });
    const collectionRef = collection(
      db,
      collectionPath,
      subCollectionName,
      entityName
    );
    await deleteCollection(collectionRef);
    const addDocPromises = [];

    newArray.forEach((data) => {
      addDocPromises.push(addDoc(collectionRef, data));
    });
    await Promise.all(addDocPromises);
    console.log("newArray", newArray);
    return newArray;
  } catch (error) {
    console.error("Error al leer el archivo Excel:", error);
    throw error;
  }
};

async function deleteCollection(collectionRef) {
  try {
    const querySnapshot = await getDocs(collectionRef);
    querySnapshot.forEach((doc) => {
      deleteDoc(doc.ref);
    });
  } catch (error) {
    throw error;
  }
}

export default handleUploadExcelToFirestore;
