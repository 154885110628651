import { useEffect, useState } from "react";
import { UserAuth } from "../../Context/AuthContent";
import { collection, getDocs, onSnapshot, query } from "firebase/firestore";
import { db } from "../../firebase";
import { searchFilterData } from "../../utils/helpers";

const useLossRunsList = () => {
  const itemsPerPage = 10;
  const { user } = UserAuth();
  const userID = user.uid;
  const [lossRunsList, setLossRunsList] = useState([]);
  const [doneGettingLossRuns, setDoneGettingLossRuns] =
    useState(false);
  const [showCompanySelection, setShowCompanySelection] = useState(false);
  const [selected, setSelected] = useState("");
  const [selectedCompanyInfo, setSelectedCompanyInfo] = useState();
  const [showHistory, setShowHistory] = useState(false);
  const [currentSort, setCurrentSort] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [filteredResults, setFilteredResults] = useState([]);
  const [dataUpdated, setDataUpdated] = useState(0);

  const offset = currentPage * itemsPerPage;
  const currentItems = filteredResults.slice(offset, offset + itemsPerPage);
  const pageCount = Math.ceil(filteredResults.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    console.log(pageNumber)
    setCurrentPage(pageNumber);
  };
  
  useEffect(() => {
    const getLossRunsList = () => {
      setDoneGettingLossRuns(false);
      const q = query(collection(db, "loss-run-requests"));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const lossRuns = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          date: new Date(doc.data().date),
        }));
        setLossRunsList(lossRuns);
        setFilteredResults(lossRuns);
        setDoneGettingLossRuns(true);
      });
      return unsubscribe;
    };
    const unsubscribe = getLossRunsList();
    return () => unsubscribe();
  }, []);

  const [searchValue, setSearchValue] = useState("");

  const searchAllProperties = (e) => {
    const { value } = e.target
    setSearchValue(value);
    if (value === "") {
      setFilteredResults(lossRunsList);
      return;
    } else {
      const values = searchFilterData(lossRunsList, value)
      setFilteredResults(values);
      setCurrentPage(0)
      setDataUpdated(dataUpdated + 1);
    }
  };

  const handleDateSort = (e, name) => {
    e.preventDefault();
    if (currentSort !== name) {
      setCurrentSort(name);
      filteredResults.sort((a, b) => new Date(a.date) - new Date(b.date));
    }
    if (currentSort === name) {
      setCurrentSort(`reverse-${name}`);
      filteredResults.sort((a, b) => new Date(b.date) - new Date(a.date));
    }
    setDataUpdated(dataUpdated + 1);
  };

  const handleAlphabetSort = (e, name) => {
    e.preventDefault();
    if (currentSort !== name) {
      setCurrentSort(name);
      filteredResults.sort((a, b) =>
        a[name] > b[name] ? 1 : b[name] > a[name] ? -1 : 0
      );
    }
    if (currentSort === name) {
      setCurrentSort(`reverse-${name}`);
      filteredResults.sort((a, b) =>
        a[name] < b[name] ? 1 : b[name] < a[name] ? -1 : 0
      );
    }
    setDataUpdated(dataUpdated + 1);
  };

  return {
    showCompanySelection,
    searchValue,
    searchAllProperties,
    currentSort,
    handleDateSort,
    handleAlphabetSort,
    currentItems,
    pageCount,
    currentPage,
    selected,
    setSelected,
    setSelectedCompanyInfo,
    setShowHistory,
    handlePageChange,
    doneGettingLossRuns
  }
};

export default useLossRunsList;
