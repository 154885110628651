import React, { useState } from "react";
import ExpandCollapseArrow from "../../generals/DesignElements/ExpandCollapseArrow";

export default function ReturnToAgentMessage({
  className,
  reasons,
  returnedDate,
  userRole,
  user,
}) {
  const [show, setShow] = useState(true);
  const color = "red";
  return (
    <main
      className={`card mt-3 bg-${color}-100 text-${color}-700 ${
        className || ""
      }`}
    >
      <div className="card-header" onClick={(e) => setShow(!show)}>
        <h1 className={`text-lg font-bold text-${color}-700`}>
          <button>Returned by an {userRole}</button>
          <ExpandCollapseArrow show={show} setShow={setShow} />
        </h1>
      </div>
      {show && (
        <div className="card-body">
          <p className="mb-2">
            This application was returned to the agent by
            {userRole === "Underwriter" && " an underwriter "}
            {userRole === "Admin" && " an admin "}
            {userRole === "Agent" && " an agent " + "(" + user + ")" + " "}
            on{" "}
            {returnedDate
              ?.toDate()
              .toLocaleDateString("en-US", {
                month: "short",
                day: "2-digit",
                year: "numeric",
              })}{" "}
            for the following reasons:
          </p>
          <ul className="list-disc ml-6">
            {reasons.map((reason, i) => {
              return (
                <li key={i} className="mb-2">
                  {reason}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </main>
  );
}
