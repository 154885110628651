import { collection, doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";

const getFleetCredit = async (vehicleCount) => {
  const fleetCreditsDocumentRef = doc(db, "fleet-credits", "credits");
  const fleetCreditsDocument = await getDoc(fleetCreditsDocumentRef);
  const fleetCreditsData = fleetCreditsDocument.data();
  
  if (!fleetCreditsData || !fleetCreditsData.credits) {
    alert("Fleet Credits not found in database");
    throw new Error("Fleet Credits not found in database");
  }

  const credits = fleetCreditsData.credits;
  for (const credit of credits) {
    if (vehicleCount >= credit.MinVehCount && vehicleCount <= credit.MaxVehCount) {
      return credit.FleetCredit;
    }
  }
  alert("No suitable fleet credit found for the given vehicle count");
  throw new Error("No suitable fleet credit found for the given vehicle count");
};

export default getFleetCredit;
