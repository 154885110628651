import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
    },
  },
  es: {
    translation: { 
      "Please indicate what percentage of your loads consist of the commodities listed below.": "Por favor, indique qué porcentaje de sus cargas consiste en las mercancías enumeradas a continuación.",
      "Here you must add any commodities hauled but not listed above.": "Aquí debe agregar cualquier mercancía transportada que no esté enumerada anteriormente.",
        "Custom Commodities": "Mercancías Personalizadas",
        "to": "a",
        "will not be removed from the policy": "no se eliminarán de la póliza",
        "Add Note": "Agregar Nota",
        "Submit Note": "Enviar Nota",
        "You are about to delete a Note! If you are sure, type DELETE below:": "Estás a punto de eliminar una Nota. Si estás seguro, escribe ELIMINAR a continuación:",
        "Note by": "Nota por",
        "Delete Note": "Eliminar Nota",
        "on": "en",
        "Note": "Nota",
        "Quotes": "Cotizaciones",
        "You are about to delete a Quote! If you are sure, type DELETE below:": "Estás a punto de eliminar una cotización. Si estás seguro, escribe DELETE a continuación:",
        "Create New Quote": "Crear Nueva Cotización",
        "Delete List of Quotes": "Eliminar Lista",
        "Description": "Descripción",
        "Change": "Cambiar",
        "Deductible": "Deducible",
        "Non-Owned": "No Propiedad",
        "Total": "Total",
        "Apply Adjustments": "Aplicar Ajustes",
        "Item": "Ítem",
        "Amount": "Cantidad",
        "Adjustments": "Ajustes",
        "Please rerate the policy to view premium information.": "Por favor, vuelva a calcular la póliza para ver la información de la prima.",
        "Clear Adjustments": "Borrar Ajustes",
        "Do you have subsidiaries": "¿Tiene subsidiarias?",
        "Carrier Type": "Tipo de Transportista",
        "Maintenance Procedures": "Procedimientos de Mantenimiento",
        "Safety Procedures": "Procedimientos de Seguridad",
        "Truck Class": "Clase de Camión",
        "All of Texas & Mexico": "Todo Texas y México",
        "All Ports of Entry": "Todos los Puertos de Entrada",
        "All Texas Ports of Entry": "Todos los Puertos de Entrada de Texas",
        "You are about to delete an entire list! If you are sure, type DELETE below:": "¡Estás a punto de eliminar toda una lista! Si estás seguro, escribe DELETE a continuación:",
        "Policy Trailers": "Remolques de Póliza",
        "Add Trailer": "Agregar un Remolque",
        "Import": "Importar",
        "Delete List of Trailers": "Eliminar Lista",
        "Changing a trailer's status will submit an endorsement and the change will become effective on the policy once reviewed by an underwriter.": "Cambiar el estado de un remolque enviará un endoso y el cambio se hará efectivo en la póliza una vez revisado por un suscriptor.",
        "Add Power Unit": "Agregar Unidad",
        "Fill out the following form to Add Unit. Click submit once the form is complete.": "Complete el siguiente formulario para agregar una Unidad. Haga clic en enviar una vez que el formulario esté completo.",
        "Truck VIN": "Número de Identificación del Vehículo (VIN) del Camión",
        "Truck Year": "Año del Camión",
        "Truck Make": "Marca del Camión",
        "Truck Model": "Modelo del Camión",
        "Tractor": "Tractor",
        "Bob Tail": "Cola de Pato",
        "Cargo Van": "Furgoneta de Carga",
        "Step Van": "Furgoneta Paso a Paso",
        "Van": "Furgoneta",
        "Other": "Otro",
        "Pickup": "Camioneta",
        "Truck Weight": "Peso del Camión",
        "Operational Radius": "Radio Operativo",
        "State of Entry": "Estado de Entrada",
        "Port of Entry": "Puerto de Entrada",
        "Actual Cash Value": "Valor en Efectivo Real",
        "Upload a Truck's List": "Cargar Lista de Camiones",
        "Enter your truck's list into the": "Ingrese la lista de sus camiones en el",
        "Truck's List Template": "Formato de Lista de Camiones",
        "then upload it to add trucks in bulk.": "y luego cárguelo para agregar camiones en cantidad.",
        "Gross Written Premium": "Prima Bruta Emitida",
        "Total Premium": "Prima Total",
        "Loss Ratio": "Índice de Pérdidas",
        "This policy is unrated.": "Esta póliza no tiene calificación.",
        "No alerts at this time.": "No hay alertas en este momento.",
        "Add Coverage": "Agregar Cobertura",
        "Endorsements": "Endosos",
        "Renew Policy": "Renovar Póliza",
        "Power Units List Uploaded": "Lista de Unidades Cargada",
        "Driver Added": "Conductor Agregado",
        "Add Power Unit Endorsement": "Agregar Endoso de Unidad",
        "Drivers List Uploaded": "Lista de Conductores Cargada",
        "Trailers List Uploaded": "Lista de Remolques Cargada",
        "Trailer Added": "Remolque Agregado",
        "Power Unit Added": "Unidad Agregada",
        "Premium PPU": "Prima por Unidad de Producción (PPU)",
        "Calculate Loss Ratio": "Calcular Índice de Pérdidas",
        "Notes": "Notas",
        "Add a Commodity": "Agregar una Mercancía",
        "This insured has suffered no reported losses while insured with Southern Star MGA.": "Este asegurado no ha sufrido pérdidas reportadas mientras estaba asegurado con Southern Star MGA.",
        "Annual Premium": "Prima Anual",
        "Carrier": "Aseguradora",
        "Number of Units": "Número de Unidades",
        "Premium": "Prima",
        "Losses": "Pérdidas",
        "Open BI Claims": "Reclamaciones de Lesiones Corporales Abiertas",
        "Open At Fault BI Claims": "Reclamaciones de Lesiones Corporales Abiertas con Culpa",
        "Total Open Claims": "Total de Reclamaciones Abiertas",
        "Reset": "Restablecer",  
        "Describe Commodity": "Describir Mercancía",
        "Commodity": "Mercancía",
        "% of Hauls": "Porcentaje de Envíos",
        "Remove Commodity": "Eliminar Mercancía",
        "Marijuana % of Hauls": "Porcentaje de Marihuana de los Envíos",
        "Marijuana": "Marihuana",
        "Copper % of Hauls": "Porcentaje de Cobre de los Envíos",
        "Copper": "Cobre",
        "Commodities": "Mercancías",
        "Policy Has Excluded Commodities": "La Política ha Excluido Mercancías",
        "Commodities Do Not Equal 100%": "Las Mercancías no Suman el 100%",
        "Contact Information": "Información de Contacto",
        "Primary Contact Name": "Nombre del Contacto Principal",
        "Other Contacts": "Otros Contactos",
        "Contacts": "Contactos",
        "File Upload": "Carga de Archivo",
        "Common": "Común",
        "Contract": "Contrato",
        "Private": "Privado",
        "Other": "Otro",
        "Body Work Only": "Solo Trabajo de Carrocería",
        "Minor Repairs": "Reparaciones Menores",
        "Major Repairs": "Reparaciones Mayores",
        "Minor Repairs & Body Work": "Reparaciones Menores y Trabajo de Carrocería",
        "Major Repairs & Body Work": "Reparaciones Mayores y Trabajo de Carrocería",
        "Loading... Please Wait.": "Cargando... Espere por favor.",
        "Power Units": "Unidades",
        "Port of Entry": "Puerto de Entrada",
        "State of Entry": "Estado de Entrada",
        "Weight": "Peso",
        "Your sheet contains duplicate VINs. Please fix these errors and resubmit. No trailers have been added.": "Tu hoja contiene números de VIN duplicados. Por favor, corrige estos errores y vuelve a enviar. No se han añadido remolques.",    
        "You are about to delete a Truck! If you are sure, type DELETE below:": "¡Estás a punto de eliminar un Camión! Si estás seguro, escribe DELETE abajo:",
        "Power Unit Information:": "Información de la Unidad:",
        "Date Created": "Fecha de Creación",
        "Actual Cash Value": "Valor Actual en Efectivo",
        "Policy Trucks": "Póliza de Camiones",
        "Add Unit": "Agregar Una Unidad",
        "Delete All Units": "Eliminar Lista",
        "Remove Power Unit Endorsement.": "Endoso para Eliminar Unidad.",
        "Deleting a power unit from the power units list will submit a Remove Power Unit Endorsement.": "Eliminar una Unidad de la lista de unidades enviará un Endoso para Eliminar Unidad.",
        "The power unit": "La Unidad",
        "until the endorsement is approved by an underwriter.": "hasta que el endoso sea aprobado por un suscriptor.",
        "You are about to delete an entire list! If you are sure, type DELETE below:": "¡Estás a punto de eliminar toda la lista! Si estás seguro, escribe DELETE abajo:",
        "Upload A License": "Subir Licencia",
        "years": "años",
        "License": "Licencia",
        "Fill out the following form to Add Driver. Click submit once the form is complete.": "Completa el siguiente formulario para añadir un conductor. Haz clic en enviar una vez que el formulario esté completo.",
        "Driver First Name:": "Nombre del Conductor:",
        "Years of Experience:": "Años de Experiencia:",
        "Driver Country:": "País del Conductor:",
        "United States": "Estados Unidos",
        "Mexico": "México",
        "Canada": "Canadá",
        "Continue": "Continuar",
        "Driver's License State:": "Estado de la Licencia de Conducir:",
        "Driver's License Number:": "Número de Licencia de Conducir:",
        "Submit": "Enviar",
        "Front of Driver's License:": "Frente de la Licencia de Conducir:",
        "Choose a file and then click upload.": "Elige un archivo y haz clic en subir.",
        "Upload Progress": "Progreso de Subida",
        "You may": "Puedes",
        "view": "ver",
        " or ": " o ",
        "replace": "reemplazar",
        "the file.": "el archivo.",
        "Upload Driver Abstract": "Subir Resumen del Conductor",
        "YOE": "ADE",
        "Your sheet contains duplicate license numbers. Please fix these errors and resubmit. No trailers have been added.": "Tu hoja contiene números de licencia duplicados. Por favor, corrige estos errores y vuelve a enviar. No se han añadido remolques.",
        "Upload a Driver's List": "Subir Lista de Conductores",
        "Enter your driver's list into the": "Ingresa tu lista de conductores en la",
        "Driver's List Template": "Plantilla de Lista de Conductores",
        ", then upload it to add drivers in bulk.": ", y después súbela para añadir conductores en masa.",
        "Upload File": "Subir Archivo",
        "You are about to remove a driver! If you are sure, type DELETE below:": "¡Estás a punto de eliminar un conductor! Si estás seguro, escribe DELETE abajo:",
        "Missing front of driver's license.": "Falta el frente de su licencia de conducir.",
        "State": "Estado",
        "Front of License": "Frente de la Licencia",
        "Upload Front Of License": "Subir Frente de la Licencia",
        "Driver History": "Historial del Conductor",
        "View All": "Ver Todo",
        "By": "Por",
        "SCT Results": "Resultados SCT",
        "Lookup Status": "Estado de Búsqueda",
        "Name In SCT": "Nombre en SCT",
        "Determination": "Determinación",
        "Observations": "Observaciones",
        "License Class": "Clase de Licencia",
        "Fit To Drive": "Apto para Conducir",
        "Medical Exam Expires (dd/mm/yyyy)": "Expiración del Examen Médico (dd/mm/yyyy)",
        "License Expires (dd/mm/yyyy)": "Expiración de la Licencia (dd/mm/yyyy)",
        "Driver Score": "Puntuación del Conductor",
        "You are about to delete an entire list! If you are sure, type DELETE below: ": "¡Estás a punto de borrar toda la lista! Si estás seguro, escribe DELETE abajo:",
        "Invalid Driver Data": "Datos del Conductor Inválidos",
        "SCT Audit Progress": "Progreso de Auditoría SCT",
        "out of": "de",
        "Audit in progress": "Auditoría en Progreso",
        "Start Audit": "Iniciar Auditoría",
        "Clicking on a driver will allow you to view and edit their details.": "Al hacer clic en un conductor podrás ver y editar sus detalles.",
        "Add Driver": "Agregar un Conductor",
        "History": "Historial",
        "Drivers": "Conductores",
        "Units": "Unidades",
        "Trailers": "Remolques",
        "New Business": "Nuevo Negocio",
        "Next AL": "Próxima Póliza AL",
        "Next GL": "Próxima Póliza GL",
        "AL Policy Number": "Número de Póliza AL",
        "GL Policy Number": "Número de Póliza GL",
        "Effective Date": "Fecha de Efectividad",
        "Coverage Single Limit": "Límite Único de Cobertura",
        "Product": "Producto",
        "State Of Entry": "Estado de Entrada",
        "Bound": "Atado",
        "Cancelled": "Cancelado",
        "Bodily Injury Deductible": "Deducible por Lesiones Corporales",
        "Non-Owned Auto": "Auto No Propio",
        "New/Renewal": "Nuevo/Renovación",
        "Program": "Programa",
        "Cargo Type": "Tipo de Carga",
        "Payment Plan": "Plan de Pagos",
        "AL Coverage": "Cobertura AL",
        "GL Coverage": "Cobertura GL",
        "APD Coverage": "Cobertura APD",
        "MTC Coverage": "Cobertura MTC",
        "Documents": "Documentos",
        "Premiums": "Primas",
        "Agent Notes": "Notas del Agente",
        "Underwriter Notes": "Notas del Suscriptor",
        "Add Note": "Agregar Nota",
        "Delete Note": "Eliminar Nota",
        "Add Comment": "Agregar Comentario",
        "Delete Comment": "Eliminar Comentario",
        "Gross Written Premium": "Prima Bruta Escrita",
        "Non-Owned Adjustment": "Ajuste por No Propio",
        "General Liability": "Responsabilidad Civil General",
        "Processing Fee": "Tarifa de Procesamiento",
        "Other Fees": "Otros Cargos",
        "Stamp Fee": "Impuesto de Timbre",
        "Surplus Lines Tax": "Impuesto de Líneas Excedentes",
        "Total Premium": "Prima Total",
        "Losses": "Pérdidas",
        "Loss History": "Historial de Pérdidas",
        "Loss Ratio": "Índice de Pérdidas",
        "The insured has suffered no reported losses.":
          "El asegurado no ha sufrido pérdidas reportadas.",
        "The insured did not operate/have coverage during this term.":
          "El asegurado no operó durante este período.",
        "Upload Loss Runs": "Subir Informe de Pérdidas",
        "Choose File": "Seleccionar Archivo",
        "Upload": "Subir",
        "Insurance Carrier": "Compañía Aseguradora",
        "Number of Units": "Número de Unidades",
        "Premium Per Power Unit": "Prima por Unidad",
        "Open BI Claims": "Reclamaciones de Lesiones Corporales Abiertas",
        "Open At Fault BI Claims":
          "Reclamaciones de Lesiones Corporales con Culpa Abiertas",
        "Total Open Claims": "Total de Reclamaciones Abiertas",
        "Commodities": "Mercancías",
        "Copper": "Cobre",
        "Percentage of Hauls": "Porcentaje de Cargas",
        "Describe Commodity 1": "Describir Mercancía 1",
        "The total of commodities must equal 100% and it currently equals":
          "El total de las mercancías debe ser igual al 100% y actualmente es del",
        "Questionnaire": "Cuestionario",
        "E-Mail": "Correo Electrónico",
        "Phone": "Teléfono",
        "Other Contacts": "Otros Contactos",
        "Contacts": "Contactos",
        "Company Information": "Información de la Empresa",
        "Yes": "Sí",
        "No": "No",
        "Location": "Ubicación",
        "Business Structure": "Estructura Empresarial",
        "Federal Tax ID": "Número de Identificación Fiscal Federal",
        "Year Established": "Año de Establecimiento",
        "Attach Current Financials": "Adjuntar Información Financiera Actual",
        "Have you ever filed for bankruptcy":
          "¿Ha solicitado alguna vez la quiebra?",
        "Have you ever operated under a different name":
          "¿Ha operado alguna vez bajo un nombre diferente?",
        "If yes, please describe": "Si es así, por favor describa",
        "Description of Operations": "Descripción de Operaciones",
        "Common": "Común",
        "Contract": "Contrato",
        "Private": "Privado",
        "Other": "Otro",
        "MC Number": "Número MC",
        "Latest DOT Rating": "Última Calificación DOT",
        "Year of Latest DOT Rating": "Año de la Última Calificación DOT",
        "State Filings": "Presentaciones Estatales",
        "Have you been cancelled & renewed by another carrier within the past 3 years":
          "¿Ha sido cancelado y renovado por otra compañía en los últimos 3 años?",
        "Is carrier involved in any non-trucking business":
          "¿La compañía está involucrada en algún negocio que no sea de camiones?",
        "Over 1000 miles": "Más de 1000 millas",
        "Do you travel through metro areas":
          "¿Viaja a través de áreas metropolitanas?",
        "If other, please explain": "Si es otro, por favor explique",
        "Which states do you predominantly travel through":
          "¿Por qué estados viaja principalmente?",
        "Radius of Operations": "Radio de Operaciones",
        "Driver Hiring Procedures":
          "Procedimientos de Contratación de Conductores",
        "Do you hire drivers with less than 2 years of experience":
          "¿Contrata conductores con menos de 2 años de experiencia?",
        "Minimum Experience": "Experiencia Mínima",
        "Are drivers drug tested prior to hire":
          "¿Se realiza prueba de drogas a los conductores antes de contratarlos?",
        "Are drivers drug tested randomly after hiring":
          "¿Se realiza prueba de drogas de forma aleatoria después de la contratación?",
        "Are driver MVRs reviewed prior to hiring":
          "¿Se revisan los registros de manejo de los conductores antes de contratarlos?",
        "Are medical certificates reviewed prior to hiring":
          "¿Se revisan los certificados médicos antes de contratarlos?",
        "Is a road test given prior to hiring":
          "¿Se realiza una prueba de manejo antes de contratarlos?",
        "Is a written test given prior to hiring":
          "¿Se realiza una prueba escrita antes de contratarlos?",
        "Are drivers given a formal onboarding/orientation":
          "¿Se les brinda una incorporación/orientación formal a los conductores?",
        "Are drivers offered extra incentives outside of their normal wages":
          "¿Se les ofrecen incentivos adicionales fuera de su salario normal a los conductores?",
        "Is there a written safety program":
          "¿Existe un programa de seguridad por escrito?",
        "Are safety meetings regularly held":
          "¿Se realizan regularmente reuniones de seguridad?",
        "If yes, please describe the frequency":
          "Si es así, por favor describa la frecuencia",
        "Do you have a full time safety director":
          "¿Tiene un director de seguridad a tiempo completo?",
        "Does each vehicle maintain a logbook":
          "¿Cada vehículo mantiene un libro de registro?",
        "Do you utilize an in-house repair shop":
          "¿Utiliza un taller de reparación interno?",
        "What types of repair does it handle": "¿Qué tipos de reparación maneja?",
        "Do you schedule regular inspections of equipment":
          "¿Programa inspecciones regulares del equipo?",
        "Add Trailer": "Agregar un Remolque",
        "Add Unit": "Agregar una Unidad",
        "Add Driver": "Agregar un Conductor",
        "Delete": "Eliminar",
        "Delete List of Trailers": "Eliminar Lista",
        "Delete All Drivers": "Eliminar Lista",
        "Delete List of Units": "Eliminar Lista",
        "Import": "Importar",
        "Import": "Importar",
        "Import": "Importar",
        "Only Show Last 4 of VIN": "Mostrar solo los últimos 4 dígitos del VIN",
        "Status": "Estatus",
        "Year": "Año",
        "Make": "Marca",
        "Model": "Modelo",
        "ACV": "VAE",
        "Radius": "Radio",
        "Class": "Clase",
        "Rating": "Calificación",
        "Driver Name": "Nombre del Conductor",
        "Country": "País",
        "License Number": "Número de Licencia",
        "Years of Experience": "Años de Experiencia",
        "Underwriter Tools": "Herramientas del Suscriptor",
        "Policy Drivers": "Conductores de Póliza",
        "Policy Trailers": "Remolques de Póliza",
        "Policy Units": "Unidades de Póliza",
        "Request Quote": "Solicitar Cotización",
        "Request Bind": "Solicitar Vinculación",
        "Bind Policy": "Vincular Póliza",
        "Return to Agent": "Volver al Agente",
        "Returned": "Devuelto",
        "Save": "Guardar",
        "Saved": "Guardado",
        "Unsubmitted": "No Enviado",
        "Submitted": "Enviado",
        "Policy": "Póliza",
        "Application": "Solicitud",
        "Quote Request": "Solicitud de Cotización",
        "Renewal": "Renovación",
        "Pay In Full": "Pagar en Efectivo",
        "External Finance": "Financiamiento Externo",
        "Rate Policy": "Cotizar Póliza",
        "Edit Insureds Details": "Editar Detalles del Asegurado",
        "Please Add Email": "Agregue correo electrónico",
        "Class I": "Clase I",
        "Class II": "Clase II",
        "Class III": "Clase III",
        "Class IV": "Clase IV",
        "Active" : "Activo",
        "Actions" : "Opciones",
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if youre using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
