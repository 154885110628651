import { deleteDoc, doc } from "firebase/firestore";
import { db } from "../../../../firebase";
import { confirmDeletePromptGlobal } from "../../../../layout/ConfirmDeleteProvider";

const handleDelete = async (policyID, noteID, collectionPath) => {
    const confirmation = await confirmDeletePromptGlobal("To confirm deletion, confirm the action");
    if (confirmation === "DELETE") {
      await deleteDoc(
        doc(db, collectionPath, noteID)
      );
    } else {
      alert("Deletion cancelled.");
    }
  };

  export default handleDelete;