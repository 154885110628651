const miniValidation = (truckList, policyData) => {
    if (truckList.length === 0 || !truckList) {
      alert("Please add trucks to rate the policy.");
      return false;
    }
    if (!policyData.product || policyData.product === "") {
      alert("Please select a product to rate the policy.");
      return false;
    }
    if (!policyData.stateOfEntry || policyData.stateOfEntry === "") {
      alert("Please select a state of entry to rate the policy.");
      return false;
    }
    if (
      !policyData.coverageSingleLimit ||
      policyData.coverageSingleLimit === ""
    ) {
      alert("Please select a coverage single limit to rate the policy.");
      return false;
    }
    if (!policyData.program || policyData.program === "") {
      alert("Please select a program to rate the policy.");
      return false;
    }
    return true;
  };

  export default miniValidation;