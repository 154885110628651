import { t } from "i18next";
import React, { useState } from "react";
import updatePolicyField from "../../../utils/updatePolicyField";
import { auth } from "../../../../firebase";
import YearCard from "./APDorMTCYearCard";
import { Col, Container, Row } from "react-bootstrap";
import { useMediaQuery } from "@mui/material";
import ExpandCollapseArrow from "../../../generals/DesignElements/ExpandCollapseArrow";

export default function APDLossHistory(props) {
  const formData = props.formData;
  const setFormData = props.setFormData;
  const setDisplayAPDLossHistory = props.setDisplayAPDLossHistory;
  const displayAPDLossHistory = props.displayAPDLossHistory;

  const [showYear1, setShowYear1] = useState(false);
  const [showYear2, setShowYear2] = useState(false);
  const [showYear3, setShowYear3] = useState(false);
  const [showYear4, setShowYear4] = useState(false); 
  const [showYear5, setShowYear5] = useState(false);

  const [imgUrl, setImgUrl] = useState(null);
  const [year1Progress, setYear1Progress] = useState(0);
  const [year2Progress, setYear2Progress] = useState(0);
  const [year3Progress, setYear3Progress] = useState(0);
  const [year4Progress, setYear4Progress] = useState(0); 
  const [year5Progress, setYear5Progress] = useState(0);
  const [year1LossData, setYear1LossData] = useState({
    lossRatioCalculated: false,
  });
  const [year2LossData, setYear2LossData] = useState({
    lossRatioCalculated: false,
  });
  const [year3LossData, setYear3LossData] = useState({
    lossRatioCalculated: false,
  });
  const [year4LossData, setYear4LossData] = useState({ 
    lossRatioCalculated: false,
  });
  const [year5LossData, setYear5LossData] = useState({ 
    lossRatioCalculated: false,
  });

  const [show5years, setShow5Years] = useState(true);

  const resetLosses = async (e, property) => {
    e.preventDefault();
    const values = { ...formData.lossHistory };
    delete values[property];

    // Ensure that the state update occurs outside the rendering phase
    setTimeout(() => {
      setFormData({ ...formData, lossHistory: values });
      updatePolicyField(props.policyID, "lossHistory", values);
    }, 0);
  };

  const handleSubmit = async (e, years, lossData, property) => {
    e.preventDefault();
    const data = {
      ...lossData,
      author: auth.currentUser.displayName,
      authorID: auth.currentUser.uid,
      createdOn: new Date(),
      years,
      annualPremium: lossData.numberOfUnits * lossData.premiumPPU,
      lossRatio:
        (lossData.losses / (lossData.numberOfUnits * lossData.premiumPPU)) *
        100,
      lossRatioCalculated: true,
    };
    setFormData({
      ...formData,
      lossHistory: {
        ...formData.lossHistory,
        [property]: { ...lossData, ...data },
      },
    });
    updatePolicyField(props.policyID, "lossHistory", {
      ...formData.lossHistory,
      [property]: { ...lossData, ...data },
    });
  };

  const isMobileScreen = useMediaQuery("(max-width: 600px)");

  const [checked, setChecked] = useState(false);

  const handleChange = () => {
    setChecked(!checked);
  };

  return (
    <div className="card mb-2">
      <div className="card-header">
        <button onClick={(e) => setDisplayAPDLossHistory(!displayAPDLossHistory)}>
          APD {t("Loss History")}
        </button>
        <ExpandCollapseArrow show={displayAPDLossHistory} setShow={setDisplayAPDLossHistory} />
      </div>
      {displayAPDLossHistory && (
        <div className="card-body">
          <Container className="p-0">
            <Row>
              <Col sm={12} md={4} className="p-0">
                <YearCard
                  coverageType={"APD"}
                  currentYear={props.currentYear}
                  showYearX={showYear1}
                  setShowYearX={setShowYear1}
                  formData={formData}
                  setFormData={setFormData}
                  setProgressState={setYear1Progress}
                  setImgUrl={setImgUrl}
                  policyID={props.policyID}
                  yearXLossData={year1LossData}
                  setYearXLossData={setYear1LossData}
                  resetLosses={resetLosses}
                  handleSubmit={handleSubmit}
                  year={1}
                  numberOfYearsPrior={0}
                  yearXLossRunsFile={formData.year1LossRunsFile}
                  didNotOperateInYearXPrior={formData.didNotOperateInYear1Prior}
                  progressState={year1Progress}
                />
              </Col>
              <Col sm={12} md={4} className={isMobileScreen ? 'p-0 pt-1' : ''}>
                <YearCard
                  coverageType={"APD"}
                  currentYear={props.currentYear}
                  showYearX={showYear2}
                  setShowYearX={setShowYear2}
                  formData={formData}
                  setFormData={setFormData}
                  setProgressState={setYear2Progress}
                  setImgUrl={setImgUrl}
                  policyID={props.policyID}
                  yearXLossData={year2LossData}
                  setYearXLossData={setYear2LossData}
                  resetLosses={resetLosses}
                  handleSubmit={handleSubmit}
                  year={2}
                  numberOfYearsPrior={1}
                  yearXLossRunsFile={formData.year2LossRunsFile}
                  didNotOperateInYearXPrior={formData.didNotOperateInYear2Prior}
                  progressState={year2Progress}
                />
              </Col>
              <Col sm={12} md={4} className={isMobileScreen ? 'p-0 pt-1' : ''}>
                <YearCard
                  coverageType={"APD"}
                  currentYear={props.currentYear}
                  showYearX={showYear3}
                  setShowYearX={setShowYear3}
                  formData={formData}
                  setFormData={setFormData}
                  setProgressState={setYear3Progress}
                  setImgUrl={setImgUrl}
                  policyID={props.policyID}
                  yearXLossData={year3LossData}
                  setYearXLossData={setYear3LossData}
                  resetLosses={resetLosses}
                  handleSubmit={handleSubmit}
                  year={3}
                  numberOfYearsPrior={2}
                  yearXLossRunsFile={formData.year3LossRunsFile}
                  didNotOperateInYearXPrior={formData.didNotOperateInYear3Prior}
                  progressState={year3Progress}
                />
              </Col>
              </Row>
              <Row className={isMobileScreen ? '' : 'mt-2'}>
              {show5years && (
                <>
                <Col sm={12} md={4} className={isMobileScreen ? 'p-0 pt-1' : 'p-0'}>
                  {/* TODO: These year cards dates should operate off the effective date that way they dont change as the years go on in records where they shouldnt change */}
                  <YearCard
                    coverageType={"APD"}
                    currentYear={props.currentYear}
                    showYearX={showYear4}
                    setShowYearX={setShowYear4}
                    formData={formData}
                    setFormData={setFormData}
                    setProgressState={setYear4Progress}
                    setImgUrl={setImgUrl}
                    policyID={props.policyID}
                    yearXLossData={year4LossData}
                    setYearXLossData={setYear4LossData}
                    resetLosses={resetLosses}
                    handleSubmit={handleSubmit}
                    year={4}
                    numberOfYearsPrior={3}
                    yearXLossRunsFile={formData.year4LossRunsFile}
                    didNotOperateInYearXPrior={formData.didNotOperateInYear4Prior}
                    progressState={year4Progress}
                  />
                </Col>
                <Col sm={12} md={4} className={isMobileScreen ? 'p-0 pt-1' : ''}>
                  <YearCard
                    coverageType={"APD"}
                    currentYear={props.currentYear}
                    showYearX={showYear5}
                    setShowYearX={setShowYear5}
                    formData={formData}
                    setFormData={setFormData}
                    setProgressState={setYear5Progress}
                    setImgUrl={setImgUrl}
                    policyID={props.policyID}
                    yearXLossData={year5LossData}
                    setYearXLossData={setYear5LossData}
                    resetLosses={resetLosses}
                    handleSubmit={handleSubmit}
                    year={5}
                    numberOfYearsPrior={4}
                    yearXLossRunsFile={formData.year5LossRunsFile}
                    didNotOperateInYearXPrior={formData.didNotOperateInYear5Prior}
                    progressState={year5Progress}
                  />
                </Col>
                </>
              )}
            </Row>
          </Container>
        </div>
      )}
    </div>
  );
}
