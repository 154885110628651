import { doc } from "firebase/firestore";
import { db } from "../../../firebase";

const updateBatch = async (batch, formData, unit) => {
  const unitRef = doc(
    db,
    "agencies",
    formData.agencyID,
    "insureds",
    formData.insuredID,
    "power-units",
    unit.id
  );

  await batch.update(unitRef, unit);
};

export default updateBatch;