import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc
} from "firebase/firestore";
import { db } from "../../firebase";

export const getUsersRequest = async () => {
  try {
    const q = query(collection(db, "users"));
    const queryData = await getDocs(q);
    const queryResults = queryData.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    return queryResults;
  } catch (error) {
    throw error;
  }
};

export const getUserByUidRequest = async uid => {
  try {
    const userDocRef = doc(db, "users", uid);
    const docSnap = await getDoc(userDocRef);
    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      return {
        error: "Must create a profile to proceed",
      };
    }
  } catch (error) {
    throw error;
  }
};

export const setUserByUidRequest = async (uid, newData) => {
  try {
    const userRef = doc(db, "users", uid);
    const result = await setDoc(userRef, newData, { merge: true });
    return result
  } catch (error) {
    throw error;
  }
};