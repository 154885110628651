import React, { useState, useEffect, useRef } from "react";
import { auth, db } from "../../../firebase";
import {
  onSnapshot,
  collection,
  query,
  where,
  deleteDoc,
  getDocs,
} from "firebase/firestore";
import useUsersState from "../../../Context/actions/useUsersState";
import avatar from "../../../assets/images/default-avatar.png";
import { NavDropdown } from "react-bootstrap";
import MyAccountOptions from "../../Navbar/MyAccountOptions";
import styled from "@emotion/styled";
import { Notifications, Delete, CheckCircle, Error } from "@mui/icons-material";
import Badge from "@mui/material/Badge";
import MailIcon from "@mui/icons-material/Mail";

const UserProfileAvatar = ({ logout }) => {
  const dropdownRef = useRef(null); // Ref para el contenedor del dropdown
  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      right: -3,
      top: 13,
      border: `2px solid #072a48`,
      padding: "0 4px",
      backgroundColor: "red",
      color: "white",
    },
  }));

  const CustomNavDropdown = styled(NavDropdown)`
    &&& > .dropdown-toggle::before {
      display: none;
    }
  `;

  const [user, setUser] = useState(null);
  const [unreadAlerts, setUnreadAlerts] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const unsubscribeAlerts = useRef(null);
  const { user: userInfo } = useUsersState();

  const handleDismissAlert = async (alertId) => {
    try {
      const alertDocRef = collection(db, "alerts", alertId);
      await deleteDoc(alertDocRef);
      setUnreadAlerts((prevAlerts) =>
        prevAlerts.filter((alert) => alert.id !== alertId)
      );
    } catch (error) {
      console.error("Error dismissing alert:", error.message);
    }
  };

  const handleDismissAllAlerts = async () => {
    try {
      const alertsCollectionRef = collection(db, "alerts");
      const snapshot = await getDocs(alertsCollectionRef);

      const deletePromises = snapshot.docs.map(async (doc) => {
        await deleteDoc(doc.ref);
      });

      await Promise.all(deletePromises);

      setUnreadAlerts([]);
    } catch (error) {
      console.error("Error dismissing all alerts:", error.message);
    }
  };

  useEffect(() => {
    const unsubscribeUser = auth.onAuthStateChanged((user) => {
      setUser(user);
    });

    const fetchUnreadAlerts = async () => {
      if (user) {
        const alertsCollectionRef = collection(db, "alerts");
        const alertsQuery = query(
          alertsCollectionRef,
          where("userID", "==", user.uid),
          where("read", "==", false)
        );

        unsubscribeAlerts.current = onSnapshot(alertsQuery, (snapshot) => {
          const newUnreadAlerts = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setUnreadAlerts(newUnreadAlerts);
        });
      }
    };

    fetchUnreadAlerts();

    return () => {
      if (unsubscribeAlerts.current) {
        unsubscribeAlerts.current();
      }
      unsubscribeUser();
    };
  }, [user]);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    if (showDropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDropdown]);

  const handleIndicatorClick = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <div className="ml-auto flex items-center relative">
      {user && user.photoURL && (
        <>
          <CustomNavDropdown
            className="pl-3"
            title={
              <img
                src={
                  userInfo?.profilePhoto
                    ? userInfo?.profilePhoto
                    : user?.photoURL
                    ? user?.photoURL
                    : avatar
                }
                alt="User Avatar"
                className="rounded-full h-8 w-8"
              />
            }
            align="start"
            drop="start"
          >
            <MyAccountOptions userInfo={userInfo} logout={logout} />
          </CustomNavDropdown>
          <div
            onClick={handleIndicatorClick}
            className="flex items-center cursor-pointer"
          >
            <StyledBadge
              badgeContent={unreadAlerts.length}
              color="secondary"
              overlap="circular"
            >
              <MailIcon style={{ color: "white" }} />{" "}
            </StyledBadge>
          </div>
          {showDropdown && (
            <div
              ref={dropdownRef}
              className="min-w-[300px] min-h-[300px] absolute mt-4 right-0 top-2 bg-white border rounded shadow-md p-2 max-h-32 overflow-y-auto z-50"
            >
              {unreadAlerts.length > 0 ? (
                unreadAlerts.map((alert) => (
                  <div
                    key={alert.id}
                    className="mb-2 p-2 bg-gray-100 rounded flex items-center"
                  >
                    <CheckCircle className="text-green-500 mr-2" />
                    <div className="flex-1">
                      <div className="font-[600]">{alert.message}</div>
                      <button
                        onClick={() => handleDismissAlert(alert.id)}
                        className="text-blue-500 hover:underline cursor-pointer flex items-center mt-1"
                      >
                        <Delete className="mr-1" /> Dismiss Alert
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <div className="text-gray-500 flex items-center">
                  <Error className="mr-2" /> No unread alerts
                </div>
              )}
              {unreadAlerts.length > 0 && (
                <button
                  onClick={handleDismissAllAlerts}
                  className="mt-2 w-full bg-[#8B0000] text-white px-4 py-2 rounded hover:bg-[#6d0000] flex items-center justify-center"
                >
                  <Delete className="mr-2" /> Dismiss All Alerts
                </button>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default UserProfileAvatar;