import React, { useState } from "react";
import { Box } from "@mui/material";
import DatePicker from "react-datepicker";
import ApprovalButtons from "../assets/forms/ApprovalButtons";
import { cancelPolicyFn } from "./cancelPolicyFn";

export default function CancelPolicyPanel({ goBack, policyID, insuredInfo, formData }) {
  const [loading, setLoading] = useState(false);
  const [cancellationData, setCancellationData] = useState({
    cancellationDate: new Date(),
  });

  const customClass =
    "appearance-none block w-full bg-white border border-gray-300 rounded-md py-3 px-3 leading-tight focus:outline-none focus:shadow-outline-blue focus:border-blue-300";
  const labelCustomClass = "text-md d-block";

  return (
    <main>
      <h1 className="text-2xl mt-2.5 font-bold md:mb-0">Cancel Policy</h1>
      <p className="mt-3">
        Cancellations must be approved by an underwriter. To cancel the policy,
        please complete the following form.
      </p>
      <Box sx={{ p: 2 }}>
        <form onSubmit={(e) => cancelPolicyFn(
          e,
          cancellationData,
          formData,
          insuredInfo,
          policyID,
          setLoading,
          goBack
        )}>
          <label className={labelCustomClass}>Cancellation Date</label>
          <DatePicker
            wrapperClassName="datePicker"
            selected={cancellationData.cancellationDate}
            onChange={(date) => {
              setCancellationData({
                ...cancellationData,
                cancellationDate: date,
              });
            }}
            className={customClass}
          />
          <ApprovalButtons
            onApprove={null}
            handleAsSubmit={true}
            onDecline={(e) => {
              goBack(e);
            }}
            titleAccept="Cancel Policy"
            titleDecline="Do Not Cancel Policy"
            disabledAccept={false}
            forceLoadingAccept={loading}
          />
        </form>
      </Box>
    </main>
  );
}
