const BigAlert = ({ title, subtitle, okButton, status, className, fn, list }) => {
  const color =
    status === "successful" ? "green" : status === "error" ? "red" : "yellow";
  return (
    <main
      className={`bg-${color}-100 p-6 rounded-md shadow-md text-${color}-700 ${className}`}
    >
      <p className="text-2xl font-semibold mb-2">{title}</p>
      <p className="mb-2">{subtitle}</p>

      {list && list.length ? (
        <ul className="list-disc ml-6 mb-2">
          {list.map((item, index) => (
            <li className={`text-${color}-700`} key={index}>{item}</li>
          ))}
        </ul>
      ) : null}

      {okButton ?
        <button
          onClick={fn}
          className={`bg-${color}-900 hover:bg-${color}-600 text-white font-semibold py-2 px-4 rounded`}
        >
          {okButton}
        </button> : null
      }
    </main>
  );
};

export default BigAlert;
