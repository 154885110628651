import { useRef } from 'react';

const useRenderCount = () => {
  const renderCount = useRef(0);

  // Increment the render count on each render
  renderCount.current += 1;

  return renderCount.current;
};

export default useRenderCount;
