import React, { useEffect, useState } from "react";
import { PASSWORD_ROUTE, USER_DETAILS_ROUTE } from "../../../utils/routes";
import { NavDropdown } from "react-bootstrap";
import useTranslations from "../../../Context/actions/useTranslations";
import changeUserRole from "../../utils/changeUserRole";
import Popup from "../../generals/Popup";
import { auth, db } from "../../../firebase";
import BlueButton from "../../assets/forms/BlueButton";
import {
  Edit as EditIcon,
  Lock as LockIcon,
  Person as PersonIcon,
  Language as LanguageIcon,
  Logout as LogoutIcon,
} from "@mui/icons-material";
import {
  collection,
  doc,
  onSnapshot,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import useGeneralState from "../../../Context/actions/useGeneralState";
import useUsersState from "../../../Context/actions/useUsersState";

export default function MyAccountOptions({ userInfo, logout }) {
  const { changeLanguage, t, language } = useTranslations();
  const { controlToast } = useGeneralState();
  const { user } = useUsersState();

  const [openChangeCompanyModal, setOpenChangeCompanyModal] = useState(false);
  const [agencies, setAgencies] = useState([]);
  const [selectedAgency, setSelectedAgency] = useState({
    id: 0,
    key: "",
    label: "Select Company",
  });

  const handleChangeAgencyModal = (e) => {
    e.preventDefault();
    setOpenChangeCompanyModal(!openChangeCompanyModal);
  };

  const handleChangeAgency = (e) => {
    e.preventDefault();
    const filtered = agencies.find((agency) => {
      return agency.key === e?.target?.value;
    });
    setSelectedAgency(filtered);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (Boolean(selectedAgency.key)) {
        const userRef = doc(db, "users", auth.currentUser.uid);
        await setDoc(
          userRef,
          {
            agencyID: selectedAgency.key,
            company: selectedAgency.label,
          },
          { merge: true }
        );
        controlToast(true, "Company Changed Successfully", "success");
      } else {
        controlToast(true, "Select a company", "error");
      }
    } catch (error) {
      console.error("error", error);
      controlToast(
        true,
        "There was an error trying to change the company",
        "error"
      );
    }
  };

  const getAgencyInfo = () => {
    const q = query(collection(db, "agencies"), where("name", "!=", ""));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const agencies = [];
      querySnapshot.forEach((doc) => {
        if (Boolean(doc?.data()?.name)) {
          agencies.push({ ...doc.data(), id: doc.id });
        }
      });
      if (agencies.length > 0) {
        const agencyData = agencies.map((agency, agencyIndex) => {
          return {
            id: agencyIndex + 1,
            key: agency.id,
            label: agency.name,
          };
        });
        const filteredAgencyData = agencyData.filter((agency) => {
          return typeof agency.label === "string";
        });
        filteredAgencyData.unshift({
          id: 0,
          key: "",
          label: "Select Company",
        });
        setAgencies(filteredAgencyData);
      }
    });
    return unsubscribe;
  };

  useEffect(() => {
    if (!openChangeCompanyModal) {
      return () => {};
    }
    const unsubscribe = getAgencyInfo();
    return () => unsubscribe();
  }, [openChangeCompanyModal]);

  return (
    <div>
      <Popup
        maxWidth="50%"
        isOpen={openChangeCompanyModal}
        onRequestClose={handleChangeAgencyModal}
      >
        <h2 className="text-xl font-bold mt-2 mb-4">Change Company</h2>
        <div className="mb-4">
          <select
            required
            value={selectedAgency.value}
            onChange={handleChangeAgency}
            className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
          >
            {agencies.map((agency) => (
              <option key={agency.id} value={agency.key}>
                {agency.label}
              </option>
            ))}
          </select>
        </div>
        <div>
          <BlueButton
            text="Change Company"
            onClickFunction={handleSubmit}
            icon={<EditIcon />}
            className="w-[100%]"
            disabled={selectedAgency.key === ""}
          />
        </div>
      </Popup>
      <NavDropdown.Item href={PASSWORD_ROUTE}>
        <LockIcon className="mr-2" />
        {t("navbar.change-password")}
      </NavDropdown.Item>
      <NavDropdown.Item href={USER_DETAILS_ROUTE}>
        <PersonIcon className="mr-2" />
        {t("navbar.edit-account-details")}
      </NavDropdown.Item>
      <NavDropdown.Divider />
      {language === "es" ? (
        <NavDropdown.Item href="#" onClick={() => changeLanguage("en")}>
          <LanguageIcon className="mr-2" />
          English
        </NavDropdown.Item>
      ) : (
        <NavDropdown.Item href="#" onClick={() => changeLanguage("es")}>
          <LanguageIcon className="mr-2" />
          Español
        </NavDropdown.Item>
      )}
      <NavDropdown.Divider />
      {userInfo.admin && (
        <>
          {userInfo.role !== "Admin" && (
            <NavDropdown.Item
              href="#"
              onClick={() => {
                changeUserRole(auth.currentUser.uid, "Admin");
              }}
            >
              <EditIcon className="mr-2" />
              Role: Admin
            </NavDropdown.Item>
          )}
          {userInfo.role !== "Agent" && (
            <NavDropdown.Item
              href="#"
              onClick={() => {
                changeUserRole(auth.currentUser.uid, "Agent");
              }}
            >
              <EditIcon className="mr-2" />
              Role: Agent
            </NavDropdown.Item>
          )}
          {userInfo.role !== "Client" && (
            <NavDropdown.Item
              href="#"
              onClick={() => {
                changeUserRole(auth.currentUser.uid, "Client");
              }}
            >
              <EditIcon className="mr-2" />
              Role: Client
            </NavDropdown.Item>
          )}
          {userInfo.role !== "Underwriter" && (
            <NavDropdown.Item
              href="#"
              onClick={() => {
                changeUserRole(auth.currentUser.uid, "Underwriter");
              }}
            >
              <EditIcon className="mr-2" />
              Role: Underwriter
            </NavDropdown.Item>
          )}
          <NavDropdown.Item onClick={handleChangeAgencyModal}>
            <EditIcon className="mr-2" />
            Change Company
          </NavDropdown.Item>
          <NavDropdown.Divider />
        </>
      )}
      <NavDropdown.Item href="#" onClick={logout}>
        <LogoutIcon className="mr-2" />
        {t("navbar.logout")}
      </NavDropdown.Item>
    </div>
  );
}
