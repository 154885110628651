import React, { useState } from "react";
import { Table, Tbody, Td, Thead, Tr } from "react-super-responsive-table";
import getAge from "../../utils/getAge";
import DriverDetailsModal from "./modals/DriverDetailsModal";

export default function ProfileTable({ userInfo, setDriverModalIsOpen, aggregatedDriverInfo, dataReady, driverModalIsOpen, closeDriverModal, openDriverModal}) {
  const [show, setShow] = useState(false);
  return (
    <>
      <h1 className={`${"text-2xl mt-2.5 font-bold md:mb-0"}`}>
        Profile Table
      </h1>
      Count: {aggregatedDriverInfo?.length}
      <button
        className="bg-[#072a48] text-white d-block rounded-md p-2 my-2"
        onClick={(e) => setShow(!show)}
      >
        {show ? "Collapse" : "Expand"} Table
      </button>
      <Table>
        <Thead>
          <Tr>
            <Td className="bg-[#072a48] text-center text-white rounded-tl-md cursor-pointer hover:bg-[#072a48]">
              
              Full Name
            </Td>
            <Td className="bg-[#072a48] text-center text-white cursor-pointer hover:bg-[#072a48]">
              Score Total
            </Td>
            <Td className="bg-[#072a48] text-center text-white cursor-pointer hover:bg-[#072a48]">
              Result
            </Td>
            <Td className="bg-[#072a48] text-center text-white cursor-pointer hover:bg-[#072a48]">
              Claims Count
            </Td>
            <Td className="bg-[#072a48] text-center text-white cursor-pointer hover:bg-[#072a48]">
              Claims Amount
            </Td>
            {show && (
              <Td className="bg-[#072a48] text-center text-white cursor-pointer hover:bg-[#072a48]">
                Age
              </Td>
            )}
            <Td className="bg-[#072a48] text-center text-white cursor-pointer hover:bg-[#072a48]">
              Major Violations
            </Td>
            <Td className="bg-[#072a48] text-center text-white cursor-pointer hover:bg-[#072a48]">
              Minor Violations
            </Td>
            <Td className="bg-[#072a48] text-center text-white cursor-pointer hover:bg-[#072a48]">
              Violations Out of Driving Risk
            </Td>
            <Td className="bg-[#072a48] text-center text-white cursor-pointer hover:bg-[#072a48]">
              Violations Not Included
            </Td>
            <Td
              className={`bg-[#072a48] text-center text-white cursor-pointer hover:bg-[#072a48] ${
                !show && "rounded-tr-md"
              }`}
            >
              Violations Case By Case
            </Td>
            {show && (
              <>
                <Td className="bg-[#072a48] text-center text-white cursor-pointer hover:bg-[#072a48]">
                  Claims Count Score
                </Td>
                <Td className="bg-[#072a48] text-center text-white cursor-pointer hover:bg-[#072a48]">
                  Claims Amount Score
                </Td>
                <Td className="bg-[#072a48] text-center text-white cursor-pointer hover:bg-[#072a48]">
                  Age Score
                </Td>
                <Td className="bg-[#072a48] text-center text-white cursor-pointer hover:bg-[#072a48]">
                  Exp. Score
                </Td>
                <Td className="bg-[#072a48] text-center text-white cursor-pointer hover:bg-[#072a48]">
                  Major Violations Score
                </Td>
                <Td className="bg-[#072a48] text-center text-white cursor-pointer hover:bg-[#072a48]">
                  Minor Violations Score
                </Td>
                <Td className="bg-[#072a48] text-center text-white cursor-pointer hover:bg-[#072a48]">
                  Out of Driving Violations Score
                </Td>
                <Td className="bg-[#072a48] text-center text-white cursor-pointer hover:bg-[#072a48]">
                  Not Included Violation Score
                </Td>
              </>
            )}
          </Tr>
        </Thead>
        <Tbody>
          {dataReady &&
            aggregatedDriverInfo &&
            aggregatedDriverInfo.map((driver, i) => (
              <Tr key={driver?.licenseNumber + i}>
                <Td className="text-center">
                <button onClick={openDriverModal} title={driver?.licenseNumber}>
                  {driver?.driverFirst + " " + driver?.driverLast}
                </button>
              
          <DriverDetailsModal
            driver={driver}
            setDriverModalIsOpen={setDriverModalIsOpen}
            userInfo={userInfo}
            driverModalIsOpen={driverModalIsOpen}
            closeDriverModal={closeDriverModal}
          />
                </Td>
                <Td className="text-center">{driver?.scoreTotal.toFixed(4)}</Td>
                <Td className="text-center">{driver?.result}</Td>
                <Td className="text-center">{driver?.claimsCount}</Td>
                <Td className="text-center">{driver?.claimsAmount}</Td>
                {show && (
                  <Td className="text-center">
                    {driver?.dob &&
                      getAge(driver?.dob.toDate().toString().slice(4, 15))}
                  </Td>
                )}

                <Td className="text-center">{driver?.majorViolationsCount}</Td>
                <Td className="text-center">{driver?.minorViolationsCount}</Td>
                <Td className="text-center">{driver?.outOfDrivingCount}</Td>
                <Td className="text-center">{driver?.notIncludedCount}</Td>
                <Td className="text-center">{driver?.caseByCaseCount}</Td>
                {show && (
                  <>
                    <Td className="text-center">{driver?.claimsCountScore}</Td>
                    <Td className="text-center">{driver?.claimsAmountScore}</Td>
                    <Td className="text-center">{driver?.ageScore}</Td>
                    <Td className="text-center">{driver?.expScore}</Td>
                    <Td className="text-center">{driver?.majorScore}</Td>
                    <Td className="text-center">{driver?.minorScore}</Td>
                    <Td className="text-center">{driver?.outOfDrivingScore}</Td>
                    <Td className="text-center">{driver?.notIncludedScore}</Td>
                  </>
                )}
              </Tr>
            ))}
        </Tbody>
      </Table>
    </>
  );
}
