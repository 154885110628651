const Row = ({
  xs,
  sm,
  md,
  lg,
  xl,
  xxl,
  as: Component = "div",
  bsPrefix = "",
  className = "",
  children,
  onClick = null
}) => {
  let classes = bsPrefix;

  if (xs) classes += ` grid grid-cols-${xs}`;
  if (sm) classes += ` sm:grid sm:grid-cols-${sm}`;
  if (md) classes += ` md:grid md:grid-cols-${md}`;
  if (lg) classes += ` lg:grid lg:grid-cols-${lg}`;
  if (xl) classes += ` xl:grid xl:grid-cols-${xl}`;
  if (xxl) classes += ` 2xl:grid 2xl:grid-cols-${xxl}`;

  classes += ` ${className} w-full flex flex-wrap max-w-full`;

  return <Component onClick={onClick} className={classes.trim()}>{children}</Component>;
};

export default Row
