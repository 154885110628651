import React, { useEffect, useState } from "react";
import {
  collection,
  query,
  getDocs,
  updateDoc,
  addDoc,
  doc,
  onSnapshot,
} from "firebase/firestore";
import {
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  MenuItem,
  Grid,
  Typography,
  IconButton,
} from "@mui/material";
import { Edit, Save } from "@mui/icons-material";
import { db } from "../../../firebase";
import camelCaseToTitleCase from "../../utils/camelCaseToTitleCase";

const Table = ({ mainCollection, tableName }) => {
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingDocument, setEditingDocument] = useState(null);
  const [newValues, setNewValues] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [classificationOptions, setClassificationOptions] = useState([]);
  const [newEventuality, setNewEventuality] = useState({
    code: "",
    description: "",
    classification: "",
  });

  const [customClassification, setCustomClassification] = useState("");
  const [useCustomClassification, setUseCustomClassification] = useState(false);
  const [finalClassification, setFinalClassification] = useState("");
  const [notIncluded, setNotIncluded] = useState("");
  const [uwClassification, setUwClassification] = useState("");
  const [generalClassification, setGeneralClassification] = useState("");
  const [special, setSpecial] = useState("");

  useEffect(() => {
    const unsubscribe = onSnapshot(
      query(collection(db, "loss-control", mainCollection, tableName)),
      (snapshot) => {
        const fetchedDocuments = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setDocuments(fetchedDocuments);
        setLoading(false);
      },
      (error) => {
        console.error("Error fetching documents: ", error);
        setLoading(false);
      }
    );

    // Clean up function to unsubscribe when the component unmounts
    return () => unsubscribe();
  }, [mainCollection, tableName]);

  useEffect(() => {
    const fetchClassifications = async () => {
      const eventualitiesQuerySnapshot = await getDocs(
        collection(db, "loss-control", mainCollection, tableName)
      );
      const classifications = new Set();
      eventualitiesQuerySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.classification) {
          classifications.add(data.classification);
        }
      });
      setClassificationOptions([...classifications, "Other"]);
    };

    fetchClassifications();
  }, [mainCollection, tableName]);

  const handleEdit = (documentId) => {
    setEditingDocument(documentId);
    setNewValues({
      ...newValues,
      [documentId]: { ...documents.find((doc) => doc.id === documentId) },
    });
  };

  const handleSave = async (tableName, documentId) => {
    try {
      await updateDoc(
        doc(db, "loss-control", mainCollection, tableName, documentId),
        newValues[documentId]
      );

      setEditingDocument(null);
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  };

  const handleChange = (e, fieldName, documentId) => {
    const { value } = e.target;
    setNewValues({
      ...newValues,
      [documentId]: { ...newValues[documentId], [fieldName]: value },
    });
  };

  const handleNewEventualityChange = (e) => {
    const { name, value } = e.target;
    setNewEventuality({ ...newEventuality, [name]: value });
  };

  const handleAddEventuality = async () => {
    try {
      let classificationData = {};
      if (newEventuality.classification === "Other") {
        classificationData = {
          customClassification,
          finalClassification,
          notIncluded,
          uwClassification,
          generalClassification,
          special,
        };
      }

      await addDoc(
        collection(db, "loss-control", mainCollection, tableName),
        {
          ...newEventuality,
          ...(newEventuality.classification === "Other" ? classificationData : {}),
        }
      );
      const classificationCollection = collection(db, "loss-control", "tables", "classifications");
      // add a new document to the classifications table
      if (tableName === "eventualities" && newEventuality.classification === "Other") {
        await addDoc(classificationCollection, {
          classification: customClassification,
          finalClassification,
          notIncluded,
          uwClassification,
          generalClassification,
          special,
        });
      }
      setShowModal(false);
      setNewEventuality({ code: "", description: "", classification: "" });
      setCustomClassification("");
      setFinalClassification("");
      setNotIncluded("");
      setUwClassification("");
      setGeneralClassification("");
      setSpecial("");
    } catch (error) {
      console.error("Error adding eventuality: ", error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (documents.length === 0) {
    return <div>No documents found.</div>;
  }

  const columnNames = Object.keys(documents[0]);

  return (
    <Card variant="outlined">
      <CardContent sx={{ overflowX: "auto" }}>
        <Typography variant="h6" gutterBottom>
          {camelCaseToTitleCase(tableName)}
        </Typography>
        {/* If is Eventualities table, display add eventuality button */}
        {tableName === "eventualities" && (
          <div className="mb-2">
            <Button onClick={() => setShowModal(true)}>Add Eventuality</Button>
            <Dialog open={showModal} onClose={() => setShowModal(false)}>
              <DialogTitle>Add Eventuality</DialogTitle>
              <DialogContent>
                <TextField
                  label="Code"
                  name="code"
                  value={newEventuality.code}
                  onChange={handleNewEventualityChange}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Description"
                  name="description"
                  value={newEventuality.description}
                  onChange={handleNewEventualityChange}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  select
                  label="Classification"
                  name="classification"
                  value={newEventuality.classification}
                  onChange={handleNewEventualityChange}
                  fullWidth
                  margin="normal"
                >
                  {classificationOptions.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
                {newEventuality.classification === "Other" && (
                  <>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Custom Classification"
                        value={customClassification}
                        onChange={(e) =>
                          setCustomClassification(e.target.value)
                        }
                        sx={{ mt: 2 }}
                      />
                      <TextField
                        select
                        fullWidth
                        label="Final Classification"
                        value={finalClassification}
                        onChange={(e) => setFinalClassification(e.target.value)}
                        sx={{ mt: 2 }}
                      >
                        <MenuItem value="Included">Included</MenuItem>
                        <MenuItem value="Not Included">Not Included</MenuItem>
                        <MenuItem value="Major">Major</MenuItem>
                        <MenuItem value="Minor">Minor</MenuItem>
                      </TextField>
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                      select
                        fullWidth
                        label="Not Included"
                        value={notIncluded}
                        onChange={(e) => setNotIncluded(e.target.value)}
                        sx={{ mt: 2 }}
                      >
                        
                        <MenuItem value="Not Included">Not Included</MenuItem>

                      </TextField>
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        select
                        fullWidth
                        label="UW Classification"
                        value={uwClassification}
                        onChange={(e) => setUwClassification(e.target.value)}
                        sx={{ mt: 2 }}
                      >
                        <MenuItem value="Type A">Type A</MenuItem>
                        <MenuItem value="Type B">Type B</MenuItem>
                      </TextField>
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        select
                        fullWidth
                        label="General Classification"
                        value={generalClassification}
                        onChange={(e) =>
                          setGeneralClassification(e.target.value)
                        }
                        sx={{ mt: 2 }}
                      >
                        <MenuItem value="Included">Included</MenuItem>
                        <MenuItem value="Not Included">Not Included</MenuItem>
                        <MenuItem value="Major">Major</MenuItem>
                        <MenuItem value="Minor">Minor</MenuItem>
                      </TextField>
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        select
                        fullWidth
                        label="Special"
                        value={special}
                        onChange={(e) => setSpecial(e.target.value)}
                        sx={{ mt: 2 }}
                      >
                        <MenuItem value="Case By Case">Case By Case</MenuItem>
                      </TextField>
                    </Grid>
                  </>
                )}
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setShowModal(false)}>Close</Button>
                <Button onClick={handleAddEventuality} variant="contained">
                  Add
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        )}
        <table className="w-full">
          <thead>
            <tr>
              {columnNames.map((columnName) => {
                if (columnName === "id") return null;
                return (
                  <th key={columnName}>{camelCaseToTitleCase(columnName)}</th>
                );
              })}
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {documents.map((doc) => (
              <tr key={doc.id}>
                {columnNames.map((columnName) => {
                  if (columnName === "id") return null;
                  return (
                    <td key={columnName}>
                      {editingDocument === doc.id ? (
                        <TextField
                          fullWidth
                          value={newValues[doc.id][columnName]}
                          onChange={(e) => handleChange(e, columnName, doc.id)}
                        />
                      ) : (
                        doc[columnName]
                      )}
                    </td>
                  );
                })}
                <td>
                  {editingDocument === doc.id ? (
                    <IconButton onClick={() => handleSave(tableName, doc.id)}>
                      <Save />
                    </IconButton>
                  ) : (
                    <IconButton onClick={() => handleEdit(doc.id)}>
                      <Edit />
                    </IconButton>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </CardContent>
    </Card>
  );
};

export default Table;
