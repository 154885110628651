import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Typography,
} from "@mui/material";
import { db } from "../../../../firebase";
import Popup from "../../../generals/Popup";
import { doc, onSnapshot } from "firebase/firestore";
import camelCaseToTitleCase from "../../../utils/camelCaseToTitleCase";

const PreviousYearsAdjustments = ({
  insuredID,
  agencyID,
  openAssignDocumentModal,
  setOpenModal,
}) => {
  const [adjustmentsData, setAdjustmentsData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAdjustmentsData = () => {
      const insuredRef = doc(db, "agencies", agencyID, "insureds", insuredID);

      // Real-time listener for the insured document
      const unsubscribeInsured = onSnapshot(insuredRef, (insuredSnapshot) => {
        if (insuredSnapshot.exists()) {
          const { alPolicies } = insuredSnapshot.data(); // assuming alPolicies is the field with policy data

          // Initialize an array to store all adjustments
          const allAdjustments = [];

          const fetchPolicies = async () => {
            // Create an array of promises for policy listeners
            const policyPromises = alPolicies.map((policy) => {
              const policyID = policy.policyID;
              const policyDocRef = doc(db, "policy-applications", policyID);

              return new Promise((resolve) => {
                // Real-time listener for each policy document in "policy-applications"
                const unsubscribePolicy = onSnapshot(
                  policyDocRef,
                  (policyDocSnapshot) => {
                    if (policyDocSnapshot.exists()) {
                      const { bindData, alPolicyNo, effectiveDate } =
                        policyDocSnapshot.data();
                      const { adjustments } = bindData;
                      if (adjustments) {
                        adjustments.alPolicyNo = alPolicyNo;
                        adjustments.effectiveDate = effectiveDate;
                        allAdjustments.push(adjustments);
                        console.log("Adjustments: ", adjustments )
                      }
                    } else {
                      console.log("No such policy document!");
                    }
                    resolve(unsubscribePolicy); // Resolve with unsubscribe function
                  }
                );
              });
            });

            // Wait for all policy listeners to resolve
            Promise.all(policyPromises).then(() => {
              // sort adjustments by al policy no last 2 characters
              allAdjustments.sort((a, b) => {
                const aPolicyNo = a.alPolicyNo;
                const bPolicyNo = b.alPolicyNo;
                const aLastTwo = aPolicyNo.slice(-2);
                const bLastTwo = bPolicyNo.slice(-2);
                return bLastTwo - aLastTwo;
              });
              // Update the state after processing all policies
              setAdjustmentsData(allAdjustments);
              setLoading(false);
            });
          };

          fetchPolicies();
        } else {
          console.log("No such insured document!");
          setLoading(false);
        }
      });

      // Cleanup the listener on unmount
      return () => {
        unsubscribeInsured();
      };
    };

    if (insuredID && agencyID && openAssignDocumentModal) {
      fetchAdjustmentsData();
    }
  }, [insuredID, agencyID, openAssignDocumentModal]);

  return (
    <Popup
      isOpen={openAssignDocumentModal}
      onRequestClose={() => setOpenModal(false)}
      maxWidth={"600px"}
    >
      {loading ? (
        <CircularProgress />
      ) : adjustmentsData.length === 0 ? (
        <Typography>No adjustments found</Typography>
      ) : (
        adjustmentsData.map((adjustments, index) => (
          <TableContainer
            key={index}
            component={Paper}
            style={{ marginBottom: "16px" }}
          >
            <Typography variant="h6" align="center" gutterBottom>
              {adjustments.alPolicyNo}
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <strong>Category</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Description</strong>
                  </TableCell>
                  <TableCell align="right">
                    <strong>Percentage</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* Credits Section */}
                {Object.entries(adjustments.credits).map(([key, value], i) => (
                  <TableRow key={i}>
                    <TableCell>Credits</TableCell>
                    <TableCell>{camelCaseToTitleCase(key.replace(/([A-Z])/g, " $1"))}</TableCell>
                    <TableCell align="right">
                      {(value * 100).toFixed(2)}%
                    </TableCell>
                  </TableRow>
                ))}

                {/* Fleet Credit */}
                <TableRow>
                  <TableCell>Credits</TableCell>
                  <TableCell>Fleet Credit</TableCell>
                  <TableCell align="right">
                    {(adjustments.fleetCredit * 100).toFixed(2)}%
                  </TableCell>
                </TableRow>

                {/* Surcharges Section */}
                {Object.entries(adjustments.surcharges).map(
                  ([key, value], i) => (
                    <TableRow key={i}>
                      <TableCell>Surcharges</TableCell>
                      <TableCell>{camelCaseToTitleCase(key.replace(/([A-Z])/g, " $1"))}</TableCell>
                      <TableCell align="right">
                        {(value * 100).toFixed(2)}%
                      </TableCell>
                    </TableRow>
                  )
                )}

                {/* Total Adjustment */}
                <TableRow>
                  <TableCell colSpan={2}>
                    <strong>Total Adjustment</strong>
                  </TableCell>
                  <TableCell align="right">
                    <strong>
                      {(adjustments.totalAdjustment * 100).toFixed(2)}%
                    </strong>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        ))
      )}
    </Popup>
  );
};

export default PreviousYearsAdjustments;
