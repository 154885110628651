import { useEffect, useState } from "react";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "../../firebase";
import { searchFilterData } from "../../utils/helpers";

const useSupportTicketsList = () => {
  const itemsPerPage = 10;
  const [supportTicketsList, setSupportTicketsList] = useState([]);
  const [doneGettingSupportTickets, setDoneGettingSupportTickets] =
    useState(false);
  const [showCompanySelection, setShowCompanySelection] = useState(false);
  const [selected, setSelected] = useState("");
  const [selectedCompanyInfo, setSelectedCompanyInfo] = useState();
  const [showHistory, setShowHistory] = useState(false);
  const [currentSort, setCurrentSort] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [filteredResults, setFilteredResults] = useState([]);
  const [dataUpdated, setDataUpdated] = useState(0);

  const offset = currentPage * itemsPerPage;
  const currentItems = filteredResults.slice(offset, offset + itemsPerPage);
  const pageCount = Math.ceil(filteredResults.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    setDoneGettingSupportTickets(false);
    const q = query(collection(db, "support-tickets"), orderBy("date", "desc"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const supportTickets = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
        date: new Date(doc.data().date),
      }));
      supportTickets.sort((a, b) => b.date - a.date);
      setSupportTicketsList(supportTickets);
      setFilteredResults(supportTickets);
      setDoneGettingSupportTickets(true);
    });
    return () => unsubscribe();
  }, []); 

  const [searchValue, setSearchValue] = useState("");

  const searchAllProperties = (e) => {
    const { value } = e.target
    setSearchValue(value);
    if (value === "") {
      setFilteredResults(supportTicketsList);
      return;
    } else {
      const values = searchFilterData(supportTicketsList, value)
      setFilteredResults(values);
      setCurrentPage(0)
      setDataUpdated(dataUpdated + 1);
    }
  };

  const handleDateSort = (e, name) => {
    e.preventDefault();
    if (currentSort !== name) {
      setCurrentSort(name);
      filteredResults.sort((a, b) => new Date(a.date) - new Date(b.date));
    }
    if (currentSort === name) {
      setCurrentSort(`reverse-${name}`);
      filteredResults.sort((a, b) => new Date(b.date) - new Date(a.date));
    }
    setDataUpdated(dataUpdated + 1);
  };

  const handleAlphabetSort = (e, name) => {
    e.preventDefault();
    if (currentSort !== name) {
      setCurrentSort(name);
      filteredResults.sort((a, b) =>
        a[name] > b[name] ? 1 : b[name] > a[name] ? -1 : 0
      );
    }
    if (currentSort === name) {
      setCurrentSort(`reverse-${name}`);
      filteredResults.sort((a, b) =>
        a[name] < b[name] ? 1 : b[name] < a[name] ? -1 : 0
      );
    }
    setDataUpdated(dataUpdated + 1);
  };

  return {
    showCompanySelection,
    searchValue,
    searchAllProperties,
    currentSort,
    handleDateSort,
    handleAlphabetSort,
    currentItems,
    pageCount,
    currentPage,
    selected,
    setSelected,
    setSelectedCompanyInfo,
    setShowHistory,
    handlePageChange,
    doneGettingSupportTickets
  }
};

export default useSupportTicketsList;
