import { doc, getDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../../firebase";
import useTranslations from "../../../Context/actions/useTranslations";

export default function CompanyProfile(props) {

  const { t } = useTranslations()
  const [companyInfo, setInsuredInfo] = useState({});
  const [gotInfo, setGotInfo] = useState(false);
  const [noInfo, setNoInfo] = useState(false);
  const getCompanyInfo = async () => {
    setGotInfo(false);
    const docRef = doc(db, "ss-insureds", props.agencyID);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setInsuredInfo({...docSnap.data(), id: docSnap.id});
      console.log(docSnap.id)
      setGotInfo(true);
      setNoInfo(false);
    } else {
      // doc.data() will be undefined in this case
      setNoInfo(true);
      return null;
    }
  };

  useEffect(() => {
    !gotInfo && getCompanyInfo();
    gotInfo && props.setSelectedCompanyInfo(companyInfo);
  }, [gotInfo]);

  return (
    <main className="card">

      <div className="card-body">
        <h2 title={companyInfo.id} className="text-xl font-bold text-[#072a48]">
          {companyInfo.company}
        </h2>
        <h3 className="text-lg text-[#072a48]">{companyInfo.address}</h3>
        <h3 className="text-lg text-[#072a48]">{companyInfo.city}, {companyInfo.state} {companyInfo.zip}</h3>
        <h3 className="text-lg text-[#072a48]">DOT: {companyInfo.dot}</h3>
        <h3 className="text-lg text-[#072a48]">MC: {companyInfo.mc}</h3>
        <h3 className="text-lg text-[#072a48]">{t('account.proposals.phone')}: {companyInfo.phone}</h3>

      </div>
          </main>
  );
}
