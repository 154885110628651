import React, { useState, useEffect } from "react";
import { Table, Tbody, Td, Thead, Tr } from "react-super-responsive-table";
import classNames from "classnames";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { db } from "../../../firebase";
import { useNavigate } from "react-router-dom";
import useTranslations from "../../../Context/actions/useTranslations";
import Pagination from "../../assets/lists/DynamicTable/Pagination";
import TableSkeleton from "../../generals/TableSkeleton";
import Popup from "../../generals/Popup";
import {
  Button,
  MenuItem,
  Select,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

const policysPerPage = 10;

function PolicyGroupsList({
  policyList,
  policyGroupsList,
  setPolicyGroupsList,
  userInfo,
  loading,
  setLoading,
}) {
  const agencyID = userInfo.agencyID;

  const { t, language } = useTranslations();

  const initialSortConfig = {
    key: "",
    direction: "asc",
  };

  const [sortConfig, setSortConfig] = useState(initialSortConfig);
  const [searchTerm, setSearchTerm] = useState("");

  const handleSort = (key) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key, direction });
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const [policyGroups, setPolicyGroups] = useState([]);

  useEffect(() => {
    // Reference the collection
    const policyGroupCollection = collection(db, "policyGroups");

    // Set up the real-time listener
    const unsubscribe = onSnapshot(policyGroupCollection, (snapshot) => {
      const policyGroupsData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setPolicyGroups(policyGroupsData);
    });

    // Cleanup the listener when the component unmounts
    return () => unsubscribe();
  }, []);

  const sortedData = [...policyGroups].sort((a, b) => {
    if (sortConfig.direction === "asc") {
      return a[sortConfig.key] > b[sortConfig.key] ? 1 : -1;
    } else {
      return a[sortConfig.key] < b[sortConfig.key] ? 1 : -1;
    }
  });

  const [currentPage, setCurrentPage] = useState(0);
  const offset = currentPage * policysPerPage;
  const currentItems = sortedData.slice(offset, offset + policysPerPage);

  const pageCount = Math.ceil(sortedData?.length / policysPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const [hoveredRow, setHoveredRow] = useState(null);
  const [showEditGroupModal, setShowEditGroupModal] = useState(false);
  const [selectedPolicy, setSelectedPolicy] = useState(null);

  const handleAddPolicy = () => {
    // TODO: add system logging here
    const currentPolicies = selectedGroup.policies || [];
    // ensure that policy is not already in the group
    if (currentPolicies.includes(selectedPolicy)) {
        return alert('Policy already exists in this group');
    }
    const updatedPolicies = [...currentPolicies, selectedPolicy];
    const docRef = doc(db, "policyGroups", selectedGroup.id);
    setDoc(docRef, { policies: updatedPolicies }, { merge: true });
    setSelectedPolicy(null);
  };

  const handleMouseEnter = (index) => {
    setHoveredRow(index);
  };

  const handleMouseLeave = () => {
    setHoveredRow(null);
  };
  const navigate = useNavigate();
  const [selectedGroup, setSelectedGroup] = useState({});
  const handleRowClick = (policyGroup) => {
    setSelectedGroup(policyGroup);
    setShowEditGroupModal(true);
  };

  const [insureds, setInsureds] = useState([]);

  useEffect(() => {
    if (!agencyID) return;

    // Reference to the "insureds" sub-collection under the given agency
    const insuredsCollection = collection(db, "agencies", agencyID, "insureds");

    // Real-time listener setup for insureds collection
    const unsubscribe = onSnapshot(insuredsCollection, (snapshot) => {
      const insuredsData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setInsureds(insuredsData);
    });

    // Cleanup listener on component unmount
    return () => unsubscribe();
  }, [agencyID]);

  const getInsuredName = (insuredID) => {
    const insured = insureds.find((insured) => insured.id === insuredID);
    return insured ? insured.company : "Unknown";
  };

  return (
    <main>
      <Popup
        isOpen={showEditGroupModal}
        onRequestClose={() => setShowEditGroupModal(false)}
        maxWidth="500px"
      >
        {/* Title */}
        <Typography variant="h6" gutterBottom>
          Edit Grouped Policies
        </Typography>

        {/* Currently Grouped Policies */}
        <Typography variant="subtitle1" gutterBottom>
          {selectedGroup.groupName} Includes:
        </Typography>
        <Box sx={{ maxHeight: "150px", overflowY: "auto", mb: 2 }}>
          <List>
            {selectedGroup.policies && selectedGroup.policies.length > 0 ? (
              selectedGroup.policies.map((policy, index) => (
                <ListItem
                  key={index}
                >
                  <ListItemText primary={getInsuredName(policy)} />
                </ListItem>
              ))
            ) : (
              <Typography variant="body2" color="textSecondary">
                No policies are currently grouped.
              </Typography>
            )}
          </List>
        </Box>

        {/* Select Menu for Policy List */}
        <Typography variant="subtitle1" gutterBottom>
          Select Insured to Add
        </Typography>
        <Box sx={{ maxHeight: "150px", overflowY: "auto", mb: 2 }}>
          <Select
            value={selectedPolicy}
            onChange={(e) => setSelectedPolicy(e.target.value)}
            fullWidth
          >
            {insureds && insureds.length > 0 ? (
              insureds.map((insured, index) => (
                <MenuItem key={index} value={insured.id}>
                  {insured.company}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>No policies available</MenuItem>
            )}
          </Select>
        </Box>

        {/* Add Policy Button */}
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddPolicy}
          fullWidth
        >
          Add Policy To Group
        </Button>
      </Popup>
      {loading ? (
        <TableSkeleton rows={5} columns={5} />
      ) : policyGroups?.length === 0 ? (
        <p>{t("You have no policy groups, get started by adding one.")}</p>
      ) : (
        <div className="mt-4">
          {/* <input
            type="text"
            placeholder={t("manage-policies.search")}
            value={searchTerm}
            onChange={handleSearch}
            className="mb-2 p-2 border rounded w-full"
          /> */}
          <Table>
            <Thead>
              <Tr>
                <Td
                  className={classNames(
                    "bg-[#072a48] text-white rounded-tl-md cursor-pointer",
                    {
                      "hover:bg-[#072a48]": sortConfig.key !== "groupName",
                      "bg-blue-500": sortConfig.key === "groupName",
                    }
                  )}
                  onClick={() => handleSort("groupName")}
                >
                  {t("Group Name")}
                </Td>
                <Td
                  className={classNames(
                    "bg-[#072a48] text-white cursor-pointer",
                    {
                      "hover:bg-[#072a48]": sortConfig.key !== "policies",
                      "bg-blue-500": sortConfig.key === "policies",
                    }
                  )}
                >
                  {t("Policies")}
                </Td>
              </Tr>
            </Thead>

            <Tbody>
              {currentItems.map((policyGroup, index) => (
                <Tr
                  key={policyGroup.id}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                  onClick={() => handleRowClick(policyGroup)}
                  style={{
                    cursor: "pointer",
                    backgroundColor:
                      hoveredRow === index ? "#e6f7ff" : "inherit",
                  }}
                >
                  <Td>{policyGroup.groupName}</Td>
                  <Td>
                    <List>
                      {policyGroup.policies.map((policy, index) => (
                        <ListItem
                          key={index}
                        >
                          <ListItemText primary={getInsuredName(policy)} />
                        </ListItem>
                      ))}
                    </List>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          <Pagination
            pageCount={pageCount}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
          />
        </div>
      )}
    </main>
  );
}

export default PolicyGroupsList;
