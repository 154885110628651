import React, { useEffect, useState } from "react";
import {
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { db, auth } from "../../../firebase";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

function W9() {
  const navigate = useNavigate();
  let { paramRefID } = useParams();

  const [agentProfile, setAgentProfile] = useState({});
  const [gotProfile, setGotProfile] = useState(false);
  const getAgentProfile = async () => {
    setGotProfile(false);
    const docRef = doc(db, "users", paramRefID);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setAgentProfile(docSnap.data());
      setGotProfile(true);
      console.log("Got Agent Profile");
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document! User must create a profile to proceed.");
      return null;
    }
  };

  //   Update Status to Contract Signed
  const markW9Complete = async () => {
    const agentRef = doc(db, "users", paramRefID);
    await updateDoc(agentRef, {
      W9Complete: {
        when: new Date(),
        by: auth.currentUser.displayName,
        downloadURL: "",
      },
    });
    sendEmail(
      "colt@southernstarmga.com",
      "W9 Submitted",
      "A W9 was submitted:" + agentProfile.company
    );
    navigate("/account");
  };

  const sendEmail = async (to, subject, text) => {
    axios
      .post("https://sstools-api-d4ca8.uc.r.appspot.com/send-email", {
        to: to,
        subject: subject,
        text: text,
      })
      .then(function (response) {
        // handle success
        console.log(response);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  };

  useEffect(() => {
    if (gotProfile === false) getAgentProfile();
  }, [db, gotProfile]);

  return (
    <>
      <h1 className="mt-4 text-xl font-bold text-[#072a48]">Complete a W9:</h1>
      <p className="mb-1">
        Congratulations on your acceptance. To proceed with submitting business,
        you must first sign the agent appointment contract and submit a W9. Once
        the documents are signed signed, they will be reviewed by an underwriter
        and barring any unforeseen circumstances you will be granted a Tier 1
        Appointment.
      </p>

      {!agentProfile.W9Complete ? (
        <>
          <p className="mb-4">
            Once you've submitted your W9, please click the "I Have Submitted My
            W9" button below to notify our underwriters that the W9 is complete
            and update your application status.
          </p>
          <button
            onClick={markW9Complete}
            className="border block px-6 py-2 mr-5 text-center bg-[#072a48] text-white rounded mb-4"
          >
            I Have Submitted My W9
          </button>
          <iframe
            src="https://na4.documents.adobe.com/public/esignWidget?wid=CBFCIBAA3AAABLblqZhCVLYWOmV2e3inleblDxe4m1TxVEU87PYGeijLtNQNAvr1xOWYvBokIsFBzBBiBo0Q*&hosted=false"
            width="100%"
            height="100%"
            frameborder="0"
            style={{
                border: 0,
                overflow: "hidden",
                minHeight: "500px",
                minWidth: "600px",
              }}
          ></iframe>
        </>
      ) : (
        <>
          You marked your W9 as complete. Your W9 is in the process of
          being reviewed by an a member of our team. Please check back for updates.
        </>
      )}
    </>
  );
}

export default W9;
