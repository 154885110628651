import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";

const updateDriverField = async (policyID, driverID, fieldName, value, agencyID, insuredID) => {
    const policyRef = doc(db, "agencies", agencyID, "insureds", insuredID, "drivers", driverID);
    
    await updateDoc(policyRef, {
      [fieldName]: value
    });
  };

  export default updateDriverField;