import React from 'react';

const Breadcrumbs = ({ items }) => {
  return (
    <nav className="text-sm">
      <ol className="list-none p-0 inline-flex">
        {items.map((item, index) => (
          <li key={index} className="flex items-center">
            {index > 0 && <span className="mx-2 text-gray-500">{'>'}</span>}
            {index === 0 ? (
              <a href={item.link} className="text-gray-500 hover:text-gray-700">
                {item.label}
              </a>
            ) : (
              <span className="text-gray-500">{item.label}</span>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;