import React, { useState, useEffect } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../../firebase";
import config from "../../../config/env";

export default function PolicySearch({
  searchValue,
  selectedInsured,
  setSelectedInsured,
  fetchViolations,
  selectedPolicy,
  setSelectedPolicy,
  setSearchValue,
}) {
  const handleSearchChange = (event) => {
    event.preventDefault();
    setSelectedInsured(event.target.value);
    fetchPolicies(event.target.value); // Fetch policies when an insured is selected
  };

  const [insureds, setInsureds] = useState([]);
  const [policies, setPolicies] = useState([]);

  const handleChangePolicy = (e) => {
    setSelectedPolicy(e.target.value);
  };

  const { REACT_APP_LICONA_INSURANCE_GROUP_ID } = config

  useEffect(() => {
    const fetchInsureds = async () => {
      try {
        const querySnapshot = await getDocs(
          collection(db, "agencies", REACT_APP_LICONA_INSURANCE_GROUP_ID, "insureds")
        );
        const results = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setInsureds(results);
      } catch (error) {
        console.error("Error fetching insureds:", error);
      }
    };

    fetchInsureds();
  }, []);

  const fetchPolicies = async (insuredID) => {
    const q = query(
      collection(db, "policy-applications"),
      where("insuredID", "==", insuredID)
    );

    const querySnapshot = await getDocs(q);
    const values = [];
    querySnapshot.forEach((doc) => {
      values.push({ ...doc.data(), id: doc.id });
    });
    setPolicies(values);
  };

  return (
    <div className="my-4">
      <FormControl fullWidth>
        <InputLabel htmlFor="policy-input">Select Insured</InputLabel>
        <Select
          required
          id="search-input"
          value={selectedInsured}
          onChange={handleSearchChange}
          variant="outlined"
          fullWidth
        >
        <MenuItem value="">Select an option</MenuItem>
          {insureds
            .slice() // Make a shallow copy of the array
            .sort((a, b) => a.company.localeCompare(b.company)) // Sort the array alphabetically
            .map((insured) => (
              <MenuItem key={insured.id} value={insured.id}>
                {insured.company}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <div className="my-4">
        {selectedInsured !== null && ( // Conditionally render the second Select if an insured is selected
          <FormControl fullWidth>
            <InputLabel htmlFor="policy-input">Select Policy</InputLabel>
            <Select
              required
              id="policy-input"
              value={selectedPolicy}
              onChange={handleChangePolicy} // Handle policy selection
              variant="outlined"
              fullWidth
            >
            <MenuItem value="">Select an option</MenuItem>
              {policies.map((policy) => (
                <MenuItem key={policy.id} value={policy.id}>
                  {policy?.alPolicyNo ||
                    "Application" +
                      ((policy.createdOn ? " from " : "") +
                        policy.createdOn.toDate().toString().slice(11, 15))}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </div>
    </div>
  );
}
