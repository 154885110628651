import axios from "axios";
import config from "../../../../config/env";

const { REACT_APP_VPIC_API } = config

const decodeVIN = async (VIN, year) => {
  const requestURL = `${REACT_APP_VPIC_API}/vehicles/DecodeVin/${VIN}?format=json&modelyear=${year}`
  let filteredResults;
  await axios.get(requestURL).then((results) => {
    filteredResults = results.data.Results.filter(
        (result) =>
          result.Value !==
            "0 - VIN decoded clean. Check Digit (9th position) is correct" &&
          result.Value !== "" &&
          result.Value !== "Not Applicable" &&
          result.Value !== "0" &&
          result.Value !== null
      )
  });
  
  return filteredResults;
};

export default decodeVIN;
