import { Box, Tooltip } from "@mui/material";
import { useState } from "react";
import { Visibility as VisibilityIcon } from "@mui/icons-material";
import Popup from "../../../generals/Popup";
import LicenseUploadForm from "./LicenseUploadForm";

const ModalLicenseUploadForm = ({ isApplicationOrPolicy, item }) => {
  const [seeInfo, setSeeInfo] = useState(false);

  return (
    <>
      <Tooltip title={<Box>View Uploaded Images</Box>} placement="top" arrow>
        <button
          onClick={(e) => {
            e.preventDefault();
            setSeeInfo(true);
          }}
          className={`text-[#8B0000] hover:text-[#8B0000] d-inline mx-1
            ${
            !isApplicationOrPolicy
                ? "cursor-not-allowed"
                : ""
            }
          `}
        >
          <VisibilityIcon
            className={!isApplicationOrPolicy ? "opacity-20" : ""}
          />
        </button>
      </Tooltip>
      <Popup isOpen={seeInfo} onRequestClose={() => setSeeInfo(false)}>
        <LicenseUploadForm
          licenseNumber={item?.licenseNumber}
          dob={item?.dob}
          type={item?.licenseCountry}
          tutor={item?.tutor}
          fileObjectsLicenseFront={item?.driverLicenseFront ? [item?.driverLicenseFront] : []}
          setFileObjectsLicenseFront={() => {}}
          fileObjectsLicenseBack={item?.driverLicenseBack ? [item?.driverLicenseBack] : []}
          setFileObjectsLicenseBack={() => {}}
          fileObjectsMvr={item?.MVR ? [item?.MVR] : []}
          setFileObjectsMvr={() => {}}
          fileObjectsCaDriverAbstract={item?.caDriverAbstract ? [item?.caDriverAbstract] : []}
          setFileObjectsCaDriverAbstract={() => {}}
          fileObjectsMedicalForm={item?.medicalLongForm ? [item?.medicalLongForm] : []}
          setFileObjectsMedicalForm={() => {}}
          fileObjectsAdditionalForm={item?.additionalDocument?.[0] ? item?.additionalDocument : []}
          setFileObjectsAdditionalForm={() => {}}
          fileObjectsOtherDocuments={item?.otherDocuments?.[0] ? item?.otherDocuments : []}
          setFileObjectsOtherDocuments={() => {}}
          isViewMode={true}
        />
      </Popup>
    </>
  );
};

export default ModalLicenseUploadForm;
