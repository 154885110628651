import { useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "../firebase";
import useUsersState from "../Context/actions/useUsersState";
import { useNavigate } from "react-router-dom";
import { AGENT_COMPANY_PROFILE_ROUTE } from "../utils/routes";
import { doc, onSnapshot } from "firebase/firestore";

const useInitialSetting = () => {
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [noProfile, setNoProfile] = useState(false);
  const [waitRedirections, setWaitRedirections] = useState(true)
  const { getUser } = useUsersState()
  const navigate = useNavigate()

  useEffect(() => {
    let unsubscribeUser = null;
  
    const fetchUserData = user => {
      const userRef = doc(db, "users", user?.uid);
      unsubscribeUser = onSnapshot(userRef, (docSnap) => {
          setWaitRedirections(false);
          if (docSnap.exists) {
            setUserInfo(docSnap.data());
            getUser(user?.id, docSnap.data())
            setNoProfile(!docSnap.data().first);
            if (!docSnap.data().agencyID) {
              navigate(AGENT_COMPANY_PROFILE_ROUTE);
            }
          } else {
            setNoProfile(true);
          }
        }, error => {
          console.error("Error fetching user data: ", error);
          setNoProfile(true);
        });
    };
  
    const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchUserData(user);
      } else {
        setWaitRedirections(false);
        console.error("User not authenticated.");
        if (unsubscribeUser) {
          unsubscribeUser();
        }
      }
    });
  
    return () => {
      unsubscribeAuth();
      if (unsubscribeUser) {
        unsubscribeUser();
      }
    };
  }, []);

  return {
    userInfo,
    setLoading,
    noProfile,
    setNoProfile,
    waitRedirections,
    loading
  };
};

export default useInitialSetting;
