import React, { useState } from "react";
import Breadcrumbs from "../../Components/assets/Breadcrumbs";
import { ACCOUNT_ROUTE } from "../../utils/routes";
import ProofOfFilingsList from "../../Components/ProofOfFilings/ProofOfFilingsList";

export default function ProofOfFilings({ userInfo, loading, setLoading }) {
  const breadcrumbs = [
    { label: "Home", link: ACCOUNT_ROUTE },
    { label: "Proof of filings" },
  ];

  return (
    <div className="my-4 max-w-[1165px] mx-auto bg-white rounded-md shadow-md p-6">
      <Breadcrumbs items={breadcrumbs} />
      <div className="flex flex-col md:flex-row md:items-center md:justify-between mt-1">
        <span className="text-3xl font-semibold mb-2 md:mb-0">
          Proof of filings
        </span>
      </div>
      <p className="mb-2">See your proof of filings here.</p>
      <ProofOfFilingsList />
    </div>
  );
}
