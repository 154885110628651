import NewSupportList from "../../../Components/support/NewSupportList";
import useTranslations from "../../../Context/actions/useTranslations";
import styles from "../../../assets/styles/pages/new-support-ticket";

function SupportTicketsList(props) {
  
  const { t } = useTranslations();

  return (
    <main className={styles.fullWrapper}>
      <div className={styles.titleContainer}>
        <h1 className="title-1">{t("support-ticket.title")}</h1>
      </div>
        <NewSupportList userInfo={props.userInfo} />
    </main>
  );
}

export default SupportTicketsList;
