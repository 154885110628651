import { TableHead, TableRow, TableSortLabel, TableCell } from "@mui/material";
import React, { useState } from "react";

export default function CustomTableHead({
  sortField,
  setSortField,
  sortDirection,
  setSortDirection,
}) {
  const handleSort = (field) => {
    if (sortField === field) {
      // If the field is already the current sort field, toggle the direction
      setSortDirection((prevDirection) =>
        prevDirection === "asc" ? "desc" : "asc"
      );
    } else {
      // If clicking on a new field, set it as the sort field and default direction to ascending
      setSortField(field);
      setSortDirection("asc");
    }
  };
  return (
    <TableHead>
      <TableRow sx={{ backgroundColor: "#072a48" }}>
        <TableCell>
          <TableSortLabel
            active={sortField === "status"}
            direction={sortDirection}
            onClick={() => handleSort("status")}
          >
            Status
          </TableSortLabel>
        </TableCell>
        <TableCell>
          <TableSortLabel
            active={sortField === "endoNumber"}
            direction={sortDirection}
            onClick={() => handleSort("endorsementNumber")}
          >
            Endo No.
          </TableSortLabel>
        </TableCell>
        <TableCell>
          <TableSortLabel
            active={sortField === "namedInsured"}
            direction={sortDirection}
            onClick={() => handleSort("namedInsured")}
          >
            Named Insured
          </TableSortLabel>
        </TableCell>
        <TableCell>
          <TableSortLabel
            active={sortField === "endoType"}
            direction={sortDirection}
            onClick={() => handleSort("type")}
          >
            Endo Type
          </TableSortLabel>
        </TableCell>
        <TableCell>
          <TableSortLabel
            active={sortField === "effectiveDate"}
            direction={sortDirection}
            onClick={() => handleSort("endoEffectiveDate")}
          >
            Effective Date
          </TableSortLabel>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}
