import React, { useState, useEffect } from "react";
import { t } from "i18next";
import Checkbox from "./Checkbox";
import { doc, updateDoc, getDoc, onSnapshot } from "firebase/firestore";
import Breadcrumbs from "../../assets/Breadcrumbs";
import { db } from "../../../firebase";
import { debounce } from "lodash";
import NamedShippers from "./NamedShippers";
import KPBrokersInfo from "../KPBrokersInfo";
import EditDropdownModal from "./EditDropdownModal"; // Import the modal component
import { Button } from "@mui/material";
import { numberWithCommas } from "../../../utils/helpers";

export default function MTC({
  policyID,
  formData,
  setFormData,
  isAdminOrUnderwriter,
  loadedPolicies,
  policies,
  formSaved,
  setFormSaved,
  userInfo,
}) {
  const [enableEditing, setEnableEditing] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalField, setModalField] = useState("");
  const [dropdownType, setDropdownType] = useState("");
  const [dropdownOptions, setDropdownOptions] = useState({});

  // realtime listener to get dropdown options
  useEffect(() => {
    const unsubscribe = onSnapshot(
      doc(db, "dropdownData", "mtcDropdowns"),
      (doc) => {
        if (doc.exists()) {
          setDropdownOptions(doc.data());
          console.log(doc.data());
        } else {
          console.log("No dropdown options found.");
        }
      }
    );

    return () => unsubscribe();
  }, []);

  const breadcrumbs = [
    { label: formData.documentType },
    { label: "Motor Truck Cargo" },
  ];

  const disabledRule =
    formData.documentType !== "Quote Request" &&
    formData.documentType !== "Application";

  const handleOpenModal = (field, type) => {
    setModalField(field);
    setDropdownType(type);
    setModalOpen(true);
  };

  return (
    <div>
      <Breadcrumbs items={breadcrumbs} />
      <div className="flex flex-col md:flex-row md:items-center md:justify-between mt-1">
        <span className="text-3xl font-semibold mb-4 md:mb-0">
          Motor Truck Cargo
        </span>
      </div>

      <KPBrokersInfo
        activePolicies={policies.activeMTCPolicies}
        userInfo={userInfo}
      />

      {loadedPolicies && !(policies.activeMTCPolicies.length > 0) && (
        <>
          {!formData?.hasMTCCoverage ? (
            <p className="mb-4">
              Add MTC to the {formData.documentType} to get started.
            </p>
          ) : (
            <fieldset>
              <h1 className="text-lg">Deductible</h1>
              <label
                htmlFor={`select-target-deductible`}
                className="block text-sm font-medium text-gray-700"
              >
                Target Deductible
              </label>
              <select
                disabled={disabledRule}
                id={`select-target-deductible`}
                onChange={async (e) => {
                  const mtcDetails = {
                    ...(formData.mtcDetails || {}),
                    targetDeductible: e.target.value,
                  };

                  setFormData({
                    ...formData,
                    mtcDetails,
                  });
                  const policyRef = doc(db, "policy-applications", policyID);
                  await updateDoc(policyRef, {
                    mtcDetails,
                  });
                }}
                value={formData?.mtcDetails?.targetDeductible}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300 sm:text-sm"
              >
                <option value="">Choose a Deductible</option>
                {dropdownOptions?.targetDeductibles?.slice()
                  .sort((a, b) => a - b)
                  .map((deductible) => (
                    <option key={deductible} value={deductible}>
                      ${numberWithCommas(deductible)}
                    </option>
                  ))}
              </select>
              {isAdminOrUnderwriter && (
                <Button
                  sx={{ fontSize: "10px" }}
                  onClick={() =>
                    handleOpenModal("targetDeductibles", "mtcDropdowns")
                  }
                >
                  Edit Options
                </Button>
              )}

              <h1 className="text-lg mt-3">Limits</h1>
              <label
                htmlFor={`anyOneLossLimit-select-anwone-loss`}
                className="block text-sm font-medium text-gray-700"
              >
                Target Any One Loss Limit
              </label>
              <select
                disabled={disabledRule}
                id={`anyOneLossLimit-select-anwone-loss`}
                onChange={async (e) => {
                  const mtcDetails = {
                    ...(formData.mtcDetails || {}),
                    anyOneLossLimit: e.target.value,
                  };

                  setFormData({
                    ...formData,
                    mtcDetails,
                  });
                  const policyRef = doc(db, "policy-applications", policyID);
                  await updateDoc(policyRef, {
                    mtcDetails,
                  });
                }}
                value={formData?.mtcDetails?.anyOneLossLimit}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300 sm:text-sm"
              >
                <option value="">Choose A Loss Limit</option>
                {dropdownOptions?.anyOneLossLimits?.slice()
                  .sort((a, b) => a - b)
                  .map((limit) => (
                    <option key={limit} value={limit}>
                      ${numberWithCommas(limit)}
                    </option>
                  ))}
              </select>
              {isAdminOrUnderwriter && (
                <Button
                  sx={{ fontSize: "10px" }}
                  onClick={() =>
                    handleOpenModal("anyOneLossLimits", "mtcDropdowns")
                  }
                >
                  Edit Options
                </Button>
              )}

              {!formData.hasAPDCoverage && (
                <>
                  <h1 className="text-lg">Non Standard Clauses</h1>
                  <Checkbox
                    disabled={disabledRule}
                    label={t("Attached Trailer Interchange")}
                    field={"trailerInterchangeTarget"}
                    policyID={policyID}
                    setFormData={setFormData}
                    formData={formData}
                    defaultToTrue={true}
                  />
                  {formData?.apdDetails?.trailerInterchangeTarget && (
                    <div className="mb-3">
                      <label
                        htmlFor={`trailerInterchangeTarget-select`}
                        className="ml-2"
                      >
                        Select a target limit:
                      </label>
                      <select
                        disabled={disabledRule}
                        id={`trailerInterchangeTarget-select`}
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300 sm:text-sm"
                        value={formData?.apdDetails?.trailerInterchangeLimit}
                        onChange={async (e) => {
                          const apdDetails = {
                            ...(formData.apdDetails || {}),
                            trailerInterchangeLimit: e.target.value,
                          };
                          setFormData({
                            ...formData,
                            apdDetails,
                          });
                          const policyRef = doc(
                            db,
                            "policy-applications",
                            policyID
                          );
                          await updateDoc(policyRef, {
                            apdDetails,
                          });
                        }}
                      >
                        <option value="">Choose One</option>
                        {dropdownOptions?.trailerInterchangeLimits?.slice()
                          .sort((a, b) => a - b)
                          .map((limit) => (
                            <option key={limit} value={limit}>
                              ${numberWithCommas(limit)}
                            </option>
                          ))}
                      </select>

                      <label
                        htmlFor={`trailerInterchangeTarget-select`}
                        className="mt-3 ml-2"
                      >
                        Briefly Describe Exposure:
                      </label>
                      <input
                        disabled={disabledRule}
                        id={"tiExposure"}
                        type="text"
                        className="w-full rounded p-2"
                        placeholder={t("Example: 15 trailers full time")}
                        onChange={async (e) => {
                          const debouncedUpdateField = debounce(async (e) => {
                            const apdDetails = {
                              ...(formData.apdDetails || {}),
                              tiExposure: e.target.value,
                            };
                            setFormData({
                              ...formData,
                              apdDetails,
                            });
                            const policyRef = doc(
                              db,
                              "policy-applications",
                              policyID
                            );
                            await updateDoc(policyRef, {
                              apdDetails,
                            });
                          }, 300);
                          debouncedUpdateField(e);
                        }}
                      />
                    </div>
                  )}
                </>
              )}
              <div className="mt-3">
              <NamedShippers
                formData={formData}
                setFormData={setFormData}
                policyID={policyID}
                formSaved={formSaved}
                setFormSaved={setFormSaved}
              /></div>
            </fieldset>
          )}
        </>
      )}

      {/* Add the modal here */}
      <EditDropdownModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        field={modalField}
        dropdownType={dropdownType}
      />
    </div>
  );
}
