import React from "react";
import TextInput from "../assets/forms/TextInput";
import MyCheckbox from "../assets/forms/Checkbox";

export default function CancellationReasons({
  cancellationReasons,
  setCancellationReasons,
}) {
  const customClass =
    "appearance-none block w-full bg-white border border-gray-300 rounded-md py-3 px-3 leading-tight focus:outline-none focus:shadow-outline-blue focus:border-blue-300";
  const labelCustomClass = "text-md d-block";

  const reasonsList = {
    nonPayment: false,
    other: false,
    otherReason: "",
  };

  return (
    <>
      <div className="mt-2">
        <label className={labelCustomClass}>Cancellation Reason(s)</label>
        <MyCheckbox
          label={"Non-Payment of Premium"}
          field={"nonPaymentOfPremium"}
          setFormData={setCancellationReasons}
          formData={cancellationReasons}
        />
      </div>
      <div>
        <MyCheckbox
          label={"Requested by Insured"}
          field={"requestedByInsured"}
          setFormData={setCancellationReasons}
          formData={cancellationReasons}
        />
      </div>
      <div>
        <MyCheckbox
          label={"Non-Compliance of Claims Reporting"}
          field={"noncomplianceOfClaimsReporting"}
          setFormData={setCancellationReasons}
          formData={cancellationReasons}
        />
      </div>
      <div>
        <MyCheckbox
          label={"Failure to Aid in the Claims Process"}
          field={"failureToAidInTheClaimsProcess"}
          setFormData={setCancellationReasons}
          formData={cancellationReasons}
        />
      </div>
      <div>
        <MyCheckbox
          label={"Material Change in Risk: Safety Personnel"}
          field={"materialChangeInRiskSafetyPersonnel"}
          setFormData={setCancellationReasons}
          formData={cancellationReasons}
        />
      </div>
      <div>
        <MyCheckbox
          label={"Material Change in Risk: Other"}
          field={"materialChangeInRiskOther"}
          setFormData={setCancellationReasons}
          formData={cancellationReasons}
        />
      </div>
      {cancellationReasons.materialChangeInRiskOtherl && (
        <TextInput
          label={"Material Change in Risk:"}
          required={true}
          saveForm={() => {}}
          property={"materialChangeInRiskOtherReason"}
          setFormData={setCancellationReasons}
          formData={cancellationReasons}
          setFormSaved={() => {}}
          doNotDebounce={true}
          customClass={customClass}
          labelCustomClass={labelCustomClass}
        />
      )}
      <div>
        <MyCheckbox
          label={"Other"}
          field={"other"}
          setFormData={setCancellationReasons}
          formData={cancellationReasons}
        />
      </div>
      {cancellationReasons.other && (
        <TextInput
          label={"Other Reason:"}
          required={true}
          saveForm={() => {}}
          property={"otherReason"}
          setFormData={setCancellationReasons}
          formData={cancellationReasons}
          setFormSaved={() => {}}
          doNotDebounce={true}
          customClass={customClass}
          labelCustomClass={labelCustomClass}
        />
      )}
    </>
  );
}
