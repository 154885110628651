import React from "react";
import Checkbox from "../assets/forms/Checkbox";
import { Grid } from "@mui/material";
import useTranslations from "../../Context/actions/useTranslations";

export default function StatusOptions({ currentFilters, setCurrentFilters, options }) {

  const { language } = useTranslations()

  return (
    <Grid container spacing={0}>
      {options.map((checkbox, index) => {
        const realLabel = checkbox.lang[language || 'en']
        return (
          <Grid item xs={6} sm={3} key={index}>
            <Checkbox
              label={realLabel}
              field={checkbox.field}
              setFormData={setCurrentFilters}
              formData={currentFilters}
            />
          </Grid>
        )
      })}
    </Grid>
  );
}
