import { createContext, useContext, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import { auth } from "../firebase";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../firebase";

const UserContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const createUserDocument = async (
    first,
    last,
    email,
    phone,
    company,
    title
  ) => {
    try {
      const docRef = await addDoc(collection(db, "users"), {
        email: email,
        first: first,
        last: last,
        phone: phone,
        company: company,
        title: title,
        status: "Unappointed",
        role: "Agent",
      });
      console.log("Document written with ID: ", docRef.id);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  const createUser = async (email, password) => {
    try {
      const userCredentials = await createUserWithEmailAndPassword(auth, email, password);
      return userCredentials
    } catch (error) {
      throw error
    }
  };

  const signIn = async (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);
  };

  const logout = () => {
    return signOut(auth);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <UserContext.Provider
      value={{ createUser, user, logout, signIn, createUserDocument }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(UserContext);
};
