import {
  doc,
  runTransaction,
} from "firebase/firestore";
import { auth, db } from "../../../../firebase";
import updateSystemLog from "../../../utils/updateSystemLog";
import endoStatusChangeNotification from "../../../utils/notifications/endoStatusChangeNotification";
import { setPolicyInfo, setPolicyInfoForEndorsement } from "../../Drivers/setPolicyInfo";
import { selectIfNewOrExistingSubcollection, validateIfExists } from "../../../utils/validateIfBlacklisted";
import { multipleToEndorsements } from "../../Drivers/multipleToEndorsements";

const submitAddTrailerEndorsement = async (
  e,
  policyID,
  trailerDataToVerify,
  insuredInfo,
  userInfo,
  setSuccess,
  setTrailerData,
  endorsementEffectiveDate,
  formData,
  goBack,
  isEndoManager,
  getEndorsements,
  documentTypeToUse,
  renewalProcess,
  trailerList
) => {
  e?.preventDefault();

  const { agencyID, insuredID } = formData;

  const data = await validateIfExists(
    [trailerDataToVerify],
    agencyID,
    insuredID,
    "trailers"
  );

  const trailerData = data?.[0];

  let endoEffectiveDate;
  if (documentTypeToUse === "Policy" || renewalProcess) {
    endoEffectiveDate = endorsementEffectiveDate;
  } else {
    endoEffectiveDate = "";
  }
  if (trailerData.trailerVIN.length !== 17)
    return alert("VIN must be 17 characters.");
  if (trailerData.trailerYear.length !== 4)
    return alert("Year must be 4 characters.");
  if (trailerData.trailerACV === "0")
    return alert("Actual Cash Value must be greater than $0.00");

  const foundTrailer = trailerList.find(
    (trailer) => trailer.trailerVIN === trailerData.trailerVIN
  );

  if (foundTrailer?.id && !renewalProcess && documentTypeToUse === "Policy") {
    goBack(e);
    return alert(
      `Trailer with VIN ${trailerData.trailerVIN} already exists in the policy.`
    );
  } else {
    const { agencyID, insuredID } = formData
    const payloadResult = setPolicyInfo(
      {
        ...trailerData,
        dateCreated: new Date(),
      }, {
        ...formData,
        policyEffectiveDate: formData?.effectiveDate,
        glPolicyNo: formData?.glPolicyNo,
        alPolicyNo: formData?.alPolicyNo,
        policyID,
        status: !renewalProcess && documentTypeToUse === "Application" ? 'Active' : 'Pending',
        documentType: documentTypeToUse,
        documentSource:
          documentTypeToUse === "Policy" ?
          "Endorsement" : "Policy",
        policyStatus: formData?.status,
        agencyID,
        insuredID
      },
      !renewalProcess && documentTypeToUse === "Application" ? 'direct-addition' : 'endo-addition',
      true
    )
    
    const response = await selectIfNewOrExistingSubcollection(
      payloadResult,
      agencyID,
      insuredID,
      "trailers"
    )

    if (documentTypeToUse === "Policy") {
      const countersDocRef = doc(db, "unique-numbers", "endorsements"); 
      await runTransaction(db, async (transaction) => {
        const countersDoc = await transaction.get(countersDocRef);

        const currentCounter = countersDoc.data().lastCount;
        const newCounter = currentCounter + 1;
        transaction.update(countersDocRef, {
          lastCount: newCounter,
        });

        const type = "Add Trailers"
        const status = "Submitted"

        const payload = {
          ...payloadResult,
          id: response.id,
        }

        const payloadForEndo = setPolicyInfoForEndorsement(payload, policyID)

        await multipleToEndorsements(
          policyID,
          endoEffectiveDate,
          type,
          [payloadForEndo],
          insuredInfo,
          formData,
          userInfo,
          getEndorsements,
          [],
          null,
          null,
          null
        );

        if (userInfo) {
          endoStatusChangeNotification(
            type,
            newCounter,
            status,
            '',
            policyID,
            userInfo?.agencyID
          );
        }
        setTrailerData({});
        if (isEndoManager) {
          await getEndorsements()
        }
        setSuccess(true);
      });
      updateSystemLog(
        auth,
        policyID,
        "Add Trailer Endorsement (" +
          trailerData.trailerVIN +
          ") Submitted For " +
          insuredInfo.company +
          " by " +
          auth.currentUser.displayName,
        "Add Trailer Endorsement",
        trailerData
      );
    }

    updateSystemLog(
      auth,
      policyID,
      "Trailer (" +
        trailerData.trailerVIN +
        ") Added To Application For " +
        insuredInfo.company +
        " by " +
        auth.currentUser.displayName,
      "Trailer Added",
      trailerData
    );
    setTrailerData({});
    goBack(e);
    return
  }
};

export default submitAddTrailerEndorsement;
