import React, { useState } from 'react';
import { Card, CardContent, Typography, Grid, TextField } from '@mui/material';

const CrashAndAccidents = () => {
  // Define questions
  const questions = [
    { id: 'accidentAnalysis', label: 'Accident analysis – Accident description, details on every accident to see if there is a pattern or maybe something we can come up with to PREVENT them in the future.', type: 'textarea' },
    { id: 'camerasInsideOutside', label: 'Cameras inside / outside:', type: 'text' },
    { id: 'cameraSystemUsed', label: 'What camera system do you use?', type: 'text' },
    { id: 'cameraNotificationReviewer', label: 'Who reviews the camera notifications?', type: 'text' },
    { id: 'postAccidentProcess', label: 'Please provide documentation of the exact “post-accident” process.', type: 'textarea' },
    { id: 'drugTestAfterAccident', label: 'Once the driver is fine… Do you drug test them?', type: 'text' },
    { id: 'driverReturn', label: 'Does the driver go back?', type: 'text' },
    { id: 'circumstancesForAnotherDriver', label: 'Under what circumstances do you not allow the driver to drive back and will send another driver to pick him up?', type: 'textarea' },
    { id: 'questionsAskedWhenBack', label: 'What type of questions do you ask once he is back?', type: 'textarea' },
    { id: 'authorizationForDriver', label: 'Do they ask for authorization from somebody to get that driver on the road?', type: 'text' },
    { id: 'damagedEquipment', label: 'What do you do with your damaged or totaled equipment?', type: 'textarea' },
    { id: 'salvageTitlesRule', label: 'Do you have a Rule for Salvage titles?', type: 'text' },
    { id: 'accidentCoaching', label: 'Who coaches drivers after an accident?', type: 'text' },
    { id: 'trainingProgramDocument', label: 'Do you have a document that outlines a training program?', type: 'text' },
    { id: 'trainingType', label: 'What kind of training do you use?', type: 'textarea' },
    { id: 'disciplinaryActionGrounds', label: 'What are grounds for disciplinary action related to accidents?', type: 'textarea' },
    { id: 'immediateTermination', label: 'What would you consider grounds for immediate termination?', type: 'textarea' },
    { id: 'driverNegligenceSamples', label: 'What are the exact samples that would be considered driver negligence?', type: 'textarea' },
    { id: 'negligenceDisciplinaryActions', label: 'What are the disciplinary actions?', type: 'textarea' },
    { id: 'accidentInvestigation', label: 'Do you investigate accidents to prevent them?', type: 'text' },
    { id: 'accidentRecords', label: 'Keep record and evidence of all accidents, no matter severity.', type: 'textarea' },
    { id: 'preventableNonPreventableData', label: 'Non preventable and preventable. Data Q.', type: 'textarea' },
  ];

  // State to manage responses
  const [responses, setResponses] = useState(
    questions.reduce((acc, { id }) => ({ ...acc, [id]: '' }), {}) // Initialize state dynamically based on questions
  );

  const handleInputChange = (id, value) => {
    setResponses((prevResponses) => ({
      ...prevResponses,
      [id]: value,
    }));
  };

  return (
    <Card sx={{ maxWidth: 800, margin: 'auto', padding: 2, marginBottom: 2 }}>
      <CardContent>
        <Typography variant="h5" component="div" gutterBottom>
          Crash and Accidents
        </Typography>
        <Grid container spacing={2}>
          {questions.map(({ id, label, type }) => (
            <Grid item xs={12} key={id}>
              <TextField
                fullWidth
                multiline={type === 'textarea'}
                rows={type === 'textarea' ? 4 : 1}
                variant="outlined"
                label={label}
                value={responses[id] || ''}
                onChange={(e) => handleInputChange(id, e.target.value)}
              />
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CrashAndAccidents;

