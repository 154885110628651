import { addDoc, collection, doc, runTransaction } from "firebase/firestore";
import { auth, db } from "../../firebase";

export const cancelPolicyFn = async (
  event,
  cancellationData,
  formData,
  insuredInfo,
  policyID,
  setLoading,
  goBack
) => {
  try {
    if (event && event?.preventDefault) event.preventDefault();
    setLoading(true);
    await runTransaction(db, async (transaction) => {
      const countersDocRef = doc(db, "unique-numbers", "endorsements");
      const countersDoc = await transaction.get(countersDocRef);
      const currentCounter = countersDoc.data().lastCount;
      const newCounter = currentCounter + 1;
      transaction.update(countersDocRef, {
        lastCount: newCounter,
      });
      const payload = {
        endorsementNumber: String(newCounter),
        endoEffectiveDate: cancellationData.cancellationDate,
        policyID,
        type: "Cancellation",
        dateCreated: new Date(),
        status: "Submitted",
        author: auth.currentUser.displayName,
        authorID: auth.currentUser.uid,
        namedInsured: insuredInfo.company,
        policyEffectiveDate: formData.effectiveDate,
        alPolicyNo: formData.alPolicyNo || "",
        agencyID: formData.agencyID,
        insuredID: formData.insuredID,
        documentSource: "Endorsement",
        cancellationReasons: ["Cancellation requested by the insured"],
      };
      await addDoc(
        collection(db, "policy-applications", policyID, "endorsements"),
        payload
      );
      setLoading(false);
      goBack();
    });
  } catch (error) {
    setLoading(false);
    console.error("Error submitting cancellation request:", error);
  }
};
