import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import timezone from "moment-timezone";
import FormPopup from "../../Components/generals/Popup/FormPopup";
import useGeneralState from "../../Context/actions/useGeneralState";
import { db } from "../../firebase";
import { addDoc, collection, onSnapshot, query, where } from "firebase/firestore";
import { calendarEventsEmails } from "./utils";
import useUsersState from "../../Context/actions/useUsersState";

const CalendarRoute = () => {

  const { controlToast } = useGeneralState();
  const localizer = momentLocalizer(moment);

  const [valuesInModal, setValuesInModal] = useState({
    selectedStartTime: "",
    selectedEndTime: "",
    selectedTimezone: "",
    resourceId: "",
  });

  const [dates, setDates] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });

  const [openNewEventModal, setOpenNewEventModal] = useState(false);

  const eventPropGetter = (event) => {
    const color =
      event.automatic ? '#8B0000' :
      (event.color || '#3174ad');

    const backgroundColor = color
    const borderColor = color

    return { style: {
      backgroundColor,
      borderColor
    } };
  };

  const handleParseDate = (date) => {
    const originalMoment = moment(date);
    const monthName = originalMoment.format("MMMM");
    const dayOfMonth = originalMoment.format("Do");
    const year = originalMoment.format("YYYY");
    const hour = originalMoment.format("h");
    const minute = originalMoment.format("mm");
    const amPm = originalMoment.format("a");
    const formattedDateStr = `${monthName} ${dayOfMonth} ${year} at ${hour}:${minute}${amPm}`;
    return formattedDateStr;
  };

  const handleShowDate = (timestamp) => {
    return new Date(timestamp * 1000);
  };

  const components = {
    events: ({ event }) => {
      const data = event?.data;
      if (data?.appointment) {
        return (
          <div>
            <p>{data.appointment.location}</p>
            <p>{data.appointment.resource}</p>
            <p>{data.appointment.address}</p>
          </div>
        );
      } else if (data?.blockout) {
        return (
          <div>
            <p>{data.blockout.name}</p>
          </div>
        );
      } else {
        return (
          <div>
            <p>{event.title}</p>
            <p>{event.description}</p>
          </div>
        );
      }
    },
    timeSlotWrapper: ({ children, value, resource }) => {
      const handleClick = (e) => {
        e.preventDefault();
        const originalDate = moment(value);
        const modifiedDate = originalDate.add(1, "hour");
        const timeZone = timezone.tz.guess();
        setValuesInModal({
          selectedStartTime: value.toString(),
          selectedEndTime: modifiedDate.toDate().toString(),
          selectedTimezone: timeZone,
          resourceId: resource,
        });
        setOpenNewEventModal(true);
      };
      return <div onClick={handleClick}>{children}</div>;
    },
  };

  const { user } = useUsersState()
  const [events, setEvents] = useState([]);

  useEffect(() => {
    if (!user?.email) return;
    const eventsQuery = query(
      collection(db, "calendar-events"),
      where("users", "array-contains", user.email),
      where("status", "==", "Active")
    );
    const unsubscribe = onSnapshot(eventsQuery, (snapshot) => {
      const events = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }))
      const parsedEvents = events.map((event) => {
        const startDate = handleShowDate(event?.["start-date"]?.seconds);
        const endDate = handleShowDate(event?.["end-date"]?.seconds);
        return {
          title: event.summary,
          start: startDate,
          end: endDate,
          allDay: false,
          data: { appointment: { id: event.id } },
          resourceId: event.id,
          automatic: Boolean(event?.automatic),
        }
      });
      setEvents(parsedEvents);
    });
    return () => unsubscribe();
  }, [user?.email]);

  const [loading, setLoading] = useState(false);

  const handleNewEvent = async (e, value) => {
    try {
      e.preventDefault();
      setLoading(true);
      const eventData = {
        ...value,
        "start-date": dates.startDate,
        "end-date": dates.endDate,
        "users": calendarEventsEmails,
        "status": "Active"
      }
      await addDoc(collection(db, "calendar-events"), eventData);
      setLoading(false);
      setOpenNewEventModal(false);
      controlToast(true, "Calendar events created", "success");
    } catch (error) {
      throw error;
    }
  };

  const formSettingsNewEvent = {
    onSubmit: handleNewEvent,
    inputs: [
      {
        label: "Start date of new event",
        type: "just-text",
        name: "start-date",
        defaultValue: handleParseDate(valuesInModal?.selectedStartTime) || "",
      },
      {
        label: "End date of new event",
        type: "just-text",
        name: "end-date",
        defaultValue: handleParseDate(valuesInModal?.selectedEndTime) || "",
      },
      {
        label: "Timezone",
        name: "timezone",
        type: "just-text",
        defaultValue: valuesInModal?.selectedTimezone || "",
      },
      {
        label: "Summary",
        name: "summary",
        required: true,
        type: "text",
        defaultValue: "Cancel Insured",
      },
      {
        label: "Description",
        name: "description",
        required: true,
        type: "text",
      },
    ],
    buttonLabel: "Add New Event",
  };

  return (
    <div>
      <div className="mt-2">
        <p className="text-2xl font-bold text-[#072a48] mt-2 mb-4">Calendar</p>
      </div>
      <div className="bg-white p-3 rounded shadow text-[#072a48] relative">
        <FormPopup
          isOpen={openNewEventModal}
          formSettings={formSettingsNewEvent}
          onRequestClose={() => setOpenNewEventModal(false)}
          title="Calendar event to add"
          loading={loading}
        />
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          views={["month", "week", "day", "agenda"]}
          defaultView="week"
          style={{ height: 500, cursor: "pointer" }}
          components={components}
          selectable={true}
          step={30}
          timeslots={1}
          eventPropGetter={eventPropGetter}
          onSelectSlot={(slotInfo) => {
            const originalDate = moment(slotInfo.start);
            const modifiedDate = originalDate.add(1, "hour");
            const timeZone = timezone.tz.guess();
            setValuesInModal({
              selectedStartTime: slotInfo.start.toString(),
              selectedEndTime: modifiedDate.toDate().toString(),
              selectedTimezone: timeZone,
              resourceId: '',
            });
            setDates({
              startDate: slotInfo.start,
              endDate: modifiedDate.toDate(),
            });
            setOpenNewEventModal(true);
          }}
        />
      </div>
    </div>
  );
};

export default CalendarRoute;