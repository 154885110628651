import {
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import processDriverData from "../listeners/processDriverData";
import { db } from "../../../../firebase";
import config from "../../../../config/env";

const searchByPolicy = async (searchValue, insuredID, policyID) => {

  const { REACT_APP_LICONA_INSURANCE_GROUP_ID } = config
  const q = query(
    collection(db, "agencies", REACT_APP_LICONA_INSURANCE_GROUP_ID, "insureds", insuredID, "drivers"),
    where("policies", "array-contains", policyID)
  );

  const querySnapshot = await getDocs(q);
  const driversMap = processDriverData(querySnapshot);
  return driversMap;
};

export default searchByPolicy;
