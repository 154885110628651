import decodeVIN from "./decodeVIN";

const getTruckData = async (VIN, year) => {
  try {
    const results = await decodeVIN(VIN, year);

    const parseClassFromRawData = (inputString) => {
      if (!inputString) return
      const weightString = inputString.substring(0, 7);
      if (weightString === 'Class 1') {
        return 'Class I';
      } else if (weightString === 'Class 2') {
        return 'Class I';
      } else if (weightString === 'Class 3') {
        return 'Class II';
      } else if (weightString === 'Class 4') {
        return 'Class II';
      } else if (weightString === 'Class 5') {
        return 'Class II';
      } else if (weightString === 'Class 6') {
        return 'Class III';
      } else if (weightString === 'Class 7') {
        return 'Class III';
      } else if (weightString === 'Class 8') {
        return 'Class IV';
      } else {
        alert('Invalid weight class. Please try again. If the problem persists, please contact support.')
      }
    };

    function findValueByVariableId(variable, data) {
      const foundObject = data.find((obj) => obj.Variable === variable);
      return foundObject ? foundObject.Value : null;
    }
    
    const rawData = findValueByVariableId("Gross Vehicle Weight Rating From", results);
    const truckYear = findValueByVariableId("Model Year", results);
    const truckMake = findValueByVariableId("Make", results);
    const truckModel = findValueByVariableId("Model", results);
    const truckClass = parseClassFromRawData(rawData);

    let truckWeight;
    if (truckClass === 'Class I') {
      truckWeight = 'Up to 10,000 lbs';
    } else if (truckClass === 'Class II') {
      truckWeight = '10,001 to 19,500 lbs';
    } else if (truckClass === 'Class III') {
      truckWeight = '19,501 to 33,000 lbs';
    } else if (truckClass === 'Class IV') {
      truckWeight = '33,001+ lbs';
    } else {
      alert('Invalid weight class. Please try again. If the problem persists, please contact support.')
    }

    return {
      truckVIN: VIN,
      truckYear,
      truckMake,
      truckModel,
      truckClass,
      truckWeight,
    };
  } catch (error) {
    console.error("Error decoding VIN:", error);
  }
};

export default getTruckData;
