import React, { useEffect, useState } from "react";
import {
  getDocs,
  addDoc,
  collection,
  query,
  deleteDoc,
  doc,
  getDoc,
  orderBy,
} from "firebase/firestore";
import { db } from "../../firebase";
import "react-datepicker/dist/react-datepicker.css";
import "../styling/app.css";
import { getAuth } from "firebase/auth";
import { Table, Thead, Tbody, Tr, Td } from "react-super-responsive-table";
import useUsersState from "../../Context/actions/useUsersState";
import { confirmDeletePromptGlobal } from "../../layout/ConfirmDeleteProvider";

function LetterLog() {
  const auth = getAuth();
  const { user } = useUsersState();
  const userID = user.uid;
  const [letterRecipient, setLetterRecipient] = useState("");

  const submitLetter = async () => {
    await addDoc(collection(db, "letters"), {
      createdOn: new Date(),
      authorName: auth.currentUser.displayName,
      authorID: auth.currentUser.uid,
      letterRecipient,
      letterType,
    });
    getLetters();
    setDisplayNewLetter(false);
    setLetterRecipient("");
  };

  const deleteLetter = async (docID) => {
    let verify = await confirmDeletePromptGlobal(
      "You are about to delete an Letter! If you are sure, confirm the action"
    );
    if (verify === "DELETE") {
      await deleteDoc(doc(db, "letters", docID));
      getLetters();
    }
  };

  const [letterList, setLetterList] = useState([]);
  const [gotLetters, setGotLetters] = useState(false);
  const today = new Date();
  const todayInSeconds = today.getTime() / 1000;
  const getLetters = async () => {
    setGotLetters(false);

    const q = query(collection(db, "letters"), orderBy("createdOn"));
    const queryData = await getDocs(q);
    const queryResults = queryData.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    setLetterList(queryResults.reverse());
    setGotLetters(true);
  };

  const [displayNewLetter, setDisplayNewLetter] = useState(false);

  const convertDate = (secs) => {
    const output = new Date(secs * 1000);
    return output.toDateString();
  };

  const convertDateLong = (secs) => {
    const output = new Date(secs * 1000);
    return output.toString();
  };

  const [letterType, setLetterType] = useState();

  useEffect(() => {
    if (gotLetters === false) getLetters();
  }, [userID, gotLetters, user.role]);

  return (
    <>
      <main className="max-w-[800px] mx-auto">
        <div className="my-2">
          <a href="/filinglog" className="text-[#072a48]">
            Filing Log
          </a>
          {" | "}
          <a href="/systemlog" className="text-[#072a48]">
            System Log
          </a>
        </div>
        <h1 className="text-[#072a48] text-2xl font-bold mb-2">Letters</h1>

        <section className="bg-gray-200 p-3 rounded-md mb-3">
          <h2 className="text-xl font-medium mb-2">New Letter Log Entry</h2>
          <form>
            <div className="flex items-center mb-4">
              <select
                value={letterType}
                onChange={(e) => setLetterType(e.target.value)}
                className="form-control w-full"
              >
                <option value="">Letter Type</option>
                <option value="NOC">Notice of Cancellation</option>
                <option value="NOR">Notice of Reinstatement</option>
                <option value="PNONR">Provisional Notice of Non-Renewal</option>
              </select>
            </div>
            <input
              type="text"
              placeholder="Letter Recipient"
              value={letterRecipient}
              onChange={(e) => setLetterRecipient(e.target.value)}
              className="text-input w-full"
            />
          </form>
          <button
            onClick={() => {
              setLetterRecipient("");
              setDisplayNewLetter(false);
            }}
            className="button-1"
          >
            Cancel
          </button>
          <button onClick={submitLetter} className="button-2">
            Submit
          </button>
        </section>

        <p className="mb-3">
          This log was created on 06/06/2023 and does not include letters sent
          prior to that date.
        </p>
        <Table className="data-table w-full">
          <Thead>
            <Tr className="data-table-header">
              <Td>Log Date</Td>
              <Td>Type</Td>
              <Td>Letter Recipient</Td>
              <Td>Author</Td>
              {user.role === "Admin" && <Td>Actions</Td>}
            </Tr>
          </Thead>
          <Tbody>
            {letterList.map((letter, i) => {
              return (
                <Tr
                  key={i}
                  title={convertDateLong(letter.createdOn.seconds)}
                  className="mb-2"
                >
                  <Td>{convertDate(letter.createdOn.seconds)}</Td>
                  <Td>{letter.letterType}</Td>
                  <Td>{letter.letterRecipient}</Td>
                  <Td>{letter.authorName}</Td>
                  {user.role === "Admin" && (
                    <Td>
                      <button
                        onClick={(e) => deleteLetter(letter.id)}
                        className="text-[#072a48]"
                      >
                        Delete
                      </button>
                    </Td>
                  )}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </main>
    </>
  );
}

export default LetterLog;
