import React, { useState } from 'react';
import { Card, CardContent, Typography, Grid, TextField } from '@mui/material';

const Tools = ({ handleInputChange }) => {
  const questions = [
    { id: 'jjKellerTraining', label: 'Have your drivers taken the JJ Keller training?', type: 'text' },
    { id: 'youtubeVideos', label: 'Make sure to document what source they came from or what entity is doing the training.', type: 'textarea' },
    { id: 'centralAnalysisBureau', label: 'How often do you use it?', type: 'text' },
    { id: 'centralAnalysisBureauUsage', label: 'What do you use it for?', type: 'textarea' },
    { id: 'preEmploymentScreeningProgram', label: 'Do you use it before hiring a driver?', type: 'text' },
    { id: 'motorVehicleReport', label: 'Do you check the driver\'s history?', type: 'text' },
    { id: 'sctVerification', label: 'Do you verify Mexican licenses?', type: 'text' },
    { id: 'cvsaKnowledge', label: 'Do you know what CVSA is?', type: 'text' },
    { id: 'cvsaSticker', label: 'Have you ever got a CVSA sticker?', type: 'text' },
    { id: 'cvsaUnderstanding', label: 'Do you know how it works?', type: 'text' },
    { id: 'dataQAccount', label: 'Create an account?', type: 'text' },
    { id: 'ctpatCertification', label: 'Certified?', type: 'text' },
    { id: 'documentLawsuit', label: 'Document going into a lawsuit.', type: 'textarea' },
    { id: 'vehicleInspectionChecklist', label: 'Vehicle Inspection Checklist', type: 'textarea' },
  ];

  // State to manage responses
  const [responses, setResponses] = useState(
    questions.reduce((acc, { id }) => ({ ...acc, [id]: '' }), {}) // Initialize state dynamically based on questions
  );
  return (
    <Card sx={{ maxWidth: 800, margin: 'auto', padding: 2, marginBottom: 2 }}>
      <CardContent>
        <Typography variant="h5" component="div" gutterBottom>
          Tools
        </Typography>
        <Grid container spacing={2}>
          {questions.map(({ id, label, type }) => (
            <Grid item xs={12} key={id}>
              <TextField
                fullWidth
                multiline={type === 'textarea'}
                rows={type === 'textarea' ? 4 : 1}
                variant="outlined"
                label={label}
                value={responses[id] || ''}
                onChange={(e) => handleInputChange(id, e.target.value)}
              />
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Tools;
