import { IconButton } from "@mui/material";
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';

const SendLetterIcon = ({ fn, className, disabled = false, thereIsDownloadButton }) => {
  return (
    <IconButton
      onClick={fn}
      disabled={disabled}
      className={className}
      style={{
        position: 'absolute', 
        top: 20, 
        right: thereIsDownloadButton ? 70 : 20,
        color: "#072a48",
        height: 50,
        width: 50,  
      }}
    >
      <HistoryEduIcon />
    </IconButton>
  );
};

export default SendLetterIcon;
