function hasDuplicates(array, index) {
  var valuesSoFar = [];
  for (var i = 0; i < array.length; ++i) {
      var value = array[i][index];
      if (valuesSoFar.indexOf(value) !== -1) {
          return true;
      }
      valuesSoFar.push(value);
  }
  return false;
}

export default hasDuplicates;