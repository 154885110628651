import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../../firebase";

const handleSave = async (
  policyID,
  noteID,
  notesList,
  editedContent,
  editedSubject,
  setEditedContent,
  setEditMode,
  setEditedSubject,
  collectionPath
) => {
  const originalNote = notesList.find((note) => note.id === noteID);
  const updatedNote = {
    ...originalNote,
    note: editedContent[noteID] || originalNote.note,
    subject: editedSubject[noteID] || originalNote.subject,
  };
  await updateDoc(
    doc(db, collectionPath, noteID),
    updatedNote
  );
  setEditMode((prevMode) => ({
    ...prevMode,
    [noteID]: false,
  }));
  setEditedContent((prevContent) => ({
    ...prevContent,
    [noteID]: undefined,
  }));
  setEditedSubject((prevSubject) => ({
    ...prevSubject,
    [noteID]: undefined,
  }));
};

export default handleSave;
