import React from "react";
import useTranslations from "../../../../Context/actions/useTranslations";

export default function AgentAtAGlance(props) {

  const { t } = useTranslations()

  return (
    <div>
      <div className="card mb-2 shadow">
        <div className="card-header bg-[#116582]">
          <h2 className="font-medium text-white">{t('account.agent-at-a-glance.submissions-summary')}</h2>
        </div>
        <div className="card-body py-2">
          <h1 className="text-center">
            <span className="text-2xl mr-2">
            {props.incompleteSubmissionCount}
            </span>
            {t('account.agent-at-a-glance.incomplete-business-submissions')}
          </h1>
        </div>
        <div className="card-footer">
          <h4 className="text-[#8B0000] font-medium">
          {props.pendingQuoteRequestCount} {t('account.agent-at-a-glance.pending')}
            <span className="float-right text-green-900">
              {props.approvedSubmissions} {t('account.agent-at-a-glance.approved')}
            </span>
          </h4>
        </div>
      </div>
      <div className="card mb-2 shadow">
        <div className="card-header bg-[#072a48]">
          <h2 className="font-medium text-white">{t('account.agent-at-a-glance.business-written')}</h2>
        </div>
        <div className="card-body py-2">
          <h1 className="text-center">
            <span className="text-2xl mr-2">
              $0
            </span>
            {t('account.agent-at-a-glance.written')}
          </h1>
        </div>
        <div className="card-footer">
          <h4 className="text-green-900 text-center font-medium">
             $1,000,000 {t('account.agent-at-a-glance.until-tier')}
          </h4>
        </div>
      </div>
      <div className="card mb-4 shadow">
        <div className="card-header bg-[#8B0000]">
          <h2 className="font-medium text-white">{t('account.agent-at-a-glance.business-composition')}</h2>
        </div>
        <div className="card-body py-2">
          <h1 className="text-center">
            <span className="text-2xl">0%</span>
            <span className="mr-4 ml-1">{t('account.agent-at-a-glance.mtc')}</span>
            <span className="text-2xl">0%</span>
            <span className="ml-1">{t('account.agent-at-a-glance.apd')}</span>
          </h1>
        </div>
        <div className="card-footer">
          <h4 className="text-[#8B0000] font-medium">
          {t('account.agent-at-a-glance.tier-reached')}
          </h4>
        </div>
      </div>
    </div>
  );
}
