import { collection, getDocs } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Table, Tr, Th, Td } from "react-super-responsive-table";
import { db } from "../../firebase";
import Breadcrumbs from "../assets/Breadcrumbs";

export default function DriversBlacklist({ userInfo }) {
  const [drivers, setDrivers] = useState({});
  const getDrivers = async () => {
    try {
      const blackListRef = collection(db, "drivers-blacklist");
      const snapshot = await getDocs(blackListRef);
      const driversData = {};
      snapshot.forEach((doc) => {
        driversData[doc.id] = doc.data();
      });
      setDrivers(driversData);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getDrivers();
  }, []);

  const breadcrumbs = [
    { label: "Loss Control" },
    { label: "Drivers Blacklist" },
  ];

  return (
    <>
      <div className="my-4">
        {/* TODO: Make this table paginated, dynamic, and searchable */}
        <Breadcrumbs items={breadcrumbs} />
        <h1 className="text-3xl mt-2 font-semibold mb-3">Drivers Blacklist</h1>
        <p className="mt-3 mb-4">
          A list of all drivers who have been blacklisted.
        </p>
        <p className="mt-3 mb-4">
          Blacklist Count: {Object.keys(drivers).length} drivers
        </p>
        <Table>
          <thead>
            <Tr>
              <Th className={`bg-[#072a48] p-2 text-white rounded-tl-md`}>
                Driver First
              </Th>
              <Th className={`bg-[#072a48] p-2 text-white`}>DOB</Th>
              <Th className={`bg-[#072a48] p-2 text-white `}>
                License Country
              </Th>
              <Th className={`bg-[#072a48] p-2 text-white`}>License Number</Th>
              <Th className={`bg-[#072a48] p-2 text-white`}>
                Blacklist Reason
              </Th>
              <Th className={`bg-[#072a48] p-2 text-white`}>
                Date Blacklisted
              </Th>
              <Th className={`bg-[#072a48] p-2 text-white rounded-tr-md`}>
                Date Blacklisting Expiration
              </Th>
            </Tr>
          </thead>
          <tbody>
            {Object.keys(drivers).map((driverId) => {
              const driver = drivers[driverId];
              return (
                <Tr key={driverId}>
                  <Td>
                    {driver.driverFirst} {driver.driverLast}
                  </Td>
                  <Td>{driver?.dob?.toDate().toString().slice(4, 15)}</Td>
                  <Td>{driver.licenseCountry}</Td>
                  <Td>{driver.licenseNumber}</Td>
                  <Td>{driver.blacklistReason}</Td>
                  <Td>
                    {driver.dateBlacklisted
                      ? driver.dateBlacklisted.toDate().toString().slice(4, 15)
                      : ""}
                  </Td>
                  <Td>
                    {driver.blacklistExpirationDate
                      ? driver.blacklistExpirationDate
                          .toDate()
                          .toString()
                          .slice(4, 15)
                      : ""}
                  </Td>
                </Tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </>
  );
}
