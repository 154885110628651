import * as React from 'react';
import Box from '@mui/material/Box';
import { Snackbar, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

export default function PositionedSnackbar({
  duration = 3000,
  setShowToast,
  type,
  message
}) {
  const [visible, setVisible] = React.useState(true);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
      setShowToast();
    }, duration);
    return () => clearTimeout(timer);
  }, [duration, setShowToast]);

    let backgroundColor = "";

    switch (type) {
      case "success":
        backgroundColor = "green";
        break;
      case "error":
        backgroundColor = "red";
        break;
      case "info":
        backgroundColor = "blue";
        break;
      default:
        backgroundColor = "gray";
    }
  
  const handleClose = () => {
    setVisible(false);
    setShowToast();
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

    return visible ? (
      <Box sx={{ width: 500 }}>
        <Snackbar
          autoHideDuration={duration}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={true}
          onClose={handleClose}
          message={message}
          action={action}
          sx={{
            backgroundColor,
            '& .MuiSnackbarContent-root': {
              backgroundColor,
              color: 'white',
            },
          }}
        />
      </Box>
    ) : null;
}