import { db, auth } from "../../../firebase";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import FormGroup from "react-bootstrap/esm/FormGroup";
import React from "react";
import useTranslations from "../../../Context/actions/useTranslations";

export default function AddNewInsuredModal({
  show,
  handleClose,
  handleChange,
  formData,
  AddCustomer,
}) {
  const { t } = useTranslations();
  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>{t("manage-applications.add-a-customer")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="createPostContainer">
          <Form>
            <div className="inputGroup">
              <FormGroup>
                <label>{t("manage-applications.company-name")}:</label>
                <Form.Control
                  required
                  disabled
                  type="text"
                  name="company"
                  placeholder="Company Name"
                  className="form-input mb-2"
                  onChange={handleChange}
                  value={formData.company}
                />
                <label className="mt-1">
                  {t("manage-applications.safer-physical-address")}:
                </label>
                <Form.Control
                  required
                  disabled
                  type="text"
                  name="paddress"
                  placeholder="Full Physical Address"
                  className="form-input mb-2"
                  onChange={handleChange}
                  value={formData.physicalAddress}
                />
                <label className="mt-1">
                  {t("manage-applications.safer-mailing-address")}:
                </label>
                <Form.Control
                  required
                  disabled
                  type="text"
                  name="maddress"
                  placeholder="Full Mailing Address"
                  className="form-input mb-2"
                  onChange={handleChange}
                  value={formData.mailingAddress}
                />
                <label className="mt-1">
                  {t("manage-applications.address-for-notifications")}:
                </label>
                <Form.Control
                  required
                  type="text"
                  name="address"
                  placeholder={t(
                    "manage-applications.address-for-notifications"
                  )}
                  className="form-input mb-2"
                  onChange={handleChange}
                  value={formData.address}
                />
                <label className="mt-1">{t("manage-applications.city")}:</label>
                <Form.Control
                  required
                  type="text"
                  name="city"
                  placeholder={t("manage-applications.city")}
                  className="form-input mb-2"
                  onChange={handleChange}
                  value={formData.city}
                />
                <label className="mt-1">
                  {t("manage-applications.state")}:
                </label>
                <Form.Control
                  required
                  type="text"
                  name="state"
                  placeholder={t("manage-applications.state")}
                  className="form-input mb-2"
                  onChange={handleChange}
                  value={formData.state}
                />
                <label className="mt-1">{t("manage-applications.zip")}:</label>
                <Form.Control
                  required
                  type="text"
                  name="zip"
                  placeholder={t("manage-applications.zip")}
                  className="form-input mb-2"
                  onChange={handleChange}
                  value={formData.zip}
                />
                <label className="mt-1">
                  {t("manage-applications.phone")}:
                </label>
                <Form.Control
                  required
                  type="phone"
                  name="phone"
                  placeholder="Main Phone"
                  className="form-input mb-2"
                  onChange={handleChange}
                  value={formData.phone}
                />
                <label className="mt-1">
                  {t("manage-applications.dot-number")}:
                </label>
                <Form.Control
                  required
                  disabled
                  type="text"
                  name="dot"
                  placeholder={t("manage-applications.dot-number")}
                  className="form-input mb-2"
                  onChange={handleChange}
                  value={formData.dot}
                />
                <label className="mt-1">
                  {t("manage-applications.mc-number")}:
                </label>
                <Form.Control
                  required
                  disabled
                  type="text"
                  name="mc"
                  placeholder={t("manage-applications.mc-number")}
                  className="form-input mb-2"
                  onChange={handleChange}
                  value={formData.mc}
                />
              </FormGroup>
            </div>

            <button
              className="block ml-2 text-lg text-white bg-[#072a48] hover:bg-red-700 pt-1 pb-2 px-3 mt-3"
              onClick={AddCustomer}
            >
              {t("manage-applications.add-customer")}
            </button>
          </Form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="block text-lg text-white bg-gray-900 hover:bg-gray-700 pt-1 pb-2 px-3 mt-3"
          onClick={handleClose}
        >
          {t("manage-applications.cancel")}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
