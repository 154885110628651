import React, { useEffect, useMemo, useState } from "react";
import BlueButton from "../../assets/forms/BlueButton";
import RedButton from "../../assets/forms/RedButton";
import { Col, Container, Row } from "react-bootstrap";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  runTransaction,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { auth, db } from "../../../firebase";
import { numberWithCommas } from "../../../utils/helpers";
import RatePowerUnitEndorsement from "./RatePowerUnitEndorsement";
import ApprovalButtons from "../../assets/forms/ApprovalButtons";
import calculatePremiums from "../../utils/calculatePremiums";
import getTaxSchedule from "../../utils/getTaxSchedule";
import endoStatusChangeNotification from "../../utils/notifications/endoStatusChangeNotification";
import Form from "../../generals/Forms/Form";
import ReasonsModal from "../../PolicyLifecycleManager/ReturnToAgentModal";
import {
  setPolicyDetailUpdate,
  setPolicyInfo,
} from "../../PolicyLifecycleManager/Drivers/setPolicyInfo";
import useUsersState from "../../../Context/actions/useUsersState";
import RequestExceptions from "../../generals/Toast/RequestExceptions";
import Popup from "../../generals/Popup";
import PowerUnitDetailFlags from "../../PolicyLifecycleManager/RedFlags/PowerUnitDetailFlags";
import { getLastId } from "../../../utils/endorsementDetails";
import { formatDateToYYYYMMDD } from "../../Finances/Tables/utils";
import updateSystemLog from "../../utils/updateSystemLog";

export default function AddPowerUnits(props) {
  const endorsement = props.endorsement;
  const paramPolicyID = props.paramPolicyID;
  const paramRefID = props.paramRefID;
  const setSuccess = props.setSuccess;
  const requestExceptionInputs = props.requestExceptionInputs;
  const insuredInfo = props.insuredInfo;
  const [unitsRated, setUnitsRated] = useState(false);
  const [endorsementData, setEndorsementData] = useState([]);

  const handleGetPowerUnitsById = async () => {
    let powerUnits = [];
    for (const powerUnitId of endorsement.data) {
      const docRef = doc(
        db,
          "agencies",
          endorsement.agencyID,
          "insureds",
          endorsement.insuredID,
          "power-units",
          getLastId(powerUnitId.path)
      );
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        powerUnits.push({
          id: docSnap.id,
          ...docSnap.data(),
        });
      } else {
        console.log("No such document!");
        return null;
      }
    }
    setEndorsementData(powerUnits);
  }

  useEffect(() => {
    handleGetPowerUnitsById();
  }, [endorsement, unitsRated]);

  const { user } = useUsersState();
  const [requestingExceptionMode, setRequestingExceptionMode] = useState(false);

  const isRequestException = endorsement?.documentType === "Exception Request";
  const wasARequestException = endorsement?.wasARequestException === true;

  const [correspondingRequestExceptionInputs] = requestExceptionInputs
    .filter((info) => {
      return info.id === endorsement?.type;
    })
    .map((info) => info.inputs);

  const [taxSchedule, setTaxSchedule] = useState();
  const [gotTaxSchedule, setGotTaxSchedule] = useState(false);

  const [openInCancellationModal, setOpenInCancellationModal] = useState(false);

  const approveAddPowerUnitsEndorsement = async (e, requestExceptionData) => {
    e.preventDefault();
    const { agencyID, insuredID } = endorsement;
    const updateEndorsementStatusAndCreatePowerUnit = async (
      endorsementId,
      status,
      powerUnitData,
      policyData
    ) => {
      const endorsementRef = doc(
        db,
        "policy-applications",
        paramPolicyID,
        "endorsements",
        endorsementId
      );
      const existingTrucksSnapshot = await getDocs(
        collection(db, `agencies/${agencyID}/insureds/${insuredID}/power-units`)
      );
      const existingTrucks = existingTrucksSnapshot.docs.map((doc) => ({
        status: doc.data().status,
        VIN: doc.data().truckVIN,
        id: doc.id,
      }));

      try {
        await runTransaction(db, async (transaction) => {
          await transaction.get(endorsementRef);
          const issuedDate = formatDateToYYYYMMDD(new Date())
          const endoPayload = {
            status,
            wasARequestException: false,
            issuedDate,
          };
          if (requestExceptionData) {
            endoPayload["wasARequestException"] = true;
            endoPayload["requestException"] = requestExceptionData;
          }
          transaction.update(endorsementRef, endoPayload);
          const promises = powerUnitData.map(async (powerUnit) => {
            const existingTruck = existingTrucks.find(
              (truck) => truck.VIN === powerUnit.truckVIN
            );

            const payload = setPolicyInfo(
              {
                ...powerUnit,
                dateCreated: new Date(),
              },
              {
                policyEffectiveDate: endorsement.endoEffectiveDate,
                policyID: paramPolicyID,
                status: "Active",
              },
              "endo-addition"
            );

            if (existingTruck) {
              const subcollectionRef = doc(
                db,
                "agencies",
                agencyID,
                "insureds",
                insuredID,
                "power-units",
                powerUnit.id
              );

              await setDoc(subcollectionRef, payload, { merge: true });

              updateSystemLog(
                auth,
                paramPolicyID,
                "Add Power Units Endorsement (" +
                  payload.truckVIN +
                  ") Approved For " +
                  insuredInfo.company +
                  " by " +
                  auth.currentUser.displayName,
                "Add Power Unit Endo Approved",
                payload,
                true
              );

              const renewalApplicationID = policyData?.renewalApplicationID
              if (renewalApplicationID) {
                const mirroredSubcollectionRef = doc(
                  db,
                  "agencies",
                  agencyID,
                  "insureds",
                  insuredID,
                  "power-units",
                  powerUnit?.id
                );
                const mirroredPayload = setPolicyInfo(
                  {
                    ...payload,
                    dateCreated: new Date(),
                  },
                  {
                    policyEffectiveDate: endorsement.endoEffectiveDate,
                    policyID: renewalApplicationID,
                    status: "Active",
                  },
                  "endo-addition"
                );

                await setDoc(mirroredSubcollectionRef, mirroredPayload, {
                  merge: true,
                });

                updateSystemLog(
                  auth,
                  renewalApplicationID,
                  "Add Power Units Endorsement (" +
                    mirroredPayload.truckVIN +
                    ") Approved For " +
                    insuredInfo.company +
                    " by " +
                    auth.currentUser.displayName,
                  "Add Power Unit Endo Approved",
                  mirroredPayload,
                  true
                );
              }

              return subcollectionRef.id;
            }
          });
          await Promise.all(promises);
          const policySnapshot = await getDoc(
            doc(db, "policy-applications", paramPolicyID)
          );
          await calculatePremiums(
            policySnapshot?.id,
            policySnapshot?.data(),
            await getTaxSchedule(setTaxSchedule),
            "#",
            policySnapshot?.data()?.alPremiums?.adjustment || 0,
            true,
            true,
            paramRefID
          );
          setSuccess(true);
        });
      } catch (error) {
        console.error(
          "Error updating endorsement status and creating power unit:",
          error
        );
      }
    };

    const policyRef = doc(db, "policy-applications", paramPolicyID);
    const policyDoc = await getDoc(policyRef);
    const policyData = policyDoc.data();
    const policyStatus = policyData.status;
    if (policyStatus === "In-Cancellation") {
      setOpenInCancellationModal(true);
      return;
    }

    updateEndorsementStatusAndCreatePowerUnit(
      paramRefID,
      "Approved",
      endorsementData,
      policyData
    );
    endoStatusChangeNotification(
      endorsement.type,
      endorsement.endorsementNumber,
      "Approved",
      paramRefID,
      paramPolicyID,
      endorsement.agencyID
    );
  };

  const [rejectionModal, setRejectionModal] = useState(false);

  const declineAddPowerUnitsEndorsement = async (e, _, reasons) => {
    if (e) {
      e.preventDefault();
    }
    const powerUnitRef = doc(
      db,
      "policy-applications",
      paramPolicyID,
      "endorsements",
      paramRefID
    );

    const payload = {
      status: "Declined",
      wasARequestException: false,
    };
    if (isRequestException) {
      payload["wasARequestException"] = true;
    }
    if (reasons) {
      payload["rejectionReasons"] = reasons;
    }

    await updateDoc(powerUnitRef, payload);

    const policyRef = doc(db, "policy-applications", paramPolicyID);
    const policyDoc = await getDoc(policyRef);
    const policyData = policyDoc.data();

    const promises = endorsementData?.map(async (subcollection) => {
      const { agencyID, insuredID } = endorsement;
      const subcollectionRef = doc(
        db,
        "agencies",
        agencyID,
        "insureds",
        insuredID,
        "power-units",
        subcollection?.id
      );

      const subCollectionPayload = setPolicyInfo(
        {
          ...subcollection,
          dateCreated: new Date(),
        },
        {
          policyEffectiveDate: endorsement?.endoEffectiveDate,
          glPolicyNo: endorsement?.glPolicyNo,
          alPolicyNo: endorsement?.alPolicyNo,
          policyID: paramPolicyID,
          status: "Declined",
        },
        "endo-addition"
      );

      const entities = await getDoc(subcollectionRef);
      const entityData = entities.data();
      const updatedPayload = setPolicyDetailUpdate(
        subCollectionPayload,
        entityData,
        subCollectionPayload?.["policiesDetails"]?.[0]
      );

      await setDoc(subcollectionRef, updatedPayload, { merge: true });

      updateSystemLog(
        auth,
        paramPolicyID,
        "Add Power Units Endorsement (" +
          updatedPayload.truckVIN +
          ") Rejected For " +
          insuredInfo.company +
          " by " +
          auth.currentUser.displayName,
        "Add Power Unit Endo Rejected",
        updatedPayload,
        true
      );

      const renewalApplicationID = policyData?.renewalApplicationID

      if (renewalApplicationID) {
        const mirroredSubcollectionRef = doc(
          db,
          "agencies",
          agencyID,
          "insureds",
          insuredID,
          "power-units",
          subcollection?.id
        );

        const mirroredPayload = setPolicyInfo(
          {
            ...payload,
            dateCreated: new Date(),
          },
          {
            policyEffectiveDate: endorsement?.endoEffectiveDate,
            policyID: renewalApplicationID,
            status: "Declined",
          },
          "endo-addition"
        );

        const updatedPayload = setPolicyDetailUpdate(
          mirroredPayload,
          entityData,
          mirroredPayload?.["policiesDetails"]?.[0]
        );

        await setDoc(mirroredSubcollectionRef, updatedPayload, {
          merge: true,
        });

        updateSystemLog(
          auth,
          renewalApplicationID,
          "Add Power Units Endorsement (" +
            updatedPayload.truckVIN +
            ") Rejected For " +
            insuredInfo.company +
            " by " +
            auth.currentUser.displayName,
          "Add Power Unit Endo Rejected",
          updatedPayload,
          true
        );
      }
    });
    await Promise.all(promises);

    endoStatusChangeNotification(
      endorsement.type,
      endorsement.endorsementNumber,
      "Declined",
      paramRefID,
      paramPolicyID,
      endorsement.agencyID
    );
    setSuccess(true);
  };

  const requestExceptionModeSetting = async (e, data) => {
    e.preventDefault();
    const endoRef = doc(
      db,
      "policy-applications",
      paramPolicyID,
      "endorsements",
      paramRefID
    );
    await updateDoc(endoRef, {
      documentType: "Exception Request",
      requestException: data,
    });
    endoStatusChangeNotification(
      endorsement.type,
      endorsement.endorsementNumber,
      "Declined",
      paramRefID,
      paramPolicyID,
      endorsement.agencyID
    );
    setRequestingExceptionMode(false);
  };

  const formSettingsRequestException = useMemo(() => {
    const settings = {
      title: "Reason for exception",
      onSubmit: requestExceptionModeSetting,
      onDecline: () => setRequestingExceptionMode(false),
      inputs: correspondingRequestExceptionInputs || [],
      buttonLabel: "Submit",
      buttonLabelDecline: "Cancel",
      otherInput: { label: "Other exception request" },
    };
    return settings;
  }, [correspondingRequestExceptionInputs]);

  const priceToHandle = (price) => {
    if (price) {
      const amount = numberWithCommas(
        price.toFixed(2)
      )
      if (amount === NaN || !amount || amount === 'NaN') {
        return 'Not calculated yet'
      }
      return `$${amount}`
    } else {
      return 'Not calculated yet'
    }
  }

  return (
    <>
      <Popup
        isOpen={openInCancellationModal}
        onRequestClose={() => setOpenInCancellationModal(false)}
        maxWidth="50%"
      >
        <p className="text-2xl font-semibold mb-2 text-center text-red-700">
          This endorsement cannot be approved because the policy is In
          Cancellation
        </p>
      </Popup>
      <ReasonsModal
        title="Rejection Reasons"
        text="Please enter the reason(s) why the endorsement is being rejected."
        addButtonText="Add A Reason"
        onSubmit={(e, reasons) =>
          declineAddPowerUnitsEndorsement(e, null, reasons)
        }
        open={rejectionModal}
        onClose={() => setRejectionModal(false)}
      />
      <div>
        {endorsementData &&
          endorsementData.map((truck, truckIndex) => {
            const policyDetailToShow = truck?.policiesDetails.find(
              (detail) => detail.id === paramPolicyID
            );
            return (
              <div
                key={truckIndex}
                className="mx-auto p-6 bg-white shadow-md rounded-lg mb-2"
              >
                <Container className="p-0">
                  <Row>
                    <Col sm={12} md={6}>
                      <PowerUnitDetailFlags
                        powerUnit={truck}
                        policyID={paramPolicyID}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} md={6}>
                      <span className="font-semibold">VIN:</span>{" "}
                      {truck.truckVIN}
                    </Col>
                    <Col sm={12} md={6}>
                      <span className="font-semibold">Weight:</span>{" "}
                      {truck.truckWeight}
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} md={6}>
                      <span className="font-semibold">Year:</span>{" "}
                      {truck.truckYear}
                    </Col>
                    <Col sm={12} md={6}>
                      <span className="font-semibold">Make:</span>{" "}
                      {policyDetailToShow?.truckMake}
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} md={6}>
                      <span className="font-semibold">Model:</span>{" "}
                      {policyDetailToShow?.truckModel}
                    </Col>
                    <Col sm={12} md={6}>
                      <span className="font-semibold">Class:</span>{" "}
                      {policyDetailToShow?.truckClass}
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} md={6}>
                      <span className="font-semibold">Rating:</span>{" "}
                      {priceToHandle(policyDetailToShow?.adjustedRate)}
                    </Col>
                    <Col sm={12} md={6}>
                      <span className="font-semibold">SL Tax:</span>{" "}
                      {priceToHandle(policyDetailToShow?.taxes?.surplusLinesTax)}
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} md={6}>
                      <span className="font-semibold">Stamp Fee:</span>{" "}
                      {priceToHandle(policyDetailToShow?.taxes?.stampFee)}
                    </Col>
                    <Col sm={12} md={6}>
                      <span className="font-semibold">Processing Fee:</span>{" "}
                      {priceToHandle(policyDetailToShow?.taxes?.processingFee)}
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} md={6}>
                      <span className="font-semibold">Total:</span>{" "}
                      {priceToHandle(
                        policyDetailToShow?.taxes?.processingFee +
                        policyDetailToShow?.taxes?.stampFee +
                        policyDetailToShow?.taxes?.surplusLinesTax +
                        policyDetailToShow?.adjustedRate
                      )}
                    </Col>
                  </Row>
                </Container>
              </div>
            );
          })}
      </div>
      {requestingExceptionMode &&
      typeof correspondingRequestExceptionInputs !== "undefined" ? (
        <Form
          formSettings={formSettingsRequestException}
          isSmallVersion={true}
        />
      ) : null}

      {(endorsement.status === "Submitted" || isRequestException) &&
      (
        user.role === "Underwriter" ||
        user.role === "Admin"
      ) ? (
        <>
          {!unitsRated ? (
            <>
              <article className="my-4">
                <p>
                  Before approving or declining this endorsement, you must rate
                  or rerate the power units.
                </p>
              </article>
              <RatePowerUnitEndorsement
                truckList={endorsementData}
                policyID={paramPolicyID}
                endoID={paramRefID}
                setSuccess={setUnitsRated}
              />
            </>
          ) : (
            <>
              <article className="my-4">
                <p
                  className={
                    isRequestException
                      ? "text-red-700 text-center !max-w-[345px] mx-auto"
                      : ""
                  }
                >
                  {isRequestException
                    ? `This endorsement has already been declined. 
                    You are accepting/decling an exception request`
                    : `Approving this endorsement will add the above Power Unit(s) to
                    the policy and notify the insured of the change.`}
                </p>
              </article>
              {isRequestException ? (
                <RequestExceptions
                  endorsement={endorsement}
                  color="red"
                  center={true}
                />
              ) : null}
              <ApprovalButtons
                onApprove={approveAddPowerUnitsEndorsement}
                onDecline={() => setRejectionModal(true)}
              />
            </>
          )}
        </>
      ) : endorsement.status === "Declined" &&
        !isRequestException &&
        !wasARequestException &&
        user.role === "Agent" &&
        !requestingExceptionMode ? (
        <ApprovalButtons
          dissapearAccept={true}
          titleDecline="REQUEST EXCEPTION"
          onDecline={() => setRequestingExceptionMode(true)}
        />
      ) : null}
    </>
  );
}
