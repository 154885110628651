import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";

const PulseEffect = () => <div className="h-4 bg-gray-400 animate-pulse" />;

const PulseEffectHeader = () => (
  <Th>
    <PulseEffect />
  </Th>
);

const PulseEffectBody = () => (
  <Td>
    <PulseEffect />
  </Td>
);

const TableSkeleton = ({ rows = 2, columns = 4, withHead = true }) => {

  const rowsArray = Array.from({ length: rows }, (v, i) => i);
  const columnsArray = Array.from({ length: columns }, (v, i) => i);

  return (
    <Table>
      {withHead ?
        <Thead>
          <Tr>
            {columnsArray.map((_, index) => <PulseEffectHeader key={index} />)}
          </Tr>
        </Thead> : null
      }
      <Tbody>
        {rowsArray.map((_, index) => (
          <Tr key={index}>
            {columnsArray.map((_, index) => <PulseEffectBody key={index} />)}
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

export default TableSkeleton;
