export const calendarEventsEmails = [
  "abdiel@southernstarmga.com",
  "kcorona@liconainsurance.com",
  "bruiz@southernstarmga.com",
  "dlicona@liconainsurance.com",
  "colt@southernstarmga.com",
  "tx96@lainsuranceagency.com",
  "hssidhu2708@gmail.com",
  "ana@bestchoicetruckingins.com",
  "tester101@gmail.com",
  "christy@eagleinsurance.agency",
  "YADIRA2987@YAHOO.COM",
  "raj@ocitrucking.com",
  "tester102@gmail.com",
  "andrew@transfleetinsurance.com",
  "testerx1234@gmail.com",
  "thakurashishjamwal@gmail.com",
  "v.cantu@pacificcrestinsurance.com",
  "ssilva@borderinsurancemx.com",
  "TEXASWIZE@gmail.com",
  "vijai@sevains.com",
  "jax.alley@m5ip.com",
  "cornerstonemultiinsurance@gmail.com",
  "KAVI@KAVILLC.COM",
  "yoli@insure-zone.com",
  "SUNNYSIDEUPINSURANCE@GMAIL.COM",
  "lexi@bluemountainins.com",
  "rogeliomdeluna@safestopia.com",
  "usainsurance111@gmail.com",
  "bmanager@pcinsllc.com",
  "texasinsurancedepot@gmail.com",
  "knycha.joseph@kjiagency.com",
  "marsden@newsouthinsurance.com",
  "SESPINOZA@LOVITT-TOUCHE.COM",
  "jaikarinsurance@gmail.com",
  "DFONTE@INSURANCECENTEROFDADE.COM",
  "Elizabeth@attainsurance.com",
  "mcallen_csr@liconainsurance.com",
  "guillermo@magdalenoagency.com",
  "cflores@borderinsurancemx.com",
  "info@arrowcis.com",
  "frances@islandfleetins.com",
  "jrivas@asegure.net",
  "ahussein@husseinagency.com",
  "mcallen_csr@liconainsurance.com",
  "lilian@getcomins.com",
  "jason@mygoldinsurance.com",
  "sheri@harmonyinsuranceagency.com",
  "taranjeet@paltainsurance.com",
  "southpoint.ins@gmail.com",
  "Barbara@Barriosinsurance.com",
  "emilys@amtexinsurance.com",
  "INFO@USHERINSURANCEGROUP.COM",
  "admin@canaaninsurance.net",
  "steve@insurance4truck.com",
  "paul@armadilloinsurancegroup.com",
  "sarah@hometownassurance.com",
  "rhett@tailoredis.com",
  "advisorinsurance996@gmail.com",
  "mike@imminentins.com",
  "marcus.anderson@ai-affiliates.com",
  "carlos@asegura-insurance.com",
  "ptsingh@sohoib.com",
  "tester100@gmail.com",
  "281zachary@gmail.com",
  "bobby.fazio@policypro.com",
  "taylor.mabra@biltmoreins.com",
  "rightwayinsgroup@gmail.com",
  "aofns.ao@gmail.com",
  "jeff@novatrustrisk.com",
  "Kiran@perfectunionins.com",
  "admin@valorinsuranceinc.com",
  "commercial.ins@casaservices.net",
  "Dan@davisandassoc.com",
  "roop@arrowcis.com",
  "manderson@ai-affiliates.com",
  "colt.schultz1@gmail.com",
  "sales@supreme-insurance.com",
  "manjit@roadwayscia.com",
  "colt@itow.app",
  "info@acisinsurance.com",
  "andrew.arceri@gmail.com",
  "carolina@gosafeagency.com",
  "nissa@cyatruckinginsurance.com",
  "federico.roesch@prontoinsurance.com",
  "quotes@roadwayscia.com",
  "pam.golsan@hibbshallmark.com",
  "capitalinsurancemd@gmail.com",
  "tester104@gmail.com",
  "ops@vantageins.us",
  "rizwan@premiertruckgroup.com",
  "office@jaikarinsurance.com",
  "management@northins.com",
  "rocio.ga@rokains.com",
  "aiaffiliates20@gmail.com",
  "lauren.warner@usi.com",
  "sunnysideins01@gmail.com",
  "kavillcca@gmail.com",
  "maria@jefferstruckins.com",
  "israel@uigoftexas.com",
  "jot@capitalhillins.com",
  "corey@paladininsured.com",
  "erika@kashinsurance.com",
  "info@singhbrothersins.com",
  "edgar@empiretransins.com",
  "liz@libreins.com",
  "mabra.taylor@gmail.com",
  "INFO@ALPHAINS.US",
  "info@cornerstonemultiins.com",
  "dyoung@vip-ins.net",
  "bill@truguard.co",
  "jenny@h2oins.com",
  "mary@texasinsurancebureau.net",
  "triaginsurance@gmail.com",
  "KIA@KIACALIFORNIA.COM",
  "dayna@txisc.com",
  "edward@eztruckingins.com",
  "javier@insxprts.com",
  "frank@crossroads-ins.com",
  "lesjardinsinsurance@gmail.com",
  "mcallen_csr@liconainsurance.com",
  "danielle@tiginsure.com",
  "brandonethio1993@gmail.com",
  "jesse@reddotins.com",
  "robrodriguez635@yahoo.com",
  "empiretransins@gmail.com",
  "raman@paltainsurance.com",
  "TRUCK@SUCCESSINS.NET",
  "bwright@davisandassoc.com",
  "ANIL@THECOUNTYLINESERVICES.COM",
  "powerxinsurance@gmail.com",
  "christina@cminsures.com",
  "chrism@swan-ins.com",
  "venetexinsgrp@gmail.com",
  "contractorsdir@att.net",
  "kyle.richards@marqueeig.com",
  "mark@mminsurance.shop",
  "KOrdonez_ck@veroscredit.com",
  "isoto@insuranceleadergroup.com",
  "insuranceplanetfl@gmail.com",
  "will@globalriskoffice.com",
  "jesse.case@marqueeig.com",
  "crystal.meador@biltmoreins.com",
  "Edward@concordcommercialti.com",
  "agency@accuranceins.com",
  "backoffice@florinsurance.con",
  "robert@powerxinsurance.com",
  "sfa6000@yahoo.com",
  "anovoa@gia-usa.com",
  "isekuloski@insurox.com",
  "amedellin@royaleinsurance.us",
  "testerx1@gmail.com",
  "carlos@myinsurancepros.com",
  "juanita-sierra@libertystarr.com",
  "service@legacyinsureagency.com",
  "adamnoone@martinnooneagency.net",
  "ins@teginsurancecompany.com",
  "olivia@cookinsurancegroup.com",
  "carlos@northins.com",
  "jlasterinsurance@gmail.com",
  "LIZ@TOROTAXES.COM",
  "Renato@igotcoverage.com",
  "cmariana1979@gmail.com",
  "Gdacosta@infoinsurancetx.com",
  "cesar@arcusinsurance.com",
  "yadira@dlginsurance.us",
  "alex@alexrue.com",
  "hardeep.singh@piaselect.com",
  "flor@florinsurance.com",
  "testerx110@gmail.com",
  "test3333@test.com",
  "testerx106@gmail.com",
  "colt@test.com",
  "test233@test.com",
  "emejia@southernstarmga.com",
  "losscontrol@southernstarmga.com",
  "mcallen_csr2@liconainsurance.com",
  "mcallen_csr3@liconainsurance.com",
  "amarino@southernstarmga.com",
  "sbonilla@southernstarmga.com",
  "aortiz@southernstarmga.com",
];
