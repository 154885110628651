import {
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import React, { useEffect, useMemo, useState } from "react";
import { db } from "../../../firebase";
import { t } from "i18next";
import {
  convertDDMMYYYToFirestoreFormat,
  convertISOToFirestoreFormat,
  isExpired,
  numberWithCommas,
} from "../../../utils/helpers";
import useGeneralState from "../../../Context/actions/useGeneralState";
import useEndorsementsState from "../../../Context/actions/useEndorsementsState";
import { individualToEndorsement } from "../Drivers/individualToEndorsement.";
import { clearList } from "../Drivers/deleteMultiple";
import useTranslations from "../../../Context/actions/useTranslations";
import { buildShowingInfo, filterRightPoliciesDetails } from "../Drivers/setPolicyInfo";
import { selectIfDeleteOrExistingSubcollection } from "../../utils/validateIfBlacklisted";
import { handleSubcollectionStatus } from "../../../utils/constants";
import { confirmDeletePromptGlobal } from "../../../layout/ConfirmDeleteProvider";

const useTrucksList = (props) => {
  const {
    formData,
    truckList,
    policyID,
    setTruckList,
    insuredInfo,
    userInfo,
    isEndoManager,
    getEndorsements,
  } = props;

  const [infoModalIsOpen, setInfoModalIsOpen] = useState(false);
  const { t, language } = useTranslations();
  const [tableData, setTableData] = useState([]);
  const [tableDataInDeletionMode, setTableDataInDeletionMode] = useState([]);
  const [tableDataShowingDeletion, setTableDataShowingDeletion] = useState([]);
  const { controlToast } = useGeneralState();
  const { endorsements } = useEndorsementsState();
  const [modalValues, setModalValues] = useState(null);
  const [deletionMode, setDeletionMode] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalSubmitting, setModalSubmitting] = useState(false);

  const isAtLeastThreeSelectedInDeletedMode = useMemo(() => {
    const selectedData = tableDataInDeletionMode.filter((data) => {
      return Boolean(data?.tableInfo?.actions?.actions?.[0]?.checkbox);
    });
    return Boolean(selectedData.length >= 3 && deletionMode);
  }, [tableDataInDeletionMode]);

  const isAtLeastOneSelectedInDeletedMode = useMemo(() => {
    return tableDataInDeletionMode.some((data) => {
      return Boolean(data?.tableInfo?.actions?.actions?.[0]?.checkbox);
    }) && deletionMode;
  }, [tableDataInDeletionMode]);

  const clearTruckList = async (
    e, values, button,
    policyIDToUse,
    documentTypeToUse,
    renewalProcess
  ) => {
    e.preventDefault();
    const effectiveDate = values["effective-date"];
    const deletionReason = values["deletion-reason"];
    const proofOfPowerDeletion = values["proof-of-power-deletion"];
    setModalSubmitting(true);
    try {
      const verify = await clearList(
        formData,
        controlToast,
        policyIDToUse,
        truckList,
        tableDataInDeletionMode,
        insuredInfo,
        userInfo,
        getEndorsements,
        endorsements,
        "Remove Power Units",
        "power-units",
        effectiveDate,
        deletionReason,
        proofOfPowerDeletion,
        button,
        documentTypeToUse,
        renewalProcess
      );
      if (!verify && button === 'massive') {
        setModalSubmitting(false)
        return 'verify is null'
      }
      setModalSubmitting(false);
      handleCloseModal();
      setTableDataInDeletionMode([])
      setTableDataShowingDeletion([])
    } catch (error) {
      setModalSubmitting(false);
      throw error;
    }
  };

  const detectingIndividualOrMultiple = async (
    e, data, button,
    policyIDToUse,
    documentTypeToUse,
    renewalProcess
  ) => {
    try {
      if (modalValues === "multiple" || data === "multiple") {
        return await clearTruckList(
          e,
          data,
          button,
          policyIDToUse,
          documentTypeToUse,
          renewalProcess
        );
      } else {
        return await deleteThisTruck(
          e,
          data,
          null,
          policyIDToUse,
          documentTypeToUse,
          renewalProcess
        );
      }
    } catch (error) {
      throw error;
    }
  };

  const mirrorLogic = async (e, data, button) => {
    const isRenewalApplication =
      formData.status === "In-Renewal" &&
      formData.documentType === "Application";
    const isRenewalPolicy =
      formData.status === "In-Renewal" &&
      formData.documentType === "Policy";
    const notARenewalProcess = formData.status !== "In-Renewal";
    try {
      if (notARenewalProcess) {
        await detectingIndividualOrMultiple(
          e, data, button,
          policyID,
          formData.documentType,
          false
        );
      }
      if (isRenewalApplication) {
        await detectingIndividualOrMultiple(
          e, data, button,
          policyID,
          "Application",
          true
        );
        await detectingIndividualOrMultiple(
          e, data, button,
          formData.renewalPolicyID,
          "Policy",
          true
        );
      }
      if (isRenewalPolicy) {
        await detectingIndividualOrMultiple(
          e, data, button,
          policyID,
          "Policy",
          true
        );
        await detectingIndividualOrMultiple(
          e, data, button,
          formData.renewalApplicationID,
          "Application",
          true
        );
      }
      setDeletionMode(false);
    } catch (error) {
      throw error
    }
  }

  const inputs = useMemo(() => {
    let formInputs = [
      {
        label: "Effective Date of the endorsement",
        type: "date",
        name: "effective-date",
        minDate: new Date(),
        required: true,
      },
    ];
    if (isAtLeastThreeSelectedInDeletedMode) {
      formInputs.push({
        label: "Deletion Reason",
        type: "text",
        name: "deletion-reason",
        required: true,
      });
    }
    formInputs.push({
      label: "Upload Your Power Unit Deletion Proof Files",
      type: "file",
      storageFile: `files/power-units/${modalValues?.truckVIN}`,
      name: "proof-of-power-deletion",
      required: true,
      multiple: true,
      dissapearWhenImgExists: true,
      showPastingOption: true
    });
    return formInputs;
  }, [isAtLeastThreeSelectedInDeletedMode, modalValues]);

  const formSettings = {
    onSubmit: mirrorLogic,
    inputs,
    buttonLabel: "Create Deletion Endorsement",
  };

  const handleSubmit = async (e, values, button) => {
    try {
      if (e) e.preventDefault();
      if (
        formData.documentType === "Policy" ||
        formData.status === "In-Renewal"
      ) {
        setOpenModal(true);
        setModalValues(values);
      } else {
        setModalValues(values);
        const response = await mirrorLogic(e, values, button);
        if (response === 'verify is null') return
      }
    } catch (error) {
      throw error;
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setModalValues(null);
  };

  function openInfoModal() {
    setInfoModalIsOpen(true);
  }

  function afterInfoModalOpen() {}

  function closeInfoModal() {
    setInfoModalIsOpen(false);
    setSelectedTruck(0);
  }

  const deleteThisTruck = async (
    e, truck, _,
    policyIDToUse,
    documentTypeToUse,
    renewalProcess
  ) => {
    e.preventDefault();
    setModalSubmitting(true);
    try {
      let verify
      if (documentTypeToUse === "Application" && !renewalProcess) {
        verify = await confirmDeletePromptGlobal(t(
          "You are about to delete a Truck! If you are sure, type DELETE below:"
        ));
      }
      if (
        verify === "DELETE" ||
        renewalProcess ||
        documentTypeToUse === "Policy"
      ) {

        const payload = {
          ...modalValues,
          ...truck,
        };
        delete payload["iconLeft"];
        delete payload["iconRight"];
        delete payload["tableInfo"];
        delete payload["dataIndex"];
        let effectiveDate
        if (truck?.["effective-date"]) {
          effectiveDate = convertISOToFirestoreFormat(
            truck["effective-date"]
          );
          delete payload["effective-date"];
        }
  
        const { agencyID, insuredID } = formData
        const payloadResult = await selectIfDeleteOrExistingSubcollection(
          payload,
          agencyID,
          insuredID,
          'power-units',
          policyIDToUse,
          renewalProcess,
          documentTypeToUse,
          formData
        )

        if (documentTypeToUse === "Policy" || renewalProcess) {
          if (truck?.["effective-date"]) {
            await individualToEndorsement(
              policyIDToUse,
              formData.effectiveDate,
              "Remove Power Unit",
              payloadResult,
              insuredInfo,
              formData,
              userInfo,
              isEndoManager,
              getEndorsements,
              endorsements
            );
          }
          controlToast(
            true,
            "The endorsement was succesfully created",
            "success"
          );
        }
        setModalSubmitting(false);
        setOpenModal(false);
        setModalValues(null);
      }

    } catch (error) {
      controlToast(true, "There was an error in the service", "error");
      setModalSubmitting(false);
      throw error;
    }
  };

  const [selectedTruck, setSelectedTruck] = useState(0);

  useEffect(() => {
    if (!formData.agencyID) return;
    const policyRef = doc(db, "policy-applications", policyID)
    const q = query(
      collection(
        db,
        "agencies",
        formData.agencyID,
        "insureds",
        formData.insuredID,
        "power-units"
      )
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const values = [];
      querySnapshot.forEach((doc) => {
        values.push({
          ...doc.data(),
          id: doc.id,
        });
      });
      const filteredValues = buildShowingInfo(values, policyRef.id)
      setTruckList(filteredValues);
    });
    return () => {
      unsubscribe();
    };
  }, [policyID, formData.agencyID]);

  const initialColumns = [
    {
      name: "truckVIN",
      label: "VIN",
    },
    // {
    //   name: "status",
    //   label: "Status",
    // },
    {
      name: "truckACV",
      label: "ACV",
    },
    {
      name: "truckYear",
      label: "Year",
    },
    {
      name: "truckMake",
      label: "Make",
    },
    {
      name: "truckRadius",
      label: "Radius",
    },
    {
      name: "truckClass",
      label: "Class",
    }
  ];

  const [columnsSet, setColumnsSet] = useState(false);
  const [columns, setColumns] = useState(initialColumns);

  useEffect(() => {
    if (truckList.length > 0) {
      let newColumns = initialColumns;
      if (formData?.hasALCoverage) {
        newColumns = [
          ...initialColumns.slice(0, 6),
          {
            name: "adjustedRate",
            label: "Adj. Rate",
          },
          ...initialColumns.slice(6),
        ];
      }
      newColumns.push({
        name: "actions",
        label: "Actions",
      })
      setColumns(newColumns);
      setColumnsSet(true);
    }
  }, [truckList, formData, deletionMode]);

  const formingTableData = (deletionFlag, tableBehavior) => {
    const effectiveElementsList = truckList.filter((item) => {
      return !isExpired(item?.expirationDate);
    });
    const tableData = effectiveElementsList?.map((data, dataIndex) => {
      const {
        truckVIN,
        truckYear,
        policiesDetails,
        truckDocuments
      } = data || {};

      const iconLeft = [];
      const iconRight = [''];

      iconLeft.push({
        name: "redFlag-powerUnit",
        toField: "truckVIN",
        className: "text-[#8B0000]"
      });

      const actionsInNormalMode = []
      // actionsInNormalMode.push('delete')
      iconRight.push({
        icon: "dropdown-power-unit-imgs",
        action: true,
        toField: "action",
      });
      actionsInNormalMode.push('dropdown-power-unit-imgs')

      iconRight.push({
        icon: "edit",
        action: true,
        toField: "action",
      });
      actionsInNormalMode.push('edit')

      if (truckDocuments?.length > 0) {
        iconRight.push({
          icon: "dropdown-power-units-view-result",
          action: true,
          toField: "action",
        });
        actionsInNormalMode.push("dropdown-power-units-view-result")
      }

      // if (
      //   formData.documentType === "Application" ||
      //   formData.documentType === "Policy"
      // ) {
      //   iconRight.push({
      //     icon: "delete",
      //     action: true,
      //     toField: "action",
      //   });
      // }
      
      const tableObject = {
        ...data,
        tableInfo: {
          truckVIN: {
            text: truckVIN,
            isButton: true,
            className: "text-sky-900 hover:text-[#072a48] mr-2 break-all",
          },
          // status: {
          //   text: handleSubcollectionStatus(
          //     filterRightPoliciesDetails(policiesDetails, policyID)?.status,
          //     language
          //   ),
          // },
          truckACV: {
            text:
              `$${
                numberWithCommas(filterRightPoliciesDetails(policiesDetails, policyID)?.truckACV?.toFixed(2))
                ? numberWithCommas(filterRightPoliciesDetails(policiesDetails, policyID)?.truckACV?.toFixed(2))
                : "0.00"
              }`
          },
          truckYear: {
            text: truckYear,
          },
          truckMake: {
            text: filterRightPoliciesDetails(policiesDetails, policyID)?.truckMake,
          },
          truckRadius: {
            text: filterRightPoliciesDetails(policiesDetails, policyID)?.truckRadius,
          },
          truckClass: {
            text: filterRightPoliciesDetails(policiesDetails, policyID)?.truckClass,
          }
        },
        dataIndex,
        iconLeft,
        iconRight,
      };
      
      if (formData?.hasALCoverage) {
        const adjustedRate = filterRightPoliciesDetails(policiesDetails, policyID)?.adjustedRate
        tableObject["tableInfo"]["adjustedRate"] = {
          text: `$${
            numberWithCommas(adjustedRate?.toFixed(2))
              ? numberWithCommas(adjustedRate?.toFixed(2))
              : "0.00"
          }`,
        };
      }
      tableObject["tableInfo"]["actions"] = {
        actions: tableBehavior
          ? ["deletion-behavior"]
          : deletionFlag
          ? [
              {
                checkbox: false,
              },
            ]
          : actionsInNormalMode,
      };
      return tableObject;
    });
    return tableData;
  };

  useEffect(() => {
    if (typeof truckList.length !== "undefined") {
      if (deletionMode) {
        const tableDataDeletionMode = formingTableData(true);
        setTableDataInDeletionMode(tableDataDeletionMode);
      }
      const tableBehavior = deletionMode ? "deletion-behavior" : "";
      const tableData = formingTableData(false, tableBehavior);
      setTableData(tableData);
    }
  }, [truckList, deletionMode, columnsSet, formData]);

  const showItem = (e, id, _) => {
    e.preventDefault();
    setSelectedTruck(id);
    openInfoModal();
  };

  const filterKeys = [
    "truckVIN",
    "truckACV",
    "truckYear",
    "truckMake",
    "truckRadius",
    "truckClass",
    "rating",
    "status"
  ];

  return {
    columns,
    tableData,
    showItem,
    truckList,
    selectedTruck,
    infoModalIsOpen,
    afterInfoModalOpen,
    closeInfoModal,
    filterKeys,
    handleSubmit,
    formSettings,
    openModal,
    handleCloseModal,
    deletionMode,
    setDeletionMode,
    modalSubmitting,
    tableDataInDeletionMode,
    setTableDataInDeletionMode,
    tableDataShowingDeletion,
    setTableDataShowingDeletion,
    isAtLeastOneSelectedInDeletedMode,
    clearTruckList,
  };
};

export default useTrucksList;
