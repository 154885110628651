import React, { useEffect, useState } from "react";
import classNames from "classnames";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  collection,
  doc,
  onSnapshot,
  updateDoc,
  query,
  where,
} from "firebase/firestore";
import { Table, Tbody, Td, Thead, Tr } from "react-super-responsive-table";
import { db } from "../../firebase";
import TableSkeleton from "../generals/TableSkeleton";

export default function NotificationManager({ userInfo, notificationType }) {
  const [recipients, setRecipients] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");

  const recipientListFieldNameLowerCase = `${(notificationType || "").toLowerCase()}NotificationRecipients`;
  const recipientListFieldName = recipientListFieldNameLowerCase.replace(/\s/g, '');


  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    const companyRef = doc(db, "Admin", "notification-recipients");
    const unsubscribeRecipients = onSnapshot(companyRef, (doc) => {
      const data = doc.data();
      setRecipients(data[recipientListFieldName] || []);
      setLoading(false)
    });
    return () => unsubscribeRecipients();
  }, [userInfo.agencyID]);

  useEffect(() => {
    if (userInfo.agencyID) {
      const usersCollection = collection(db, "users");
      const q = query(
        usersCollection,
        where("agencyID", "==", userInfo.agencyID),
      );

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const updatedUserList = snapshot.docs
          .map((doc) => {
            if (
              !recipients.some((user) => {
                const thisUser = JSON.parse(user);
                return thisUser.id === doc.id;
              })
            ) {
              return { id: doc.id, ...doc.data() };
            }
            return null;
          })
          .filter(Boolean);
        setUsers(updatedUserList);
      });
      return () => unsubscribe();
    }
  }, [userInfo.agencyID, recipients]);

  const handleDeleteRecipient = async (index) => {
    const updatedRecipients = [...recipients];
    updatedRecipients.splice(index, 1);
    const companyRef = doc(db, "Admin", "notification-recipients");
    await updateDoc(companyRef, {
      [recipientListFieldName]: updatedRecipients,
    });
  };

  const handleAddRecipient = async () => {
    if (!selectedUser) return;
    const updatedRecipients = [...recipients, selectedUser];
    const companyRef = doc(db, "Admin", "notification-recipients");
    await updateDoc(companyRef, {
      [recipientListFieldName]: updatedRecipients,
    });
    setSelectedUser("");
  };

  return (
    <section className="mt-4">
      <h2 className="text-lg font-semibold mb-2">
        Admin/Underwriter {notificationType} Notification Recipients
      </h2>
      {loading ? <TableSkeleton rows={5} columns={5} /> :
        <Table className="min-w-full divide-y divide-gray-200">
          <Thead className="bg-gray-50">
            <Tr>
              <Td
                className={classNames(
                  `bg-[#072a48] text-white
                          rounded-tl-md`
                )}
              >
                Name
              </Td>
              <Td className={classNames(`bg-[#072a48] text-white`)}>Email</Td>
              <Td
                className={classNames(
                  `bg-[#072a48] text-white
                          rounded-tr-md`
                )}
              >
                Actions
              </Td>
            </Tr>
          </Thead>
          <Tbody className="bg-white divide-y divide-gray-200">
            {recipients.map((rawRecipient, index) => {
              const recipient = JSON.parse(rawRecipient);
              return (
                <Tr key={index}>
                  <Td>
                    {recipient?.first} {recipient?.last}
                  </Td>
                  <Td>{recipient?.email}</Td>
                  <Td>
                    <button
                      onClick={() => handleDeleteRecipient(index)}
                      className="text-[#8B0000] hover:text-[#8B0000] d-inline mx-1"
                    >
                      <DeleteIcon />
                    </button>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      }
      <div className="mt-4">
        <select
          className="p-2 mb-2 d-block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          value={selectedUser}
          onChange={(e) => setSelectedUser(e.target.value)}
        >
          <option value="">Select User</option>
          {users.map((user) => (
            <option key={user?.id} value={JSON.stringify(user)}>
              {user?.first} {user?.last}
            </option>
          ))}
        </select>
        <button
          className="px-4 d-block w-full py-2 bg-[#072a48] border border-Transparent rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={handleAddRecipient}
        >
          Add Recipient
        </button>
      </div>
    </section>
  );
}
