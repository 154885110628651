import { useEffect, useMemo, useState } from "react";
import useWaivers from "../../../hooks/endorsements/useWaivers";
import Button from "../../generals/Forms/Button";
import { addDoc, collection, onSnapshot, query } from "firebase/firestore";
import PDFPreviewer from "../../generals/PDFPreviewer";
import { db } from "../../../firebase";
import GoBack from "../../generals/GoBack";
import DynamicTable from "../../assets/lists/DynamicTable";
import { isExpired } from "../../../utils/helpers";
import BlueButton from "../../assets/forms/BlueButton";
import { useMediaQuery } from "@mui/material";
import useTranslations from "../../../Context/actions/useTranslations";
import ApprovalButtons from "../../assets/forms/ApprovalButtons";
import DatePicker from "react-datepicker";
import FormPopup from "../../generals/Popup/FormPopup";
import { companyUSStateOptions } from "../../../utils/constants";
import useUsersState from "../../../Context/actions/useUsersState";

const WaiverOfSubrogation = (props) => {
  const { formData, policyID, waiversList, setWaiversList } = props;

  const { t } = useTranslations();
  const { user } = useUsersState()

  const {
    editMode,
    loading,
    handleEditMode,
    generateWaiverFile,
    viewData,
    generateFileLoader,
    handleWaiversOfSubrogation,
  } = useWaivers(props);

  useEffect(() => {
    const { agencyID, insuredID } = formData;

    setSkeletonLoader(true);
    const collectionRef = collection(
      db,
      "agencies",
      agencyID,
      "insureds",
      insuredID,
      "waivers-of-subrogation"
    );

    const unsubscribe = onSnapshot(collectionRef, (querySnapshot) => {
      const values = [];
      querySnapshot.forEach((doc) => {
        values.push({
          ...doc.data(),
          id: doc.id,
        });
      });
      setSkeletonLoader(false);
      setWaiversList(values);
    });
    return () => {
      unsubscribe();
    };
  }, [policyID]);

  const multipleDriverTableColumn = [
    {
      name: "namedInsured",
      label: t("waiver-subrogation.name-insured"),
    },
    {
      name: "company",
      label: t("waiver-subrogation.company"),
    },
    {
      name: "state",
      label: t("waiver-subrogation.state"),
    },
    {
      name: "city",
      label: t("waiver-subrogation.city"),
    },
    {
      name: "address",
      label: t("waiver-subrogation.address"),
    },
    {
      name: "zip",
      label: t("waiver-subrogation.zip"),
    },
    {
      name: "action",
      label: t("waiver-subrogation.see-details"),
    },
  ];

  const filterKeys = [
    "namedInsured",
    "company",
    "state",
    "city",
    "address",
    "zip",
  ];

  const [skeletonLoader, setSkeletonLoader] = useState(false);

  const formingTableData = () => {
    const effectiveElementsList = waiversList.filter((item) => {
      return !isExpired(item?.expirationDate);
    });
    const tableData = effectiveElementsList?.map((data, dataIndex) => {
      const { namedInsured, company, state, city, address, zip } = data || {};
      const iconLeft = [];
      const iconRight = [];
      if (
        formData.documentType === "Application" ||
        formData.documentType === "Policy"
      ) {
        iconRight.push({
          icon: "delete",
          action: true,
          toField: "action",
        });
      }
      return {
        ...data,
        tableInfo: {
          namedInsured: {
            text: namedInsured,
          },
          company: {
            text: company,
          },
          state: {
            text: state,
          },
          city: {
            text: city,
          },
          address: {
            text: address,
          },
          zip: {
            text: zip,
          },
          actions: {
            actions: ["view"],
          },
        },
        dataIndex,
        iconLeft,
        iconRight,
      };
    });
    return tableData;
  };

  const [tableData, setTableData] = useState([]);
  const [effectiveDate, setEffectiveDate] = useState(
    viewData?.effectiveDate ? viewData?.effectiveDate?.toDate() : null
  )

  useEffect(() => {
    if (typeof waiversList.length !== "undefined") {
      const tableData = formingTableData(false, "");
      setTableData(tableData);
    }
  }, [waiversList, viewData]);

  const handleOpenEditMode = (e, item) => {
    e.preventDefault();
    handleEditMode(item);
  };

  const isMobileScreen = useMediaQuery("(max-width: 600px)");

  const [data, setData] = useState({
    effectiveDate: new Date(),
  });

  useEffect(() => {
    if (Boolean(viewData)) {
      setEffectiveDate(viewData?.effectiveDate?.toDate())
      setData({
        effectiveDate: viewData?.effectiveDate,
        companyName: viewData?.company,
        address: viewData?.address,
        city: viewData?.city,
        state: viewData?.state,
        zip: viewData?.zip,
      });
    }
  }, [viewData])

  const [loadingWaiverCompanies, setLoadingWaiverCompanies] = useState(false);
  const [waiverCompanies, setWaiverCompanies] = useState([]);
  const [openAddCompanyModal, setOpenAddCompanyModal] = useState(false);

  const handleChangeAddCompanyModal = () => {
    setOpenAddCompanyModal(!openAddCompanyModal);
  }

  useEffect(() => {
    const fetchWaiverCompanies = () => {
      setLoadingWaiverCompanies(true);
      const waiverOfSubrogationCompaniesRef = collection(db, "waiver-companies");
      const q = query(waiverOfSubrogationCompaniesRef);
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const companies = [];
        querySnapshot.forEach((doc) => {
          companies.push({ ...doc.data(), id: doc.id });
        });
        setWaiverCompanies(companies);
        setLoadingWaiverCompanies(false)
      });
      return unsubscribe;
    };
    const unsubscribe = fetchWaiverCompanies();
    return () => unsubscribe();
  }, []);

  const handleSubmitAddCompany = async (e, data) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, "waiver-companies"), data);
      handleChangeAddCompanyModal();
    } catch (error) {
      console.error(error)
    }
  }

  const options = useMemo(() => {
    const convertedOptions = waiverCompanies.map((company, companyIndex) => {
      return {
        id: companyIndex + 4,
        key: "",
        label: company.company,
        ...company
      }
    })
    return convertedOptions
  }, [waiverCompanies])

  const formSettings = {
    onSubmit: handleSubmitAddCompany,
    inputs: [
      {
        label: "Company Name",
        name: "company",
        required: true,
        type: "text",
      },
      {
        label: "Address",
        name: "address",
        required: true,
        type: "text",
      },
      {
        label: "City",
        name: "city",
        required: true,
        type: "text",
      },
      {
        label: "State",
        name: "state",
        required: true,
        type: "select",
        options: companyUSStateOptions
      },
      {
        label: "Zip",
        name: "zip",
        required: true,
        type: "text",
      },
    ],
    buttonLabel: "Add Custom Company",
  };

  return (
    <>
      <FormPopup
        isOpen={openAddCompanyModal}
        onRequestClose={handleChangeAddCompanyModal}
        title="Create Custom Company for Waivers of Subrogation"
        formSettings={formSettings}
        loading={loading}
      />
      <h1 className="text-2xl mt-2.5 mb-3 font-bold md:mb-0">
        {t("waiver-subrogation.title")}
      </h1>
      <p className="mb-4">{t("waiver-subrogation.subtitle")}</p>
      {editMode ? (
        <div>
          <GoBack
            handleFn={() => {
              handleEditMode()
              setData({
                effectiveDate: new Date(),
              })
            }}
            disabled={loading}
            className={"!mb-[-30px]"}
          />
          {viewData?.file ? (
            <PDFPreviewer height={300} file={viewData?.file} />
          ) : Boolean(viewData) ? (
            <Button
              label="Generate Waiver File"
              onClick={generateWaiverFile}
              className={
                "h-[20px] !w-[240px] !sm:w-full max-w-[700px] mx-auto my-16 p-4"
              }
              loading={generateFileLoader}
              disabled={generateFileLoader}
            />
          ) : null}
          <div className="mt-3">
            <label className="">Effective Date:</label>
            <DatePicker
              wrapperClassName="datePicker"
              required
              selected={effectiveDate}
              onChange={(date) => {
                setEffectiveDate(date);
                setData({
                  ...data,
                  effectiveDate: date,
                });
              }}
              className="w-full p-2 border"
              disabled={Boolean(viewData)}
            />

            {user?.role === "Admin" || user?.role === 'Underwriter' ?
              <div>
                <BlueButton
                  text="Add Custom Company"
                  onClickFunction={handleChangeAddCompanyModal}
                  hasContentToTop={true}
                  hasContentToBottom={true}
                />
              </div> : null
            }
            {!Boolean(viewData) ?
              <>
                <label htmlFor="company" className="mt-1">
                  Select Company:
                </label>
                <select
                  required
                  onChange={(e) => {
                    const value = e.target.value;
                    const selectedCompany = waiverCompanies.find(company => company?.company === value)
                    setData({
                      ...data,
                      company: selectedCompany?.company || value,
                      companyName: "",
                      address: selectedCompany?.address || "",
                      city: selectedCompany?.city || "",
                      state: selectedCompany?.state || "",
                      zip: selectedCompany?.zip || "",
                    });
                  }}
                  value={data.company}
                  type="text"
                  id="company"
                  className="w-full p-2 border"
                >
                  <option>Select Company</option>
                  {loadingWaiverCompanies ? [] :
                    options.map((option, index) => {
                      return (
                        <option key={index} value={option.company}>
                          {option.company}
                        </option>
                      );
                    })
                  }
                  <option value="Other">Other</option>
                </select>
              </> : null
            }
            <>
              {(data?.company === "Other") || Boolean(viewData) ? (
                <>
                  <label className="mt-1">Company:</label>
                  <input
                    required
                    onChange={(e) => {
                      setData({
                        ...data,
                        companyName: e.target.value,
                      });
                    }}
                    value={data.companyName}
                    type="text"
                    id="companyName"
                    className="w-full p-2 border"
                    disabled={data.company !== "Other" || Boolean(viewData)}
                  />
                  <label className="mt-1">Address:</label>
                  <input
                    required
                    onChange={(e) =>
                      setData({
                        ...data,
                        address: e.target.value,
                      })
                    }
                    value={data.address}
                    type="text"
                    id="address"
                    className="w-full p-2 border"
                    disabled={Boolean(viewData)}
                  />
                  <label className="mt-1">City:</label>
                  <input
                    required
                    onChange={(e) =>
                      setData({
                        ...data,
                        city: e.target.value,
                      })
                    }
                    value={data.city}
                    type="text"
                    id="city"
                    className="w-full p-2 border"
                    disabled={Boolean(viewData)}
                  />
                  <label className="mt-1">State:</label>
                  <select
                    required
                    onChange={(e) =>
                      setData({
                        ...data,
                        state: e.target.value,
                      })
                    }
                    value={data.state}
                    type="text"
                    id="state"
                    className="w-full p-2 border"
                    disabled={Boolean(viewData)}
                  >
                    <option>Select an state</option>
                    <option value="AL">Alabama</option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option>
                    <option value="CA">California</option>
                    <option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option>
                    <option value="DE">Delaware</option>
                    <option value="DC">District Of Columbia</option>
                    <option value="FL">Florida</option>
                    <option value="GA">Georgia</option>
                    <option value="HI">Hawaii</option>
                    <option value="ID">Idaho</option>
                    <option value="IL">Illinois</option>
                    <option value="IN">Indiana</option>
                    <option value="IA">Iowa</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NV">Nevada</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option>
                    <option value="OH">Ohio</option>
                    <option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="SD">South Dakota</option>
                    <option value="TN">Tennessee</option>
                    <option value="TX">Texas</option>
                    <option value="UT">Utah</option>
                    <option value="VT">Vermont</option>
                    <option value="VA">Virginia</option>
                    <option value="WA">Washington</option>
                    <option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WY">Wyoming</option>
                  </select>
                  <label className="mt-1">Zip Code:</label>
                  <input
                    required
                    onChange={(e) =>
                      setData({
                        ...data,
                        zip: e.target.value,
                      })
                    }
                    value={data.zip}
                    type="text"
                    id="zip"
                    className="w-full p-2 border"
                    disabled={Boolean(viewData)}
                  />
                </>
              ) : null}
            </>
            {Boolean(viewData) ? (
              <Button
                label="Generate Waiver File"
                onClick={generateWaiverFile}
                className={
                  "h-[20px] !w-[240px] !sm:w-full max-w-[700px] mx-auto my-16 p-4"
                }
                loading={generateFileLoader}
                disabled={generateFileLoader && (
                  effectiveDate || viewData?.effectiveDate
                )}
              />
            ) : null}
          </div>
          {!viewData ?
            <ApprovalButtons
              onApprove={(e) => {
                handleWaiversOfSubrogation(e, data);
              }}
              onDecline={(e) => {
                e.preventDefault();
                handleEditMode();
              }}
              titleAccept="Continue"
              titleDecline="Go Back"
              disabledAccept={!Boolean(effectiveDate)}
            /> : null
          }
        </div>
      ) : (
        <>
          {Boolean(formData?.hasALCoverage) ||
          Boolean(formData?.hasGLCoverage) ? (
            <BlueButton
              text={"Request Waiver of Subrogation"}
              onClickFunction={() => handleEditMode()}
              disabled={false}
              hasContentToBottom={true}
              hasContentToRight={!isMobileScreen}
              className={isMobileScreen ? "w-[100%]" : ""}
            />
          ) : null}
          <DynamicTable
            skeletonLoader={skeletonLoader}
            columns={multipleDriverTableColumn}
            tableData={tableData}
            deleteThisItem={() => {}}
            onClickItem={() => {}}
            filterKeys={filterKeys}
            handleOpenEditMode={handleOpenEditMode}
          />
        </>
      )}
    </>
  );
};

export default WaiverOfSubrogation;
