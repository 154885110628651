import { useContext } from "react"
import { AppContext, initialState } from "../AppContext"
import { SET_ENDORSEMENTS } from "../types"

const useEndorsementsState = () => {
    const { state, dispatch } = useContext(AppContext)
    const { endorsements } = state || initialState

    const setEndorsements = result => {
        dispatch({ type: SET_ENDORSEMENTS, payload: result })
    }

    return {
        setEndorsements,
        endorsements
    }
}

export default useEndorsementsState