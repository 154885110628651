const calculateGrandTotalOfCashValues = (truckArray, trailerArray) => {
    const calculateTotal = (array) => {
      if (!Array.isArray(array) || array.length === 0) {
        return 0; // Return 0 for an empty or non-array input
      }
  
      // Use the reduce function to calculate the total of cash values for the given array
      return array.reduce((total, current) => {
        const cashValue = parseInt(current.truckACV) || parseInt(current.trailerACV) || 0;
        return total + cashValue;
      }, 0);
    };
  
    // Calculate the total for truckArray and trailerArray separately
    const truckTotal = calculateTotal(truckArray);
    const trailerTotal = calculateTotal(trailerArray);
  
    return truckTotal + trailerTotal;
  };
  
  export default calculateGrandTotalOfCashValues;
  