import React, { useState } from "react";
import {
  Button,
  Modal,
  TextField,
  IconButton,
  Typography,
  Box,
  useMediaQuery,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { doc, updateDoc } from "firebase/firestore";
import { auth, db } from "../../../firebase";
import returnToAgent from "../../utils/returnToAgent";
import ApprovalButtons from "../../assets/forms/ApprovalButtons";

export default function NonRenew({ onClose, policyID, setNonRenew }) {
  const policyRef = doc(db, "policy-applications", policyID);

  const [reasons, setReasons] = useState([""]);

  const handleAddReason = () => {
    setReasons([...reasons, ""]);
  };

  const handleChangeReason = (index, value) => {
    const updatedReasons = [...reasons];
    updatedReasons[index] = value;
    setReasons(updatedReasons);
  };

  const handleRemoveReason = (index) => {
    const updatedReasons = [...reasons];
    updatedReasons.splice(index, 1);
    setReasons(updatedReasons);
  };

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      // Construct the updated data object
      const updatedData = {
        nonRenewalReasons: reasons,
        nonRenewal: true,
        nonRenewalDate: new Date(),
        status: "Non-Renewal",
      };

      // Update the document in Firestore
      await updateDoc(policyRef, updatedData);

      // Reset reasons to an empty array and close the modal
      setReasons([""]);
      setNonRenew(false);
      onClose();
    } catch (error) {
      console.error("Error updating document:", error);
      // Handle error (e.g., display an error message to the user)
    }
  };

  const isMobileScreen = useMediaQuery("(max-width: 600px)");
  return (
    <>
      <form onSubmit={e => handleSubmit(e)}>
        <Typography className="mt-2" variant="h6" gutterBottom>
          Reasons for Non-Renewal
        </Typography>
        <p className="my-3">
          Please enter the reason(s) why the policy is being marked as a
          non-renewal.
        </p>
        {reasons.map((reason, index) => (
          <Box key={index} display="flex" alignItems="center" mb={2}>
            <TextField
              InputLabelProps={{ style: { fontSize: "1.2rem" } }}
              required
              label={`Reason ${index + 1}`}
              value={reason}
              onChange={(e) => handleChangeReason(index, e.target.value)}
              fullWidth
            />
            <IconButton onClick={() => handleRemoveReason(index)}>
              <DeleteIcon />
            </IconButton>
          </Box>
        ))}
        <Button
          onClick={handleAddReason}
          variant="outlined"
          sx={{ mb: 2, color: "#072a48", borderColor: "#072a48" }}
        >
          Add A Reason
        </Button>
        <ApprovalButtons
          onApprove={null}
          onDecline={(e) => {
            setReasons([]);
            setNonRenew(false)
            onClose();
          }}
          titleAccept={"Submit"}
          titleDecline={"Cancel"}
          disabledAccept={false}
          disabledDecline={false}
          handleAsSubmit={true}
        />
      </form>
    </>
  );
}
