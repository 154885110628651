import React from "react";
import useTranslations from "../../../Context/actions/useTranslations";

export default function YesNo(props) {
  const { t } = useTranslations();
  const { property, formData, setFormData } = props;

  const handleChange = async (e) => {
    try {
      const { value } = e.target;
      setFormData({ ...formData, [property]: value });
    } catch (error) {
      throw error;
    }
  };

  return (
    <div>
      <label
        htmlFor={property}
        className="block text-sm font-medium text-gray-700"
      >
        {props.label}
      </label>
      <select
        onChange={handleChange}
        className="mt-1 p-1 block w-full rounded-md border border-gray-300 shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        value={formData?.[property] || ""}
      >
        <option value=""></option>
        <option value="Yes">{t("plm.yes")}</option>
        <option value="No">{t("plm.no")}</option>
      </select>
    </div>
  );
}
