import React from "react";
import { t } from "i18next";
import { formatPrice } from "../../../Finances/Tables/utils";

const LossDataDisplay = ({ formData, year, resetLosses, coverageType }) => {
  return (
    <div className="my-4">
      <article>
        <p className="mb-2">
          <span className="font-semibold">{t("Loss Ratio")}: </span>
          {parseFloat(formData.lossHistory?.[`year${year + "" + coverageType}LossData`].lossRatio).toFixed(2)}
        </p>
        <p className="mb-2">
          <span className="font-semibold">{t("Annual Premium")}: </span>
          {formatPrice(
            formData.lossHistory?.[`year${year + "" + coverageType}LossData`].annualPremium
          )}
        </p>
        <p className="mb-2">
          <span className="font-semibold">{t("Carrier")}: </span>
          {formData.lossHistory?.[`year${year + "" + coverageType}LossData`].carrier}
        </p>
        <p className="mb-2">
          <span className="font-semibold">{t("Number of Units")}: </span>
          {formData.lossHistory?.[`year${year + "" + coverageType}LossData`].numberOfUnits}
        </p>
        <p className="mb-2">
          <span className="font-semibold">{t("Premium")} PPU: </span>
          {formatPrice(formData.lossHistory?.[`year${year + "" + coverageType}LossData`].premiumPPU)}
        </p>
        <p className="mb-2">
          <span className="font-semibold">{t("Losses")}: </span>
          {formatPrice(formData.lossHistory?.[`year${year + "" + coverageType}LossData`].losses)}
        </p>
        <p className="mb-2">
          <span className="font-semibold">{t("Open BI Claims")}: </span>
          {formData.lossHistory?.[`year${year + "" + coverageType}LossData`].openBIClaims}
        </p>
        <p className="mb-2">
          <span className="font-semibold">{t("Open At Fault BI Claims")}: </span>
          {formData.lossHistory?.[`year${year + "" + coverageType}LossData`].openAtFaultBIClaims}
        </p>
        <p className="mb-2">
          <span className="font-semibold">{t("Total Open Claims")}: </span>
          {formData.lossHistory?.[`year${year + "" + coverageType}LossData`].totalOpenClaims}
        </p>
      </article>
      {formData.documentType === "Application" && (
        <button
          onClick={(e) => resetLosses(e, `year${year + "" + coverageType}LossData`)}
          className="bg-[#8B0000] text-white w-full p-2 mt-4 rounded"
        >
          {t("Reset")}
        </button>
      )}
    </div>
  );
};

export default LossDataDisplay;
