import ProgressLoader from "../../generals/ProgressLoader";
import { useEffect, useState } from "react";
import useGeneralState from "../../../Context/actions/useGeneralState";
import { isPastSomeMinutes } from "../../../utils/helpers";

const PdfGenerator = ({
    percentage,
    policiesPdf
}) => {

  const {
    url,
    loading,
    error,
    dateWasCancel
  } = policiesPdf || {
    loading: false,
    url: '',
    error: false,
    cancelRequest: false,
    loadingCancel: false,
    dateWasCancel: null
  }
  const { controlToast } = useGeneralState()
  const [completed, setCompleted] = useState(false)

  useEffect(() => {
    if (percentage === 100) {
        setCompleted(true)
    }
  }, [percentage, loading])

  useEffect(() => {
    if (error && completed) {
      controlToast(
        true,
        'There was an error in the service',
        'error'
      )
    } else if (url && completed) {
      controlToast(
        true,
        'Policy was successfully created to be printed',
        'success'
      )
    }
  }, [error, url])

  return (
    <>
      {Boolean((loading && !completed) || error || percentage !== 0) &&
      isPastSomeMinutes(dateWasCancel) ?
        <ProgressLoader percentage={percentage} error={error} /> : null}
    </>
  );
};

export default PdfGenerator;
