import { collection, query, where, limit, doc, getDocs, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";

const getUniqueIdentifier = async (program, insuredID, coverage, agencyID) => {
  let collectionRef;
  const uniqueIdentifiersNAFTARef = collection(
    db,
    "accreditedUniqueIdentifiers"
  );
  const uniqueIdentifiersDomesticRef = collection(
    db,
    "accreditedDomesticUniqueIdentifiers"
  );

  if (program === "NAFTA") collectionRef = uniqueIdentifiersNAFTARef;
  else if (program === "Domestic") collectionRef = uniqueIdentifiersDomesticRef;

  // Create a query against the collection to get the first document with status 0.
  const q = query(
    collectionRef,
    where("status", "==", 0),
    limit(1)
  );
  
  try {
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      console.log("Document found");
      // Retrieve the first document
      const firstDocument = querySnapshot.docs[0];
      const uniqueIdentifier = firstDocument.data().uniqueIdentifier;
      // Update the document in the collection
      const uniqueIdentifierRef = doc(collectionRef, firstDocument.id);
      const insuredRef = doc(db, "agencies", agencyID, "insureds", insuredID);
      const fieldName = `uniqueIdentifier${program + coverage}`;

      await updateDoc(uniqueIdentifierRef, {
        status: 1,
        customer: insuredID,
        type: coverage,
      });

      await updateDoc(insuredRef, {
        [fieldName]: uniqueIdentifier
      });
      return uniqueIdentifier; // Return the unique identifier
    } else {
      console.log("Error assigning unique identifier: No documents found.");
      return null; // Return null if no unique identifier is found
    }
  } catch (error) {
    console.error("Error assigning unique identifier:", error);
    return null; // Return null if there is an error
  }
}

export default getUniqueIdentifier;
