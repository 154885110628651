import Popup from "..";
import Form from "../../Forms/Form";

export default function FormPopup({
  isOpen,
  onRequestClose,
  title,
  formSettings,
  loading,
  zIndex
}) {
  return (
    <Popup zIndex={zIndex} isOpen={isOpen} onRequestClose={onRequestClose}>
      <div>
        <p className="text-center mt-[30px] mb-[-54px]">
          {title}
        </p>
        <Form formSettings={formSettings} loading={loading} isSmallVersion={true} />
      </div>
    </Popup>
  );
}
