import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase";

const normalizeCityName = (cityName) => {
  return cityName
    .toLowerCase()
    .replace(/\s+/g, '');
};

export const getCounty = async (city, state) => {
  try {
    const normalizeCity = normalizeCityName(city)
    const querySnapshot = await getDocs(
      query(
        collection(db, "us-cities"),
        where("cityNormalized", "==", normalizeCity),
        where("stateId", "==", state)
      )
    );
    const counties = [];
    querySnapshot.forEach((doc) => {
      if (doc.exists()) {
        counties.push(doc.data().countyName);
      }
    });
    return counties;
  } catch (error) {
    console.error('Error getting counties:', error);
    return [];
  }
};
