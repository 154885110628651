import updatePolicyField from "../../../utils/updatePolicyField";

const updateCommodityTotal = (policyID, formData) => {
    let customCommodityTotal = 0;
    formData.commodityFields.forEach((element) => {
      customCommodityTotal =
        parseInt(customCommodityTotal, 10) +
        parseInt(element.commodityLoad, 10);
    });
    const standardCommoditiesTotal = Object.values(
      formData.standardCommodityPercentages ?? {}
    )
      .filter((value) => !isNaN(parseInt(value, 10))) // Filter out non-integer values
      .reduce((a, b) => a + parseInt(b, 10), 0);
    updatePolicyField(
      policyID,
      "commodityTotal",
      parseInt(customCommodityTotal, 10) + parseInt(standardCommoditiesTotal)
    );
  };

  export default updateCommodityTotal;