import React, { useState } from "react";
import ALPremiums from "./ALPremiums";
import GLPremiums from "./GLPremiums";
import QuotesTable from "../QuotesTable";
import { auth } from "../../../../firebase";
import MTCPremiums from "../APDandMTC/MTC/MTCPremiums";
import APDPremiums from "../APDandMTC/APD/APDPremiums";

export default function ALandGL({
  formData,
  getQuoteHistory,
  clearQuoteList,
  applyAdjustments,
  userInfo,
  openModal,
  quoteHistory,
  policyID,
  createNewQuote,
  taxSchedule,
  adjustmentFormData,
  setAdjustmentFormData,
  truckListData,
  setShowQuoteSuccess,
  setLoading,
  insuredInfo,
}) {
  const isQuoteRequestOrBindOrder =
    formData.documentType === "Quote Request" ||
    formData.documentType === "Bind Order";
  const hasALCoverage = formData.hasALCoverage;
  const hasGLCoverage = formData.hasGLCoverage;
  const hasALOrGL = hasALCoverage || hasGLCoverage;
  return (
    <div>
      <div className="card-body">
        {isQuoteRequestOrBindOrder && hasALOrGL && (
          <div className="my-3">
            <QuotesTable
              setLoading={setLoading}
              adjustmentFormData={adjustmentFormData}
              setAdjustmentFormData={setAdjustmentFormData}
              truckListData={truckListData}
              setShowQuoteSuccess={setShowQuoteSuccess}
              taxSchedule={taxSchedule}
              insuredInfo={insuredInfo}
              getQuoteHistory={getQuoteHistory}
              clearQuoteList={clearQuoteList}
              applyAdjustments={applyAdjustments}
              userInfo={userInfo}
              formData={formData}
              openModal={openModal}
              quoteHistory={quoteHistory}
              policyID={policyID}
              createNewQuote={createNewQuote}
            />
          </div>
        )}
        {hasALCoverage && (
          <div className="my-4">
            <ALPremiums policyID={policyID} formData={formData} />
          </div>
        )}
        {hasGLCoverage && (
          <div className="my-4">
            <GLPremiums policyID={policyID} formData={formData} />
          </div>
        )}
        {formData.hasAPDCoverage && (
          <div className="my-4">
            <APDPremiums formData={formData} />
          </div>
        )}
        {formData.hasMTCCoverage && (
          <div className="my-4">
            <MTCPremiums formData={formData} />
          </div>
        )}
      </div>
    </div>
  );
}
