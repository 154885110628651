import { useState, useEffect, useRef } from "react";
import {
  activeFilters,
  manageEndorsementOptions,
} from "../../utils/searchFilters";
import ApiService from "../../services/southern-api/apiService";
import usePaginationsState from "../../Context/actions/usePaginationsState";
import { initialState } from "../../Context/AppContext";

const useEndorsements = (props) => {
  const policyID = props.policyID;
  const insuredInfo = props.insuredInfo;
  const userInfo = props.userInfo;
  const endoEffectiveDate = props.endoEffectiveDate;
  const formData = props.formData;
  const truckData = props.truckData;
  const agencyInfo = props.agencyInfo;

  const apiService = new ApiService('southern')

  let subtitle;
  const [modalIsOpen, setIsOpen] = useState(false);

  const [currentEndoFilters, setCurrentEndoFilters] = useState({
    submitted: true,
  });

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }

  const [endorsements, setEndorsements] = useState([]);
  const [skeletonTableLoader, setSkeletonTableLoader] = useState(false)
  const filters = activeFilters(manageEndorsementOptions, currentEndoFilters);

  const {
    paginations,
    setPaginations
  } = usePaginationsState()

  const previousFiltersRef = useRef(currentEndoFilters);

  const handleEndorsementRequest = async () => {
    const filteredChanged = Boolean(
      JSON.stringify(previousFiltersRef.current) !==
      JSON.stringify(currentEndoFilters)
    )
    try {
      setSkeletonTableLoader(true)
      const result = await apiService.paginatedRequestsEndorsement({
        currentPage:
          filteredChanged ?
            initialState.paginations.endorsementsPagination.currentPage :
            paginations?.endorsementsPagination?.currentPage,
        itemsPerPage:
          filteredChanged ?
            initialState.paginations.endorsementsPagination.itemsPerPage :
            paginations?.endorsementsPagination?.itemsPerPage,
        filters
      })
      setPaginations({
        ...paginations,
        ["endorsementsPagination"]: {
          totalCount: result?.pagination?.totalCount,
          currentPage: result?.pagination?.currentPage,
          pageCount: result?.pagination?.pageCount,
          itemsPerPage: result?.pagination?.itemsPerPage,
          nextPage: result?.pagination?.nextPage,
          prevPage: result?.pagination?.prevPage,
        },
      });
      setEndorsements(result?.data)
      setSkeletonTableLoader(false)
      previousFiltersRef.current = currentEndoFilters;
    } catch (error) {
      console.error(error)
      setEndorsements([])
      setSkeletonTableLoader(false)
    }
  }

  const [didRequest, setDidRequest] = useState(false)

  useEffect(() => {
    if (!didRequest) {
      handleEndorsementRequest()
      setDidRequest(true)
    }
  }, [currentEndoFilters]);

  useEffect(() => {
    if (didRequest) {
      handleEndorsementRequest()
    }
  }, [
    currentEndoFilters,
    paginations?.endorsementsPagination?.currentPage,
  ]);

  return {
    endorsements,
    openModal,
    modalIsOpen,
    afterOpenModal,
    closeModal,
    policyID,
    insuredInfo,
    agencyInfo,
    userInfo,
    endoEffectiveDate,
    formData,
    truckData,
    currentEndoFilters,
    setCurrentEndoFilters,
    skeletonTableLoader
  };
};

export default useEndorsements;
