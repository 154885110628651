import { IconButton } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";

const DownloadButton = ({ downloadFunction, className, disabled = false }) => {
  return (
    <IconButton
      onClick={downloadFunction}
      disabled={disabled}
      className={className}
      style={{ 
        position: 'absolute', 
        top: 20, 
        right: 20, 
        color: "#072a48",  // Custom color for the icon
        height: 50,
        width: 50,  
      }}
    >
      <PrintIcon />
    </IconButton>
  );
};

export default DownloadButton;
