import React, { useEffect, useState } from "react";
// import { t } from "i18next";
import BigAlert from "../../generals/Toast/BigAlert";
import "react-datepicker/dist/react-datepicker.css";
import { setPolicyInfo } from "./setPolicyInfo";
import FormPopup from "../../generals/Popup/FormPopup";
import Step1 from "./addDriverSteps/Step1";
import Step2 from "./addDriverSteps/Step2";
import Step3 from "./addDriverSteps/Step3";
import Breadcrumbs from "../../assets/Breadcrumbs";
import useLossControlListener from "../../utils/useLossControlListener";
import useTranslations from "../../../Context/actions/useTranslations";

export default function AddDriver(props) {
  const setShowAddDriver = props.setShowAddDriver;
  const setShowTable = props.setShowTable;
  const isEndoManager = props.isEndoManager;
  const formData = props.formData;
  const driverList = props.driverList;
  const policyID = props.policyID;
  const insuredInfo = props.insuredInfo;

  const [success, setSuccess] = useState(false);
  const [endoEffectiveDate, setEndoEffectiveDate] = useState(new Date());
  const [dobDate, setDobDate] = useState(new Date());
  const [licenseExpirationDate, setLicenseExpirationDate] = useState(
    new Date()
  );
  const [driverData, setDriverData] = useState({
    licenseExpirationDate: new Date(),
  });
  const [progresspercent, setProgresspercent] = useState(0);

  const goBack = (e) => {
    e.preventDefault();
    setDriverData({});
    setShowAddDriver(false);
    setShowTable(true);
  };

  const { t } = useTranslations();

  const [showStep1, setShowStep1] = useState(true);
  const [showStep2, setShowStep2] = useState(false);
  const [showStep3, setShowStep3] = useState(false);
  const [fileObjectsLicenseFront, setFileObjectsLicenseFront] = useState([]);
  const [fileObjectsLicenseBack, setFileObjectsLicenseBack] = useState([]);
  const [fileObjectsMvr, setFileObjectsMvr] = useState([]);
  const [fileObjectsCaDriverAbstract, setFileObjectsCaDriverAbstract] =
    useState([]);
  const [fileObjectsMedicalForm, setFileObjectsMedicalForm] = useState([]);
  const [fileObjectsAdditionalForm, setFileObjectsAdditionalForm] = useState([]);
  const [fileObjectsOtherDocuments, setFileObjectsOtherDocuments] = useState([]);
  const [openModalAddDriverException, setOpenModalAddDriverException] =
    useState(false);
  const handleCloseAddDriverModal = () => {
    setOpenModalAddDriverException(false);
  };

  const handleAddValue = (e, value) => {
    e.preventDefault();
    setDriverData({
      ...driverData,
      exceptionReason: value["exception-reason"]
    });
    goToSecondStep();
    handleCloseAddDriverModal();
  };

  const addDriverModal = [
    {
      label: "Add a reason for exception",
      type: "text",
      name: "exception-reason",
      required: true,
    }
  ];

  const formSettingsAddDriver = {
    onSubmit: handleAddValue,
    inputs: addDriverModal,
    buttonLabel: "Add driver exception",
  };

  const goToSecondStep = () => {
    setShowStep1(false);
    setShowStep2(true);
  };

  const breadcrumbs = [
    { label: formData.documentType },
    { label: "Drivers" },
    { label: "Add Driver" },
  ];

  const [fmcsaDriverFitness, setFmcsaDriverFitness] = useState([]);

  useLossControlListener(
    "driverData",
    "fmcsaDriverFitness",
    setFmcsaDriverFitness
  );

  const activeDriversWithAtLeas5YearsOfExperience = driverList.filter((driver) => {
    const validPolicyDetail = driver.policiesDetails.find((policyDetail) => {
      return policyDetail.id === policyID && policyDetail.status === "Active"
    })
    const isAtLeast5Years = driver.yoe >= 5

    const driverFitnessMatch = fmcsaDriverFitness.find((fitness) => {
      const fitnessLicenseNumber =
        fitness.nacionality === "Mexicano" ?
        fitness.drvLicMx : fitness.drvLicUs
      return fitnessLicenseNumber === driver.licenseNumber
    })

    const isFitnessDriverInNotTraining =
      driverFitnessMatch ?
      driverFitnessMatch.inTraining === 'NO ONGOING TRAINING' : true

    return Boolean(
      validPolicyDetail &&
      isAtLeast5Years &&
      isFitnessDriverInNotTraining
    )
  })

  const selectInsureds = [
    'ARIZPE TRANSPORT AND LOGISTICS LLC',
    'FELHER TRANSPORTATION INC',
    'ROLESA TRANSPORT LLC',
    'DETA LOGIS PLUS LLC',
    'DETA LOGISTICS LLC',
    'TRADEX FREIGHT INC',
    'TRADEX LOGISTICS INC',
  ]
  const isSelectInsured = selectInsureds.includes(insuredInfo?.company || '')
  
  const appearATutorInput = Boolean(
    activeDriversWithAtLeas5YearsOfExperience.length > 0 &&
    isSelectInsured &&
    driverData.yoe < 2
  )

  useEffect(() => {
    if (!appearATutorInput) {
      setDriverData({
        ...driverData,
        tutor: ''
      })
    }
  }, [driverData.yoe])

  return (
    <>
      <FormPopup
        isOpen={openModalAddDriverException}
        formSettings={formSettingsAddDriver}
        onRequestClose={handleCloseAddDriverModal}
        title="Driver has less than 2 years of experience, provide a reason to add it."
      />
      {success ? (
        <BigAlert
          title="Your endorsement has been successfully submitted."
          subtitle="An underwriter will review your endorsement, and you will receive a notification when the status is updated."
          okButton="OK"
          className="mt-2"
          status="successful"
          fn={goBack}
        />
      ) : (
        <main>
          <header>
            {!isEndoManager ? <Breadcrumbs items={breadcrumbs} /> : null}
            <h1
              className={`${
                isEndoManager
                  ? "text-2xl mt-2.5 font-bold md:mb-0"
                  : "text-3xl mt-2 font-semibold md:mb-0"
              }`}
            >
              {!isEndoManager ? `${t("Add Driver")}` : `${t("Add Driver")}`}
            </h1>
            <p className="mt-2 mb-3">
              {(formData.documentType === "Application" ||
                formData.documentType === "Policy") && (
                <>
                  {t('plm.drivers.details.add-driver')}
                </>
              )}
              {formData.documentType !== "Application" &&
                formData.documentType !== "Policy" && (
                  <>{t('plm.drivers.details.quoting-phase')}</>
                )}
            </p>
          </header>
          <section className="card mx-auto max-w-[750px] mb-4 shadow-lg">
            <div className="card-body">
              {showStep1 && (
                <Step1
                  parentProps={props}
                  driverData={driverData}
                  setDriverData={setDriverData}
                  setEndoEffectiveDate={setEndoEffectiveDate}
                  endoEffectiveDate={endoEffectiveDate}
                  dobDate={dobDate}
                  setDobDate={setDobDate}
                  setOpenModalAddDriverException={
                    setOpenModalAddDriverException
                  }
                  goToSecondStep={goToSecondStep}
                  progresspercent={progresspercent}
                  goBack={goBack}
                  appearATutorInput={appearATutorInput}
                  activeDriversWithAtLeas5YearsOfExperience={activeDriversWithAtLeas5YearsOfExperience}
                />
              )}
              {showStep2 && (
                <Step2
                  driverData={driverData}
                  setDriverData={setDriverData}
                  licenseExpirationDate={licenseExpirationDate}
                  setLicenseExpirationDate={setLicenseExpirationDate}
                  progresspercent={progresspercent}
                  goBack={goBack}
                  setShowStep2={setShowStep2}
                  setShowStep3={setShowStep3}
                  setShowStep1={setShowStep1}
                  driverList={driverList}
                />
              )}
              {showStep3 && (
                <Step3
                  parentProps={props}
                  driverData={driverData}
                  setDriverData={setDriverData}
                  fileObjectsMedicalForm={fileObjectsMedicalForm}
                  fileObjectsLicenseBack={fileObjectsLicenseBack}
                  fileObjectsLicenseFront={fileObjectsLicenseFront}
                  fileObjectsMvr={fileObjectsMvr}
                  fileObjectsCaDriverAbstract={fileObjectsCaDriverAbstract}
                  fileObjectsOtherDocuments={fileObjectsOtherDocuments}
                  setFileObjectsMedicalForm={setFileObjectsMedicalForm}
                  setFileObjectsLicenseBack={setFileObjectsLicenseBack}
                  setFileObjectsLicenseFront={setFileObjectsLicenseFront}
                  setFileObjectsMvr={setFileObjectsMvr}
                  setFileObjectsCaDriverAbstract={setFileObjectsCaDriverAbstract}
                  fileObjectsAdditionalForm={fileObjectsAdditionalForm}
                  setFileObjectsAdditionalForm={setFileObjectsAdditionalForm}
                  setFileObjectsOtherDocuments={setFileObjectsOtherDocuments}
                  endoEffectiveDate={endoEffectiveDate}
                  setSuccess={setSuccess}
                  setPolicyInfo={setPolicyInfo}
                  goBack={goBack}
                  setShowStep2={setShowStep2}
                  setShowStep3={setShowStep3}
                  appearATutorInput={appearATutorInput}
                  formData={formData}
                />
              )}
            </div>
          </section>
        </main>
      )}
    </>
  );
}
