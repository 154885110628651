import React from "react";

const ProgressIndicator = ({ progressState }) => {
  return (
    <>
      {progressState > 0 && progressState < 100 && (
        <div className="relative pt-1">
          <div className="flex mb-2 items-center justify-between">
            <div>
              <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-teal-600 bg-teal-200">
                Upload Progress
              </span>
            </div>
            <div className="text-right">
              <span className="text-xs font-semibold inline-block text-teal-600">
                {`${progressState}%`}
              </span>
            </div>
          </div>
          <div className="flex flex-col space-y-1">
            <div className="progress-bar-container">
              <div
                className="progress-bar bg-teal-500"
                style={{ width: `${progressState}%` }}
              ></div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProgressIndicator;
