import React, { useState, useEffect } from 'react';
import { doc, onSnapshot, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const FleetCreditManager = () => {
    const [fleetCredits, setFleetCredits] = useState([]);
    const [editingRow, setEditingRow] = useState(null);
    const [tempValues, setTempValues] = useState({});
    const [validationError, setValidationError] = useState('');

    useEffect(() => {
        const docRef = doc(db, "fleet-credits", "credits");
        const unsubscribe = onSnapshot(docRef, (docSnapshot) => {
            if (docSnapshot.exists()) {
                const data = docSnapshot.data();
                const sortedData = data.credits.sort((a, b) => a.MinVehCount - b.MinVehCount);
                setFleetCredits(sortedData);
            } else {
                console.log("No such document!");
            }
        });

        return () => unsubscribe();
    }, []);

    const handleEdit = (index) => {
        setEditingRow(index);
        setTempValues(fleetCredits[index]);
        setValidationError('');
    };

    const handleSave = async () => {
        if (editingRow !== null) {
            // Validation: Ensure min of current row is greater than max of previous row
            if (editingRow > 0) {
                const previousRow = fleetCredits[editingRow - 1];
                if (tempValues.MinVehCount <= previousRow.MaxVehCount) {
                    setValidationError('Min Vehicle Count must be greater than the Max Vehicle Count of the previous range.');
                    return;
                }
            }

            // Validation: Ensure max of current row is greater than min of current row
            if (tempValues.MaxVehCount < tempValues.MinVehCount) {
                setValidationError('Max Vehicle Count must be greater than or equal to the Min Vehicle Count.');
                return;
            }

            const updatedCredits = fleetCredits.map((credit, index) =>
                index === editingRow ? tempValues : credit
            );

            await updateDoc(doc(db, 'fleet-credits', 'credits'), {
                credits: updatedCredits
            });

            setFleetCredits(updatedCredits);
            setEditingRow(null);
            setValidationError('');
        }
    };

    const handleCancel = () => {
        setEditingRow(null);
        setTempValues({});
        setValidationError('');
    };

    const handleChange = (e, field) => {
        const { value } = e.target;
        setTempValues((prev) => ({
            ...prev,
            [field]: field === 'FleetCredit' ? parseFloat(value) : parseInt(value)
        }));
    };

    return (
        <div className="mt-3">
            <h1 className="text-xl font-bold mb-2">Fleet Credit Manager</h1>
            <p className='mb-4'>Fleet credits are set when a policy is bound. Changing the figures below will not affect existing policies, only policies quoted and bound after the changes are saved.</p>
            {validationError && <p style={{ color: 'red' }}>{validationError}</p>}
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow sx={{  }}>
                            <TableCell sx={{ backgroundColor: '#072a48', color: 'white' }}>Min Vehicle Count</TableCell>
                            <TableCell sx={{ backgroundColor: '#072a48', color: 'white' }}>Max Vehicle Count</TableCell>
                            <TableCell sx={{ backgroundColor: '#072a48', color: 'white' }}>Fleet Credit (%)</TableCell>
                            <TableCell sx={{ backgroundColor: '#072a48', color: 'white' }}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {fleetCredits.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell>
                                    {editingRow === index ? (
                                        <TextField
                                            type="number"
                                            value={tempValues.MinVehCount}
                                            onChange={(e) => handleChange(e, 'MinVehCount')}
                                            fullWidth
                                            error={editingRow > 0 && tempValues.MinVehCount <= fleetCredits[editingRow - 1].MaxVehCount}
                                            helperText={editingRow > 0 && tempValues.MinVehCount <= fleetCredits[editingRow - 1].MaxVehCount ? 'Must be greater than previous max' : ''}
                                        />
                                    ) : (
                                        row.MinVehCount
                                    )}
                                </TableCell>
                                <TableCell>
                                    {editingRow === index ? (
                                        <TextField
                                            type="number"
                                            value={tempValues.MaxVehCount}
                                            onChange={(e) => handleChange(e, 'MaxVehCount')}
                                            fullWidth
                                            error={tempValues.MaxVehCount < tempValues.MinVehCount}
                                            helperText={tempValues.MaxVehCount < tempValues.MinVehCount ? 'Must be greater than min' : ''}
                                        />
                                    ) : (
                                        row.MaxVehCount
                                    )}
                                </TableCell>
                                <TableCell>
                                    {editingRow === index ? (
                                        <TextField
                                            type="number"
                                            value={tempValues.FleetCredit}
                                            onChange={(e) => handleChange(e, 'FleetCredit')}
                                            fullWidth
                                        />
                                    ) : (
                                        (row.FleetCredit * 100).toFixed(2) + '%'
                                    )}
                                </TableCell>
                                <TableCell>
                                    {editingRow === index ? (
                                        <>
                                            <IconButton onClick={handleSave}>
                                                <SaveIcon />
                                            </IconButton>
                                            <IconButton onClick={handleCancel}>
                                                <CancelIcon />
                                            </IconButton>
                                        </>
                                    ) : (
                                        <IconButton onClick={() => handleEdit(index)}>
                                            <EditIcon />
                                        </IconButton>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default FleetCreditManager;
