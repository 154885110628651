import React, { useEffect, useMemo, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  runTransaction,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../../firebase";
import ApprovalButtons from "../../assets/forms/ApprovalButtons";
import calculatePremiums from "../../utils/calculatePremiums";
import getTaxSchedule from "../../utils/getTaxSchedule";
import RatePowerUnitEndorsement from "./RatePowerUnitEndorsement";
import numberWithCommas from "../../utils/numberWithCommas";
import endoStatusChangeNotification from "../../utils/notifications/endoStatusChangeNotification";
import Form from "../../generals/Forms/Form";
import ReasonsModal from "../../PolicyLifecycleManager/ReturnToAgentModal";
import { setPolicyDetailUpdate, setPolicyInfo } from "../../PolicyLifecycleManager/Drivers/setPolicyInfo";
import useUsersState from "../../../Context/actions/useUsersState";
import RequestExceptions from "../../generals/Toast/RequestExceptions";
import Popup from "../../generals/Popup";
import getCurrentPolicyTotals from "../../utils/getCurrentPolicyTotals";
import PowerUnitDetailFlags from "../../PolicyLifecycleManager/RedFlags/PowerUnitDetailFlags";
import { formatDateToYYYYMMDD } from "../../Finances/Tables/utils";

export default function AddPowerUnit(props) {
  const endorsement = props?.endorsement;
  const paramPolicyID = props?.paramPolicyID;
  const paramRefID = props?.paramRefID;
  const setSuccess = props?.setSuccess;
  const requestExceptionInputs = props?.requestExceptionInputs;

  const { user } = useUsersState();
  const [requestingExceptionMode, setRequestingExceptionMode] = useState(false);

  const isRequestException = endorsement?.documentType === "Exception Request";
  const wasARequestException = endorsement?.wasARequestException === true;

  const [correspondingRequestExceptionInputs] = requestExceptionInputs
    .filter((info) => {
      return info.id === endorsement?.type;
    })
    .map((info) => info.inputs);

  const [taxSchedule, setTaxSchedule] = useState();
  const [gotTaxSchedule, setGotTaxSchedule] = useState(false);
  const [unitsRated, setUnitsRated] = useState(false);
  const [oldGWP, setOldGWP] = useState();
  const [newGWP, setNewGWP] = useState();

  const [openInCancellationModal, setOpenInCancellationModal] = useState(false);

  const approveAUEndorsement = async (e, requestExceptionData) => {
    e.preventDefault();
    const { agencyID, insuredID } = endorsement;
    const updateEndorsementStatusAndCreatePowerUnit = async (
      endorsementId,
      status,
      powerUnitData
    ) => {
      const endorsementRef = doc(
        db,
        "policy-applications",
        paramPolicyID,
        "endorsements",
        endorsementId
      );
      const subcollectionRef = doc(
        db,
        "agencies",
        agencyID,
        "insureds",
        insuredID,
        "power-units",
        powerUnitData?.id
      );
      const existingTrucksSnapshot = await getDocs(
        collection(db, `agencies/${agencyID}/insureds/${insuredID}/power-units`)
      );
      const existingTrucks = existingTrucksSnapshot.docs.map((doc) => ({
        VIN: doc.data().truckVIN,
        id: doc.id,
      }));
      const existingTruck = existingTrucks.find(
        (truck) => truck.VIN === powerUnitData.truckVIN
      );
      try {
        await runTransaction(db, async (transaction) => {
          await transaction.get(endorsementRef);
          const issuedDate = formatDateToYYYYMMDD(new Date())
          const endoPayload = {
            status,
            wasARequestException: false,
            issuedDate,
          };
          if (requestExceptionData) {
            endoPayload["wasARequestException"] = true;
            endoPayload["requestException"] = requestExceptionData;
          }
          await transaction.update(endorsementRef, endoPayload);

          const payload = setPolicyInfo(
            {
              ...powerUnitData,
              dateCreated: new Date(),
            },
            {
              policyEffectiveDate: endorsement.endoEffectiveDate,
              glPolicyNo: endorsement?.glPolicyNo,
              alPolicyNo: endorsement?.alPolicyNo,
              policyID: paramPolicyID,
              status: "Active",
            },
            "endo-addition"
          );

          if (powerUnitData?.mirroredIds && powerUnitData?.mirroredPolicyId) {
            payload["otherSideOfMirrorId"] = powerUnitData?.mirroredIds[0];
          }

          if (existingTruck) {

            const entities = await getDoc(subcollectionRef)
            const entityData = entities.data()
            const updatedPayload = setPolicyDetailUpdate(payload, entityData, payload?.['policiesDetails']?.[0])

            await setDoc(subcollectionRef, updatedPayload, { merge: true });

            const thereIsMirrorDataHere =
              powerUnitData?.mirroredIds && powerUnitData?.mirroredPolicyId;
            if (thereIsMirrorDataHere) {
              for (const id of powerUnitData.mirroredIds) {
                const mirroredSubcollectionRef = doc(
                  db,
                  "agencies",
                  agencyID,
                  "insureds",
                  insuredID,
                  "power-units",
                  id
                );

                const mirroredPayload = setPolicyInfo(
                  {
                    ...updatedPayload,
                    dateCreated: new Date(),
                  },
                  {
                    policyEffectiveDate: endorsement.endoEffectiveDate,
                    glPolicyNo: endorsement?.glPolicyNo,
                    alPolicyNo: endorsement?.alPolicyNo,
                    policyID: powerUnitData?.mirroredPolicyId,
                    status: "Active",
                  },
                  "endo-addition"
                );

                mirroredPayload["otherSideOfMirrorId"] = powerUnitData?.id;

                await setDoc(mirroredSubcollectionRef, mirroredPayload, {
                  merge: true,
                });
              }
            }
          }
        });
      } catch (error) {
        console.error(
          "Error updating endorsement status and creating power unit:",
          error
        );
      }
    };

    const policyRef = doc(db, "policy-applications", paramPolicyID);
    const policyDoc = await getDoc(policyRef);
    const policyData = policyDoc.data();
    const policyStatus = policyData.status;
    if (policyStatus === "In-Cancellation") {
      setOpenInCancellationModal(true);
      return;
    }

    await updateEndorsementStatusAndCreatePowerUnit(
      paramRefID,
      "Approved",
      endorsement.data
    );

    try {
      const policySnapshot = await getDoc(
        doc(db, "policy-applications", paramPolicyID)
      );
      await calculatePremiums(
        policySnapshot.id,
        policySnapshot.data(),
        e,
        "#",
        policySnapshot.data().alPremiums.adjustment,
        true,
        true,
        paramRefID
      );
      endoStatusChangeNotification(
        endorsement.type,
        endorsement.endorsementNumber,
        "Approved",
        paramRefID,
        paramPolicyID,
        endorsement.agencyID
      );
    } catch (err) {
      throw new Error("Error calculating premiums:" + err);
    }
    setSuccess(true);
  };

  const [rejectionModal, setRejectionModal] = useState(false);

  const declineAUEndorsement = async (e, _, reasons) => {
    if (e) {
      e.preventDefault();
    }
    const endoRef = doc(
      db,
      "policy-applications",
      paramPolicyID,
      "endorsements",
      paramRefID
    );

    const payload = {
      status: "Declined",
      wasARequestException: false,
    };
    if (isRequestException) {
      payload["wasARequestException"] = true;
    }
    if (reasons) {
      payload["rejectionReasons"] = reasons;
    }

    await updateDoc(endoRef, payload);

    const { agencyID, insuredID, data } = endorsement;
    const subcollectionRef = doc(
      db,
      "agencies",
      agencyID,
      "insureds",
      insuredID,
      "power-units",
      data?.id
    );

    const subCollectionPayload = setPolicyInfo(
      {
        ...data,
        dateCreated: new Date(),
      },
      {
        policyEffectiveDate: endorsement.endoEffectiveDate,
        glPolicyNo: endorsement?.glPolicyNo,
        alPolicyNo: endorsement?.alPolicyNo,
        policyID: paramPolicyID,
        status: "Declined",
      },
      "endo-addition"
    );

    const entities = await getDoc(subcollectionRef)
    const entityData = entities.data()
    const updatedPayload = setPolicyDetailUpdate(subCollectionPayload, entityData, subCollectionPayload?.['policiesDetails']?.[0])

    await setDoc(subcollectionRef, updatedPayload, { merge: true });

    const thereIsMirrorDataHere = data?.mirroredIds && data?.mirroredPolicyId;
    if (thereIsMirrorDataHere) {
      for (const id of data.mirroredIds) {
        const mirroredSubcollectionRef = doc(
          db,
          "agencies",
          agencyID,
          "insureds",
          insuredID,
          "power-units",
          id
        );

        const mirroredPayload = setPolicyInfo(
          {
            ...payload,
            dateCreated: new Date(),
          },
          {
            policyEffectiveDate: endorsement.endoEffectiveDate,
            glPolicyNo: endorsement?.glPolicyNo,
            alPolicyNo: endorsement?.alPolicyNo,
            policyID: data?.mirroredPolicyId,
            status: "Declined",
          },
          "endo-addition"
        );

        const updatedPayload = setPolicyDetailUpdate(mirroredPayload, entityData, mirroredPayload?.['policiesDetails']?.[0])

        await setDoc(mirroredSubcollectionRef, updatedPayload, {
          merge: true,
        });
      }
    }

    endoStatusChangeNotification(
      endorsement.type,
      endorsement.endorsementNumber,
      "Declined",
      paramRefID,
      paramPolicyID,
      endorsement.agencyID
    );
    setSuccess(true);
  };

  const requestExceptionModeSetting = async (e, data) => {
    e.preventDefault();
    const endoRef = doc(
      db,
      "policy-applications",
      paramPolicyID,
      "endorsements",
      paramRefID
    );
    await updateDoc(endoRef, {
      documentType: "Exception Request",
      requestException: data,
    });
    endoStatusChangeNotification(
      endorsement.type,
      endorsement.endorsementNumber,
      "Declined",
      paramRefID,
      paramPolicyID,
      endorsement.agencyID
    );
    setRequestingExceptionMode(false);
  };

  const formSettingsRequestException = useMemo(() => {
    const settings = {
      title: "Reason for exception",
      onSubmit: requestExceptionModeSetting,
      onDecline: () => setRequestingExceptionMode(false),
      inputs: correspondingRequestExceptionInputs || [],
      buttonLabel: "Submit",
      buttonLabelDecline: "Cancel",
      otherInput: { label: "Other exception request" },
    };
    return settings;
  }, [correspondingRequestExceptionInputs]);

  const policyDetailToShow = endorsement?.data?.policiesDetails.find(
    (detail) => detail.id === paramPolicyID
  );

  const policyID = paramPolicyID;
  const endoID = paramRefID;
  const { agencyID, insuredID } = endorsement;

  useEffect(async () => {
    const currentGWP = await getCurrentPolicyTotals(
      agencyID,
      insuredID,
      policyID,
      endoID
    );
    setOldGWP("$" + numberWithCommas(currentGWP.toFixed(2)));
    // sum adjustedRate of endorsement.data array objects
    let endorsementsTotalGWP = parseFloat(
      endorsement.data.policiesDetails[0].adjustedRate
    );
    console.log("current gwp: ", currentGWP);
    console.log("new addition: ", endorsementsTotalGWP);
    const newTotal = currentGWP + endorsementsTotalGWP;
    console.log("new total: ", newTotal);
    // endorsement.data.forEach((endo) => {
    //   endorsementsTotalGWP += endo.adjustedRate || 0
    // })
    setNewGWP("$" + numberWithCommas(newTotal.toFixed(2)));
  }, [agencyID, insuredID, policyID, endoID]);

  return (
    <div>
      <Popup
        isOpen={openInCancellationModal}
        onRequestClose={() => setOpenInCancellationModal(false)}
        maxWidth="50%"
      >
        <p className="text-2xl font-semibold mb-2 text-center text-red-700">
          This endorsement cannot be approved because the policy is In
          Cancellation
        </p>
      </Popup>
      <ReasonsModal
        title="Rejection Reasons"
        text="Please enter the reason(s) why the endorsement is being rejected."
        addButtonText="Add A Reason"
        onSubmit={(e, reasons) => declineAUEndorsement(e, null, reasons)}
        open={rejectionModal}
        onClose={() => setRejectionModal(false)}
      />
      <div className="max-w-xl mx-auto p-6 bg-white shadow-lg rounded-lg">
        <Container className="p-0">
          <Row>
            <Col sm={12} md={6}>
              <PowerUnitDetailFlags
                powerUnit={endorsement.data}
                policyID={paramPolicyID}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6}>
              <span className="font-semibold">VIN:</span>{" "}
              {endorsement.data.truckVIN}
            </Col>
            <Col sm={12} md={6}>
              <span className="font-semibold">Weight:</span>{" "}
              {endorsement.data.truckWeight}
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6}>
              <span className="font-semibold">Year:</span>{" "}
              {endorsement.data.truckYear}
            </Col>
            <Col sm={12} md={6}>
              <span className="font-semibold">Make:</span>{" "}
              {policyDetailToShow?.truckMake}
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6}>
              <span className="font-semibold">Model:</span>{" "}
              {policyDetailToShow?.truckModel}
            </Col>
            <Col sm={12} md={6}>
              <span className="font-semibold">Class:</span>{" "}
              {policyDetailToShow?.truckClass}
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6}>
              <span className="font-semibold">Rating:</span> $
              {numberWithCommas(policyDetailToShow?.adjustedRate?.toFixed(2))}
            </Col>
            <Col sm={12} md={6}>
              <span className="font-semibold">SL Tax:</span> $
              {numberWithCommas(
                policyDetailToShow?.taxes?.surplusLinesTax.toFixed(2)
              )}
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6}>
              <span className="font-semibold">Stamp Fee:</span> $
              {numberWithCommas(policyDetailToShow?.taxes?.stampFee.toFixed(2))}
            </Col>
            <Col sm={12} md={6}>
              <span className="font-semibold">Processing Fee:</span> $
              {numberWithCommas(
                policyDetailToShow?.taxes?.processingFee.toFixed(2)
              )}
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6}>
              <span className="font-semibold">Other Fees:</span> $
              {numberWithCommas(
                policyDetailToShow?.taxes?.otherFees.toFixed(2)
              )}
            </Col>
            <Col sm={12} md={6}>
              <span className="font-semibold">Total:</span> $
              {numberWithCommas(
                (
                  policyDetailToShow?.taxes?.processingFee +
                  policyDetailToShow?.taxes?.stampFee +
                  policyDetailToShow?.taxes?.surplusLinesTax +
                  policyDetailToShow?.adjustedRate
                ).toFixed(2)
              )}
            </Col>
            {endorsement?.type?.includes("Adjust") ? (
              <>
                <Col sm={12} md={6}>
                  <span className="font-semibold">Old Radius:</span>{" "}
                  {policyDetailToShow?.oldRadius}{" "}
                  <span className="font-semibold">to </span>
                </Col>
                <Col sm={12} md={6}>
                  <span className="font-semibold">
                    New Radius (Changed Operational Radius):
                  </span>{" "}
                  {policyDetailToShow?.truckRadius}
                </Col>
              </>
            ) : null}
          </Row>
        </Container>
      </div>
      
      {requestingExceptionMode &&
      typeof correspondingRequestExceptionInputs !== "undefined" ? (
        <Form
          formSettings={formSettingsRequestException}
          isSmallVersion={true}
        />
      ) : null}

      {(endorsement.status === "Submitted" || isRequestException) &&
      user.role === "Underwriter" ? (
        <>
          {!unitsRated ? (
            <>
              <article className="my-4 d-block">
                <p>
                  Before approving or declining this endorsement, you must rate
                  or rerate the power units.
                </p>
              </article>
              <RatePowerUnitEndorsement
                truckList={[{ ...endorsement.data }]}
                policyID={paramPolicyID}
                endoID={paramRefID}
                setSuccess={setUnitsRated}
                singleUnit={true}
              />
            </>
          ) : (
            <>
              <article className="my-4">
                <p
                  className={
                    isRequestException
                      ? "text-red-700 text-center !max-w-[345px] mx-auto"
                      : ""
                  }
                >
                  {isRequestException
                    ? `This endorsement has already been declined. 
                    You are accepting/decling an exception request`
                    : `Approving this endorsement will add the above Power Unit(s) to
                    the policy and notify the insured of the change.`}
                </p>
              </article>
              {isRequestException ? (
                <RequestExceptions
                  endorsement={endorsement}
                  color="red"
                  center={true}
                />
              ) : null}
            </>
          )}
          <>
            <ApprovalButtons
              dissapearAccept={!unitsRated}
              onApprove={approveAUEndorsement}
              onDecline={() => setRejectionModal(true)}
            />
          </>
        </>
      ) : endorsement.status === "Declined" &&
        !isRequestException &&
        !wasARequestException &&
        user.role === "Agent" &&
        !requestingExceptionMode ? (
        <ApprovalButtons
          dissapearAccept={true}
          titleDecline="REQUEST EXCEPTION"
          onDecline={() => setRequestingExceptionMode(true)}
        />
      ) : null}
    </div>
  );
}
