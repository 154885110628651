import { deleteObject, ref } from "firebase/storage";
import { storage } from "../../firebase";

  // Function to delete a file from Firebase Storage
  const deleteFileFromStorage = async (fileUrl) => {
    if (fileUrl) {
      const storageRef = ref(storage, fileUrl);
      try {
        await deleteObject(storageRef);
        console.log("File deleted successfully from Firebase Storage");
      } catch (error) {
        console.error("Error deleting file from Firebase Storage:", error);
      }
    }
  };

  export default deleteFileFromStorage;