import { doc, writeBatch } from "firebase/firestore";
import { db } from "../../../firebase";

const updateTrucks = async (truckList, formData) => {
  try {
    const promises = truckList.map(async (truck) => {
      const { truckRadius, id: truckId } = truck;
      const batch = writeBatch(db);
      const unitRef = doc(
        db,
        "agencies",
        formData.agencyID,
        "insureds",
        formData.insuredID,
        "power-units",
        truckId
      );
      batch.update(unitRef, {
        truckRadius,
      });
      return batch.commit();
    });
    return Promise.all(promises);
  } catch (error) {
    console.log("Error updating trucks", error);
    throw error;
  }
};

export default updateTrucks;
