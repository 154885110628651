import React, { useState } from 'react';
import {
  TextField,
  Button,
  Container,
  Typography,
  Box,
  MenuItem,
  Select,
  FormControl,
  InputLabel
} from '@mui/material';
import { collection, addDoc, Timestamp } from 'firebase/firestore';
import { db, auth } from '../../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import ApiService from '../../services/southern-api/apiService';
import useGeneralState from '../../Context/actions/useGeneralState';
import LoadingScreen from "react-loading-screen";

const SubmitReport = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [errorLocation, setErrorLocation] = useState('');
  const [customLocation, setCustomLocation] = useState('');
  const [user] = useAuthState(auth);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const { controlToast } = useGeneralState()
  const [loading, setLoading] = useState(false);

  const apiService = new ApiService();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!title || !description || !errorLocation || (errorLocation === 'Other' && !customLocation)) {
      setError('Please fill in all fields');
      return;
    }
    setError('');
    setSuccess('');
    try {
      const payload = {
        title,
        description,
        errorLocation: errorLocation === 'Other' ? customLocation : errorLocation,
        createdAt: Timestamp.fromDate(new Date()),
        userId: user ? user.uid : null,
        userEmail: user ? user.email : null,
        status: 'Submitted'
      }
      await addDoc(collection(db, 'bugReports'), payload);
      setTitle('');
      setDescription('');
      setErrorLocation('');
      setCustomLocation('');
      setSuccess('Report submitted successfully');
      await apiService.bugReportSubmissionConfirmation(payload)
      setLoading(false);
      controlToast(true, 'Report submitted successfully', 'success')
    } catch (err) {
      setLoading(false);
      setError('Error submitting report');
      controlToast(true, 'There was an error trying to submit the bug report', 'error')
    }
  };

  return (
    <LoadingScreen
      loading={loading}
      bgColor="#f1f1f1"
      spinnerColor="#9ee5f8"
      textColor="#676767"
      padding="0"
      logoSrc=""
      text={`Sending Bug Report...`}
    >
      <Container maxWidth="sm">
        <Box mt={5}>
          <Typography variant="h4" gutterBottom>
            Submit a Bug Report
          </Typography>
          {error && <Typography color="error">{error}</Typography>}
          {success && <Typography color="primary">{success}</Typography>}
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label="Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              margin="normal"
              variant="outlined"
            />
            
            <FormControl fullWidth margin="normal" variant="outlined">
              <InputLabel>Error Location</InputLabel>
              <Select
                value={errorLocation}
                onChange={(e) => setErrorLocation(e.target.value)}
                label="Error Location"
              >
                <MenuItem value="Policy">Policy</MenuItem>
                <MenuItem value="Endorsements">Endorsements</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </Select>
            </FormControl>
            {errorLocation === 'Other' && (
              <TextField
                fullWidth
                label="Custom Location"
                value={customLocation}
                onChange={(e) => setCustomLocation(e.target.value)}
                margin="normal"
                variant="outlined"
              />
            )}
            <TextField
              fullWidth
              label="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              margin="normal"
              variant="outlined"
              multiline
              rows={4}
            />
            <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
              Submit
            </Button>
          </form>
        </Box>
      </Container>
    </LoadingScreen>
  );
};

export default SubmitReport;
