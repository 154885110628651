import { Col, Container, Row } from "react-bootstrap";
import logo from "../../assets/images/sslogowhiteshadow.png";
import styles from "../../assets/styles/pages/loss-runs";
import { ABSOLUTE_HOME_ROUTE, HOME_ROUTE } from "../../utils/routes";
import useTranslations from "../../Context/actions/useTranslations";
import useLossRuns from "../../hooks/loss-runs/useLossRuns";
import "../../assets/styles/app.css";

function LossRunRequest({ userInfo }) {
  
  const { t } = useTranslations()

  const {
    phone,
    setPhone,
    company,
    setCompany,
    email,
    setEmail,
    name,
    setName,
    comments,
    setComments,
    namedInsured,
    setNamedInsured,
    policyFields,
    authorization,
    setAuthorization,
    submitted,
    submitRequest,
    handleChangePolicyField,
    handleAddPolicyField,
    handleSubtractPolicyField,
    disabled,
    loading
  } = useLossRuns()

  return (
    <div className={styles.wrapper}>
      <div>
        <img
          className={styles.img}
          src={logo}
        />
        <div className={styles.titleContainer}>
          <h1 className={styles.title}>
            {t('loss-runs.title')}
          </h1>
        </div>
        {!submitted && !loading ? (
          <form
            name="requestForm"
            id="requestForm"
            onSubmit={(e) => {
              submitRequest(e);
            }}
          >
            <div className={styles.inputContainer}>
              <label className={styles.inputLabel}>{t('loss-runs.form.name')}</label>
              <input
                required
                onChange={(e) => setName(e.target.value)}
                className={styles.input}
                type="text"
                value={name}
              />
            </div>
            <div className={styles.inputContainer}>
              <label className={styles.inputLabel}>{t('loss-runs.form.company')}</label>
              <input
                required
                onChange={(e) => setCompany(e.target.value)}
                className={styles.input}
                type="text"
                value={company}
              />
            </div>
            <div className={styles.inputContainer}>
              <label className={styles.inputLabel}>{t('loss-runs.form.email')}</label>
              <input
                required
                onChange={(e) => setEmail(e.target.value)}
                className={styles.input}
                type="text"
                value={email}
              />
            </div>
            <div className={styles.inputContainer}>
              <label className={styles.inputLabel}>{t('loss-runs.form.phone')}</label>
              <input
                required
                onChange={(e) => setPhone(e.target.value)}
                className={styles.input}
                type="text"
                value={phone}
              />
            </div>
            <div className={styles.inputContainer}>
              <label className={styles.inputLabel}>{t('loss-runs.form.named-insured')}</label>
              <input
              required
                onChange={(e) => setNamedInsured(e.target.value)}
                className={styles.input}
                type="text"
                value={namedInsured}
              />
            </div>
            <div className={styles.inputContainer}>
              <label className={styles.inputLabel}>{t('loss-runs.form.policy-numbers')}</label>

              {policyFields.map((field, i) => {
                return (
                  <>
                    <input
                      onChange={(e) => handleChangePolicyField(e, i)}
                      className={styles.policyFields}
                      name="policyNumber"
                      placeholder={"Policy Number " + (i + 1)}
                      type="text"
                      value={field.policyNumber}
                    />
                  </>
                );
              })}
              <button
                className={styles.removePolicy}
                onClick={(e) => handleSubtractPolicyField(e)}
              >
                {t('loss-runs.form.remove')} {policyFields.length}
              </button>
              <button
                className={styles.addPolicy}
                onClick={(e) => handleAddPolicyField(e)}
              >
                {t('loss-runs.form.add')}
              </button>
            </div>
            <div className={styles.inputContainer}>
              <label className={styles.inputLabel}>{t('loss-runs.form.comments')}</label>
              <textarea
                onChange={(e) => setComments(e.target.value)}
                className={styles.input}
                value={comments}
              />
            </div>
            <Container className={styles.gridContainer}>
              <Row>
                <Col xs={1}>
                  <input
                    required
                    onChange={(e) => setAuthorization(!authorization)}
                    className={styles.input}
                    type="checkbox"
                    id="authorization"
                    value="Authorized-User"
                    checked={authorization}
                  />
                </Col>
                <Col xs={11}>
                  <label htmlFor="authorization">
                  {t('loss-runs.form.authorization')}
                  </label>
                </Col>
              </Row>
            </Container>
            <button
              type="submit"
              disabled={disabled}
              className={styles.submitRequest(disabled)}
            >
              {t('loss-runs.form.submit')}
            </button>
          </form>
        ) : (
          <div>
            <p className={styles.thankyou}>{t('loss-runs.thankyou')}</p>
            <p className={styles.thankyouText}>
            {t('loss-runs.text')}
            </p>
            <p className={styles.thankyouText}>
              <a
                className={styles.thankyouButton}
                href={
                  userInfo.email ?
                  HOME_ROUTE : ABSOLUTE_HOME_ROUTE
                }
              >
                {t('loss-runs.home')}
              </a>
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default LossRunRequest;
