const findTotalAdjustments = (quoteData) => {
  console.log(quoteData);
  
  const percentageAdjustment = parseFloat(quoteData.adjustmentFormData.percentage) *
    (quoteData.adjustmentFormData.debitOrCredit === "Credit" ? -1 : 1);
  console.log('Percentage Adjustment:', percentageAdjustment);

  const renewalCreditAdjustment = parseFloat(quoteData.adjustmentFormData.renewalCredit) *
    (quoteData.adjustmentFormData.renewalCreditDebitOrCredit === "Credit" || !quoteData.adjustmentFormData.renewalCreditDebitOrCredit ? -1 : 1);
  console.log('Renewal Credit Adjustment:', renewalCreditAdjustment);

  const lossRatioAdjustment = parseFloat(quoteData.adjustmentFormData.lossRatioAdjustmentPercentage) *
    (quoteData.adjustmentFormData.lossRatioAdjustmentDebitOrCredit === "Credit" || !quoteData.adjustmentFormData.lossRatioAdjustmentDebitOrCredit ? -1 : 1);
  console.log('Loss Ratio Adjustment:', lossRatioAdjustment);

  const safetyAdjustment = parseFloat(quoteData.adjustmentFormData.safetyAdjustmentPercentage) *
    (quoteData.adjustmentFormData.safetyAdjustmentDebitOrCredit === "Credit" || !quoteData.adjustmentFormData.safetyAdjustmentDebitOrCredit ? -1 : 1);
  console.log('Safety Adjustment:', safetyAdjustment);

  const returnAmount = (
    percentageAdjustment +
    renewalCreditAdjustment +
    lossRatioAdjustment +
    safetyAdjustment
  ) / 100;

  console.log('Return Amount:', returnAmount);
  return returnAmount;
};

export default findTotalAdjustments;
