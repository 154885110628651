import React, { useEffect, useState } from "react";
import axios from "axios";
import { storage } from "../../../firebase.js";
import { deleteObject, ref, uploadBytes } from "firebase/storage";
import { useNavigate, useParams } from "react-router-dom";
import {
  updateDoc,
  doc,
  setDoc,
  getDocs,
  collection,
  query,
  addDoc,
  getDoc,
  deleteDoc,
} from "firebase/firestore";
import { db, auth } from "../../../firebase";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Table, Thead, Tbody, Tr, Td } from "react-super-responsive-table";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import { v4 } from "uuid";
import { Beforeunload, useBeforeunload } from "react-beforeunload";
import { UserAuth } from "../../../Context/AuthContent.js";
import useUsersState from "../../../Context/actions/useUsersState";
import useTranslations from "../../../Context/actions/useTranslations";
import { handleSentStatus } from "../../../utils/constants.js";
import { confirmDeletePromptGlobal } from "../../../layout/ConfirmDeleteProvider/index.js";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"} variant={"body2"}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Proposal() {
  // Criteria Modal Stuff
  let subtitle;
  const [criteriaModalIsOpen, setCriteriaModalIsOpen] = React.useState(false);

  function openCriteriaModal(e) {
    e.preventDefault();
    setCriteriaModalIsOpen(true);
  }

  function afterOpenCriteriaModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeCriteriaModal() {
    setCriteriaModalIsOpen(false);
  }

  // XS Modal Stuff
  const [XSdeductibleModalIsOpen, setXSModalIsOpen] = React.useState(false);

  function openXSdeductibleModal(e) {
    e.preventDefault();
    setXSModalIsOpen(true);
  }

  function afterOpenXSdeductibleModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeXSdeductibleModal() {
    setXSModalIsOpen(false);
  }

  // APD Deductible Modal Stuff
  const [APDdeductibleModalIsOpen, setAPDdeductibleModalIsOpen] =
    React.useState(false);

  function openAPDdeductibleModal(e) {
    e.preventDefault();
    setAPDdeductibleModalIsOpen(true);
  }

  function afterOpenAPDdeductibleModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeAPDdeductibleModal() {
    setAPDdeductibleModalIsOpen(false);
  }

  // MTC Deductible Modal Stuff
  const [MTCdeductibleModalIsOpen, setMTCdeductibleModalIsOpen] =
    React.useState(false);

  function openMTCdeductibleModal(e) {
    e.preventDefault();
    setMTCdeductibleModalIsOpen(true);
  }

  function afterOpenMTCdeductibleModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeMTCdeductibleModal() {
    setMTCdeductibleModalIsOpen(false);
  }

  // Add Carrier Modal Stuff
  const [addCarrierModalIsOpen, setAddCarrierModalIsOpen] =
    React.useState(false);

  function openAddCarrierModal(e) {
    e.preventDefault();
    setAddCarrierModalIsOpen(true);
  }

  function afterOpenaddCarrierModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeAddCarrierModal() {
    setAddCarrierModalIsOpen(false);
  }

  // Edit List Modal Stuff
  const [editListModalIsOpen, setEditListModalIsOpen] = React.useState(false);

  function openEditListModal(e) {
    e.preventDefault();
    setEditListModalIsOpen(true);
  }

  function afterOpenEditListModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeEditListModal() {
    setEditListModalIsOpen(false);
  }

  // Other Stuff
  let navigate = useNavigate();

  let { paramRefID, paramCompanyID } = useParams();

  // Date Stuff
  const current = new Date();
  const date = `${
    current.getMonth() + 1
  }/${current.getDate()}/${current.getFullYear()}`;

  const [startDate, setStartDate] = useState(new Date());

  // Tabs Stuff
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //   Form Handling
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    namedInsured: "",
    agencyID: paramCompanyID,
    lastUpdated: date,
    status: "",
    downloadURL: "#",
    APD: false,
    MTC: false,
    XSMTC: false,
    carrier: "",
    state: "",
    effectiveDate: new Date(),
    TIV: 0,
    APDTI: "",
    APDlimitTI: 0,
    APDpremiumTI: 0,
    APDtowingStorageLimit: 0,
    APDdriverCriteria: "",
    limitMTC: 0,
    numberOfUnits: 0,
    debrisRemoval: "",
    limitDebrisRemoval: 0,
    reefer: "",
    targetInterest: "",
    targetInterestA: "Electronics, Garments, Alcohol",
    targetInterestB: "USD 100,000",
    targetInterestC: "USD 10,000",
    MTClimitTI: 0,
    MTCpremiumTI: 0,
    MTCdriverCriteria: "",
    XSlimit: 0,
    MTCTI: "",
    APDoptionFields: [
      {
        id: 1,
        deductible: "",
        rate: 0,
        premium: 0,
        policyFee: 0,
        companyFee: 0,
        SLtax: 0,
        SLstampFee: 0,
        totalPremium: 0,
        commission: 0.15,
      },
    ],
    MTCoptionFields: [
      {
        id: 1,
        deductible: "",
        rate: 0,
        premium: 0,
        policyFee: 0,
        companyFee: 0,
        SLtax: 0,
        SLstampFee: 0,
        totalPremium: 0,
        commission: 0.15,
      },
    ],
    XSoptionFields: [
      {
        id: 1,
        rate: 0,
        premium: 0,
        policyFee: 0,
        companyFee: 0,
        SLtax: 0,
        SLstampFee: 0,
        totalPremium: 0,
        commission: 0.15,
      },
    ],
  });

  const handleBlur = async (e) => {
    e.preventDefault();
    if (e.target.value === "") {
      e.target.className = "border p-1 text-xl font-medium";
    } else {
      e.target.className = "text-xl font-medium border-0 p-1";
    }
    if (IDfound === true) {
      setDoc(proposalRef, { ...formData, lastUpdated: date }, { merge: true });
    }
  };

  const handleSelectBlur = async (e) => {
    e.preventDefault();
    if (e.target.value === "") {
      e.target.className = "border p-1 bg-red-100";
    } else {
      e.target.className = "text-xl font-medium border-0 p-1";
    }
    if (IDfound === true) {
      setDoc(proposalRef, { ...formData, lastUpdated: date }, { merge: true });
    }
  };

  const texasStamp = 0.00075;
  const caliStamp = 0.0018;
  const arizonaStamp = 0.002;
  const newmexStamp = 0;

  const texasTax = 0.04;
  const caliTax = 0.03;
  const arizonaTax = 0.03;
  const newmexTax = 0.03003;

  const localeConfig = { maximumFractionDigits: 2, minimumFractionDigits: 2 };

  const calculateSurplusLinesAPD = (e) => {
    for (let i = 0; i < formData.APDoptionFields.length; i++) {
      const values = [...formData.APDoptionFields];
      values[i].premium = 0;
      values[i].SLstampFee = 0;
      values[i].SLtax = 0;

      const TIV = formData.TIV;
      const rate = values[i].rate / 100;
      const TIPremium = formData.APDpremiumTI;
      const companyFee = values[i].companyFee;
      const policyFee = values[i].policyFee;

      if (formData.state === "TX") {
        const newPremium = Math.round(TIV * rate + (TIPremium || 0));
        const newStampFee = texasStamp * (newPremium + companyFee + policyFee);
        const newSLtax = texasTax * (newPremium + companyFee + policyFee);
        values[i].premium = (+newPremium).toLocaleString("en-US", localeConfig);
        values[i].SLstampFee = (+newStampFee).toLocaleString(
          "en-US",
          localeConfig
        );
        values[i].SLtax = (+newSLtax).toLocaleString("en-US", localeConfig);
        values[i].totalPremium = (
          +newPremium +
          +newSLtax +
          +companyFee +
          +policyFee +
          +newStampFee
        ).toLocaleString("en-US", localeConfig);
        setFormData({ ...formData, APDoptionFields: values });
      } else if (formData.state === "CA") {
        const newPremium = Math.round(TIV * rate + (TIPremium || 0));
        const newStampFee = caliStamp * (newPremium + companyFee + policyFee);
        const newSLtax = caliTax * (newPremium + companyFee + policyFee);
        values[i].premium = +newPremium.toFixed(2);
        values[i].SLstampFee = +newStampFee.toFixed(2);
        values[i].SLtax = +newSLtax.toFixed(2);
        values[i].totalPremium = (
          +newPremium +
          +newSLtax +
          +companyFee +
          +policyFee +
          +newStampFee
        ).toFixed(2);
      } else if (formData.state === "AZ") {
        const newPremium = Math.round(TIV * rate + (TIPremium || 0));
        const newStampFee =
          arizonaStamp * (newPremium + companyFee + policyFee);
        const newSLtax = arizonaTax * (newPremium + companyFee + policyFee);
        values[i].premium = +newPremium.toFixed(2);
        values[i].SLstampFee = +newStampFee.toFixed(2);
        values[i].SLtax = +newSLtax.toFixed(2);
        values[i].totalPremium = (
          +newPremium +
          +newSLtax +
          +companyFee +
          +policyFee +
          +newStampFee
        ).toFixed(2);
      } else if (formData.state === "NM") {
        const newPremium = Math.round(TIV * rate + (TIPremium || 0));
        const newStampFee = newmexStamp * (newPremium + companyFee + policyFee);
        const newSLtax = newmexTax * (newPremium + companyFee + policyFee);
        values[i].premium = +newPremium.toFixed(2);
        values[i].SLstampFee = +newStampFee.toFixed(2);
        values[i].SLtax = +newSLtax.toFixed(2);
        values[i].totalPremium = (
          +newPremium +
          +newSLtax +
          +companyFee +
          +policyFee +
          +newStampFee
        ).toFixed(2);
      }
      setFormData({ ...formData, APDoptionFields: values });
      setOptionsUpdated(optionsUpdated + 1);
    }
  };

  const calculateSurplusLinesMTC = (e) => {
    for (let i = 0; i < formData.MTCoptionFields.length; i++) {
      const values = [...formData.MTCoptionFields];
      values[i].premium = 0;
      values[i].SLstampFee = 0;
      values[i].SLtax = 0;

      const numberOfUnits = formData.numberOfUnits;
      const rate = values[i].rate;
      const TIPremium = formData.MTCpremiumTI;
      const companyFee = values[i].companyFee;
      const policyFee = values[i].policyFee;

      if (formData.state === "TX") {
        const newPremium = +numberOfUnits * +rate + (TIPremium || 0);
        const newStampFee =
          texasStamp * (+newPremium + +companyFee + +policyFee);
        const newSLtax = texasTax * (newPremium + companyFee + policyFee);
        values[i].premium = (+newPremium).toLocaleString("en-US", localeConfig);
        values[i].SLstampFee = (+newStampFee).toLocaleString(
          "en-US",
          localeConfig
        );
        values[i].SLtax = (+newSLtax).toLocaleString("en-US", localeConfig);
        values[i].totalPremium = (
          +newPremium +
          +newSLtax +
          +companyFee +
          +policyFee +
          +newStampFee
        ).toLocaleString("en-US", localeConfig);
      } else if (formData.state === "CA") {
        const newPremium = +numberOfUnits * +rate + (TIPremium || 0);
        const newStampFee = caliStamp * (newPremium + companyFee + policyFee);
        const newSLtax = caliTax * (newPremium + companyFee + policyFee);
        values[i].premium = (+newPremium).toLocaleString("en-US", localeConfig);
        values[i].SLstampFee = +newStampFee.toLocaleString(
          "en-US",
          localeConfig
        );
        values[i].SLtax = +newSLtax.toLocaleString("en-US", localeConfig);
        values[i].totalPremium = (
          +newPremium +
          +newSLtax +
          +companyFee +
          +policyFee +
          +newStampFee
        ).toLocaleString("en-US", localeConfig);
      } else if (formData.state === "AZ") {
        const newPremium = +numberOfUnits * +rate + (TIPremium || 0);
        const newStampFee =
          arizonaStamp * (newPremium + companyFee + policyFee);
        const newSLtax = arizonaTax * (newPremium + companyFee + policyFee);
        values[i].premium = (+newPremium).toLocaleString("en-US", localeConfig);
        values[i].SLstampFee = +newStampFee.toLocaleString(
          "en-US",
          localeConfig
        );
        values[i].SLtax = +newSLtax.toLocaleString("en-US", localeConfig);
        values[i].totalPremium = (
          +newPremium +
          +newSLtax +
          +companyFee +
          +policyFee +
          +newStampFee
        ).toLocaleString("en-US", localeConfig);
      } else if (formData.state === "NM") {
        const newPremium = +numberOfUnits * +rate + (TIPremium || 0);
        const newStampFee = newmexStamp * (newPremium + companyFee + policyFee);
        const newSLtax = newmexTax * (newPremium + companyFee + policyFee);
        values[i].premium = (+newPremium).toLocaleString("en-US", localeConfig);
        values[i].SLstampFee = (+newStampFee).toLocaleString(
          "en-US",
          localeConfig
        );
        values[i].SLtax = (+newSLtax).toLocaleString("en-US", localeConfig);
        values[i].totalPremium = (
          +newPremium +
          +newSLtax +
          +companyFee +
          +policyFee +
          +newStampFee
        ).toLocaleString("en-US", localeConfig);
      }
      setFormData({ ...formData, MTCoptionFields: values });
      setOptionsUpdated(optionsUpdated + 1);
    }
  };

  const calculateSurplusLinesXS = (e) => {
    for (let i = 0; i < formData.XSoptionFields.length; i++) {
      const values = [...formData.XSoptionFields];
      values[i].premium = 0;
      values[i].SLstampFee = 0;
      values[i].SLtax = 0;

      const numberOfUnits = formData.numberOfUnits;
      const rate = values[i].rate;
      const companyFee = values[i].companyFee;
      const policyFee = values[i].policyFee;

      if (formData.state === "TX") {
        const newPremium = +numberOfUnits * +rate;
        const newStampFee = texasStamp * (newPremium + companyFee + policyFee);
        const newSLtax = texasTax * (newPremium + companyFee + policyFee);
        values[i].premium = (+newPremium).toLocaleString("en-US", localeConfig);
        values[i].SLstampFee = (+newStampFee).toLocaleString(
          "en-US",
          localeConfig
        );
        values[i].SLtax = (+newSLtax).toLocaleString("en-US", localeConfig);
        values[i].totalPremium = (
          +newPremium +
          +newSLtax +
          +companyFee +
          +policyFee +
          +newStampFee
        ).toLocaleString("en-US", localeConfig);
      } else if (formData.state === "CA") {
        const newPremium = +numberOfUnits * +rate;
        const newStampFee = caliStamp * (newPremium + companyFee + policyFee);
        const newSLtax = caliTax * (newPremium + companyFee + policyFee);
        values[i].premium = (+newPremium).toLocaleString("en-US", localeConfig);
        values[i].SLstampFee = (+newStampFee).toLocaleString(
          "en-US",
          localeConfig
        );
        values[i].SLtax = (+newSLtax).toLocaleString("en-US", localeConfig);
        values[i].totalPremium = (
          +newPremium +
          +newSLtax +
          +companyFee +
          +policyFee +
          +newStampFee
        ).toLocaleString("en-US", localeConfig);
      } else if (formData.state === "AZ") {
        const newPremium = +numberOfUnits * +rate;
        const newStampFee =
          arizonaStamp * (newPremium + companyFee + policyFee);
        const newSLtax = arizonaTax * (newPremium + companyFee + policyFee);
        values[i].premium = (+newPremium).toLocaleString("en-US", localeConfig);
        values[i].SLstampFee = (+newStampFee).toLocaleString(
          "en-US",
          localeConfig
        );
        values[i].SLtax = (+newSLtax).toLocaleString("en-US", localeConfig);
        values[i].totalPremium = (
          +newPremium +
          +newSLtax +
          +companyFee +
          +policyFee +
          +newStampFee
        ).toLocaleString("en-US", localeConfig);
      } else if (formData.state === "NM") {
        const newPremium = +numberOfUnits * +rate;
        const newStampFee = newmexStamp * (newPremium + companyFee + policyFee);
        const newSLtax = newmexTax * (newPremium + companyFee + policyFee);
        values[i].premium = (+newPremium).toLocaleString("en-US", localeConfig);
        values[i].SLstampFee = (+newStampFee).toLocaleString(
          "en-US",
          localeConfig
        );
        values[i].SLtax = (+newSLtax).toLocaleString("en-US", localeConfig);
        values[i].totalPremium = (
          +newPremium +
          +newSLtax +
          +companyFee +
          +policyFee +
          +newStampFee
        ).toLocaleString("en-US", localeConfig);
      }
      setFormData({ ...formData, XSoptionFields: values });
      setOptionsUpdated(optionsUpdated + 1);
    }
  };

  const [optionsUpdated, setOptionsUpdated] = useState(0);
  const updateOptionTotals = (e) => {
    calculateSurplusLinesAPD(e);
    calculateSurplusLinesMTC(e);
    // calculateSurplusLinesXS(e);
  };

  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  const handleChangeInputAPDdeductible = (i, e) => {
    const values = [...formData.APDoptionFields];
    if (e.target.name === "deductible") {
      values[i][e.target.name] = e.target.value;
    } else if (e.target.name === "rate") {
      values[i][e.target.name] = parseFloat(e.target.value).toFixed(2);
    } else {
      values[i][e.target.name] = parseInt(e.target.value);
    }
    setFormData({ ...formData, APDoptionFields: values });
  };

  const handleChangeInputMTCdeductible = (i, e) => {
    const values = [...formData.MTCoptionFields];
    if (e.target.name === "deductible") {
      values[i][e.target.name] = e.target.value;
    } else {
      values[i][e.target.name] = parseFloat(e.target.value);
    }
    setFormData({ ...formData, MTCoptionFields: values });
  };

  const handleChangeInputXSdeductible = (i, e) => {
    const values = [...formData.XSoptionFields];
    values[i][e.target.name] = parseFloat(e.target.value);
    setFormData({ ...formData, XSoptionFields: values });
  };

  const handleAddAPD = (e) => {
    e.preventDefault();
    const i = formData.APDoptionFields.length;
    if (i > 4) return;
    const lastID = formData.APDoptionFields[i - 1].id;
    setFormData({
      ...formData,
      APDoptionFields: [
        ...formData.APDoptionFields,
        {
          id: lastID + 1,
          deductible: "",
          rate: 0,
          premium: 0,
          policyFee: 0,
          companyFee: 0,
          SLtax: 0,
          SLstampFee: 0,
          totalPremium: 0,
          commission: 0.15,
        },
      ],
    });
  };

  const handleAddMTC = (e) => {
    e.preventDefault();
    const i = formData.MTCoptionFields.length;
    if (i > 4) return;
    const lastID = formData.MTCoptionFields[i - 1].id;
    setFormData({
      ...formData,
      MTCoptionFields: [
        ...formData.MTCoptionFields,
        {
          id: lastID + 1,
          deductible: "",
          rate: 0,
          premium: 0,
          policyFee: 0,
          companyFee: 0,
          SLtax: 0,
          SLstampFee: 0,
          totalPremium: 0,
          commission: 0.15,
        },
      ],
    });
  };

  const handleAddXS = (e) => {
    e.preventDefault();
    const i = formData.XSoptionFields.length;
    if (i > 0) return;
    const lastID = formData.XSoptionFields[i - 1].id;
    setFormData({
      ...formData,
      XSoptionFields: [
        ...formData.XSoptionFields,
        {
          id: lastID + 1,
          rate: 0,
          premium: 0,
          policyFee: 0,
          companyFee: 0,
          SLtax: 0,
          SLstampFee: 0,
          totalPremium: 0,
          commission: 0.15,
        },
      ],
    });
  };

  const handleSubtractAPD = async (e, i) => {
    e.preventDefault();
    const values = [...formData.APDoptionFields];
    values.splice(i, 1);
    setFormData({ ...formData, APDoptionFields: [...values] });
    if (values.length === 0) {
      setFormData({
        ...formData,
        APDoptionFields: [
          {
            id: 1,
            deductible: "",
            rate: 0,
            premium: 0,
            policyFee: 0,
            companyFee: 0,
            SLtax: 0,
            SLstampFee: 0,
            totalPremium: 0,
            commission: 0.15,
          },
        ],
      });
    }
    await updateDoc(proposalRef, { APDoptionFields: values });
  };

  const handleSubtractMTC = async (e, i) => {
    e.preventDefault();
    const values = [...formData.MTCoptionFields];
    values.splice(i, 1);
    setFormData({ ...formData, MTCoptionFields: [...values] });
    if (values.length === 0) {
      setFormData({
        ...formData,
        MTCoptionFields: [
          {
            id: 1,
            deductible: "",
            rate: 0,
            premium: 0,
            policyFee: 0,
            companyFee: 0,
            SLtax: 0,
            SLstampFee: 0,
            totalPremium: 0,
            commission: 0.15,
          },
        ],
      });
    }
    await updateDoc(proposalRef, { MTCoptionFields: values });
  };

  const updateProposalStatus = async (newStatus) => {
    await updateDoc(proposalRef, { status: newStatus });
    if (newStatus === "Unsubmitted") updateDownloadURL("#");
  };

  const updateDownloadURL = async (newURL) => {
    await updateDoc(proposalRef, { downloadURL: newURL });
  };

  // Get a proposal
  const [myData, setMyData] = useState({});
  const [dataUpdated, setDataUpdated] = useState(false);
  const [gotProposal, setGotProposal] = useState(false);
  const getProposal = async (ID) => {
    const docRef = doc(db, "proposals", ID);
    const docSnap = await getDoc(docRef);
    setDataUpdated(false);
    if (docSnap.exists()) {
      setMyData(docSnap.data());
      setDataUpdated(true);
      setGotProposal(true);
    } else {
      console.log("No such document!");
      return null;
    }
  };

  // New Proposal?
  const [IDfound, setIDfound] = useState(false);

  //   For Edit Proposal

  const proposalRef = doc(db, "proposals", paramRefID);

  const saveProposal = async (e) => {
    if (paramRefID === "new") {
      saveNewProposal(e);
      // Log System Activity to System Log
      await addDoc(collection(db, "system-activity"), {
        activityDate: new Date(),
        authorID: auth.currentUser.uid,
        authorName: auth.currentUser.displayName,
        description:
          "New Proposal For " +
          formData.namedInsured +
          " saved by " +
          auth.currentUser.displayName,
      });
      return;
    }
    if (formData.status === "Submitted") return;
    e.preventDefault();
    // if (isAuthor === false) return;
    setDoc(
      proposalRef,
      { ...formData, lastUpdated: date, status: "Unsubmitted" },
      { merge: true }
    );
    // Log System Activity to System Log
    await addDoc(collection(db, "system-activity"), {
      activityDate: new Date(),
      authorID: auth.currentUser.uid,
      authorName: auth.currentUser.displayName,
      description:
        "Existing proposal for " +
        formData.namedInsured +
        " updated & saved by " +
        auth.currentUser.displayName,
    });
  };

  // For New Proposal
  const saveNewProposal = async (e) => {
    e.preventDefault();
    const docRef = await addDoc(collection(db, "proposals"), {
      ...formData,
      authorName: auth.currentUser.displayName,
      dateCreated: new Date(),
      authorID: auth.currentUser.uid,
      company: paramCompanyID,
    });
    console.log("Document written with ID: ", docRef.id);
    navigate("/account");
  };

  // Get Company Info
  const [gotCompanyInfo, setGotCompanyInfo] = useState(false);
  const getCompanyInfo = async () => {
    const docRef = doc(db, "ss-insureds", paramCompanyID);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setGotCompanyInfo(true);
      setFormData({ ...formData, namedInsured: docSnap.data().company });
    } else {
      console.log("No such document!");
    }
  };

  const [driverCriteria, setDriverCriteria] = useState([]);
  const [APDdeductibles, setAPDdeductibles] = useState([]);
  const [MTCdeductibles, setMTCdeductibles] = useState([]);
  const [XSdeductibles, setXSdeductibles] = useState([]);
  const [carriers, setCarriers] = useState([]);

  const getCarriers = async () => {
    const q = query(collection(db, "carriers"));
    const queryData = await getDocs(q);
    setCarriers(queryData.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  const getDriverCriteria = async () => {
    const q = query(collection(db, "driver-criteria"));
    const queryData = await getDocs(q);
    setDriverCriteria(
      queryData.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
    );
  };

  const getAPDdeductibles = async () => {
    const q = query(collection(db, "apd-deductibles"));
    const queryData = await getDocs(q);
    setAPDdeductibles(
      queryData.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
    );
  };

  const getMTCdeductibles = async () => {
    const q = query(collection(db, "mtc-deductibles"));
    const queryData = await getDocs(q);
    setMTCdeductibles(
      queryData.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
    );
  };

  const getXSdeductibles = async () => {
    const q = query(collection(db, "xs-deductibles"));
    const queryData = await getDocs(q);
    setXSdeductibles(
      queryData.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
    );
  };

  const [criteriaName, setCriteriaName] = useState("");
  const [newAPDdeductible, setNewAPDdeductible] = useState("");
  const [newMTCdeductible, setNewMTCdeductible] = useState("");
  const [newXSdeductible, setNewXSdeductible] = useState("");
  const [newCarrier, setNewCarrier] = useState("");

  const addNewCarrier = async () => {
    const newCriteriaRef = doc(collection(db, "carriers"));

    await setDoc(newCriteriaRef, { name: newCarrier });
    closeAddCarrierModal();
    getCarriers();
    setNewCarrier("");
  };

  const addNewCriteria = async () => {
    const newCriteriaRef = doc(collection(db, "driver-criteria"));

    await setDoc(newCriteriaRef, { name: criteriaName });
    closeCriteriaModal();
    getDriverCriteria();
    setCriteriaName("");
  };

  const addNewAPDdeductible = async () => {
    const newAPDdeductibleRef = doc(collection(db, "apd-deductibles"));

    await setDoc(newAPDdeductibleRef, { name: newAPDdeductible });
    closeAPDdeductibleModal();
    getAPDdeductibles();
    setNewAPDdeductible("");
  };

  const addNewMTCdeductible = async () => {
    const newMTCdeductibleRef = doc(collection(db, "mtc-deductibles"));

    await setDoc(newMTCdeductibleRef, { name: newMTCdeductible });
    closeMTCdeductibleModal();
    getMTCdeductibles();
    setNewMTCdeductible("");
  };

  const addNewXSdeductible = async () => {
    const newXSdeductibleRef = doc(collection(db, "xs-deductibles"));

    await setDoc(newXSdeductibleRef, { name: newXSdeductible });
    closeXSdeductibleModal();
    getXSdeductibles();
    setNewXSdeductible("");
  };

  const getDownload = (e) => {
    e.preventDefault();
    const data = formData;
    const url = "https://sstools-api-d4ca8.uc.r.appspot.com/excel";
    const email = auth.currentUser.email;
    axios
      .post(url, { ...data, exportRecipientEmail: email })
      .then((response) => {
        updateDownloadURL(response.data);
        window.open(response.data);
      })
      .then(updateProposalStatus("Submitted"))
      .then(navigate("/account"))
      .catch((e) => {
        console.log(e);
      });
    // .then(alert("File sent"));
  };

  // Edit Lists Stuff
  const [chosenList, setChosenList] = useState("driver-criteria");
  const [currentList, setCurrentList] = useState([]);
  const [itemToDelete, setItemToDelete] = useState("");
  const getListForEditing = async (listRef) => {
    setChosenList(listRef);
    const q = query(collection(db, listRef));
    const queryData = await getDocs(q);
    setCurrentList(
      queryData.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
    );
  };

  const deleteListItem = async (e, listRef) => {
    let verify = await confirmDeletePromptGlobal(
      "You are about to delete a list item! If you are sure, confirm the action"
    );
    if (verify === "DELETE") {
      await deleteDoc(doc(db, listRef, itemToDelete));

      if (listRef === "driver-criteria") getDriverCriteria();
      if (listRef === "apd-deductibles") getAPDdeductibles();
      if (listRef === "mtc-deductibles") getMTCdeductibles();
      if (listRef === "xs-deductibles") getXSdeductibles();
      if (listRef === "carriers") getCarriers();
    }
    closeEditListModal();
    setItemToDelete("");
  };

  const [disabled, setDisabled] = useState(false);
  const [newProposal, setNewProposal] = useState(false);
  const convertDate = (secs) => {
    const output = new Date(secs * 1000);
    return output;
  };

  const getLists = () => {
    getDriverCriteria();
    getAPDdeductibles();
    getMTCdeductibles();
    getXSdeductibles();
    getCarriers();
  };

  const fixData = () => {
    if (myData.effectiveDate) {
      const convertedDate = convertDate(myData.effectiveDate.seconds);
      setFormData({
        ...formData,
        ...myData,
        effectiveDate: convertedDate,
      });
    } else setFormData({ ...formData, ...myData, effectiveDate: "" });
  };

  const { user } = useUsersState();

  const deleteFileFromStorage = async (fileUrl) => {
    if (fileUrl) {
      const storageRef = ref(storage, fileUrl);
      try {
        await deleteObject(storageRef);
        console.log("File deleted successfully from Firebase Storage");
      } catch (error) {
        console.error("Error deleting file from Firebase Storage:", error);
      }
    }
  };

  useEffect(() => {
    paramRefID === "new" && setNewProposal(true);
    if (!newProposal) {
      setIDfound(true);
      !gotProposal && getProposal(paramRefID);
      gotProposal && fixData();
      !gotCompanyInfo && getCompanyInfo();
    }
    if (newProposal) {
      setGotProposal(true);
      setFormData({ ...formData, status: "Unsubmitted" });
    }
    getLists();
    if (formData.status === "Submitted") setDisabled(true);
    if (gotCompanyInfo && gotProposal) setLoading(false);
  }, [db, gotProposal, gotCompanyInfo, disabled]);

  const { t, language } = useTranslations()

  return (
    <main className="">
      {!loading ? (
        <>
          <>
            <h1 className="my-3 text-xl font-bold text-[#072a48]">
              {t('account.proposals.proposal-for')} {formData.namedInsured}
            </h1>
            <fieldset>
              <Box sx={{ width: "100%", height: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="Factfinder Tabs"
                    // disable the tab indicator because it doesn't work well with wrapped container
                    // TabIndicatorProps={{ sx: { display: "none" } }}
                    indicatorColor="black"
                    sx={{
                      "& .MuiTabs-flexContainer": {
                        flexWrap: "wrap",
                        backgroundColor: "lightGray",
                        marginTop: "5px",
                        justifyContent: "center",
                      },
                    }}
                  >
                    <Tab label={t('account.proposals.tabs.policy-info')} {...a11yProps(0)} />
                    <Tab label={t('account.proposals.tabs.apd')} {...a11yProps(1)} />
                    <Tab label={t('account.proposals.tabs.mtc')} {...a11yProps(2)} />
                    <Tab label={t('account.proposals.tabs.excess-mtc')} {...a11yProps(3)} />
                    <Tab label={t('account.proposals.tabs.submit')} {...a11yProps(4)} />
                  </Tabs>
                </Box>
                {/* Policy Info */}
                <TabPanel value={value} index={0} className="bg-gray-100">
                  <h1 className="text-xl font-bold text-[#072a48]">
                    {t('account.proposals.tabs.policy-info')}
                  </h1>
                  <div className="mb-4">
                    Status:
                    <div className="w-full text-bottom mb-1">
                      <h1 className="font-bold text-[#072a48]">
                        {handleSentStatus(formData.status, language)}
                      </h1>
                      {formData.status === "Submitted" && (
                        <button
                          className="text-[#8B0000] hover:text-[#8B0000] mt-2"
                          onClick={(e) => {
                            setFormData({
                              ...formData,
                              status: "Unsubmitted",
                              downloadURL: "#",
                            });
                            updateProposalStatus("Unsubmitted");
                            deleteFileFromStorage(formData.downloadURL);
                          }}
                        >
                          {t('account.proposals.delete-excel')}
                        </button>
                      )}
                    </div>
                  </div>
                  <h1 className="text-xl font-bold text-[#072a48]">
                    {t('account.proposals.policy-information')}
                  </h1>
                  {t('account.proposals.coverages')}:
                  <div className="w-full text-bottom mb-1">
                    <input
                      disabled={disabled}
                      onChange={(e) =>
                        setFormData({ ...formData, APD: !formData.APD })
                      }
                      type="checkbox"
                      name="APD"
                      id="APD"
                      className="form-input"
                      value="APD"
                      checked={formData.APD}
                    />
                    <label
                      htmlFor="APD"
                      className="checkbox-input-label ml-2 mr-2"
                    >
                      {t('account.proposals.auto-physical-damage')}
                    </label>
                  </div>
                  <div className="w-full text-bottom mb-1">
                    <input
                      disabled={disabled}
                      onChange={(e) =>
                        setFormData({ ...formData, MTC: !formData.MTC })
                      }
                      type="checkbox"
                      name="MTC"
                      id="MTC"
                      className="form-input"
                      value="MTC"
                      checked={formData.MTC}
                    />
                    <label htmlFor="MTC" className="checkbox-input-label ml-2">
                      {t('account.proposals.motor-truck-cargo')}
                    </label>
                  </div>
                  <div className="w-full text-bottom mb-1">
                    <input
                      disabled={disabled}
                      onChange={(e) =>
                        setFormData({ ...formData, XSMTC: !formData.XSMTC })
                      }
                      type="checkbox"
                      name="XSMTC"
                      id="XSMTC"
                      className="form-input"
                      value="XSMTC"
                      checked={formData.XSMTC}
                    />
                    <label
                      htmlFor="XSMTC"
                      className="checkbox-input-label ml-2"
                    >
                      {t('account.proposals.excess-motor-truck-cargo')}
                    </label>
                  </div>
                  <div className="flex flex-col py-2">
                    <label className="py-2">{t('account.proposals.named-insured')}:</label>
                    <input
                      disabled={true}
                      className={`p-1 text-xl font-bold ${
                        formData.namedInsured === "" && `border-2 bg-red-100`
                      }  ${
                        formData.namedInsured !== "" && `border-0 bg-gray-100`
                      }`}
                      type="text"
                      value={formData.namedInsured}
                    />
                  </div>
                  <div className="flex flex-col py-2">
                    <label className="py-2">{t('account.proposals.carrier')}:</label>
                    <select
                      disabled={disabled}
                      onChange={(e) =>
                        setFormData({ ...formData, carrier: e.target.value })
                      }
                      onFocus={(e) => (e.target.className = "text-base p-1")}
                      className={`p-1 border-2 ${
                        formData.state === "" && `text-xl border-0 bg-red-100`
                      } ${
                        formData.state !== "" &&
                        `text-xl font-bold light-gray-100 border-0`
                      }`}
                      value={formData.carrier}
                    >
                      <option value="">{t('account.proposals.choose-one')}</option>
                      {carriers.map((carrier, i) => {
                        return (
                          <option key={"carrier" + i} value={carrier.name}>
                            {carrier.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <button
                    className="text-left mt-1 text-[#072a48]"
                    onClick={(e) => openAddCarrierModal(e)}
                  >
                    + {t('account.proposals.add-carrier')}
                  </button>
                  <button
                    className="ml-4 text-left mt-1 text-[#072a48]"
                    onClick={(e) => {
                      getListForEditing("carriers");
                      openEditListModal(e);
                    }}
                  >
                    - {t('account.proposals.delete-carrier')}
                  </button>
                  <div>
                    <Modal
                      isOpen={addCarrierModalIsOpen}
                      onAfterOpen={afterOpenaddCarrierModal}
                      onRequestClose={closeAddCarrierModal}
                      style={customStyles}
                      contentLabel="Add Carrier"
                    >
                      <div>{t('account.proposals.add-carrier')}</div>
                      <label className="block">{t('account.proposals.carrier-name')}:</label>
                      <input
                        disabled={disabled}
                        type="text"
                        onChange={(e) => setNewCarrier(e.target.value)}
                        value={newCarrier}
                      />
                      <button
                        className="block"
                        onClick={(e) => addNewCarrier(e)}
                      >
                        {t('account.proposals.tabs.submit')}
                      </button>
                      <button
                        onClick={(e) => {
                          closeAddCarrierModal(e);
                          setNewCarrier("");
                        }}
                      >
                        {t('account.proposals.cancel')}
                      </button>
                    </Modal>
                  </div>
                  <div className="flex flex-col py-2">
                    <label className="py-2">{t('account.proposals.state')}:</label>
                    <select
                      disabled={disabled}
                      onChange={(e) =>
                        setFormData({ ...formData, state: e.target.value })
                      }
                      onBlur={(e) => {
                        updateOptionTotals(e);
                      }}
                      onFocus={(e) => (e.target.className = "text-base p-1")}
                      className={`p-1 border-2 ${
                        formData.state === "" && `text-xl border-0 bg-red-100`
                      } ${
                        formData.state !== "" &&
                        `text-xl font-bold light-gray-100 border-0`
                      }`}
                      value={formData.state}
                    >
                      <option value="">{t('account.proposals.choose-one')}</option>
                      <option value="AZ">Arizona</option>
                      <option value="CA">California</option>
                      <option value="NM">New Mexico</option>
                      <option value="TX">Texas</option>
                    </select>
                  </div>
                  <div className="flex flex-col py-2">
                    <label className="py-2">{t('account.proposals.effective-date')}:</label>
                    <DatePicker
                      wrapperClassName="datePicker"
                      disabled={disabled}
                      selected={formData.effectiveDate}
                      onChange={(date) =>
                        setFormData({ ...formData, effectiveDate: date })
                      }
                      className="w-full p-1"
                    />
                  </div>
                  <article className="max-w-[200px] mx-auto text-center">
                    <button
                      disabled
                      className="border-2 border-[#072a48] text-[#072a48] text-medium py-1 px-3 m-2"
                    >
                      {t('account.proposals.back')}
                    </button>
                    <button
                      onClick={() => setValue(value + 1)}
                      className="border-2 border-[#072a48] text-[#072a48] text-medium py-1 px-3 m-2"
                    >
                      {t('account.proposals.next')}
                    </button>
                  </article>
                </TabPanel>
                {/* Auto Physical Damage */}
                <TabPanel value={value} index={1} className="bg-gray-100">
                  {!formData.APD ? (
                    <p>
                      {t('account.proposals.select-apd')}
                    </p>
                  ) : (
                    <div>
                      <h1 className="text-xl font-bold text-[#072a48]">
                        {t('account.proposals.auto-physical-damage')}
                      </h1>
                      {/* formData.TIV */}
                      <div className="flex flex-col py-2">
                        <label className="py-2">{t('account.proposals.total-insured-value')}:</label>
                        <input
                          disabled={disabled}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              TIV: parseInt(e.target.value),
                            })
                          }
                          onBlur={(e) => {
                            calculateSurplusLinesAPD();
                          }}
                          onFocus={(e) =>
                            (e.target.className = "text-base p-1")
                          }
                          className={`p-1 text-xl font-bold ${
                            formData.TIV === "" && `border-2 bg-red-100`
                          }  ${formData.TIV !== "" && `border-0 bg-gray-100`}`}
                          type="number"
                          value={formData.TIV}
                        />
                      </div>
                      {/* Towing & Storage Limit */}
                      <div className="flex flex-col py-2">
                        <label className="py-2">{t('account.proposals.towing-storage')}:</label>
                        <select
                          disabled={disabled}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              APDtowingStorageLimit: e.target.value,
                            })
                          }
                          onBlur={(e) => {}}
                          onFocus={(e) =>
                            (e.target.className = "text-base p-1")
                          }
                          className={`p-1 border-2 ${
                            formData.state === "" &&
                            `text-xl border-0 bg-red-100`
                          } ${
                            formData.state !== "" &&
                            `text-xl font-bold light-gray-100 border-0`
                          }`}
                          value={formData.APDtowingStorageLimit}
                        >
                          <option value="">{t('account.proposals.choose-one')}</option>
                          <option value="5000">$5,000</option>
                          <option value="10000">$10,000</option>
                        </select>
                      </div>
                      {/* Trailer Interchange */}
                      <div className="flex flex-col py-2">
                        <label className="py-2">{t('account.proposals.trailer-interchange')}:</label>
                        <select
                          disabled={disabled}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              APDTI: e.target.value,
                            })
                          }
                          onBlur={(e) => {
                            calculateSurplusLinesAPD();
                          }}
                          onFocus={(e) =>
                            (e.target.className = "text-base p-1")
                          }
                          className={`p-1 border-2 ${
                            formData.state === "" &&
                            `text-xl border-0 bg-red-100`
                          } ${
                            formData.state !== "" &&
                            `text-xl font-bold light-gray-100 border-0`
                          }`}
                          value={formData.APDTI}
                        >
                          <option value="">{t('account.proposals.choose-one')}</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </div>
                      {formData.APDTI === "Yes" && (
                        <>
                          {/* Trailer Interchange Limit */}
                          <div className="flex flex-col py-2">
                            <label className="py-2">
                              {t('account.proposals.trailer-interchange-limit')}:
                            </label>
                            <input
                              disabled={disabled}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  APDlimitTI: e.target.value,
                                })
                              }
                              onFocus={(e) =>
                                (e.target.className = "text-base p-1")
                              }
                              className={`p-1 text-xl font-bold ${
                                formData.APDlimitTI === "" &&
                                `border-2 bg-red-100`
                              }  ${
                                formData.APDlimitTI !== "" &&
                                `border-0 bg-gray-100`
                              }`}
                              type="number"
                              value={formData.APDlimitTI}
                            />
                          </div>
                          {/* Trailer Interchange Premium */}
                          <div className="flex flex-col py-2">
                            <label className="py-2">
                              {t('account.proposals.trailer-interchange-premium')}:
                            </label>
                            <input
                              disabled={disabled}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  APDpremiumTI: parseInt(e.target.value),
                                })
                              }
                              onBlur={(e) => {
                                calculateSurplusLinesAPD();
                              }}
                              onFocus={(e) =>
                                (e.target.className = "text-base p-1")
                              }
                              className={`p-1 text-xl font-bold ${
                                formData.APDpremiumTI === "" &&
                                `border-2 bg-red-100`
                              }  ${
                                formData.APDpremiumTI !== "" &&
                                `border-0 bg-gray-100`
                              }`}
                              type="number"
                              value={formData.APDpremiumTI}
                            />
                          </div>
                        </>
                      )}
                      {/* Driver Criteria */}
                      <div className="flex flex-col py-2">
                        <label className="py-2">{t('account.proposals.apd-driver-criteria')}:</label>
                        <select
                          disabled={disabled}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              APDdriverCriteria: e.target.value,
                            })
                          }
                          onBlur={(e) => {}}
                          onFocus={(e) =>
                            (e.target.className = "text-base p-1")
                          }
                          className={`p-1 border-2 ${
                            formData.state === "" &&
                            `text-xl border-0 bg-red-100`
                          } ${
                            formData.state !== "" &&
                            `text-xl font-bold light-gray-100 border-0`
                          }`}
                          value={formData.APDdriverCriteria}
                        >
                          <option value="">{t('account.proposals.choose-one')}</option>

                          {driverCriteria.map((criteria, i) => {
                            return (
                              <option
                                key={"APDDriverCriteria" + i}
                                value={criteria.name}
                              >
                                {criteria.name}
                              </option>
                            );
                          })}
                        </select>
                        <button
                          className="text-left mt-1 text-[#072a48]"
                          onClick={(e) => openCriteriaModal(e)}
                        >
                          + {t('account.proposals.add-criteria')}
                        </button>
                        <button
                          className="text-left mt-1 text-[#072a48]"
                          onClick={(e) => {
                            getListForEditing("driver-criteria");
                            openEditListModal(e);
                          }}
                        >
                          - {t('account.proposals.delete-criteria')}
                        </button>
                        <div>
                          <Modal
                            isOpen={criteriaModalIsOpen}
                            onAfterOpen={afterOpenCriteriaModal}
                            onRequestClose={closeCriteriaModal}
                            style={customStyles}
                            contentLabel="Add Criteria"
                          >
                            <div>{t('account.proposals.new-driver-criteria')}</div>
                            <label className="block">{t('account.proposals.criteria-name')}:</label>
                            <input
                              disabled={disabled}
                              type="text"
                              onChange={(e) => setCriteriaName(e.target.value)}
                              value={criteriaName}
                            />
                            <button
                              className="block"
                              onClick={(e) => addNewCriteria(e)}
                            >
                              {t('account.proposals.tabs.submit')}
                            </button>
                            <button onClick={(e) => closeCriteriaModal(e)}>
                              {t('account.proposals.cancel')}
                            </button>
                          </Modal>
                        </div>
                      </div>

                      {/* Premium/Deductible Options */}
                      <h1 className="text-xl font-bold text-[#072a48] mt-3 mb-1">
                        {t('account.proposals.apd-deductible-options')}.
                      </h1>
                      {formData.TIV === 0 && (
                        <p>{t('account.proposals.enter-tiv')}.</p>
                      )}
                      {formData.state === "" && (
                        <p>{t('account.proposals.select-state')}.</p>
                      )}
                      <button
                        className="text-left mt-1 text-[#072a48]"
                        onClick={(e) => openAPDdeductibleModal(e)}
                      >
                        + {t('account.proposals.add-apd-deductible-list')}
                      </button>
                      <button
                        className="ml-4 text-left mt-1 text-[#072a48]"
                        onClick={(e) => {
                          getListForEditing("apd-deductibles");
                          openEditListModal(e);
                        }}
                      >
                        - {t('account.proposals.delete-deductible')}
                      </button>
                      <div>
                        <Modal
                          isOpen={APDdeductibleModalIsOpen}
                          onAfterOpen={afterOpenAPDdeductibleModal}
                          onRequestClose={closeAPDdeductibleModal}
                          style={customStyles}
                          contentLabel={t('account.proposals.add-apd-deductible')}
                        >
                          <div>{t('account.proposals.new-apd-deductible')}</div>
                          <label className="block">{t('account.proposals.criteria-name')}:</label>
                          <input
                            disabled={disabled}
                            type="text"
                            onChange={(e) =>
                              setNewAPDdeductible(e.target.value)
                            }
                            value={newAPDdeductible}
                          />
                          <button
                            className="block"
                            onClick={(e) => addNewAPDdeductible(e)}
                          >
                            {t('account.proposals.tabs.submit')}
                          </button>
                          <button
                            onClick={(e) => {
                              closeAPDdeductibleModal(e);
                              setNewAPDdeductible("");
                            }}
                          >
                            {t('account.proposals.cancel')}
                          </button>
                        </Modal>
                      </div>
                      <Table>
                        <Thead>
                          <Tr className="bg-black text-white">
                            <Td>#</Td>
                            <Td>{t('account.proposals.deductible')}</Td>
                            <Td>{t('account.proposals.rate')} (%)</Td>
                            <Td>{t('account.proposals.premium')}</Td>
                            <Td>{t('account.proposals.policy-fee')}</Td>
                            <Td>{t('account.proposals.company-fee')}</Td>
                            <Td>{t('account.proposals.sl-tax')}</Td>
                            <Td>{t('account.proposals.stamp-fee')}</Td>
                            <Td>{t('account.proposals.total')}</Td>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {formData.APDoptionFields.map((option, i) => {
                            return (
                              <Tr key={"APDoption" + i}>
                                <Td>{i + 1}</Td>
                                {/* Deductible */}
                                <Td className="max-w-[275px] min-w-[150px]">
                                  <select
                                    disabled={disabled}
                                    name="deductible"
                                    onChange={(e) =>
                                      handleChangeInputAPDdeductible(i, e)
                                    }
                                    onBlur={(e) => calculateSurplusLinesAPD()}
                                    className={`w-full text-xl font-medium border-0 font-bold`}
                                    value={option.deductible}
                                  >
                                    <option value="">{t('account.proposals.select')}</option>
                                    {APDdeductibles.map((deductible, i) => {
                                      return (
                                        <option
                                          key={"APDDeductible" + i}
                                          value={deductible.name}
                                        >
                                          {deductible.name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </Td>
                                {/* Rate */}
                                <Td className="min-w-[100px]">
                                  <input
                                    disabled={disabled}
                                    name="rate"
                                    step=".01"
                                    onChange={(e) =>
                                      handleChangeInputAPDdeductible(i, e)
                                    }
                                    onBlur={(e) => calculateSurplusLinesAPD()}
                                    className={`max-w-[100px] text-xl font-medium border-0 font-bold`}
                                    type="number"
                                    value={option.rate}
                                  />
                                </Td>
                                {/* Option Premium */}
                                <Td className="max-w-[125px]">
                                  <p
                                    name="premium"
                                    className={`max-w-[125px] text-lg font-medium border-0 font-bold`}
                                  >
                                    {option.premium}
                                  </p>
                                </Td>
                                {/* Policy Fee */}
                                <Td className="max-w-[90px]">
                                  <input
                                    disabled={disabled}
                                    name="policyFee"
                                    onChange={(e) =>
                                      handleChangeInputAPDdeductible(i, e)
                                    }
                                    onBlur={(e) => calculateSurplusLinesAPD()}
                                    className={`max-w-[90px] text-xl font-medium border-0 font-bold`}
                                    type="number"
                                    value={option.policyFee}
                                  />
                                </Td>
                                {/* Company Fee */}
                                <Td className="max-w-[100px]">
                                  <input
                                    disabled={disabled}
                                    name="companyFee"
                                    onChange={(e) =>
                                      handleChangeInputAPDdeductible(i, e)
                                    }
                                    onBlur={(e) => calculateSurplusLinesAPD()}
                                    className={`max-w-[100px] text-xl font-medium border-0 font-bold`}
                                    type="number"
                                    value={option.companyFee}
                                  />
                                </Td>
                                {/* SL Tax */}
                                <Td className="max-w-[80px]">
                                  <p
                                    name="SLtax"
                                    className={`max-w-[125px] text-lg font-medium border-0 font-bold`}
                                  >
                                    {option.SLtax}
                                  </p>
                                </Td>
                                {/* Stamp Fee */}
                                <Td className="max-w-[95px]">
                                  <p
                                    name="SLstampFee"
                                    className={`max-w-[125px] text-lg font-medium border-0 font-bold`}
                                  >
                                    {option.SLstampFee}
                                  </p>
                                </Td>
                                {/* Total Premium */}
                                <Td className="max-w-[150px]">
                                  <p
                                    name="totalPremium"
                                    className={`max-w-[125px] text-lg font-medium border-0 font-bold`}
                                  >
                                    {option.totalPremium}
                                  </p>
                                </Td>
                              </Tr>
                            );
                          })}
                        </Tbody>
                      </Table>
                      <hr className="my-2" />
                      <button
                        className="text-[#072a48]"
                        onClick={(e) => handleAddAPD(e)}
                      >
                        {t('account.proposals.add-option')}
                      </button>
                      <article className="max-w-[200px] mx-auto text-center">
                        <button
                          className="border-2 border-[#072a48] text-[#072a48] text-medium py-1 px-3 m-2"
                          onClick={() => setValue(value - 1)}
                        >
                          {t('account.proposals.back')}
                        </button>
                        <button
                          onClick={() => setValue(value + 1)}
                          className="border-2 border-[#072a48] text-[#072a48] text-medium py-1 px-3 m-2"
                        >
                          {t('account.proposals.next')}
                        </button>
                      </article>
                    </div>
                  )}
                </TabPanel>

                {/* Motor Truck Cargo */}
                <TabPanel value={value} index={2} className="bg-gray-100">
                  {!formData.MTC ? (
                    <p>
                      {t('account.proposals.edit-options')}
                    </p>
                  ) : (
                    <div>
                      <h1 className="text-xl font-bold text-[#072a48]">
                        {t('account.proposals.motor-truck-cargo')}
                      </h1>
                      <div className="flex flex-col py-2">
                        <label className="py-2">{t('account.proposals.mtc-limit')}:</label>
                        <input
                          disabled={disabled}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              limitMTC: e.target.value,
                            })
                          }
                          onFocus={(e) =>
                            (e.target.className = "text-base p-1")
                          }
                          className={`p-1 text-xl font-bold ${
                            formData.limitMTC === "" && `border-2 bg-red-100`
                          }  ${
                            formData.limitMTC !== "" && `border-0 bg-gray-100`
                          }`}
                          type="number"
                          value={formData.limitMTC}
                        />
                      </div>
                      <div className="flex flex-col py-2">
                        <label className="py-2">{t('account.proposals.number-units')}:</label>
                        <input
                          disabled={disabled}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              numberOfUnits: parseInt(e.target.value),
                            })
                          }
                          onBlur={(e) => {
                            calculateSurplusLinesMTC();
                          }}
                          onFocus={(e) =>
                            (e.target.className = "text-base p-1")
                          }
                          className={`p-1 text-xl font-bold ${
                            formData.numberOfUnits === "" &&
                            `border-2 bg-red-100`
                          }  ${
                            formData.numberOfUnits !== "" &&
                            `border-0 bg-gray-100`
                          }`}
                          type="number"
                          value={formData.numberOfUnits}
                        />
                      </div>
                      <div className="flex flex-col py-2">
                        <label className="py-2">{t('account.proposals.debris-removal')}:</label>
                        <select
                          disabled={disabled}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              debrisRemoval: e.target.value,
                            })
                          }
                          onBlur={(e) => {}}
                          onFocus={(e) =>
                            (e.target.className = "text-base p-1")
                          }
                          className={`p-1 border-2 ${
                            formData.state === "" &&
                            `text-xl border-0 bg-red-100`
                          } ${
                            formData.state !== "" &&
                            `text-xl font-bold light-gray-100 border-0`
                          }`}
                          value={formData.debrisRemoval}
                        >
                          <option value="">{t('account.proposals.choose-one')}</option>
                          <option value="Yes">{t('account.proposals.yes')}</option>
                          <option value="No">{t('account.proposals.no')}</option>
                        </select>
                      </div>
                      {formData.debrisRemoval === "Yes" && (
                        <>
                          <div className="flex flex-col py-2">
                            <label className="py-2">
                            {t('account.proposals.debris-removal-limit')}:
                            </label>
                            <input
                              disabled={disabled}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  limitDebrisRemoval: e.target.value,
                                })
                              }
                              onFocus={(e) =>
                                (e.target.className = "text-base p-1")
                              }
                              className={`p-1 text-xl font-bold ${
                                formData.limitDebrisRemoval === "" &&
                                `border-2 bg-red-100`
                              }  ${
                                formData.limitDebrisRemoval !== "" &&
                                `border-0 bg-gray-100`
                              }`}
                              type="number"
                              value={formData.limitDebrisRemoval}
                            />
                          </div>
                        </>
                      )}
                      <div className="flex flex-col py-2">
                        <label className="py-2">{t('account.proposals.refrigerated-breakdown')}:</label>
                        <select
                          disabled={disabled}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              reefer: e.target.value,
                            })
                          }
                          onBlur={(e) => {}}
                          onFocus={(e) =>
                            (e.target.className = "text-base p-1")
                          }
                          className={`p-1 border-2 ${
                            formData.state === "" &&
                            `text-xl border-0 bg-red-100`
                          } ${
                            formData.state !== "" &&
                            `text-xl font-bold light-gray-100 border-0`
                          }`}
                          value={formData.reefer}
                        >
                          <option value="">{t('account.proposals.choose-one')}</option>
                          <option value="Yes">{t('account.proposals.yes')}</option>
                          <option value="No">{t('account.proposals.no')}</option>
                        </select>
                      </div>
                      <div className="flex flex-col py-2">
                        <label className="py-2">
                        {t('account.proposals.target-interest')}:
                        </label>
                        <select
                          disabled={disabled}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              targetInterest: e.target.value,
                            })
                          }
                          onBlur={(e) => {}}
                          onFocus={(e) =>
                            (e.target.className = "text-base p-1")
                          }
                          className={`p-1 border-2 ${
                            formData.state === "" &&
                            `text-xl border-0 bg-red-100`
                          } ${
                            formData.state !== "" &&
                            `text-xl font-bold light-gray-100 border-0`
                          }`}
                          value={formData.targetInterest}
                        >
                          <option value="">{t('account.proposals.choose-one')}</option>
                          <option value="Yes">{t('account.proposals.yes')}</option>
                          <option value="No">{t('account.proposals.no')}</option>
                        </select>
                      </div>
                      {formData.targetInterest === "Yes" && (
                        <>
                          <div className="flex flex-col py-2">
                            <ol className="ml-10 list-decimal list-inside w-full">
                              <li>
                                <input
                                  disabled={disabled}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      targetInterestA: e.target.value,
                                    })
                                  }
                                  onFocus={(e) =>
                                    (e.target.className =
                                      "text-base p-1 mb-1 w-11/12")
                                  }
                                  className={`p-1 mb-1 w-11/12 text-xl font-bold ${
                                    formData.targetInterestA === "" &&
                                    `border-2 bg-red-100`
                                  }  ${
                                    formData.targetInterestA !== "" &&
                                    `border-0 bg-gray-100`
                                  }`}
                                  type="text"
                                  value={formData.targetInterestA}
                                  placeholder="Ex: Electronics, Garments, Alcohol"
                                />
                              </li>
                              <li>
                                <input
                                  disabled={disabled}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      targetInterestB: e.target.value,
                                    })
                                  }
                                  onFocus={(e) =>
                                    (e.target.className =
                                      "text-base p-1 mb-1 w-11/12")
                                  }
                                  className={`p-1 mb-1 w-11/12 text-xl font-bold ${
                                    formData.targetInterestB === "" &&
                                    `border-2 bg-red-100`
                                  }  ${
                                    formData.targetInterestB !== "" &&
                                    `border-0 bg-gray-100`
                                  }`}
                                  type="text"
                                  value={formData.targetInterestB}
                                  placeholder="USD 100,000"
                                />
                              </li>
                              <li>
                                <input
                                  disabled={disabled}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      targetInterestC: e.target.value,
                                    })
                                  }
                                  onFocus={(e) =>
                                    (e.target.className =
                                      "text-base p-1 mb-1 w-11/12")
                                  }
                                  className={`p-1 mb-1 w-11/12 text-xl font-bold ${
                                    formData.targetInterestC === "" &&
                                    `border-2 bg-red-100`
                                  }  ${
                                    formData.targetInterestC !== "" &&
                                    `border-0 bg-gray-100`
                                  }`}
                                  type="text"
                                  value={formData.targetInterestC}
                                  placeholder="USD 10,000"
                                />
                              </li>
                            </ol>
                          </div>
                        </>
                      )}
                      <div className="flex flex-col py-2">
                        <label className="py-2">{t('account.proposals.trailer-interchange')}:</label>
                        <select
                          disabled={disabled}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              MTCTI: e.target.value,
                            })
                          }
                          onBlur={(e) => {
                            calculateSurplusLinesMTC();
                          }}
                          onFocus={(e) =>
                            (e.target.className = "text-base p-1")
                          }
                          className={`p-1 border-2 ${
                            formData.state === "" &&
                            `text-xl border-0 bg-red-100`
                          } ${
                            formData.state !== "" &&
                            `text-xl font-bold light-gray-100 border-0`
                          }`}
                          value={formData.MTCTI}
                        >
                          <option value="">{t('account.proposals.choose-one')}</option>
                          <option value="Yes">{t('account.proposals.yes')}</option>
                          <option value="No">{t('account.proposals.no')}</option>
                        </select>
                      </div>

                      {formData.MTCTI === "Yes" && (
                        <>
                          <div className="flex flex-col py-2">
                            <label className="py-2">
                            {t('account.proposals.trailer-interchange-limit')}:
                            </label>
                            <input
                              disabled={disabled}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  MTClimitTI: e.target.value,
                                })
                              }
                              onFocus={(e) =>
                                (e.target.className = "text-base p-1")
                              }
                              className={`p-1 text-xl font-bold ${
                                formData.MTClimitTI === "" &&
                                `border-2 bg-red-100`
                              }  ${
                                formData.MTClimitTI !== "" &&
                                `border-0 bg-gray-100`
                              }`}
                              type="number"
                              value={formData.MTClimitTI}
                            />
                          </div>
                          <div className="flex flex-col py-2">
                            <label className="py-2">
                            {t('account.proposals.trailer-interchange-premium')}:
                            </label>
                            <input
                              disabled={disabled}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  MTCpremiumTI: parseInt(e.target.value),
                                })
                              }
                              onBlur={(e) => {
                                calculateSurplusLinesMTC();
                              }}
                              onFocus={(e) =>
                                (e.target.className = "text-base p-1")
                              }
                              className={`p-1 text-xl font-bold ${
                                formData.MTCpremiumTI === "" &&
                                `border-2 bg-red-100`
                              }  ${
                                formData.MTCpremiumTI !== "" &&
                                `border-0 bg-gray-100`
                              }`}
                              type="number"
                              value={formData.MTCpremiumTI}
                            />
                          </div>
                        </>
                      )}

                      <div className="flex flex-col py-2">
                        <label className="py-2">{t('account.proposals.mtc-driver-criteria')}:</label>
                        <select
                          disabled={disabled}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              MTCdriverCriteria: e.target.value,
                            })
                          }
                          onBlur={(e) => {}}
                          onFocus={(e) =>
                            (e.target.className = "text-base p-1")
                          }
                          className={`p-1 border-2 ${
                            formData.state === "" &&
                            `text-xl border-0 bg-red-100`
                          } ${
                            formData.state !== "" &&
                            `text-xl font-bold light-gray-100 border-0`
                          }`}
                          value={formData.MTCdriverCriteria}
                        >
                          <option value="">{t('account.proposals.choose-one')}</option>
                          {driverCriteria.map((criteria, i) => {
                            return (
                              <option
                                key={"driverCriteria" + i}
                                value={criteria.name}
                              >
                                {criteria.name}
                              </option>
                            );
                          })}
                        </select>
                        <button
                          className="text-left mt-1 text-[#072a48]"
                          onClick={(e) => openCriteriaModal(e)}
                        >
                          + {t('account.proposals.add-criteria-list')}
                        </button>
                        <div>
                          <Modal
                            isOpen={criteriaModalIsOpen}
                            onAfterOpen={afterOpenCriteriaModal}
                            onRequestClose={closeCriteriaModal}
                            style={customStyles}
                            contentLabel={t('account.proposals.add-criteria')}
                          >
                            <div>{t('account.proposals.new-driver-criteria-mtc')}</div>
                            <label className="block">{t('account.proposals.criteria-name')}:</label>
                            <input
                              disabled={disabled}
                              type="text"
                              onChange={(e) => setCriteriaName(e.target.value)}
                              value={criteriaName}
                            />
                            <button
                              className="block"
                              onClick={(e) => addNewCriteria(e)}
                            >
                              {t('account.proposals.tabs.submit')}
                            </button>
                            <button
                              onClick={(e) => {
                                closeCriteriaModal(e);
                                setCriteriaName("");
                              }}
                            >
                              {t('account.proposals.cancel')}
                            </button>
                          </Modal>
                        </div>
                      </div>

                      {/* Premium/Deductible Options */}
                      <h1 className="text-xl font-bold text-[#072a48] mt-4">
                      {t('account.proposals.mtc-deductible-options')}
                      </h1>
                      {formData.numberOfUnits === 0 && (
                        <p>
                        {t('account.proposals.enter-units-options')}.
                        </p>
                      )}
                      {formData.state === "" && (
                        <p>{t('account.proposals.select-state-mtc')}.</p>
                      )}
                      <button
                        className="text-left text-[#072a48] mb-1"
                        onClick={(e) => openMTCdeductibleModal(e)}
                      >
                        + {t('account.proposals.add-mtc-deductible-list')}
                      </button>
                      <button
                        className="ml-4 text-left mt-1 text-[#072a48]"
                        onClick={(e) => {
                          getListForEditing("mtc-deductibles");
                          openEditListModal(e);
                        }}
                      >
                        - {t('account.proposals.delete-deductible-mtc')}
                      </button>
                      <div>
                        <Modal
                          isOpen={MTCdeductibleModalIsOpen}
                          onAfterOpen={afterOpenMTCdeductibleModal}
                          onRequestClose={closeMTCdeductibleModal}
                          style={customStyles}
                          contentLabel={t('account.proposals.add-mtc-deductible')}
                        >
                          <div>{t('account.proposals.new-mtc-deductible')}</div>
                          <label className="block">{t('account.proposals.criteria-name')}:</label>
                          <input
                            disabled={disabled}
                            type="text"
                            onChange={(e) =>
                              setNewMTCdeductible(e.target.value)
                            }
                            value={newMTCdeductible}
                          />
                          <button
                            className="block"
                            onClick={(e) => addNewMTCdeductible(e)}
                          >
                            {t('account.proposals.tabs.submit')}
                          </button>
                          <button
                            onClick={(e) => {
                              closeMTCdeductibleModal(e);
                              setNewMTCdeductible("");
                            }}
                          >
                            {t('account.proposals.cancel')}
                          </button>
                        </Modal>
                      </div>
                      <Table>
                        <Thead>
                          <Tr className="bg-black text-white">
                            <Td>#</Td>
                            <Td>{t('account.proposals.deductible')}</Td>
                            <Td>{t('account.proposals.rate')} (PPU)</Td>
                            <Td>{t('account.proposals.premium')}</Td>
                            <Td>{t('account.proposals.policy-fee')}</Td>
                            <Td>{t('account.proposals.company-fee')}</Td>
                            <Td>{t('account.proposals.sl-tax')}</Td>
                            <Td>{t('account.proposals.stamp-fee')}</Td>
                            <Td>{t('account.proposals.total')}</Td>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {formData.MTCoptionFields.map((option, i) => {
                            return (
                              <Tr key={"MTCoption" + i}>
                                <Td>{i + 1}</Td>
                                {/* Deductible */}
                                <Td className="max-w-[275px] min-w-[150px]">
                                  <select
                                    disabled={disabled}
                                    name="deductible"
                                    onChange={(e) =>
                                      handleChangeInputMTCdeductible(i, e)
                                    }
                                    className={`w-full text-xl font-medium border-0 font-bold`}
                                    type="number"
                                    value={option.deductible}
                                  >
                                    <option>Select</option>
                                    {MTCdeductibles.map((deductible, i) => {
                                      return (
                                        <option
                                          key={"MTCdeductible" + i}
                                          value={deductible.name}
                                        >
                                          {deductible.name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </Td>
                                {/* Rate */}
                                <Td className="max-w-[115px]">
                                  <input
                                    disabled={disabled}
                                    name="rate"
                                    onChange={(e) =>
                                      handleChangeInputMTCdeductible(i, e)
                                    }
                                    onBlur={(e) => calculateSurplusLinesMTC()}
                                    className={`max-w-[115px] text-xl font-medium border-0 font-bold`}
                                    type="number"
                                    value={option.rate}
                                  />
                                </Td>
                                {/* Option Premium */}
                                <Td className="max-w-[125px]">
                                  <p
                                    name="premium"
                                    className={`max-w-[125px] text-lg font-medium border-0 font-bold`}
                                  >
                                    {option.premium}
                                  </p>
                                </Td>
                                {/* Policy Fee */}
                                <Td className="max-w-[90px]">
                                  <input
                                    disabled={disabled}
                                    name="policyFee"
                                    onChange={(e) =>
                                      handleChangeInputMTCdeductible(i, e)
                                    }
                                    onBlur={(e) => calculateSurplusLinesMTC()}
                                    className={`max-w-[90px] text-xl font-medium border-0 font-bold`}
                                    type="number"
                                    value={option.policyFee}
                                  />
                                </Td>
                                {/* Company Fee */}
                                <Td className="max-w-[100px]">
                                  <input
                                    disabled={disabled}
                                    name="companyFee"
                                    onChange={(e) =>
                                      handleChangeInputMTCdeductible(i, e)
                                    }
                                    onBlur={(e) => calculateSurplusLinesMTC()}
                                    className={`max-w-[100px] text-xl font-medium border-0 font-bold`}
                                    type="number"
                                    value={option.companyFee}
                                  />
                                </Td>
                                {/* SL Tax */}
                                <Td className="max-w-[80px]">
                                  <p
                                    name="SLtax"
                                    className={`max-w-[125px] text-lg font-medium border-0 font-bold`}
                                  >
                                    {option.SLtax}
                                  </p>
                                </Td>
                                {/* Stamp Fee */}
                                <Td className="max-w-[95px]">
                                  <p
                                    name="SLstampFee"
                                    className={`max-w-[125px] text-lg font-medium border-0 font-bold`}
                                  >
                                    {option.SLstampFee}
                                  </p>
                                </Td>
                                {/* Total Premium */}
                                <Td className="max-w-[150px]">
                                  <p
                                    name="totalPremium"
                                    className={`max-w-[125px] text-lg font-medium border-0 font-bold`}
                                  >
                                    {option.totalPremium}
                                  </p>
                                </Td>
                              </Tr>
                            );
                          })}
                        </Tbody>
                      </Table>
                      <hr className="my-2" />
                      <button
                        className="text-[#072a48]"
                        onClick={(e) => handleAddMTC(e)}
                      >
                        {t('account.proposals.add-option')}
                      </button>
                      <article className="max-w-[200px] mx-auto text-center">
                        <button
                          className="border-2 border-[#072a48] text-[#072a48] text-medium py-1 px-3 m-2"
                          onClick={() => setValue(value - 1)}
                        >
                          {t('account.proposals.back')}
                        </button>
                        <button
                          onClick={() => setValue(value + 1)}
                          className="border-2 border-[#072a48] text-[#072a48] text-medium py-1 px-3 m-2"
                        >
                          {t('account.proposals.next')}
                        </button>
                      </article>
                    </div>
                  )}
                </TabPanel>
                {/* Excess Motor Truck Cargo */}
                <TabPanel value={value} index={3} className="bg-gray-100">
                  {!formData.XSMTC ? (
                    <p>
                      {t('account.proposals.edit-options')}
                    </p>
                  ) : (
                    <div>
                      <h1 className="text-xl font-bold text-[#072a48]">
                        {t('account.proposals.excess-motor-truck-cargo')}
                      </h1>
                      <div className="flex flex-col py-2">
                        <label className="py-2">{t('account.proposals.excess-mtc-limit')}:</label>
                        <input
                          disabled={disabled}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              XSlimit: e.target.value,
                            })
                          }
                          onFocus={(e) =>
                            (e.target.className = "text-base p-1")
                          }
                          className={`p-1 text-xl font-bold ${
                            formData.XSlimit === "" && `border-2 bg-red-100`
                          }  ${
                            formData.XSlimit !== "" && `border-0 bg-gray-100`
                          }`}
                          type="number"
                          value={formData.XSlimit}
                        />
                      </div>
                      {/* Premium/Deductible Options */}
                      <h1 className="text-xl font-bold text-[#072a48] mt-4">
                        {t('account.proposals.excess-deductible-options')}
                      </h1>
                      {formData.numberOfUnits === 0 && (
                        <p>
                          {t('account.proposals.enter-units-xs')}.
                        </p>
                      )}
                      {formData.state === "" && (
                        <p>{t('account.proposals.select-state-xs')}.</p>
                      )}
                      {/* <button
              className="text-left text-[#072a48] mb-1"
              onClick={(e) => openXSdeductibleModal(e)}
            >
              + Add an XS Deductible to the list
            </button> */}
                      <div>
                        <Modal
                          isOpen={XSdeductibleModalIsOpen}
                          onAfterOpen={afterOpenXSdeductibleModal}
                          onRequestClose={closeXSdeductibleModal}
                          style={customStyles}
                          contentLabel={t('account.proposals.new-xs-deductible')}
                        >
                          <div>{t('account.proposals.new-xs-deductible')}</div>
                          <label className="block">{t('account.proposals.criteria-name')}:</label>
                          <input
                            disabled={disabled}
                            type="text"
                            onChange={(e) => setNewXSdeductible(e.target.value)}
                            value={newXSdeductible}
                          />
                          <button
                            className="block"
                            onClick={(e) => addNewXSdeductible(e)}
                          >
                            {t('account.proposals.tabs.submit')}
                          </button>
                          <button
                            onClick={(e) => {
                              closeXSdeductibleModal(e);
                              setNewXSdeductible("");
                            }}
                          >
                            {t('account.proposals.cancel')}
                          </button>
                        </Modal>
                      </div>
                      <Table>
                        <Thead>
                          <Tr className="bg-black text-white">
                            <Td>#</Td>
                            <Td>{t('account.proposals.rate')} (PPU)</Td>
                            <Td>{t('account.proposals.premium')}</Td>
                            <Td>{t('account.proposals.policy-fee')}</Td>
                            <Td>{t('account.proposals.company-fee')}</Td>
                            <Td>{t('account.proposals.sl-tax')}</Td>
                            <Td>{t('account.proposals.stamp-fee')}</Td>
                            <Td>{t('account.proposals.total')}</Td>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {formData.XSoptionFields.map((option, i) => {
                            return (
                              <Tr key={"XSoption" + i}>
                                <Td>{i + 1}</Td>
                                {/* Rate */}
                                <Td className="max-w-[115px]">
                                  <input
                                    disabled={disabled}
                                    name="rate"
                                    onChange={(e) =>
                                      handleChangeInputXSdeductible(i, e)
                                    }
                                    onBlur={(e) => calculateSurplusLinesXS()}
                                    className={`max-w-[115px] text-xl font-medium border-0 font-bold`}
                                    type="number"
                                    value={option.rate}
                                  />
                                </Td>
                                {/* Option Premium */}
                                <Td className="max-w-[125px]">
                                  <p
                                    name="premium"
                                    className={`max-w-[125px] text-lg font-medium border-0 font-bold`}
                                  >
                                    {option.premium}
                                  </p>
                                </Td>
                                {/* Policy Fee */}
                                <Td className="max-w-[90px]">
                                  <input
                                    disabled={disabled}
                                    name="policyFee"
                                    onChange={(e) =>
                                      handleChangeInputXSdeductible(i, e)
                                    }
                                    onBlur={(e) => calculateSurplusLinesXS()}
                                    className={`max-w-[90px] text-xl font-medium border-0 font-bold`}
                                    type="number"
                                    value={option.policyFee}
                                  />
                                </Td>
                                {/* Company Fee */}
                                <Td className="max-w-[100px]">
                                  <input
                                    disabled={disabled}
                                    name="companyFee"
                                    onChange={(e) =>
                                      handleChangeInputXSdeductible(i, e)
                                    }
                                    onBlur={(e) => calculateSurplusLinesXS()}
                                    className={`max-w-[100px] text-xl font-medium border-0 font-bold`}
                                    type="number"
                                    value={option.companyFee}
                                  />
                                </Td>
                                {/* SL Tax */}
                                <Td className="max-w-[80px]">
                                  <p
                                    name="SLtax"
                                    className={`max-w-[125px] text-lg font-medium border-0 font-bold`}
                                  >
                                    {option.SLtax}
                                  </p>
                                </Td>
                                {/* Stamp Fee */}
                                <Td className="max-w-[95px]">
                                  <p
                                    name="SLstampFee"
                                    className={`max-w-[125px] text-lg font-medium border-0 font-bold`}
                                  >
                                    {option.SLstampFee}
                                  </p>
                                </Td>
                                {/* Total Premium */}
                                <Td className="max-w-[150px]">
                                  <p
                                    name="totalPremium"
                                    className={`max-w-[125px] text-lg font-medium border-0 font-bold`}
                                  >
                                    {option.totalPremium}
                                  </p>
                                </Td>
                              </Tr>
                            );
                          })}
                        </Tbody>
                      </Table>
                      <hr className="my-2" />
                      <button
                        className="text-[#072a48]"
                        onClick={(e) => handleAddXS(e)}
                      >
                        {t('account.proposals.add-options')}
                      </button>
                      <article className="max-w-[200px] mx-auto text-center">
                        <button
                          className="border-2 border-[#072a48] text-[#072a48] text-medium py-1 px-3 m-2"
                          onClick={() => setValue(value - 1)}
                        >
                          {t('account.proposals.back')}
                        </button>
                        <button
                          onClick={() => setValue(value + 1)}
                          className="border-2 border-[#072a48] text-[#072a48] text-medium py-1 px-3 m-2"
                        >
                          {t('account.proposals.next')}
                        </button>
                      </article>
                    </div>
                  )}
                </TabPanel>
                {/* Submit & Export Tab */}
                <TabPanel value={value} index={4} className="bg-gray-100">
                  {formData.status === "Submitted" && (
                    <div className="w-full text-center mt-2">
                      <a
                        className="bg-green-900 hover:bg-green-700 w-full p-4 mt-4 mx-auto text-white text-xl text-medium max-w-[700px]"
                        href={formData.downloadURL}
                      >
                        {t('account.proposals.download-proposal')}
                      </a>
                    </div>
                  )}
                  {formData.status === "Unsubmitted" && (
                    <>
                      <div className="mx-auto max-w-[700px]">
                        {newProposal && (
                          <button
                            onClick={(e) => saveNewProposal(e)}
                            className="bg-yellow-900 hover:bg-yellow-700 w-full p-4 mt-4 mx-auto text-white text-xl text-medium max-w-[700px]"
                          >
                            {t('account.proposals.save-exit')}
                          </button>
                        )}

                        {!newProposal && gotProposal && (
                          <div className="w-full text-center mt-2">
                            <button
                              onClick={(e) => {
                                saveProposal(e);
                                navigate("/account");
                              }}
                              className="bg-yellow-900 hover:bg-yellow-700 w-full p-4 mt-4 mx-auto text-white text-xl text-medium max-w-[700px]"
                            >
                              {t('account.proposals.save-exit')}
                            </button>

                            <button
                              className="bg-green-900 hover:bg-green-700 w-full p-4 mt-4 mx-auto text-white text-xl text-medium max-w-[700px]"
                              onClick={(e) => getDownload(e)}
                              download
                            >
                              {t('account.proposals.submit-download')}
                            </button>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </TabPanel>
              </Box>
              {/* Edit Lists Modal */}
              <div>
                <Modal
                  isOpen={editListModalIsOpen}
                  onAfterOpen={afterOpenEditListModal}
                  onRequestClose={closeEditListModal}
                  style={customStyles}
                  contentLabel={t('account.proposals.edit-list')}
                >
                  <h1 className="text-lg font-bold text-[#072a48]">
                  {t('account.proposals.edit-list')}
                  </h1>
                  <label className="block mt-2">{t('account.proposals.list-to-edit')}:</label>
                  <select
                    disabled={disabled}
                    onChange={(e) => {
                      getListForEditing(e.target.value);
                    }}
                    onBlur={(e) => {}}
                    value={chosenList}
                  >
                    <option value="driver-criteria">{t('account.proposals.driver-criteria')}</option>
                    <option value="apd-deductibles">{t('account.proposals.apd-deductibles')}</option>
                    <option value="mtc-deductibles">{t('account.proposals.mtc-deductibles')}</option>
                    <option value="carriers">{t('account.proposals.carriers')}</option>
                  </select>

                  <label className="block">{t('account.proposals.item-to-delete')}:</label>
                  <select
                    disabled={disabled}
                    onChange={(e) => {
                      setItemToDelete(e.target.value);
                    }}
                    value={itemToDelete}
                  >
                    <option value="">{t('account.proposals.choose-one')}</option>
                    {currentList.map((item, i) => {
                      return (
                        <option key={"currentListItem" + i} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>

                  <button
                    className="block bg-[#072a48] text-white p-2 mt-3"
                    onClick={(e) => deleteListItem(e, chosenList)}
                  >
                    {t('account.proposals.delete-item')}
                  </button>
                  <button
                    className="mt-2"
                    onClick={(e) => {
                      closeEditListModal(e);
                    }}
                  >
                    {t('account.proposals.cancel')}
                  </button>
                </Modal>
              </div>
            </fieldset>
          </>
        </>
      ) : (
        <>{t('account.proposals.loading')}...</>
      )}
    </main>
  );
}

export default Proposal;
