import { collection, doc, getDoc, getDocs, query } from "firebase/firestore";
import { db } from "../firebase";
import { numberWithCommas } from "./helpers";
import { handleFilterStatus, manageEndorsementOptions } from "./searchFilters";
import {
  handleDocumentTypeStatus,
  handleEndorsementTypeStatus
} from "./constants";
import { filterRightPoliciesDetails } from "../Components/PolicyLifecycleManager/Drivers/setPolicyInfo";

export const getLastId = (path) => {
  if (!path) return '';
  const segments = path.split('/');
  return segments.pop();
};

export const getMultipleMatchSubcollections = async (
  endorsement,
  subcollection
) => {
  const {
    data,
    agencyID,
    insuredID
  } = endorsement;
  const q = query(
    collection(
      db,
      "agencies",
      agencyID,
      "insureds",
      insuredID,
      subcollection
    )
  );
  const ids = data.map(id => getLastId(id.path));
  const querySnapshot = await getDocs(q);
  const filteredDocs = querySnapshot.docs.filter((doc) => {
    return ids.includes(doc.id);
  });
  const documentsData = filteredDocs.map((doc) => {
    return {
      ...doc.data(),
      id: doc.id,
    };
  });
  return documentsData;
};

export const removeMultipleDriversTable = async (
  endorsement,
  subcollection,
  policyID
) => {
  const matchedSubcollections = await getMultipleMatchSubcollections(
    endorsement,
    subcollection,
    policyID
  );
  const tableData = matchedSubcollections.map((data) => {
    const {
      driverFirst,
      driverLast,
      licenseCountry,
      licenseNumber,
      licenseState,
      mxNMP,
      yoe,
      licensePhoto,
      driverLookupStatus,
    } = data || {};
    const fullName = `${driverFirst} ${driverLast}`;
    const iconLeft = [];
    iconLeft.push({
      name: "copyToClipboard",
      toField: "fullName",
      className: "text-sky-900 hover:text-[#072a48] mr-2",
      onClick: () => navigator.clipboard.writeText(fullName),
    });
    if (!licensePhoto) {
      iconLeft.push({
        name: "noDriverLicense",
        toField: "fullName",
        className: "text-[#8B0000] hover:text-[#072a48] mr-2",
        onClick: null,
      });
    }
    if (
      driverLookupStatus !== "Incomplete" &&
      driverLookupStatus !== "Complete"
    ) {
      iconLeft.push({
        name: "redFlag",
        toField: "fullName",
        className: "text-[#8B0000]",
        onClick: null,
      });
    }
    const iconRight = [];
    return {
      ...data,
      tableInfo: {
        fullName: {
          text: fullName,
          isButton: true,
        },
        licenseCountry: {
          text: licenseCountry,
          className:
            driverLookupStatus !== "Incomplete" ? "text-green-600" : "",
        },
        licenseNumber: {
          text: `${
            licenseCountry === "USA" ? licenseState : ""
          } ${licenseNumber}`,
        },
        mxNMP: {
          text: mxNMP,
        },
        yoe: {
          text: yoe,
        },
      },
      iconLeft,
      iconRight,
    };
  });
  return {
    tableData,
    matchedSubcollections,
  };
};

export const filterDriverKeysToSearch = [
  "driverFirst",
  "driverLast",
  "licenseCountry",
  "licenseState",
  "licenseNumber",
  "mxNMP",
  "yoe",
];

export const multipleDriverTableColumn = [
  {
    name: "fullName",
    label: "Driver Name",
  },
  {
    name: "licenseCountry",
    label: "Country",
  },
  {
    name: "licenseNumber",
    label: "License",
  },
  {
    name: "mxNMP",
    label: "NMP",
  },
  {
    name: "yoe",
    label: "YOE",
  },
];

export const removeMultiplePowerUnitsTable = async (
  endorsement,
  subcollection,
  policyID
) => {
  const matchedSubcollections = await getMultipleMatchSubcollections(
    endorsement,
    subcollection,
    policyID
  );
  const tableData = matchedSubcollections.map((data) => {
    const {
      truckVIN,
      truckYear,
      policiesDetails
    } = data || {};
    const iconLeft = [];
    const iconRight = [];

    const truckACV = filterRightPoliciesDetails(policiesDetails, policyID)?.truckACV || 0
    const adjustedRate = filterRightPoliciesDetails(policiesDetails, policyID)?.adjustedRate || 0

    return {
      ...data,
      tableInfo: {
        truckVIN: {
          text: truckVIN,
          isButton: true,
          className: "text-sky-900 hover:text-[#072a48] mr-2",
        },
        truckYear: {
          text: truckYear,
        },
        truckModel: {
          text: filterRightPoliciesDetails(policiesDetails, policyID)?.truckModel,
        },
        truckRadius: {
          text: filterRightPoliciesDetails(policiesDetails, policyID)?.truckRadius,
        },
        truckClass: {
          text: filterRightPoliciesDetails(policiesDetails, policyID)?.truckClass,
        },
        truckACV: {
          text: `$${
            numberWithCommas(Number(truckACV)?.toFixed(2))
              ? numberWithCommas(Number(truckACV)?.toFixed(2))
              : "0.00"
          }`,
        },
        adjustedRate: {
          text: `$${
            numberWithCommas(adjustedRate?.toFixed(2))
              ? numberWithCommas(adjustedRate?.toFixed(2))
              : "0.00"
          }`,
        },
      },
      iconLeft,
      iconRight,
    };
  });
  return {
    tableData,
    matchedSubcollections,
  };
};

export const filterPowerUnitsKeysToSearch = [
  "truckVIN",
  "truckYear",
  "truckModel",
  "truckRadius",
  "truckClass",
  "rating",
  "truckACV",
];

export const multiplePowerUnitsTableColumn = [
  {
    name: "truckVIN",
    label: "VIN",
  },
  {
    name: "truckYear",
    label: "Year",
  },
  {
    name: "truckModel",
    label: "Model",
  },
  {
    name: "truckRadius",
    label: "Radius",
  },
  {
    name: "truckClass",
    label: "Class",
  },
  {
    name: "truckACV",
    label: "ACV",
  },
  {
    name: "adjustedRate",
    label: "Adj. Rate",
  },
];

export const removeMultipleTrailersTable = async (
  endorsement,
  subcollection,
  policyID
) => {
  const matchedSubcollections = await getMultipleMatchSubcollections(
    endorsement,
    subcollection,
    policyID
  );
  const tableData = matchedSubcollections?.map((data) => {
    const { trailerVIN, trailerYear, trailerMake, trailerACV } = data || {};
    const iconLeft = [];
    iconLeft.push({
      name: "copyToClipboard",
      toField: "trailerVIN",
      className: "text-sky-900 hover:text-[#072a48] mr-2",
      onClick: () => navigator.clipboard.writeText(trailerVIN),
    });
    const iconRight = [];
    return {
      ...data,
      tableInfo: {
        trailerVIN: {
          text: trailerVIN,
          isButton: true,
        },
        trailerYear: {
          text: trailerYear,
        },
        trailerMake: {
          text: trailerMake,
        },
        trailerACV: {
          text: trailerACV,
        },
      },
      iconLeft,
      iconRight,
    };
  });
  return {
    tableData,
    matchedSubcollections,
  };
};

export const filterTrailerKeysToSearch = [
  "trailerVIN",
  "trailerYear",
  "trailerMake",
  "trailerACV",
];

export const multipleTrailersTableColumn = [
  {
    name: "trailerVIN",
    label: "VIN",
  },
  {
    name: "trailerYear",
    label: "Year",
  },
  {
    name: "trailerMake",
    label: "Make",
  },
  {
    name: "trailerACV",
    label: "ACV",
  },
];

export const addWaiverOfSubrogationEndorsementDetails = (endorsement) => {
  const {
    policyNo,
    type,
    namedInsured,
    company,
    address,
    city,
    state,
    zip
  } = endorsement?.data || {};
  const details = []
  if (policyNo) {
    details.push({
      label: "Policy Number",
      data: `${policyNo}`,
    })
  }
  if (type) {
    details.push({
      label: "Type",
      data: `${type}`,
    })
  }
  if (namedInsured) {
    details.push({
      label: "Named Insured",
      data: `${namedInsured}`,
    })
  }
  if (company) {
    details.push({
      label: "Company",
      data: `${company}`,
    })
  }
  if (address) {
    details.push({
      label: "Address",
      data: `${address}`,
    })
  }
  if (city) {
    details.push({
      label: "City",
      data: `${city}`,
    })
  }
  if (state) {
    details.push({
      label: "State",
      data: `${state}`,
    })
  }
  if (zip) {
    details.push({
      label: "Zip",
      data: `${zip}`,
    })
  }
  return details
};

export const removeDriverEndorsementDetails = (endorsement) => {
  const { driverFirst, driverLast, licenseCountry, licenseNumber, mxNMP, yoe } =
    endorsement?.data || {};
  return [
    {
      label: "Driver Name",
      data: `${driverFirst} ${driverLast}`,
    },
    {
      label: "Country",
      data: `${licenseCountry}`,
    },
    {
      label: "License",
      data: `${licenseNumber}`,
    },
    {
      label: "NMP",
      data: `${mxNMP}`,
    },
    {
      label: "YOE",
      data: `${yoe}`,
    },
  ];
};

export const removePowerUnitEndorsementDetails = (endorsement) => {
  const {
    truckVIN,
    truckYear,
    truckMake,
    truckModel,
    truckWeight,
    truckClass
  } = endorsement?.data || {};
  return [
    {
      label: "VIN",
      data: truckVIN,
    },
    {
      label: "Year",
      data: truckYear,
    },
    {
      label: "Make",
      data: truckMake,
    },
    {
      label: "Model",
      data: truckModel,
    },
    {
      label: "Weight",
      data: truckWeight,
    },
    {
      label: "Class",
      data: truckClass,
    }
  ];
};

export const removeTrailerEndorsementDetails = (endorsement) => {
  const { trailerVIN, trailerYear, trailerMake, trailerModel, trailerACV } =
    endorsement?.data || {};
  return [
    {
      label: "VIN",
      data: trailerVIN,
    },
    {
      label: "Year",
      data: trailerYear,
    },
    {
      label: "Make",
      data: trailerMake,
    },
    {
      label: "Model",
      data: trailerModel,
    },
    {
      label: "ACV",
      data: trailerACV,
    },
  ];
};

export const filterEndorsementKeysToSearch = (insidePolicy = false) => {
  let filters = [
    "status",
    "namedInsured",
    "endoEffectiveDate",
    "type",
    "endorsementNumber",
  ];
  if (insidePolicy) {
    filters = filters.filter(col => col.name !== "namedInsured");
  }
  return filters
}

export const endorsementTableColumns = (insidePolicy = false, t) => {
  let cols = [
    {
      name: "status",
      label: t("endorsements.status"),
    },
    {
      name: "namedInsured",
      label: t("endorsements.insured"),
    },
    {
      name: "endoEffectiveDate",
      label: t("endorsements.effective"),
    },
    {
      name: "type",
      label: t("endorsements.endo-type"),
    },
    {
      name: "documentType",
      label: t("endorsements.type"),
    },
    {
      name: "endorsementNumber",
      label: t("endorsements.doc-id"),
    },
    {
      name: "action",
      label: t('plm.drivers.actions'),
    },
  ];
  if (insidePolicy) {
    cols = cols.filter(col => col.name !== "namedInsured");
  }
  return cols
}

function normalizeDate(dateObj) {
  if (!dateObj) return null;

  const seconds = dateObj.seconds !== undefined ? dateObj.seconds : dateObj._seconds;
  const nanoseconds = dateObj.nanoseconds !== undefined ? dateObj.nanoseconds : dateObj._nanoseconds;

  if (seconds !== undefined && nanoseconds !== undefined) {
    return new Date(seconds * 1000 + nanoseconds / 1000000);
  }

  return null;
}

export const endorsementDetailsTable = async (
  endorsements,
  insidePolicy = false,
  language
) => {
  const tableData = await Promise.all(endorsements.map(async (data) => {
    const {
      status,
      namedInsured,
      endoEffectiveDate,
      type,
      endorsementNumber,
      documentSource,
      data: info
    } = data || {};
    let infoData = info;
    if (
      (type?.includes('Add') || type?.includes('Remove')) &&
      Array.isArray(info) &&
      info?.[0]?.path
    ) {
      let subcollection = null;
      if (type?.includes("Driver")) {
        subcollection = "drivers";
      } else if (type?.includes("Power Unit")) {
        subcollection = "power-units";
      } else if (type?.includes("Trailer")) {
        subcollection = "trailers";
      }
      const promises = info?.map(async (id) => {
        const docRef = doc(
          db,
          "agencies",
          data?.agencyID,
          "insureds",
          data?.insuredID,
          subcollection,
          getLastId(id?.path)
        );
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          return {
            id: docSnap.id,
            ...docSnap.data(),
          };
        } else {
          console.log("No such document!");
          return null;
        }
      });
      infoData = await Promise.all(promises);
      data['data'] = infoData;
    }

    const iconLeft = [];
    const iconRight = [];
    const normalizedEndoEffectiveDate = normalizeDate(endoEffectiveDate);
    const parsedEffectiveDate =
      normalizedEndoEffectiveDate ? normalizedEndoEffectiveDate
        .toLocaleDateString("en-US", {
          month: "short",
          day: "2-digit",
          year: "numeric",
        }) : '';

    iconRight.push({
      icon: "download",
      action: true,
      toField: "action",
    });

    const tableInfo = {
      status: {
        text: handleFilterStatus(status, manageEndorsementOptions, language),
        isButton: true,
      },
      namedInsured: {
        text: namedInsured,
        isButton: true,
      },
      endoEffectiveDate: {
        text: parsedEffectiveDate,
        isButton: true,
      },
      type: {
        text: handleEndorsementTypeStatus(type, language),
        isButton: true,
      },
      documentSource: {
        text: handleDocumentTypeStatus(documentSource, language),
        isButton: true,
      },
      endorsementNumber: {
        text: endorsementNumber,
        isButton: true,
      }
    };

    if (insidePolicy) {
      delete tableInfo.namedInsured;
    }

    tableInfo['actions'] = {
      actions: [
        status === 'Approved' || status === 'Issued'
          ? 'download'
          : 'download-disabled'
      ]
    };

    return {
      ...data,
      tableInfo,
      iconLeft,
      iconRight,
    };
  }));

  return { tableData };
};