import React from "react";
import { Card, CardContent, Typography, Grid } from "@mui/material";
import camelCaseToTitleCase from "../../utils/camelCaseToTitleCase";
import { numberWithCommas } from "../../../utils/helpers";

const PolicyDetails = ({ data, userInfo }) => {
  const isAdminOrUnderwriter =
    userInfo.role === "Underwriter" || userInfo.role === "Admin";
  return (
    <Card className="mb-4">
      <CardContent>
        {isAdminOrUnderwriter && (
          <Grid container spacing={2}>
            {Object.entries(data).map(([key, value]) => (
              <Grid item xs={12} md={6} key={key}>
                <Typography color="textSecondary">
                  {camelCaseToTitleCase(key)}: {value}
                </Typography>
              </Grid>
            ))}
          </Grid>
        )}
        {!isAdminOrUnderwriter && (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography color="textSecondary">
                  Policy Number: {data.policyNumber}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography color="textSecondary">
                  Effective Date:{" "}
                  {new Date(data.inceptionDate).toString().slice(4, 15)}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography color="textSecondary">
                  Expiry Date: {data.expiryDate}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography color="textSecondary">
                  Deductible: ${numberWithCommas(parseInt(data.deductible))}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography color="textSecondary">
                  Limit Total: ${numberWithCommas(data.limitCombination)}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography color="textSecondary">
                  Limit Per Loss: ${numberWithCommas(data.limitLoss)}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography color="textSecondary">
                  Limit Per Unit: ${numberWithCommas(data.limitUnit)}
                </Typography>
              </Grid>
              {/* Add more grid items for other data properties */}
            </Grid>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default PolicyDetails;
