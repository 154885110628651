import React, { useState } from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

export default function ApprovalButtons({
  dissapearAccept = false,
  dissapearDecline = false,
  onApprove,
  onDecline,
  titleAccept = 'Accept',
  titleDecline = 'Decline',
  disabledAccept = false,
  disabledDecline = false,
  handleAsSubmit,
  manuallyTotalDeclineDisabled,
  forceLoadingAccept = false,
}) {
  const [loadingAccept, setLoadingAccept] = useState(false);
  const [loadingDecline, setLoadingDecline] = useState(false);

  const handleApprove = async (e) => {
    try {
      setLoadingAccept(true);
      await onApprove(e);
    } catch (error) {
      console.error(error)
    } finally {
      setLoadingAccept(false);
    }
  };

  const handleDecline = async (e) => {
    try {
      setLoadingDecline(true);
      await onDecline(e);
    } catch (error) {
      console.error(error)
    } finally {
      setLoadingDecline(false);
    }
  };

  return (
    <div className="flex my-3">
      {!dissapearAccept ?
        <Button
          type={handleAsSubmit ? "submit" : ""}
          variant="contained"
          fullWidth
          style={{ borderRadius: '0', backgroundColor: '#072a48', color: 'white' }}
          onClick={handleAsSubmit ? undefined : handleApprove}
          disabled={forceLoadingAccept || disabledAccept || loadingAccept || loadingDecline}
        >
          {loadingAccept || forceLoadingAccept ? <CircularProgress size={24} color="inherit" /> : titleAccept}
        </Button> : false
      }
      {!dissapearDecline ?
        <Button
          variant="contained"
          fullWidth
          style={{ borderRadius: '0', backgroundColor: '#8B0000', color: '#fff' }}
          onClick={handleDecline}
          disabled={
            typeof manuallyTotalDeclineDisabled === 'undefined' ?
            (disabledDecline || loadingAccept || loadingDecline) :
            manuallyTotalDeclineDisabled
          }
        >
          {loadingDecline ? <CircularProgress size={24} color="inherit" /> : titleDecline}
        </Button> : null
      }
    </div>
  );
}