import React from "react";
import updatePolicyField from "../../../utils/updatePolicyField";

export default function SelectDropdown({
  policyID,
  formData,
  options,
  fieldName,
  label,
  customClass,
  index,
}) {
  const optionElements = options.map((option) => (
    <option key={option.value} value={option.value}>
      {option.label}
    </option>
  ));

  const value = formData.OtherLossRuns?.[index]?.[fieldName] ?? '';

  return (
    <>
      <select
        onChange={(e) => {
          const existingArray = formData?.OtherLossRuns || [];
          const newData = {
            ...existingArray[index],
            [fieldName]: e.target.value,
          };
          existingArray[index] = newData;
          updatePolicyField(policyID, "OtherLossRuns", existingArray);
        }}
        className={`${
          customClass
            ? customClass
            : `appearance-none block w-full bg-white border border-gray-300 rounded-md py-1 px-1 leading-tight focus:outline-none focus:shadow-outline-blue focus:border-blue-300`
        }`}
        value={value}
      >
        <option value="">Select</option>
        {optionElements}
      </select>
    </>
  );
}
