import React from "react";
import {
  Upload as UploadIcon
} from "@mui/icons-material";
import { Dropdown, DropdownButton } from "react-bootstrap";
import MultiUpload from "./MultiUpload";
import { doc, setDoc, writeBatch } from "firebase/firestore";
import { db } from "../../../../firebase";
import BlueButton from "../BlueButton";
import { useMediaQuery } from "@mui/material";
import useGeneralState from "../../../../Context/actions/useGeneralState";
import useTranslations from "../../../../Context/actions/useTranslations";
import { useLocation } from "react-router-dom";
import './styles/index.css'
import ApiService from "../../../../services/southern-api/apiService";

export default function DropdownMultiUpload({
  wrapperStyle,
  iconStyle,
  containerStyle,
  handleDropdown,
  formData,
  item,
  icon,
  disabled,
  list,
  dropdownType
}) {
  const dropdownItems = handleDropdown();
  const { agencyID, insuredID } = formData;
  const { controlToast } = useGeneralState()
  const { t } = useTranslations()

  const location = useLocation()
  const isTemporalUrl = location?.pathname?.includes('temporarily')
  const apiService = new ApiService();

  const handleSubmit = async (name, value, multiple) => {
    try {
      const payload = {
        [name]:
          multiple ? value :
          value[0] ? value[0] : "",
      };
      if (!isTemporalUrl) {
        await setDoc(
          doc(
            db,
            "agencies",
            agencyID,
            "insureds",
            insuredID,
            "drivers",
            item?.id
          ),
          payload,
          { merge: true }
        );
      } else {
        await apiService.renewalProcessUpdateDriver({
          agencyID,
          insuredID,
          driverID: item?.id,
          payload,
        })
      }
    } catch (error) {
      console.error("Error adding document: ", error);
      throw error;
    }
  };

  const handleBulking = async (name, value, multiple, results) => {
    try {
      const batch = writeBatch(db);
      for (const result of results) {
        const { matchElem } = result;
        const docRef = doc(
          db,
          "agencies",
          agencyID,
          "insureds",
          insuredID,
          "drivers",
          matchElem?.id
        );
        const payload = {
          [name]:
            name === "caDriverAbstract" ? value[0] :
            name === "driverLicenseFront" ? value[0] :
            name === "driverLicenseBack" ? value[0] :
            name === "MVR" ? value[0] :
            name === "medicalLongForm" ? value[0] :
            name === "additionalDocument" ? value :
            name === "otherDocuments" ? value : ''
        };
        batch.set(docRef, payload, { merge: true });
      }
      await batch.commit()
      controlToast(true, "Upload successful", "success")
    } catch (error) {
      console.error("Error adding document: ", error);
      throw error;
    }
  };

  const isMobileScreen = useMediaQuery("(max-width: 600px)");

  return (
    <DropdownButton
      variant=""
      drop="down"
      disabled={disabled}
      className={containerStyle}
      style={{ padding: 0 }}
      title={
        <div className={wrapperStyle}>
          {icon === 'import' ?
            <BlueButton
              text={t('plm.drivers.import')}
              hasContentToBottom={true}
              hasContentToRight={!isMobileScreen}
              className={isMobileScreen ? "w-[100%]" : ""}
            />
            :
            <UploadIcon className={iconStyle} />
          }
        </div>
      }
    >
      {dropdownItems.map((dropdown, index) => {
        const {
          label,
          name,
          multiple,
          notAccesible,
          type,
          onClick,
          valueToCompare
        } = dropdown;
        if (type === 'file') {
          return (
            <div key={index}>
              <MultiUpload
                label={label}
                property={name}
                formData={[]}
                setFormData={(value, results) => {
                  if (dropdownType === "import") {
                    handleBulking(name, value, multiple, results)
                  } else {
                    handleSubmit(name, value, multiple);
                  }
                }}
                storageFile={`files/drivers/${item?.licenseNumber}`}
                multiple={multiple}
                changeNormal={true}
                fileInButton={true}
                width="100%"
                dissapearWhenImgExists={true}
                showInAPopup={true}
                showPastingOption={true}
                dropdownStyleType={true}
                notClickable={notAccesible}
                valueToCompare={valueToCompare}
                list={list}
              />
            </div>
          );
        }
        if (type === 'normal-item') {
          return (
            <Dropdown.Item key={index} onClick={onClick}>
              {label}
            </Dropdown.Item>
          );
        }
        return null
      })}
    </DropdownButton>
  );
}
