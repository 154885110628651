import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
  
  const getTaxSchedule = async (setTaxSchedule) => {
    if (setTaxSchedule) setTaxSchedule({});
    const docRef = doc(db, "taxes-and-fees", "stateTaxes");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      if (setTaxSchedule) setTaxSchedule(docSnap.data());
      return docSnap.data();
    } else {
      // docSnap.data() will be undefined in this case
      console.log("No such document!");
    }
  };

  export default getTaxSchedule;