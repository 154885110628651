import { IconButton, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import useTranslations from "../../../Context/actions/useTranslations";
import usePreviousRoute from "../../../hooks/history/usePreviousRoute";
import { useNavigate } from "react-router-dom";

const GoBack = ({
  throughHistory,
  defaultRoute,
  handleFn,
  label,
  disabled = false,
  className,
}) => {
  const previousRoute = usePreviousRoute();
  const { t } = useTranslations();
  const navigate = useNavigate();

  const handleNavigation = (e) => {
    if (handleFn) {
      handleFn(e);
    }
    if (throughHistory) {
      navigate(defaultRoute ? defaultRoute : previousRoute);
    }
  };

  return (
    <IconButton
      disabled={disabled}
      onClick={handleNavigation}
      className={`${className ? className : ''} mb-1`}
      style={{ color: "#8B0000" }}  // Set the color
    >
      <ArrowBackIcon /> {/* Replaces the "<" with an MUI icon */}
      <Typography
        variant="body2"  // Adjust typography size and style
        style={{ marginLeft: 4, fontWeight: 500 }}  // Add spacing and boldness for style
      >
        {label ? label : t("go-back.back")}
      </Typography>
    </IconButton>
  );
};

export default GoBack;
