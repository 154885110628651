import { collection, getDocs, limit, query, where } from "firebase/firestore";
import getViolationsQuery from "./getData/getViolationsQuery";
import mapClassifications from "./getData/mapClassifications";
import mapEventualities from "./getData/mapEventualities";
import performJoins from "./getData/performJoins";
import processQueryInChunks from "./getData/processQueryInChunks";
import searchByLicense from "./getData/searchByLicense";
import searchByName from "./getData/searchByName";
import searchByPolicy from "./getData/searchByPolicy";
import aggregateDriverInfo from "./listeners/aggregateDriverInfo";
import { db } from "../../../firebase";

const fetchViolations = async (
  e,
  searchType,
  searchValue,
  selectedInsured,
  selectedPolicy,
  setAggregatedDriverInfo,
  setDataReady,
  setCrashes,
  setInspections
) => {
  e.preventDefault();
  try {
    // Step 1: Fetch drivers based on searchType
    let driversMap;
    if (searchType === "license") {
      driversMap = await searchByLicense(searchValue);
    } else if (searchType === "name") {
      driversMap = await searchByName(searchValue);
    } else if (searchType === "policy") {
      driversMap = await searchByPolicy(
        searchValue,
        selectedInsured,
        selectedPolicy
      );
    }

    const licenseArray = Object.values(driversMap).map(
      (driver) => driver.licenseNumber
    );

    // Step 2: Fetch violations
    const violationsQuerySnapshot =
      searchType === "policy"
        ? null
        : await getViolationsQuery(searchType, searchValue, licenseArray);

    const violationsQuery =
      searchType === "license"
        ? violationsQuerySnapshot.docs
        : searchType === "policy" || searchType === "name"
        ? await processQueryInChunks(licenseArray)
        : null;

    // Step 3: Fetch eventualities and classifications
    const eventualitiesMap = await mapEventualities();
    const classificationsMap = await mapClassifications();

    // Perform joins
    const fullyJoinedViolations = await performJoins(
      violationsQuerySnapshot,
      eventualitiesMap,
      classificationsMap,
      searchType === "policy" || searchType === "name" ? true : false,
      violationsQuery
    );

    // Step 4: Fetch crashes
    const crashesRef = collection(db, "loss-control", "driverData", "crashes");
    const crashesQuerySnapshot = query(
      crashesRef,
      where("drvLicNbr", "==", searchValue),
      limit(10)
    );
    const crashesData = await getDocs(crashesQuerySnapshot);
    const crashes = crashesData.docs.map((doc) => doc.data());
    console.log("crashes", crashesData.docs.map((doc) => doc.data()));

    // Step 5: Fetch inspections
    const inspectionsRef = collection(
      db,
      "loss-control",
      "driverData",
      "inspections"
    );
    const inspectionsQuerySnapshot = query(
      inspectionsRef,
      where("drvLicNbr", "==", searchValue),
      limit(10)
    );
    const inspectionsData = await getDocs(inspectionsQuerySnapshot);
    const inspections = inspectionsData.docs.map((doc) => doc.data());
    console.log("inspections", inspectionsData.docs.map((doc) => doc.data()));

    // Step 6: Aggregate the data, passing crashes along with violations and drivers
    const aggregatedData = await aggregateDriverInfo(
      fullyJoinedViolations,
      driversMap,
      crashes,
      setDataReady,
      inspections
    );

    setAggregatedDriverInfo(aggregatedData);
  } catch (error) {
    console.error("Error fetching violations:", error);
  }
};

export default fetchViolations;
