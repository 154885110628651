import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../../firebase";
import getAge from "../../../utils/getAge";

const scoreNotIncluded = async (numberOfViolations) => {
    if (!numberOfViolations) return 0;
    
    // Get Claim Matrix
    let matrix = {};
    const querySnapshot = await getDocs(collection(db, "loss-control", "tables", "notIncluded"));
    querySnapshot.forEach((doc) => {
        const data = doc.data();
      matrix = {
        ...matrix,
      [data.numberOfViolations]: data.score
      }
    });

    // Get Score
    function getScore(number) {
        const keys = Object.keys(matrix).map(Number).sort((a, b) => a - b);
        let value;
        if (number === 0) return 0;
        keys.some(key => {
            if (number <= key) {
                value = matrix[key];
                return true;
            }
            return false;
        });
    
        return value !== undefined ? value : matrix[keys[keys.length - 1]];
    }
    const returnValue = getScore(numberOfViolations)

    return returnValue;
}

export default scoreNotIncluded;