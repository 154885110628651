import { doc, getDoc, updateDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Button, Grid, TextField } from "@mui/material";
import { db } from "../../../../firebase";
import useTranslations from "../../../../Context/actions/useTranslations";
import EditIcon from "@mui/icons-material/Edit";

export default function CompanyProfile(props) {
  const { t } = useTranslations();
  const [insuredInfo, setInsuredInfo] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [editedValues, setEditedValues] = useState({});
  const [gotInfo, setGotInfo] = useState(false);
  const [noInfo, setNoInfo] = useState(false);

  const getCompanyInfo = async () => {
    setGotInfo(false);
    const docRef = doc(
      db,
      "agencies",
      props.agencyID,
      "insureds",
      props.insuredID
    );
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setInsuredInfo({ ...docSnap.data(), id: docSnap.id });
      setEditedValues({ ...docSnap.data(), id: docSnap.id });
      setGotInfo(true);
      setNoInfo(false);
    } else {
      setNoInfo(true);
    }
  };

  const handleEdit = () => {
    setEditMode(!editMode);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedValues({ ...editedValues, [name]: value });
  };

  const handleSave = async () => {
    try {
      await updateDoc(
        doc(db, "agencies", props.agencyID, "insureds", props.insuredID),
        editedValues
      );
      setInsuredInfo(editedValues);
      setEditMode(false);
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  };

  useEffect(() => {
    !gotInfo && getCompanyInfo();
    gotInfo && props.setSelectedCompanyInfo(insuredInfo);
  }, [gotInfo]);

  return (
    <main>
      {editMode ? (
        <div className="my-3">
          <TextField
            className="mb-3"
            name="company"
            label="Company"
            value={editedValues.company || ""}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            className="mb-3"
            name="address"
            label="Address"
            value={editedValues.address || ""}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            className="mb-3"
            name="city"
            label="City"
            value={editedValues.city || ""}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            className="mb-3"
            name="state"
            label="State"
            value={editedValues.state || ""}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            className="mb-3"
            name="zip"
            label="ZIP"
            value={editedValues.zip || ""}
            onChange={handleInputChange}
            fullWidth
          />
          <Button onClick={handleSave} variant="contained" color="primary">
            Save
          </Button>
          
        </div>
      ) : (
        <>
          Please verify the mailing address:
          <div className="ml-5 my-2">
            <h2
              title={insuredInfo.id}
              className="text-xl font-bold text-[#072a48]"
            >
              {insuredInfo.company}
            </h2>
            <h3 className="text-lg text-[#072a48]">{insuredInfo.address}</h3>
            <h3 className="text-lg text-[#072a48]">
              {insuredInfo.city}, {insuredInfo.state} {insuredInfo.zip}
            </h3>
          </div>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                onClick={handleEdit}
                startIcon={<EditIcon />}
              >
                Edit Mailing Address
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </main>
  );
}
