import React from "react";
import SearchFilters from "../../../SearchFilters";

export default function ListFilters({
  currentFilters,
  setCurrentFilters,
  options
}) {

  return (
    <SearchFilters
      currentFilters={currentFilters}
      setCurrentFilters={setCurrentFilters}
      options={options}
    />
  );
}
