const styles = {
  wrapper: (isSmallVersion) => `max-w-[700px] mx-auto ${isSmallVersion ? 'mt-8' : 'my-16'} p-4`,
  title: "text-center text-3xl font-bold",
  inputWrapper: "flex flex-col py-2",
  inputLabel: "py-2 font-medium",
  input: (isSmallVersion) => `border ${isSmallVersion ? 'h-[40px] p-[5px]' : 'p-3'} w-[100%]`,
  button: (disabled, loading) =>
    `bg-[#072a48] rounded w-full p-4 my-2 text-white flex justify-center items-center
    ${
      disabled || loading
        ? "opacity-50 cursor-not-allowed"
        : "hover:bg-blue-700"
    }`,
  spinnerContainer: "ml-4",
  spinner:
    "animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-blue-500",
};

export default styles;
