import React, { useState } from "react";
import CreateUserPanel from "../../Components/agent/createUsers/CreateUserPanel";
import UserList from "../../Components/agent/createUsers/UserList";
import Breadcrumbs from "../../Components/assets/Breadcrumbs";
import { ACCOUNT_ROUTE } from "../../utils/routes";
import useTranslations from "../../Context/actions/useTranslations";
import Popup from "../../Components/generals/Popup";
import ApiService from "../../services/southern-api/apiService";
import RedButton from "../../Components/assets/forms/RedButton";
import useGeneralState from "../../Context/actions/useGeneralState";

export default function ManageUsers(props) {
  const [showCreateUserPanel, setShowCreateUserPanel] = useState(false);
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const handleCloseDeleteUserModal = () => {
    setShowDeleteUserModal(false);
    setSelectedUser(null);
  }

  const userInfo = props.userInfo;
  const [userList, setUserList] = useState([]);

  const { t } = useTranslations();
  const { controlToast } = useGeneralState()

  const breadcrumbs = [
    { label: t("breadcrumbs.home"), link: ACCOUNT_ROUTE },
    { label: t("breadcrumbs.manage-users") },
  ];

  const userCanModify = userInfo.userPermissions === true;
  const permissions = userInfo.userPermissions;
  const userIsOwner = userInfo.companyOwner;

  const apiService = new ApiService();

  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await apiService.deleteUserWithFlag(selectedUser.email);
      controlToast(true, `${selectedUser.first} ${selectedUser.last} was deleted successfully`, "success");
      handleCloseDeleteUserModal();
    } catch (error) {
      console.error(error);
      controlToast(true, "Error deleting user", "error");
    } finally {
      setLoading(false);
    }
  }

  const handleOpenDeleteUserModal = (e, user) => {
    e.preventDefault();
    setSelectedUser(user);
    setShowDeleteUserModal(true);
  }

  return (
    <>
      <Popup
        maxWidth='50%'
        isOpen={showDeleteUserModal}
        onRequestClose={handleCloseDeleteUserModal}
      >
        <h2 className="text-xl font-bold mt-2 mb-4">
          You are about to delete the user "{selectedUser?.first} {selectedUser?.last}".
          To confirm, click the button below.
        </h2>
        <div>
          <RedButton
            text={loading ? `Deleting User` : `Delete User`}
            onClickFunction={handleSubmit} 
            className="w-[100%]"
            disabled={loading}
          />
        </div>
      </Popup>
      <div className="max-w-[1000px] mx-auto bg-white rounded-md shadow-md p-6 my-4">
        <Breadcrumbs items={breadcrumbs} />
        <div className="flex flex-col md:flex-row md:items-center md:justify-between mt-1">
          <span className="text-3xl font-semibold mb-4 md:mb-0">
            {t("agent.manage-users")}
          </span>
          {!showCreateUserPanel && permissions && (userCanModify || userIsOwner) && (
            <button
              type="button"
              className="md:ml-auto md:mt-2 sm:mt-2 bg-[#072a48] hover:bg-[#072a48] text-white py-2 px-4 mb-4 rounded"
              onClick={() => setShowCreateUserPanel(true)}
            >
              {t("agent.add-user")}
            </button>
          )}
        </div>
        <p className="mb-4">{t("agent.clients-policies")}</p>
        {!showCreateUserPanel && (
          <UserList
            userList={userList}
            setUserList={setUserList}
            userInfo={userInfo}
            handleOpenDeleteUserModal={handleOpenDeleteUserModal}
          />
        )}
        {showCreateUserPanel && (
          <CreateUserPanel
            setShowCreateUserPanel={setShowCreateUserPanel}
            userInfo={userInfo}
          />
        )}
      </div>
    </>
  );
}
