import Navbar from '../../Components/Navbar/'

function MainWrapper({ children, userInfo }) {
  return (
    <div className="min-h-screen bg-gradient-to-r from-slate-200 to-slate-400">
      <Navbar userInfo={userInfo} />
      <div className="md:px-3 sm:px-1 pb-5">
        {children}
      </div>
    </div>
  );
}

export default MainWrapper;
