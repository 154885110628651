import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import DriverDetails from "../../../PolicyLifecycleManager/Drivers/DriverDetails";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../../firebase";

export default function DriverDetailsModal({
  driverModalIsOpen,
  closeDriverModal,
  driver,
  setDriverModalIsOpen,
  userInfo,
}) {
  const driverID = driver.id;
  const policyID = driver.policiesDetails[0].id;
  const [gotData, setGotData] = useState(false);
  const [formData, setFormData] = useState({});
  const getFormData = async () => {
    console.log(policyID);
    const formDataRef = doc(db, "policy-applications", policyID);
    const formData = await getDoc(formDataRef);
    const document = formData.data();
    setFormData(document);
    console.log(document);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (driverModalIsOpen) {
        await getFormData();
        setGotData(true);
      }
    };

    fetchData();
  }, [policyID, driverModalIsOpen]);

  return (
    <div>
      <Modal
        isOpen={driverModalIsOpen}
        onRequestClose={closeDriverModal}
        style={{ overlay: { zIndex: 1000 }, content: { zIndex: 1000 } }}
      >
        {!gotData ? (
          <h1>Loading...</h1>
        ) : (
          <>
            {driverModalIsOpen && (
              <DriverDetails
                selectedDriver={driverID}
                setShowDriverDetails={setDriverModalIsOpen}
                policyID={policyID}
                setShowTable={() => {
                  return null;
                }}
                userInfo={userInfo}
                formData={formData}
              />
            )}
          </>
        )}
      </Modal>
    </div>
  );
}
