import React, { useState } from "react";
import { t } from "i18next";
import TextInput from "../../../assets/forms/TextInput";
import NumberInput from "../../../assets/forms/NumberInput";

const LossDataForm = ({
  handleSubmit,
  currentYear,
  numberOfYearsPrior,
  formData,
  setFormData,
  year,
  coverageType,
  policyID,
}) => {
  const initialState =
    formData?.lossHistory?.[`year${year + "" + coverageType}LossData`] || {};
  const [lossData, setLossData] = useState(initialState);
  const section = "lossHistory";
  const category = `year${year + "" + coverageType}LossData`;

  return (
    <form
      onSubmit={(e) => {
        handleSubmit(
          e,
          currentYear -
            numberOfYearsPrior -
            1 +
            " " +
            t("to") +
            " " +
            (currentYear - numberOfYearsPrior),
          lossData,
          `year${year + "" + coverageType}LossData`
        );
      }}
    >
      <fieldset disabled={formData.documentType !== "Application"}>
        <TextInput
          label={t("Carrier") + ":"}
          required={true}
          saveForm={() => {}}
          property={"carrier"}
          setFormData={setLossData}
          formData={lossData}
          setFormSaved={() => {}}
          policyID={policyID}
          debounceLosses={{
            section,
            category,
          }}
        />
        <NumberInput
          label={t("Number of Units") + ":"}
          required={true}
          saveForm={() => {}}
          property="numberOfUnits"
          setFormData={setLossData}
          formData={lossData}
          setFormSaved={() => {}}
          policyID={policyID}
          debounceLosses={{
            section,
            category,
          }}
        />
        <NumberInput
          label={t("Total Insured Value") + ":"}
          required={true}
          saveForm={() => {}}
          property="tiv"
          setFormData={setLossData}
          formData={lossData}
          setFormSaved={() => {}}
          policyID={policyID}
          debounceLosses={{
            section,
            category,
          }}
        />
        <NumberInput
          label={t("Deductible") + ":"}
          required={true}
          saveForm={() => {}}
          property="deductible"
          setFormData={setLossData}
          formData={lossData}
          setFormSaved={() => {}}
          policyID={policyID}
          debounceLosses={{
            section,
            category,
          }}
        />
        <NumberInput
          label={t("Mileage") + ":"}
          required={true}
          saveForm={() => {}}
          property="mileage"
          setFormData={setLossData}
          formData={lossData}
          setFormSaved={() => {}}
          policyID={policyID}
          debounceLosses={{
            section,
            category,
          }}
        />
        <NumberInput
          label={t("Losses") + ":"}
          required={true}
          saveForm={() => {}}
          property="losses"
          setFormData={setLossData}
          formData={lossData}
          setFormSaved={() => {}}
          policyID={policyID}
          debounceLosses={{
            section,
            category,
          }}
        />
        <NumberInput
          label={t("Number of Claims") + ":"}
          required={true}
          saveForm={() => {}}
          property="howManyClaims"
          setFormData={setLossData}
          formData={lossData}
          setFormSaved={() => {}}
          policyID={policyID}
          debounceLosses={{
            section,
            category,
          }}
        />
        <button className="submit-button" type="submit">
          {t("Calculate Loss Ratio")}
        </button>
      </fieldset>
    </form>
  );
};

export default LossDataForm;
