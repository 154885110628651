import React from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  Typography
} from '@mui/material';

const Inspections = ({ inspections }) => {
  return (
    <TableContainer component={Paper}>
      <Typography variant="h6" align="center" sx={{ margin: 2 }}>
        Vehicle Inspections
      </Typography>
      <Table aria-label="vehicle inspections table">
        <TableHead>
          <TableRow>
            <TableCell>Driver Name</TableCell>
            <TableCell>Driver License Number</TableCell>
            <TableCell>Driver License State</TableCell>
            <TableCell>Driver DOB</TableCell>
            <TableCell>Co-Driver Name</TableCell>
            <TableCell>Inspection Date</TableCell>
            <TableCell>Inspection ID</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Violation Code</TableCell>
            <TableCell>Violation Group</TableCell>
            <TableCell>Out of Service</TableCell>
            <TableCell>BASIC</TableCell>
            <TableCell>BASIC Violations Per Inspection</TableCell>
            <TableCell>Vehicle 1 License Number</TableCell>
            <TableCell>Vehicle 1 License State</TableCell>
            <TableCell>Vehicle 1 VIN</TableCell>
            <TableCell>Vehicle 1 Make</TableCell>
            <TableCell>Vehicle 1 Type</TableCell>
            <TableCell>Vehicle 2 License Number</TableCell>
            <TableCell>Vehicle 2 License State</TableCell>
            <TableCell>Vehicle 2 VIN</TableCell>
            <TableCell>Vehicle 2 Make</TableCell>
            <TableCell>Vehicle 2 Type</TableCell>
            <TableCell>Violation Severity Weight</TableCell>
            <TableCell>Upload Date</TableCell>
            <TableCell>State</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(inspections).map(([id, inspection]) => (
            <TableRow key={id}>
              <TableCell>{`${inspection.drvFirstName} ${inspection.drvLastName}`}</TableCell>
              <TableCell>{inspection.drvLicNbr}</TableCell>
              <TableCell>{inspection.drvLicSt}</TableCell>
              <TableCell>{inspection.drvDOB}</TableCell>
              <TableCell>{`${inspection.coDrvFirstName} ${inspection.coDrvLastName}`}</TableCell>
              <TableCell>
                {inspection.inspectionDate && inspection.inspectionDate.__time__
                  ? new Date(inspection.inspectionDate.__time__).toLocaleDateString()
                  : 'N/A'}
              </TableCell>
              <TableCell>{inspection.inspectionID}</TableCell>
              <TableCell>{inspection.description}</TableCell>
              <TableCell>{inspection.code}</TableCell>
              <TableCell>{inspection.violationGroupDescription}</TableCell>
              <TableCell>{inspection.outOfService}</TableCell>
              <TableCell>{inspection.bASIC}</TableCell>
              <TableCell>{inspection.bASICViolationsPerInspection}</TableCell>
              <TableCell>{inspection.vehUnit1LicenseNumber}</TableCell>
              <TableCell>{inspection.vehUnit1LicenseState}</TableCell>
              <TableCell>{inspection.vehUnit1VIN}</TableCell>
              <TableCell>{inspection.vehUnit1Make}</TableCell>
              <TableCell>{inspection.vehUnit1Type}</TableCell>
              <TableCell>{inspection.vehUnit2LicenseNumber}</TableCell>
              <TableCell>{inspection.vehUnit2LicenseState}</TableCell>
              <TableCell>{inspection.vehUnit2VIN}</TableCell>
              <TableCell>{inspection.vehUnit2Make}</TableCell>
              <TableCell>{inspection.vehUnit2Type}</TableCell>
              <TableCell>{inspection.violationSeverityWeight}</TableCell>
              <TableCell>
                {inspection.uploadDate && inspection.uploadDate.__time__
                  ? new Date(inspection.uploadDate.__time__).toLocaleDateString()
                  : 'N/A'}
              </TableCell>
              <TableCell>{inspection.state}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Inspections;
