import { collection, deleteDoc, doc, onSnapshot, query, where } from "firebase/firestore";
import React, { useEffect, useMemo, useState } from "react";
import { db } from "../../../firebase";
import useTranslations from "../../../Context/actions/useTranslations";
import { isExpired } from "../../../utils/helpers";
import { individualToEndorsement } from "./individualToEndorsement.";
import useGeneralState from "../../../Context/actions/useGeneralState";
import useEndorsementsState from "../../../Context/actions/useEndorsementsState";
import { convertISOToFirestoreFormat } from "../../../utils/helpers";
import { clearList } from "./deleteMultiple";
import { buildShowingInfo, filterRightPoliciesDetails } from "./setPolicyInfo";
import { selectIfDeleteOrExistingSubcollection } from "../../utils/validateIfBlacklisted";
import { handleSubcollectionStatus } from "../../../utils/constants";
import { activeFilters, manageDriverOptions } from "../../../utils/searchFilters";
import ApiService from "../../../services/southern-api/apiService";
import { UserAuth } from "../../../Context/AuthContent";

const useDriversList = (props) => {
  const {
    formData,
    driverList,
    setSelectedDriver,
    setShowDriverDetails,
    insuredInfo,
    userInfo,
    getEndorsements,
    setShowTable,
    policyID,
    isExclusionPanel,
    setShowDriverExclusions
  } = props;

  const apiService = new ApiService('southern')
  const { user: auth } = UserAuth()
  const { t, language } = useTranslations();
  const [tableData, setTableData] = useState([]);
  const [tableDataInDeletionMode, setTableDataInDeletionMode] = useState([]);
  const [tableDataShowingDeletion, setTableDataShowingDeletion] = useState([]);
  const { controlToast } = useGeneralState();
  const { endorsements } = useEndorsementsState();
  const [modalValues, setModalValues] = useState(null);
  const [deletionMode, setDeletionMode] = useState(
    isExclusionPanel ? true : false
  );
  const [openModal, setOpenModal] = useState(false);
  const [modalSubmitting, setModalSubmitting] = useState(false);
  const [deletionType, setDeletionType] = useState(false);

  const isAtLeastThreeSelectedInDeletedMode = useMemo(() => {
    const selectedData = tableDataInDeletionMode.filter((data) => {
      return Boolean(data?.tableInfo?.actions?.actions?.[0]?.checkbox);
    });
    return Boolean(selectedData.length >= 3 && deletionMode);
  }, [tableDataInDeletionMode, deletionMode]);


  const isAtLeastOneSelectedInDeletedMode = useMemo(() => {
    return tableDataInDeletionMode.some((data) => {
      return Boolean(data?.tableInfo?.actions?.actions?.[0]?.checkbox);
    }) && deletionMode;
  }, [tableDataInDeletionMode, deletionMode]);

  const clearDriverList = async (
    e, values, button,
    policyIDToUse,
    documentTypeToUse,
    renewalProcess
  ) => {
    if (e) e?.preventDefault();
    const effectiveDate = values["effective-date"];
    const deletionReason = values["deletion-reason"];
    const blacklistExpirationDate = values["blacklist-expiration-date"];
    const emailToProvideInExclusion = values?.["email"];
    const typeOfDeletion =
      deletionType === "exclusion" ? "Exclude Drivers" : "Remove Drivers";
    setModalSubmitting(true)
    try {
      const verify = await clearList(
        formData,
        controlToast,
        policyIDToUse,
        driverList,
        tableDataInDeletionMode,
        insuredInfo,
        userInfo,
        getEndorsements,
        endorsements,
        typeOfDeletion,
        "drivers",
        effectiveDate,
        deletionReason,
        null,
        button,
        documentTypeToUse,
        renewalProcess,
        blacklistExpirationDate,
        emailToProvideInExclusion
      );
      if (!verify && button === 'massive') {
        setModalSubmitting(false)
        return 'verify is null'
      }
      setModalSubmitting(false)
      handleCloseModal()
      setTableDataInDeletionMode([])
      setTableDataShowingDeletion([])
    } catch (error) {
      setModalSubmitting(false)
      throw error;
    }
  };

  const deleteThisDriver = async (
    e, driver, _,
    policyIDToUse,
    documentTypeToUse,
    renewalProcess
  ) => {
    if (e) e?.preventDefault();
    setModalSubmitting(true);
    try {
      // let verify
      // if (documentTypeToUse === "Application" && !renewalProcess) {
      //   verify = prompt(t("plm.drivers.delete-driver"));
      // }
      if (
        renewalProcess ||
        documentTypeToUse === "Policy"
      ) {
        const payload = {
          ...modalValues,
          ...driver,
        };
        delete payload["iconLeft"];
        delete payload["iconRight"];
        delete payload["tableInfo"];
        delete payload["dataIndex"];
        let effectiveDate
        let blacklistExpirationDate
        if (driver?.["effective-date"]) {
          effectiveDate = convertISOToFirestoreFormat(
            driver["effective-date"]
          );
          delete payload["effective-date"];
        }
        if (driver?.["blacklist-expiration-date"]) {
          blacklistExpirationDate = convertISOToFirestoreFormat(
            driver["blacklist-expiration-date"]
          );
          delete payload["blacklist-expiration-date"];
        }
  
        const { agencyID, insuredID } = formData
        const payloadResult = await selectIfDeleteOrExistingSubcollection(
          payload,
          agencyID,
          insuredID,
          'drivers',
          policyIDToUse,
          renewalProcess,
          documentTypeToUse,
          formData,
          deletionType === "exclusion" ? "exclusion" : "deletion"
        )
        
        if (documentTypeToUse === "Policy" || renewalProcess) {
          if (driver?.["effective-date"]) {
            const typeOfDeletion =
              deletionType === "exclusion" ? "Exclude Driver" : "Remove Driver";

            await individualToEndorsement(
              policyIDToUse,
              formData.effectiveDate,
              typeOfDeletion,
              payloadResult,
              props.insuredInfo,
              formData,
              props.userInfo,
              props.isEndoManager,
              props.getEndorsements,
              endorsements,
              blacklistExpirationDate
            );
          }
          controlToast(
            true,
            "The endorsement was succesfully created",
            "success"
          );
        }
        setModalSubmitting(false);
        setOpenModal(false);
        setModalValues(null);
      }
    } catch (error) {
      controlToast(true, "There was an error in the service", "error");
      setModalSubmitting(false);
      throw error;
    }
  };

  const detectingIndividualOrMultiple = async (
    e,
    data,
    button,
    policyIDToUse,
    documentTypeToUse,
    renewalProcess
  ) => {
    try {
      if (
        (modalValues === "multiple" || data === "multiple") &&
        deletionType === "exclusion"
      ) {
        return await clearDriverList(
          e,
          data,
          null,
          policyIDToUse,
          documentTypeToUse,
          renewalProcess
        );
      } else if (deletionType === "exclusion") {
        return await deleteThisDriver(
          e,
          data,
          "exclusion",
          policyIDToUse,
          documentTypeToUse,
          renewalProcess
        );
      } else if (modalValues === "multiple" || data === "multiple") {
        return await clearDriverList(
          e,
          data,
          button,
          policyIDToUse,
          documentTypeToUse,
          renewalProcess
        );
      } else {
        return await deleteThisDriver(
          e,
          data,
          null,
          policyIDToUse,
          documentTypeToUse,
          renewalProcess
        );
      }
    } catch (error) {
      throw error;
    }
  };

  const mirrorLogic = async (e, data, button) => {
    const isRenewalApplication =
      formData.status === "In-Renewal" &&
      formData.documentType === "Application";
    const isRenewalPolicy =
      formData.status === "In-Renewal" &&
      formData.documentType === "Policy";
    const notARenewalProcess = formData.status !== "In-Renewal";
    try {
      if (notARenewalProcess) {
        await detectingIndividualOrMultiple(
          e, data, button,
          policyID,
          formData.documentType,
          false
        );
      }
      if (isRenewalApplication) {
        await detectingIndividualOrMultiple(
          e, data, button,
          policyID,
          "Application",
          true
        );
        await detectingIndividualOrMultiple(
          e, data, button,
          formData.renewalPolicyID,
          "Policy",
          true
        );
      }
      if (isRenewalPolicy) {
        await detectingIndividualOrMultiple(
          e, data, button,
          formData.renewalApplicationID,
          "Application",
          true
        );
        await detectingIndividualOrMultiple(
          e, data, button,
          policyID,
          "Policy",
          true
        );
      }
      setDeletionMode(false)
      if (setShowDriverExclusions) {
        setShowDriverExclusions(false)
      }
    } catch (error) {
      throw error
    }
  }

  const inputs = useMemo(() => {
    let formInputs = [
      {
        label: "Effective Date of the endorsement",
        type: "date",
        name: "effective-date",
        minDate: new Date(),
        required: true,
      },
    ];
    if (isAtLeastThreeSelectedInDeletedMode) {
      formInputs.push({
        label: "Deletion Reason",
        type: "text",
        name: "deletion-reason",
        required: true,
      });
    }
    if (deletionType === "exclusion") {
      formInputs.push({
        label: "Expiration Date of blacklisting",
        type: "date",
        name: "blacklist-expiration-date",
        minDate: new Date(),
        required: true,
      });
    }
    return formInputs;
  }, [isAtLeastThreeSelectedInDeletedMode, deletionType]);

  const formSettings = {
    onSubmit: mirrorLogic,
    inputs,
    buttonLabel: "Create Deletion Endorsement",
  };

  const handleSubmit = async (e, values, button) => {
    try {
      if (e) e?.preventDefault();
      if (
        formData.documentType === "Policy" ||
        formData.status === "In-Renewal"
      ) {
        setOpenModal(true);
        setModalValues(values);
      } else {
        setModalValues(values);
        const response = await mirrorLogic(e, values, button);
        if (response === 'verify is null') return
      }
    } catch (error) {
      throw error
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setModalValues(null);
  };

  const [skeletonTableLoader, setSkeletonTableLoader] = useState(false)

  const [currentFilters, setCurrentFilters] = useState({
    goodToGo: false,
  });

  const filters = activeFilters(manageDriverOptions, currentFilters);

  useEffect(() => {
    if (!formData.agencyID) return;
    if (!auth) {
      setSkeletonTableLoader(true)
      const { agencyID, insuredID } = formData
      apiService.renewalProcessDrivers({
        agencyID,
        insuredID,
      }).then((res) => {
        const drivers = res.drivers;
        const values = [];
        drivers.forEach((driver) => {        
          const thereIsAFilter = filters.length === 1
          if (thereIsAFilter) {
            const filter = filters[0]
            const isAccepted = Boolean(filter === "Accepted")
            if (isAccepted && driver.goodToGo) {
              values.push(driver);
            } else if (!isAccepted && !driver.goodToGo) {
              values.push(driver);
            }
          } else {
            values.push(driver);
          }
        });
        const filteredValues = buildShowingInfo(values, policyID)
        props.setDriverList(filteredValues);
        setSkeletonTableLoader(false)
      }).catch((error) => {
        console.error(error)
        setSkeletonTableLoader(false)
      }).finally(() => {
        setSkeletonTableLoader(false)
        return
      })
      return
    }
    setSkeletonTableLoader(true)
    const policyRef = doc(db, "policy-applications", policyID)
    let q = query(
      collection(
        db,
        "agencies",
        formData.agencyID,
        "insureds",
        formData.insuredID,
        "drivers"
      )
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const values = [];
      querySnapshot.forEach((doc) => {        
        const thereIsAFilter = filters.length === 1
        if (thereIsAFilter) {
          const filter = filters[0]
          const isAccepted = Boolean(filter === "Accepted")
          if (isAccepted && doc.data().goodToGo) {
            values.push({
              ...doc.data(),
              id: doc.id,
            });
          } else if (!isAccepted && !doc.data().goodToGo) {
            values.push({
              ...doc.data(),
              id: doc.id,
            });
          }
        } else {
          values.push({
            ...doc.data(),
            id: doc.id,
          });
        }
      });
      const filteredValues = buildShowingInfo(values, policyRef.id)
      props.setDriverList(filteredValues);
      setSkeletonTableLoader(false)
    });
    return () => {
      unsubscribe();
    };
  }, [props.policyID, formData.agencyID, currentFilters]);

  const showItem = (e, id) => {
    if (e) e?.preventDefault();
    setSelectedDriver(id);
    setShowTable(false);
    setShowDriverDetails(true);
  };

  const handleOpenEditMode = (e, body, type) => {
    if (e) e?.preventDefault();
  }

  const formingTableData = (deletionFlag, tableBehavior) => {
    const effectiveElementsList = driverList.filter((item) => {
      return !isExpired(item?.expirationDate);
    });
    const tableData = effectiveElementsList.map((data, dataIndex) => {
      const {
        driverFirst,
        driverLast,
        licenseCountry,
        licenseNumber,
        licensePhoto,
        driverLookupStatus,
        policiesDetails,
        driverLicenseFront,
        driverLicenseBack,
        MVR,
        caDriverAbstract,
        medicalLongForm,
        additionalDocument,
        otherDocuments,
        goodToGo
      } = data || {};
      const fullName = `${driverFirst} ${driverLast}`;
      const iconLeft = [];
      iconLeft.push({
        name: "copyToClipboard",
        toField: "fullName",
        className: "text-sky-900 hover:text-[#072a48] mr-2",
        onClick: () => navigator.clipboard.writeText(fullName),
      });
      if (goodToGo) {
        iconLeft.push({
          name: "goodToGo",
          toField: "fullName",
          className: "text-[#8B0000] hover:text-[#072a48] mr-2",
          onClick: null,
        });
      }
      iconLeft.push({
        name: "redFlag",
        toField: "fullName",
        className: "text-[#8B0000]",
        onClick: null,
      });
      const driversActions = [
        // "delete",
        "dropdown-driver-imgs"
      ]
      const iconRight = [];
      if (
        formData.documentType === "Application" ||
        formData.documentType === "Policy"
      ) {
        // iconRight.push({
        //   icon: "delete",
        //   action: true,
        //   toField: "action",
        // });
        iconRight.push({
          icon: "dropdown-driver-imgs",
          action: true,
          toField: "action",
        });
        if (
          driverLicenseFront?.length > 0 ||
          driverLicenseBack?.length > 0 ||
          MVR?.length > 0 ||
          caDriverAbstract?.length > 0 ||
          medicalLongForm?.length > 0 ||
          additionalDocument?.length > 0 ||
          otherDocuments?.length > 0
        ) {
          iconRight.push({
            icon: "dropdown-driver-view-result",
            action: true,
            toField: "action",
          });
          driversActions.push("dropdown-driver-view-result")
        }
      }

      return {
        ...data,
        tableInfo: {
          fullName: {
            text: fullName,
            isButton: true,
          },
          licenseCountry: {
            text: licenseCountry,
            className:
              driverLookupStatus === "Complete" ? "text-green-600" : "",
          },
          licenseNumber: {
            text: `${licenseNumber}`,
            className: 'break-all'
          },
          status: {
            text: handleSubcollectionStatus(
              filterRightPoliciesDetails(policiesDetails, policyID)?.status,
              language
            )
          },
          actions: {
            actions: tableBehavior
              ? ["deletion-behavior"]
              : deletionFlag
              ? [
                  {
                    checkbox: false,
                  },
                ]
              : driversActions,
          },
        },
        dataIndex,
        iconLeft,
        iconRight,
      };
    });
    return tableData;
  };

  useEffect(() => {
    if (typeof driverList.length !== "undefined") {
      if (deletionMode) {
        const tableDataDeletionMode = formingTableData(true);
        setTableDataInDeletionMode(tableDataDeletionMode);
      }
      const tableBehavior = deletionMode ? "deletion-behavior" : "";
      const tableData = formingTableData(false, tableBehavior);
      setTableData(tableData);
    }
  }, [driverList, deletionMode]);

  const columns = [
    {
      name: "fullName",
      label: t('plm.drivers.driver-name'),
    },
    {
      name: "licenseCountry",
      label: t('plm.drivers.country'),
    },
    {
      name: "licenseNumber",
      label: t('plm.drivers.license'),
    },
    {
      name: "status",
      label: t('plm.drivers.details.status'),
    },
    {
      name: "action",
      label: t('plm.drivers.actions'),
    },
  ];

  const filterKeys = [
    "driverFirst",
    "driverLast",
    "licenseCountry",
    "licenseState",
    "licenseNumber",
    "status",
  ];

  return {
    openModal,
    handleCloseModal,
    deletionMode,
    setDeletionMode,
    formSettings,
    modalSubmitting,
    columns,
    tableData,
    tableDataInDeletionMode,
    setTableDataInDeletionMode,
    tableDataShowingDeletion,
    setTableDataShowingDeletion,
    handleSubmit,
    showItem,
    filterKeys,
    isAtLeastOneSelectedInDeletedMode,
    setDeletionType,
    skeletonTableLoader,
    handleOpenEditMode,
    currentFilters,
    setCurrentFilters,
    deletionType,
    mirrorLogic
  };
};

export default useDriversList;
