import React, { useEffect, useState } from "react";
import axios from "axios";
import { storage } from "../../../firebase.js";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { useNavigate, useParams } from "react-router-dom";
import {
  doc,
  setDoc,
  getDoc,
  updateDoc,
  addDoc,
  collection,
} from "firebase/firestore";
import { db, auth } from "../../../firebase.js";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../styling/app.css";
import { UserAuth } from "../../../Context/AuthContent.js";
import useUsersState from "../../../Context/actions/useUsersState.js";
import agencyAppointmentStatusChangeNotification from "../../utils/notifications/agencyappointmentStatusChangeNotification.jsx";
import config from "../../../config/env.js";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"} variant={"body2"}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function AppointmentApplication() {
  // Other Stuff
  let { paramRefID } = useParams();

  // Date Stuff
  const current = new Date();
  const date = `${
    current.getMonth() + 1
  }/${current.getDate()}/${current.getFullYear()}`;

  // Tabs Stuff
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Save Application
  const saveApplication = async () => {
    if (disabled) return;
    // if (paramRefID !== auth.currentUser.uid)
    //   return alert(
    //     "Error: You are not the author. Only the author may save/submit."
    //   );
    if (formData.status !== "Incomplete" && formData.status !== "")
      return alert("Error: Application already submitted.");
    await setDoc(
      doc(db, "appointment-applications", paramRefID),
      {
        ...formData,
        status: "Incomplete",
      },
      { merge: true }
    );
    setFormData({ ...formData, status: "Incomplete" });
    console.log("saved");
  };

  const submitApplication = async () => {
    if (paramRefID !== auth.currentUser.uid)
      return alert("You are not the author. Only the author may save/submit.");
    if (formData.status !== "Incomplete" && formData.status !== "")
      return alert("Error: Application already submitted.");
    const issues = document.querySelector("#issues");
    if (issues.textContent.length !== 0) {
      await setDoc(
        doc(db, "appointment-applications", paramRefID),
        {
          ...formData,
          status: "Pending",
        },
        { merge: true }
      );
      setFormData({ ...formData, status: "Pending" });
      // Log System Activity
      await addDoc(collection(db, "system-activity"), {
        activityDate: new Date(),
        authorID: auth.currentUser.uid,
        authorName: auth.currentUser.displayName,
        description:
          "Appointment Application Submitted by: " +
          auth.currentUser.displayName +
          " of " +
          user.company,
      });
      // Send Notification Email
      sendEmail()
        .then(alert("Your application has been submitted."))
        .then(navigate("/account"));
    } else {
      alert(
        "You may not submit your application because it is incomplete. Please see the list of incomplete fields on the Submit tab of your application."
      );
    }
  };

  //   Get Data
  const [myData, setMyData] = useState({});
  const [dataUpdated, setDataUpdated] = useState(false);
  const getApplication = async (usersID) => {
    // Check if the agent has an application from the old way of doing things
    const docRef = doc(db, "appointment-applications", usersID);
    const docSnap = await getDoc(docRef);
    setDataUpdated(false);
    if (docSnap.exists()) {
      setMyData(docSnap.data());
      setDataUpdated(true);
      if (docSnap.data().status === "Pending") setDisabled(true);
    } else {
      console.log("No such document!");
      setDataUpdated(true);
      return null;
    }
  };

  const [disabled, setDisabled] = useState(false);
  const { user } = useUsersState();

  const [fixedData, setFixedData] = useState(false);
  const fixData = () => {
    const convertedDate = convertDate(myData.dateOfIncorporation?.seconds);
    if (myData.dateOfIncorporation)
      setFormData({
        ...formData,
        ...myData,
        dateOfIncorporation: convertedDate,
      });
    else setFormData({ ...formData, ...myData, dateOfIncorporation: "" });
    setFixedData(true);
  };

  const handleAddStockholder = () => {
    setFormData({
      ...formData,
      stockholderFields: [
        ...formData.stockholderFields,
        { name: "", ownership: 0 },
      ],
    });
  };

  const handleSubtractStockholder = async (e, i) => {
    e.preventDefault();
    const values = [...formData.stockholderFields];
    values.splice(i, 1);
    setFormData({ ...formData, stockholderFields: [...values] });
    if (values.length === 0) {
      setFormData({
        ...formData,
        stockholderFields: [
          {
            name: "",
            ownership: 0,
          },
        ],
      });
    }
  };

  const handleChangeInputStockholder = (i, e) => {
    const values = [...formData.stockholderFields];
    values[i][e.target.name] = e.target.value;
    setFormData({ ...formData, stockholderFields: values });
  };

  const handleAddCorporateOfficer = () => {
    setFormData({
      ...formData,
      corporateOfficerFields: [
        ...formData.corporateOfficerFields,
        { name: "", title: "" },
      ],
    });
  };

  const handleSubtractCorporateOfficer = async (e, i) => {
    e.preventDefault();
    const values = [...formData.corporateOfficerFields];
    values.splice(i, 1);
    setFormData({ ...formData, corporateOfficerFields: [...values] });
    if (values.length === 0) {
      setFormData({
        ...formData,
        corporateOfficerFields: [
          {
            name: "",
            title: "",
          },
        ],
      });
    }
  };

  const handleChangeInputCorporateOfficer = (i, e) => {
    const values = [...formData.corporateOfficerFields];
    values[i][e.target.name] = e.target.value;
    setFormData({ ...formData, corporateOfficerFields: values });
  };

  const handleAddLLCMember = () => {
    setFormData({
      ...formData,
      LLCMemberFields: [
        ...formData.LLCMemberFields,
        { name: "", title: "", ownership: 0 },
      ],
    });
  };

  const handleSubtractLLCMember = async (e, i) => {
    e.preventDefault();
    const values = [...formData.LLCMemberFields];
    values.splice(i, 1);
    setFormData({ ...formData, LLCMemberFields: [...values] });
    if (values.length === 0) {
      setFormData({
        ...formData,
        LLCMemberFields: [
          {
            name: "",
            title: "",
            ownership: 0,
          },
        ],
      });
    }
  };

  const handleChangeInputLLCMember = (i, e) => {
    const values = [...formData.LLCMemberFields];
    values[i][e.target.name] = e.target.value;
    setFormData({ ...formData, LLCMemberFields: values });
  };

  const handleAddLLCOfficer = () => {
    setFormData({
      ...formData,
      LLCOfficerFields: [
        ...formData.LLCOfficerFields,
        { name: "", title: "", businessInsuranceAmount: 0 },
      ],
    });
  };

  const handleSubtractLLCOfficer = async (e, i) => {
    e.preventDefault();
    const values = [...formData.LLCOfficerFields];
    values.splice(i, 1);
    setFormData({ ...formData, LLCOfficerFields: [...values] });
    if (values.length === 0) {
      setFormData({
        ...formData,
        LLCOfficerFields: [
          {
            name: "",
            title: "",
            businessInsuranceAmount: 0,
          },
        ],
      });
    }
  };

  const handleChangeInputLLCOfficer = (i, e) => {
    const values = [...formData.LLCOfficerFields];
    values[i][e.target.name] = e.target.value;
    setFormData({ ...formData, LLCOfficerFields: values });
  };

  const handleAddPartner = () => {
    setFormData({
      ...formData,
      partnerFields: [
        ...formData.partnerFields,
        { name: "", title: "", insuranceAmount: 0 },
      ],
    });
  };

  const handleSubtractPartner = async (e, i) => {
    e.preventDefault();
    const values = [...formData.partnerFields];
    values.splice(i, 1);
    setFormData({ ...formData, partnerFields: [...values] });
    if (values.length === 0) {
      setFormData({
        ...formData,
        partnerFields: [
          {
            name: "",
            title: "",
            insuranceAmount: 0,
          },
        ],
      });
    }
  };

  const handleChangeInputPartner = (i, e) => {
    const values = [...formData.partnerFields];
    values[i][e.target.name] = e.target.value;
    setFormData({ ...formData, partnerFields: values });
  };

  //   Form Handling
  const [formData, setFormData] = useState({
    status: "",
    lastUpdated: date,
    agencyName: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    POBox: "",
    POBoxZip: "",
    phone: "",
    fax: "",
    webAddress: "",
    taxID: "",
    exactName: "",
    locations: "",
    subsidiaries: "",
    TWP: "",
    ALPercentage: "",
    APDPercentage: "",
    MTCPercentage: "",
    marketingContact: "",
    accountingContact: "",
    licensingContact: "",
    ITcontact: "",
    structure: "",
    corporationState: "",
    dateOfIncorporation: new Date(),
    vestedManager: "",
    managerName: "",
    managerLifeInsuranceAmount: "",
    proprietorshipName: "",
    SSNFEIN: "",
    changedAgencyOwnership: "",
    whyChangedAgencyOwnership: "",
    mergerOrPurchase: "",
    whyMergerOrPurchase: "",
    changeInLicenses: "",
    whyChangeInLicenses: "",
    changeInManagement: "",
    whyChangeInManagement: "",
    finesOrDisciplinary: "",
    whyFinesOrDisciplinary: "",
    howAgentsCompensated: "",
    buySellAgreements: "",
    firmSpecializes: "",
    whyFirmSpecializes: "",
    marketingArea: "",
    majorCompetitors: "",
    mainCompetition: "",
    volumePersonal: "",
    volumeCommercial: "",
    volumeTruckOnly: "",
    brokerTruckBusiness: "",
    writingSurplusLines: "",
    hasPremiumTrust: "",
    methodSeparateFunds: "",
    trustBank: "",
    bankName: "",
    bankAddress: "",
    bankCityStateZip: "",
    bankPhone: "",
    bankFax: "",
    bankContactName: "",
    bankContactEmail: "",
    businessName: "",
    businessAddress: "",
    businessCityStateZip: "",
    businessPhone: "",
    businessFax: "",
    businessContactName: "",
    businessContactEmail: "",
    business2Name: "",
    business2Address: "",
    business2CityStateZip: "",
    business2Phone: "",
    business2Fax: "",
    business2ContactName: "",
    business2ContactEmail: "",
    balanceSheet1URL: "",
    balanceSheet2URL: "",
    incomeStatement1URL: "",
    incomeStatement2URL: "",
    mostRecentbalanceSheetURL: "",
    mostRecentIncomeStatementURL: "",
    TXLicenseURL: "",
    AZLicenseURL: "",
    CALicenseURL: "",
    NMLicenseURL: "",
    EOcertificateURL: "",
    partnerFields: [
      {
        name: "",
        title: "",
        insuranceAmount: 0,
      },
    ],
    LLCMemberFields: [
      {
        name: "",
        title: "",
        ownership: 0,
      },
    ],
    LLCOfficerFields: [
      {
        name: "",
        title: "",
        businessInsuranceAmount: 0,
      },
    ],
    corporateOfficerFields: [
      {
        name: "",
        title: "",
      },
    ],
    stockholderFields: [
      {
        name: "",
        ownership: 0,
      },
    ],
  });

  const handleBlur = async (e) => {
    saveApplication();
  };

  const handleSelectBlur = async (e) => {
    saveApplication();
  };

  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  const handleSubmit = async (e, changer) => {
    e.preventDefault();
    if (formData.agencyName === "")
      return alert(
        "You must enter an Agency Name on the Agency Info tab before uploading documentation."
      );

    const file = e.target[0]?.files[0];

    if (!file) return;

    const storageRef = ref(
      storage,
      `files/appointment-applications/${formData.agencyName}/${file.name}`
    );
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {},
      (error) => {
        alert(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
          setFormData({ ...formData, [changer]: downloadURL });
          const washingtonRef = doc(db, "appointment-applications", paramRefID);
          await updateDoc(washingtonRef, {
            [changer]: formData.downloadURL,
          });
        });
      }
    );
  };

  const convertDate = (secs) => {
    const output = new Date(secs * 1000);
    return output;
  };

  const { REACT_APP_SOUTHERN_BACKEND } = config;

  const sendEmail = async () => {
    const notificationPayload =
      await agencyAppointmentStatusChangeNotification();
    axios
      .post(`${REACT_APP_SOUTHERN_BACKEND}/send-email/application-submitted`, {
        ...formData,
        to: notificationPayload.to,
        cc: notificationPayload.cc,
        name: user.first + " " + user.last,
        company: user.company,
        email: user.email,
        phone: user.phone,
      })
      .then(function (response) {
        // handle success
        console.log(response);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (dataUpdated === false) getApplication(paramRefID);
    dataUpdated && fixData();
  }, [dataUpdated, fixedData]);

  return (
    <section className="h-screen w-full bg-gray-100">
      {fixedData === false ? (
        <p>Loading...</p>
      ) : (
        <>
          <Box sx={{ width: "100%", height: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="Factfinder Tabs"
                // disable the tab indicator because it doesn't work well with wrapped section
                // TabIndicatorProps={{ sx: { display: "none" } }}
                indicatorColor="black"
                sx={{
                  "& .MuiTabs-flexsection": {
                    flexWrap: "wrap",
                    backgroundColor: "lightGray",
                    marginTop: "5px",
                    justifyContent: "center",
                  },
                }}
              >
                <Tab label="Status" {...a11yProps(0)} />
                <Tab label="Agency Info" {...a11yProps(1)} />
                {user.agentAccepted && (
                  <>
                    <Tab label="Contacts" {...a11yProps(2)} />
                    <Tab label="Structure" {...a11yProps(3)} />
                    <Tab label="Continued" {...a11yProps(4)} />
                    <Tab label="References" {...a11yProps(5)} />
                    <Tab label="Documentation" {...a11yProps(6)} />
                    {formData.status !== "Pending" && (
                      <Tab label="Submit" {...a11yProps(7)} />
                    )}
                  </>
                )}
              </Tabs>
            </Box>
            <fieldset>
              {/* Status */}
              <TabPanel value={value} index={0} className="bg-gray-100">
                {formData.status !== "" && (
                  <p className="pt-1 text-xl text-[#072a48] text-center">
                    Current Application Status:
                  </p>
                )}
                {formData.status === "Incomplete" ? (
                  <>
                    <p className="pt-1 pb-3 text-3xl font-bold text-[#8B0000] text-center">
                      {formData.status}
                    </p>
                  </>
                ) : (
                  <p className="pt-1 pb-3 text-3xl font-bold text-green-600 text-center">
                    {formData.status}
                  </p>
                )}

                {user.role === "Underwriter" || user.role === "Admin" ? (
                  <section>
                    {formData.comments !== "" && formData.comments && (
                      <div className="p-3 text-center border-2 border-black">
                        <p className="mb-2">Comments From Agent:</p>
                        <p>{formData?.comments}</p>
                      </div>
                    )}
                  </section>
                ) : (
                  <></>
                )}
                <p>More About Statuses</p>
                <p className="font-bold text-md text-[#072a48] mt-2">
                  Incomplete/No Status
                </p>
                <p>
                  Your status will not change to Pending until you have
                  completed all fields and submitted your application by
                  clicking the submit button. If the information for a
                  particular field truly does not exist, you may enter 0 or N/A.
                </p>
                <p className="font-bold text-md text-[#072a48] mt-4">Pending</p>
                <p>
                  Once your application is complete and has been submitted, it
                  will go into review by our Agent Development team. Check back
                  regularly, once you have been accepted the ability to submit
                  business will be added to your Dashboard.
                </p>
                <div className="text-center w-full">
                  <button
                    onClick={() => {
                      setValue(value + 1);
                    }}
                    className="button-2 mt-4"
                  >
                    Proceed to Application
                  </button>
                </div>
              </TabPanel>
              {/* Agency Information */}
              <TabPanel value={value} index={1} className="bg-gray-100">
                <form
                  onSubmit={async (e) => {
                    e.preventDefault();
                    setFormData({ ...formData, status: "Pending" });
                    await setDoc(
                      doc(db, "appointment-applications", paramRefID),
                      {
                        ...formData,
                        status: "Pending",
                      },
                      { merge: true }
                    );
                  }}
                >
                  <h1 className="title-1">Appointment Information</h1>
                  <div>
                    <label className="input-label">Web Address:</label>
                    <input
                      required
                      disabled={disabled}
                      onChange={(e) =>
                        setFormData({ ...formData, webAddress: e.target.value })
                      }
                      onBlur={(e) => handleBlur(e)}
                      className="p-2 mb-3 w-full"
                      type="text"
                      value={formData.webAddress}
                    />
                  </div>
                  <div>
                    <label className="input-label">Federal Tax ID:</label>
                    <input
                      required
                      disabled={disabled}
                      onChange={(e) =>
                        setFormData({ ...formData, taxID: e.target.value })
                      }
                      onBlur={(e) => handleBlur(e)}
                      className="p-2 mb-3 w-full"
                      type="text"
                      value={formData.taxID}
                    />
                  </div>
                  <div>
                    <label className="input-label">
                      Exact name of agency to which the above referenced Federal
                      Tax ID Number is Issued to (include d.b.a if applicable):
                    </label>
                    <input
                      required
                      disabled={disabled}
                      onChange={(e) =>
                        setFormData({ ...formData, exactName: e.target.value })
                      }
                      onBlur={(e) => handleBlur(e)}
                      className="p-2 mb-3 w-full"
                      type="text"
                      value={formData.exactName}
                    />
                  </div>
                  <div>
                    <label className="input-label">
                      List States You Write Business In:
                    </label>
                    <input
                      required
                      disabled={disabled}
                      onChange={(e) =>
                        setFormData({ ...formData, locations: e.target.value })
                      }
                      onBlur={(e) => handleBlur(e)}
                      className="p-2 mb-3 w-full"
                      type="text"
                      value={formData.locations}
                    />
                  </div>
                  <div>
                    <label className="input-label">
                      List any affiliates or subsidiaries (optional):
                    </label>
                    <input
                      disabled={disabled}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          subsidiaries: e.target.value,
                        })
                      }
                      onBlur={(e) => handleBlur(e)}
                      className="p-2 mb-3 w-full"
                      type="text"
                      value={formData.subsidiaries}
                    />
                  </div>
                  <div>
                    <label className="input-label">
                      Does the firm specialize in any specific line or class of
                      business:
                    </label>
                    <select
                      required
                      disabled={disabled}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          firmSpecializes: e.target.value,
                        });
                      }}
                      onBlur={(e) => {
                        handleSelectBlur(e);
                      }}
                      className="p-2 mb-3 w-full"
                      value={formData.firmSpecializes}
                    >
                      <option value="">Choose One</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                    {/* If user selectes Yes */}
                    {formData.firmSpecializes === "Yes" && (
                      <>
                        <div>
                          <label className="input-label">Please explain:</label>
                          <textarea
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                whyFirmSpecializes: e.target.value,
                              })
                            }
                            onBlur={(e) => handleBlur(e)}
                            className="p-2 mb-3 w-full"
                            value={formData.whyFirmSpecializes}
                          ></textarea>
                        </div>
                      </>
                    )}
                  </div>
                  <div>
                    <label className="input-label">
                      What percentage of your agency volume is in personal
                      insurance?:
                    </label>
                    <input
                      required
                      disabled={disabled}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          volumePersonal: e.target.value,
                        })
                      }
                      onBlur={(e) => handleBlur(e)}
                      className="p-2 mb-3 w-full"
                      type="text"
                      value={formData.volumePersonal}
                    />
                  </div>
                  <div>
                    <label className="input-label">
                      What percentage of your agency volume is in commercial
                      insurance?:
                    </label>
                    <input
                      required
                      disabled={disabled}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          volumeCommercial: e.target.value,
                        })
                      }
                      onBlur={(e) => handleBlur(e)}
                      className="p-2 mb-3 w-full"
                      type="text"
                      value={formData.volumeCommercial}
                    />
                  </div>
                  <div>
                    <label className="input-label">
                      What percentage of your agency volume is in Truck Only
                      business?:
                    </label>
                    <input
                      required
                      disabled={disabled}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          volumeTruckOnly: e.target.value,
                        })
                      }
                      onBlur={(e) => handleBlur(e)}
                      className="p-2 mb-3 w-full"
                      type="text"
                      value={formData.volumeTruckOnly}
                    />
                  </div>
                  <div>
                    <label className="input-label">
                      What is your total written premium in trucking business?
                      (AL + APD + MTC):
                    </label>
                    <input
                      required
                      disabled={disabled}
                      onChange={(e) =>
                        setFormData({ ...formData, TWP: e.target.value })
                      }
                      onBlur={(e) => handleBlur(e)}
                      className="p-2 mb-3 w-full"
                      type="text"
                      value={formData.TWP}
                    />
                  </div>
                  <div>
                    <label className="input-label">
                      What percentage of your total written trucking premium is
                      Auto Liability?:
                    </label>
                    <input
                      required
                      disabled={disabled}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          ALPercentage: e.target.value,
                        })
                      }
                      onBlur={(e) => handleBlur(e)}
                      className="p-2 mb-3 w-full"
                      type="text"
                      value={formData.ALPercentage}
                    />
                  </div>
                  <div>
                    <label className="input-label">
                      What percentage of your total written trucking premium is
                      Auto Physical Damage?:
                    </label>
                    <input
                      required
                      disabled={disabled}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          APDPercentage: e.target.value,
                        })
                      }
                      onBlur={(e) => handleBlur(e)}
                      className="p-2 mb-3 w-full"
                      type="text"
                      value={formData.APDPercentage}
                    />
                  </div>
                  <div>
                    <label className="input-label">
                      What percentage of your total written trucking premium is
                      Motor Truck Cargo?:
                    </label>
                    <input
                      required
                      disabled={disabled}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          MTCPercentage: e.target.value,
                        })
                      }
                      onBlur={(e) => handleBlur(e)}
                      className="p-2 mb-3 w-full"
                      type="text"
                      value={formData.MTCPercentage}
                    />
                  </div>
                  <div>
                    <label className="input-label">Comments:</label>
                    <textarea
                      required
                      disabled={disabled}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          comments: e.target.value,
                        })
                      }
                      onBlur={(e) => handleBlur(e)}
                      className="p-2 mb-3 w-full"
                      type="text"
                      value={formData.comments}
                    />
                  </div>
                  <article className="back-next-section text-center">
                    {formData.status === "Incomplete" ||
                    formData.status === "" ? (
                      <>
                        {user.agentAccepted ? (
                          <>
                            <button
                              onClick={() => {
                                setValue(value + 1);
                                saveApplication();
                              }}
                              className="button-2"
                            >
                              Save & Continue
                            </button>
                          </>
                        ) : (
                          <>
                            <button
                              className="button-2"
                              onClick={async (e) => {
                                submitApplication();
                              }}
                            >
                              Submit Application
                            </button>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {user.agentAccepted && (
                          <>
                            <button
                              onClick={() => setValue(value - 1)}
                              className="button-1"
                              disabled={value === 0}
                            >
                              Back
                            </button>
                            <button
                              onClick={() => {
                                setValue(value + 1);
                              }}
                              className="button-2"
                            >
                              Next
                            </button>
                          </>
                        )}
                      </>
                    )}
                  </article>
                </form>
              </TabPanel>
              {user.agentAccepted && (
                <>
                  {/* Agency Contacts */}
                  <TabPanel value={value} index={2} className="bg-gray-100">
                    <h1 className="title-1">Agency Contacts</h1>
                    <div>
                      <label className="input-label">
                        Marketing Contact Email:
                      </label>
                      <input
                        disabled={disabled}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            marketingContact: e.target.value,
                          })
                        }
                        onBlur={(e) => handleBlur(e)}
                        className="p-2 mb-3 w-full"
                        type="email"
                        value={formData.marketingContact}
                      />
                    </div>
                    <div>
                      <label className="input-label">
                        Licensing Contact Email:
                      </label>
                      <input
                        disabled={disabled}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            licensingContact: e.target.value,
                          })
                        }
                        onBlur={(e) => handleBlur(e)}
                        className="p-2 mb-3 w-full"
                        type="email"
                        value={formData.licensingContact}
                      />
                    </div>
                    <div>
                      <label className="input-label">
                        Accounting Contact Email:
                      </label>
                      <input
                        disabled={disabled}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            accountingContact: e.target.value,
                          })
                        }
                        onBlur={(e) => handleBlur(e)}
                        className="p-2 mb-3 w-full"
                        type="email"
                        value={formData.accountingContact}
                      />
                    </div>
                    <div>
                      <label className="input-label">IT Contact Email:</label>
                      <input
                        disabled={disabled}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            ITcontact: e.target.value,
                          })
                        }
                        onBlur={(e) => handleBlur(e)}
                        className="p-2 mb-3 w-full"
                        type="email"
                        value={formData.ITcontact}
                      />
                    </div>

                    <article className="back-next-section text-center">
                      {formData.status === "Incomplete" ||
                      formData.status === "" ? (
                        <>
                          <button
                            onClick={() => {
                              setValue(value + 1);
                              saveApplication();
                            }}
                            className="button-2"
                          >
                            Save & Continue
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            onClick={() => {
                              setValue(value + 1);
                            }}
                            className="button-2"
                          >
                            Next
                          </button>
                        </>
                      )}
                      <button
                        onClick={() => setValue(value - 1)}
                        className="button-1"
                        disabled={value === 0}
                      >
                        Back
                      </button>
                    </article>
                  </TabPanel>
                  {/* Business Structure */}
                  <TabPanel value={value} index={3} className="bg-gray-100">
                    <h1 className="title-1">Business Structure</h1>
                    <div>
                      <label className="input-label">Agency is a:</label>
                      <select
                        disabled={disabled}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            structure: e.target.value,
                          });
                        }}
                        onBlur={(e) => {
                          handleSelectBlur(e);
                        }}
                        className="p-2 mb-3 w-full"
                        value={formData.structure}
                      >
                        <option value="">Choose One</option>
                        <option value="LLC">
                          Limited Liability Company (LLC)
                        </option>
                        <option value="Corporation">Corporation</option>
                        <option value="Sub-chapter S Corporation">
                          Sub-chapter S Corporation
                        </option>
                        <option value="Partnership">Partnership</option>
                        <option value="Sole Proprietorship">
                          Sole Proprietorship
                        </option>
                      </select>
                      {/* If user selectes Corporation */}
                      {(formData.structure === "Corporation") |
                      (formData.structure === "Sub-chapter S Corporation") ? (
                        <>
                          <div>
                            <label className="input-label">
                              Date of Incorporation:
                            </label>
                            <DatePicker
                              wrapperClassName="datePicker"
                              selected={formData.dateOfIncorporation}
                              onChange={(date) => {
                                setFormData({
                                  ...formData,
                                  dateOfIncorporation: date,
                                });
                              }}
                              className="p-2 mb-3 w-full"
                            />
                          </div>
                          <div>
                            <label className="input-label">State:</label>
                            <select
                              disabled={disabled}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  corporationState: e.target.value,
                                })
                              }
                              onBlur={(e) => handleBlur(e)}
                              className="p-2 mb-3 w-full"
                              value={formData.corporationState}
                            >
                              <option value="">Choose One</option>
                              <option value="AL">Alabama</option>
                              <option value="AK">Alaska</option>
                              <option value="AZ">Arizona</option>
                              <option value="AR">Arkansas</option>
                              <option value="CA">California</option>
                              <option value="CO">Colorado</option>
                              <option value="CT">Connecticut</option>
                              <option value="DE">Delaware</option>
                              <option value="DC">District Of Columbia</option>
                              <option value="FL">Florida</option>
                              <option value="GA">Georgia</option>
                              <option value="HI">Hawaii</option>
                              <option value="ID">Idaho</option>
                              <option value="IL">Illinois</option>
                              <option value="IN">Indiana</option>
                              <option value="IA">Iowa</option>
                              <option value="KS">Kansas</option>
                              <option value="KY">Kentucky</option>
                              <option value="LA">Louisiana</option>
                              <option value="ME">Maine</option>
                              <option value="MD">Maryland</option>
                              <option value="MA">Massachusetts</option>
                              <option value="MI">Michigan</option>
                              <option value="MN">Minnesota</option>
                              <option value="MS">Mississippi</option>
                              <option value="MO">Missouri</option>
                              <option value="MT">Montana</option>
                              <option value="NE">Nebraska</option>
                              <option value="NV">Nevada</option>
                              <option value="NH">New Hampshire</option>
                              <option value="NJ">New Jersey</option>
                              <option value="NM">New Mexico</option>
                              <option value="NY">New York</option>
                              <option value="NC">North Carolina</option>
                              <option value="ND">North Dakota</option>
                              <option value="OH">Ohio</option>
                              <option value="OK">Oklahoma</option>
                              <option value="OR">Oregon</option>
                              <option value="PA">Pennsylvania</option>
                              <option value="RI">Rhode Island</option>
                              <option value="SC">South Carolina</option>
                              <option value="SD">South Dakota</option>
                              <option value="TN">Tennessee</option>
                              <option value="TX">Texas</option>
                              <option value="UT">Utah</option>
                              <option value="VT">Vermont</option>
                              <option value="VA">Virginia</option>
                              <option value="WA">Washington</option>
                              <option value="WV">West Virginia</option>
                              <option value="WI">Wisconsin</option>
                              <option value="WY">Wyoming</option>
                            </select>
                          </div>
                          <>
                            {formData.stockholderFields.map((field, i) => (
                              <div key={"stockholder" + field.name}>
                                <div>
                                  <label className="input-label">
                                    Stockholder {i + 1} Name:
                                  </label>
                                  <input
                                    disabled={disabled}
                                    name="name"
                                    onChange={(e) =>
                                      handleChangeInputStockholder(i, e)
                                    }
                                    onBlur={handleBlur}
                                    className="p-2 mb-3 w-full"
                                    type="text"
                                    value={field.name}
                                  />
                                </div>
                                <div>
                                  <label className="input-label">
                                    Stockholder {i + 1} Percentage Ownership:
                                  </label>
                                  <input
                                    disabled={disabled}
                                    name="ownership"
                                    onChange={(e) =>
                                      handleChangeInputStockholder(i, e)
                                    }
                                    onBlur={handleBlur}
                                    className="p-2 mb-3 w-full"
                                    type="text"
                                    value={field.ownership}
                                  />
                                </div>
                                <button
                                  className="text-[#072a48] mb-4"
                                  onClick={(e) =>
                                    handleSubtractStockholder(e, i)
                                  }
                                >
                                  - Remove Stockholder {i + 1}
                                </button>
                              </div>
                            ))}
                            <button
                              className="text-[#072a48] mb-4"
                              onClick={() => handleAddStockholder()}
                            >
                              + Add another Stockholder
                            </button>
                          </>
                          <>
                            {formData.corporateOfficerFields.map((field, i) => (
                              <div key={"corporateOfficer" + field.name}>
                                <div>
                                  <label className="input-label">
                                    Corporate Officer {i + 1} Name:
                                  </label>
                                  <input
                                    disabled={disabled}
                                    name="name"
                                    onChange={(e) =>
                                      handleChangeInputCorporateOfficer(i, e)
                                    }
                                    onBlur={handleBlur}
                                    className="p-2 mb-3 w-full"
                                    type="text"
                                    value={field.name}
                                  />
                                </div>
                                <div>
                                  <label className="input-label">
                                    Corporate Officer {i + 1} Title:
                                  </label>
                                  <input
                                    disabled={disabled}
                                    name="title"
                                    onChange={(e) =>
                                      handleChangeInputCorporateOfficer(i, e)
                                    }
                                    onBlur={handleBlur}
                                    className="p-2 mb-3 w-full"
                                    type="text"
                                    value={field.title}
                                  />
                                </div>
                                <button
                                  className="text-[#072a48] mb-4"
                                  onClick={(e) =>
                                    handleSubtractCorporateOfficer(e, i)
                                  }
                                >
                                  - Remove Corporate Officer {i + 1}
                                </button>
                              </div>
                            ))}
                            <button
                              className="text-[#072a48] mb-4"
                              onClick={() => handleAddCorporateOfficer()}
                            >
                              + Add another Corporate Officer
                            </button>
                          </>
                        </>
                      ) : (
                        <></>
                      )}
                      {/* If user selectes LLC */}
                      {formData.structure === "LLC" ? (
                        <>
                          <div>
                            <label className="input-label">
                              Date of Incorporation:
                            </label>
                            <DatePicker
                              wrapperClassName="datePicker"
                              disabled={disabled}
                              selected={formData.dateOfIncorporation}
                              onChange={(date) => {
                                setFormData({
                                  ...formData,
                                  dateOfIncorporation: date,
                                });
                              }}
                              className="p-2 mb-3 w-full"
                            />
                          </div>
                          <div>
                            <label className="input-label">State:</label>
                            <select
                              disabled={disabled}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  corporationState: e.target.value,
                                })
                              }
                              onBlur={(e) => handleBlur(e)}
                              className="p-2 mb-3 w-full"
                              value={formData.corporationState}
                            >
                              <option value="">Choose One</option>
                              <option value="AL">Alabama</option>
                              <option value="AK">Alaska</option>
                              <option value="AZ">Arizona</option>
                              <option value="AR">Arkansas</option>
                              <option value="CA">California</option>
                              <option value="CO">Colorado</option>
                              <option value="CT">Connecticut</option>
                              <option value="DE">Delaware</option>
                              <option value="DC">District Of Columbia</option>
                              <option value="FL">Florida</option>
                              <option value="GA">Georgia</option>
                              <option value="HI">Hawaii</option>
                              <option value="ID">Idaho</option>
                              <option value="IL">Illinois</option>
                              <option value="IN">Indiana</option>
                              <option value="IA">Iowa</option>
                              <option value="KS">Kansas</option>
                              <option value="KY">Kentucky</option>
                              <option value="LA">Louisiana</option>
                              <option value="ME">Maine</option>
                              <option value="MD">Maryland</option>
                              <option value="MA">Massachusetts</option>
                              <option value="MI">Michigan</option>
                              <option value="MN">Minnesota</option>
                              <option value="MS">Mississippi</option>
                              <option value="MO">Missouri</option>
                              <option value="MT">Montana</option>
                              <option value="NE">Nebraska</option>
                              <option value="NV">Nevada</option>
                              <option value="NH">New Hampshire</option>
                              <option value="NJ">New Jersey</option>
                              <option value="NM">New Mexico</option>
                              <option value="NY">New York</option>
                              <option value="NC">North Carolina</option>
                              <option value="ND">North Dakota</option>
                              <option value="OH">Ohio</option>
                              <option value="OK">Oklahoma</option>
                              <option value="OR">Oregon</option>
                              <option value="PA">Pennsylvania</option>
                              <option value="RI">Rhode Island</option>
                              <option value="SC">South Carolina</option>
                              <option value="SD">South Dakota</option>
                              <option value="TN">Tennessee</option>
                              <option value="TX">Texas</option>
                              <option value="UT">Utah</option>
                              <option value="VT">Vermont</option>
                              <option value="VA">Virginia</option>
                              <option value="WA">Washington</option>
                              <option value="WV">West Virginia</option>
                              <option value="WI">Wisconsin</option>
                              <option value="WY">Wyoming</option>
                            </select>
                          </div>
                          <>
                            {formData.LLCMemberFields.map((field, i) => (
                              <div key={"LLCMember" + i}>
                                <div>
                                  <label className="input-label">
                                    LLC Member {i + 1} Name:
                                  </label>
                                  <input
                                    disabled={disabled}
                                    name="name"
                                    onChange={(e) =>
                                      handleChangeInputLLCMember(i, e)
                                    }
                                    onBlur={handleBlur}
                                    className="p-2 mb-3 w-full"
                                    type="text"
                                    value={field.name}
                                  />
                                </div>
                                <div>
                                  <label className="input-label">
                                    LLC Member {i + 1} Title:
                                  </label>
                                  <input
                                    disabled={disabled}
                                    name="title"
                                    onChange={(e) =>
                                      handleChangeInputLLCMember(i, e)
                                    }
                                    onBlur={handleBlur}
                                    className="p-2 mb-3 w-full"
                                    type="text"
                                    value={field.title}
                                  />
                                </div>
                                <div>
                                  <label className="input-label">
                                    LLC Member {i + 1} % ownership of Capital:
                                  </label>
                                  <input
                                    disabled={disabled}
                                    name="ownership"
                                    onChange={(e) =>
                                      handleChangeInputLLCMember(i, e)
                                    }
                                    onBlur={handleBlur}
                                    className="p-2 mb-3 w-full"
                                    type="text"
                                    value={field.ownership}
                                  />
                                </div>
                                <button
                                  className="text-[#072a48] mb-4"
                                  onClick={(e) => handleSubtractLLCMember(e, i)}
                                >
                                  - Remove LLC Member {i + 1}
                                </button>
                              </div>
                            ))}
                            <button
                              className="text-[#072a48] mb-4"
                              onClick={() => handleAddLLCMember()}
                            >
                              + Add another LLC Member
                            </button>
                          </>
                          <>
                            {formData.LLCOfficerFields.map((field, i) => (
                              <div key={"LLCOfficer" + i}>
                                <div>
                                  <label className="input-label">
                                    LLC Officer {i + 1} Name:
                                  </label>
                                  <input
                                    disabled={disabled}
                                    name="name"
                                    onChange={(e) =>
                                      handleChangeInputLLCOfficer(i, e)
                                    }
                                    onBlur={handleBlur}
                                    className="p-2 mb-3 w-full"
                                    type="text"
                                    value={field.name}
                                  />
                                </div>
                                <div>
                                  <label className="input-label">
                                    LLC Officer {i + 1} Title:
                                  </label>
                                  <input
                                    disabled={disabled}
                                    name="title"
                                    onChange={(e) =>
                                      handleChangeInputLLCOfficer(i, e)
                                    }
                                    onBlur={handleBlur}
                                    className="p-2 mb-3 w-full"
                                    type="text"
                                    value={field.title}
                                  />
                                </div>
                                <div>
                                  <label className="input-label">
                                    LLC Officer {i + 1} Current Business
                                    Insurance Amount:
                                  </label>
                                  <input
                                    disabled={disabled}
                                    name="businessInsuranceAmount"
                                    onChange={(e) =>
                                      handleChangeInputLLCOfficer(i, e)
                                    }
                                    onBlur={handleBlur}
                                    className="p-2 mb-3 w-full"
                                    type="text"
                                    value={field.businessInsuranceAmount}
                                  />
                                </div>
                                <button
                                  className="text-[#072a48] mb-4"
                                  onClick={(e) =>
                                    handleSubtractLLCOfficer(e, i)
                                  }
                                >
                                  - Remove LLC Officer {i + 1}
                                </button>
                              </div>
                            ))}
                            <button
                              className="text-[#072a48] mb-4"
                              onClick={() => handleAddLLCOfficer()}
                            >
                              + Add another LLC Officer
                            </button>
                          </>
                          <div>
                            <label className="input-label">
                              Has management in the LLC been vested as a
                              manager?:
                            </label>
                            <select
                              disabled={disabled}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  vestedManager: e.target.value,
                                });
                              }}
                              onBlur={(e) => {
                                handleSelectBlur(e);
                              }}
                              className="p-2 mb-3 w-full"
                              value={formData.vestedManager}
                            >
                              <option value="">Choose One</option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                            {/* If user selectes Yes */}
                            {formData.vestedManager === "Yes" && (
                              <>
                                <div>
                                  <label className="input-label">
                                    Manager Name:
                                  </label>
                                  <input
                                    disabled={disabled}
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        managerName: e.target.value,
                                      })
                                    }
                                    onBlur={(e) => handleBlur(e)}
                                    className="p-2 mb-3 w-full"
                                    type="text"
                                    value={formData.managerName}
                                  />
                                  <div>
                                    <label className="input-label">
                                      Manager Life Insurance Amount:
                                    </label>
                                    <input
                                      disabled={disabled}
                                      onChange={(e) =>
                                        setFormData({
                                          ...formData,
                                          managerLifeInsuranceAmount:
                                            e.target.value,
                                        })
                                      }
                                      onBlur={(e) => handleBlur(e)}
                                      className="p-2 mb-3 w-full"
                                      type="text"
                                      value={
                                        formData.managerLifeInsuranceAmount
                                      }
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      {/* If user selectes Partnership or Sole Proprietorship */}
                      {(formData.structure === "Partnership") |
                      (formData.structure === "Sole Proprietorship") ? (
                        <>
                          <div>
                            <label className="input-label">
                              Date of Incorporation:
                            </label>
                            <DatePicker
                              wrapperClassName="datePicker"
                              disabled={disabled}
                              selected={formData.dateOfIncorporation}
                              onChange={(date) => {
                                setFormData({
                                  ...formData,
                                  dateOfIncorporation: date,
                                });
                              }}
                              className="p-2 mb-3 w-full"
                            />
                          </div>
                          <div>
                            <label className="input-label">State:</label>
                            <select
                              disabled={disabled}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  corporationState: e.target.value,
                                })
                              }
                              onBlur={(e) => handleBlur(e)}
                              className="p-2 mb-3 w-full"
                              value={formData.corporationState}
                            >
                              <option value="">Choose One</option>
                              <option value="AL">Alabama</option>
                              <option value="AK">Alaska</option>
                              <option value="AZ">Arizona</option>
                              <option value="AR">Arkansas</option>
                              <option value="CA">California</option>
                              <option value="CO">Colorado</option>
                              <option value="CT">Connecticut</option>
                              <option value="DE">Delaware</option>
                              <option value="DC">District Of Columbia</option>
                              <option value="FL">Florida</option>
                              <option value="GA">Georgia</option>
                              <option value="HI">Hawaii</option>
                              <option value="ID">Idaho</option>
                              <option value="IL">Illinois</option>
                              <option value="IN">Indiana</option>
                              <option value="IA">Iowa</option>
                              <option value="KS">Kansas</option>
                              <option value="KY">Kentucky</option>
                              <option value="LA">Louisiana</option>
                              <option value="ME">Maine</option>
                              <option value="MD">Maryland</option>
                              <option value="MA">Massachusetts</option>
                              <option value="MI">Michigan</option>
                              <option value="MN">Minnesota</option>
                              <option value="MS">Mississippi</option>
                              <option value="MO">Missouri</option>
                              <option value="MT">Montana</option>
                              <option value="NE">Nebraska</option>
                              <option value="NV">Nevada</option>
                              <option value="NH">New Hampshire</option>
                              <option value="NJ">New Jersey</option>
                              <option value="NM">New Mexico</option>
                              <option value="NY">New York</option>
                              <option value="NC">North Carolina</option>
                              <option value="ND">North Dakota</option>
                              <option value="OH">Ohio</option>
                              <option value="OK">Oklahoma</option>
                              <option value="OR">Oregon</option>
                              <option value="PA">Pennsylvania</option>
                              <option value="RI">Rhode Island</option>
                              <option value="SC">South Carolina</option>
                              <option value="SD">South Dakota</option>
                              <option value="TN">Tennessee</option>
                              <option value="TX">Texas</option>
                              <option value="UT">Utah</option>
                              <option value="VT">Vermont</option>
                              <option value="VA">Virginia</option>
                              <option value="WA">Washington</option>
                              <option value="WV">West Virginia</option>
                              <option value="WI">Wisconsin</option>
                              <option value="WY">Wyoming</option>
                            </select>
                          </div>
                          <>
                            {formData.partnerFields.map((field, i) => (
                              <div key={"Partner" + field.name}>
                                <div>
                                  <label className="input-label">
                                    Partner/Owner {i + 1} Name:
                                  </label>
                                  <input
                                    disabled={disabled}
                                    name="name"
                                    onChange={(e) =>
                                      handleChangeInputPartner(i, e)
                                    }
                                    onBlur={handleBlur}
                                    className="p-2 mb-3 w-full"
                                    type="text"
                                    value={field.name}
                                  />
                                </div>
                                <div>
                                  <label className="input-label">
                                    Partner/Owner {i + 1} Title:
                                  </label>
                                  <input
                                    disabled={disabled}
                                    name="title"
                                    onChange={(e) =>
                                      handleChangeInputPartner(i, e)
                                    }
                                    onBlur={handleBlur}
                                    className="p-2 mb-3 w-full"
                                    type="text"
                                    value={field.title}
                                  />
                                </div>
                                <div>
                                  <label className="input-label">
                                    Partner/Owner {i + 1} Business Life
                                    Insurance Amount:
                                  </label>
                                  <input
                                    disabled={disabled}
                                    name="insuranceAmount"
                                    onChange={(e) =>
                                      handleChangeInputPartner(i, e)
                                    }
                                    onBlur={handleBlur}
                                    className="p-2 mb-3 w-full"
                                    type="text"
                                    value={field.insuranceAmount}
                                  />
                                </div>
                                <button
                                  className="text-[#072a48] mb-4"
                                  onClick={(e) => handleSubtractPartner(e, i)}
                                >
                                  - Remove Partner {i + 1}
                                </button>
                              </div>
                            ))}
                            <button
                              className="text-[#072a48] mb-4"
                              onClick={() => handleAddPartner()}
                            >
                              + Add another Partner
                            </button>
                          </>
                          <div>
                            <label className="input-label">
                              Proprietorship Name:
                            </label>
                            <input
                              disabled={disabled}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  proprietorshipName: e.target.value,
                                })
                              }
                              onBlur={(e) => handleBlur(e)}
                              className="p-2 mb-3 w-full"
                              type="text"
                              value={formData.proprietorshipName}
                            />
                          </div>
                          <div>
                            <label className="input-label">SSN/FEIN:</label>
                            <input
                              disabled={disabled}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  SSNFEIN: e.target.value,
                                })
                              }
                              onBlur={(e) => handleBlur(e)}
                              className="p-2 mb-3 w-full"
                              type="text"
                              value={formData.SSNFEIN}
                            />
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div>
                      <label className="input-label">
                        In the last 5 years, has there been changes to the
                        agency ownership:
                      </label>
                      <select
                        disabled={disabled}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            changedAgencyOwnership: e.target.value,
                          });
                        }}
                        onBlur={(e) => {
                          handleSelectBlur(e);
                        }}
                        className="p-2 mb-3 w-full"
                        value={formData.changedAgencyOwnership}
                      >
                        <option value="">Choose One</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                      {/* If user selectes Yes */}
                      {formData.changedAgencyOwnership === "Yes" && (
                        <>
                          <div>
                            <label className="input-label">
                              Please explain:
                            </label>
                            <textarea
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  whyChangedAgencyOwnership: e.target.value,
                                })
                              }
                              onBlur={(e) => handleBlur(e)}
                              className="p-2 mb-3 w-full"
                              value={formData.whyChangedAgencyOwnership}
                            ></textarea>
                          </div>
                        </>
                      )}
                    </div>
                    <div>
                      <label className="input-label">
                        In the last 5 years, has there been a merger with or
                        purchase of another agency:
                      </label>
                      <select
                        disabled={disabled}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            mergerOrPurchase: e.target.value,
                          });
                        }}
                        onBlur={(e) => {
                          handleSelectBlur(e);
                        }}
                        className="p-2 mb-3 w-full"
                        value={formData.mergerOrPurchase}
                      >
                        <option value="">Choose One</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                      {/* If user selectes Yes */}
                      {formData.mergerOrPurchase === "Yes" && (
                        <>
                          <div>
                            <label className="input-label">
                              Please explain:
                            </label>
                            <textarea
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  whyMergerOrPurchase: e.target.value,
                                })
                              }
                              onBlur={(e) => handleBlur(e)}
                              className="p-2 mb-3 w-full"
                              value={formData.whyMergerOrPurchase}
                            ></textarea>
                          </div>
                        </>
                      )}
                    </div>
                    <div>
                      <label className="input-label">
                        In the last 5 years, has there been a change in the
                        status of agency licenses:
                      </label>
                      <select
                        disabled={disabled}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            changeInLicenses: e.target.value,
                          });
                        }}
                        onBlur={(e) => {
                          handleSelectBlur(e);
                        }}
                        className="p-2 mb-3 w-full"
                        value={formData.changeInLicenses}
                      >
                        <option value="">Choose One</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                      {/* If user selectes Yes */}
                      {formData.changeInLicenses === "Yes" && (
                        <>
                          <div>
                            <label className="input-label">
                              Please explain:
                            </label>
                            <textarea
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  whyChangeInLicenses: e.target.value,
                                })
                              }
                              onBlur={(e) => handleBlur(e)}
                              className="p-2 mb-3 w-full"
                              value={formData.whyChangeInLicenses}
                            ></textarea>
                          </div>
                        </>
                      )}
                    </div>
                    <div>
                      <label className="input-label">
                        In the last 5 years, has there been a change in upper
                        management:
                      </label>
                      <select
                        disabled={disabled}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            changeInManagement: e.target.value,
                          });
                        }}
                        onBlur={(e) => {
                          handleSelectBlur(e);
                        }}
                        className="p-2 mb-3 w-full"
                        value={formData.changeInManagement}
                      >
                        <option value="">Choose One</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                      {/* If user selectes Yes */}
                      {formData.changeInManagement === "Yes" && (
                        <>
                          <div>
                            <label className="input-label">
                              Please explain:
                            </label>
                            <textarea
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  whyChangeInManagement: e.target.value,
                                })
                              }
                              onBlur={(e) => handleBlur(e)}
                              className="p-2 mb-3 w-full"
                              value={formData.whyChangeInManagement}
                            ></textarea>
                          </div>
                        </>
                      )}
                    </div>

                    <article className="back-next-section text-center">
                      {formData.status === "Incomplete" ||
                      formData.status === "" ? (
                        <>
                          <button
                            onClick={() => {
                              setValue(value + 1);
                              saveApplication();
                            }}
                            className="button-2"
                          >
                            Save & Continue
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            onClick={() => {
                              setValue(value + 1);
                            }}
                            className="button-2"
                          >
                            Next
                          </button>
                        </>
                      )}
                      <button
                        onClick={() => setValue(value - 1)}
                        className="button-1"
                        disabled={value === 0}
                      >
                        Back
                      </button>
                    </article>
                  </TabPanel>
                  {/* Continued... */}
                  <TabPanel value={value} index={4} className="bg-gray-100">
                    <h1 className="title-1">Continued...</h1>
                    <div>
                      <label className="input-label">
                        Have any of the agency owners, officers, managers,
                        members, or producers been subject to any state
                        insurance department complaints, fines, or disciplinary
                        actions in the past five years:
                      </label>
                      <select
                        disabled={disabled}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            finesOrDisciplinary: e.target.value,
                          });
                        }}
                        onBlur={(e) => {
                          handleSelectBlur(e);
                        }}
                        className="p-2 mb-3 w-full"
                        value={formData.finesOrDisciplinary}
                      >
                        <option value="">Choose One</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                      {/* If user selectes Yes */}
                      {formData.finesOrDisciplinary === "Yes" && (
                        <>
                          <div>
                            <label className="input-label">
                              Please explain:
                            </label>
                            <textarea
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  whyFinesOrDisciplinary: e.target.value,
                                })
                              }
                              onBlur={(e) => handleBlur(e)}
                              className="p-2 mb-3 w-full"
                              value={formData.whyFinesOrDisciplinary}
                            ></textarea>
                          </div>
                        </>
                      )}
                    </div>
                    <div>
                      <label className="input-label">
                        How are producers compensated:
                      </label>
                      <select
                        disabled={disabled}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            howAgentsCompensated: e.target.value,
                          });
                        }}
                        onBlur={(e) => {
                          handleSelectBlur(e);
                        }}
                        className="p-2 mb-3 w-full"
                        value={formData.howAgentsCompensated}
                      >
                        <option value="">Choose One</option>
                        <option value="Commission">Commission</option>
                        <option value="Salary">Salary</option>
                        <option value="SalaryAndCommission">
                          Salary + Commission
                        </option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                    <div>
                      <label className="input-label">
                        Does the agency currently have any buy/sell agreements
                        in effect:
                      </label>
                      <select
                        disabled={disabled}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            buySellAgreements: e.target.value,
                          });
                        }}
                        onBlur={(e) => {
                          handleSelectBlur(e);
                        }}
                        className="p-2 mb-3 w-full"
                        value={formData.buySellAgreements}
                      >
                        <option value="">Choose One</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>

                    <div>
                      <label className="input-label">
                        What is your geographic marketing area:
                      </label>
                      <textarea
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            marketingArea: e.target.value,
                          })
                        }
                        onBlur={(e) => handleBlur(e)}
                        className="p-2 mb-3 w-full"
                        value={formData.marketingArea}
                      ></textarea>
                    </div>
                    <div>
                      <label className="input-label">
                        Who are your major competing insurance agencies for
                        truck business:
                      </label>
                      <textarea
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            majorCompetitors: e.target.value,
                          })
                        }
                        onBlur={(e) => handleBlur(e)}
                        className="p-2 mb-3 w-full"
                        value={formData.majorCompetitors}
                      ></textarea>
                    </div>
                    <div>
                      <label className="input-label">
                        Who do you consider your main agency competition for
                        truck business:
                      </label>
                      <textarea
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            mainCompetition: e.target.value,
                          })
                        }
                        onBlur={(e) => handleBlur(e)}
                        className="p-2 mb-3 w-full"
                        value={formData.mainCompetition}
                      ></textarea>
                    </div>
                    <div>
                      <label className="input-label">
                        Do you broker truck business to other agencies:
                      </label>
                      <select
                        disabled={disabled}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            brokerTruckBusiness: e.target.value,
                          });
                        }}
                        onBlur={(e) => {
                          handleSelectBlur(e);
                        }}
                        className="p-2 mb-3 w-full"
                        value={formData.brokerTruckBusiness}
                      >
                        <option value="">Choose One</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                    <div>
                      <label className="input-label">
                        Are you writing surplus lines presently:
                      </label>
                      <select
                        disabled={disabled}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            writingSurplusLines: e.target.value,
                          });
                        }}
                        onBlur={(e) => {
                          handleSelectBlur(e);
                        }}
                        className="p-2 mb-3 w-full"
                        value={formData.writingSurplusLines}
                      >
                        <option value="">Choose One</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                    <div>
                      <label className="input-label">
                        Do you have a premium trust account:
                      </label>
                      <select
                        disabled={disabled}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            hasPremiumTrust: e.target.value,
                          });
                        }}
                        onBlur={(e) => {
                          handleSelectBlur(e);
                        }}
                        className="p-2 mb-3 w-full"
                        value={formData.hasPremiumTrust}
                      >
                        <option value="">Choose One</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                      {/* If user selectes Yes */}
                      {formData.hasPremiumTrust === "Yes" && (
                        <>
                          <div>
                            <label className="input-label">
                              Name, City, & State of Bank that holds trust:
                            </label>
                            <input
                              disabled={disabled}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  trustBank: e.target.value,
                                })
                              }
                              onBlur={(e) => handleBlur(e)}
                              className="p-2 mb-3 w-full"
                              type="text"
                              value={formData.trustBank}
                            />
                          </div>
                        </>
                      )}
                      {/* If user selectes No */}
                      {formData.hasPremiumTrust === "No" && (
                        <>
                          <div>
                            <label className="input-label">
                              What method do you use to separate premiums from
                              your own operating funds:
                            </label>
                            <input
                              disabled={disabled}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  methodSeparateFunds: e.target.value,
                                })
                              }
                              onBlur={(e) => handleBlur(e)}
                              className="p-2 mb-3 w-full"
                              type="text"
                              value={formData.methodSeparateFunds}
                            />
                          </div>
                        </>
                      )}
                    </div>

                    <article className="back-next-section text-center">
                      {formData.status === "Incomplete" ||
                      formData.status === "" ? (
                        <>
                          <button
                            onClick={() => {
                              setValue(value + 1);
                              saveApplication();
                            }}
                            className="button-2"
                          >
                            Save & Continue
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            onClick={() => {
                              setValue(value + 1);
                            }}
                            className="button-2"
                          >
                            Next
                          </button>
                        </>
                      )}
                      <button
                        onClick={() => setValue(value - 1)}
                        className="button-1"
                        disabled={value === 0}
                      >
                        Back
                      </button>
                    </article>
                  </TabPanel>
                  {/* References */}
                  <TabPanel value={value} index={5} className="bg-gray-100">
                    <h1 className="title-1">References</h1>
                    <div>
                      <label className="input-label">
                        Bank Reference Name:
                      </label>
                      <input
                        disabled={disabled}
                        onChange={(e) =>
                          setFormData({ ...formData, bankName: e.target.value })
                        }
                        onBlur={(e) => handleBlur(e)}
                        className="p-2 mb-3 w-full"
                        type="text"
                        value={formData.bankName}
                      />
                    </div>
                    <div>
                      <label className="input-label">
                        Bank Reference Address:
                      </label>
                      <input
                        disabled={disabled}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            bankAddress: e.target.value,
                          })
                        }
                        onBlur={(e) => handleBlur(e)}
                        className="p-2 mb-3 w-full"
                        type="text"
                        value={formData.bankAddress}
                      />
                    </div>
                    <div>
                      <label className="input-label">
                        Bank Reference City, State, Zip:
                      </label>
                      <input
                        disabled={disabled}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            bankCityStateZip: e.target.value,
                          })
                        }
                        onBlur={(e) => handleBlur(e)}
                        className="p-2 mb-3 w-full"
                        type="text"
                        value={formData.bankCityStateZip}
                      />
                    </div>
                    <div>
                      <label className="input-label">
                        Bank Reference Phone Number:
                      </label>
                      <input
                        disabled={disabled}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            bankPhone: e.target.value,
                          })
                        }
                        onBlur={(e) => handleBlur(e)}
                        className="p-2 mb-3 w-full"
                        type="text"
                        value={formData.bankPhone}
                      />
                    </div>
                    <div>
                      <label className="input-label">
                        Bank Reference Fax Number:
                      </label>
                      <input
                        disabled={disabled}
                        onChange={(e) =>
                          setFormData({ ...formData, bankFax: e.target.value })
                        }
                        onBlur={(e) => handleBlur(e)}
                        className="p-2 mb-3 w-full"
                        type="text"
                        value={formData.bankFax}
                      />
                    </div>
                    <div>
                      <label className="input-label">
                        Bank Reference Contact Name:
                      </label>
                      <input
                        disabled={disabled}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            bankContactName: e.target.value,
                          })
                        }
                        onBlur={(e) => handleBlur(e)}
                        className="p-2 mb-3 w-full"
                        type="text"
                        value={formData.bankContactName}
                      />
                    </div>
                    <div>
                      <label className="input-label">
                        Bank Reference Contact email:
                      </label>
                      <input
                        disabled={disabled}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            bankContactEmail: e.target.value,
                          })
                        }
                        onBlur={(e) => handleBlur(e)}
                        className="p-2 mb-3 w-full"
                        type="text"
                        value={formData.bankContactEmail}
                      />
                    </div>
                    <div>
                      <label className="input-label">
                        Business Reference Name:
                      </label>
                      <input
                        disabled={disabled}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            businessName: e.target.value,
                          })
                        }
                        onBlur={(e) => handleBlur(e)}
                        className="p-2 mb-3 w-full"
                        type="text"
                        value={formData.businessName}
                      />
                    </div>
                    <div>
                      <label className="input-label">
                        Business Reference Address:
                      </label>
                      <input
                        disabled={disabled}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            businessAddress: e.target.value,
                          })
                        }
                        onBlur={(e) => handleBlur(e)}
                        className="p-2 mb-3 w-full"
                        type="text"
                        value={formData.businessAddress}
                      />
                    </div>
                    <div>
                      <label className="input-label">
                        Business Reference City, State, Zip:
                      </label>
                      <input
                        disabled={disabled}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            businessCityStateZip: e.target.value,
                          })
                        }
                        onBlur={(e) => handleBlur(e)}
                        className="p-2 mb-3 w-full"
                        type="text"
                        value={formData.businessCityStateZip}
                      />
                    </div>
                    <div>
                      <label className="input-label">
                        Business Reference Phone Number:
                      </label>
                      <input
                        disabled={disabled}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            businessPhone: e.target.value,
                          })
                        }
                        onBlur={(e) => handleBlur(e)}
                        className="p-2 mb-3 w-full"
                        type="text"
                        value={formData.businessPhone}
                      />
                    </div>
                    <div>
                      <label className="input-label">
                        Business Reference Fax Number:
                      </label>
                      <input
                        disabled={disabled}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            businessFax: e.target.value,
                          })
                        }
                        onBlur={(e) => handleBlur(e)}
                        className="p-2 mb-3 w-full"
                        type="text"
                        value={formData.businessFax}
                      />
                    </div>
                    <div>
                      <label className="input-label">
                        Business Reference Contact Name:
                      </label>
                      <input
                        disabled={disabled}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            businessContactName: e.target.value,
                          })
                        }
                        onBlur={(e) => handleBlur(e)}
                        className="p-2 mb-3 w-full"
                        type="text"
                        value={formData.businessContactName}
                      />
                    </div>
                    <div>
                      <label className="input-label">
                        Business Reference Contact email:
                      </label>
                      <input
                        disabled={disabled}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            businessContactEmail: e.target.value,
                          })
                        }
                        onBlur={(e) => handleBlur(e)}
                        className="p-2 mb-3 w-full"
                        type="text"
                        value={formData.businessContactEmail}
                      />
                    </div>
                    <div>
                      <label className="input-label">
                        Business Reference 2 Name:
                      </label>
                      <input
                        disabled={disabled}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            business2Name: e.target.value,
                          })
                        }
                        onBlur={(e) => handleBlur(e)}
                        className="p-2 mb-3 w-full"
                        type="text"
                        value={formData.business2Name}
                      />
                    </div>
                    <div>
                      <label className="input-label">
                        Business Reference 2 Address:
                      </label>
                      <input
                        disabled={disabled}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            business2Address: e.target.value,
                          })
                        }
                        onBlur={(e) => handleBlur(e)}
                        className="p-2 mb-3 w-full"
                        type="text"
                        value={formData.business2Address}
                      />
                    </div>
                    <div>
                      <label className="input-label">
                        Business Reference 2 City, State, Zip:
                      </label>
                      <input
                        disabled={disabled}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            business2CityStateZip: e.target.value,
                          })
                        }
                        onBlur={(e) => handleBlur(e)}
                        className="p-2 mb-3 w-full"
                        type="text"
                        value={formData.business2CityStateZip}
                      />
                    </div>
                    <div>
                      <label className="input-label">
                        Business Reference 2 Phone Number:
                      </label>
                      <input
                        disabled={disabled}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            business2Phone: e.target.value,
                          })
                        }
                        onBlur={(e) => handleBlur(e)}
                        className="p-2 mb-3 w-full"
                        type="text"
                        value={formData.business2Phone}
                      />
                    </div>
                    <div>
                      <label className="input-label">
                        Business Reference 2 Fax Number:
                      </label>
                      <input
                        disabled={disabled}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            business2Fax: e.target.value,
                          })
                        }
                        onBlur={(e) => handleBlur(e)}
                        className="p-2 mb-3 w-full"
                        type="text"
                        value={formData.business2Fax}
                      />
                    </div>
                    <div>
                      <label className="input-label">
                        Business Reference 2 Contact Name:
                      </label>
                      <input
                        disabled={disabled}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            business2ContactName: e.target.value,
                          })
                        }
                        onBlur={(e) => handleBlur(e)}
                        className="p-2 mb-3 w-full"
                        type="text"
                        value={formData.business2ContactName}
                      />
                    </div>
                    <div>
                      <label className="input-label">
                        Business Reference 2 Contact email:
                      </label>
                      <input
                        disabled={disabled}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            business2ContactEmail: e.target.value,
                          })
                        }
                        onBlur={(e) => handleBlur(e)}
                        className="p-2 mb-3 w-full"
                        type="text"
                        value={formData.business2ContactEmail}
                      />
                    </div>

                    <article className="back-next-section text-center">
                      {formData.status === "Incomplete" ||
                      formData.status === "" ? (
                        <>
                          <button
                            onClick={() => {
                              setValue(value + 1);
                              saveApplication();
                            }}
                            className="button-2"
                          >
                            Save & Continue
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            onClick={() => {
                              setValue(value + 1);
                            }}
                            className="button-2"
                          >
                            Next
                          </button>
                        </>
                      )}
                      <button
                        onClick={() => setValue(value - 1)}
                        className="button-1"
                        disabled={value === 0}
                      >
                        Back
                      </button>
                    </article>
                  </TabPanel>
                  {/* Documentation */}
                  <TabPanel value={value} index={6} className="bg-gray-100">
                    <h1 className="title-1">Documentation</h1>
                    <p className="text-lg mt-2 mb-3 max-w-[600px]">
                      For each document listed below, first click "Choose File"
                      to select a file, then the respective "Upload" button to
                      upload the file.
                    </p>
                    <div className="mb-3">
                      <label className="input-label">
                        {formData.TXLicenseURL === "" ? (
                          <span className="text-[#8B0000]">
                            (Not Uploaded){" "}
                          </span>
                        ) : (
                          <span className="text-green-600">(Uploaded) </span>
                        )}
                        Texas Surplus Lines License:
                      </label>
                      <div className="ml-5">
                        {formData.TXLicenseURL !== "" ? (
                          <div>
                            File Uploaded, you may{" "}
                            <a
                              className="text-[#072a48]"
                              target="_blank"
                              rel="noreferrer"
                              href={formData.TXLicenseURL}
                            >
                              View
                            </a>{" "}
                            or{" "}
                            <button
                              disabled={disabled}
                              className="text-[#8B0000]"
                              onClick={(e) =>
                                setFormData({ ...formData, TXLicenseURL: "" })
                              }
                            >
                              Delete
                            </button>{" "}
                            the file.
                          </div>
                        ) : (
                          <form
                            disabled={disabled}
                            onSubmit={(e) => {
                              handleSubmit(e, "TXLicenseURL");
                            }}
                            className="form"
                          >
                            <input disabled={disabled} type="file" />
                            <button
                              type="submit"
                              className="mt-2 bg-[#072a48] px-3 py-2 rounded font-bold text-white"
                            >
                              Upload
                            </button>
                          </form>
                        )}
                      </div>
                    </div>
                    <div className="mb-3">
                      <label className="input-label">
                        {formData.NMLicenseURL === "" ? (
                          <span className="text-[#8B0000]">
                            (Not Uploaded){" "}
                          </span>
                        ) : (
                          <span className="text-green-600">(Uploaded) </span>
                        )}
                        New Mexico Surplus Lines License:
                      </label>
                      <div className="ml-5">
                        {formData.NMLicenseURL !== "" ? (
                          <div>
                            File Uploaded, you may{" "}
                            <a
                              className="text-[#072a48]"
                              target="_blank"
                              rel="noreferrer"
                              href={formData.NMLicenseURL}
                            >
                              View
                            </a>{" "}
                            or{" "}
                            <button
                              disabled={disabled}
                              className="text-[#8B0000]"
                              onClick={(e) =>
                                setFormData({ ...formData, NMLicenseURL: "" })
                              }
                            >
                              Delete
                            </button>{" "}
                            the file.
                          </div>
                        ) : (
                          <form
                            disabled={disabled}
                            onSubmit={(e) => handleSubmit(e, "NMLicenseURL")}
                            className="form"
                          >
                            <input disabled={disabled} type="file" />
                            <button
                              type="submit"
                              className="mt-2 bg-[#072a48] px-3 py-2 rounded font-bold text-white"
                            >
                              Upload
                            </button>
                          </form>
                        )}
                      </div>
                    </div>
                    <div className="mb-3">
                      <label className="input-label">
                        {formData.AZLicenseURL === "" ? (
                          <span className="text-[#8B0000]">
                            (Not Uploaded){" "}
                          </span>
                        ) : (
                          <span className="text-green-600">(Uploaded) </span>
                        )}
                        Arizona Surplus Lines License:
                      </label>
                      <div className="ml-5">
                        {formData.AZLicenseURL !== "" ? (
                          <div>
                            File Uploaded, you may{" "}
                            <a
                              className="text-[#072a48]"
                              target="_blank"
                              rel="noreferrer"
                              href={formData.AZLicenseURL}
                            >
                              View
                            </a>{" "}
                            or{" "}
                            <button
                              disabled={disabled}
                              className="text-[#8B0000]"
                              onClick={(e) =>
                                setFormData({ ...formData, AZLicenseURL: "" })
                              }
                            >
                              Delete
                            </button>{" "}
                            the file.
                          </div>
                        ) : (
                          <form
                            disabled={disabled}
                            onSubmit={(e) => handleSubmit(e, "AZLicenseURL")}
                            className="form"
                          >
                            <input disabled={disabled} type="file" />
                            <button
                              type="submit"
                              className="mt-2 bg-[#072a48] px-3 py-2 rounded font-bold text-white"
                            >
                              Upload
                            </button>
                          </form>
                        )}
                      </div>
                    </div>
                    <div className="mb-3">
                      <label className="input-label">
                        {formData.CALicenseURL === "" ? (
                          <span className="text-[#8B0000]">
                            (Not Uploaded){" "}
                          </span>
                        ) : (
                          <span className="text-green-600">(Uploaded) </span>
                        )}
                        California Surplus Lines License:
                      </label>
                      <div className="ml-5">
                        {formData.CALicenseURL !== "" ? (
                          <div>
                            File Uploaded, you may{" "}
                            <a
                              className="text-[#072a48]"
                              target="_blank"
                              rel="noreferrer"
                              href={formData.CALicenseURL}
                            >
                              View
                            </a>{" "}
                            or{" "}
                            <button
                              disabled={disabled}
                              className="text-[#8B0000]"
                              onClick={(e) =>
                                setFormData({ ...formData, CALicenseURL: "" })
                              }
                            >
                              Delete
                            </button>{" "}
                            the file.
                          </div>
                        ) : (
                          <form
                            onSubmit={(e) => handleSubmit(e, "CALicenseURL")}
                            className="form"
                          >
                            <input disabled={disabled} type="file" />
                            <button
                              type="submit"
                              className="mt-2 bg-[#072a48] px-3 py-2 rounded font-bold text-white"
                            >
                              Upload
                            </button>
                          </form>
                        )}
                      </div>
                    </div>
                    <div className="mb-3">
                      <label className="input-label">
                        {formData.balanceSheet1URL === "" ? (
                          <span className="text-[#8B0000]">
                            (Not Uploaded){" "}
                          </span>
                        ) : (
                          <span className="text-green-600">(Uploaded) </span>
                        )}
                        Year End Balance Sheet 1 Year Prior:
                      </label>
                      <div className="ml-5">
                        {formData.balanceSheet1URL !== "" ? (
                          <div>
                            File Uploaded, you may{" "}
                            <a
                              className="text-[#072a48]"
                              target="_blank"
                              rel="noreferrer"
                              href={formData.balanceSheet1URL}
                            >
                              View
                            </a>{" "}
                            or{" "}
                            <button
                              disabled={disabled}
                              className="text-[#8B0000]"
                              onClick={(e) =>
                                setFormData({
                                  ...formData,
                                  balanceSheet1URL: "",
                                })
                              }
                            >
                              Delete
                            </button>{" "}
                            the file.
                          </div>
                        ) : (
                          <form
                            onSubmit={(e) =>
                              handleSubmit(e, "balanceSheet1URL")
                            }
                            className="form"
                          >
                            <input disabled={disabled} type="file" />
                            <button
                              type="submit"
                              className="mt-2 bg-[#072a48] px-3 py-2 rounded font-bold text-white"
                            >
                              Upload
                            </button>
                          </form>
                        )}
                      </div>
                    </div>
                    <div className="mb-3">
                      <label className="input-label">
                        {formData.balanceSheet2URL === "" ? (
                          <span className="text-[#8B0000]">
                            (Not Uploaded){" "}
                          </span>
                        ) : (
                          <span className="text-green-600">(Uploaded) </span>
                        )}
                        Year End Balance Sheet 2 Years Prior:
                      </label>
                      <div className="ml-5">
                        {formData.balanceSheet2URL !== "" ? (
                          <div>
                            File Uploaded, you may{" "}
                            <a
                              className="text-[#072a48]"
                              target="_blank"
                              rel="noreferrer"
                              href={formData.balanceSheet2URL}
                            >
                              View
                            </a>{" "}
                            or{" "}
                            <button
                              disabled={disabled}
                              className="text-[#8B0000]"
                              onClick={(e) => {
                                setFormData({
                                  ...formData,
                                  balanceSheet2URL: "",
                                });
                              }}
                            >
                              Delete
                            </button>{" "}
                            the file.
                          </div>
                        ) : (
                          <form
                            onSubmit={(e) =>
                              handleSubmit(e, "balanceSheet2URL")
                            }
                            className="form"
                          >
                            <input disabled={disabled} type="file" />
                            <button
                              type="submit"
                              className="mt-2 bg-[#072a48] px-3 py-2 rounded font-bold text-white"
                            >
                              Upload
                            </button>
                          </form>
                        )}
                      </div>
                    </div>
                    <div className="mb-3">
                      <label className="input-label">
                        {formData.incomeStatement1URL === "" ? (
                          <span className="text-[#8B0000]">
                            (Not Uploaded){" "}
                          </span>
                        ) : (
                          <span className="text-green-600">(Uploaded) </span>
                        )}
                        Year End Income Statement 1 Year Prior:
                      </label>
                      <div className="ml-5">
                        {formData.incomeStatement1URL !== "" ? (
                          <div>
                            File Uploaded, you may{" "}
                            <a
                              className="text-[#072a48]"
                              target="_blank"
                              rel="noreferrer"
                              href={formData.incomeStatement1URL}
                            >
                              View
                            </a>{" "}
                            or{" "}
                            <button
                              disabled={disabled}
                              className="text-[#8B0000]"
                              onClick={(e) =>
                                setFormData({
                                  ...formData,
                                  incomeStatement1URL: "",
                                })
                              }
                            >
                              Delete
                            </button>{" "}
                            the file.
                          </div>
                        ) : (
                          <form
                            onSubmit={(e) =>
                              handleSubmit(e, "incomeStatement1URL")
                            }
                            className="form"
                          >
                            <input disabled={disabled} type="file" />
                            <button
                              type="submit"
                              className="mt-2 bg-[#072a48] px-3 py-2 rounded font-bold text-white"
                            >
                              Upload
                            </button>
                          </form>
                        )}
                      </div>
                    </div>
                    <div className="mb-3">
                      <label className="input-label">
                        {formData.incomeStatement2URL === "" ? (
                          <span className="text-[#8B0000]">
                            (Not Uploaded){" "}
                          </span>
                        ) : (
                          <span className="text-green-600">(Uploaded) </span>
                        )}
                        Year End Income Statement 2 Years Prior:
                      </label>
                      <div className="ml-5">
                        {formData.incomeStatement2URL !== "" ? (
                          <div>
                            File Uploaded, you may{" "}
                            <a
                              className="text-[#072a48]"
                              target="_blank"
                              rel="noreferrer"
                              href={formData.incomeStatement2URL}
                            >
                              View
                            </a>{" "}
                            or{" "}
                            <button
                              disabled={disabled}
                              className="text-[#8B0000]"
                              onClick={(e) =>
                                setFormData({
                                  ...formData,
                                  incomeStatement2URL: "",
                                })
                              }
                            >
                              Delete
                            </button>{" "}
                            the file.
                          </div>
                        ) : (
                          <form
                            onSubmit={(e) =>
                              handleSubmit(e, "incomeStatement2URL")
                            }
                            className="form"
                          >
                            <input disabled={disabled} type="file" />
                            <button
                              type="submit"
                              className="mt-2 bg-[#072a48] px-3 py-2 rounded font-bold text-white"
                            >
                              Upload
                            </button>
                          </form>
                        )}
                      </div>
                    </div>
                    <div className="mb-3">
                      <label className="input-label">
                        {formData.mostRecentbalanceSheetURL === "" ? (
                          <span className="text-[#8B0000]">
                            (Not Uploaded){" "}
                          </span>
                        ) : (
                          <span className="text-green-600">(Uploaded) </span>
                        )}
                        Most Recent Quarterly Balance Sheet:
                      </label>
                      <div className="ml-5">
                        {formData.mostRecentbalanceSheetURL !== "" ? (
                          <div>
                            File Uploaded, you may{" "}
                            <a
                              className="text-[#072a48]"
                              target="_blank"
                              rel="noreferrer"
                              href={formData.mostRecentbalanceSheetURL}
                            >
                              View
                            </a>{" "}
                            or{" "}
                            <button
                              disabled={disabled}
                              className="text-[#8B0000]"
                              onClick={(e) =>
                                setFormData({
                                  ...formData,
                                  mostRecentbalanceSheetURL: "",
                                })
                              }
                            >
                              Delete
                            </button>{" "}
                            the file.
                          </div>
                        ) : (
                          <form
                            onSubmit={(e) =>
                              handleSubmit(e, "mostRecentbalanceSheetURL")
                            }
                            className="form"
                          >
                            <input disabled={disabled} type="file" />
                            <button
                              type="submit"
                              className="mt-2 bg-[#072a48] px-3 py-2 rounded font-bold text-white"
                            >
                              Upload
                            </button>
                          </form>
                        )}
                      </div>
                    </div>
                    <div className="mb-3">
                      <label className="input-label">
                        {formData.mostRecentIncomeStatementURL === "" ? (
                          <span className="text-[#8B0000]">
                            (Not Uploaded){" "}
                          </span>
                        ) : (
                          <span className="text-green-600">(Uploaded) </span>
                        )}
                        Most Recent Quarterly Income Statement:
                      </label>
                      <div className="ml-5">
                        {formData.mostRecentIncomeStatementURL !== "" ? (
                          <div>
                            File Uploaded, you may{" "}
                            <a
                              className="text-[#072a48]"
                              target="_blank"
                              rel="noreferrer"
                              href={formData.mostRecentIncomeStatementURL}
                            >
                              View
                            </a>{" "}
                            or{" "}
                            <button
                              disabled={disabled}
                              className="text-[#8B0000]"
                              onClick={(e) =>
                                setFormData({
                                  ...formData,
                                  mostRecentIncomeStatementURL: "",
                                })
                              }
                            >
                              Delete
                            </button>{" "}
                            the file.
                          </div>
                        ) : (
                          <form
                            onSubmit={(e) =>
                              handleSubmit(e, "mostRecentIncomeStatementURL")
                            }
                            className="form"
                          >
                            <input disabled={disabled} type="file" />
                            <button
                              type="submit"
                              className="mt-2 bg-[#072a48] px-3 py-2 rounded font-bold text-white"
                            >
                              Upload
                            </button>
                          </form>
                        )}
                      </div>
                    </div>
                    <div className="mb-3">
                      <label className="input-label">
                        {formData.EOcertificateURL === "" ? (
                          <span className="text-[#8B0000]">
                            (Not Uploaded){" "}
                          </span>
                        ) : (
                          <span className="text-green-600">(Uploaded) </span>
                        )}
                        Current E&O Certificate of Insurance:
                      </label>
                      <div className="ml-5">
                        {formData.EOcertificateURL !== "" ? (
                          <div>
                            File Uploaded, you may{" "}
                            <a
                              className="text-[#072a48]"
                              target="_blank"
                              rel="noreferrer"
                              href={formData.EOcertificateURL}
                            >
                              View
                            </a>{" "}
                            or{" "}
                            <button
                              disabled={disabled}
                              className="text-[#8B0000]"
                              onClick={(e) =>
                                setFormData({
                                  ...formData,
                                  EOcertificateURL: "",
                                })
                              }
                            >
                              Delete
                            </button>{" "}
                            the file.
                          </div>
                        ) : (
                          <form
                            onSubmit={(e) =>
                              handleSubmit(e, "EOcertificateURL")
                            }
                            className="form"
                          >
                            <input disabled={disabled} type="file" />
                            <button
                              type="submit"
                              className="mt-2 bg-[#072a48] px-3 py-2 rounded font-bold text-white"
                            >
                              Upload
                            </button>
                          </form>
                        )}
                      </div>
                    </div>

                    <article className="back-next-section text-center">
                      {formData.status === "Incomplete" ||
                      formData.status === "" ? (
                        <>
                          <button
                            onClick={() => {
                              setValue(value + 1);
                              saveApplication();
                            }}
                            className="button-2"
                          >
                            Save & Continue
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            onClick={() => {
                              setValue(value + 1);
                            }}
                            className="button-2"
                          >
                            Next
                          </button>
                        </>
                      )}
                      <button
                        onClick={() => setValue(value - 1)}
                        className="button-1"
                        disabled={value === 0}
                      >
                        Back
                      </button>
                    </article>
                  </TabPanel>
                  {/* Comments & Submit */}
                  {formData.status !== "Pending" && (
                    <TabPanel
                      value={value}
                      index={7}
                      className="bg-gray-100 text-center"
                    >
                      <>
                        <h1 className="title-1">Save or Submit</h1>
                        {/* Validation */}
                        <div id="issues">
                          {formData.agencyName === "" && (
                            <div>Agency Name field is incomplete.</div>
                          )}
                          {formData.address === "" && (
                            <div>Agency Address field is incomplete.</div>
                          )}
                          {formData.city === "" && (
                            <div>Agency City field is incomplete.</div>
                          )}
                          {formData.state === "" && (
                            <div>Agency State field is incomplete.</div>
                          )}
                          {formData.zip === "" && (
                            <div>Agency Zip field is incomplete.</div>
                          )}
                          {formData.phone === "" && (
                            <div>Agency Phone field is incomplete.</div>
                          )}
                          {formData.fax === "" && (
                            <div>Agency Fax field is incomplete.</div>
                          )}
                          {formData.webAddress === "" && (
                            <div>Agency Web Addresss field is incomplete.</div>
                          )}
                          {formData.taxID === "" && (
                            <div>Agency Tax ID field is incomplete.</div>
                          )}
                          {formData.exactName === "" && (
                            <div>Agency Exact Name field is incomplete.</div>
                          )}
                          {formData.locations === "" && (
                            <div>Agency Locations field is incomplete.</div>
                          )}
                          {formData.subsidiaries === "" && (
                            <div>Agency Subsidiaries field is incomplete.</div>
                          )}
                          {formData.TWP === 0 && (
                            <div>
                              Agency Total Written Premium field is incomplete.
                            </div>
                          )}
                          {formData.ALPercentage === 0 && (
                            <div>
                              Agency Auto Liability % field is incomplete.
                            </div>
                          )}
                          {formData.APDPercentage === 0 && (
                            <div>Agency APD % field is incomplete.</div>
                          )}
                          {formData.MTCPercentage === 0 && (
                            <div>Agency MTC % field is incomplete.</div>
                          )}
                          {formData.marketingContact === "" && (
                            <div>Marketing Contact field is incomplete.</div>
                          )}
                          {formData.accountingContact === "" && (
                            <div>Accounting Contact field is incomplete.</div>
                          )}
                          {formData.licensingContact === "" && (
                            <div>Licensing Contact field is incomplete.</div>
                          )}
                          {formData.ITcontact === "" && (
                            <div>IT Contact field is incomplete.</div>
                          )}
                          {formData.structure === "" && (
                            <div>Business Structure field is incomplete.</div>
                          )}
                          {formData.corporationState === "" && (
                            <div>
                              State of incorporation field is incomplete.
                            </div>
                          )}
                          {formData.vestedManager === "" && (
                            <div>Is manager vested field is incomplete.</div>
                          )}
                          {formData.managerName === "" && (
                            <div>Manager Name field is incomplete.</div>
                          )}
                          {formData.managerLifeInsuranceAmount === "" && (
                            <div>
                              Manager Life Insurance Amount field is incomplete.
                            </div>
                          )}
                          {formData.changedAgencyOwnership === "" && (
                            <div>
                              Changes in Agency Ownership field is incomplete.
                            </div>
                          )}
                          {formData.changedAgencyOwnership === "Yes" && (
                            <>
                              {formData.whyChangedAgencyOwnership === "" && (
                                <div>
                                  Explain changes in agency ownership field is
                                  incomplete.
                                </div>
                              )}
                            </>
                          )}
                          {formData.mergerOrPurchase === "" && (
                            <div>Merger or Purchase field is incomplete.</div>
                          )}
                          {formData.mergerOrPurchase === "Yes" && (
                            <>
                              {formData.whyMergerOrPurchase === "" && (
                                <div>
                                  Explain Merger or Purchase field is
                                  incomplete.
                                </div>
                              )}
                            </>
                          )}
                          {formData.changeInLicenses === "" && (
                            <div>Change in licenses field is incomplete.</div>
                          )}
                          {formData.changeInLicenses === "Yes" && (
                            <>
                              {formData.whyChangeInLicenses === "" && (
                                <div>
                                  Explain Change in licenses field is
                                  incomplete.
                                </div>
                              )}
                            </>
                          )}
                          {formData.changeInManagement === "" && (
                            <div>Change in management field is incomplete.</div>
                          )}
                          {formData.changeInManagement === "Yes" && (
                            <>
                              {formData.whyChangeInManagement === "" && (
                                <div>
                                  Explain Change in management field is
                                  incomplete.
                                </div>
                              )}
                            </>
                          )}

                          {formData.finesOrDisciplinary === "" && (
                            <div>
                              Fines or Disciplinary action field is incomplete.
                            </div>
                          )}
                          {formData.finesOrDisciplinary === "Yes" && (
                            <>
                              {formData.whyFinesOrDisciplinary === "" && (
                                <div>
                                  Explain Fines or Disciplinary action field is
                                  incomplete.
                                </div>
                              )}
                            </>
                          )}

                          {formData.howAgentsCompensated === "" && (
                            <div>
                              How are agents compensated field is incomplete.
                            </div>
                          )}
                          {formData.buySellAgreements === "" && (
                            <div>
                              Any buy/sell agreements field is incomplete.
                            </div>
                          )}
                          {formData.firmSpecializes === "" && (
                            <div>Firm specializes field is incomplete.</div>
                          )}
                          {formData.firmSpecializes === "Yes" && (
                            <>
                              {formData.whyFirmSpecializes === "" && (
                                <div>
                                  Explain firm specialty field is incomplete.
                                </div>
                              )}
                            </>
                          )}

                          {formData.marketingArea === "" && (
                            <div>Marketing Area field is incomplete.</div>
                          )}
                          {formData.majorCompetitors === "" && (
                            <div>Major competitors field is incomplete.</div>
                          )}
                          {formData.mainCompetition === "" && (
                            <div>Main competition field is incomplete.</div>
                          )}
                          {formData.volumePersonal === 0 && (
                            <div>Volume Personal field is incomplete.</div>
                          )}
                          {formData.volumeCommercial === 0 && (
                            <div>Volume Commercial field is incomplete.</div>
                          )}
                          {formData.volumeTruckOnly === 0 && (
                            <div>Volume Truck Only field is incomplete.</div>
                          )}
                          {formData.brokerTruckBusiness === "" && (
                            <div>
                              Do you broker truck business field is incomplete.
                            </div>
                          )}
                          {formData.writingSurplusLines === "" && (
                            <div>
                              Do you currently write surplus lines field is
                              incomplete.
                            </div>
                          )}
                          {formData.hasPremiumTrust === "" && (
                            <div>
                              Do you have a premium trust field is incomplete.
                            </div>
                          )}
                          {formData.hasPremiumTrust === "No" && (
                            <>
                              {formData.methodSeparateFunds === "" && (
                                <div>
                                  Method for separating funds field is
                                  incomplete.
                                </div>
                              )}
                            </>
                          )}
                          {formData.hasPremiumTrust === "Yes" && (
                            <>
                              {formData.trustBank === "" && (
                                <div>
                                  Trust bank details field is incomplete.
                                </div>
                              )}
                            </>
                          )}
                          {formData.bankName === "" && (
                            <div>Bank Reference Name field is incomplete.</div>
                          )}
                          {formData.bankAddress === "" && (
                            <div>
                              Bank Reference Address field is incomplete.
                            </div>
                          )}
                          {formData.bankCityStateZip === "" && (
                            <div>
                              Bank Reference City, State, Zip field is
                              incomplete.
                            </div>
                          )}
                          {formData.bankPhone === "" && (
                            <div>
                              {" "}
                              Bank Reference Phone field is incomplete.
                            </div>
                          )}
                          {formData.bankFax === "" && (
                            <div>Bank Reference Fax field is incomplete.</div>
                          )}
                          {formData.bankContactEmail === "" && (
                            <div>
                              Bank Reference Contact Email field is incomplete.
                            </div>
                          )}
                          {formData.bankContactName === "" && (
                            <div>
                              Bank Reference Contact Name field is incomplete.
                            </div>
                          )}
                          {formData.businessName === "" && (
                            <div>
                              Business Reference 1 Name field is incomplete.
                            </div>
                          )}
                          {formData.businessAddress === "" && (
                            <div>
                              Business Reference 1 Address field is incomplete.
                            </div>
                          )}
                          {formData.businessCityStateZip === "" && (
                            <div>
                              Business Reference 1 City, State, Zip field is
                              incomplete.
                            </div>
                          )}
                          {formData.businessPhone === "" && (
                            <div>
                              Business Reference 1 Phone field is incomplete.
                            </div>
                          )}
                          {formData.businessFax === "" && (
                            <div>
                              Business Reference 1 Fax field is incomplete.
                            </div>
                          )}
                          {formData.businessContactName === "" && (
                            <div>
                              Business Reference 1 Contact Name field is
                              incomplete.
                            </div>
                          )}
                          {formData.businessContactEmail === "" && (
                            <div>
                              Business Reference 1 Contact Email field is
                              incomplete.
                            </div>
                          )}
                          {formData.business2Name === "" && (
                            <div>
                              Business Reference 2 Name field is incomplete.
                            </div>
                          )}
                          {formData.business2Address === "" && (
                            <div>
                              Business Reference 2 Address field is incomplete.
                            </div>
                          )}
                          {formData.business2CityStateZip === "" && (
                            <div>
                              Business Reference 2 City, State, Zip field is
                              incomplete.
                            </div>
                          )}
                          {formData.business2Phone === "" && (
                            <div>
                              Business Reference 2 Phone field is incomplete.
                            </div>
                          )}
                          {formData.business2Fax === "" && (
                            <div>
                              Business Reference 2 Fax field is incomplete.
                            </div>
                          )}
                          {formData.business2ContactEmail === "" && (
                            <div>
                              Business Reference 2 Contact Email field is
                              incomplete.
                            </div>
                          )}
                          {formData.business2ContactName === "" && (
                            <div>
                              Business Reference 2 Contact Name field is
                              incomplete.
                            </div>
                          )}
                          {(formData.structure === "Corporation") |
                          (formData.structure ===
                            "Sub-chapter S Corporation") ? (
                            <>
                              {formData.stockholderFields === "" && (
                                <div>Stockholder fields are incomplete.</div>
                              )}
                              {formData.corporateOfficerFields === "" && (
                                <div>
                                  Corporate Officer fields are incomplete.
                                </div>
                              )}
                            </>
                          ) : (
                            <></>
                          )}

                          {formData.structure === "LLC" && (
                            <>
                              {formData.LLCMemberFields === "" && (
                                <div>LLC Member fields are incomplete.</div>
                              )}
                              {formData.LLCOfficerFields === "" && (
                                <div>LLC Officer fields are incomplete.</div>
                              )}
                            </>
                          )}
                          {(formData.structure === "Partnership") |
                          (formData.structure === "Sole Proprietorship") ? (
                            <>
                              {formData.partnerFields === "" && (
                                <div>Partner fields are incomplete.</div>
                              )}
                              {formData.proprietorshipName === "" && (
                                <div>
                                  Proprietorship Name field is incomplete.
                                </div>
                              )}
                              {formData.SSNFEIN === "" && (
                                <div>SSN/FEIN field is incomplete.</div>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                        <p className="my-3">
                          While blank fields are not permitted in most cases,
                          you may enter N/A or 0 into any fields that do not
                          apply to you.
                        </p>
                        <article className="back-next-section text-center">
                          {formData.status === "Incomplete" ||
                          formData.status === "" ? (
                            <>
                              <button
                                className="button-2"
                                onClick={async (e) =>
                                  await saveApplication()
                                    .then(
                                      // Log System Activity
                                      await addDoc(
                                        collection(db, "system-activity"),
                                        {
                                          activityDate: new Date(),
                                          authorID: auth.currentUser.uid,
                                          authorName:
                                            auth.currentUser.displayName,
                                          description:
                                            "Appointment Application Saved by " +
                                            user.first +
                                            " " +
                                            user.last +
                                            " of " +
                                            user.company,
                                        }
                                      )
                                    )
                                    .then(
                                      alert(
                                        "Application saved but not submitted."
                                      )
                                    )
                                    .then(navigate("/account"))
                                }
                              >
                                Save Application
                              </button>
                              <button
                                className="button-2"
                                onClick={async (e) => {
                                  submitApplication();
                                }}
                              >
                                Submit Application
                              </button>
                              <button
                                onClick={() => setValue(value - 1)}
                                className="button-1"
                                disabled={value === 0}
                              >
                                Back
                              </button>
                            </>
                          ) : (
                            <></>
                          )}
                        </article>
                      </>
                    </TabPanel>
                  )}
                </>
              )}
            </fieldset>
          </Box>
        </>
      )}
    </section>
  );
}

export default AppointmentApplication;
