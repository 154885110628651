import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const TableSelection = ({
  selectedOption,
  setSelectedOption,
  disabled,
  disabledCrashes,
  searchType,
  disabledInspections
}) => {
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <div className="my-4">
      <FormControl fullWidth>
        <InputLabel id="select-label">Select Option</InputLabel>
        <Select
          labelId="select-label"
          id="select"
          value={selectedOption}
          onChange={handleOptionChange}
          fullWidth
        >
          <MenuItem disabled={disabled} value={"profile"}>
            Driver Profile
          </MenuItem>
          <MenuItem disabled={disabled} value={"fitness"}>
            Driver Fitness
          </MenuItem>
              <MenuItem disabled={disabledCrashes} value={"crashes"}>
                Crashes
              </MenuItem>
              <MenuItem disabled={disabledInspections} value={"inspections"}>
                Inspections
              </MenuItem>
          <MenuItem value={"scaling"}>Driver Profile Scaling</MenuItem>
          <MenuItem value={"tables"}>View/Edit Tables</MenuItem>
          <MenuItem value={"matrices"}>View/Edit Matrices</MenuItem>
          <MenuItem value={"upload"}>Upload Violations</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

export default TableSelection;
