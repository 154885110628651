import React, { useEffect, useState } from "react";
import useTranslations from "../../../../Context/actions/useTranslations";
import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../../../firebase";
import OpenApplications from "./Agent/OpenApplications";
import BusinessWritten from "./Agent/BusinessWritten";
import BusinessComposition from "./Agent/BusinessComposition";

export default function AgentAtAGlance({ userInfo, coverageCounts, setCoverageCounts }) {
  const { t } = useTranslations();

  return (
    <div>
      <OpenApplications userInfo={userInfo} />
      <BusinessWritten userInfo={userInfo} />
      <BusinessComposition userInfo={userInfo} coverageCounts={coverageCounts} setCoverageCounts={setCoverageCounts} />
    </div>
  );
}
