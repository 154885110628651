import { useState } from "react";
import { Td, Tr } from "react-super-responsive-table";
import SelectDropdown from "./OtherLosses/SelectDropdown";
import { arrayUnion, doc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import MultiUpload from "../../assets/forms/DynamicPropertiesInputs/MultiUpload";
import updatePolicyField from "../../utils/updatePolicyField";

const LossRunItem = ({
  files,
  index,
  policyID,
  formData,
  dropdownOptions,
  labelCoverageType,
  labelCoverageTerm,
  labelMultiupload,
  fieldNameCoverageType,
  fieldNameCoverageTerm,
  fieldNameMultiupload,
}) => {

  const [fileObjectsOtherLossRuns, setFileObjectsOtherLossRuns] =
    useState(files);

  return (
    <Tr>
      <Td>
        <SelectDropdown
          label={labelCoverageType}
          policyID={policyID}
          formData={formData}
          options={dropdownOptions.coverages}
          fieldName={fieldNameCoverageType}
          softUpdate={true}
          index={index}
        />
      </Td>
      <Td>
        <SelectDropdown
          label={labelCoverageTerm}
          policyID={policyID}
          formData={formData}
          options={dropdownOptions.term}
          fieldName={fieldNameCoverageTerm}
          softUpdate={true}
          index={index}
        />
      </Td>
      <Td>
        <MultiUpload
          label={labelMultiupload}
          property={fieldNameMultiupload}
          formData={fileObjectsOtherLossRuns}
          setFormData={async (value) => {
            setFileObjectsOtherLossRuns(value);
            const existingArray = formData?.OtherLossRuns || [];
            const newData = {
                ...existingArray[index],
                [fieldNameMultiupload]: value,
            };
            existingArray[index] = newData;
            updatePolicyField(policyID, "OtherLossRuns", existingArray);
          }}
          storageFile={`files/policies/${policyID}/loss-runs/other-loss-runs`}
          changeNormal={true}
          fileInButton={true}
          width="100%"
          dissapearWhenImgExists={true}
        />
      </Td>
    </Tr>
  );
};

export default LossRunItem;
