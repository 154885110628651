import React, { useEffect, useState } from "react";
import {
  getDocs,
  collection,
} from "firebase/firestore";
import { db } from "../../../firebase";
import FitnessTable from "./FitnessTable";

export default function DriverFitness({ dataReady, aggregatedDriverInfo, userInfo, setDriverModalIsOpen, driverModalIsOpen, closeDriverModal, openDriverModal}) {

  // Get Matrices
  const [severityMatrix, setSeverityMatrix] = useState([]);
  const [frequencyMatrix, setFrequencyMatrix] = useState([]);
  const [combinedMatrix, setCombinedMatrix] = useState([]);
  const fetchMatrixData = async (matrixType, stateSetter) => {
    try {
      const matrixRef = collection(db, "loss-control", "tables", matrixType);
      const snapshot = await getDocs(matrixRef);
      const matrix = [];
      snapshot.forEach((doc) => {
        matrix.push(doc.data());
      });
      stateSetter(matrix);
    } catch (error) {
      console.error(`Error getting ${matrixType} matrix:`, error);
    }
  };
  
  useEffect(() => {
    fetchMatrixData("combinedMatrix", setCombinedMatrix);
  }, []);
  
  useEffect(() => {
    fetchMatrixData("frequencyMatrix", setFrequencyMatrix);
  }, []);
  
  useEffect(() => {
    fetchMatrixData("severityMatrix", setSeverityMatrix);
  }, []);
  

  return (
    <div>
      <FitnessTable
      userInfo={userInfo}
      setDriverModalIsOpen={setDriverModalIsOpen}
      driverModalIsOpen={driverModalIsOpen}
      closeDriverModal={closeDriverModal}
      openDriverModal={openDriverModal}
        aggregatedDriverInfo={aggregatedDriverInfo}
        frequencyMatrix={frequencyMatrix}
        severityMatrix={severityMatrix}
        combinedMatrix={combinedMatrix}
        dataReady={dataReady}
      />
    </div>
  );
}
