import classNames from 'classnames';
import React from 'react';
import { Thead, Table, Tr, Td, Tbody } from 'react-super-responsive-table';

const OFACSection = ({ currentItems }) => {
    return (
        <>
            <Table className='w-[100%]'>
                <Thead>
                    <Tr>
                    <Td
                        className={classNames("bg-[#072a48] text-white rounded-tl-md cursor-pointer bg-blue-500")}
                    >
                        Name
                    </Td>
                    <Td
                        className={classNames("bg-[#072a48] text-white cursor-pointer bg-blue-500")}
                    >
                        Address
                    </Td>
                    <Td
                        className={classNames("bg-[#072a48] text-white cursor-pointer bg-blue-500")}
                    >
                        Type
                    </Td>
                    <Td
                        className={classNames("bg-[#072a48] text-white cursor-pointer bg-blue-500")}
                    >
                        Program
                    </Td>
                    <Td
                        className={classNames("bg-[#072a48] text-white cursor-pointer bg-blue-500")}
                    >
                        List
                    </Td>
                    <Td
                        className={classNames("bg-[#072a48] text-white cursor-pointer rounded-tr-md bg-blue-500")}
                    >
                        Score
                    </Td>
                    </Tr>
                </Thead>
                <Tbody>
                    {currentItems.map((item, index) => {
                        return (
                            <Tr
                                key={index}
                                style={{
                                    cursor: "pointer",
                                    // backgroundColor: hoveredRow === index ? "#e6f7ff" : "inherit",
                                }}
                            >
                                <Td>{item?.name || '-'}</Td>
                                <Td>{item?.address || '-'}</Td>
                                <Td>{item?.type || '-'}</Td>
                                <Td>{item?.program || '-'}</Td>
                                <Td>{item?.list || '-'}</Td>
                                <Td>{item?.score || '-'}</Td>
                            </Tr>
                        );
                    })}
                </Tbody>
            </Table>
        </>
    )
}

export default OFACSection