const calculateAge = (dob) => {
  if (!dob) return "-";
  let birthDate;
  if (dob.seconds !== undefined && dob.nanoseconds !== undefined) {
    birthDate = new Date(dob.seconds * 1000);
  } 
  else {
    birthDate = new Date(dob);
  }
  if (isNaN(birthDate)) return "-";
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDifference = today.getMonth() - birthDate.getMonth();
  if (
    monthDifference < 0 ||
    (monthDifference === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }
  return age;
};

export const calculateAge2 = (dob) => {
  if (!dob) return "-";
  let birthDate;
  if (dob.seconds !== undefined && dob.nanoseconds !== undefined) {
    birthDate = new Date(dob.seconds * 1000);
  }
  else if (typeof dob === "string") {
    birthDate = new Date(dob);
  }
  else if (dob instanceof Date) {
    birthDate = dob;
  }
  else if (typeof dob?.toDate === "function") {
    birthDate = dob.toDate();
  }
  else {
    return "-";
  }
  if (isNaN(birthDate)) return "-";
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDifference = today.getMonth() - birthDate.getMonth();
  if (
    monthDifference < 0 ||
    (monthDifference === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }
  return age;
};

export default calculateAge;