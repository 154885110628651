import React, { createContext, useState } from 'react';
import ConfirmModal from './ConfirmModal';

const MasterPromptContext = createContext();

let globalMasterPrompt;

export const MasterPromptProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [resolvePromise, setResolvePromise] = useState(null);
  const [inputs, setInputs] = useState('');
  const [title, setTitle] = useState('');

  const masterPrompt = (inputs, title) => {
    setInputs(inputs);
    setTitle(title);
    setIsOpen(true);
    return new Promise((resolve) => {
      setResolvePromise(() => resolve);
    });
  };

  globalMasterPrompt = masterPrompt;

  const handleConfirm = (value) => {
    setIsOpen(false);
    if (resolvePromise) {
      resolvePromise({
        solved: true,
        value: value ? value : null,
      });
    }
  };

  const handleCancel = () => {
    setIsOpen(false);
    if (resolvePromise) {
      resolvePromise({
        solved: false,
        value: null
      });
    }
  };

  return (
    <MasterPromptContext.Provider value={{ masterPrompt }}>
      {children}
      <ConfirmModal
        open={isOpen}
        onClose={handleCancel}
        onConfirm={handleConfirm}
        inputs={inputs}
        title={title}
      />
    </MasterPromptContext.Provider>
  );
};

export const masterPromptGlobal = (inputs, title) => { 
  if (!globalMasterPrompt) {
    throw new Error('masterPrompt is not initialized.');
  }
  return globalMasterPrompt(inputs, title);
};