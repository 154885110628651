import React, { useState } from "react";
import AdminNotificationManager from "../NotificationManager/AdminNotificationManager";

export default function Notifications({ userInfo }) {
  const [value, setValue] = useState("");

  return (
    <div>
      <h1 className="text-xl font-bold my-4">Notification Manager</h1>
      <p className="my-4">Choose Which Notification to Modify</p>
      <select
        onClick={(e) => {
          setValue(e.target.value);
        }}
        className="p-2 w-full border rounded"
      >
        <option value="">Make A Selection</option>
        <option value="System">System Notifications</option>
        <option value="Policies">Policy Notification</option>
        <option value="Endorsements">Endorsement Notifications</option>
        <option value="AgencyAppointment">
          Agency Appointment Notifications
        </option>
        <option value="LossRunRequest">Loss Run Request Notifications</option>
        <option value="NOR-NOC">NOR & NOC Notifications</option>
        <option value="TechSupport">Tech Support Notifications</option>
        <option value="UnderwritingSupport">
          Underwriting Support Notifications
        </option>
      </select>
      {value === "Policies" && (
        <AdminNotificationManager
          userInfo={userInfo}
          notificationType={"Policy"}
        />
      )}
      {value === "Endorsements" && (
        <AdminNotificationManager
          userInfo={userInfo}
          notificationType={"Endorsement"}
        />
      )}
      {value === "System" && (
        <AdminNotificationManager
          userInfo={userInfo}
          notificationType={"System"}
        />
      )}
      {value === "AgencyAppointment" && (
        <AdminNotificationManager
          userInfo={userInfo}
          notificationType={"Agency Appointment"}
        />
      )}
      {value === "LossRunRequest" && (
        <AdminNotificationManager
          userInfo={userInfo}
          notificationType={"Agency Appointment"}
        />
      )}
      {value === "TechSupport" && (
        <AdminNotificationManager
          userInfo={userInfo}
          notificationType={"Tech Support"}
        />
      )}
      {value === "UnderwritingSupport" && (
        <AdminNotificationManager
          userInfo={userInfo}
          notificationType={"Underwriting Support"}
        />
      )}
      {value === "NOR-NOC" && (
        <AdminNotificationManager
          userInfo={userInfo}
          notificationType={"NOR and NOC"}
        />
      )}
    </div>
  );
}
