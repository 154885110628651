import { Navigate, useLocation } from "react-router-dom";
import { UserAuth } from "../Context/AuthContent";
import {
  HOME_ROUTE,
  LOSS_RUNS_ROUTE,
  MAINTENANCE_ROUTE,
  NEW_SUPPORT_TICKET_ROUTE,
  SIGN_IN_ROUTE,
  SIGN_UP_ROUTE,
  TEMPORARILY_ROUTE,
} from "../utils/routes";
import { useContext, useState, useEffect } from "react";
import { AppContext } from "../Context/AppContext";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";
import useUsersState from "../Context/actions/useUsersState";
import useManagingState from "../Context/actions/useManagingState";

const ProtectedRoute = ({ children }) => {
  const { user } = UserAuth();
  const { pathname } = useLocation();
  const { contextLoading } = useContext(AppContext);
  const { user: userState } = useUsersState()
  const { setAllowApprovalRedFlags } = useManagingState()
  
  const [shouldRender, setShouldRender] = useState(false);
  const [maintenanceMode, setMaintenanceMode] = useState(false);

  const handleManaging = () => {
    const docRef = doc(db, "managing", "maintenance-mode");
    const unsubscribe = onSnapshot(docRef, (docSnap) => {
      if (docSnap.exists()) {
        const on = docSnap.data()?.on;
        const isAdmin = userState?.admin;
        const enableMaintenance = on === true && isAdmin !== true;
        setMaintenanceMode(Boolean(enableMaintenance))
      } else {
        setMaintenanceMode(false);
      }
    });
    return unsubscribe;
  };

  useEffect(() => {
    if (!contextLoading) {
      const unsubscribe = handleManaging();
      setShouldRender(true);
      return () => unsubscribe();
    }
  }, [contextLoading, userState]);

  const handleRedFlags = () => {
    const docRef = doc(db, "managing", "red-flags");
    const unsubscribe = onSnapshot(docRef, (docSnap) => {
      if (docSnap.exists()) {
        const allowApprovalRedFlags = docSnap.data()?.allowApprovalRedFlags;
        setAllowApprovalRedFlags(allowApprovalRedFlags)
      }
    });
    return unsubscribe;
  };

  useEffect(() => {
    if (!contextLoading) {
      const unsubscribe = handleRedFlags();
      return () => unsubscribe();
    }
  }, [contextLoading, userState]);


  if (!shouldRender) {
    return <div></div>;
  }

  if (
    maintenanceMode === true &&
    pathname !== LOSS_RUNS_ROUTE &&
    pathname !== NEW_SUPPORT_TICKET_ROUTE &&
    pathname !== SIGN_IN_ROUTE &&
    pathname !== SIGN_UP_ROUTE &&
    pathname !== MAINTENANCE_ROUTE
  ) {
    return <Navigate to={MAINTENANCE_ROUTE} />;
  } else if (
    maintenanceMode === false &&
    pathname === MAINTENANCE_ROUTE
  ) {
    return <Navigate to={HOME_ROUTE} />;
  } else if (
    !user &&
    pathname !== LOSS_RUNS_ROUTE &&
    !pathname.includes(TEMPORARILY_ROUTE)
  ) {
    return <Navigate to={HOME_ROUTE} />;
  } else {
    return children;
  }
};

export default ProtectedRoute;