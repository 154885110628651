import React from "react";
import { Navbar } from "react-bootstrap";
import { Container } from "react-bootstrap";
import NavItems from "./NavItems";
import { SIGN_IN_ROUTE } from "../../utils/routes";
import './index.css';

export default function index(props) {
  const userInfo = props.userInfo;
  return (
    <Navbar className="bg-[#072a48] w-full" variant="dark" expand="lg">
      <Container fluid>
        <Navbar.Brand href={SIGN_IN_ROUTE}>Southern Star Portal</Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <NavItems userInfo={userInfo} />
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
