import React, { useState } from "react";
import Breadcrumbs from "../../Components/assets/Breadcrumbs";
import { ACCOUNT_ROUTE } from "../../utils/routes";
import DragDropImportList from "../../Components/assets/forms/DragDropFileUpload/DragDropImportList";
import useGeneralState from "../../Context/actions/useGeneralState";
import { readOldInspasData } from "./readOldInspasData";

export default function Converter() {
  const [file, setFile] = useState(null);
  const { controlToast } = useGeneralState()

  const breadcrumbs = [
    { label: "Home", link: ACCOUNT_ROUTE },
    { label: "Converter" },
  ];

  const handleUpload = async (file) => {
    try {
      await readOldInspasData(file, controlToast)
    } catch (error) {
      console.error("Error reading file: ", error);
    }
  }

  return (
    <div className="my-4 max-w-[1165px] mx-auto bg-white rounded-md shadow-md p-6">
      <Breadcrumbs items={breadcrumbs} />
      <div className="flex flex-col md:flex-row md:items-center md:justify-between mt-1">
        <span className="text-3xl font-semibold mb-4 md:mb-0">Converter</span>
      </div>
      <p className="mb-4">Search for a file to convert</p>
      <DragDropImportList
        file={file}
        setFile={setFile}
        handleUpload={handleUpload}
        fileTypes={["XLSX"]}
      />
    </div>
  );
}