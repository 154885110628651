import MultiUpload from "../../../assets/forms/DynamicPropertiesInputs/MultiUpload";

const TrucksUploadForm = ({
  truckVIN,
  fileObjectsTruckDocuments,
  setFileObjectsTruckDocuments,
  isViewMode = false,
}) => {
  return (
    <>
      {(isViewMode && fileObjectsTruckDocuments.length > 0) || !isViewMode ? (
        <div className="mb-2">
          <MultiUpload
            label={"Truck Documents"}
            property="truckDocuments"
            formData={fileObjectsTruckDocuments}
            setFormData={setFileObjectsTruckDocuments}
            storageFile={`files/power-units/${truckVIN}/truck-docs`}
            multiple={true}
            changeNormal={true}
            fileInButton={true}
            width="100%"
            dissapearWhenImgExists={true}
            showInAPopup={isViewMode ? false : true}
            disabled={isViewMode}
            isViewMode={isViewMode}
          />
        </div>
      ) : null}
    </>
  );
};

export default TrucksUploadForm;
