import React from 'react';

export default function ActionButton({
  onClickFunction,
  label,
  icon,
  disabled = false
}) {
  return (
    <button
      disabled={Boolean(disabled)}
      onClick={(e) => onClickFunction(e)}
      className="nav-button"
      style={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center',
        background: 'none',
        border: 'none',
        cursor: 'pointer',
        paddingLeft: '0.5rem',
        paddingRight: '0.5rem',
        paddingTop: 0,
        paddingBottom: 0,
        transition: 'background 0.3s ease',
        borderRadius: '4px',
      }}
    >
      {icon && <span style={{ marginBottom: '0.125rem', fontSize: '1rem' }}>{icon}</span>}
      <span style={{ fontSize: '0.8rem' }}>{label}</span>
    </button>
  );
}
