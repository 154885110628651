import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useHistoryTrack = () => {
  const location = useLocation();

  useEffect(() => {
    const currentPath = location.pathname + location.search;
    const historyStack = JSON.parse(localStorage.getItem('history')) || [];
    const previousRoute = historyStack[historyStack.length - 1];
    historyStack.push(currentPath);
    if (currentPath !== previousRoute) {
        localStorage.setItem('history', JSON.stringify(historyStack));
    }
  }, [location]);
}

export default useHistoryTrack