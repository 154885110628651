import React, { useEffect, useState } from "react";
import { Modal, Box, useMediaQuery, Button } from "@mui/material";
import ApprovalButtons from "../assets/forms/ApprovalButtons";
import VisibilityIcon from "@mui/icons-material/Visibility";
import BlueButton from "../assets/forms/BlueButton";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";


export default function QuillModal({
  isOpen,
  setIsOpen,
  label,
  setFile,
  handleUpload,
  alreadyUploadedRule,
  taskLocation,
  hideButtonLabel
}) {
  const isMobileScreen = useMediaQuery("(max-width: 600px)");
  const [value, setValue] = useState("");

  useEffect(() => {
    if (taskLocation) {
      setValue(taskLocation);
    }
  }, [taskLocation]);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setFile([]);
  };

  const submitNote = () => {
    handleUpload({ description: label, note: value });
    closeModal();
  };

  return (
    <div>
      {!hideButtonLabel && (
        <h3 className="text-[#072a48] mb-2">
          {alreadyUploadedRule ? "Upload " : ""}
          {label}
        </h3>
      )}
      {!alreadyUploadedRule && (
        <BlueButton
          text={"Leave A Note"}
          onClickFunction={openModal}
          hasContentToBottom={true}
          icon={<AssignmentIcon />}
          className={"w-full"}
        />
      )}
      
      {alreadyUploadedRule && (
        <Button
          variant="contained"
          color="success"
          startIcon={<VisibilityIcon />}
          onClick={openModal}
          size="small"
          sx={{
            width: 'auto', // Adjust width as needed
            padding: '4px 8px', // Decrease padding for a smaller button
            fontSize: '0.75rem', // Smaller font size
            minWidth: '100%', // Prevent button from expanding
            marginBottom: '8px',
          }}
        >
          View Note
        </Button>
      )}
      <Modal open={isOpen} onClose={closeModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            maxHeight: "100vh",
            overflowY: "auto",
            width: isMobileScreen ? "100%" : 500,
            height: isMobileScreen ? "100%" : 530,
            borderRadius: isMobileScreen ? 0 : 4,
          }}
        >
          <h2 className="mb-2 font-bold text-xl text-left text-[#072a48]">
            Leave A Note
          </h2>
          <h2 className="mb-3 font-bold text-left text-[#072a48]">
            Subject: {label}
          </h2>
          <p></p>
          <div style={{ backgroundColor: "white" }}>
            <ReactQuill
              theme="snow"
              value={value}
              onChange={setValue}
              style={{ height: "300px" }}
            />
          </div>
          <div className="mt-4 d-block">
            <ApprovalButtons
              onApprove={submitNote}
              onDecline={closeModal}
              dissapearAccept={false}
              titleDecline="Close"
              titleAccept="Submit"
            />
          </div>
        </Box>
      </Modal>
    </div>
  );
}
