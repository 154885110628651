import { doc, setDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { currentDate, generateRandomUid } from "../../utils/helpers";

export const createSupportTicket = async (
    uid,
    displayName,
    subject,
    message,
    type,
) => {
  try {
    await setDoc(
      doc(
        db,
        "support-tickets",
        generateRandomUid(uid)
      ),
      {
        date: currentDate(),
        senderID: uid,
        sender: displayName,
        status: "Unread",
        subject,
        message: Object.values(message).join(" "),
        type,
      }
    );
  } catch (error) {
    throw error
  }
};

export const setSupportTicket = async (payload, id) => {
  try {
    await setDoc(doc(db, "support-tickets", id), payload, { merge: true });
  } catch (error) {
    throw error;
  }
};