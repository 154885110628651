import { useState } from "react";
import useLossControlListener from "./useLossControlListener";
import { convertFirestoreToHumanDate } from "../../utils/helpers";

const useLossControlFormulas = () => {
  const [classifications, setClassifications] = useState([]);
  const [eventualities, setEventualities] = useState([]);

  const [major, setMajor] = useState([]);
  const [minor, setMinor] = useState([]);
  const [notDrivingRisk, setNotDrivingRisk] = useState([]);
  const [notIncluded, setNotIncluded] = useState([]);
  const [age, setAge] = useState([]);
  const [experience, setExperience] = useState([]);
  const [numberOfClaims, setNumberOfClaims] = useState([]);
  const [claimsDollarAmount, setClaimsDollarAmount] = useState([]);
  const [finalWeight, setFinalWeight] = useState([]);

  const [fmcsaViolations, setFmcsaViolations] = useState([]);
  const [fmcsaDriverFitness, setFmcsaDriverFitness] = useState([]);
  const [driverProfile, setDriverProfile] = useState([]);

  useLossControlListener("tables", "classifications", setClassifications);
  useLossControlListener("tables", "eventualities", setEventualities);
  useLossControlListener("tables", "major", setMajor);
  useLossControlListener("tables", "minor", setMinor);
  useLossControlListener("tables", "notDrivingRisk", setNotDrivingRisk);
  useLossControlListener("tables", "notIncluded", setNotIncluded);
  useLossControlListener("tables", "age", setAge);
  useLossControlListener("tables", "experience", setExperience);
  useLossControlListener("tables", "numberOfClaims", setNumberOfClaims);
  useLossControlListener("tables", "claimsDollarAmount", setClaimsDollarAmount);
  useLossControlListener("tables", "finalWeight", setFinalWeight);
  useLossControlListener("driverData", "fmcsaViolations", setFmcsaViolations);
  useLossControlListener(
    "driverData",
    "fmcsaDriverFitness",
    setFmcsaDriverFitness
  );
  useLossControlListener("driverData", "driverProfile", setDriverProfile);

  const regularValue = (value) => {
    if (typeof value === "number") return value?.toFixed(2);
    if (value?.seconds) return convertFirestoreToHumanDate(value)
    return value;
  };

  const getAverageClaimAmount = (row) => {
    const averageClaimAmount =
      parseInt(row.claimsDollarAmount, 10) / parseInt(row.numberOfClaims, 10);
    return isNaN(averageClaimAmount) ? "" : averageClaimAmount.toFixed(2);
  };

  const getFrequency = (row) => {
    const frequency =
      parseInt(row.numberOfClaims, 10) / parseInt(row.numberOfDrivers, 10);
    return isNaN(frequency) ? "" : frequency.toFixed(2);
  };

  const getRiskPremium = (row) => {
    const riskPremium =
      parseInt(row.frequency, 10) / parseInt(row.averageClaimAmount, 10);
    return isNaN(riskPremium) ? "" : riskPremium.toFixed(2);
  };

  const getClassificationInViolations = (row) => {
    const eventualityObject =
      eventualities?.find((item) => item.code === row.violationCode) || {};
    return eventualityObject.classification || "";
  };

  const getCategoryInViolations = (classification) => {
    const classificationObject =
      classifications?.find((item) => item.classification === classification) ||
      {};
    return classificationObject.finalClassification || "";
  };

  const getCaseByCaseInViolations = (classification) => {
    const classificationObject =
      classifications?.find((item) => item.classification === classification) ||
      {};
    return classificationObject.special || "";
  };

  const getNationality = (drvLicMx) => {
    const nacionality = drvLicMx ? "Mexican" : "American";
    return nacionality;
  };

  const getTotalViolations = (row, type) => {
    const violations = fmcsaViolations.filter((violation) => {
      return violation.driverName === row.fullName;
    });
    let totalViolations = 0;
    violations.forEach((violation) => {
      const classification = getClassificationInViolations(violation);
      const category = getCategoryInViolations(classification);
      if (category === type) totalViolations++;
    });
    return totalViolations;
  };

  const getTotalCrashes = (row) => {
    const violations = fmcsaViolations.filter((violation) => {
      return violation.driverName === row.fullName;
    });
    let crashes = 0;
    violations.forEach((violation) => {
      if (violation.violationCode === 'crash') crashes++;
    });
    return crashes;
  };

  const getTotalClaimAmount = (row) => {
    const violations = fmcsaViolations.filter((violation) => {
      return violation.driverName === row.fullName;
    });
    let claimAmount = 0;
    violations.forEach((violation) => {
      if (violation.violationCode === 'crash') {
        claimAmount = claimAmount + violation.claimAmount;
      }
    });
    return claimAmount;
  };

  const getWarningDatosStatus = (row) => {
    if (row.age === 0) return "Revise the age";
    if (row.exp === 0) return "Revise the experience";
    return "";
  };

  const getAgeStatus = (row) => {
    if (row.age < 22) return "Driver is less than 23 years old";
    if (row.age > 65) return "Driver needs Long Form";
    if (row.age >= 22 && row.age <= 65) return "Qualified Driver";
    return "";
  };

  const getExperienceStatus = (row) => {
    if (row.exp > 0) return "Qualified Driver";
    return "Not Qualified Driver";
  };

  const getMajorViolationStatus = (row) => {
    const totalMajorViolation = getTotalViolations(row, "Major");
    return totalMajorViolation > 0
      ? "Exclusion by Type A violation"
      : "Insurable";
  };

  const isLessThanThreeYears = (date) => {
    const inputDate = new Date(date);
    const dateThreeYearsAgo = new Date();
    dateThreeYearsAgo.setFullYear(dateThreeYearsAgo.getFullYear() - 3);
    return inputDate >= dateThreeYearsAgo;
  };

  const getMinorViolationStatus = (row) => {
    const violations = fmcsaViolations.filter((violation) => {
      return violation.driverName === row.fullName;
    });
    const minorViolations = violations.filter((violation) => {
      const classification = getClassificationInViolations(violation);
      const category = getCategoryInViolations(classification);
      return category === "minor";
    });
    if (minorViolations.length === 0) return "Insurable";
    const dateFilteredViolations = minorViolations.filter((violation) => {
      return isLessThanThreeYears(violation.inspectionDate);
    });
    if (dateFilteredViolations.length < 3) return "Insurable";
    return "Exclusion by Type B violation";
  };

  const getCrashesStatus = (row) => {
    const totalCrashes = getTotalCrashes(row);
    return totalCrashes > 1 ? "Exclusion by Crashes" : "Insurable";
  };

  const getCaseByCaseInDrivers = (row) => {
    const violations = fmcsaViolations.filter((violation) => {
      return violation.driverName === row.fullName;
    });
    const caseByCaseViolations = violations.filter((violation) => {
      const classification = getClassificationInViolations(violation);
      const caseByCase = getCaseByCaseInViolations(classification);
      return caseByCase === "Case by Case";
    });
    return caseByCaseViolations.length;
  };

  const getTrainingStatus = (row) => {
    if (row?.scoreTotal < 0.6) {
      return "No training Needed";
    }
    return 'Training Needed'
  };

  const scoreClaimsCount = (driver) => {
    const totalCrashes = getTotalCrashes(driver);
    const claim = numberOfClaims.find((claim) => {
      return claim.numberOfViolations === totalCrashes;
    }) || {
      numberOfViolations: 0,
      score: 0
    }
    const ponderationWeight = finalWeight.find((weight) => {
      return weight?.scoreTitle === "Score Claims Count";
    })
    const ponderation = claim?.score * ponderationWeight?.weight;
    return {
      original: driver?.scoreClaimsCount,
      score: claim?.score,
      ponderation: ponderation,
    };
  };

  const scoreClaimsAmount = (driver) => {
    const totalClaimAmount = getTotalClaimAmount(driver);
    const claimAmount = claimsDollarAmount.find((claimAmount) => {
      return (
        totalClaimAmount <= claimAmount.maxClaimDollarAmount &&
        totalClaimAmount >= claimAmount.minClaimDollarAmount
      );
    }) || {
      minClaimDollarAmount: 0,
      maxClaimDollarAmount: 0,
      score: 0
    }
    const ponderationWeight = finalWeight.find((weight) => {
      return weight?.scoreTitle === "Score Claims Amount";
    });
    const ponderation = claimAmount?.score * ponderationWeight?.weight;
    return {
      original: driver?.scoreClaimsAmount,
      score: claimAmount?.score,
      ponderation: ponderation,
    };
  };

  const scoreAge = (driver) => {
    const ageFound = age.find((item) => {
      return driver.age > item.minAge && driver.age < item.maxAge;
    }) || {
      minAge: 0,
      maxAge: 0,
      score: 0
    }
    const ponderationWeight = finalWeight.find((weight) => {
      return weight?.scoreTitle === "Score Age";
    })
    const ponderation = ageFound?.score * ponderationWeight?.weight;
    return {
      original: driver?.scoreAge,
      score: ageFound?.score,
      ponderation: ponderation,
    };
  };

  const scoreExp = (driver) => {
    const realExp = driver.exp || 0
    const expFound = experience.find((item) => {
      return realExp > item.minExp && realExp < item.maxExp
    }) || {
      minExp: 0,
      maxExp: 0,
      score: 0
    }
    const ponderationWeight = finalWeight.find((weight) => {
      return weight?.scoreTitle === "Score Exp";
    });
    const ponderation = expFound?.score * ponderationWeight?.weight;
    return {
      original: driver?.scoreExp,
      score: expFound?.score,
      ponderation: ponderation,
    };
  };

  const scoreViolationsMajor = (driver) => {
    const totalMajorViolation = getTotalViolations(driver, "Major");
    const majorViolationFound = major.find((item) => {
      return totalMajorViolation === item.numberOfViolations;
    }) || {
      numberOfViolations: 0,
      score: 0
    }
    const ponderationWeight = finalWeight.find((weight) => {
      return weight?.scoreTitle === "Score Violations Major";
    });
    const ponderation =
      totalMajorViolation === 0
        ? 0
        : majorViolationFound?.score * ponderationWeight?.weight;
    return {
      original: driver?.scoreViolationMajor,
      score: majorViolationFound?.score,
      ponderation: ponderation,
    };
  };

  const scoreViolationsMinor = (driver) => {
    const totalMinorViolation = getTotalViolations(driver, "Minor");
    const minorViolationFound = minor.find((item) => {
      return totalMinorViolation === item.numberOfViolations;
    })  || {
      numberOfViolations: 0,
      score: 0
    }
    const ponderationWeight = finalWeight.find((weight) => {
      return weight?.scoreTitle === "Score Violations Minor";
    });
    const ponderation =
      totalMinorViolation === 0
        ? 0
        : minorViolationFound?.score * ponderationWeight?.weight;
    return {
      original: driver?.scoreViolationMinor,
      score: minorViolationFound?.score,
      ponderation: ponderation,
    };
  };

  const scoreViolationsOutOfDrivingRisk = (driver) => {
    const totalNotDrivingRiskViolation = getTotalViolations(
      driver,
      "Not Driving Risk"
    );
    const notDrivingRiskViolationFound = notDrivingRisk.find((item) => {
      return totalNotDrivingRiskViolation === item.numberOfViolations;
    }) || {
      numberOfViolations: 0,
      score: 0
    }
    const ponderationWeight = finalWeight.find((weight) => {
      return weight?.scoreTitle === "Score Violations out of Driving Risk";
    });
    const ponderation =
      totalNotDrivingRiskViolation === 0
        ? 0
        : notDrivingRiskViolationFound?.score * ponderationWeight?.weight;
    return {
      original: driver?.scoreViolationsOutOfDrivingRisk,
      score: notDrivingRiskViolationFound?.score,
      ponderation: ponderation,
    };
  };

  const scoreViolationsNotIncluded = (driver) => {
    const totalNotIncludedViolations = getTotalViolations(
      driver,
      "Not included"
    );
    const notDrivingRiskViolationFound = notIncluded.find((item) => {
      return totalNotIncludedViolations === item.numberOfViolations;
    }) || {
      numberOfViolations: 0,
      score: 0
    }
    const ponderationWeight = finalWeight.find((weight) => {
      return weight?.scoreTitle === "Score Violations Not Included";
    });
    const ponderation =
      totalNotIncludedViolations === 0
        ? 0
        : notDrivingRiskViolationFound?.score * ponderationWeight?.weight;
    return {
      original: driver?.scoreViolationsNotInclude,
      score: notDrivingRiskViolationFound?.score,
      ponderation: ponderation,
    };
  };

  const getDriverScore = (row) => {
    const driver =
      driverProfile.find((profile) => row.fullName === profile.fullName) || {};
    let { ponderation: claim } = scoreClaimsCount(driver);
    let { ponderation: claimAmount } = scoreClaimsAmount(driver);
    let { ponderation: age } = scoreAge(driver);
    let { ponderation: exp } = scoreExp(driver);
    let { ponderation: violationsMajor } = scoreViolationsMajor(driver);
    let { ponderation: violationsMinor } = scoreViolationsMinor(driver);
    let { ponderation: violationsOutOfDrivingRisk } =
      scoreViolationsOutOfDrivingRisk(driver);
    let { ponderation: violationsNotInclude } =
      scoreViolationsNotIncluded(driver);

    claim = isNaN(claim) ? 0 : claim;
    claimAmount = isNaN(claimAmount) ? 0 : claimAmount;
    age = isNaN(age) ? 0 : age;
    exp = isNaN(exp) ? 0 : exp;
    violationsMajor = isNaN(violationsMajor) ? 0 : violationsMajor;
    violationsMinor = isNaN(violationsMinor) ? 0 : violationsMinor;
    violationsOutOfDrivingRisk = isNaN(violationsOutOfDrivingRisk)
      ? 0
      : violationsOutOfDrivingRisk;
    violationsNotInclude = isNaN(violationsNotInclude)
      ? 0
      : violationsNotInclude;

    const sum =
      claim +
      claimAmount +
      age +
      exp +
      violationsMajor +
      violationsMinor +
      violationsOutOfDrivingRisk +
      violationsNotInclude;
    return sum;
  };

  const getFmcsaDriverMirrorData = (driver) => {
    const driverFound =
      fmcsaDriverFitness.find(
        (profile) => driver.fullName === profile.fullName
      ) || {};
    return driverFound;
  };

  const driverProfileScalingSwitch = (value, row) => {
    if (value === "formula1") {
      return getAverageClaimAmount(row);
    } else if (value === "formula2") {
      return getFrequency(row);
    } else if (value === "formula3") {
      return getRiskPremium(row);
    }
    return regularValue(value);
  };

  const fmcsaViolationsSwitch = (value, row) => {
    const classification = getClassificationInViolations(row);
    if (value === "formula1") {
      return classification;
    } else if (value === "formula2") {
      return getCategoryInViolations(classification);
    } else if (value === "formula3") {
      return getCaseByCaseInViolations(classification);
    }
    return regularValue(value);
  };

  const fmcsaDriverFitnessSwitch = (value, row) => {
    if (value === "formula1") {
      return getNationality(row?.drvLicMx);
    } else if (value === "formula2") {
      return getTotalViolations(row, "Major");
    } else if (value === "formula3") {
      return getTotalViolations(row, "Minor");
    } else if (value === "formula4") {
      return getTotalCrashes(row);
    } else if (value === "formula5") {
      return getWarningDatosStatus(row);
    } else if (value === "formula6") {
      return getAgeStatus(row);
    } else if (value === "formula7") {
      return getExperienceStatus(row);
    } else if (value === "formula8") {
      return getMajorViolationStatus(row);
    } else if (value === "formula9") {
      return getMinorViolationStatus(row);
    } else if (value === "formula10") {
      return getCrashesStatus(row);
    } else if (value === "formula11") {
      return getCaseByCaseInDrivers(row);
    } else if (value === "formula12") {
      return getTrainingStatus({
        scoreTotal: getDriverScore(row)
      });
    } else if (value === "formula13") {
      return getDriverScore(row).toFixed(4)
    }
    return regularValue(value);
  };

  const driverProfileSwitch = (value, row) => {
    if (value === "formula1") {
      return getDriverScore(row).toFixed(4)
    } else if (value === "formula2") {
      return getTrainingStatus({
        scoreTotal: getDriverScore(row)
      });
    } else if (value === "formula3") {
      return getTotalCrashes(row);
    } else if (value === "formula4") {
      return getTotalClaimAmount(row);
    } else if (value === "formula5") {
      return getFmcsaDriverMirrorData(row)?.age || "";
    } else if (value === "formula6") {
      return getFmcsaDriverMirrorData(row)?.exp || "";
    } else if (value === "formula7") {
      return getTotalViolations(row, "Major");
    } else if (value === "formula8") {
      return getTotalViolations(row, "Minor");
    } else if (value === "formula9") {
      return getTotalViolations(row, "Not Driving Risk");
    } else if (value === "formula10") {
      return getTotalViolations(row, "Not included");
    } else if (value === "formula11") {
      return getCaseByCaseInDrivers(row);
    } else if (value === "formula12") {
      return scoreClaimsCount(row).score;
    } else if (value === "formula13") {
      return scoreClaimsAmount(row).score;
    } else if (value === "formula14") {
      const driver = getFmcsaDriverMirrorData(row);
      return scoreAge(driver).score;
    } else if (value === "formula15") {
      const driver = getFmcsaDriverMirrorData(row);
      return scoreExp(driver).score;
    } else if (value === "formula16") {
      return scoreViolationsMajor(row).score;
    } else if (value === "formula17") {
      return scoreViolationsMinor(row).score;
    } else if (value === "formula18") {
      return scoreViolationsOutOfDrivingRisk(row).score;
    } else if (value === "formula19") {
      return scoreViolationsNotIncluded(row).score;
    }
    return regularValue(value);
  };

  const driverProfileScalingSortWay = [
    "id",
    "numberOfDrivers",
    "riskPremium",
    "frequency",
    "cluster",
    "numberOfClaims",
    "claimsDollarAmount",
    "individualVsAverage",
    "averageClaimAmount",
    "maxExp",
    "minExp",
    "scoreCombined",
    "scoreSeverity",
    "scoreFrequency",
  ]

  const fmcsaViolationsSortWay = [
    "id",
    "inspectionDate",
    "driverName",
    "driverDob",
    "driverAge",
    "violationCode",
    "description",
    "claimAmount",
    "classification",
    "category",
    "caseByCase",
  ]

  const fmcsaDriverFitnessSortWay = [
    "id",
    "no",
    "fullName",
    "nationality",
    "dob",
    "age",
    "exp",
    "inPolicy",
    "totalMajorViolations",
    "totalMinorViolations",
    "claimsCount",
    "riskFactorFrequency",
    "riskFactorSeverity",
    "riskFactorCombined",
    "drvLicUs",
    "drvLicUsIssueDate",
    "drvLicUsExpirationDate",
    "drvLicMx",
    "drvLicMxIssueDate",
    "drvLicMxExpirationDate",
    "noMedPreventiva",
    "licenseClass",
    "notes",
    "warningDatos",
    "ageStatus",
    "expStatus",
    "majorViolationsStatus",
    "minorViolationsStatus",
    "crashesStatus",
    "caseByCaseReview",
    "trainingStatus",
    "scoreTotal",
  ]

  const driverProfileSortWay = [
    "id",
    "fullName",
    "scoreTotal",
    "trainingStatus",
    "claimsCount",
    "claimsAmount",
    "age",
    "exp",
    "violationsMajor",
    "violationsMinor",
    "violationsOutOfDrivingRisk",
    "violationsNotInclude",
    "violationsCaseByCase",
    "scoreClaimsCount",
    "scoreClaimsAmount",
    "scoreAge",
    "scoreExp",
    "scoreViolationsMajor",
    "scoreViolationsMinor",
    "scoreViolationsOutOfDrivingRisk",
    "scoreViolationsNotInclude",
  ]

  return {
    driverProfileScalingSwitch,
    fmcsaViolationsSwitch,
    fmcsaDriverFitnessSwitch,
    driverProfileSwitch,
    driverProfileScalingSortWay,
    fmcsaViolationsSortWay,
    fmcsaDriverFitnessSortWay,
    driverProfileSortWay,




    getTrainingStatus

  };
};
export default useLossControlFormulas;
