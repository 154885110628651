import { addDoc, collection, doc, setDoc } from "firebase/firestore";
import { db } from "../../firebase";

export const createCompanyRequest = async companyData => {
    try {
        const agency = await addDoc(collection(db, "agencies"), companyData);
        return agency
    } catch (error) {
        throw error
    }
}

export const setCompanyByIdRequest = async (agencyID, companyData) => {
    try {
        const applicationRef = doc(db, "agencies", agencyID);
        const agency = await setDoc(applicationRef, companyData, { merge: true });
        return agency
    } catch (error) {
        throw error
    }
}