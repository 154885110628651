import useTranslations from "../../Context/actions/useTranslations";
import styles from "../../assets/styles/pages/new-support-ticket";
import useSupportTicket from "../../hooks/support-ticket/useSupportTicket";
import { typeSupportTicketOptions } from "../../utils/constants";

function NewSupportTicket() {
  const {
    handleChangeSubject,
    handleChangeMessage,
    sendMessage,
    handleChangeType,
    subject,
    message,
    selectedTypeOption,
    disabled
  } = useSupportTicket();

  const { t } = useTranslations();

  return (
    <main className={styles.wrapper}>
      <div className={styles.titleContainer}>
        <h1 className="title-1">{t("support-ticket.title")}</h1>
      </div>
      <select
        className={styles.select}
        value={selectedTypeOption ? selectedTypeOption.id : ""}
        onChange={handleChangeType}
      >
        <option value="">
          {t("support-ticket.select-label")}
        </option>
        {typeSupportTicketOptions.map((option) => (
          <option key={option.id} value={option.id}>
            {option.label}
          </option>
        ))}
      </select>
      <input
        className={styles.input}
        placeholder="Subject"
        type="text"
        onChange={handleChangeSubject}
        value={subject}
      />
      <textarea
        className={styles.input}
        type="text"
        value={message}
        placeholder="Message Body"
        onChange={handleChangeMessage}
      />
      <button
        onClick={sendMessage}
        className={styles.button(disabled)}
        disabled={disabled}
      >
        {t("support-ticket.submit")}
      </button>
    </main>
  );
}

export default NewSupportTicket;
