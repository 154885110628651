import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
} from "@mui/material";
import { db } from "../../../../firebase";
import { collection, getDocs } from "firebase/firestore";

export default function ReportHistory({
  setReportID,
  setShowLossControlReport,
}) {
  // State for pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // State for sorting
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("createdOn");

  const [data, setData] = useState({});

  // Fetch data from Firestore
  useEffect(() => {
    const fetchData = async () => {
      const collectionRef = collection(db, "loss-control-reports");
      const snapshot = await getDocs(collectionRef);
      const data = snapshot.docs.reduce((acc, doc) => {
        acc[doc.id] = { id: doc.id, ...doc.data() }; // Include the document ID
        return acc;
      }, {});
      setData(data);
    };
    fetchData();
  }, []);

  // Extract rows from data object
  const rows = Object.keys(data).map((key) => ({
    id: data[key].id,
    author: data[key].creationData.createdByUser,
    createdOn: data[key].creationData.createdOn,
    namedInsured: data[key].creationData.namedInsured,
  }));

  // Sort and paginate rows
  const sortedRows = rows.slice().sort((a, b) => {
    if (orderBy) {
      if (order === "asc") {
        return a[orderBy] > b[orderBy] ? 1 : -1;
      } else {
        return a[orderBy] < b[orderBy] ? 1 : -1;
      }
    }
    return 0;
  });

  const paginatedRows = sortedRows.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  // Handle sorting
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRowClick = (e, id) => {
    console.log("new id: ", id);
    setReportID(id);
    setShowLossControlReport(true);
  };
  return (
    <>
      <p className="mt-4">
        Select a loss control report to view or edit. Or create a new report.
      </p>
      <div className="my-4">
        <Paper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {["Author", "Created On", "Named Insured"].map((headCell) => (
                    <TableCell
                      key={headCell}
                      sortDirection={orderBy === headCell ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === headCell}
                        direction={orderBy === headCell ? order : "asc"}
                        onClick={() => handleRequestSort(headCell)}
                      >
                        {headCell}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedRows.map((row) => (
                  <TableRow
                    sx={{ cursor: "pointer" }}
                    onClick={(e) => handleRowClick(e, row.id)}
                  >
                    <TableCell>{row.author}</TableCell>
                    <TableCell>
                      {row.createdOn.toDate().toString().slice(4, 15)}
                    </TableCell>
                    <TableCell>{row.namedInsured}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </Paper>
      </div>
    </>
  );
}
