import React from "react";
import useTranslations from "../../../../Context/actions/useTranslations";

export default function AtAGlance(props) {
  const { t } = useTranslations();

  return (
    <div>
      <div className="card mb-2 shadow">
        <div className="card-header bg-[#116582]">
          <h2 className="font-medium text-white">
            {t("account.client-at-a-glance.claims-summary")}
          </h2>
        </div>
        <div className="card-body py-2">
          <h1 className="text-center">
            <span className="text-2xl mr-2">1</span>
            {t("account.client-at-a-glance.open-claims")}
          </h1>
        </div>
        <div className="card-footer">
          <h4 className="text-[#8B0000] font-medium">
            {t("account.client-at-a-glance.start-a-new-claim")}
            <span className="float-right text-green-900">
              {t("account.client-at-a-glance.view-all-claims")}
            </span>
          </h4>
        </div>
      </div>
      <div className="card mb-2 shadow">
        <div className="card-header bg-[#072a48]">
          <h2 className="font-medium text-white">
            {t("account.client-at-a-glance.power-units-summary")}
          </h2>
        </div>
        <div className="card-body py-2">
          <h1 className="text-center">
            <span className="text-2xl mr-2">{props.activeUnits}</span>
            {t("account.client-at-a-glance.active-power-units")}
          </h1>
        </div>
        <div className="card-footer">
          <h4 className="text-[#8B0000] font-medium">
            {t("account.client-at-a-glance.add-a-power-unit")}
            <span className="float-right text-green-900">
              {t("account.client-at-a-glance.view-all-power-units")}
            </span>
          </h4>
        </div>
      </div>
      <div className="card mb-4 shadow">
        <div className="card-header bg-[#8B0000]">
          <h2 className="font-medium text-white">
            {t("account.client-at-a-glance.drivers-summary")}
          </h2>
        </div>
        <div className="card-body py-2">
          <h1 className="text-center">
            <span className="text-2xl mr-2">{props.activeDrivers}</span>
            {t("account.client-at-a-glance.active-drivers")}
          </h1>
        </div>
        <div className="card-footer">
          <h4 className="text-[#8B0000] font-medium">
            {t("account.client-at-a-glance.add-a-driver")}
            <span className="float-right text-green-900">
              {t("account.client-at-a-glance.view-all-drivers")}
            </span>
          </h4>
        </div>
      </div>
    </div>
  );
}
