import React, { useState } from 'react';
import { Card, CardContent, Typography, Grid, TextField } from '@mui/material';

const HiringProcess = ({ handleInputChange }) => {
    // Define sections and questions
    const questions = [
      { id: 'hiringProcedures', label: 'Need hiring procedures and driver DQ file. List of all drivers:', type: 'text' },
      { id: 'recruitmentPractices', label: 'What are your recruitment practices?', type: 'text' },
      { id: 'companyStandards', label: 'What are your company standards in hiring drivers?', type: 'text' },
      { id: 'yearsOfExperience', label: 'Years of experience:', type: 'number' },
      { id: 'language', label: 'Language:', type: 'text' },
      { id: 'violationsAccepted', label: 'How many violations are accepted by the company?', type: 'number' },
      { id: 'proceduresDocument', label: 'Actual procedures on a document.', type: 'text' },
      { id: 'hrOverseer', label: 'Who oversees the driver HR files? - Full name and contact information:', type: 'text' },
      {
        id: 'recommendations',
        label:
          'Recommendations: Run DMV checks every six months for all company drivers to ensure that their CDL is valid and active, also to make sure that their medical card has not expired. Confirm that they do not have any violation of their personal vehicle.',
        type: 'textarea',
      },
      { id: 'hiringProcess', label: 'What is your hiring process?', type: 'text' },
      { id: 'hiringProcessDuration', label: 'How long is your hiring process?', type: 'text' },
      { id: 'callPreviousCompanies', label: 'Do you call previous companies they have worked with?', type: 'text' },
      { id: 'yearsBackResearch', label: 'How many years back do you research before you hire a driver?', type: 'number' },
      { id: 'dismissCriteria', label: 'What will dismiss a driver applicant?', type: 'text' },
    ];
  
    // State to manage responses
    const [responses, setResponses] = useState(
      questions.reduce((acc, { id }) => ({ ...acc, [id]: '' }), {}) // Initialize state dynamically based on questions
    );
  

  return (
    <Card sx={{ maxWidth: 800, margin: 'auto', padding: 2, marginBottom: 2 }}>
      <CardContent>
        <Typography variant="h5" component="div" gutterBottom>
          Hiring Process
        </Typography>
        <Grid container spacing={2}>
          {questions.map(({ id, label, type }) => (
            <Grid item xs={12} key={id}>
              <TextField
                fullWidth
                multiline={type === 'textarea'}
                rows={type === 'textarea' ? 4 : 1}
                variant="outlined"
                label={label}
                value={responses[id] || ''}
                onChange={(e) => handleInputChange(id, e.target.value)}
              />
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default HiringProcess;
