import React from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import updatePolicyField from "../../utils/updatePolicyField";

export default function MyCheckbox({
  policyID,
  formData,
  setFormData,
  field,
  label,
  disabled,
  onChange
}) {
  const isControlled = policyID !== undefined;

  const handleChange = (e) => {
    if (onChange) {
      onChange(e);
    } else {
      const fieldValue = !formData?.[field];
      if (!isControlled) {
        setFormData({
          ...formData,
          [field]: fieldValue,
        });
      } else {
        policyID && updatePolicyField(policyID, field, fieldValue);
      }
    }
  };

  return (
    <FormControlLabel
      control={
        <Checkbox
          id={field}
          checked={isControlled ? formData?.[field] : formData?.[field]}
          onChange={handleChange}
          style={{
            fontSize: "20px",
            color: formData?.[field] ? "#116582" : "#072a48"
          }}
          className="larger-checkbox"
          size="large"
          disabled={disabled}
        />
      }
      label={label}
      className="ml-2"
      disabled={disabled}
    />
  );
}
