const { getDoc, doc } = require("firebase/firestore");
const { db } = require("../../../firebase");

const getEndorsementData = async (policyID, endoID) => {
  if (!endoID) return [null, null, null];
  const endoRef = doc(db, "policy-applications", policyID, "endorsements", endoID);
  const endoSnap = await getDoc(endoRef);
  const endoObj = {
    ...endoSnap.data(),
    id: endoSnap.id
  }
  return [endoRef, endoSnap, endoObj];
};

export default getEndorsementData;