import updatePolicyField from "../../../utils/updatePolicyField";

const handleSubtractCommodity = async (e, i, formData, policyID, setFormData) => {
    e.preventDefault();
    const values = [...formData.commodityFields];
    values.splice(i, 1);
    updatePolicyField(policyID, "commodityFields", values, formData, setFormData);
    if (values.length === 0) {
        updatePolicyField(policyID, "commodityFields", [
            {
              id: 1,
              commodityDescription: "",
              commodityLoad: "0",
            },
          ], formData, setFormData);
    }
    updatePolicyField(policyID, "commodityFields", values, formData, setFormData);
  };

  export default handleSubtractCommodity;