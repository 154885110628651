import { collection, onSnapshot } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../../firebase";
import Modal from "react-modal";
import Breadcrumbs from "../../assets/Breadcrumbs";
import ALandGL from "./ALandGL";
import CreateNewQuote from "./CreateNewQuote";
import { Backdrop, CircularProgress } from "@mui/material";
import createNewQuote from "./utils/createNewQuote";
import countPowerUnits from "./utils/countPowerUnits";
import getQuoteHistory from "./utils/getQuoteHistory";
import clearQuoteList from "./utils/clearQuoteList";
import applyAdjustments from "./utils/applyAdjustments";
import clearAdjustments from "./utils/clearAdjustments";
import { UserAuth } from "../../../Context/AuthContent";
import UnderwritingDetails from "./UnderwritingDetails";
import { Button } from "@mui/material";
import { t } from "i18next";
import PreviousYearsAdjustments from "./PreviousYearsAdjustments";
import BlueButton from "../../assets/forms/BlueButton";
import VisibilityIcon from "@mui/icons-material/Visibility";

Modal.setAppElement("#root");
export default function PolicyQuoteHistory({
  insuredInfo,
  policyID,
  trailerList,
  taxSchedule,
  formData,
  setFormData,
  apdAndMtc,
  userInfo,
  formDataGL,
}) {
  const agencyID = formData.agencyID;
  const insuredID = formData.insuredID;

  const { user: auth } = UserAuth();

  const [powerUnitCount, setPowerUnitCount] = useState(0);

  const [modalIsOpen, setIsOpen] = useState(false);
  function openModal() {
    setIsOpen(true);
  }

  const [quoteHistory, setQuoteHistory] = useState([]);
  const [gotQuoteHistory, setGotQuoteHistory] = useState(false);
  const [showQuoteSuccess, setShowQuoteSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [truckListData, setTruckListData] = useState([]);
  const [adjustmentFormData, setAdjustmentFormData] = useState({
    percentage: 0,
    debitOrCredit: "Debit",
    recipientFields: [""],
    biLiabilityDeductible: "$1,000",
    renewalCredit: 0,
    lossRatioAdjustmentPercentage: 0,
    safetyAdjustmentPercentage: 0,
  });

  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(db, "policy-applications", policyID, "quotes"),
      (querySnapshot) => {
        let values = [];
        querySnapshot.forEach((doc) => {
          values.push({ ...doc.data(), id: doc.id });
        });
        setQuoteHistory(values);
        setGotQuoteHistory(true);
      }
    );

    countPowerUnits(agencyID, insuredID, setPowerUnitCount);

    // Cleanup the listener when the component unmounts
    return () => unsubscribe();
  }, [policyID]);

  const [openAssignDocumentModal, setOpenAssignDocumentModal] = useState(false);

  const handleOpenModal = () => {
    setOpenAssignDocumentModal(true);
  };

  const handleCloseModal = () => {
    setOpenAssignDocumentModal(false);
  };

  const breadcrumbs = [
    { label: formData.documentType },
    { label: formData.documentType === "Policy" ? "Premiums" : "Quotes" },
  ];

  return (
    <>
      {loading ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
          onClick={null}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <main>
          <Breadcrumbs items={breadcrumbs} />
          <h1 className="text-3xl mt-2 font-semibold md:mb-0">
            {formData.documentType === "Policy" ? "Premiums" : "Quotes"}
          </h1>
          {userInfo.role === "Agent" ? (
            <>
              <p className="mt-3 mb-3">
                Select a quote to apply the changes to the premiums. Once you
                have the desired premium option selected for each coverage, you
                may request a bind.
              </p>
            </>
          ) : (
            <>
              <p className="mt-3 mb-3">
                Here you may{" "}
                {formData.documentType !== "Policy" &&
                  " create new quotes and "}
                view premium information.
              </p>
            </>
          )}
          {formData.documentType === "Application" && (
            <p className="mt-2 mb-3">
              Please complete the application and click the "Request Quote"
              button to access premiums & quotes.
            </p>
          )}
          {formData.documentType !== "Policy" &&
            formData.newOrRenewal !== "New Business" && (
              <div>
                <BlueButton
                  text={"Previous Adjustments"}
                  hasContentToBottom={true}
                  onClickFunction={handleOpenModal}
                  icon={<VisibilityIcon />}
                />

                {/* PreviousYearsAdjustments Modal */}
                <PreviousYearsAdjustments
                  formData={formData}
                  insuredID={insuredID}
                  agencyID={agencyID}
                  openAssignDocumentModal={openAssignDocumentModal}
                  setOpenModal={handleCloseModal}
                />
              </div>
            )}
          <div>
            {formData.documentType !== "Application" &&
              formData.documentType !== "Policy" && (
                <UnderwritingDetails
                  agencyID={agencyID}
                  insuredID={insuredID}
                  policyID={policyID}
                  trailerList={trailerList}
                  formData={formData}
                  setFormData={setFormData}
                  apdAndMtc={apdAndMtc}
                  userInfo={userInfo}
                  powerUnitCount={powerUnitCount}
                  insuredInfo={insuredInfo}
                  openModal={openModal}
                  isQuo
                />
              )}
            {formData.documentType !== "Application" && (
              <ALandGL
                setLoading={setLoading}
                adjustmentFormData={adjustmentFormData}
                setAdjustmentFormData={setAdjustmentFormData}
                truckListData={truckListData}
                insuredInfo={insuredInfo}
                setShowQuoteSuccess={setShowQuoteSuccess}
                taxSchedule={taxSchedule}
                getQuoteHistory={() =>
                  getQuoteHistory(policyID, setQuoteHistory, setGotQuoteHistory)
                }
                clearQuoteList={(e) =>
                  clearQuoteList(e, quoteHistory, setQuoteHistory, policyID)
                }
                applyAdjustments={(e, quoteData) =>
                  applyAdjustments(
                    e,
                    quoteData,
                    policyID,
                    formData,
                    taxSchedule,
                    formDataGL
                  )
                }
                userInfo={userInfo}
                formData={formData}
                openModal={openModal}
                quoteHistory={quoteHistory}
                policyID={policyID}
                clearAdjustments={clearAdjustments}
                createNewQuote={(e, isReprint, quoteAdjustmentFormData) =>
                  createNewQuote(
                    e,
                    isReprint,
                    setLoading,
                    quoteAdjustmentFormData,
                    setAdjustmentFormData,
                    truckListData,
                    setShowQuoteSuccess,
                    formData,
                    insuredInfo,
                    auth,
                    formDataGL
                  )
                }
              />
            )}
            {/* Create new quote modal */}
            <CreateNewQuote
              modalIsOpen={modalIsOpen}
              setIsOpen={setIsOpen}
              policyID={policyID}
              formData={formData}
              setFormData={setFormData}
              taxSchedule={taxSchedule}
              insuredInfo={insuredInfo}
              openModal={openModal}
              createNewQuote={(e, isReprint, quoteAdjustmentFormData) =>
                createNewQuote(
                  e,
                  isReprint,
                  setLoading,
                  quoteAdjustmentFormData,
                  setAdjustmentFormData,
                  truckListData,
                  setShowQuoteSuccess,
                  formData,
                  insuredInfo,
                  auth,
                  formDataGL
                )
              }
              loading={loading}
              setLoading={setLoading}
              adjustmentFormData={adjustmentFormData}
              setAdjustmentFormData={setAdjustmentFormData}
              truckListData={truckListData}
              setTruckListData={setTruckListData}
            />
          </div>
        </main>
      )}
    </>
  );
}
