import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../../firebase";
import ApiService from "../../../../services/southern-api/apiService";

const handleBlurCommodity = async (e, policyID, formData, setFormData) => {
  const getModuleValueFromCurrentUrl = () => {
    const url = window.location.href;
    const urlObject = new URL(url);
    return urlObject.searchParams.get("module");
  };
  const moduleValue = getModuleValueFromCurrentUrl();
  const apiService = new ApiService();

  if (Boolean(moduleValue)) {
    setFormData({
      ...formData,
      commodityFields: formData?.commodityFields,
      standardCommodityPercentages: formData?.standardCommodityPercentages,
    });
    await apiService.renewalProcessUpdatePolicyField({
      policyID,
      payload: {
        commodityFields: formData.commodityFields,
        standardCommodityPercentages: formData.standardCommodityPercentages,
      }
    });
  } else {
    const policyRef = doc(db, "policy-applications", policyID);
    await updateDoc(policyRef, {
      commodityFields: formData?.commodityFields,
      standardCommodityPercentages: formData?.standardCommodityPercentages,
    });
  }
};

export default handleBlurCommodity;
