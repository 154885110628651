// src/Components/BugReports/ViewReports.jsx

import React, { useState } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { collection, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  Box
} from '@mui/material';

const ViewReports = () => {
  const [value, loading, error] = useCollection(collection(db, 'bugReports'));
  const [updating, setUpdating] = useState('');

  const handleUpdateStatus = async (id) => {
    setUpdating(id);
    try {
      const reportRef = doc(db, 'bugReports', id);
      await updateDoc(reportRef, { status: 'Complete' });
    } catch (err) {
      console.error('Error updating document: ', err);
    } finally {
      setUpdating('');
    }
  };

  return (
    <Container maxWidth="md">
      <Box mt={5}>
        <Typography variant="h4" gutterBottom>
          Bug Reports
        </Typography>
        {error && <Typography color="error">Error: {error.message}</Typography>}
        {loading && <Typography>Loading...</Typography>}
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Error Location</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {value && value.docs.map(doc => {
                const { title, description, errorLocation, status = 'Submitted' } = doc.data();
                return (
                  <TableRow key={doc.id}>
                    <TableCell>{title}</TableCell>
                    <TableCell>{description}</TableCell>
                    <TableCell>{errorLocation}</TableCell>
                    <TableCell>{status}</TableCell>
                    <TableCell>
                      {status === 'Submitted' && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleUpdateStatus(doc.id)}
                          disabled={updating === doc.id}
                        >
                          {updating === doc.id ? 'Updating...' : 'Mark as Complete'}
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Container>
  );
};

export default ViewReports;
