import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { ACCOUNT_ROUTE } from "../../utils/routes";
import { UserAuth } from "../../Context/AuthContent";

const useSignIn = () => {
  const provider = new GoogleAuthProvider();
  const { signIn } = UserAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await signIn(email, password);
      navigate(ACCOUNT_ROUTE);
    } catch (e) {
      alert("Error Signing In:" + e.message);
    }
  };

  const auth = getAuth();

  const [loginErrorMessage, setLoginErrorMessage] = useState("");
  const googleSignIn = () => {
    signInWithPopup(auth, provider)
      .then(async () => {
        navigate(ACCOUNT_ROUTE);
        localStorage.setItem("loggedInToLicona", "true");
      })
      .catch(() => {
        setLoginErrorMessage("Invalid login. Please contact an administrator.");
      });
  };

  useEffect(() => {
    if (auth.currentUser !== null) navigate(ACCOUNT_ROUTE);
  });

  return {
    loginErrorMessage,
    setEmail,
    setPassword,
    handleSubmit,
    googleSignIn
  }
};

export default useSignIn;
