import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../../firebase";

const mapClassifications = async () => {
    const classificationsRef = collection(
        db,
        "loss-control",
        "tables",
        "classifications"
      );
      const classificationsSnapshot = await getDocs(classificationsRef);
      const classificationsMap = new Map(
        classificationsSnapshot.docs.map((doc) => [
          doc.data().classification,
          doc.data(),
        ])
      );
      return classificationsMap;
}

export default mapClassifications;