import React, { useState } from 'react';
import { Card, CardContent, Typography, Grid, TextField } from '@mui/material';

const VehicleMaintenanceAnalysis = ({ handleInputChange }) => {
  // Define questions and state management
  const questions = [
    { id: 'ownerOperator', label: 'Owner Operator?', type: 'text' },
    { id: 'shopLocation', label: 'Shop location:', type: 'text' },
    { id: 'maintenanceLocation', label: 'Where is the vehicle maintenance done?', type: 'text' },
    { id: 'ownOrThirdParty', label: 'Own or third party?', type: 'text' },
    { id: 'mechanicCount', label: 'How many mechanics?', type: 'number' },
    { id: 'mechanicLeader', label: 'Mechanic leader?', type: 'text' },
    { id: 'mechanicDuties', label: 'Duties of each one:', type: 'textarea' },
    { id: 'inventoryTruckParts', label: 'Truck parts inventory:', type: 'textarea', placeholder: 'Date in, Date out' },
    { id: 'invoicesOnFile', label: 'Invoices on file:', type: 'textarea' },
    { id: 'workOrdersOnFile', label: 'Work orders on file:', type: 'textarea' },
    { id: 'repairmentExplanation', label: 'Brief explanation of the repairment:', type: 'textarea' },
    { id: 'inventoryEquipment', label: 'Inventory of equipment:', type: 'textarea' },
    { id: 'equipmentFullName', label: 'Full name:', type: 'text' },
    { id: 'equipmentPhoneNumber', label: 'Phone number:', type: 'text' },
    { id: 'equipmentEmail', label: 'Email address:', type: 'email' },
    { id: 'orderProcess', label: 'What is the process for ordering new equipment?', type: 'textarea' },
    { id: 'approvalProcess', label: 'Does it go through an approval process?', type: 'text' },
    { id: 'oversightOfNewPersonnel', label: 'More units, more work for the team, who oversees if more personnel is needed?', type: 'textarea' },
    { id: 'orderingFrequency', label: 'How often is new equipment ordered?', type: 'text' },
    { id: 'ageConsideration', label: 'Do we consider the age of the equipment to replace older trucks?', type: 'text' },
    { id: 'personInCharge', label: 'Who is the person in charge of equipment ordering?', type: 'textarea' },
    { id: 'insuranceNotification', label: 'Who is responsible for notifying insurance of equipment changes?', type: 'text' },
    { id: 'outOfServiceEquipment', label: 'Who is responsible for totaled/out of service equipment?', type: 'text' },
    { id: 'checklistPreTrip', label: 'Checklist and pre-trip, how is it ensured this is done correctly?', type: 'textarea' },
    { id: 'roadTripInspection', label: 'On-the-road trip inspection, post-trip:', type: 'textarea' },
    { id: 'maintenanceFilesOversight', label: 'Who oversees vehicle maintenance files?', type: 'text' },
    { id: 'preventativeMaintenance', label: 'What is your preventative maintenance procedure?', type: 'textarea' },
    { id: 'inspectionFrequency', label: 'How often are vehicles inspected? Signed documents or samples:', type: 'textarea' },
    { id: 'maintenanceFrequency', label: 'How often is vehicle maintenance?', type: 'textarea' },
    { id: 'milesIntervals', label: 'Miles intervals:', type: 'text' },
    { id: 'maintenanceChecklist', label: 'Do we have a checklist for maintenance?', type: 'text' },
    { id: 'badPointsUnits', label: 'Units with bad points (CAB):', type: 'textarea' },
  ];

  // State to manage responses
  const [responses, setResponses] = useState(
    questions.reduce((acc, { id }) => ({ ...acc, [id]: '' }), {})
  );

  return (
    <Card sx={{ maxWidth: 800, margin: 'auto', padding: 2, marginBottom: 2 }}>
      <CardContent>
        <Typography variant="h5" component="div" gutterBottom>
          Vehicle Maintenance Analysis
        </Typography>
        <Grid container spacing={2}>
          {questions.map(({ id, label, type, placeholder }) => (
            <Grid item xs={12} key={id}>
              <TextField
                fullWidth
                multiline={type === 'textarea'}
                rows={type === 'textarea' ? 4 : 1}
                variant="outlined"
                label={label}
                type={type === 'date' ? 'date' : 'text'}
                value={responses[id] || ''}
                onChange={(e) => handleInputChange(id, e.target.value)}
                placeholder={placeholder || ''}
              />
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default VehicleMaintenanceAnalysis;
