import React from "react";
import { t } from "i18next";
import numberWithCommas from "../../../utils/numberWithCommas";

const LossDataDisplay = ({ formData, year, resetLosses, coverageType }) => {
  return (
    <div className="my-4">
      <article>
        <p className="mb-2">
          <span className="font-semibold">{t("Carrier")}: </span>
          {
            formData.lossHistory?.[`year${year + "" + coverageType}LossData`]
              .carrier
          }
        </p>
        <p className="mb-2">
          <span className="font-semibold">{t("Number of Units")}: </span>
          {
            formData.lossHistory?.[`year${year + "" + coverageType}LossData`]
              .numberOfUnits
          }
        </p>
        <p className="mb-2">
          <span className="font-semibold">{t("TIV")}: $</span>
          {parseInt(formData.lossHistory?.[
            `year${year + "" + coverageType}LossData`
          ].tiv).toFixed(2)}
        </p>
        <p className="mb-2">
          <span className="font-semibold">{t("Deductible")}: $</span>
          {parseInt(formData.lossHistory?.[
            `year${year + "" + coverageType}LossData`
          ].deductible).toFixed(2)}
        </p>
        <p className="mb-2">
          <span className="font-semibold">{t("Mileage")}: $</span>
          {parseInt(formData.lossHistory?.[
            `year${year + "" + coverageType}LossData`
          ].mileage).toFixed(2)}
        </p>
        <p className="mb-2">
          <span className="font-semibold">{t("Losses")}: $</span>
          {numberWithCommas(
            parseInt(formData.lossHistory?.[
              `year${year + "" + coverageType}LossData`
            ].losses).toFixed(2)
          )}
        </p>
        <p className="mb-2">
          <span className="font-semibold">{t("No. Of Claims")}: </span>
          {
            formData.lossHistory?.[`year${year + "" + coverageType}LossData`]
              .howManyClaims
          }
        </p>
      </article>
      {formData.documentType === "Application" && (
        <button
          onClick={(e) =>
            resetLosses(e, `year${year + "" + coverageType}LossData`)
          }
          className="bg-[#8B0000] text-white w-full p-2 mt-4 rounded"
        >
          {t("Reset")}
        </button>
      )}
    </div>
  );
};

export default LossDataDisplay;
