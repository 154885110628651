import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import parseAddress from 'parse-address';

const replaceUndefinedWithEmptyString = (data) => {
  if (Array.isArray(data)) {
    return data.map(item => replaceUndefinedWithEmptyString(item));
  } else if (typeof data === 'object' && data !== null) {
    const result = {};
    for (const [key, value] of Object.entries(data)) {
      result[key] = replaceUndefinedWithEmptyString(value);
    }
    return result;
  } else if (data === undefined) {
    return '';
  } else {
    return data;
  }
};

const importQuestions = async (policyID, excelRows) => {
  try {
    const rows = excelRows;
    const indexOfFields = {
      contactName: 5,
      contactPhone: 6,
      contactFax: 7,
      contactEmail: 8,
      MCNumber: 28,
      businessStructure: 14,
      cancelledPast3Years: 33,
      carrierType: 25,
      contractForWhom: 26,
      currentFinancials: 18,
      dba: 3,
      describeCancelledRenewed: 34,
      describeDriverIncentives: 105,
      describeNonTruckingBiz: 36,
      describeOperatedDifferentName: 21,
      describeOrientation: 103,
      describeSafetyProgram: 99,
      describeSubsidiaries: 23,
      descriptionOfOps: 24,
      driverIncentives: 104,
      driverMVRsReviewed: 93,
      driverMinExp: 90,
      driversDrugTestedAfterHiring: 92,
      driversDrugTestedPriorToHire: 91,
      email: 8,
      everFiledForBankruptcy: 19,
      fax: 7,
      federalTaxID: 16,
      formalOrientation: 102,
      fullTimeSafetyDirector: 106,
      haveSubsidiaries: 22,
      hireDriversLess2YOE: 89,
      inHouseMaintenance: 111,
      locationField0: 10,
      locationField1: 11,
      locationField2: 12,
      locationField3: 13,
      involvedNonTruckingBiz: 35,
      latestDOTRating: 29,
      mailingAddress: 4,
      maintenanceLogbooks: 109,
      medicalCertificatesReviewed: 94,
      name: 2,
      operatedUnderDiffName: 20,
      phone: 6,
      predominantStates: 62,
      primaryContact: 5,
      regularInspectionsOfEquip: 113,
      regularSafetyMeetings: 100,
      roadTest: 95,
      safetyDirectorName: 107,
      safetyMeetingFrequency: 101,
      safetyOfficerYOE: 108,
      travelThroughMetros: 61,
      typesOfRepairShopHandles: 112,
      writtenSafetyPrograms: 98,
      writtenTest: 96,
      yearOfLatestDOTRating: 30,
      yearsInBusiness: 17,
    };
    const indexOfField = (fieldName) => {
      return indexOfFields[fieldName];
    };
  
    const policyRef = doc(db, "policy-applications", policyID);
    const policyDocument = await getDoc(policyRef);
    const policyData = policyDocument.data();
  
    const companyRef = doc(
      db,
      "agencies",
      "JiAfXAkc0Bjr4OXfqSv9",
      "insureds",
      policyData.insuredID
    );
  
    const locationFields = [...(policyData?.questionnaire?.locationFields || [])];
  
    // if there is data in the location fields from the excel sheet, push them to the locations
    if (rows[indexOfField("locationField0")][4]) {
      const parsed = parseAddress.parseLocation(rows[indexOfField("locationField0")][4]);
      const address = ((parsed?.number || '') + (parsed?.prefix ? ' ' + parsed.prefix : '') + ' ' + (parsed?.street || '') + ' ' + (parsed?.type || ''))?.toUpperCase() || ''
      const city = parsed?.city?.toUpperCase() || ''
      const state = parsed?.state?.toUpperCase() || ''
      const zip = parsed?.zip
      locationFields.push({      
        address,
        city,
        state,
        zip
      });
    } else if (rows[indexOfField("locationField1")][4]) {
      const parsed = parseAddress.parseLocation(rows[indexOfField("locationField1")][4]);
      const address = ((parsed?.number || '') + (parsed?.prefix ? ' ' + parsed.prefix : '') + ' ' + (parsed?.street || '') + ' ' + (parsed?.type || ''))?.toUpperCase() || ''
      const city = parsed?.city?.toUpperCase() || ''
      const state = parsed?.state?.toUpperCase() || ''
      const zip = parsed?.zip
      locationFields.push({      
        address,
        city,
        state,
        zip
      });
    } else if (rows[indexOfField("locationField2")][4]) {
      const parsed = parseAddress.parseLocation(rows[indexOfField("locationField2")][4]);
      const address = ((parsed?.number || '') + (parsed?.prefix ? ' ' + parsed.prefix : '') + ' ' + (parsed?.street || '') + ' ' + (parsed?.type || ''))?.toUpperCase() || ''
      const city = parsed?.city?.toUpperCase() || ''
      const state = parsed?.state?.toUpperCase() || ''
      const zip = parsed?.zip
      locationFields.push({      
        address,
        city,
        state,
        zip
      });
    } else if (rows[indexOfField("locationField3")][4]) {
      const parsed = parseAddress.parseLocation(rows[indexOfField("locationField3")][4]);
      const address = ((parsed?.number || '') + (parsed?.prefix ? ' ' + parsed.prefix : '') + ' ' + (parsed?.street || '') + ' ' + (parsed?.type || ''))?.toUpperCase() || ''
      const city = parsed?.city?.toUpperCase() || ''
      const state = parsed?.state?.toUpperCase() || ''
      const zip = parsed?.zip
      locationFields.push({      
        address,
        city,
        state,
        zip
      });
    }
  
    const harvestedData = {
      locationFields,
      businessStructure: rows[indexOfField("businessStructure")][4] || "",
      everFiledForBankruptcy:
        rows[indexOfField("everFiledForBankruptcy")][2] === 1
          ? "Yes"
          : rows[indexOfField("everFiledForBankruptcy")][3] === 1
          ? "No"
          : "",
      operatedUnderDiffName:
        rows[indexOfField("operatedUnderDiffName")][2] === 1
          ? "Yes"
          : rows[indexOfField("operatedUnderDiffName")][3] === 1
          ? "No"
          : "",
      describeOperatedDifferentName:
        rows[indexOfField("describeOperatedDifferentName")][4] || "",
      haveSubsidiaries:
        rows[indexOfField("haveSubsidiaries")][2] === 1
          ? "Yes"
          : rows[indexOfField("haveSubsidiaries")][3] === 1
          ? "No"
          : "",
      describeSubsidiaries: rows[indexOfField("describeSubsidiaries")][4] || "",
      descriptionOfOps: rows[indexOfField("descriptionOfOps")][4] || "",
      MCNumber:
        rows[indexOfField("MCNumber")][2] === 1
          ? "Yes"
          : rows[indexOfField("MCNumber")][3] === 1
          ? "No"
          : "",
      latestDOTRating: rows[indexOfField("latestDOTRating")][4] || "",
      yearOfLatestDOTRating: rows[indexOfField("yearOfLatestDOTRating")][4] || "",
      cancelledPast3Years:
        rows[indexOfField("cancelledPast3Years")][2] === 1
          ? "Yes"
          : rows[indexOfField("cancelledPast3Years")][3] === 1
          ? "No"
          : "",
      describeCancelledRenewed:
        rows[indexOfField("describeCancelledRenewed")][4] || "",
      involvedNonTruckingBiz:
        rows[indexOfField("involvedNonTruckingBiz")][2] === 1
          ? "Yes"
          : rows[indexOfField("involvedNonTruckingBiz")][3] === 1
          ? "No"
          : "",
      describeNonTruckingBiz:
        rows[indexOfField("describeNonTruckingBiz")][4] || "",
      travelThroughMetros:
        rows[indexOfField("travelThroughMetros")][2] === 1
          ? "Yes"
          : rows[indexOfField("travelThroughMetros")][3] === 1
          ? "No"
          : "",
      predominantStates: rows[indexOfField("predominantStates")][4] || "",
      hireDriversLess2YOE:
        rows[indexOfField("hireDriversLess2YOE")][2] === 1
          ? "Yes"
          : rows[indexOfField("hireDriversLess2YOE")][3] === 1
          ? "No"
          : "",
      driverMinExp: rows[indexOfField("driverMinExp")][4] || "",
      driversDrugTestedPriorToHire:
        rows[indexOfField("driversDrugTestedPriorToHire")][2] === 1
          ? "Yes"
          : rows[indexOfField("driversDrugTestedPriorToHire")][3] === 1
          ? "No"
          : "",
      driversDrugTestedAfterHiring:
        rows[indexOfField("driversDrugTestedAfterHiring")][2] === 1
          ? "Yes"
          : rows[indexOfField("driversDrugTestedAfterHiring")][3] === 1
          ? "No"
          : "",
      driverMVRsReviewed:
        rows[indexOfField("driverMVRsReviewed")][2] === 1
          ? "Yes"
          : rows[indexOfField("driverMVRsReviewed")][3] === 1
          ? "No"
          : "",
      medicalCertificatesReviewed:
        rows[indexOfField("medicalCertificatesReviewed")][2] === 1
          ? "Yes"
          : rows[indexOfField("medicalCertificatesReviewed")][3] === 1
          ? "No"
          : "",
      roadTest:
        rows[indexOfField("roadTest")][2] === 1
          ? "Yes"
          : rows[indexOfField("roadTest")][3] === 1
          ? "No"
          : "",
      writtenTest:
        rows[indexOfField("writtenTest")][2] === 1
          ? "Yes"
          : rows[indexOfField("writtenTest")][3] === 1
          ? "No"
          : "",
      writtenSafetyPrograms:
        rows[indexOfField("writtenSafetyPrograms")][2] === 1
          ? "Yes"
          : rows[indexOfField("writtenSafetyPrograms")][3] === 1
          ? "No"
          : "",
      describeSafetyProgram: rows[indexOfField("describeSafetyProgram")][4] || "",
      regularSafetyMeetings:
        rows[indexOfField("regularSafetyMeetings")][2] === 1
          ? "Yes"
          : rows[indexOfField("regularSafetyMeetings")][3] === 1
          ? "No"
          : "",
      safetyMeetingFrequency:
        rows[indexOfField("safetyMeetingFrequency")][4] || "",
      formalOrientation:
        rows[indexOfField("formalOrientation")][2] === 1
          ? "Yes"
          : rows[indexOfField("formalOrientation")][3] === 1
          ? "No"
          : "",
      describeOrientation: rows[indexOfField("describeOrientation")][4] || "",
      driverIncentives:
        rows[indexOfField("driverIncentives")][2] === 1
          ? "Yes"
          : rows[indexOfField("driverIncentives")][3] === 1
          ? "No"
          : "",
      describeDriverIncentives:
        rows[indexOfField("describeDriverIncentives")][4] || "",
      fullTimeSafetyDirector:
        rows[indexOfField("fullTimeSafetyDirector")][2] === 1
          ? "Yes"
          : rows[indexOfField("fullTimeSafetyDirector")][3] === 1
          ? "No"
          : "",
      safetyDirectorName: rows[indexOfField("safetyDirectorName")][4] || "",
      safetyOfficerYOE: rows[indexOfField("safetyOfficerYOE")][4] || "",
      maintenanceLogbooks:
        rows[indexOfField("maintenanceLogbooks")][2] === 1
          ? "Yes"
          : rows[indexOfField("maintenanceLogbooks")][3] === 1
          ? "No"
          : "",
      inHouseMaintenance:
        rows[indexOfField("inHouseMaintenance")][2] === 1
          ? "Yes"
          : rows[indexOfField("inHouseMaintenance")][3] === 1
          ? "No"
          : "",
      regularInspectionsOfEquip:
        rows[indexOfField("regularInspectionsOfEquip")][2] === 1
          ? "Yes"
          : rows[indexOfField("regularInspectionsOfEquip")][3] === 1
          ? "No"
          : "",
    };
  
    let carrierType;
    if (rows[indexOfField("carrierType")]?.[4]?.toLowerCase()?.includes("common")) {
      carrierType = "Common";
    } else if (
      rows[indexOfField("carrierType")]?.[4]?.toLowerCase()?.includes("contract")
    ) {
      carrierType = "Contract";
    } else if (
      rows[indexOfField("carrierType")]?.[4]?.toLowerCase()?.includes("private")
    ) {
      carrierType = "Private";
    } else if (
      rows[indexOfField("carrierType")]?.[4]?.toLowerCase()?.includes("other")
    ) {
      carrierType = "Other";
    }
  
    let businessStructure;
    if (rows[indexOfField("businessStructure")]?.[4]?.toLowerCase()?.includes("sole") || rows[indexOfField("businessStructure")]?.[4]?.toLowerCase()?.includes("proprietorship")) {
      businessStructure = "Sole Proprietorship";
    } else if (rows[indexOfField("businessStructure")]?.[4]?.toLowerCase()?.includes("corporation")) {
      businessStructure = "Corporation";
    } else if (rows[indexOfField("businessStructure")]?.[4]?.toLowerCase()?.includes("partnership")) {
      businessStructure = "Partnership";
    } else if (rows[indexOfField("businessStructure")]?.[4]?.toLowerCase()?.includes("llc")) {
      businessStructure = "LLC";
    }
  
    const questionnairePayLoad = {
      ...policyData.questionnaire,
      ...harvestedData,
      locationFields,
      carrierType,
      businessStructure
    };
  
    const primaryContactPayLoad = {
      primaryContact: rows[indexOfField("primaryContact")][4] || "",
      phone: rows[indexOfField("phone")][4]
        ? rows[indexOfField("phone")][4].replace(/[^0-9]/g, "")
        : "",
      fax: rows[indexOfField("fax")][4] || "",
      email: rows[indexOfField("email")][4] || "",
    };

    const questionnairePayload = replaceUndefinedWithEmptyString(questionnairePayLoad)
  
    await updateDoc(policyRef, {
      questionnaire: questionnairePayload,
    });
  
    await updateDoc(companyRef, {
      ...primaryContactPayLoad,
    });
  } catch (error) {
    throw error
  }
};

export default importQuestions;
