const performJoins = async (
  violationsQuerySnapshot,
  eventualitiesMap,
  classificationsMap,
  useArrayInstead,
  violationsArray
) => {
  // Join Violtaions with Eventualities and Classifications
  let fullyJoinedViolations = [];
  let data;

  // Choose data source based on useArrayInstead flag
    if (useArrayInstead) {
      data = violationsArray;
    } else {
      // Extract data from Firestore documents and include document ID
      data = violationsQuerySnapshot.docs.map(doc => ({
        id: doc.id, // Include the document ID
        ...doc.data()
      }));
    }
  
  // Process each violation
  for (let i = 0; i < data.length; i++) {
    const violationData = data[i];

    // Join with eventualities
    const eventualityData = eventualitiesMap.get(violationData.violationCode) || {};
    let joinedViolation = {
      ...violationData,
      ...eventualityData,
    };

    // Join with classifications
    if (joinedViolation && eventualityData.classification) {
      const classificationData = classificationsMap.get(
        eventualityData.classification
      );
      if (classificationData) {
        joinedViolation = {
          ...joinedViolation,
          ...classificationData,
        };
      }
    }

    fullyJoinedViolations.push(joinedViolation);
  }

  return fullyJoinedViolations;
};

export default performJoins;
