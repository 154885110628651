import React from "react";
import {
  UNDERWRITING_ENDORSEMENT_ROUTE,
  OLD_AGENT_DIRECTORY_ROUTE,
  AGENT_DIRECTORY_ROUTE,
  FILING_LOG_ROUTE,
  LOSS_RUNS_LIST_ROUTE,
  SUPPORT_TICKETS_LIST_ROUTE,
  LOSS_CONTROL_ROUTE,
  BLACKLIST_ROUTE,
  SCT_ROUTE,
  AGENT_MANAGE_USERS_ROUTE,
  AGENT_MANAGE_CLIENTS_ROUTE,
  AGENT_COMPANY_PROFILE_ROUTE,
  AGENT_MANAGE_NOTIFICATIONS_ROUTE,
  DRIVERS_TRAININGS_ROUTE,
  SUBMIT_BUG_ROUTE,
  VIEW_BUGS_ROUTE,
  FINANCES_ROUTE,
} from "../../../utils/routes";
import { Nav, NavDropdown } from "react-bootstrap";
import useTranslations from "../../../Context/actions/useTranslations";
import {
  Group,
  EmojiObjects,
  People,
  Business,
  Notifications,
  ListAlt,
  FileCopy,
  Support,
  BarChart,
  Block,
  Star,
  DirectionsCar,
  BugReport,
  AttachMoney,
} from "@mui/icons-material";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";

export default function UnderwriterManagerMenu({ userInfo }) {
  const { t } = useTranslations();
  return (
    <>
      {userInfo.policyPermissions && (
        <NavDropdown.Item href={UNDERWRITING_ENDORSEMENT_ROUTE}>
          <EmojiObjects className="mr-2" /> Endorsements
        </NavDropdown.Item>
      )}
      {userInfo.userPermissions && (
        <NavDropdown.Item href={AGENT_MANAGE_USERS_ROUTE}>
          <People className="mr-2" /> Users
        </NavDropdown.Item>
      )}
      {userInfo.clientPermissions && (
        <NavDropdown.Item href={AGENT_MANAGE_CLIENTS_ROUTE}>
          <SupervisedUserCircleIcon className="mr-2" /> Clients
        </NavDropdown.Item>
      )}
      {!userInfo.notificationPermissions && (
        <NavDropdown.Item href={AGENT_MANAGE_NOTIFICATIONS_ROUTE}>
          <Notifications className="mr-2" /> Notifications
        </NavDropdown.Item>
      )}
      <NavDropdown.Item href={LOSS_RUNS_LIST_ROUTE}>
        <ListAlt className="mr-2" /> Loss Runs
      </NavDropdown.Item>
      {/* <NavDropdown.Item href={FILING_LOG_ROUTE}>
        <FileCopy className="mr-2" /> Logs
      </NavDropdown.Item> */}
      <NavDropdown.Item href={SUPPORT_TICKETS_LIST_ROUTE}>
        <Support className="mr-2" /> Tickets
      </NavDropdown.Item>
      <NavDropdown.Item href={LOSS_CONTROL_ROUTE}>
        <BarChart className="mr-2" /> Loss Control
      </NavDropdown.Item>
      <NavDropdown.Item href={BLACKLIST_ROUTE}>
        <Block className="mr-2" /> Blacklist
      </NavDropdown.Item>
      <NavDropdown.Item href={SCT_ROUTE}>
        <Star className="mr-2" /> SCT
      </NavDropdown.Item>
      <NavDropdown.Item href={DRIVERS_TRAININGS_ROUTE}>
        <DirectionsCar className="mr-2" /> Drivers Trainings
      </NavDropdown.Item>
      <NavDropdown.Item href={SUBMIT_BUG_ROUTE}>
        <BugReport className="mr-2" /> {t("Submit Bug Report")}
      </NavDropdown.Item>
      <NavDropdown.Item href={VIEW_BUGS_ROUTE}>
        <BugReport className="mr-2" /> {t("View Bug Reports")}
      </NavDropdown.Item>
      <NavDropdown.Item href={FINANCES_ROUTE}>
        <AttachMoney className="mr-2" /> Finance
      </NavDropdown.Item>
    </>
  );
}
