const axios = require("axios");

class HttpService {
  constructor(baseURL) {
    this._axiosInstance = axios.create({
      baseURL: baseURL,
    });
  }

  async _makeRequest(method, url, data = {}) {
    try {
      const response = await this._axiosInstance[method](url, data);
      return response.data;
    } catch (error) {
      console.error(`Error in ${method.toUpperCase()} request:`, error);
      throw error;
    }
  }

  async _get(url, params = {}) {
    try {
      return await this._makeRequest("get", url, { params });
    } catch (error) {
      throw error
    }
  }

  async _post(url, data = {}) {
    try {
      return await this._makeRequest("post", url, data);
    } catch (error) {
      throw error
    }
  }

  async _put(url, data = {}) {
    try {
      return await this._makeRequest("put", url, data);
    } catch (error) {
      throw error
    }
  }

  async _delete(url) {
    try {
      return await this._makeRequest("delete", url);
    } catch (error) {
      throw error
    }
  }
}

export default HttpService;
