import React, { useState } from "react";
import {
  collection,
  addDoc,
} from "firebase/firestore";
import { db, auth } from "../../../firebase";
import SouthernStarCompanySelection from "../../toRetireOrReplace/SouthernStarCompanySelection";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import NORPreview from "./NORPreview";
import axios from "axios";
import NOCPreview from "./NOCPreview";
import PNONRPreview from "./PNONRPreview";
import CompanyProfile from "./CompanyProfile";
import useUsersState from "../../../Context/actions/useUsersState";
import norandnocStatusChangeNotification from "../../utils/notifications/norandnocStatusChangeNotification";
import config from "../../../config/env";

function SendLetterPanel() {
  const [display, setDisplay] = useState(true);
  const { user } = useUsersState();

  const [showCompanySelection, setShowCompanySelection] = useState(true);
  const [selected, setSelected] = useState("");
  const [selectedCompanyInfo, setSelectedCompanyInfo] = useState();
  const [policyTerm, setPolicyTerm] = useState("");
  const [letterPolicyNumber, setLetterPolicyNumber] = useState();
  const [letter2ndPolicyNumber, setLetter2ndPolicyNumber] = useState();
  const [letterPolicyEffectiveDate, setLetterPolicyEffectiveDate] =
    useState("");
    
  const [dateOfReinstatement, setDateOfReinstatement] = useState("");
  const [letter, setLetter] = useState("");
  const [showLetterEditor, setShowLetterEditor] = useState(false);
  const [NOCReasonExplanation, setNOCReasonExplanation] = useState("");

  const expirationDate = dateOfReinstatement.toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric', });

  const { REACT_APP_SOUTHERN_BACKEND } = config

  const [loading, setLoading] = useState(false);
  const [fileURL, setFileURL] = useState("");
  const showPreview = async (e) => {
    e.preventDefault();
    setShowLetterEditor(false);
    setShowLetterPreview(true);
  };

  const handleChangeLetterType = (e, letterType) => {
    e.preventDefault();
    setLetter(letterType);
    setShowLetterEditor(true);
  };

  // TODO: Add better error handling to letters

  const [NOCReason, setNOCReason] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const handleSendLetter = async (e) => {
    e.preventDefault();
    try {
      const letterData = {
        created: new Date(),
        recipientID: selectedCompanyInfo.id,
        recipientCompanyName: selectedCompanyInfo.company,
        recipientAddress: selectedCompanyInfo.address,
        recipientCity: selectedCompanyInfo.city,
        recipientState: selectedCompanyInfo.state,
        mailingDate: new Date().toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric', }),
        recipientZip: selectedCompanyInfo.zip,
        pdfURL: fileURL,
        policyNumberOne: letterPolicyNumber,
        policyNumberTwo: letter2ndPolicyNumber,
        policyTerm: policyTerm,
        NOCReason: NOCReason !== "Other" ? NOCReason : NOCReasonExplanation,
        expirationDate,
        effectiveDateOfReinstatement: dateOfReinstatement.toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric', }),
        uneditedDate: dateOfReinstatement.toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric', }),
        description:
          letter +
          " for " +
          selectedCompanyInfo.company +
          " on " +
          new Date().toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric', }),
      };
      // Create Entry that triggers zap
      if (letter === "NOR") await addDoc(collection(db, "NORs"), letterData);
      if (letter === "NOC") await addDoc(collection(db, "NOCs"), letterData);
      if (letter === "PNONR")
        await addDoc(collection(db, "PNONRs"), letterData);

      //   Log to Letter Log
      await addDoc(collection(db, "letters"), {
        authorID: auth.currentUser.uid,
        authorName: auth.currentUser.displayName,
        createdOn: new Date(),
        letterRecipient: selectedCompanyInfo.company,
        letterType: letter,
      });

      // Log System Activity to System Log
      await addDoc(collection(db, "system-activity"), {
        activityDate: new Date(),
        authorID: auth.currentUser.uid,
        authorName: auth.currentUser.displayName,
        description:
          letter +
          " sent via USPS certified mail to " +
          selectedCompanyInfo.company,
      });
      if (letter === "NOC") {
        sendEmail(e, "/send-email/notice-of-cancellation");
      } else if (letter === "NOR")
        sendEmail(e, "/send-email/notice-of-reinstatement");
    } catch (error) {
      // Handle errors
      console.error("Error sending letter:", error);
      alert("There was an error sending the letter. Please try again.");
    } finally {
      setShowSuccess(true);
    }
  };

  const [showLetterPreview, setShowLetterPreview] = useState(false);

  const resetPage = (e) => {
    e.preventDefault();
    setSelected("");
    setLetter("");
    setFileURL("");
    setLetter2ndPolicyNumber("");
    setDateOfReinstatement("");
    setLetterPolicyNumber("");
    setLetterPolicyEffectiveDate("");
    setPolicyTerm("");
    setShowSuccess(false);
    setShowLetterEditor(false);
    setShowLetterPreview(false);
    setShowCompanySelection(true);
  };

  const sendEmail = async (e, route) => {
    e.preventDefault();

    const notificationPayload = await norandnocStatusChangeNotification()

    axios
      .post(REACT_APP_SOUTHERN_BACKEND + route, {
        // to: "dlicona@liconainsurance.com",
        // cc: [
        //   "kcorona@liconainsurance.com",
        //   "felicona@liconainsurance.com",
        //   "colt@southernstarmga.com",
        // ],
        to: notificationPayload.to,
        cc: notificationPayload.cc,
        created: new Date(),
        recipientID: selectedCompanyInfo.id,
        recipientCompanyName: selectedCompanyInfo.company,
        recipientAddress: selectedCompanyInfo.address,
        recipientCity: selectedCompanyInfo.city,
        recipientState: selectedCompanyInfo.state,
        mailingDate: new Date().toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric', }),
        recipientZip: selectedCompanyInfo.zip,
        pdfURL: fileURL,
        policyNumberOne: letterPolicyNumber,
        policyNumberTwo: letter2ndPolicyNumber,
        policyTerm: policyTerm,
        NOCReason: NOCReason !== "Other" ? NOCReason : NOCReasonExplanation,
        effectiveDateOfReinstatement: dateOfReinstatement.toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric', }),
        uneditedDate: dateOfReinstatement,
        description:
          letter +
          " for " +
          selectedCompanyInfo.company +
          " on " +
          new Date().toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric', }),
      })
      .then(async (response) => {
        // handle success
      })
      .then(() => {})
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  };

  return (
    <main>
      {user.role === "Underwriter" || user.role === "Admin" ? (
        <section>
          <div className="card my-3">
            <div className="card-header bg-gray-200">
              <button
                onClick={(e) => setDisplay(!display)}
                className="text-xl font-bold text-[#072a48]"
              >
                Send Letters
              </button>
            </div>
            {display && (
              <section>
                {!showSuccess ? (
                  <div className="card-body">
                    <section>
                      <p className="mb-3">
                        In this panel you may send letters to insureds.
                      </p>
                      {showCompanySelection && selected === "" && (
                        <section>
                          <div>
                            <SouthernStarCompanySelection
                              selected={selected}
                              setSelected={setSelected}
                              setShowCompanySelection={setShowCompanySelection}
                              setShowLetterEditor={setShowLetterEditor}
                            />
                          </div>
                        </section>
                      )}
                      {selected && !showLetterPreview && (
                        <div>
                          <CompanyProfile
                            agencyID={selected}
                            setSelected={setSelected}
                            selectedCompanyInfo={selectedCompanyInfo}
                            setSelectedCompanyInfo={setSelectedCompanyInfo}
                          />
                          <button
                            onClick={(e) => {
                              setSelected("");
                              setLetter("");
                              setShowLetterEditor(false);
                              setShowLetterPreview(false);
                              setShowCompanySelection(true);
                            }}
                            className="text-[#8B0000] my-2 d-block"
                          >
                            Choose Another Insured
                          </button>
                          {letter === "" ? (
                            <div>
                              <button
                                onClick={(e) =>
                                  handleChangeLetterType(e, "NOC")
                                }
                                className="text-[#072a48] d-block text-left"
                              >
                                Send Notice of Cancellation
                              </button>
                              <button
                                onClick={(e) =>
                                  handleChangeLetterType(e, "NOR")
                                }
                                className="text-[#072a48] d-block text-left"
                              >
                                Send Notice of Reinstatement
                              </button>
                              <button
                                onClick={(e) =>
                                  handleChangeLetterType(e, "PNONR")
                                }
                                className="text-[#072a48] d-block text-left"
                              >
                                Send Provisional Notice of Non-Renewal
                              </button>
                            </div>
                          ) : (
                            <>
                              <button
                                onClick={(e) => {
                                  setLetter("");
                                  setShowLetterPreview(false);
                                  setShowCompanySelection(false);
                                  setShowLetterEditor(true);
                                }}
                                className="text-[#8B0000] mb-2"
                              >
                                Choose Another Letter Type
                              </button>
                              <h1 className="text-xl text-[#072a48] font-[600] mb-1">
                                {letter === "NOR" && (
                                  <>Notice of Reinstatement</>
                                )}
                                {letter === "NOC" && (
                                  <>Notice of Cancellation</>
                                )}
                                {letter === "PNONR" && (
                                  <>Provisional Notice of Non-Renewal</>
                                )}
                              </h1>
                            </>
                          )}
                          {showLetterEditor && (
                            <section>
                              {!loading ? (
                                <section>
                                  {letter === "NOR" && (
                                    <form onSubmit={(e) => showPreview(e)}>
                                      <h2 className="text-lg font-[600]">
                                        Enter Letter Details
                                      </h2>
                                      <p className="my-2">
                                        The following information must be filled
                                        manually. All fields are required.
                                      </p>
                                      <label className="d-block">
                                        Date of Reinstatement
                                      </label>
                                      <DatePicker wrapperClassName="datePicker"
                                        selected={dateOfReinstatement}
                                        onChange={(date) => {
                                          setDateOfReinstatement(date);
                                          console.log("new date" + date);
                                        }}
                                        className={
                                          "mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                                        }
                                      />
                                      <label className="d-block">
                                        Policy Number (Required)
                                      </label>
                                      <input
                                        value={letterPolicyNumber}
                                        onChange={(e) =>
                                          setLetterPolicyNumber(e.target.value)
                                        }
                                        required
                                        type="text"
                                        name="letterPolicyNumber"
                                        className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                                      />
                                      <label className="d-block">
                                        2nd Policy Number (Optional)
                                      </label>
                                      <input
                                        value={letter2ndPolicyNumber}
                                        onChange={(e) =>
                                          setLetter2ndPolicyNumber(
                                            e.target.value
                                          )
                                        }
                                        required
                                        type="text"
                                        name="letter2ndPolicyNumber"
                                        className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                                      />
                                      <label className="d-block">
                                        Policy Effective Date
                                      </label>
                                      <DatePicker wrapperClassName="datePicker"
                                        selected={letterPolicyEffectiveDate}
                                        onChange={(date) => {
                                          setLetterPolicyEffectiveDate(date);
                                          const thisYearValue = new Date(date);
                                          const nextYearValue = new Date(date);
                                          const aYearFromEffectiveDate =
                                            new Date(
                                              nextYearValue.setFullYear(
                                                nextYearValue.getFullYear() + 1
                                              )
                                            )
                                              .toString()
                                              .slice(4, 15);
                                          setPolicyTerm(
                                            thisYearValue
                                              .toString()
                                              .slice(4, 15) +
                                              " - " +
                                              aYearFromEffectiveDate
                                          );
                                        }}
                                        className={
                                          "mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                                        }
                                      />
                                      <button
                                        className="d-block text-lg text-white bg-[#072a48] hover:bg-red-700 py-2 px-4 mt-3 mb-10 rounded-md"
                                        type="submit"
                                      >
                                        Preview Letter
                                      </button>
                                    </form>
                                  )}
                                  {letter === "NOC" && (
                                    <form onSubmit={(e) => showPreview(e)}>
                                      <h2 className="text-lg font-[600]">
                                        Enter Letter Details
                                      </h2>
                                      <p className="my-2">
                                        The following information must be filled
                                        manually. All fields are required.
                                      </p>
                                      <label className="d-block">
                                        Date of Cancellation
                                      </label>
                                      <DatePicker wrapperClassName="datePicker"
                                        required
                                        selected={dateOfReinstatement}
                                        onChange={(date) => {
                                          setDateOfReinstatement(date);
                                          console.log("new date" + date);
                                        }}
                                        className={
                                          "mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                                        }
                                      />
                                      <label className="d-block">
                                        Reason For Cancellation
                                      </label>
                                      <select
                                        onChange={(e) => {
                                          setNOCReason(e.target.value);
                                        }}
                                        value={NOCReason}
                                        className="w-full rounded mt-1 mb-2"
                                      >
                                        <option value="">Choose One</option>
                                        <option value="Non-Payment of Premium">
                                          Non-Payment of Premium
                                        </option>
                                        <option value="Other">Other</option>
                                      </select>
                                      {NOCReason === "Other" && (
                                        <>
                                          <label className="d-block">
                                            Enter Reason (Required)
                                          </label>
                                          <input
                                            value={NOCReasonExplanation}
                                            onChange={(e) =>
                                              setNOCReasonExplanation(
                                                e.target.value
                                              )
                                            }
                                            required
                                            type="text"
                                            name="NOCReasonExplanation"
                                            className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                                          />
                                        </>
                                      )}

                                      <label className="d-block">
                                        Policy Number (Required)
                                      </label>
                                      <input
                                        value={letterPolicyNumber}
                                        onChange={(e) =>
                                          setLetterPolicyNumber(e.target.value)
                                        }
                                        required
                                        type="text"
                                        name="letterPolicyNumber"
                                        className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                                      />
                                      <label className="d-block">
                                        2nd Policy Number (Optional)
                                      </label>
                                      <input
                                        value={letter2ndPolicyNumber}
                                        onChange={(e) =>
                                          setLetter2ndPolicyNumber(
                                            e.target.value
                                          )
                                        }
                                        required
                                        type="text"
                                        name="letter2ndPolicyNumber"
                                        className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                                      />
                                      <label className="d-block">
                                        Policy Effective Date
                                      </label>
                                      <DatePicker wrapperClassName="datePicker"
                                        selected={letterPolicyEffectiveDate}
                                        onChange={(date) => {
                                          setLetterPolicyEffectiveDate(date);
                                          const thisYearValue = new Date(date);
                                          const nextYearValue = new Date(date);
                                          const aYearFromEffectiveDate =
                                            new Date(
                                              nextYearValue.setFullYear(
                                                nextYearValue.getFullYear() + 1
                                              )
                                            )
                                              .toString()
                                              .slice(4, 15);
                                          setPolicyTerm(
                                            thisYearValue
                                              .toString()
                                              .slice(4, 15) +
                                              " - " +
                                              aYearFromEffectiveDate
                                          );
                                        }}
                                        className={
                                          "mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                                        }
                                      />
                                      <button
                                        className="text-lg text-white bg-[#072a48] hover:bg-red-700 py-2 px-4 mt-3 mb-10 rounded-md"
                                        type="submit"
                                      >
                                        Preview Letter
                                      </button>
                                    </form>
                                  )}
                                  {letter === "PNONR" && (
                                    <form onSubmit={(e) => showPreview(e)}>
                                      <h2 className="text-lg font-[600]">
                                        Enter Letter Details
                                      </h2>
                                      <p className="my-2">
                                        The following information must be filled
                                        manually. All fields are required.
                                      </p>
                                      <label className="d-block">
                                        Expiration Date of Policy
                                      </label>
                                      <DatePicker wrapperClassName="datePicker"
                                        selected={dateOfReinstatement}
                                        onChange={(date) => {
                                          setDateOfReinstatement(date);
                                          console.log("new date" + date);
                                        }}
                                        className={
                                          "mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                                        }
                                      />
                                      <label className="d-block">
                                        Policy Number (Required)
                                      </label>
                                      <input
                                        value={letterPolicyNumber}
                                        onChange={(e) =>
                                          setLetterPolicyNumber(e.target.value)
                                        }
                                        required
                                        type="text"
                                        name="letterPolicyNumber"
                                        className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                                      />
                                      <label className="d-block">
                                        2nd Policy Number (Optional)
                                      </label>
                                      <input
                                        value={letter2ndPolicyNumber}
                                        onChange={(e) =>
                                          setLetter2ndPolicyNumber(
                                            e.target.value
                                          )
                                        }
                                        required
                                        type="text"
                                        name="letter2ndPolicyNumber"
                                        className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                                      />
                                      <button
                                        className="text-lg text-white bg-[#072a48] hover:bg-red-700 py-2 px-4 mt-3 mb-10 rounded-md"
                                        type="submit"
                                      >
                                        Preview Letter
                                      </button>
                                    </form>
                                  )}
                                </section>
                              ) : (
                                <p className="text-center mx-auto font-bold text-xl">
                                  We are generating your letter, please wait...
                                </p>
                              )}
                            </section>
                          )}
                        </div>
                      )}
                      {selected && showLetterPreview && (
                        <section>
                          Review the letter to be sure that all information is
                          correct. Once the letter is sent the process cannot be
                          reversed.
                          {letter === "NOR" && (
                            <div className="shadow-md border border-1 border-black rounded max-w-[790px] my-3">
                              <NORPreview
                                letterPolicyNumber={letterPolicyNumber}
                                letter2ndPolicyNumber={letter2ndPolicyNumber}
                                policyTerm={policyTerm}
                                selected={selected}
                                letterPolicyEffectiveDate={
                                  letterPolicyEffectiveDate
                                }
                                dateOfReinstatement={dateOfReinstatement}
                                selectedCompanyInfo={selectedCompanyInfo}
                              />
                            </div>
                          )}
                          {letter === "NOC" && (
                            <div className="shadow-md border border-1 border-black rounded max-w-[790px] my-3">
                              <NOCPreview
                                letterPolicyNumber={letterPolicyNumber}
                                letter2ndPolicyNumber={letter2ndPolicyNumber}
                                policyTerm={policyTerm}
                                selected={selected}
                                NOCReason={NOCReason}
                                NOCReasonExplanation={NOCReasonExplanation}
                                letterPolicyEffectiveDate={
                                  letterPolicyEffectiveDate
                                }
                                dateOfReinstatement={dateOfReinstatement}
                                selectedCompanyInfo={selectedCompanyInfo}
                              />
                            </div>
                          )}
                          {letter === "PNONR" && (
                            <div className="shadow-md border border-1 border-black rounded max-w-[790px] my-3">
                              <PNONRPreview
                                letterPolicyNumber={letterPolicyNumber}
                                letter2ndPolicyNumber={letter2ndPolicyNumber}
                                policyTerm={policyTerm}
                                selected={selected}
                                letterPolicyEffectiveDate={
                                  letterPolicyEffectiveDate
                                }
                                dateOfReinstatement={dateOfReinstatement}
                                expirationDate={expirationDate}
                                selectedCompanyInfo={selectedCompanyInfo}
                              />
                            </div>
                          )}
                          <button
                            onClick={(e) => handleSendLetter(e)}
                            className="bg-[#072a48] text-xl font-[600] d-block w-full max-w-[790px] p-2 rounded"
                          >
                            Send Letter
                          </button>
                          <button
                            onClick={resetPage}
                            className="bg-[#8B0000] text-xl font-[600] d-block w-full max-w-[790px] p-2 rounded mt-3"
                          >
                            Cancel & Revise Information
                          </button>
                        </section>
                      )}
                    </section>
                  </div>
                ) : (
                  <div className="card-body">
                    <article className="align-items-center justify-content-center text-center">
                      <h2 className="text-2xl font-[600]">
                        Your letter was successfully sent.
                      </h2>
                      <button
                        onClick={(e) => {
                          resetPage(e);
                        }}
                        className="text-[#072a48] mt-4"
                      >
                        Return To Main Menu
                      </button>
                    </article>
                  </div>
                )}
              </section>
            )}
          </div>
        </section>
      ) : (
        <p>You must be an underwriter or administrator to view this page.</p>
      )}
    </main>
  );
}

export default SendLetterPanel;
