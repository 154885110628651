import React from "react";
import InfoIcon from '@mui/icons-material/Info';
import useTranslations from "../../../Context/actions/useTranslations";

export default function BindInfo({ setDisplayBindDetailsInfo }) {

  const { t } = useTranslations();

  return (
    <div className="input-container mb-2">
      <button
        onClick={(e) => setDisplayBindDetailsInfo(true)}
        className="text-[#0c4a6e] hover:text-[#072a48] d-block text-left flex items-center"
      >
        <InfoIcon sx={{ marginRight: "8px" }} />
        {t('plm.coverage-side-panel.view-bind-details')}
      </button>
    </div>
  );
}
