import humanDate from "./humanDate";

const calculateTotal = (
  rating,
  hasNonOwned,
  hasMCP65,
  adjustmentPercent,
  fleetCredit,
  isEndorsement,
  endoData,
  formData
) => {
  let nonOwnedAdjustment = hasNonOwned ? rating * 0.05 : 0;
  // TODO: MCP 65 adjustment was commented out for now, revisit later.
  let mcp65Adjustment = 0; // hasMCP65 ? rating * 0.05 : 0; commented out for now
  let originalPremium = rating + nonOwnedAdjustment + mcp65Adjustment;
  let underwriterAdjustments = originalPremium * adjustmentPercent;
  let adjustedPremium = originalPremium + underwriterAdjustments;
  let fleetCreditAmount = adjustedPremium * fleetCredit;
  let totalPremiumAfterFleetCredit = adjustedPremium - fleetCreditAmount;

  if (isEndorsement && endoData) {
    const effectiveDate = formData.effectiveDate.toDate();
    const endoEffectiveDate = humanDate(endoData.endoEffectiveDate);

    const daysRemainingInPolicy = Math.floor(
      (endoEffectiveDate - effectiveDate) / (1000 * 60 * 60 * 24)
    );

    const isLeapYear = (year) => {
      return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
    };

    // Calculate the number of leap years between the effective date and expiration date
    let leapDays = 0;
    for (
      let year = effectiveDate.getFullYear();
      year <= endoEffectiveDate.getFullYear();
      year++
    ) {
      if (isLeapYear(year)) {
        const leapDay = new Date(year, 1, 29); // February 29
        if (leapDay >= effectiveDate && leapDay <= endoEffectiveDate) {
          leapDays++;
        }
      }
    }

    // Adjust the total premium considering the number of leap days

    // old formula: const totalDays = daysRemainingInPolicy + leapDays - 1; // subtract a day because that's what inspas did
    const totalDays = daysRemainingInPolicy;
    const dailyPremium = totalPremiumAfterFleetCredit / (365 + leapDays);
    // console.log("Leap days are being ignored.");
    totalPremiumAfterFleetCredit -= dailyPremium * totalDays;
  }

  return totalPremiumAfterFleetCredit;
};

export default calculateTotal;