import { collection, getDocs, onSnapshot, query, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../../firebase";
import { t } from "i18next";
import ExpandCollapseArrow from "../../generals/DesignElements/ExpandCollapseArrow";
import requestBind from "../../utils/requestBind";
import ApprovalButtons from "../../assets/forms/ApprovalButtons";
import {
  Modal,
  Typography,
  Box,
  useMediaQuery,
  List,
  ListItem,
  ListItemText,
  Divider,
  ListItemIcon,
  SvgIcon,
  IconButton,
} from "@mui/material";
import Breadcrumbs from "../../assets/Breadcrumbs";
import CloseIcon from "@mui/icons-material/Close";

export default function History({ open, onClose, policyID }) {
  const isMobileScreen = useMediaQuery("(max-width: 600px)");
  const [gotHistory, setGotHistory] = useState(false);
  const [history, setHistory] = useState([]);

  const getHistory = () => {
    const q = query(
      collection(db, "system-activity"),
      where("policyID", "==", policyID)
    );
  
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      let values = [];
      querySnapshot.forEach((doc) => {
        values.push({ ...doc.data(), id: doc.id });
      });
      setHistory(values);
      setGotHistory(true);
    });
  
    return unsubscribe; // You can use this to stop listening when needed
  };
  
  useEffect(() => {
    if (!gotHistory) {
      const unsubscribe = getHistory();
      return () => unsubscribe(); // Clean up the listener when the component unmounts
    }
  }, [gotHistory]);

  const breadcrumbs = [
    { label: "History" },
    { label: "Binding" },
    { label: "Bind Request" },
  ];

  const handleSubmit = async (e) => {
    onClose();
  };



  return (
    <Modal open={open} onClose={null} disableBackdropClick={true}>
      <form onSubmit={handleSubmit}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            width: isMobileScreen ? "100%" : "80%",
            height: isMobileScreen ? "100%" : "auto",
            borderRadius: isMobileScreen ? 0 : 4,
            maxHeight: isMobileScreen ? "100vh" : "80vh",
          }}
        >
          {/* Close Icon */}
          <IconButton
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
            }}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>

          <Typography className="mt-2" variant="h6" gutterBottom>
            Full History
          </Typography>
          <Box
            sx={{
              maxHeight: "60vh",
              overflowY: "auto",
            }}
          >
            <List>
              {history.map((entry, index) => (
                <React.Fragment key={entry.id}>
                  <TimelineItem entry={entry} />
                  {/* Add divider if it's not the last item */}
                  {index !== history.length - 1 && <Divider variant="middle" />}
                </React.Fragment>
              ))}
            </List>
          </Box>
        </Box>
      </form>
    </Modal>
  );
}

const testArray = Array.from({ length: 20 }, (_, index) => ({
  id: index,
  description: `Description ${index}`,
  authorName: `Author ${index}`,
  activityDate: new Date(),
}));

// Define TimelineItem component to encapsulate each history entry
function TimelineItem({ entry }) {
  return (
    <ListItem alignItems="flex-start">
      {/* Use SvgIcon to render the icon */}
      <ListItemIcon>
        <SvgIcon component={IconComponent} />
      </ListItemIcon>
      <ListItemText
        primary={
          t(entry.description)
                  }
        secondary={" By " +
        entry.authorName +
        " on " 
        + entry.activityDate.toDate().toString().slice(4, 15)
      } 
      />
    </ListItem>
  );
}

// Replace IconComponent with your desired icon component
const IconComponent = () => {
  return (
    // Example icon: circle
    <svg width="24" height="24" viewBox="0 0 24 24">
      <circle cx="12" cy="12" r="10" fill="currentColor" />
    </svg>
  );
};
