import { doc, setDoc } from "firebase/firestore";
import { db } from "../../firebase";
import ApiService from "../southern-api/apiService";

const apiService = new ApiService()

const getModuleValueFromCurrentUrl = () => {
  const url = window.location.href;
  const urlObject = new URL(url);
  return urlObject.searchParams.get("module");
};
const moduleValue = getModuleValueFromCurrentUrl();

const updatePolicy = async (policyID, nestedObject) => {
  if (Boolean(moduleValue)) {
    await apiService.setPolicyInfo({ policyID, payload: nestedObject });
  } else {
    const policyRef = doc(db, "policy-applications", policyID);
    await setDoc(policyRef, nestedObject, { merge: true });
  }
};

export const updatePrimaryContact = async (agencyID, insuredID, nestedObject) => {
  if (Boolean(moduleValue)) {
    await apiService.setInsuredInfo({ agencyID, insuredID, payload: nestedObject });
  } else {
    const primaryContactRef = doc(db, "agencies", agencyID, 'insureds', insuredID);
    await setDoc(primaryContactRef, nestedObject, { merge: true });
  }
};

export default updatePolicy;
