import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../../../firebase";
import { setDoc, doc, getDoc, addDoc, collection } from "firebase/firestore";
import { getAuth, updateProfile } from "firebase/auth";
import axios from "axios";
import { UserAuth } from "../../../Context/AuthContent";

function EditProfile(props) {
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");
  const [title, setTitle] = useState("");
  const [status, setStatus] = useState("Unappointed");

  const navigate = useNavigate();

  const sendEmail = async () => {
    axios
      .post(
        "https://sstools-api-d4ca8.uc.r.appspot.com/send-email/new-user-created",
        {
          to: "colt@southernstarmga.com",
          cc: "amarino@southernstarmga.com",
          name: first + " " + last,
          title: title,
          company: company,
          phone: phone,
          email: auth.currentUser.email,
        }
      )
      .then(function (response) {
        // handle success
        console.log(response);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  };
  const { user } = UserAuth();
  const userID = user.uid;
  const [gotExistingData, setGotExistingData] = useState(false);
  const getExistingData = async (e) => {
    setGotExistingData(false);
    const docRef = doc(db, "users", userID);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      setFirst(docSnap.data().first);
      setLast(docSnap.data().last);
      setTitle(docSnap.data().title);
      setCompany(docSnap.data().company);
      setPhone(docSnap.data().phone);
      if (docSnap.data().status) setStatus(docSnap.data().status);
    } else {
      // docSnap.data() will be undefined in this case
      console.log("No such document!");
    }
    setGotExistingData(true);
  };

  const setProfile = async (e) => {
    e.preventDefault();
    await setDoc(
      doc(db, "users", userID),
      {
        email: auth.currentUser.email,
        first: first,
        last: last,
        phone: phone,
        company: company,
        title: title,
        status: status,
        role: "Agent",
        nextStep: "Review Application",
      },
      { merge: true }
    );
    // Send Notification to Administrator
    sendEmail();
    // Log System Activity
    await addDoc(collection(db, "system-activity"), {
      activityDate: new Date(),
      authorID: auth.currentUser.uid,
      authorName: auth.currentUser.displayName,
      description:
        "New User Created: " +
        first +
        " " +
        last +
        ", " +
        title +
        " of " +
        company,
    });
    makeChange(first + " " + last);
    navigate("/signin");
  };

  //   Update display name
  const auth = getAuth();
  const makeChange = (newName) => {
    updateProfile(auth.currentUser, {
      displayName: newName,
    })
      .then(() => {
        // Profile updated!
        console.log(auth.currentUser?.displayName + `'s Profile Updated`);
        // ...
      })
      .catch((error) => {
        // An error occurred
        alert("Error");
        // ...
      });
  };

  useEffect(() => {
    if (userID) {
      !gotExistingData && getExistingData();
    }
  }, [gotExistingData, userID]);

  return (
    <div className="max-w-[700px] mx-auto my-16 p-1">
      <div>
        <h1 className="text-center text-3xl font-bold">
          Edit Your Account Details
        </h1>
        <form onSubmit={setProfile}>
          <div className="flex flex-col py-2">
            <label className="py-2 font-medium">First Name</label>
            <input
              required
              onChange={(e) => setFirst(e.target.value)}
              className="border p-3 rounded"
              type="text"
              value={first}
            />
          </div>
          <div className="flex flex-col py-2">
            <label className="py-2 font-medium">Last Name</label>
            <input
              required
              onChange={(e) => setLast(e.target.value)}
              className="border p-3 rounded"
              type="text"
              value={last}
            />
          </div>
          <div className="flex flex-col py-2">
            <label className="py-2 font-medium">Title</label>
            <input
              required
              onChange={(e) => setTitle(e.target.value)}
              className="border p-3 rounded"
              type="text"
              value={title}
            />
          </div>
          <div className="flex flex-col py-2">
            <label className="py-2 font-medium">Company</label>
            <input
              required
              onChange={(e) => setCompany(e.target.value)}
              className="border p-3 rounded"
              type="text"
              value={company}
            />
          </div>
          <div className="flex flex-col py-2">
            <label className="py-2 font-medium">Phone Number</label>
            <input
              required
              onChange={(e) => setPhone(e.target.value)}
              className="border p-3 rounded"
              type="number"
              value={phone}
            />
          </div>
          <button
            type="submit"
            className="bg-[#072a48] hover:bg-blue-700 rounded w-full p-4 my-2 text-white"
          >
            Save
          </button>
        </form>
      </div>
    </div>
  );
}

export default EditProfile;
