import React, { useState } from 'react';
import { Card, CardContent, Typography, Grid, TextField } from '@mui/material';

const AlertsOnBasics = ({ handleInputChange }) => {
    // Define questions
    const questions = [
      { id: 'actionTaken', label: 'Action taken:', type: 'textarea' },
      {
        id: 'improvementActions',
        label: 'What are they currently doing to improve it?',
        type: 'textarea',
      },
    ];
  
    // State to manage responses
    const [responses, setResponses] = useState(
      questions.reduce((acc, { id }) => ({ ...acc, [id]: '' }), {}) // Initialize state dynamically based on questions
    );

  return (
    <Card sx={{ maxWidth: 800, margin: 'auto', padding: 2, marginBottom: 2 }}>
      <CardContent>
        <Typography variant="h5" component="div" gutterBottom>
          AlertsOnBasics
        </Typography>
        <Grid container spacing={2}>
          {questions.map(({ id, label, type }) => (
            <Grid item xs={12} key={id}>
              <TextField
                fullWidth
                multiline={type === 'textarea'}
                rows={type === 'textarea' ? 4 : 1}
                variant="outlined"
                label={label}
                value={responses[id] || ''}
                onChange={(e) => handleInputChange(id, e.target.value)}
              />
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AlertsOnBasics;

