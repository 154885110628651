import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  writeBatch,
} from "firebase/firestore";
import { auth, db } from "../../firebase";
import policyStatusChangeNotification from "./notifications/policyStatusChangeNotification";
import config from "../../config/env";
import updateSystemLog from "./updateSystemLog";
import { getPolicyGLData } from "./premiumCalculation/getPolicyData";
import { buildShowingInfo, setPolicyInfo } from "../PolicyLifecycleManager/Drivers/setPolicyInfo";
import { selectIfNewOrExistingSubcollection, validateIfExists } from "./validateIfBlacklisted";
import { isExpired } from "../../utils/helpers";

const { REACT_APP_PORTAL_URL } = config

const renewPolicy = async (
  e,
  policyID,
  onClose,
  formData,
  userAuth,
  userState,
  insuredInfo
) => {
  e.preventDefault();

  try {
    const batch = writeBatch(db);

    // Get the source document data
    const sourceDocRef = doc(db, "policy-applications", policyID);
    const sourceDocSnapshot = await getDoc(sourceDocRef);
    const formDataGL = await getPolicyGLData({
      ...sourceDocSnapshot.data(),
      id: sourceDocSnapshot.id,
    })
    if (!sourceDocSnapshot.exists()) {
      throw new Error("Source document does not exist");
    }
    const sourceDocData = {
      ...sourceDocSnapshot.data(),
      id: sourceDocSnapshot.id,
      hasGLCoverage: formDataGL?.hasGLCoverage || false,
      glPolicyNo: formDataGL?.glPolicyNo || "",
      glPremiums: formDataGL?.glPremiums || null,
    }

    // Create a new document with a randomly generated ID
    const newDocRef = doc(collection(db, "policy-applications"));

    const documentType = "Policy";
    const status = "In-Renewal";
    const policyNumber = sourceDocSnapshot.data().alPolicyNo || "";
    const agencyID = sourceDocSnapshot.data().agencyID;
    const insuredID = sourceDocSnapshot.data().insuredID;

    // Set the source document to a "Renewal Policy"
    batch.set(
      sourceDocRef,
      {
        status,
        documentType,
        renewalApplicationID: newDocRef.id,
        inRenewal: true,
        nonRenewal: false,
        nonRenewalReasons: [""],
        nonRenewalDate: "",
      },
      { merge: true }
    );

    // Remove unnecessary fields from sourceDocData
    delete sourceDocData.inspasData;
    delete sourceDocData.selectedQuote;
    delete sourceDocData.alPremiums;
    delete sourceDocData.glPremiums;
    delete sourceDocData.bindData;
    delete sourceDocData.alPolicyNo;
    delete sourceDocData.glPolicyNo;
    delete sourceDocData.saferReport;
    delete sourceDocData.signedApplication;
    delete sourceDocData.ssImportList;
    delete sourceDocData.cabReport;
    delete sourceDocData.driverAndEquipmentList;
    
    const expirationDate = sourceDocData.expirationDate.toDate();
    // add one year
    const newExpirationDate = sourceDocData.expirationDate.toDate();
    newExpirationDate.setFullYear(
      newExpirationDate.getFullYear() + 1
    );
    // Set the source document data to the new document

    batch.set(newDocRef, {
      ...sourceDocData,
      renewalPolicyID: sourceDocRef.id,
      inRenewal: true,
      status,
      documentType: "Application",
      powerUnitCountAtBind: 0,
      authorID: auth.currentUser.uid,
      author: auth.currentUser.displayName,
      createdOn: new Date(),
      effectiveDate: expirationDate,
      // expiration date one year from sourceDocData.expirationDate
      expirationDate: newExpirationDate,
      newOrRenewal: "Renewal",
    });

    async function addDriversUnitsTrailers(newApplicationDocRef) {
      console.log("adding stuff");
      try {
        // Iterate through each subcollection (drivers, power-units, and trailers)
        const subcollections = ["drivers", "power-units", "trailers"];
        for (const subcollection of subcollections) {
          const q = query(
            collection(
              db,
              "agencies",
              agencyID,
              "insureds",
              insuredID,
              subcollection
            )
          );

          const querySnapshot = await getDocs(q);
          const values = querySnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));

          const filteredValues = buildShowingInfo(values, sourceDocRef.id, true);

          for (const docData of filteredValues) {            
              const verifiedInExistingData = await validateIfExists(
                [docData],
                agencyID,
                insuredID,
                subcollection
              );
  
              const payload = setPolicyInfo(
                verifiedInExistingData[0],
                {
                  ...formData,
                  policyEffectiveDate: formData?.effectiveDate,
                  glPolicyNo: formData?.glPolicyNo,
                  alPolicyNo: formData?.alPolicyNo,
                  policyID: newApplicationDocRef?.id,
                  status: "Active",
                  documentType: "Application",
                  documentSource: "Policy",
                  policyStatus: formData?.status,
                  agencyID,
                  insuredID,
                },
                "direct-addition",
                true
              );
  
              await selectIfNewOrExistingSubcollection(
                payload,
                agencyID,
                insuredID,
                subcollection
              );
          }
        }

        // Commit the batched write
        await batch.commit();
        console.log("Batched write committed successfully.");
        policyStatusChangeNotification(
          documentType,
          policyNumber,
          status,
          policyID,
          agencyID,
          formData,
          userAuth,
          userState,
          insuredInfo
        );
        updateSystemLog(
          auth,
          policyID,
          "Renewal Initiated for " + insuredInfo.company + ".",
          "Renewal Initiated",
          { agencyID, policyID }
        );
        onClose(e);
      } catch (error) {
        console.error("Error adding policy to documents:", error);
      }
    }

    await addDriversUnitsTrailers(newDocRef);
    window.open(
      `${REACT_APP_PORTAL_URL}/application/${newDocRef.id}`,
      "_blank"
    );

    console.log("Policy renewed successfully.");
  } catch (error) {
    console.error("Error renewing policy:", error);
  }
};

export default renewPolicy;
