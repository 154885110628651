import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  CircularProgress,
  Backdrop,
  Typography,
} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; // Import the CSS
import { collection, getDocs, addDoc } from "firebase/firestore";
import { db } from "../../firebase";

const AddViolation = ({ open, onClose, driverLicenseNumber }) => {
  const [violationCodeOptions, setViolationCodeOptions] = useState([]);
  const [violationData, setViolationData] = useState({
    claimAmount: "",
    inspectionDate: null,
    violationCode: "",
  });
  const [loading, setLoading] = useState(true);
  const [customCode, setCustomCode] = useState("");
  const [showCustomField, setShowCustomField] = useState(false);

  useEffect(() => {
    const fetchViolationCodes = async () => {
      try {
        const snapshot = await getDocs(collection(db, "loss-control", "tables", "eventualities"));
        const codes = snapshot.docs
          .map((doc) => doc.data().code + " - " + doc.data().description)
          .filter((value, index, self) => self.indexOf(value) === index); // Remove duplicates
        setViolationCodeOptions([...codes]);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching violation codes:", error);
        setLoading(false);
      }
    };

    fetchViolationCodes();
  }, []);

  const handleInputChange = (e) => {
    // if its claimAmount handle it as a number
    if (e.target.name === "claimAmount") {
      setViolationData({
        ...violationData,
        [e.target.name]: parseInt(e.target.value),
      });
      return;
    } else setViolationData({
      ...violationData,
      [e.target.name]: e.target.value,
    });
  };

  const handleDateChange = (date) => {
    setViolationData({
      ...violationData,
      inspectionDate: date,
    });
  };

  const handleSave = async () => {
    try {
      const finalViolationCode = showCustomField ? customCode : violationData.violationCode;
      // perform a sort of join by getting all the documents from the "classifactions" table and then joining using the 'classification' field as a key, and then add all that classification documents data
      const classificationDocs = await getDocs(collection(db, "loss-control", "tables", "classifications"));
        const classificationData = classificationDocs.docs
            .map((doc) => doc.data())
            .find((doc) => doc.classification === finalViolationCode);

      await addDoc(collection(db, "loss-control", "driverData", "violations"), {
        ...violationData,
        ...classificationData,
        LicenseNumber: driverLicenseNumber,
        violationCode: finalViolationCode,
      });
      setViolationData({
        claimAmount: "",
        inspectionDate: null,
        violationCode: "",
      });
      setCustomCode("");
      setShowCustomField(false);
      onClose(); // Close the modal
    } catch (error) {
      console.error("Error adding violation:", error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add Violation</DialogTitle>
      <DialogContent>
        {loading ? (
          <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
            <CircularProgress color="inherit" />
            <Typography variant="h6">Loading options...</Typography>
          </Backdrop>
        ) : (
          <>
            <TextField
              label="Claim Amount"
              name="claimAmount"
              type="number"
              value={violationData.claimAmount}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
            <DatePicker
              selected={violationData.inspectionDate}
              onChange={handleDateChange}
              dateFormat="MM/dd/yyyy"
              placeholderText="Select a date"
              className="form-control"
            />
            <TextField
              select
              label="Violation Code"
              name="violationCode"
              value={violationData.violationCode}
              onChange={(e) => {
                const value = e.target.value;
                setViolationData({
                  ...violationData,
                  violationCode: value,
                });
                setShowCustomField(value === "Other");
              }}
              fullWidth
              margin="normal"
            >
              {violationCodeOptions.map((code) => (
                <MenuItem key={code} value={
                    // remove everything after the dash, and the dash, and the space before it
                    code.split(" - ")[0]
                    }>
                  {code}
                </MenuItem>
              ))}
            </TextField>
            {showCustomField && (
              <TextField
                label="Custom Violation Code"
                name="customCode"
                value={customCode}
                onChange={(e) => setCustomCode(e.target.value)}
                fullWidth
                margin="normal"
              />
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddViolation;
