import React, { useMemo, useState } from "react";
import useTranslations from "../../../Context/actions/useTranslations";
import useGeneralState from "../../../Context/actions/useGeneralState";
import ApiService from "../../../services/southern-api/apiService";
import Button from "../../generals/Forms/Button";
import SingleCheckbox from "../../assets/forms/SingleCheckbox";

export default function AddUserForm(props) {
  const setShowCreateUserPanel = props.setShowCreateUserPanel;
  const { t } = useTranslations();

  const initialUserFormData = {
    firstName: "",
    lastName: "",
    title: "",
    email: "",
    phone: "",
    countryCode: "+1",
    password: "",
    confirmPassword: "",
    userIsAgent: false,
    userPermissions: true,
    policyPermissions: true,
    applicationPermissions: true,
  }
  const [userFormData, setUserFormData] = useState(initialUserFormData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserFormData({ ...userFormData, [name]: value });
  };

  const { controlToast } = useGeneralState();
  const apiService = new ApiService('southern')
  const [loading, setLoading] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    
    if (userFormData.confirmPassword !== userFormData.password) {
      setLoading(false)
      return controlToast(true, "Passwords must match.", "error");
    }

    const fullName = `${userFormData.firstName} ${userFormData.lastName}`;
    const phoneNumber = `${userFormData.countryCode}${userFormData.phone}`;

    const payload = {
      email: userFormData.email,
      password: userFormData.password,
      first: userFormData.firstName,
      last: userFormData.lastName,
      agencyID: props.userInfo.agencyID,
      userIsAgent: userFormData.userIsAgent,
      userPermissions: userFormData.userPermissions,
      policyPermissions: userFormData.policyPermissions,
      applicationPermissions: userFormData.applicationPermissions,
      role: "Sub-User",
      title: userFormData.title,
      status: "Active",
      admin: false,
    }

    if (userFormData.phone) payload['phoneNumber'] = phoneNumber
    
    try {
      await apiService.createSubusers(payload)
      setUserFormData(initialUserFormData);
      controlToast(true, `
        ${fullName} was successfully created ${userFormData.userIsAgent ? 'as an agent' : ''}
        with ${userFormData.userPermissions} user permissions,
        ${userFormData.policyPermissions} policy permissions
        and ${userFormData.applicationPermissions} application permissions
      `, "success");
      setLoading(false)
      setShowCreateUserPanel(false);
    } catch (error) {
      const realError = error?.error?.message
      setLoading(false)
      controlToast(
        true,
        `${realError} If you believe you received this message in error, you may try again or contact an administrator`,
        "error"
      );
    }
  };

  const enabled = useMemo(() => {
    const user = { ...userFormData }
    delete user.phone
    delete user.countryCode
    delete user.userIsAgent
    const values = Object.values(user)
    const allValuesAreFilledOut = values.every(value => Boolean(value))
    return allValuesAreFilledOut
  }, [userFormData])

  return (
    <div className="w-full">
      <form onSubmit={handleSubmit} className="mb-4 w-full max-w-md mx-auto">
        <div className="grid grid-cols-1 gap-4">
          <div>
            <label className="text-sm text-gray-500">
              {t("agent.first-name")}
            </label>
            <input
              type="text"
              name="firstName"
              value={userFormData.firstName}
              onChange={handleChange}
              className="border p-2 rounded w-full"
              placeholder="John"
              required
            />
          </div>
          <div>
            <label className="text-sm text-gray-500">
              {t("agent.last-name")}
            </label>
            <input
              type="text"
              name="lastName"
              value={userFormData.lastName}
              onChange={handleChange}
              className="border p-2 rounded w-full"
              placeholder="Doe"
              required
            />
          </div>
          <div>
            <label className="text-sm text-gray-500">{t("agent.title")}</label>
            <input
              type="text"
              name="title"
              value={userFormData.title}
              onChange={handleChange}
              className="border p-2 rounded w-full"
              placeholder="Manager"
              required
            />
          </div>
          <div>
            <label className="text-sm text-gray-500">{t("agent.email")}</label>
            <input
              type="email"
              name="email"
              value={userFormData.email}
              onChange={handleChange}
              className="border p-2 rounded w-full"
              placeholder="john.doe@example.com"
              required
            />
          </div>
          <div>
            <label className="text-sm text-gray-500">
              {t("agent.country-code")}
            </label>
            <select
              name="countryCode"
              value={userFormData.countryCode}
              onChange={handleChange}
              className="border p-2 rounded w-full text-sm"
            >
              <option value="+1">+1 (USA)</option>
              <option value="+52">+52 (Mexico)</option>
            </select>
          </div>
          <div>
            <label className="text-sm text-gray-500">
              {t("agent.phone-number")} (optional)
            </label>
            <input
              type="tel"
              name="phone"
              value={userFormData.phone}
              onChange={handleChange}
              className="border p-2 rounded w-full"
              placeholder="2105551234"
              pattern="[0-9]{10}"
              inputMode="numeric"
            />
          </div>
          <div>
            <label className="text-sm text-gray-500">
              {t("agent.password")}
            </label>
            <input
              type="password"
              name="password"
              value={userFormData.password}
              onChange={handleChange}
              className="border p-2 rounded w-full"
              placeholder={t("agent.enter-password-label")}
              required
              autoComplete="new-password"
            />
          </div>
          <div>
            <label className="text-sm text-gray-500">
              {t("agent.confirm-password")}
            </label>
            <input
              type="password"
              name="confirmPassword"
              value={userFormData.confirmPassword}
              onChange={handleChange}
              className="border p-2 rounded w-full"
              placeholder={t("agent.confirm-password-label")}
              required
              autoComplete="new-password"
            />
          </div>
          <div>
            <label className="text-sm text-gray-500">
              User Permissions
            </label>
            <select
              required
              name="userPermissions"
              value={userFormData.userPermissions}
              onChange={handleChange}
              className="border p-2 rounded w-full"
            >
              <option value={false}>No</option>
              <option value={true}>Yes</option>
            </select>
          </div>
          <div>
            <label className="text-sm text-gray-500">
              Application Permissions
            </label>
            <select
              required
              name="applicationPermissions"
              value={userFormData.applicationPermissions}
              onChange={handleChange}
              className="border p-2 rounded w-full"
            >
              <option value={false}>No</option>
              <option value={true}>Yes</option>
            </select>
          </div>
          <div>
            <label className="text-sm text-gray-500">
              Policy Permissions
            </label>
            <select
              required
              name="policyPermissions"
              value={userFormData.policyPermissions}
              onChange={handleChange}
              className="border p-2 rounded w-full"
            >
              <option value={false}>No</option>
              <option value={true}>Yes</option>
            </select>
          </div>
          <div>
            <label className="text-sm text-gray-500">
              Is the user an agent
            </label>
            <SingleCheckbox
              name='userIsAgent'
              checked={userFormData.userIsAgent}
              onChange={() => handleChange({
                target: {
                  name: 'userIsAgent',
                  value: !userFormData?.userIsAgent
                }
              })}
            />
          </div>
        </div>
        <div className="mt-4 flex items-center">
          <Button
            disabled={!enabled || loading}
            loading={loading}
            label={t("agent.add-user")}
            className="w-auto bg-[#072a48] hover:bg-[#072a48] text-white px-4 rounded mr-2 h-[48px]"
          />
          <button
            type="button"
            className="bg-[#8B0000] hover:bg-red-700 text-white py-2 px-4 rounded h-[48px]"
            onClick={() => setShowCreateUserPanel(false)}
          >
            {t("agent.cancel")}
          </button>
        </div>
      </form>
    </div>
  );
}
