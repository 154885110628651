const textInputs = [
    {
      label: "Web Address:",
      required: true,
      property: "webAddress",
    },
    {
      label: "Federal Tax ID:",
      required: true,
      property: "federalTaxID",
    },
    {
      label:
        "Exact name of agency to which the above referenced Federal Tax ID Number is Issued to (include d.b.a if applicable):",
      required: true,
      property: "agencyName",
    },
    {
      label: "List States You Write Business In:",
      required: true,
      property: "businessStates",
    },
    {
      label: "List any affiliates or subsidiaries (optional):",
      required: false,
      property: "affiliates",
    },
  ];

  export default textInputs;