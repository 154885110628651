import { db } from "../../../../../firebase"; // Updated import path
import {
  collectionGroup,
  query,
  where,
  getDocs,
  doc,
} from "firebase/firestore";

const handleSearch = async (
  initialSearch,
  queryText,
  searchFunction,
  userAgencyID,
  selectedStatus,
  setOutstandingEndorsements,
  setResults,
  setLoading
) => {
  let endorsementsQuery;
setLoading(true);
  const searchValue = queryText.toUpperCase(); // Convert to uppercase for consistent querying

  if (searchFunction === "company") {
    endorsementsQuery = query(
      collectionGroup(db, "endorsements"),
      where("agencyID", "==", userAgencyID),
      where("namedInsured", ">=", searchValue),
      where("namedInsured", "<", searchValue + "\uf8ff")
    );
  } else if (searchFunction === "driver") {
    // Search for driver name in searchData array
    endorsementsQuery = query(
      collectionGroup(db, "endorsements"),
      where("agencyID", "==", userAgencyID),
      where("searchData", "array-contains", searchValue) // Check for exact match
    );
  } else if (searchFunction === "license") {
    // Search for license number in searchData array
    endorsementsQuery = query(
      collectionGroup(db, "endorsements"),
      where("agencyID", "==", userAgencyID),
      where("searchData", "array-contains", searchValue) // Check for exact match
    );
  } else if (searchFunction === "status") {
    endorsementsQuery = query(
      collectionGroup(db, "endorsements"),
      where("agencyID", "==", userAgencyID),
      where("status", "==", selectedStatus)
    );
  }

  if (endorsementsQuery) {
    try {
      const querySnapshot = await getDocs(endorsementsQuery);
      const searchResults = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      initialSearch && setOutstandingEndorsements(searchResults.length);
      console.log("Search results:", searchResults);
      setResults(searchResults);
    } catch (error) {
      console.error("Error fetching endorsements:", error);
    }
  } else {
    setResults([]); // Clear results if the input is empty
  }
    setLoading(false);
};

export default handleSearch;
