import { doc, getDoc, updateDoc } from "firebase/firestore";
import { auth, db } from "../../firebase";

const updateALLossHistory = async (
  policyID,
  lossYear,
  carrier,
  losses,
  numberOfUnits,
  openAtFaultBIClaims,
  openBIClaims,
  premiumPPU,
  totalOpenClaims,
  years
) => {
  try {
    const policyRef = doc(db, "policy-applications", policyID);
    const policyDocument = await getDoc(policyRef);
    const policyData = policyDocument.data();
    const yearString = years.split("-").join(" to ");
    const updateRef = doc(db, "policy-applications", policyID);
    await updateDoc(updateRef, {
      lossHistory: {
        ...policyData.lossHistory,
        [`year${lossYear}ALLossData`]: {
          annualPremium: premiumPPU * numberOfUnits,
          author: auth.currentUser.displayName,
          authorID: auth.currentUser.uid,
          carrier,
          createdOn: new Date(),
          lossRatio: (losses / (numberOfUnits * premiumPPU)) * 100 || 0,
          lossRatioCalculated: true,
          losses,
          numberOfUnits,
          openAtFaultBIClaims,
          openBIClaims,
          premiumPPU,
          totalOpenClaims,
          years: yearString,
        },
      },
    });
  } catch (error) {
    throw error
  }
};

export default updateALLossHistory;