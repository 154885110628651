import { Box, Typography } from "@mui/material";
import Popup from "..";
import ApprovalButtons from "../../../assets/forms/ApprovalButtons";
import DatePicker from "react-datepicker";
import SingleCheckbox from "../../../assets/forms/SingleCheckbox";
import { useMemo, useState } from "react";
import {
  setTimeTo100YearAfterToday,
  setTimeToNoonOne,
} from "../../../../utils/helpers";
import SingleRealCheckbox from "../../../assets/forms/SingleRealCheckbox";

const ModalExclusion = ({
  open,
  onClose,
  handleSubmit,
  isMobileScreen,
  loading,
  insuredInfo,
}) => {

  const [formData, setFormData] = useState({
    effectiveDate: setTimeToNoonOne(new Date()),
    expirationDateOfBlacklisting: setTimeToNoonOne(new Date()),
    blacklistUntil100YearAfter: false,
    deletionReasons: "",
    email: false,
    emailValue: insuredInfo?.email || "",
    contactEmail: false,
    contactEmailValue: insuredInfo?.contactEmail || "",
    other: false,
    customEmail: "",
  });

  const customClass =
    "mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300";
  const labelCustomClass = "block text-sm font-medium text-gray-600";
  const labelCustomClassCheckbox =
    "block text-sm font-medium text-gray-600 mb-[-10px]";

  const submitFn = async (e) => {
    try {
        const newPayload = { ...formData };
        delete newPayload.email
        delete newPayload.emailValue
        delete newPayload.contactEmail
        delete newPayload.contactEmailValue;
        delete newPayload.other;
        delete newPayload.customEmail;
        delete newPayload.blacklistUntil100YearAfter;
        delete newPayload.expirationDateOfBlacklisting;
        delete newPayload.effectiveDate;
        delete newPayload.deletionReasons;
        newPayload["effective-date"] = formData.effectiveDate
        newPayload["deletion-reason"] = formData.deletionReasons
        newPayload["blacklist-expiration-date"] = formData.expirationDateOfBlacklisting
        if (formData.emailValue) {
            newPayload['email'] = formData.emailValue;
        } else if (formData.contactEmailValue) {
            newPayload['email'] = formData.contactEmailValue;
        } else if (formData.customEmail) {
            newPayload['email'] = formData.customEmail;
        }
        await handleSubmit(e, newPayload, "exclusion");
        onClose();
    } catch (error) {
      console.error(error);
    }
  };

    const enableSubmission = useMemo(() => {
        const thereIsAtLeastOneEmail =
            Boolean(formData?.email || formData?.contactEmail || formData?.customEmail)
        const deletionReasons = Boolean(formData.deletionReasons)
        return thereIsAtLeastOneEmail && deletionReasons
    }, [formData])

  return (
    <Popup
      isOpen={open}
      onRequestClose={onClose}
      maxWidth={isMobileScreen ? "100%" : "400px"}
      zIndex={1000}
      contentLabel="Confirm Endorsement Creation"
    >
      <form onSubmit={submitFn}>
        <Box
          sx={{
            width: "100%",
            borderRadius: isMobileScreen ? 0 : 4,
          }}
        >
          <Typography className="mt-2" variant="h6" gutterBottom>
            CREATE EXCLUSION ENDORSEMENT
          </Typography>
          <p className="mt-2 mb-4 text-[#8B0000]">
            Select the expiration date of the drivers and the deletion reason
          </p>

          <div className="mb-2">
            <label className={labelCustomClass}>Cancellation Date</label>
            <DatePicker
              wrapperClassName="datePicker"
              selected={formData.effectiveDate}
              onChange={(date) => {
                const newDate = setTimeToNoonOne(date);
                setFormData({
                  ...formData,
                  effectiveDate: newDate,
                });
              }}
              className={customClass}
            />
          </div>

          {!formData?.blacklistUntil100YearAfter ? (
            <div className="mb-2">
              <label className={labelCustomClass}>
                Expiration Date of Blacklisting
              </label>
              <DatePicker
                wrapperClassName="datePicker"
                selected={formData.expirationDateOfBlacklisting}
                onChange={(date) => {
                  const newDate = setTimeToNoonOne(date);
                  setFormData({
                    ...formData,
                    expirationDateOfBlacklisting: newDate,
                  });
                }}
                className={customClass}
              />
            </div>
          ) : null}

          <div className="mb-2">
            <label className={labelCustomClassCheckbox}>
              Blacklist until 100 year after
            </label>
            <SingleRealCheckbox
              name="blacklistUntil100YearAfter"
              checked={formData.blacklistUntil100YearAfter}
              onChange={() => {
                const date100YearAfterToday = setTimeTo100YearAfterToday();
                const newValue = !Boolean(formData.blacklistUntil100YearAfter);
                setFormData({
                  ...formData,
                  blacklistUntil100YearAfter: newValue,
                  expirationDateOfBlacklisting: newValue
                    ? date100YearAfterToday
                    : new Date(),
                });
              }}
              className={customClass}
            />
          </div>

          <div className="mb-4">
            <label htmlFor="deletionReasons" className={labelCustomClass}>
                Deletion Reasons:
            </label>
            <input
                required
                name="deletionReasons"
                type="text"
                value={formData.deletionReasons}
                onChange={(e) => {
                    setFormData({
                        ...formData,
                        deletionReasons: e.target.value,
                    })
                }}
                className={customClass}
            />
          </div>

          {insuredInfo?.email ? (
            <div className="mb-2">
              <label className={labelCustomClassCheckbox}>
                Company E-mail Address
              </label>
              <SingleCheckbox
                name="email"
                checked={formData.email}
                onChange={() => {
                  setFormData({
                    ...formData,
                    email: true,
                    contactEmail: false,
                    other: false
                  });
                }}
              />
            </div>
          ) : null}

          {insuredInfo?.contactEmail ? (
            <div className="mb-2">
              <label className={labelCustomClassCheckbox}>
                Primary Contact E-mail
              </label>
              <SingleCheckbox
                name="contactEmail"
                checked={formData.contactEmail}
                onChange={() => {
                  setFormData({
                    ...formData,
                    email: false,
                    contactEmail: true,
                    other: false,
                  });
                }}
              />
            </div>
          ) : null}

          <div className="mb-2">
            <label className={labelCustomClassCheckbox}>
              Other Custom Email
            </label>
            <SingleCheckbox
              name="other"
              checked={formData.other}
              onChange={() => {
                setFormData({
                  ...formData,
                  email: false,
                  contactEmail: false,
                  other: true,
                });
              }}
            />
          </div>

          {formData.other === true ? (
            <div className="mb-4">
              <label htmlFor="customEmail" className={labelCustomClass}>
                Custom E-mail Address:
              </label>
              <input
                required
                name="customEmail"
                type="text"
                value={formData.customEmail}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    customEmail: e.target.value,
                  })
                }
                className={customClass}
              />
            </div>
          ) : null}

          <ApprovalButtons
            onApprove={handleSubmit}
            onDecline={onClose}
            forceLoadingAccept={loading}
            titleAccept={"Create Deletion Endorsement"}
            titleDecline={"Cancel"}
            disabledAccept={!enableSubmission}
            disabledDecline={false}
            handleAsSubmit={true}
          />
        </Box>
      </form>
    </Popup>
  );
};

export default ModalExclusion;
