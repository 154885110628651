import React from "react";
import { t } from "i18next";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Container } from "react-bootstrap";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"} variant={"body2"}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const CustomTabs = ({ value, handleChange, filteredTabs }) => {
  return (
    <Container className="p-0 shadow-md">
      <Box
        sx={{
          width: "100%",
          height: "100%",
          backgroundColor: "white",
          borderTopRightRadius: "10px",
          borderTopLeftRadius: "10px",
        }}
      >
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            backgroundColor: "#072a48",
            color: "white",
            borderTopRightRadius: "10px",
            borderTopLeftRadius: "10px",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            indicatorColor="primary"
            textColor="white"
            allowScrollButtonsMobile
          >
            {filteredTabs.map((tab, i) => (
              <Tab key={i} label={t(tab.label)} {...a11yProps(i)} />
            ))}
          </Tabs>
        </Box>
        {filteredTabs.map((tab, i) => (
          <TabPanel
            key={"tabPanel" + i}
            value={value}
            index={i}
            className="white"
          >
            {tab.component}
          </TabPanel>
        ))}
      </Box>
    </Container>
  );
};

export default CustomTabs;
