import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../../firebase";
import getAge from "../../../utils/getAge";

const getScoreWeights = async () => {
  // Get weights
  let weights = {};
  const querySnapshot = await getDocs(
    collection(db, "loss-control", "tables", "finalWeight")
  );
  querySnapshot.forEach((doc) => {
    const data = doc.data();
    weights = {
      ...weights,
      [data.identifier]: data.weight,
    };
  });

  return weights;
};

export default getScoreWeights;
