import React, { useEffect, useState } from "react";
import { getDocs, collection, query, where } from "firebase/firestore";
import { db } from "../../firebase";
import { Container } from "react-bootstrap";
import { Table, Thead, Tbody, Tr, Td } from "react-super-responsive-table";
import useUsersState from "../../Context/actions/useUsersState";

function EmployeeDirectory() {
  const { user } = useUsersState();
  const [pageData, setPageData] = useState([]);
  const [gotData, setGotData] = useState(false);
  const getPageData = async () => {
    setGotData(false);
    const q = query(collection(db, "users"), where("role", "==", "Agent"));
    const queryData = await getDocs(q);
    const queryResults = queryData.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    setPageData(queryResults);
    setGotData(true);
  };

  const fillInTheBlanks = (data) => {
    const values = [...data];
    values.forEach((field) => {
      if (!field.title) field.title = "";
      if (!field.first) field.first = "";
      if (!field.last) field.last = "";
      if (!field.email) field.email = "";
      if (!field.phone) field.phone = "";
      if (!field.status) field.status = "";
    });
  };

  const sortData = (column) => {
    fillInTheBlanks(pageData);
    if (column === "name") {
      const data2 = [...pageData].sort((a, b) =>
        a.first.toLowerCase() < b.first.toLowerCase() ? -1 : 1
      );

      setPageData(data2);
    }
    if (column === "title") {
      const data2 = [...pageData].sort((a, b) =>
        a.title?.toLowerCase() < b.title?.toLowerCase() ? -1 : 1
      );

      setPageData(data2);
    }
    if (column === "status") {
      const data2 = [...pageData].sort((a, b) =>
        a.status?.toLowerCase() < b.status?.toLowerCase() ? -1 : 1
      );

      setPageData(data2);
    }
    if (column === "email") {
      const data2 = [...pageData].sort((a, b) =>
        a.email?.toLowerCase() < b.email?.toLowerCase() ? -1 : 1
      );

      setPageData(data2);
    }
    if (column === "phone") {
      const data2 = [...pageData].sort((a, b) => (a.phone < b.phone ? -1 : 1));
      setPageData(data2);
    }
  };

  useEffect(() => {
    if (!gotData) getPageData();
  }, [db, pageData, gotData]);

  return (
    <>
      {pageData.length < 1 && <p>Loading....</p>}
      <h1 className="title-1">Agent Directory</h1>
      <hr className="divider-1" />
      <Container>
        {pageData.length > 0 && (
          <div className="data-table-container">
            <Table className="data-table">
              <Thead>
                <Tr className="data-table-header">
                  <Td>
                    <button onClick={() => sortData("name")}>Name</button>
                  </Td>
                  <Td>
                    <button onClick={() => sortData("company")}>Agency</button>
                  </Td>
                  <Td>
                    <button onClick={() => sortData("status")}>Status</button>
                  </Td>
                  <Td>
                    <button onClick={() => sortData("email")}>Email</button>
                  </Td>
                  <Td>
                    <button onClick={() => sortData("phone")}>Phone</button>
                  </Td>
                </Tr>
              </Thead>
              <Tbody>
                {pageData.map((myData, i) => (
                  <Tr key={"row" + i} className="border-2 border-black">
                    <Td>
                      <a href={"/agent-profile/" + myData.id}>
                        {myData.first} {myData.last}
                      </a>
                    </Td>
                    <Td>
                      <a href={"/agent-profile/" + myData.id}>
                        {myData.company}
                      </a>
                    </Td>
                    <Td>{myData.status || ""}</Td>
                    <Td>
                      <a href={"mailto:" + myData.email || ""}>
                        {myData.email || ""}
                      </a>
                    </Td>
                    <Td>
                      <a href={"tel:" + myData.phone || ""}>
                        {myData.phone || ""}
                      </a>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </div>
        )}
      </Container>
    </>
  );
}

export default EmployeeDirectory;
