import getAge from "../../../utils/getAge";

// function processDriverData(snapshot) {
//   const driversData = {};

//   const totalDriversCount = snapshot.size;

//   console.log("Total Drivers Count:", totalDriversCount);
//   snapshot.forEach((doc) => {
//     const driverData = doc.data();
//     const licenseNumber = driverData.licenseNumber;
//     const driverAge = !driverData.dob
//       ? ""
//       : getAge(
//           driverData.dob.toDate().toLocaleDateString("en-US", {
//             month: "short",
//             day: "2-digit",
//             year: "numeric",
//           })
//         );
//     const YOE = parseInt(driverData.yoe);
//     if (!driversData[licenseNumber]) {
//       driversData[licenseNumber] = {
//         ...driverData,
//         ageStatus:
//           driverAge > 65
//             ? "Need Long Form"
//             : driverAge < 23
//             ? "Driver Too Young"
//             : "Insurable",
//         expStatus: YOE > 1 ? "Insurable" : "Not Enough Experience",
//       };
//     }
//   });

//   console.log("drivers length: " + Object.keys(driversData).length);
//   return driversData;
// }

function processDriverData(snapshot) {
  const driversData = {};

  snapshot.docs.forEach((doc) => { // Access the docs property of the snapshot object
    const id = doc.id;
    const driverData = doc.data();
    const licenseNumber = driverData.licenseNumber;
    const driverAge = !driverData.dob
      ? ""
      : getAge(
          driverData.dob.toDate().toLocaleDateString("en-US", {
            month: "short",
            day: "2-digit",
            year: "numeric",
          })
        );
    const YOE = parseInt(driverData.yoe);
    if (!driversData[licenseNumber]) {
      driversData[licenseNumber] = {
        ...driverData,
        id,
        ageStatus:
          driverAge > 65
            ? "Need Long Form"
            : driverAge < 23
            ? "Driver Too Young"
            : "Insurable",
        expStatus: YOE > 1 ? "Insurable" : "Not Enough Experience",
      };
    }
  });

  return driversData;
}

export default processDriverData;
