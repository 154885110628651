import { collection, doc, getDocs, query, where } from "firebase/firestore";
import { db } from "../../../firebase";

const getTruckList = async (policyID, formData) => {
  let truckList = [];
  const policyRef = doc(db, "policy-applications", policyID);
  const q = query(
    collection(
      db,
      "agencies",
      formData.agencyID,
      "insureds",
      formData.insuredID,
      "power-units"
    ),
    where("policies", "array-contains", policyRef.id)
  );
  const truckSnapshot = await getDocs(q);
  truckSnapshot.forEach((doc) => {
    truckList.push({ ...doc.data(), id: doc.id });
  });
  return truckList;
};

export default getTruckList;
