import styles from "../../../../assets/styles/components/generals/forms/form";

function Button({
  label,
  disabled,
  loading,
  onClick,
  className,
  type
}) {
  return (
    <button
      type={type}
      disabled={disabled || loading}
      className={`
        ${className}
        ${styles.button(disabled, loading)}
      `}
      onClick={onClick}
    >
      {label}
      {loading &&
        <div className={styles.spinnerContainer}>
          <div className={styles.spinner} />
        </div>
      }
    </button>
  );
}

export default Button;
