import { formatPrice } from "../Components/Finances/Tables/utils";
import { formatDateToDDMMYYYY } from "./helpers"

function convertTimestampToDate(timestamp) {
  if (
    !timestamp ||
    typeof timestamp.seconds !== 'number' ||
    typeof timestamp.nanoseconds !== 'number') {
    throw new Error('Invalid timestamp format');
  }
  const milliseconds = timestamp.seconds * 1000;
  const additionalMilliseconds = Math.floor(timestamp.nanoseconds / 1e6);
  const totalMilliseconds = milliseconds + additionalMilliseconds;
  const date = new Date(totalMilliseconds);
  const options = { year: 'numeric', month: 'short', day: '2-digit' };
  return date.toLocaleDateString('en-US', options);
}

const driverListToDocxToPdfFormat = driversList => {
  if (Array.isArray(driversList)) {
    const parsedDriversList = driversList?.map((driver, driverIndex) => {
      const {
        driverFirst,
        driverLast,
        dob,
        licenseNumber,
        licenseExpirationDate,
        licenseExpiration,
        inspasData
      } = driver
      return {
        no: String(driverIndex + 1),
        name: `${driverFirst} ${driverLast}`,
        dob:
          dob && dob?.seconds ?
          convertTimestampToDate(dob) : '',
        licenseNumber,
        expirationDate:
          licenseExpirationDate && licenseExpirationDate?.seconds ?
          convertTimestampToDate(licenseExpirationDate) :
          licenseExpiration && licenseExpiration?.seconds ?
          convertTimestampToDate(licenseExpiration) : '',
        licenseType: inspasData?.LicClass
      }
    })
    return parsedDriversList
  } else {
    return []
  }
}

export const naftaAlPolicyPayload = (
    formData,
    insuredInfo,
    driverList,
    agencyInfo
) => {

    const drivers = driverListToDocxToPdfFormat(driverList)

    const {
        company,
        address
    } = insuredInfo

    const {
        alPolicyNo,
        effectiveDate,
        expirationDate,
        alPremiums,
        glPremiums,
        mailingAddress,
        coverageSingleLimit,
        bindData
    } = formData

    const processingFeeAL = alPremiums?.processingFeeAmount || 0
    const otherFeesAL = alPremiums?.otherFees || 0
    const surplusLinesTaxAL = alPremiums?.surplusLinesTaxAmount || 0
    const stampTaxAL = alPremiums?.stampTaxAmount || 0
    const totalPremiumAL = alPremiums?.totalPremium || 0

    const processingFeeGL = glPremiums?.processingFeeAmount || 0
    const otherFeesGL = glPremiums?.otherFees || 0
    const surplusLinesTaxGL = glPremiums?.surplusLinesTaxAmount || 0
    const stampTaxGL = glPremiums?.stampTaxAmount || 0
    const totalPremiumGL = glPremiums?.totalPremium || 0

    const processingFee = processingFeeAL + processingFeeGL
    const otherFees = otherFeesAL + otherFeesGL
    const surplusLinesTax = surplusLinesTaxAL + surplusLinesTaxGL
    const stampTax = stampTaxAL + stampTaxGL
    const totalPremium = totalPremiumAL + totalPremiumGL

  return [
    {
      policyNo: alPolicyNo,
      company,
      namedInsured: company,
      mga: agencyInfo?.name || '',
      dba: agencyInfo?.dba || '',
      surpluslineAgent: agencyInfo?.name || '',
      issuedDate:
        bindData?.bindDate && bindData?.bindDate?.seconds ?
        convertTimestampToDate(bindData?.bindDate) : '',
      address,
      startDate:
        effectiveDate && effectiveDate?.seconds ?
        convertTimestampToDate(effectiveDate) : '',
      endDate:
        expirationDate && expirationDate?.seconds ?
        convertTimestampToDate(expirationDate) : '',
      summaryCALLimit: coverageSingleLimit ? `${coverageSingleLimit}.00` : "NOT COVERED",
      summaryCIMLimit: "NOT COVERED",
      summaryCGLLimit: coverageSingleLimit ? `${coverageSingleLimit}.00` : "NOT COVERED",
      summaryAPDLimit: "NOT COVERED",
      summaryTILimit: "NOT COVERED",
      summaryTRIA2022Limit: "NOT COVERED",
      summarySLTLimit: "NOT COVERED",
      summarySFLimit: "NOT COVERED",
      summaryPFLimit: "NOT COVERED",
      summaryOFLimit: "NOT COVERED",
      summaryTPDLimit: "NOT COVERED",
      summaryDPDLimit: "NOT COVERED",
      summaryCALPremium: typeof alPremiums?.GWP === 'number' ? formatPrice(alPremiums?.GWP) : "NOT COVERED",
      summaryCIMPremium: "NOT COVERED",
      summaryCGLPremium: typeof glPremiums?.GWP === 'number' ? formatPrice(glPremiums?.GWP) : "NOT COVERED",
      summaryAPDPremium: "NOT COVERED",
      summaryTIPremium: "NOT COVERED",
      summaryTRIA2022Premium: "NOT COVERED",
      summarySLTPremium: typeof surplusLinesTax === 'number' ? formatPrice(surplusLinesTax) : "NOT COVERED",
      summarySFPremium: typeof stampTax === 'number' ? formatPrice(stampTax) : "NOT COVERED",
      summaryPFPremium: typeof processingFee === 'number' ? formatPrice(processingFee) : "NOT COVERED",
      summaryOFPremium: typeof otherFees === 'number' ? formatPrice(otherFees) : "NOT COVERED",
      summaryTPDPremium: typeof totalPremium === 'number' ? formatPrice(totalPremium) : "NOT COVERED",
      summaryDPDPremium: "NOT COVERED",
    }, // 1
    {
      policyNo: alPolicyNo,
    }, // 2
    {
      namedInsured: company,
      mailAddress: mailingAddress,
      policyPeriodFrom:
        effectiveDate && effectiveDate?.seconds ?
        convertTimestampToDate(effectiveDate) : '',
      policyPeriodTo:
        expirationDate && expirationDate?.seconds ?
        convertTimestampToDate(expirationDate) : '',
      corporation: true,
      partnership: true,
      liability: true,
      other: false,
      individual: true,
      totalPremium: alPremiums?.GWP,
      surplusLineTax: alPremiums?.surplusLinesTaxAmount,
      stampingFee: alPremiums?.stampTaxAmount,
      managementFee: alPremiums?.processingFeeAmount,
      otherFees: alPremiums?.otherFees,
      totalCost: alPremiums?.totalPremium,
      coverageLiabilityLimit: `${coverageSingleLimit} CSL`,
      coverageLiabilityPremium: alPremiums?.GWP,
      coverageProtectionLimit: "Included",
      coverageProtectionPremium: "X",
      coverageMotoristLimit: "X",
      coverageMotoristPremium: "X",
      representativeName: "Alfredo Ronzon Perez",
      representativeTitle: "Lawyer",
      representativeSignature: "XXXX",
      representativeDate: formatDateToDDMMYYYY(new Date()),
    }, // 3
    {}, // 4 - 7
    {
      accept: "DDS",
      endorsementLimit: `${coverageSingleLimit} CSL`,
    }, // 8
    {}, // 9
    {}, // 10 - 22
    {
      namedInsured: company,
      endorsementEffectiveDate:
        effectiveDate && effectiveDate?.seconds ?
        convertTimestampToDate(effectiveDate) : '',
      nameOfPersonOrOrganization: company,
      addressOfPersonOrOrganization: address,
    }, // 23
    {
      signatureEffectiveDate: formatDateToDDMMYYYY(new Date()),
      signatureName: company,
    }, // 24
    {}, // 25 - 26
    {
      namedInsured: company,
      endorsementEffectiveDate:
        effectiveDate && effectiveDate?.seconds ?
        convertTimestampToDate(effectiveDate) : '',
      insureds: [
        {
          limitOfInsurance: "17,000.00",
          premium: "30,000.00"
        }
      ],
      description1: true,
      description2: false,
      description3: true,
      description4: false,
    }, // 27
    {
      policyEffectiveDate:
        effectiveDate && effectiveDate?.seconds ?
        convertTimestampToDate(effectiveDate) : '',
      company: company,
      producer: company,
      namedInsured: company,
      initials: "",
      limits: "",
      reject: true,
      date: formatDateToDDMMYYYY(new Date()),
    }, // 28
    {
      namedInsured: company,
      endorsementEffectiveDate:
        effectiveDate && effectiveDate?.seconds ?
        convertTimestampToDate(effectiveDate) : '',
      nameOfPersonOrOrganization: company,
      addressOfPersonOrOrganization: address,
    }, // 29
    {}, // 30 - 31
    { drivers }, // 32
    {
      usDot: '3454228',
      date: '3454228',
      issuedTo: company,
      of: 'TX',
      time: '12:01 AM',
      day: new Date().getDay(),
      month: new Date().getMonth(),
      year: new Date().getFullYear(),
      policyNo: alPolicyNo,
      effectiveDate,
      insuranceCompany: 'Accredited Specialty Insurance Company Inc',
      counterSignedBy: '',
      firstLimitAccept: 'X',
      firstLimit: '$1,000,000',
      secondLimitAccept: 'X',
      secondLimit: '$1,000,000',
      thirdLimit: '$1,000,000',
      phone: '(210) 948-0402'
    }, // MCS-09
    {
      namedInsured: company,
    }, // 124
  ];
};
