import React from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import updatePolicyField from "../../../utils/updatePolicyField";

export default function TaskCheckbox({
  policyID,
  formData,
  field,
  label,
  disabled,
  index
}) {
  const handleChange = (e) => {
    const id = e.target.id;
    const checked = e.target.checked;
    const payload = {
      [id]: checked
    }
    updatePolicyField(policyID, "underwritingTasks", {
      ...formData.underwritingTasks,
      proposalChecklist: payload,
    });
  };
  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            id={field}
            checked={formData?.underwritingTasks?.proposalChecklist?.[field] || false}
            onChange={handleChange}
            style={{
              fontSize: "20px",
              color: formData?.[field] ? "#116582" : "#072a48",
            }}
            className="larger-checkbox"
            size="large"
            disabled={disabled}
          />
        }
        label={label}
        className={`${index !== 0 ? "mt-2" : ""}`}
        disabled={disabled}
        
      />
    </>
  );
}
