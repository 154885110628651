import React, { useState } from "react";
import readXlsxFile from "read-excel-file";
import {
  doc,
  addDoc,
  collection,
  getDocs,
  runTransaction,
  query,
  where,
} from "firebase/firestore";
import { auth, db } from "../../../../firebase";
import updateSystemLog from "../../../utils/updateSystemLog";
import hasDuplicates from "../../../utils/hasDuplicates";
import useGeneralState from "../../../../Context/actions/useGeneralState";
import { isExpired } from "../../../../utils/helpers";
import endoStatusChangeNotification from "../../../utils/notifications/endoStatusChangeNotification";
import {
  filterRightPoliciesDetails,
  setPolicyInfo,
  setPolicyInfoForEndorsement,
} from "../../Drivers/setPolicyInfo";
import getTruckData from "../../../utils/validation/validateTruck/getTruckData";
import {
  selectIfNewOrExistingSubcollection,
  validateIfExists,
  validateIfVinExistsInAnotherPolicy,
} from "../../../utils/validateIfBlacklisted";
import { getDocumentPaths, multipleToEndorsements } from "../../Drivers/multipleToEndorsements";
import ApiService from "../../../../services/southern-api/apiService";
import "react-datepicker/dist/react-datepicker.css";

const useImportTrucks = (props) => {
  const policyID = props.policyID;
  const formData = props.formData;
  const { controlToast } = useGeneralState();
  const { agencyID, insuredID } = props.formData;
  const apiService = new ApiService("southern");
  const setSuccess = props.setSuccess
  const endoEffectiveDate = props.endoEffectiveDate
  const goBack = props.goBack
  const allFlag = props?.allFlag

  const handleUploadFile = async (
    file,
    setLoading,
    policyIDToUse,
    documentTypeToUse,
    renewalProcess,
    excelRowData
  ) => {
    if (setLoading) setLoading(true);
    if (!file) return console.log("no file");
    const startInfoRow = 10
    let rows = excelRowData ?  excelRowData : await readXlsxFile(file, { sheet: 1 })
    const prevValues = [...rows];
    const values = prevValues.splice(startInfoRow);
    let prevDupeArray = [...rows];
    const dupeArray = prevDupeArray.splice(startInfoRow);
    try {
      const existingTrucksSnapshot = await getDocs(
        query(
          collection(
            db,
            `agencies/${agencyID}/insureds/${insuredID}/power-units`
          ),
          where("status", "!=", "Deleted")
        )
      );
      const trucks = existingTrucksSnapshot.docs.map((doc) => doc.data());

      let isTheSamePolicyYouAreAboutToDuplicate = false;
      trucks.forEach((truck) => {
        truck.policiesDetails.forEach((policyDetail) => {
          if (
            policyDetail.policyID === policyIDToUse ||
            policyDetail.id === policyIDToUse
          ) {
            isTheSamePolicyYouAreAboutToDuplicate = true;
          }
        });
      });

      const existingTrucks = existingTrucksSnapshot.docs.map(
        (doc) => doc.data().truckVIN
      );

      function hasIntersection(existingVINs, trucksArray) {
        const vinSet = new Set(existingVINs);
        for (const truck of trucksArray) {
          // const truckVIN = truck[1];
          const truckVIN = truck[0];
          if (vinSet.has(truckVIN)) {
            return true;
          }
        }
        return false;
      }

      const valuesToImport = values.map((value) => {
        let resultObj = {};
        value.forEach((val, valIndex) => {
          if (valIndex === 0) resultObj["truckVIN"] = val;
          if (valIndex === 1) resultObj["truckYear"] = val;
          if (valIndex === 2) resultObj["truckRadius"] = val;
          if (valIndex === 3) resultObj["truckACV"] = val;
        });
        return resultObj;
      });

      const VINSToImport = valuesToImport.map(
        (valueToImport) => valueToImport.truckVIN
      );
      const valuesToValidate = trucks.filter((truck) => {
        return VINSToImport.includes(truck.truckVIN)
      });

      const isNot17Characters = VINSToImport.some((vin) => {
        return vin.length !== 17
      });

      const atLeastOneIsStillActive = valuesToValidate.some((truck) => {
        const policiesDetails = filterRightPoliciesDetails(
          truck.policiesDetails,
          policyIDToUse
        );
        const rightDetailStatus = policiesDetails?.status;
        const rightDetailRemovedDate = policiesDetails?.removedDate;

        return (
          !isExpired(rightDetailRemovedDate) &&
          (rightDetailStatus === "Active" ||
            rightDetailStatus === "Pending" ||
            rightDetailStatus === "Pending Deletion" ||
            rightDetailStatus === "Pending Exclusion")
        );
      });

      if (
        (hasIntersection(existingTrucks, dupeArray) ||
          hasDuplicates(dupeArray, 1)) &&
        atLeastOneIsStillActive &&
        !isNot17Characters &&
        (
          (renewalProcess &&
          documentTypeToUse === "Policy") ||
          !renewalProcess
        ) &&
        isTheSamePolicyYouAreAboutToDuplicate
      ) {
        goBack();
        alert(
          "Your sheet contains VINS already listed on this policy, has duplicate or not 17 VIN numbers. Please fix these errors and resubmit. No power-units have been added."
        );
        throw new Error();
      } else {
        const handleBatchTruckUpload = async (myValues, isPolicy) => {
          const subcollectionIds = [];
          const payloadSubcollection = [];
          try {
            for (const truck of myValues) {
              const spreadSheetData = {
                truckLookupStatus: "Incomplete",
                status: !renewalProcess && !isPolicy ? "Active" : "Pending",
                truckVIN: truck[0] || 0,
                truckYear: truck[1] || 0,
                truckRadius: truck[2] || 0,
                truckACV: truck[3] || 0,
                rating: 0,
              };
              const apiData = await getTruckData(truck[0], truck[1]);
              const docData = {
                ...spreadSheetData,
                ...apiData,
              };

              const verifiedInExistingData = await validateIfExists(
                [docData],
                agencyID,
                insuredID,
                "power-units"
              );

              const payload = setPolicyInfo(
                verifiedInExistingData[0],
                {
                  ...props?.formData,
                  policyEffectiveDate: props.formData?.effectiveDate,
                  glPolicyNo: props.formData?.glPolicyNo,
                  alPolicyNo: props.formData?.alPolicyNo,
                  policyID: policyIDToUse,
                  status: !renewalProcess && !isPolicy ? "Active" : "Pending",
                  documentType: documentTypeToUse,
                  documentSource:
                    documentTypeToUse === "Policy" ?
                    "Endorsement" : "Policy",
                  policyStatus: props.formData?.status,
                  agencyID,
                  insuredID,
                },
                "direct-addition"
              );

              const response = await selectIfNewOrExistingSubcollection(
                payload,
                agencyID,
                insuredID,
                "power-units"
              );

              if (response.id) {
                subcollectionIds.push(response.id);
              }
              payloadSubcollection.push(payload);
            }
            console.log("Batch upload successful!");
            if (setLoading) setLoading(false);
            return {
              ids: subcollectionIds,
              payload: payloadSubcollection,
            };
          } catch (error) {
            console.error("Error during batch upload:", error);
          }
        };

        const handleBatchTruckEndorsementUpload = async (
          myValues,
          subcollectionIds
        ) => {
          let endorsementEffectiveDate;
          if (
            props.formData.documentType === "Policy" ||
            props.formData.status === "In-Renewal"
          ) {
            endorsementEffectiveDate = endoEffectiveDate;
          } else {
            endorsementEffectiveDate = "";
          }

          const truckDataPromises = myValues.map(async (truck, truckIndex) => {
            const spreadSheetData = {
              truckLookupStatus: "Incomplete",
              status: "Pending",
              truckVIN: truck[0] || 0,
              truckYear: truck[1] || 0,
              truckRadius: truck[2] || 0,
              truckACV: truck[3] || 0,
              rating: 0,
              id: subcollectionIds[truckIndex],
            };
            const endoData = {
              endoEffectiveDate: endorsementEffectiveDate,
            };
            const apiData = await getTruckData(truck[0], truck[1]);
            const docData = {
              ...spreadSheetData,
              ...apiData,
              ...endoData,
            };
            return docData;
          });

          let truckData = await Promise.all(truckDataPromises);
          truckData = truckData.map((truck) => {
            return setPolicyInfo(
              truck,
              {
                ...props?.formData,
                policyEffectiveDate: props.formData?.effectiveDate,
                glPolicyNo: props.formData?.glPolicyNo,
                alPolicyNo: props.formData?.alPolicyNo,
                policyID: policyIDToUse,
                status: "Pending",
                documentType: documentTypeToUse,
                documentSource:
                  documentTypeToUse === "Policy" ?
                  "Endorsement" : "Policy",
                policyStatus: props.formData?.status,
                agencyID,
                insuredID,
              },
              "endo-addition"
            );
          });

          const countersDocRef = doc(db, "unique-numbers", "endorsements");
          await runTransaction(db, async (transaction) => {
            const countersDoc = await transaction.get(countersDocRef);
            const currentCounter = countersDoc.data().lastCount;
            const newCounter = currentCounter + 1;
            transaction.update(countersDocRef, {
              lastCount: newCounter,
            });
            const endosRef = collection(
              db,
              "policy-applications",
              policyIDToUse,
              "endorsements"
            );
            const type = "Add Power Units";
            const status = "Submitted";

            const payloadForEndo = setPolicyInfoForEndorsement(truckData, policyIDToUse);
            const docPaths = getDocumentPaths(payloadForEndo, type, formData);
            const payloadEndo = {
              endorsementNumber: String(newCounter),
              type,
              data: docPaths,
              dateCreated: new Date(),
              policyID: policyIDToUse,
              status,
              namedInsured: props.insuredInfo.company,
              alPolicyNo: props.formData.alPolicyNo,
              policyEffectiveDate: props.formData.effectiveDate,
              endoEffectiveDate,
              author: auth.currentUser.displayName,
              authorID: auth.currentUser.uid,
              agencyID: props.formData.agencyID,
              insuredID: props.formData.insuredID,
              documentSource: "Endorsement",
            }

            const thereIsValidSearchValue = payloadForEndo?.some(data => {
              const thereIsAValue = Boolean(
                data?.licenseNumber ||
                data?.driverFirst ||
                data?.driverLast ||
                data?.truckVIN ||
                data?.trailerVIN
              )
              return thereIsAValue
            })

            if (payloadForEndo?.length > 0  && thereIsValidSearchValue) {
              payloadEndo['searchData'] = payloadForEndo.map(data => {
                let searchPayload = {}
                if (data?.driverFirst || data?.driverLast) {
                  searchPayload['driverName'] = `${data?.driverFirst ? data?.driverFirst : ""} ${data?.driverLast ? data?.driverLast : ""}`
                }
                if (data?.licenseNumber) {
                  searchPayload['licenseNumber'] = data?.licenseNumber
                }
                if (data?.truckVIN) {
                  searchPayload['truckVIN'] = data?.truckVIN
                }
                if (data?.trailerVIN) {
                  searchPayload['trailerVIN'] = data?.trailerVIN
                }
                const searchPayloadKeys = Object.keys(searchPayload)
                if (!searchPayloadKeys?.length) {
                  searchPayload = null
                }
                return searchPayload
              })
            }

            const docRef = await addDoc(endosRef, payloadEndo);
            endoStatusChangeNotification(
              type,
              String(newCounter),
              status,
              docRef.id,
              policyIDToUse,
              props.userInfo.agencyID
            );
            if (setSuccess) setSuccess(true);
          });
        };
        const uploadedTrucks = await handleBatchTruckUpload(
          values,
          documentTypeToUse === "Policy"
        );
        if (documentTypeToUse === "Policy") {
          await handleBatchTruckEndorsementUpload(values, uploadedTrucks.ids);
        }
        await updateSystemLog(
          auth,
          policyIDToUse,
          "Power Units List Imported To Application For " +
            props.insuredInfo.company,
          "Power Units List Uploaded",
          { ...values, policyID: policyIDToUse }
        );
        if (setLoading) setLoading(false);
        if (!allFlag) goBack();
        if (renewalProcess && !renewalProcess && documentTypeToUse === "Policy") {
          return {
            ...uploadedTrucks,
            ids: uploadedTrucks.ids,
          };
        }
        delete uploadedTrucks.ids;
        return uploadedTrucks;
      }
    } catch (error) {
      if (setLoading) setLoading(false);
      console.error("Error fetching existing Power Units:", error);
      throw new Error();
    }
  };

  const mirrorLogic = async (file, setLoading, excelRowData) => {
    const isRenewalApplication =
      formData.status === "In-Renewal" &&
      formData.documentType === "Application";
    const isRenewalPolicy =
      formData.status === "In-Renewal" && formData.documentType === "Policy";
    const notARenewalProcess = formData.status !== "In-Renewal";
    let firstDataPayload = {};
    try {
      if (notARenewalProcess) {
        const trucks = await handleUploadFile(
          file,
          setLoading,
          policyID,
          formData.documentType,
          false,
          null,
          excelRowData
        );
        const isPolicy = formData.documentType === "Policy";
        const message = isPolicy
          ? "You created an Add Power Units endorsement successfully!"
          : "You created a list of Power Units successfully!";
        controlToast(true, message, "success");
        firstDataPayload = trucks;
      }
      if (isRenewalApplication) {
        const trucks = await handleUploadFile(
          file,
          setLoading,
          policyID,
          "Application",
          true,
          null,
          excelRowData
        );
        await handleUploadFile(
          file,
          setLoading,
          formData.renewalPolicyID,
          "Policy",
          true,
          trucks.ids,
          excelRowData
        );
        const message =
          "You created an Add Power Units endorsement successfully!";
        if (!allFlag) {
          controlToast(true, message, "success");
        }
        firstDataPayload = trucks;
      }
      if (isRenewalPolicy) {
        const trucks = await handleUploadFile(
          file,
          setLoading,
          formData.renewalApplicationID,
          "Application",
          true,
          null,
          excelRowData
        );
        await handleUploadFile(
          file,
          setLoading,
          policyID,
          "Policy",
          true,
          trucks.ids,
          excelRowData
        );
        const message =
          "You created an Add Power Units endorsement successfully!";
        controlToast(true, message, "success");
        firstDataPayload = trucks;
      }

      const payload = [];
      const payloadSubcollection = firstDataPayload?.payload || [];
      for (let truckData of payloadSubcollection) {
        const existingActiveVinsInDifferentPolicies =
          await validateIfVinExistsInAnotherPolicy(
            truckData.truckVIN,
            "power-units",
            "truckVIN",
            policyID
          );

        if (existingActiveVinsInDifferentPolicies.length > 0) {
          payload.push({
            vin: truckData.truckVIN,
            policiesDetails: existingActiveVinsInDifferentPolicies,
          });
        }
      }
      try {
        if (payload.length > 0 && firstDataPayload) {
          await apiService.sendEmailPoliciesDetailsOfManyVins({
            data: payload,
            agencyID,
          });
        }
      } catch (error) {
        console.error("Error sending email:", error);
      }
    } catch (error) {
      if (!allFlag) {
        controlToast(true, "No power unit was created", "error");
      }
      console.error(error);
      if (!allFlag) goBack()
      throw error
    }
  };

  return { mirrorLogic }
};

export default useImportTrucks;
