import React from "react";
import { t } from "i18next";
import { Td } from "react-super-responsive-table";

export default function UnderwriterHeaders() {
  return (
    <>
      <Td className="bg-[#072a48] text-white text-center">
        {t("Manual Adj.")}
      </Td>
      <Td className="bg-[#072a48] text-white text-center">
        {t("Driver Adj.")}
      </Td>
      <Td className="bg-[#072a48] text-white text-center">
        {t("Loss Ratio Adj.")}
      </Td>
      <Td className="bg-[#072a48] text-white text-center">
        {t("Safety Adj.")}
      </Td>
    </>
  );
}
