import { useContext } from "react"
import { getUserByUidRequest, getUsersRequest, setUserByUidRequest } from "../../services/firebase-api/users"
import { GET_USER, GET_USERS, SET_USER } from "../types"
import { AppContext, initialState } from "../AppContext"

const useUsersState = () => {

    const { state, dispatch } = useContext(AppContext)
    const { users, user } = state || initialState

    const getUsers = async () => {
        try {
            const usersRequest = await getUsersRequest()
            dispatch({ type: GET_USERS, payload: usersRequest })
            return usersRequest
        } catch (error) {
            throw error
        }
    }

    const getUser = async (id, bodyToNotRequest) => {
        try {
            let userRequest = bodyToNotRequest
            if (!bodyToNotRequest) {
                userRequest = await getUserByUidRequest(id)
            }
            const userPayload = {
                ...userRequest,
                uid: id
            }
            dispatch({ type: GET_USER, payload: userPayload })
            return userPayload
        } catch (error) {
            throw error
        }
    }

    const setUser = async (id, newData, pureStateManagement) => {
        try {
            if (!pureStateManagement) {
                await setUserByUidRequest(id, newData)
            }
            dispatch({ type: SET_USER, payload: newData })
        } catch (error) {
            throw error
        }
    }

    return {
        getUsers,
        getUser,
        setUser,
        users,
        user
    }
}

export default useUsersState