import React, { useState } from "react";
import readXlsxFile from "read-excel-file";
import useGeneralState from "../../../../../Context/actions/useGeneralState";
import DragDropImportList from "../../../../assets/forms/DragDropFileUpload/DragDropImportList";
import ApprovalButtons from "../../../../assets/forms/ApprovalButtons";
import { db } from "../../../../../firebase";
import { collection, doc, writeBatch } from "firebase/firestore";

export default function ImportDriversTrainings({
  setShowImport,
  driversLicenseNumber,
}) {
  const { controlToast } = useGeneralState();
  const [file, setFile] = useState(null);

  const handleDate = (dateString) => {
    if (typeof dateString === "string") {
      const parts = dateString.split("/");
      if (parts.length === 3) {
        const formattedDate = new Date(`${parts[2]}-${parts[0]}-${parts[1]}`);
        return formattedDate;
      }
    }
    return dateString;
  };

  const goBack = () => {
    setShowImport(false);
  };

  const handleUploadFile = async (file, setLoading) => {
    setLoading(true);
    if (!file) return console.log("no file");
    const rows = await readXlsxFile(file, { sheet: 1 });
    const prevValues = [...rows];
    const values = prevValues.splice(4);
    try {
      const batch = writeBatch(db);
      const trainingsCollectionRef = collection(db, "driver-trainings");
      const subcollectionData = [];
      for (const training of values) {
        const docData = {
          trainingName: training[5],
          institution: "JJ Keller",
          completionDate: handleDate(training[6]) || new Date(),
          driversLicenseNumber,
          notes: "N/A",
          certificateFileURL: [],
        };
        if (docData.trainingName) {
          const docRef = doc(trainingsCollectionRef);
          batch.set(docRef, docData);
          subcollectionData.push({
            ...docData,
            id: docRef.id,
          });
        }
      }
      await batch.commit();
      const message = "You created a list of Driver's Trainings successfully!";
      controlToast(true, message, "success");
      setLoading(false);
      goBack();
    } catch (error) {
      console.error("Error fetching existing drivers trainings:", error);
        goBack();
        setLoading(false);
        throw new Error();
    }
  };

  return (
    <main>
      <div>
        <section>
          <div className="max-w-[550px]">
            <h1 className="text-3xl mt-2 font-semibold md:mb-0">
                Import Driver's Trainings
            </h1>
            <form onSubmit={(e) => handleUploadFile(e)}>
              <DragDropImportList
                file={file}
                setFile={setFile}
                handleUpload={handleUploadFile}
                fileTypes={["XLSX"]}
              />
              <ApprovalButtons
                onApprove={null}
                handleAsSubmit={true}
                onDecline={(e) => {
                  goBack();
                }}
                titleAccept="Upload"
                titleDecline="Go Back"
                disabledAccept={true}
              />
            </form>
          </div>
        </section>
      </div>
    </main>
  );
}
