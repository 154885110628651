import React from "react";
import ProfileTable from "./ProfileTable";

export default function DriverProfile({userInfo, setDriverModalIsOpen, dataReady, aggregatedDriverInfo, driverModalIsOpen, closeDriverModal, openDriverModal}) {
  
  return (
    <div>
      <ProfileTable
      userInfo={userInfo}
      setDriverModalIsOpen={setDriverModalIsOpen}
      driverModalIsOpen={driverModalIsOpen}
      closeDriverModal={closeDriverModal}
      openDriverModal={openDriverModal}
        dataReady={dataReady}
        aggregatedDriverInfo={aggregatedDriverInfo}
      />
    </div>
  );
}
