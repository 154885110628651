import { USER_INFO_ROLE } from "../../../utils/constants";
import UnderwriterAtAGlance from "../../user/Dashboard/userAtAGlances/UnderwriterAtAGlance";
import AgentAtAGlance from "../../user/Dashboard/userAtAGlances/AgentAtAGlance";
import NewAgentAtAGlance from "../../user/Dashboard/userAtAGlances/NewAgentAtAGlance";
import ClientAtAGlance from "../../user/Dashboard/userAtAGlances/ClientAtAGlance";
import config from "../../../config/env";

const GetUserAtAGlanceSection = ({
  pendingQuoteRequestCount,
  pendingAppointmentCount,
  submittedRequests,
  incompleteApplications,
  incompleteSubmissionCount,
  inProcessRequests,
  approvedApplications,
  approvedSubmissions,
  sentRequests,
  userInfo,
  coverageCounts,
  setCoverageCounts,
}) => {
  const {
    CLIENT_ROLE,
    ADMIN_ROLE,
    AGENT_ROLE,
    UNDERWRITER_ROLE,
    SUBUSER_ROLE,
  } = USER_INFO_ROLE;

  const { REACT_APP_LICONA_INSURANCE_GROUP_ID } = config

  if (userInfo.role === UNDERWRITER_ROLE || userInfo.role === ADMIN_ROLE) {
    return (
      <>
        {/* // // I temporarily put the agent menu in place of the underwriter menu
      // <UnderwriterAtAGlance
      //   pendingQuoteRequestCount={pendingQuoteRequestCount}
      //   pendingAppointmentCount={pendingAppointmentCount}
      //   submittedRequests={submittedRequests}
      //   incompleteApplications={incompleteApplications}
      //   incompleteSubmissionCount={incompleteSubmissionCount}
      //   inProcessRequests={inProcessRequests}
      //   approvedApplications={approvedApplications}
      //   approvedSubmissions={approvedSubmissions}
      //   sentRequests={sentRequests}
      // /> */}
        {userInfo.agencyID === REACT_APP_LICONA_INSURANCE_GROUP_ID ? (
          <NewAgentAtAGlance
            pendingQuoteRequestCount={pendingQuoteRequestCount}
            pendingAppointmentCount={pendingAppointmentCount}
            submittedRequests={submittedRequests}
            incompleteApplications={incompleteApplications}
            incompleteSubmissionCount={incompleteSubmissionCount}
            inProcessRequests={inProcessRequests}
            approvedApplications={approvedApplications}
            approvedSubmissions={approvedSubmissions}
            sentRequests={sentRequests}
            agencyID={userInfo.agencyID}
            userInfo={userInfo}
            coverageCounts={coverageCounts}
            setCoverageCounts={setCoverageCounts}
          />
        ) : (
          <AgentAtAGlance
            pendingQuoteRequestCount={pendingQuoteRequestCount}
            pendingAppointmentCount={pendingAppointmentCount}
            submittedRequests={submittedRequests}
            incompleteApplications={incompleteApplications}
            incompleteSubmissionCount={incompleteSubmissionCount}
            inProcessRequests={inProcessRequests}
            approvedApplications={approvedApplications}
            approvedSubmissions={approvedSubmissions}
            sentRequests={sentRequests}
          />
        )}
      </>
    );
  } else if (userInfo.role === AGENT_ROLE || userInfo.role === SUBUSER_ROLE) {
    return (
      <>
        {userInfo.agencyID === REACT_APP_LICONA_INSURANCE_GROUP_ID ? (
          <NewAgentAtAGlance
            pendingQuoteRequestCount={pendingQuoteRequestCount}
            pendingAppointmentCount={pendingAppointmentCount}
            submittedRequests={submittedRequests}
            incompleteApplications={incompleteApplications}
            incompleteSubmissionCount={incompleteSubmissionCount}
            inProcessRequests={inProcessRequests}
            approvedApplications={approvedApplications}
            approvedSubmissions={approvedSubmissions}
            sentRequests={sentRequests}
            agencyID={userInfo.agencyID}
            userInfo={userInfo}
            coverageCounts={coverageCounts}
            setCoverageCounts={setCoverageCounts}
          />
        ) : (
          <AgentAtAGlance
            pendingQuoteRequestCount={pendingQuoteRequestCount}
            pendingAppointmentCount={pendingAppointmentCount}
            submittedRequests={submittedRequests}
            incompleteApplications={incompleteApplications}
            incompleteSubmissionCount={incompleteSubmissionCount}
            inProcessRequests={inProcessRequests}
            approvedApplications={approvedApplications}
            approvedSubmissions={approvedSubmissions}
            sentRequests={sentRequests}
          />
        )}
      </>
    );
  } else if (userInfo.role === CLIENT_ROLE) {
    return <ClientAtAGlance />;
  } else {
    return null;
  }
};

export default GetUserAtAGlanceSection;
