import React from 'react';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';

export default function RedButton({
  onClickFunction,
  disabled,
  hasContentToLeft,
  hasContentToBottom,
  hasContentToRight,
  hasContentToTop,
  text,
  className,
  icon
}) {
  return (
    <Button
      onClick={(e) => onClickFunction(e)}
      variant="contained"
      className={`${className ? className : ''} rounded`}
      disabled={disabled}
      startIcon={
        icon === 'no-icon' ? null :
        icon ? icon :
        <DeleteIcon className="bg-[8B0000] w-3 h-3" style={{ color: '#FFFFFF' }} />
    }
      sx={{
        borderRadius: 8,
        backgroundColor: '#8B0000',
        '&:hover': {
          backgroundColor: '#8B0000',
        },
        marginLeft: hasContentToLeft ? '8px' : '0',
        marginBottom: hasContentToBottom ? '8px' : '0',
        marginRight: hasContentToRight ? '8px' : '0',
        marginTop: hasContentToTop ? '8px' : '0',
        fontSize: '0.75rem',
      }}
    >
      {text}
    </Button>
  );
}
