import getUnderwriterRecipientList from './utils/getUnderwriterRecipientList';

const agencyAppointmentStatusChangeNotification = async () => {
    try {
        let underwriterRecipientList = await getUnderwriterRecipientList(
          "agencyAppointmentNotificationRecipients"
        );
        const url = window?.location?.href
        const request = {
            to: underwriterRecipientList,
            cc: underwriterRecipientList,
            url
        }
        return request
    } catch (error) {
        console.error('Error:', error);
    }
};

export default agencyAppointmentStatusChangeNotification;
