import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { arrayUnion, doc, getDoc, updateDoc } from "firebase/firestore";
import { db, storage } from "../../../../firebase";

const uploadFile = (
  file,
  setProgressState,
  policyID,
  parentFolder,
  otherData
) => {
  console.log("uploading file");
  if (!file) return console.log("no file");

  // Extract file extension
  const fileExtension = file.name.split(".").pop().toLowerCase();

  const storageRef = ref(
    storage,
    `files/${parentFolder}/${policyID}/loss-runs/${file.name}`
  );
  const uploadTask = uploadBytesResumable(storageRef, file);

  uploadTask.on(
    "state_changed",
    (snapshot) => {
      const progress = Math.round(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      );
      setProgressState(progress);
    },
    (error) => {
      alert(error);
    },
    async () => {
      const policyRef = doc(db, "policy-applications", policyID);
      const policySnapshot = await getDoc(policyRef);

      let existingOtherLossRuns = [];

      if (policySnapshot.exists()) {
        // Get the existing OtherLossRuns data
        existingOtherLossRuns = policySnapshot.data().OtherLossRuns || [];
      }

      getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
        const policyRef = doc(db, "policy-applications", policyID);

        const newLossRunData = {
          ...otherData,
          photoURL: downloadURL,
          photoFileType: fileExtension,
        };

        await updateDoc(policyRef, {
          OtherLossRuns: arrayUnion(newLossRunData),
        });
        console.log([...existingOtherLossRuns, newLossRunData]);
      });
    }
  );
};

export default uploadFile;
