import React, { useState, useEffect } from "react";
import DisplayNote from "./DisplayNote";
import useTranslations from "../../../../Context/actions/useTranslations";
import "react-quill/dist/quill.snow.css";

export default function NoteHistory({notesList, documentID, collectionPath}) {
  const [expandedNotes, setExpandedNotes] = useState({});
  const [editMode, setEditMode] = useState({});
  const [editedContent, setEditedContent] = useState({});
  const [editedSubject, setEditedSubject] = useState({});
  const { t } = useTranslations()

  useEffect(() => {
    const initialEditMode = {};
    notesList.forEach((note) => {
      initialEditMode[note.id] = false;
    });
    setEditMode(initialEditMode);
  }, [notesList]);

  return (
    <div className="mt-4 mb-5">
      <p className="text-lg font-semibold mb-2">{t("plm.drivers.details.note-history")}</p>
      {notesList.length === 0 ? (
        <p>{t('plm.drivers.details.no-notes-yet')}</p>
      ) : (
        <ul className="w-full">
          {notesList.map((note, i) => {
            return (
              <DisplayNote
                note={note}
                i={i}
                documentID={documentID}
                notesList={notesList}
                editMode={editMode}
                editedContent={editedContent}
                editedSubject={editedSubject}
                setEditedContent={setEditedContent}
                setEditMode={setEditMode}
                setEditedSubject={setEditedSubject}
                expandedNotes={expandedNotes}
                setExpandedNotes={setExpandedNotes}
                collectionPath={collectionPath}
              />
            );
          })}
        </ul>
      )}
    </div>
  );
}
