import { doc, getDoc, updateDoc } from "firebase/firestore";
import { auth, db } from "../../firebase";
import policyStatusChangeNotification from "./notifications/policyStatusChangeNotification";
import updateSystemLog from "./updateSystemLog";

const requestBind = async (
  e,
  policyID,
  agentComments,
  formData,
  userAuth,
  userState,
  insuredInfo
) => {
  e.preventDefault();

  const sourceDocRef = doc(db, "policy-applications", policyID);
  const sourceDocSnapshot = await getDoc(sourceDocRef);
  const docData = sourceDocSnapshot.data();
  const documentType = "Bind Order";
  const status = "Submitted";
  const policyNumber = docData.alPolicyNo || "";
  const agencyID = docData.agencyID;

  const bindData = {
    agentComments,
    requestDate: new Date(),
    bindingUser: auth.currentUser.displayName,
    bindingUserID: auth.currentUser.uid,
  };

  const policyData = {
    status,
    documentType,
    returnedToAgentData: null,
    bindData,
  };

  // Make sure there is a quote selected and applied for all coverages
  const documentIsValid = () => {
    if (!docData.selectedQuote) return false;
    if (docData.selectedQuote === "") return false;
    if (docData.selectedQuote === "#") return false;
    return true;
  };

  const shouldProceed = documentIsValid();

  !shouldProceed &&
    alert("Please select a quote before submitting the bind request.");

  shouldProceed &&
    (await updateDoc(doc(db, "policy-applications", policyID), policyData));

  shouldProceed &&
    policyStatusChangeNotification(
      documentType,
      policyNumber,
      status,
      policyID,
      agencyID,
      formData,
      userAuth,
      userState,
      insuredInfo
    )
    shouldProceed &&         updateSystemLog(
      auth,
      policyID,
      "Bind Requested for " + insuredInfo.company + ".",
      "Bind Requested",
      { agencyID, policyID }
    );
};

export default requestBind;
