import { useContext } from "react"
import { RESET_STATE, SET_TOAST } from "../types"
import { AppContext, initialState } from "../AppContext"

const useGeneralState = () => {

    const { state, dispatch } = useContext(AppContext)
    const { toast } = state

    const resetState = async () => {
        try {
            dispatch({ type: RESET_STATE, payload: initialState })
        } catch (error) {
            throw error
        }
    }

    const controlToast = (open, message, type) => {
        dispatch({ type: SET_TOAST, payload: {
            open,
            message,
            type
        } })
    }

    return {
        resetState,
        controlToast,
        toast
    }
}

export default useGeneralState