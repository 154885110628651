import React, { useState, useEffect } from "react";
import { db } from "../../../../firebase";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import { collection, getDoc, doc, updateDoc } from "firebase/firestore";

const ActionItemTracker = ({ reportId }) => {
  // Define initial state for action items
  const [actionItems, setActionItems] = useState([]);
  const [newActionItem, setNewActionItem] = useState({
    description: "",
    status: "Pending",
    deadline: "",
    responsiblePerson: "",
  });

  // Fetch action items from Firestore on component mount
  useEffect(() => {
    const fetchActionItems = async () => {
      try {
        const collectionRef = collection(db, "loss-control-reports");
        const doc = await getDoc(collectionRef, reportId);
        const docData = doc.data();
        setActionItems(docData.actionItems || []);
      } catch (error) {
        console.error("Error fetching action items: ", error);
      }
    };

    fetchActionItems();
  }, [reportId]);

  // Handle input changes for the new action item form
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewActionItem((prev) => ({ ...prev, [name]: value }));
  };

  // Handle adding a new action item
  const handleAddActionItem = async (e) => {
    e.preventDefault();
    const updatedActionItems = [...actionItems, newActionItem];
    setActionItems(updatedActionItems);
    setNewActionItem({
      description: "",
      status: "Pending",
      deadline: "",
      responsiblePerson: "",
    });

    try {
      const collectionRef = collection(db, "loss-control-reports");
      const docRef = doc(collectionRef, reportId);
      await updateDoc(docRef, { actionItems: updatedActionItems });
      alert("Action item added successfully!");
    } catch (error) {
      console.error("Error adding action item: ", error);
      alert("Failed to add action item.");
    }
  };

  // Handle updating action item status
  const handleUpdateStatus = async (index, status) => {
    const updatedActionItems = [...actionItems];
    updatedActionItems[index].status = status;
    setActionItems(updatedActionItems);

    try {
      const collectionRef = collection(db, "loss-control-reports");
      const docRef = doc(collectionRef, reportId);
      await updateDoc(docRef, { actionItems: updatedActionItems });
    } catch (error) {
      console.error("Error updating action item status: ", error);
    }
  };

  return (
    <Card sx={{ maxWidth: 800, margin: 'auto', padding: 2, marginBottom: 2 }}>
      <CardContent>
        <Typography variant="h5" component="div" gutterBottom>
          Action Item Tracker
        </Typography>
        <form onSubmit={e => handleAddActionItem(e)}>
          <TextField
            label="Description"
            name="description"
            value={newActionItem.description}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            required
          />
          <FormControl fullWidth margin="normal" required>
            <InputLabel>Status</InputLabel>
            <Select
              name="status"
              value={newActionItem.status}
              onChange={handleInputChange}
            >
              <MenuItem value="Pending">Pending</MenuItem>
              <MenuItem value="In Progress">In Progress</MenuItem>
              <MenuItem value="Completed">Completed</MenuItem>
            </Select>
          </FormControl>
          <TextField
            type="date"
            name="deadline"
            value={newActionItem.deadline}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Responsible Person"
            name="responsiblePerson"
            value={newActionItem.responsiblePerson}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <Button type="submit" variant="contained" color="primary">
            Add Action Item
          </Button>
        </form>

        <List>
          {actionItems.map((item, index) => (
            <ListItem key={index}>
              <ListItemText
                primary={`Description: ${item.description}`}
                secondary={`Status: ${item.status}, Deadline: ${item.deadline}, Responsible Person: ${item.responsiblePerson}`}
              />
              <ListItemSecondaryAction>
                {item.status !== "Completed" && (
                  <IconButton
                    edge="end"
                    onClick={() => handleUpdateStatus(index, "Completed")}
                    color="primary"
                  >
                    <CheckIcon />
                  </IconButton>
                )}
                <IconButton
                  edge="end"
                  onClick={() => handleUpdateStatus(index, "Removed")}
                  color="error"
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};

export default ActionItemTracker;
