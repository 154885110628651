import Form from "../../Components/generals/Forms/Form";
import useChangePassword from "../../hooks/auth/useChangePassword";

function ChangePassword() {
  
  const {
    formSettings
  } = useChangePassword()

  return (
    <Form
      formSettings={formSettings}
    />
  );
}

export default ChangePassword;
