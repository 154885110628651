import { doc, updateDoc } from "firebase/firestore"
import { db } from "../../firebase"

const changeUserRole = async (userID, newRole) => {
    console.log(userID)
updateDoc(doc(db, "users", userID), {
    role: newRole
})
}

export default changeUserRole