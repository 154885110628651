import axios from "axios";
import React, { useEffect, useState } from "react";
import PolicyDetails from "./PolicyDetails";
import { Typography } from "@mui/material";
import updatePolicyField from "../../utils/updatePolicyField";

export default function KPBrokersInfo({
  userInfo,
  activePolicies,
}) {
  const isAdminOrUnderwriter =
    userInfo.role === "Underwriter" || userInfo.role === "Admin";

  return (
    <main>
      {activePolicies.length > 0 && (
        <>
          <Typography variant="h5" component="h2">
            Active Policies
          </Typography>
          <p className="mt-2 mb-3">
            The following policies are currently active. To see expired
            policies, visit
            {isAdminOrUnderwriter ? ` the insured's ` : ` your `} policy
            history.
          </p>
          {activePolicies.length > 0 &&
            activePolicies.map((policy) => (
              <PolicyDetails data={policy} userInfo={userInfo} />
            ))}
        </>
      )}
    </main>
  );
}
