import calculatePremiums from "../../../utils/calculatePremiums";
import updatePolicyField from "../../../utils/updatePolicyField";
import findTotalAdjustments from "./findTotalAdjustments";

const applyAdjustments = async (
  e,
  quoteData,
  policyID,
  formData,
  taxSchedule,
  formDataGL
) => {
  e.preventDefault();
  console.log(quoteData.adjustmentFormData.percentage);
  const totalAdjustment = findTotalAdjustments(quoteData);

  console.log("totatl adjustment: ", totalAdjustment);

  await calculatePremiums(
    policyID,
    formData,
    e,
    quoteData.id,
    totalAdjustment,
    false,
    false,
    null,
    formDataGL
  );

  document.getElementById("premiums").scrollIntoView({
    behavior: "smooth",
  });

  quoteData.adjustmentFormData.deductible &&
    (await updatePolicyField(
      policyID,
      "biLiabilityDeductible",
      quoteData.adjustmentFormData.deductible
    ));
};

export default applyAdjustments;
