import { collection, onSnapshot, query } from "firebase/firestore";
import { useEffect } from "react";
import { db } from "../../firebase";
import ApiService from "../../services/southern-api/apiService";

const useLossControlListener = (mainCollection, subCollection, setState) => {

  const apiService = new ApiService()

  useEffect(() => {
    
    const isTemporalUrl =
      typeof window !== 'undefined' ?
      window?.location?.pathname?.includes('temporarily') : false

    if (isTemporalUrl) {
      apiService.renewalProcessLossControl({
        mainCollection,
        subCollection
      }).then((fetchedDocuments) => {
        setState(fetchedDocuments)
        return () => {}
      }).catch((error) => {
        console.error("Error fetching documents: ", error);
        return () => {}
      })
      return () => {}
    }

    const unsubscribe = onSnapshot(
      query(collection(db, "loss-control", mainCollection, subCollection)),
      (snapshot) => {
        const fetchedDocuments = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setState(fetchedDocuments)
      },
      (error) => {
        console.error("Error fetching documents: ", error);
        throw error
      }
    );
    return () => unsubscribe();
  }, []);
};

export default useLossControlListener;
