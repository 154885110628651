import React from "react";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";

export default function UserListCheckbox({field, name, userData}) {
  const updateUserIsAgent = async (userData) => {
    try {
      // Update the Firestore document with the new permissions and endorsements
      const userRef = doc(db, "users", userData.id);
      await updateDoc(userRef, {
        userIsAgent: !userData.userIsAgent,
      });

      console.log("Permissions and endorsements updated successfully!");
    } catch (error) {
      console.error("Error updating permissions and endorsements: ", error);
    }
  };
  return (
    <div className="flex items-center my-2">
      <input
        id={field}
        name={name}
        type="checkbox"
        className="large-checkbox mx-auto"
        checked={userData?.userIsAgent}
        onChange={(e) => {
          updateUserIsAgent(userData);
        }}
      />
    </div>
  );
}
