import updatePolicyField from "../../../utils/updatePolicyField";
import updateCommodityTotal from "./updateCommodityTotal";

  const handleChangeCommodityPercentage = async (i, e, formData, policyID) => {
    const values = [...formData.commodityFields];
    values[i][e.target.name] = e.target.value;
    await updatePolicyField(policyID, "commodityFields", values);
    updateCommodityTotal(policyID, formData);
  };

  export default handleChangeCommodityPercentage