import { auth } from "../../firebase";
import ManageAnnouncements from "../../Components/Announcements/";
import SetProfile from "../../Components/user/EditProfile";
import UserInfo from "../../Components/user/Dashboard/UserInfo";
import { USER_INFO_ROLE, USER_INFO_STATUS } from "../../utils/constants";
import useTranslations from "../../Context/actions/useTranslations";
import GetUserAtAGlanceSection from "../../Components/generals/GetUserAtAGlanceSection";
import { getDashboardTitle } from "../../utils/helpers";
import useAccount from "../../hooks/useAccount";
import { Container, Grid } from "@mui/material";
import styles from "../../assets/styles/pages/account";
import "../../assets/styles/app.css";
import TableSkeleton from "../../Components/generals/TableSkeleton";
import PoliciesUpForRenewal from "../../Components/PoliciesUpForRenewalTable";
import useInitialSetting from "../../hooks/useInitialSetting";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import { useState } from "react";
import config from "../../config/env";
import ToDoList from "../../Components/ToDo";
import Client from "../../Components/Client";

function Dashboard(props) {
  const { ADMIN_ROLE, AGENT_ROLE, UNDERWRITER_ROLE, CLIENT_ROLE } =
    USER_INFO_ROLE;

  const { APPOINTED_STATUS } = USER_INFO_STATUS;

  const { REACT_APP_LICONA_INSURANCE_GROUP_ID } = config;

  const { t, language, spanish } = useTranslations();

  const [coverageCounts, setCoverageCounts] = useState({
    APD: 0,
    MTC: 0,
    AL: 0,
    GL: 0,
  });

  const {
    userID,
    userInfo,
    noProfile,
    approvedSubmissions,
    incompleteSubmissionCount,
    pendingQuoteRequestCount,
    setApprovedSubmissions,
    setIncompleteSubmissionCount,
    setPendingQuoteRequestCount,
    incompleteApplications,
    approvedApplications,
    inProcessRequests,
    sentRequests,
    submittedRequests,
    pendingAppointmentCount,
  } = useAccount(props);

  const { setLoading, loading } = useInitialSetting();

  const buttons = [
    { icon: <AddCircleOutlineIcon />, label: "Add" },
    { icon: <DeleteOutlineIcon />, label: "Delete" },
    { icon: <EditIcon />, label: "Edit" },
    // Add more button objects as needed
  ];

  return (
    <>
      {userInfo.role === CLIENT_ROLE ? (
        <Client userInfo={userInfo} />
      ) : (
        <>
          {!userInfo?.first ? (
            <TableSkeleton rows={5} columns={5} />
          ) : noProfile === true ? (
            <>
              <SetProfile userID={userID} />
            </>
          ) : (
            <div>
              <Container maxWidth="xl">
                <h1 className={styles.title}>
                  {getDashboardTitle(language, userInfo, t, spanish)}
                </h1>
              </Container>
              {/* 
              <Container maxWidth="xl">
                <GetUserMenuSection userInfo={userInfo} userID={userID} />
              </Container> 
              */}
              <section>
                <div>
                  <Container maxWidth="xl">
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <div className="p-2">
                          <UserInfo
                            userInfo={userInfo}
                            userPhoto={auth?.currentUser?.photoURL}
                          />
                        </div>
                        <div className="p-2">
                          <article
                            className={styles.manageAnnouncementsContainer}
                          >
                            <ManageAnnouncements
                              userID={userID}
                              name={userInfo.first + " " + userInfo.last}
                              role={userInfo.role}
                            />
                          </article>
                          {userInfo.agencyID ===
                            REACT_APP_LICONA_INSURANCE_GROUP_ID && (
                            <PoliciesUpForRenewal
                              userInfo={userInfo}
                              loading={loading}
                              setLoading={setLoading}
                            />
                          )}
                          {/* TODO: Should we bring this back? */}
                          {/* <RecentUnderwritingDecisions
                          userInfo={userInfo}
                          loading={loading}
                          setLoading={setLoading}
                        /> */}
                        </div>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        {userInfo.agencyID !==
                          REACT_APP_LICONA_INSURANCE_GROUP_ID && (
                          <div className="p-2">
                            <GetUserAtAGlanceSection
                              pendingQuoteRequestCount={
                                pendingQuoteRequestCount
                              }
                              pendingAppointmentCount={pendingAppointmentCount}
                              submittedRequests={submittedRequests}
                              incompleteApplications={incompleteApplications}
                              incompleteSubmissionCount={
                                incompleteSubmissionCount
                              }
                              inProcessRequests={inProcessRequests}
                              approvedApplications={approvedApplications}
                              approvedSubmissions={approvedSubmissions}
                              sentRequests={sentRequests}
                              userInfo={userInfo}
                              coverageCounts={coverageCounts}
                              setCoverageCounts={setCoverageCounts}
                            />
                          </div>
                        )}
                        <div className="p-2">
                          {userInfo.agencyID ===
                            REACT_APP_LICONA_INSURANCE_GROUP_ID && (
                            <ToDoList
                              userInfo={userInfo}
                              loading={loading}
                              setLoading={setLoading}
                            />
                          )}
                          {/* TODO: Should we bring this back? */}
                          {/* <CoverageBarGraph
                          coverageCounts={coverageCounts}
                          setCoverageCounts={setCoverageCounts}
                        /> */}
                        </div>
                      </Grid>
                    </Grid>
                  </Container>
                </div>
              </section>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default Dashboard;
