import React, { useState } from 'react';
import { FormControl, TextField } from '@mui/material';

const SearchField = ({ searchValue, setSearchValue, searchType }) => {

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const s = searchType;
  const label = s === 'name' ? 'Driver Name' : s === 'license' ? 'Drivers License Number' : 'Policy Number'

  return (
    <div className='my-4'>
      <FormControl fullWidth>
        <TextField
        required
          id="search-input"
          label={label}
          variant="outlined"
          value={searchValue}
          onChange={handleSearchChange}
          fullWidth
        />
      </FormControl>
    </div>
  );
};

export default SearchField;
