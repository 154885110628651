import React from 'react';
import { t } from 'i18next';
import useGeneralState from '../../../Context/actions/useGeneralState';
import Popup from '../../generals/Popup';
import Breadcrumbs from '../../assets/Breadcrumbs';

const TruckModal = ({
  truck,
  isOpen,
  onClose,
  contentLabel,
  formData,
  policyID
}) => {
  const { controlToast } = useGeneralState();

  const policyDetailToShow = truck?.policiesDetails?.find(
    (policy) => policy?.id === policyID
  );

  const breadcrumbs = [{ label: "Power Unit" }, { label: "Details" }];

  return (
    <Popup
      isOpen={isOpen}
      onRequestClose={onClose}
      onClose={onClose}
      contentLabel={contentLabel}
      title="Power Unit Information"
    >
      <div className="modal-content">
        <Breadcrumbs items={breadcrumbs} />
        <p className="text-2xl font-semibold mb-3 mt-2">
          {t('Truck Information')}
        </p>
        <section>
          <table className="table">
            <tbody>
              <tr>
                <td>{t('VIN')}</td>
                <td>
                  <div className="flex items-center">
                    <button
                      onClick={() => {
                        navigator.clipboard.writeText(truck?.truckVIN);
                        controlToast(true, 'The VIN was copied to clipboard', 'success');
                      }}
                      className="copy-button"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-copy mr-1"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V2Zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H6ZM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1H2Z"
                        />
                      </svg>
                    </button>
                    {truck?.truckVIN}
                  </div>
                </td>
              </tr>
              {formData?.hasALCoverage && (
                <tr>
                  <td>{t('Rating')}:</td>
                  <td>{policyDetailToShow?.rating}</td>
                </tr>
              )}
              <tr>
                <td>{t('Year')}:</td>
                <td>{truck?.truckYear}</td>
              </tr>
              <tr>
                <td>{t('Make')}:</td>
                <td>{truck?.truckMake}</td>
              </tr>
              <tr>
                <td>{t('Model')}:</td>
                <td>{truck?.truckModel}</td>
              </tr>
              <tr>
                <td>{t('Actual Cash Value')}:</td>
                <td>{policyDetailToShow?.truckACV}</td>
              </tr>
              <tr>
                <td>{t('Radius')}:</td>
                <td>{policyDetailToShow?.truckRadius}</td>
              </tr>
              <tr>
                <td>{t('Class')}:</td>
                <td>{t(truck?.truckClass)}</td>
              </tr>
              <tr>
                <td>{t('Weight')}:</td>
                <td>{truck?.truckWeight}</td>
              </tr>
            </tbody>
          </table>
        </section>
      </div>
    </Popup>
  );
};

export default TruckModal;
