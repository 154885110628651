import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../../firebase";

const mapEventualities = async () => {
    const eventualitiesRef = collection(
        db,
        "loss-control",
        "tables",
        "eventualities"
      );
      const eventualitiesSnapshot = await getDocs(eventualitiesRef);
      const eventualitiesMap = new Map(
        eventualitiesSnapshot.docs.map((doc) => [doc.data().code, doc.data()])
      );
      return eventualitiesMap;
}

export default mapEventualities;