
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { db, storage } from "../../../../../firebase.js";
import { doc, updateDoc } from "firebase/firestore";

const uploadFile = (e, setProgressState, fieldName, policyID, formData, setFormData, setImgUrl, parentFolder) => {
    e.preventDefault();
    console.log("uploading file");
    const file = e.target[0]?.files[0];

    if (!file) return console.log("no file");

    const storageRef = ref(
      storage,
      `files/${parentFolder}/${policyID}/loss-runs/${file.name}`
    );
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgressState(progress);
      },
      (error) => {
        alert(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
          setImgUrl(downloadURL);
          setFormData({ ...formData, [fieldName]: downloadURL });
          const policyRef = doc(db, "policy-applications", policyID);
    
          await updateDoc(policyRef, {
            [fieldName]: downloadURL
          });
        });
      }
    );
  };

  export default uploadFile;