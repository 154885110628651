import React, { useState } from "react";
import handleEnter from "../../../utils/handleEnter";
import {
  convertToNestedString,
  handleDynamicQuestionnaireChange,
} from "../../../../utils/helpers";

export default function SelectInput(props) {
  const {
    setFormSaved,
    policyID,
    property,
    formData,
    setFormData,
    options,
    onChange,
    value,
    disabled = false
  } = props;

  const handleChange = async (e) => {
    try {
      const { value } = e.target;
      if (!onChange) {
        await handleDynamicQuestionnaireChange(
          policyID,
          property,
          value,
          formData,
          setFormData,
          setFormSaved
        );
      } else {
        onChange(e)
      }
    } catch (error) {
      throw error
    }
  };

  console.log('value', value)

  return (
    <div>
      {props.label ? <label className="d-block mt-1">{props.label}</label> : null}
      <select
        disabled={disabled}
        onChange={handleChange}
        onKeyDown={
          !onChange ? handleEnter : () => {}
        }
        className="mt-1 p-1 block w-full rounded-md border border-gray-300 shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        value={
          value ? value :
          convertToNestedString(property, formData)}
      >
        {options.map(option => {
            const { id, key, label } = option
            return (
              <option key={id} value={key}>{label}</option>
            )
        })}
      </select>
    </div>
  );
}
