import { format } from "date-fns";
import { Table, Thead, Tbody, Tr, Td } from "react-super-responsive-table";
import classNames from "classnames";
import useTranslations from "../../Context/actions/useTranslations";
import useSupportTicketsList from "../../hooks/support-ticket/useSupportTicketsList";
import styles from "../../assets/styles/components/support/supportTicketsList";
import TableSkeleton from "../generals/TableSkeleton";
import { supportTicketsStatus } from "../../utils/constants";
import SelectInput from "../assets/forms/DynamicPropertiesInputs/SelectInput";
import useGeneralState from "../../Context/actions/useGeneralState";
import { setSupportTicket } from "../../services/firebase-api/supportTicket";
import useUsersState from "../../Context/actions/useUsersState";

function NewSupportList() {
  const { t } = useTranslations();

  const {
    showCompanySelection,
    searchValue,
    searchAllProperties,
    currentSort,
    handleDateSort,
    handleAlphabetSort,
    currentItems,
    pageCount,
    currentPage,
    handlePageChange,
    doneGettingSupportTickets,
  } = useSupportTicketsList();

  const { controlToast } = useGeneralState()
  const { user } = useUsersState()

  const handleChange = async (e, id, status) => {
    e.preventDefault()
    try {
      await setSupportTicket({
        status
      }, id)
      controlToast(true, 'You changed your status succesfully', 'success')
    } catch (error) {
      controlToast(true, 'There was an error in the server', 'error')
      throw error
    }
  }

  return (
    <div className="card mb-3">
      <div className="card-body">
        {!doneGettingSupportTickets ? (
          <TableSkeleton rows={2} columns={7} />
        ) : (
          !showCompanySelection && (
            <section>
              <input
                value={searchValue}
                onChange={(e) => searchAllProperties(e)}
                placeholder={t("account.proposals.search")}
                className="w-full d-block p-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
                type="text"
              />
              <Table className="my-3 rounded-md">
                <Thead>
                  <Tr>
                    <Td
                      className={styles.statusColumn(currentSort === "status")}
                    >
                      <button
                        onClick={(e) => handleAlphabetSort(e, "status")}
                        className="focus:outline-none"
                      >
                        Status
                      </button>
                    </Td>
                    <Td className={styles.column(currentSort === "sender")}>
                      <button
                        onClick={(e) => handleAlphabetSort(e, "sender")}
                        className="focus:outline-none"
                      >
                        Sender
                      </button>
                    </Td>
                    <Td className={styles.column(currentSort === "senderID")}>
                      <button
                        onClick={(e) => handleAlphabetSort(e, "senderID")}
                        className="focus:outline-none"
                      >
                        Sender Id
                      </button>
                    </Td>
                    <Td className={styles.column(currentSort === "date")}>
                      <button onClick={(e) => handleDateSort(e, "date")}>
                        Date
                      </button>
                    </Td>
                    <Td className={styles.column(currentSort === "type")}>
                      <button
                        onClick={(e) => handleAlphabetSort(e, "type")}
                        className="focus:outline-none"
                      >
                        Type
                      </button>
                    </Td>
                    <Td className={styles.column(currentSort === "subject")}>
                      <button
                        onClick={(e) => handleAlphabetSort(e, "subject")}
                        className="focus:outline-none"
                      >
                        Subject
                      </button>
                    </Td>
                    <Td className={styles.column(currentSort === "message")}>
                      <button
                        onClick={(e) => handleAlphabetSort(e, "message")}
                        className="focus:outline-none"
                      >
                        Message
                      </button>
                    </Td>
                  </Tr>
                </Thead>
                <Tbody>
                  {currentItems.map((myData) => (
                    <Tr key={myData.id}>
                      <Td className="w-[120px]">
                        <SelectInput
                          value={myData.status}
                          disabled={user.role !== 'Admin' && user.role !== 'Underwriter'}
                          onChange={(e) => {
                            handleChange(e, myData?.id, e?.target?.value);
                          }}
                          options={supportTicketsStatus}
                        />
                      </Td>
                      <Td>{myData.sender}</Td>
                      <Td>{myData.senderID}</Td>
                      <Td>{format(myData.date, "MM/dd/yyyy")}</Td>
                      <Td>{myData.type}</Td>
                      <Td>{myData.subject}</Td>
                      <Td>{myData.message}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
              <div className={styles.paginationContainer}>
                {Array.from({ length: pageCount }, (_, index) => (
                  <button
                    key={index}
                    className={classNames(styles.notCurrentPage, {
                      [styles.currentPage]: index === currentPage,
                    })}
                    onClick={() => handlePageChange(index)}
                  >
                    {index + 1}
                  </button>
                ))}
              </div>
            </section>
          )
        )}
      </div>
    </div>
  );
}

export default NewSupportList;
