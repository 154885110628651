import { useEffect, useState } from "react";
import styles from "../../../../assets/styles/components/generals/forms/form";
import DatePicker from "react-datepicker";
import MultiUpload from "../../../assets/forms/DynamicPropertiesInputs/MultiUpload";
import SingleCheckbox from "../../../assets/forms/SingleCheckbox";

function Input(props) {
  const {
    input,
    handleChange,
    values,
    isSmallVersion
  } = props;
  const {
    label: labelInput,
    type,
    name: nameInput,
    options,
    minDate,
    required,
    disabled,
    storageFile,
    multiple = false,
    showInAPopup = false,
    dissapearWhenImgExists = false,
    showPastingOption = false,
  } = input;

  const initialFileObjects = values?.[nameInput] ? values?.[nameInput] : []
  const [fileObjects, setFileObjects] = useState(initialFileObjects)

  useEffect(() => {
    if (type === 'file') {
      const event = {
        target: {
          name: nameInput,
          value: fileObjects,
        },
      };
      handleChange(event)
    }
  }, [fileObjects])

  const generalChange = (e) => {
    handleChange(e);
  };

  return (
    <div className={styles.inputWrapper}>
      {type !== 'file' && type !== 'checkbox' ?
        <label htmlFor={nameInput} className={styles.inputLabel}>
          {labelInput}
        </label> : null
      }
      {type === 'just-text' ?
        <p>{values?.[nameInput]}</p> :
      type === 'checkbox' ?
        <SingleCheckbox
          label={labelInput}
          checked={values?.[nameInput] || false}
          onChange={(e) => {
            const { checked } = e.target
            const event = {
              target: {
                name: nameInput,
                value: checked,
              },
            };
            generalChange(event);
          }}
          disabled={disabled}
          required={required}
        /> :
      type === 'file' ?
        <MultiUpload
          label={labelInput}
          property={nameInput}
          formData={values?.[nameInput] ? values?.[nameInput] : []}
          setFormData={setFileObjects}
          storageFile={storageFile}
          multiple={multiple}
          changeNormal={true}
          required={required}
          disabled={disabled}
          showInAPopup={showInAPopup}
          fileInButton={true}
          dissapearWhenImgExists={dissapearWhenImgExists}
          showPastingOption={showPastingOption}
        /> :
      type === "date" ? (
        <DatePicker
          wrapperClassName="datePicker"
          required={required}
          name={nameInput}
          className={styles.input(isSmallVersion)}
          selected={values?.[nameInput]}
          minDate={minDate}
          onChange={(date) => {
            const event = {
              target: {
                name: nameInput,
                value: date,
              },
            };
            generalChange(event);
          }}
          disabled={disabled}
        />
      ) : type === "select" ? (
        <select
          required={required}
          onChange={generalChange}
          className={styles.input(isSmallVersion)}
          value={values?.[nameInput]}
          name={nameInput}
          disabled={disabled}
        >
          {options.map((option, optionIndex) => (
            <option key={optionIndex} value={option.key}>
              {option.label}
            </option>
          ))}
        </select>
      ) : (
        <input
          required={required}
          onChange={generalChange}
          className={styles.input(isSmallVersion)}
          value={values?.[nameInput]}
          type={type}
          name={nameInput}
          disabled={disabled}
        />
      )}
    </div>
  );
}

export default Input;
