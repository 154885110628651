import { Table, Thead, Tbody, Tr, Td } from "react-super-responsive-table";
import classNames from "classnames";
import useTranslations from "../../Context/actions/useTranslations";
import useLossRunsList from "../../hooks/loss-runs/useLossRunsList";
import styles from "../../assets/styles/components/lossruns/lossRunsList";
import { parsePolicyFields } from "../../utils/helpers";
import { lossRunsRequestsStatus } from "../../utils/constants";
import SelectInput from "../assets/forms/DynamicPropertiesInputs/SelectInput";
import { setLossRunRequest } from "../../services/firebase-api/lossRuns";
import useGeneralState from "../../Context/actions/useGeneralState";
import TableSkeleton from "../generals/TableSkeleton";

function LossRunsList() {
  const { t } = useTranslations();

  const {
    showCompanySelection,
    searchValue,
    searchAllProperties,
    currentSort,
    handleAlphabetSort,
    currentItems,
    pageCount,
    currentPage,
    handlePageChange,
    doneGettingLossRuns
  } = useLossRunsList()

  const { controlToast } = useGeneralState()

  const handleChange = async (e, id, status) => {
    e.preventDefault()
    try {
      await setLossRunRequest({
        status
      }, id)
      controlToast(true, 'You changed your status succesfully', 'success')
    } catch (error) {
      controlToast(true, 'There was an error in the server', 'error')
      throw error
    }
  }
  
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        {!doneGettingLossRuns ? <TableSkeleton
          rows={2}
          columns={7}
        /> :
        !showCompanySelection && (
          <section>
            <input
              value={searchValue}
              onChange={(e) => searchAllProperties(e)}
              placeholder={t("loss-runs.list.search")}
              className={styles.searchInput}
              type="text"
            />
            <Table className={styles.table}>
              <Thead>
                <Tr>
                  <Td className={`${styles.column(currentSort === "status")} w-[120px]`}>
                    <button
                      onClick={(e) => handleAlphabetSort(e, 'status')}
                      className="focus:outline-none"
                    >
                      Status
                    </button>
                  </Td>
                  <Td className={styles.column(currentSort === "namedInsured")}>
                    <button
                      onClick={(e) => handleAlphabetSort(e, 'namedInsured')}
                      className="focus:outline-none"
                    >
                      {t("loss-runs.list.named-insured")}
                    </button>
                  </Td>
                  <Td className={styles.column(currentSort === "policyFields")}>
                    <button
                      onClick={(e) => handleAlphabetSort(e, 'policyFields')}
                      className="focus:outline-none"
                    >
                      {t("loss-runs.list.policies-requested")}
                    </button>
                  </Td>
                  <Td className={styles.column(currentSort === "name")}>
                    <button
                      onClick={(e) => handleAlphabetSort(e, 'name')}
                      className="focus:outline-none"
                    >
                      {t("loss-runs.list.name")}
                    </button>
                  </Td>
                  <Td className={styles.column(currentSort === "company")}>
                    <button
                      onClick={(e) => handleAlphabetSort(e, 'company')}
                      className="focus:outline-none"
                    >
                      {t("loss-runs.list.company")}
                    </button>
                  </Td>
                  <Td className={styles.column(currentSort === "email")}>
                    <button
                      onClick={(e) => handleAlphabetSort(e, 'email')}
                      className="focus:outline-none"
                    >
                      {t("loss-runs.list.email")}
                    </button>
                  </Td>
                  <Td className={styles.column(currentSort === "comments")}>
                    <button
                      onClick={(e) => handleAlphabetSort(e, 'comments')}
                      className="focus:outline-none"
                    >
                      {t("loss-runs.list.comments")}
                    </button>
                  </Td>
                </Tr>
              </Thead>
              <Tbody>
                {currentItems.map(myData => {
                  return (
                    <Tr key={myData.id}>
                      <Td className='w-[120px]'>
                        <SelectInput
                          value={myData.status}
                          onChange={(e) => {
                            handleChange(
                              e,
                              myData?.id,
                              e?.target?.value
                            )
                          }}
                          options={lossRunsRequestsStatus}
                        />
                      </Td>
                      <Td>{myData.namedInsured}</Td>
                      <Td>{parsePolicyFields(myData.policyFields)}</Td>
                      <Td>{myData.name}</Td>
                      <Td>{myData.company}</Td>
                      <Td>{myData.email}</Td>
                      <Td>{myData.comments}</Td>
                    </Tr>
                  )
                })}
              </Tbody>
            </Table>
            <div className={styles.paginationContainer}>
              {Array.from({ length: pageCount }, (_, index) => (
                <button
                  key={index}
                  className={classNames(
                    styles.notCurrentPage,
                    {
                      [styles.currentPage]:
                        index === currentPage,
                    }
                  )}
                  onClick={() => handlePageChange(index)}
                >
                  {index + 1}
                </button>
              ))}
            </div>
          </section>
        )}
      </div>
    </div>
  );
}

export default LossRunsList;
