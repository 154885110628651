import { useMemo, useState } from "react";
import { getAuth } from "firebase/auth";
import ApiService from "../../services/southern-api/apiService";
import useGeneralState from "../../Context/actions/useGeneralState";

const useLossRuns = () => {
  const apiService = new ApiService("southern");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [comments, setComments] = useState("");
  const [status, setStatus] = useState("Submitted");
  const [namedInsured, setNamedInsured] = useState("");
  const [policyFields, setPolicyFields] = useState([""]);
  const [authorization, setAuthorization] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const { controlToast } = useGeneralState()

  const auth = getAuth();

  const submitRequest = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const lossRuns = {
        uid: auth?.currentUser?.uid,
        namedInsured,
        status,
        authorization,
        comments,
        name,
        company,
        email,
        phone,
        policyFields,
      };
      await apiService.sendLossRunsEmail(lossRuns);
      setLoading(false);
      setSubmitted(true);
      controlToast(true, 'You submit a loss run request successfully', 'success')
    } catch (error) {
      controlToast(true, 'There was a error in the service of the request', 'error')
      console.error(error);
      setLoading(false);
      setSubmitted(true);
    }
  };

  const handleChangePolicyField = (e, i) => {
    const values = [...policyFields];
    values[i] = e.target.value;
    setPolicyFields(values);
  };

  const handleAddPolicyField = (e) => {
    e.preventDefault();
    setPolicyFields([...policyFields, ""]);
  };

  const handleSubtractPolicyField = (e, i) => {
    e.preventDefault();
    if (policyFields.length === 1) return;
    const values = [...policyFields];
    values.splice(policyFields.length - 1, 1);
    setPolicyFields(values);
  };

  const enabled = useMemo(() => {
    const isEnabled = Boolean(
      phone &&
        company &&
        email &&
        name &&
        comments &&
        status &&
        namedInsured &&
        policyFields.length &&
        authorization
    );
    return isEnabled;
  }, [
    phone,
    company,
    email,
    name,
    comments,
    status,
    namedInsured,
    policyFields,
    authorization,
  ]);

  return {
    phone,
    setPhone,
    company,
    setCompany,
    email,
    setEmail,
    name,
    setName,
    comments,
    setComments,
    status,
    setStatus,
    namedInsured,
    setNamedInsured,
    policyFields,
    setPolicyFields,
    authorization,
    setAuthorization,
    submitted,
    setSubmitted,
    submitRequest,
    handleChangePolicyField,
    handleAddPolicyField,
    handleSubtractPolicyField,
    disabled: !enabled,
    loading
  };
};

export default useLossRuns;
