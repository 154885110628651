import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import { getPolicyGLData } from "../premiumCalculation/getPolicyData";
import rateAllUnits from "./rateAllUnits";
import updateTrucks from "./updateTrucks";
import { buildDocumentPath } from "../../PolicyLifecycleManager/Drivers/multipleToEndorsements";
import { individualToEndorsement } from "../../PolicyLifecycleManager/Drivers/individualToEndorsement.";

const ratePowerUnitGeneral = async (
  e,
  truckList,
  policyID,
  singleUnit,
  endoID,
  setSuccess,
  insuredInfo,
  userInfo
) => {
  e.preventDefault();
  const policyRef = doc(db, "policy-applications", policyID);
  const policySnap = await getDoc(policyRef);
  const formDataGL = await getPolicyGLData({
    ...policySnap.data(),
    id: policySnap.id,
  });
  const formData = {
    ...policySnap.data(),
    id: policySnap.id,
    hasGLCoverage: formDataGL?.hasGLCoverage || false,
    glPolicyNo: formDataGL?.glPolicyNo || "",
    glPremiums: formDataGL?.glPremiums || null,
  };

  const cleanTruckListFunction = (truckList) => {
    let cleanTruckList;
    if (Array.isArray(truckList)) {
      cleanTruckList = truckList.map((truck) => {
        const cleanedEntries = Object.entries(truck).filter(
          ([key, value]) => value !== undefined
        );
        return Object.fromEntries(cleanedEntries);
      });
    } else {
      cleanTruckList = truckList;
    }
    return cleanTruckList;
  };  

  const trucks = cleanTruckListFunction(truckList);

  const isEndorsement = Boolean(
    typeof formData === "undefined" || formData?.documentType === "Policy"
  );
  const isApplication = formData?.documentType === "Application";

  let updatedTruckList = [];
  try {
    if (isApplication) {
      await updateTrucks(trucks, formData);
    } else if (isEndorsement) {
      updatedTruckList = await rateAllUnits(
        e,
        policyID,
        formData,
        trucks,
        formData?.alPolicy?.adjustmentPercent || 0,
        isEndorsement,
        false,
        endoID,
        isEndorsement
      );
    }
    if (
      typeof formData === "undefined" ||
      formData?.documentType === "Policy"
    ) {
      if (endoID) {
        const finalTruckList = cleanTruckListFunction(updatedTruckList[1]);
        // // this can be deleted
        // const agencyID = formData.agencyID;
        // const insuredID = formData.insuredID;
        // getCurrentPolicyTotals(agencyID, insuredID, policyID);
        if (!singleUnit) {
          updateDoc(
            doc(db, "policy-applications", policyID, "endorsements", endoID),
            {
              data: finalTruckList.map((truck) => {
                const paths = buildDocumentPath(
                  "Adjust Radius",
                  truck.id,
                  formData
                );
                return paths;
              }),
            }
          );
        }
      } else {
        await individualToEndorsement(
          policyID,
          trucks?.[0]?.effectiveDate,
          "Adjust Radius",
          trucks[0],
          insuredInfo,
          formData,
          userInfo,
          null,
          null,
          null
        );
      }
    }
    if (setSuccess) setSuccess(true);
  } catch (error) {
    throw error;
  }
};

export default ratePowerUnitGeneral;