function findHighestActualCashValue(objectsArray) {
  if (!Array.isArray(objectsArray) || objectsArray.length === 0) {
    return null; // Return null for an empty or non-array input
  }

  // Use the reduce function to find the object with the highest cash value property
  const highestObject = objectsArray.reduce((highest, current) => {
    const highestValue = parseInt(highest.truckACV) || parseInt(highest.trailerACV) || 0;
    const currentValue = parseInt(current.truckACV) || parseInt(current.trailerACV) || 0;

    return highestValue >= currentValue ? highest : current;
  });

  return highestObject.truckACV || highestObject.trailerACV;
}

export default findHighestActualCashValue;
