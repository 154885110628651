import { useContext } from "react"
import { CREATE_COMPANY, SET_COMPANY } from "../types"
import { AppContext, initialState } from "../AppContext"
import { createCompanyRequest, setCompanyByIdRequest } from "../../services/firebase-api/companies"

const useCompaniesState = () => {

    const { state, dispatch } = useContext(AppContext)
    const { company } = state || initialState
    
    const createAgency = async (newData, pureStateManagement) => {        
        try {
            if (!pureStateManagement) {
                const companyResult = await createCompanyRequest(newData)
                dispatch({ type: CREATE_COMPANY, payload: companyResult })
                return companyResult
            } else {
                dispatch({ type: CREATE_COMPANY, payload: newData })
            }
        } catch (error) {
            throw error
        }
    }
    
    const setAgency = async (id, newData) => {
        try {
            await setCompanyByIdRequest(id, newData)
            dispatch({ type: SET_COMPANY, payload: newData })
        } catch (error) {
            throw error
        }
    }

    return {
        company,
        setAgency,
        createAgency
    }
}

export default useCompaniesState