import React from 'react'
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Paper } from "@mui/material";

export default function Header({note}) {
  return (
    <Grid item xs={12}>
            <Paper
              elevation={1}
              style={{
                padding: "12px",
                marginBottom: "2px",
                backgroundColor: "#072a48",
                color: "#FFFFFF",
              }}
            >
              <Typography variant="subtitle1" fontWeight="bold">
                {(note.role === "Underwriter" || note.role === "Admin") && (
                  <span className="bg-white rounded-full px-3 py-1 text-[#072a48] text-sm mr-2">
                    Underwriter
                  </span>
                )}
                {`Note by ${note.author} on ${note.createdOn
                  .toDate()
                  .toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}`}
              </Typography>
            </Paper>
          </Grid>
  )
}
