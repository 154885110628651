import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../../firebase";

const clearAdjustments = async (e, formData, setFormData, policyID) => {
    setFormData({ ...formData, selectedQuote: "" });
    updateDoc(doc(db, "policy-applications", policyID), {
      selectedQuote: "",
    });
  };

export default clearAdjustments;