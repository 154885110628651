import React, { useState } from 'react';
import { Card, CardContent, Typography, Grid, TextField } from '@mui/material';

const Training = ({ handleInputChange }) => {
  // Define questions
  const questions = [
    { id: 'orientationConductor', label: 'Who conducts driver orientation?', type: 'text' },
    { id: 'fullName', label: 'Full name:', type: 'text' },
    { id: 'phoneNumber', label: 'Phone number:', type: 'text' },
    { id: 'emailAddress', label: 'Email address:', type: 'email' },
    { id: 'orientationDuration', label: 'How long is the new hire orientation?', type: 'text' },
    { id: 'testType', label: 'On road test / off road test / certificate:', type: 'text' },
    { id: 'roadTestTrainer', label: 'Who is the driver trainer for road tests?', type: 'text' },
    { id: 'roadTrainingDuration', label: 'How long is an on-road training program?', type: 'text' },
    {
      id: 'recommendations',
      label: 'Recommendation: Implement a road test and have the driver sign off on having received the training. Also have a driver sign off that he understands all aspects of using the ELD systems. Pre-Trip and full inspection checklist.',
      type: 'textarea',
    },
    { id: 'soloDrivingSteps', label: 'In case of trainer and trainee aboard - What are the steps before a driver can drive alone?', type: 'text' },
  ];

  // State to manage responses
  const [responses, setResponses] = useState(
    questions.reduce((acc, { id }) => ({ ...acc, [id]: '' }), {}) // Initialize state dynamically based on questions
  );
  return (
    <Card sx={{ maxWidth: 800, margin: 'auto', padding: 2, marginBottom: 2 }}>
      <CardContent>
        <Typography variant="h5" component="div" gutterBottom>
          Training
        </Typography>
        <Grid container spacing={2}>
          {questions.map(({ id, label, type }) => (
            <Grid item xs={12} key={id}>
              <TextField
                fullWidth
                multiline={type === 'textarea'}
                rows={type === 'textarea' ? 4 : 1}
                variant="outlined"
                label={label}
                value={responses[id] || ''}
                onChange={(e) => handleInputChange(id, e.target.value)}
              />
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Training;
