import React, { useState } from "react";
import ExpandCollapseArrow from "../../generals/DesignElements/ExpandCollapseArrow";
import ApprovalButtons from "../../assets/forms/ApprovalButtons";
import updatePolicyField from "../../utils/updatePolicyField";

export default function ReferralMessage({
  className,
  policyID,
  userRole,
  reasons,
}) {
  const [show, setShow] = useState(true);
  const color = "red";
  return (
    <main
      className={`card mt-3 bg-${color}-100 text-${color}-700 ${
        className || ""
      }`}
    >
      <div className="card-header" onClick={(e) => setShow(!show)}>
        <h1 className={`text-lg font-bold text-${color}-700`}>
          <button>Account Must Be Referred</button>
          <ExpandCollapseArrow show={show} setShow={setShow} />
        </h1>
      </div>
      {show && (
        <div className="card-body">
          <p className="mb-2">
            This application must be referred for the following reasons:
          </p>
          <ul className="list-disc ml-6">
            {reasons.map((reason, i) => {
              return (
                <li key={i} className="mb-2">
                  Total premiums are over $500,000.
                </li>
              );
            })}
          </ul>
          {userRole === "Admin" ? (
            <ApprovalButtons
              onApprove={updatePolicyField(policyID, "referralApproved", true)}
              onDecline={updatePolicyField(policyID, "referralApproved", false)}
              titleAccept="Approve"
              titleDecline="Decline"
            />
          ) : (
            <p className="text-sm text-gray-500">
              Only an admin can approve or decline this referral.
            </p>
          )}
        </div>
      )}
    </main>
  );
}
