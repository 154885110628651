const cleanTruckList = (truckList) => {
  if (!Array.isArray(truckList)) return truckList;
  return truckList.map((truck) => {
    const cleanedTruck = {};
    for (const key in truck) {
      if (
        Object.prototype.hasOwnProperty.call(truck, key) &&
        truck[key] !== undefined
      ) {
        cleanedTruck[key] = truck[key];
      }
    }
    return cleanedTruck;
  });
};

export default cleanTruckList;
