import { Checkbox } from "@mui/material";

const SingleRealCheckbox = ({
  checked,
  onChange,
  name,
  disabled,
  required,
  label
}) => {
return (
  <div className="flex items-center my-2">
    <Checkbox
      type="checkbox"
      checked={checked}
      onChange={onChange}
      name={name}
      disabled={disabled}
      required={required}
      style={{
        fontSize: "20px",
        color: checked ? "#116582" : "#072a48"
      }}
      className="larger-checkbox"
      size="large"
    />
    {label ?
      <label className="ml-4" htmlFor={name}>
          {label}
      </label> : null
    }
  </div>
);
};

export default SingleRealCheckbox;