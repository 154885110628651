import React from 'react';
import { Button, TextField, Typography, Box, Container, Paper, Link } from '@mui/material';
import { Google as GoogleIcon } from '@mui/icons-material';
import { SIGN_UP_ROUTE } from "../../utils/routes";
import useTranslations from "../../Context/actions/useTranslations";
import useSignIn from "../../hooks/auth/useSignIn";

function LiconaSignin() {
  const {
    loginErrorMessage,
    handleSubmit,
    setEmail,
    setPassword,
    googleSignIn,
  } = useSignIn();
  const { t } = useTranslations();

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <Container maxWidth="sm">
        <Paper elevation={3} sx={{ padding: 4, textAlign: 'center' }}>
          <Typography variant="h4" sx={{ color: '#072a48' }} fontWeight="bold" gutterBottom>
            Licona Insurance Group
          </Typography>
          <Button
            href='/absence'
            variant="text"
            color="primary"
            fullWidth
            sx={{ mt: 1 }}
          >
            {t("Report Absence/Tardy")}
          </Button>
          {loginErrorMessage !== "" && (
            <Typography variant="body1" color="error" sx={{ backgroundColor: '#f8d7da', padding: 2, borderRadius: 1 }}>
              {loginErrorMessage}
            </Typography>
          )}
          <Button
            onClick={googleSignIn}
            variant="contained"
            color="secondary"
            fullWidth
            startIcon={<GoogleIcon />}
            sx={{ mt: 2 }}
          >
            {t("signin.sign-in-google")}
          </Button>
          <Typography variant="body1" color="textSecondary" sx={{ mt: 2, mb: 1 }}>
            {t("signin.new-user")}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Please contact your HR department or an administrator to get started.
          </Typography>
        </Paper>
      </Container>
    </Box>
  );
}

export default LiconaSignin;
