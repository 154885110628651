import { doc, setDoc } from "firebase/firestore";
import { db } from "../../firebase";

export const setProgressToZero = async (type) => {
  try {
    await setDoc(doc(db, "web-socket-simulator", `policy-${type}`), {
      message: "Initial",
      progress: 0,
    });
  } catch (error) {
    throw error;
  }
};
