import React from 'react'
import ExpandCollapseArrow from '../../generals/DesignElements/ExpandCollapseArrow';

const highlightHeader = (field) => {
  document.getElementById(field).style.backgroundColor = "#116582";
  document.getElementById(field).style.color = "white";
  document.getElementById(field).style.cursor = "pointer";
};

const unHighlightHeader = (field) => {
  document.getElementById(field).style.backgroundColor = "";
  document.getElementById(field).style.color = "";
  document.getElementById(field).style.cursor = "";
};

export default function CardHeaderHighlight(props) {
  return (
    <div
            className="card-header"
            onClick={(e) => {
              props.setter(!props.currentState);
            }}
            id={props.id}
            onMouseOver={(e) => {
              highlightHeader(props.id);
            }}
            onMouseOut={(e) => {
              unHighlightHeader(props.id);
            }}
          >
            {props.label}
            <ExpandCollapseArrow
          show={props.currentState}
          setShow={props.setter}
        />
          </div>
  )
}
