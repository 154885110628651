import React, { useState } from "react";
import { Container, Typography, Button } from "@mui/material";

export default function Help() {
  const [sections, setSections] = useState(["Applications & Policies"]);
  const oldArticles = ["Add A Driver", "Delete A Driver", "Exclude A Driver"];
  const articles = [
    {
      header: "Add A Driver",
      introduction: "Learn how to add a driver to your policy.",
      sections: [
        {
          paragraphs: [
            {
              type: "list",
              items: [
                "Go to the 'Drivers' section of your policy.",
                "Click the 'Add Driver' button.",
                "Fill in the required information.",
                "Click 'Save'.",
              ]
            }
          ],
        },
      ],
    },
  ];
  
  const addSection = () => {
    const newSectionName = prompt("Enter the name of the new section:");
    if (newSectionName) {
      setSections([...sections, newSectionName]);
    }
  };

  const addArticle = (section) => {
    const newArticleName = prompt(
      `Enter the name of the new article for ${section}:`
    );
    if (newArticleName) {
      // In a real app, you'd likely want to store articles in a structured way
      alert(`Article "${newArticleName}" added to section "${section}".`);
    }
  };

  return (
    <Container>
      <Typography variant="h5" align="center" gutterBottom>
        Welcome to the Help Wiki!
      </Typography>
      <Typography variant="body1" align="center">
        Start exploring the topics and find answers to your questions.
      </Typography>

      {sections.map((section, index) => (
        <div key={index}>
          <Typography variant="h6" gutterBottom>
            {section}
          </Typography>
          {articles.map((article, index) => (
            <div key={index}>
              <Typography variant="body1">{article.header}</Typography>
              <Typography variant="body1">{article.introduction}</Typography>
              {article.sections.map((section, index) => (
                <div key={index}>
                  <Typography variant="body1">{section.header}</Typography>
                  <Typography variant="body1">
                    {section.introduction}
                  </Typography>
                  {section.paragraphs.map((paragraph, index) => (
                    <>
                  {paragraph.type === "image" ? (
                      <img src={paragraph.url} alt="article" />
                    ) : paragraph.type === "list" ? (
                      <ul>
                        {paragraph.items.map((item, index) => (
                          <li key={index}>{item}</li>
                        ))}
                      </ul>
                    ) : (
                      paragraph.type ===
                      "text"(
                        <Typography key={index} variant="body1">
                          {paragraph.text}
                        </Typography>
                      )
                    )
                  }</>
                  ))}
                </div>
              ))}
            </div>
          ))}
          <Button variant="outlined" onClick={() => addArticle(section)}>
            Add Article
          </Button>
        </div>
      ))}

      <Button variant="contained" onClick={addSection}>
        Add Section
      </Button>
    </Container>
  );
}
