import { useEffect, useState } from 'react';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import useTranslations from "../../../../../Context/actions/useTranslations";
import { db } from '../../../../../firebase';
import { numberWithCommas } from '../../../../../utils/helpers';

export default function BusinessWritten({ userInfo }) {
  const { t } = useTranslations();
  const [grandTotal, setGrandTotal] = useState(0);
  const [totalAL, setTotalAL] = useState(0);
  const [totalGL, setTotalGL] = useState(0);
  const [currentAgencyID, setCurrentAgencyID] = useState(userInfo.agencyID); // Set currentAgencyID as needed

  useEffect(() => {
    if (currentAgencyID) {
      const applicationsCollection = collection(db, "policy-applications");
      const q = query(
        applicationsCollection,
        where("agencyID", "==", currentAgencyID)
      );

      const unsubscribe = onSnapshot(q, (snapshot) => {
        let alTotal = 0;
        let glTotal = 0;

        snapshot.forEach((doc) => {
          const data = doc.data();
          if (data.alPremiums && data.alPremiums.totalPremium) {
            alTotal += parseInt(data.alPremiums.totalPremium);
          }
          if (data.glPremiums && data.glPremiums.totalPremium) {
            glTotal += parseInt(data.glPremiums.totalPremium);
          }
        });
        setTotalAL(alTotal);
        setTotalGL(glTotal);
        setGrandTotal(alTotal + glTotal)
      });

      return () => unsubscribe();
    }
  }, [currentAgencyID]);
  return (
    <div className="card mb-2 shadow">
      <div className="card-header bg-[#072a48]">
        <h2 className="font-medium text-white">
          {t("account.agent-at-a-glance.business-written")}
        </h2>
      </div>
      <div className="card-body py-2">
        <h1 className="text-center">
          <span className="text-2xl mr-2">${numberWithCommas((totalAL || 0).toFixed(2))}</span>
          {t("account.agent-at-a-glance.written")}
        </h1>
      </div>
      <div className="card-footer">
      <h4 className="text-[#8B0000] font-medium">
          ${numberWithCommas((totalAL || 0).toFixed(2))} AL
          <span className="float-right text-green-900">
            ${numberWithCommas((totalGL || 0).toFixed(2))} GL
          </span>
        </h4>
      </div>
    </div>
  );
}
