import React, { useState } from 'react';
import { Card, CardContent, Typography, Grid, TextField } from '@mui/material';

const ClearingHouseRegistration = ({ handleInputChange }) => {
    // Define questions
    const questions = [
      { id: 'registrationComplete', label: 'Complete registration?', type: 'text' },
      { id: 'dataUpdater', label: 'Who updates the data?', type: 'text' },
      { id: 'updaterFullName', label: 'Full name:', type: 'text' },
      { id: 'updaterPhoneNumber', label: 'Phone number:', type: 'text' },
      { id: 'updaterEmailAddress', label: 'Email address:', type: 'email' },
      {
        id: 'sapMroFollowUps',
        label: 'Substance Abuse Program (SAP), Medical Review Office (MRO) and follow-ups.',
        type: 'textarea',
      },
      {
        id: 'driverTestsPositive',
        label: 'Do you know what to do or what happens when a driver tests positive?',
        type: 'textarea',
      },
    ];
  
    // State to manage responses
    const [responses, setResponses] = useState(
      questions.reduce((acc, { id }) => ({ ...acc, [id]: '' }), {}) // Initialize state dynamically based on questions
    );

  return (
    <Card sx={{ maxWidth: 800, margin: 'auto', padding: 2, marginBottom: 2 }}>
      <CardContent>
        <Typography variant="h5" component="div" gutterBottom>
          ClearingHouseRegistration
        </Typography>
        <Grid container spacing={2}>
          {questions.map(({ id, label, type }) => (
            <Grid item xs={12} key={id}>
              <TextField
                fullWidth
                multiline={type === 'textarea'}
                rows={type === 'textarea' ? 4 : 1}
                variant="outlined"
                label={label}
                value={responses[id] || ''}
                onChange={(e) => handleInputChange(id, e.target.value)}
              />
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ClearingHouseRegistration;

