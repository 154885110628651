import React, { useEffect, useState } from "react";
import avatar from "../../../assets/images/default-avatar.png";
import useTranslations from "../../../Context/actions/useTranslations";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../../firebase";
import { USER_INFO_STATUS, USER_INFO_STATUS_ES } from "../../../utils/constants";

export default function UserInfo({
  userPhoto,
  userInfo
}) {
  const { t, language } = useTranslations()
  const {
    first,
    last,
    title,
    company,
    status,
    tier,
    role,
    email,
    profilePhoto,
    agencyID
  } = userInfo

  const [agencyData, setAgencyData] = useState(null);

  useEffect(() => {
    const agencyDocRef = doc(db, "agencies", agencyID);
    const unsubscribe = onSnapshot(agencyDocRef, (doc) => {
      if (doc.exists()) {
        setAgencyData(doc.data());
      } else {
        console.log("No such document!");
      }
    });
    return () => unsubscribe();
  }, [agencyID]);

  const {
    APPOINTED_STATUS,
    UNAPPOINTED_STATUS
  } = USER_INFO_STATUS

  const {
    APPOINTED_STATUS: APPOINTED_STATUS_ES,
    UNAPPOINTED_STATUS: UNAPPOINTED_STATUS_ES
  } = USER_INFO_STATUS_ES

  const handleStatus = (status) => {
    if (language === "en") return status
    switch (status) {
      case APPOINTED_STATUS:
        return APPOINTED_STATUS_ES
      case UNAPPOINTED_STATUS:
        return UNAPPOINTED_STATUS_ES
      default:
        return status
    }
  }

  return (
    <div className="card max-w-[700px] mx-auto mb-4 shadow-lg py-3 mt-1 min-h-[380px] flex flex-col items-center justify-center bg-white rounded-md p-6">
      <img
        src={
          profilePhoto ? profilePhoto :
          userPhoto ? userPhoto : avatar}
        className="max-h-[150px] mx-auto rounded-full mb-4"
        alt="User Avatar"
      />
      <h3 className="text-2xl font-bold text-[#072a48] mb-2">
        {first} {last}
      </h3>
      <h4 className="text-lg font-medium mb-2">
        {title}
      </h4>
      <h4 className="text-lg font-medium mb-2">
        {
          agencyData ?
          agencyData.name :
          company
        }
      </h4>
      {role === "Agent" && (
        <h4 className="text-lg font-medium mb-2">
          {handleStatus(status)}
          {tier && <> - Tier {tier}</>}
        </h4>
      )}
      <h4 className="text-lg font-medium mb-2">
        {email}
      </h4>
      <a href="/user-details" className="text-[#072a48] hover:underline">
        {t('account.edit')}
      </a>
    </div>
  );
}
