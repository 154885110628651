function isAlphaNumeric(str) {
  if (/^[a-zA-Z0-9]+$/.test(str) === false) {
    console.log('has spec chars')
    return false;
  } else if (/[a-zA-Z]/g.test(str) === false) {
    console.log('no letters')
    return false;
  } else if (/\d/.test(str) === false) {
    console.log('no numbers')
    return false;
  } else {
    return true;
  }
}

export default isAlphaNumeric;
