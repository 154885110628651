import { useContext } from "react"
import { AppContext, initialState } from "../AppContext"
import { SET_FORM_DATA, SET_FORM_DATA_GL } from "../types"

const useFormDataState = () => {
    const { state, dispatch } = useContext(AppContext)
    const { formData, formDataGL } = state || initialState

    const setFormDataState = result => {
        dispatch({ type: SET_FORM_DATA, payload: result })
    }

    const setFormDataGLState = result => {
        dispatch({ type: SET_FORM_DATA_GL, payload: result })
    }

    return {
        formData,
        formDataGL,
        setFormDataState,
        setFormDataGLState,
    }
}

export default useFormDataState;