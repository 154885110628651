import updatePolicyField from "../../../utils/updatePolicyField";

const handleAddCommodity = (formData, policyID, setFormData) => {
  const i = formData.commodityFields.length;
  const lastID = formData.commodityFields[i - 1].id;
  updatePolicyField(
    policyID,
    "commodityFields",
    [
      ...formData.commodityFields,
      { id: lastID + 1, commodityDescription: "", commodityLoad: "0" },
    ],
    formData,
    setFormData
  );
};

export default handleAddCommodity;
