import { useContext } from "react"
import {
    SET_CHANGE_MANAGING_ALLOW_APPROVAL_RED_FLAG,
    SET_MANAGING_ALLOW_APPROVAL_RED_FLAG
} from "../types"
import { AppContext, initialState } from "../AppContext"

const useManagingState = () => {

    const { state, dispatch } = useContext(AppContext)
    const { managing } = state || initialState

    const redFlag = managing?.redFlag || {
        allowApprovalRedFlags: false
    }
    const allowApprovalRedFlags = redFlag?.allowApprovalRedFlags || false

    const setAllowApprovalRedFlags = async (on) => {
        try {
            dispatch({ type: SET_MANAGING_ALLOW_APPROVAL_RED_FLAG, payload: on })
        } catch (error) {
            throw error
        }
    }

    const setChangeAllowApprovalRedFlags = async () => {
        try {
            dispatch({ type: SET_CHANGE_MANAGING_ALLOW_APPROVAL_RED_FLAG })
        } catch (error) {
            throw error
        }
    }

    return {
        managing,
        allowApprovalRedFlags,
        setAllowApprovalRedFlags,
        setChangeAllowApprovalRedFlags
    }
}

export default useManagingState