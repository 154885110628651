import React from "react";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase";

export default function UserListCheckbox(props) {
  const updateUserIsAgent = async (userData) => {
    try {
      const userRef = doc(db, "users", userData.id);
      await updateDoc(userRef, {
        [props.name]: !Boolean(userData?.[props.name]),
      });
      console.log("Permissions and endorsements updated successfully!");
    } catch (error) {
      console.error("Error updating permissions and endorsements: ", error);
    }
  };
  return (
    <div className="flex items-center my-2">
      <input
        disabled={props?.disabled}
        id={props?.field}
        name={props.name}
        type="checkbox"
        className="large-checkbox mx-auto"
        checked={props?.userData?.[props.name]}
        onChange={(e) => {
          updateUserIsAgent(props.userData);
        }}
      />
    </div>
  );
}
