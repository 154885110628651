const Container = ({ fluid, as: Component = "div", className = "", children }) => {
  let classes = "";

  if (fluid === true) {
    classes = "max-w-full";
  } else if (typeof fluid === "string") {
    classes = `max-w-screen-${fluid}`;
  } else {
    classes = "max-w-screen-xxl mx-auto px-4 sm:px-6 lg:px-8";
  }

  classes += ` ${className}`;

  return <Component className={classes.trim()}>{children}</Component>;
};

export default Container;
