import React from "react";
import AddUserForm from "./AddUserForm";
import useTranslations from "../../../Context/actions/useTranslations";

const CreateUserPanel = (props) => {
  const userInfo = props.userInfo;
  const setShowCreateUserPanel = props.setShowCreateUserPanel;
  const { t } = useTranslations()
  return (
    <div className="container mx-auto my-8">
      <div className="max-w-xl mx-auto">
        <div className="bg-white shadow-md rounded-md overflow-hidden">
          <div className="bg-[#072a48] text-white p-4">{t('agent.create-new-users')}</div>
          <div className="p-4">
            <p className="mb-4">
            {t('agent.outside-organization')}
            </p>
            <div className="flex flex-col space-y-4">
              <div className="flex flex-col space-y-2">
                <AddUserForm
                  setShowCreateUserPanel={setShowCreateUserPanel}
                  userInfo={userInfo}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateUserPanel;
