import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../../firebase";

const getQuoteHistory = async (policyID, setQuoteHistory, setGotQuoteHistory) => {
    const querySnapshot = await getDocs(
      collection(db, "policy-applications", policyID, "quotes")
    );
    let values = [];
    querySnapshot.forEach((doc) => {
      values.push({ ...doc.data(), id: doc.id });
    });
    setQuoteHistory(values);
    setGotQuoteHistory(true);
  };

export default getQuoteHistory;