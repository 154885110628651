import axios from "axios";

const sendAbsenceEmail = async (
  email,
  date,
  type,
  reason,
  hasMedicalExcuse,
  comments,
  name
) => {
  axios
    .post(
      "https://api.southernstarmga.com/send-email/absence-notification",
      {
        to: "tracker@liconainsurance.com",
        cc: "",
        email,
        date,
        type,
        reason,
        hasMedicalExcuse,
        comments,
        name
      }
    )
    .then(function (response) {
      // handle success
      console.log(response);
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })
    .finally(function () {
      // always executed
    });
};

export default sendAbsenceEmail;
