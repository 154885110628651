import React, { useRef, useState } from "react";
import handleUploadExcelToFirestore from "../utils/handleUploadExcelToFirestore";
import CircularProgress from '@mui/material/CircularProgress';

export default function UploadBaseViolations() {
  const fileInputRef = useRef(null);

  const [ loading, setLoading ] = useState(false)

  const handleExcelUpload = async (e) => {
    e.preventDefault()
    try {
      setLoading(true)
      await handleUploadExcelToFirestore(
        e, 1, [
          { classification: 'formula1' },
          { category: 'formula2' },
          { caseByCase: 'formula3' }
        ],
        {
          'categoría': 'category',
          'clasificación': 'classification'
        },
        'loss-control',
        'driverData',
        'fmcsaViolations'
      )
      await handleUploadExcelToFirestore(
        e, 2, [
        { nationality: 'formula1' },
        { totalMajorViolations: 'formula2' },
        { totalMinorViolations: 'formula3' },
        { claimsCount: 'formula4' },
        { warningDatos: 'formula5' },
        { ageStatus: 'formula6' },
        { expStatus: 'formula7' },
        { majorViolationsStatus: 'formula8' },
        { minorViolationsStatus: 'formula9' },
        { crashesStatus: 'formula10' },
        { caseByCaseReview: 'formula11' },
        { trainingStatus: 'formula12' },
        { scoreTotal: 'formula13' }
      ], {},
        'loss-control',
        'driverData',
        'fmcsaDriverFitness'
      )
      await handleUploadExcelToFirestore(
          e, 3, [
          { scoreTotal: 'formula1' },
          { trainingStatus: 'formula2' },
          { claimsCount: 'formula3' },
          { claimsAmount: 'formula4' },
          { age: 'formula5' },
          { exp: 'formula6' },
          { violationsMajor: 'formula7' },
          { violationsMinor: 'formula8' },
          { violationsOutOfDrivingRisk: 'formula9' },
          { violationsNotInclude: 'formula10' },
          { violationsCaseByCase: 'formula11' },
          { scoreClaimsCount: 'formula12' },
          { scoreClaimsAmount: 'formula13' },
          { scoreAge: 'formula14' },
          { scoreExp: 'formula15' },
          { scoreViolationsMajor: 'formula16' },
          { scoreViolationsMinor: 'formula17' },
          { scoreViolationsOutOfDrivingRisk: 'formula18' },
          { scoreViolationsNotInclude: 'formula19' }
        ], {
          'driver': 'fullName',
          'result': 'trainingStatus',
          'claimscount': 'claimsCount'
        },
        'loss-control',
        'driverData',
        'driverProfile'
      )
      fileInputRef.current.value = "";
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="my-4">
      <form className="sm:flex sm:flex-wrap sm:p-2 sm:box-border md:block" onSubmit={handleExcelUpload}>
        <input ref={fileInputRef} type="file" accept=".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
        <div className="max-w-[200px]">
          <button disabled={loading} className="d-block m-3 p-2 rounded w-full bg-[#072a48] text-white hover:bg-sky-900 hover:text-white font-[600]">
            {loading ? <CircularProgress size={24} color="inherit" /> : 'Upload your excel file'}
          </button>
        </div>
      </form>
    </div>
  );
}
