import React, { useState, useEffect } from "react";
import { Typography, Box, IconButton } from "@mui/material";
import Sections from "./sections";
import { auth, db } from "../../../firebase";
import AddInsured from "../../PolicyLifecycleManager/CreateNewApp/SelectInsured.jsx";
import { addDoc, collection, doc, getDoc, getDocs, runTransaction, setDoc, updateDoc } from "firebase/firestore";
import ReportHistory from "./ReportHistory/index.jsx";
import AddIcon from '@mui/icons-material/Add';

const LossControlReport = ({ userInfo }) => {
  const [showLossControlReport, setShowLossControlReport] = useState(false);
  const [showCreateUserPanel, setShowCreateUserPanel] = useState(false);
  const [showInsuredSelection, setShowInsuredSelection] = useState(false);
  const [reportID, setReportID] = useState("");

  const createNewLossControlReport = async (data) => {
    try {
      await runTransaction(db, async (transaction) => {
        // Get the unique number document
        const numberDocRef = doc(db, "unique-numbers", "loss-control-reports");
        const numberDocSnap = await transaction.get(numberDocRef);
        const numberDocData = numberDocSnap.data();
  
        // Increment the lastUsed number
        const lossControlNumber = numberDocData.lastUsed + 1;
        transaction.update(numberDocRef, { lastUsed: lossControlNumber });
  
        // Create the new loss control report
        const collectionRef = collection(db, "loss-control-reports");
        const docRef = await addDoc(collectionRef, {
          creationData: data,
          namedInsured: data.namedInsured,
          lossControlNumber,
        });
        
        // Set the report ID and show the report
        setReportID(docRef.id);
        setShowLossControlReport(true);
      });
    } catch (error) {
      console.error("Transaction failed: ", error);
    }
  };

  const handleInputChange = async (key, value) => {
    try {
      const docRef = doc(db, "loss-control-reports", reportID);
      await updateDoc(docRef, { [key]: value });
    } catch (error) {
      console.error("Error updating document:", error);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission logic here
    console.log("Form submitted");
  };

  return (
    <div className="my-3">
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Typography variant="h5" align="left">
        Loss Control Report
      </Typography>
      {!showInsuredSelection && !showLossControlReport && (
      <IconButton onClick={e => setShowInsuredSelection(true)} sx={{ borderRadius: '50%', backgroundColor: 'primary.main', color: 'white' }}>
        <AddIcon />
      </IconButton>
      )}
    </Box>  
    <Box>
      {!showInsuredSelection && !showLossControlReport && (
        <ReportHistory
          setReportID={setReportID}
          setShowLossControlReport={setShowLossControlReport}
        />
      )}  
      {showInsuredSelection && (
        <>
          <AddInsured
            customText={
              "Please select an insured to create a loss control report for."
            }
            customFunction={async (e) => {
              console.log(e.target.value);
              const originalValue = e.target.value;
              // split into two values at the first hyphen
              const splitValue = originalValue.split("-");
              const insuredID = splitValue[0];
              const namedInsured = splitValue[1];
              const data = {
                insuredID,
                namedInsured,
                agencyID: userInfo.agencyID,
                createdByUserID: auth.currentUser.uid,
                createdByUser: auth.currentUser.displayName,
                createdOn: new Date(),
                role: "Client",
              };
              await createNewLossControlReport(data);
            }}
            setShowCreatePolicyPanel={setShowCreateUserPanel}
            setCustomShow={setShowInsuredSelection}
            useIDAsValue={true}
            agencyID={userInfo.agencyID}
          />
        </>
      )}
      {showLossControlReport && (
        <>
          <Sections
            handleInputChange={handleInputChange}
            handleSubmit={handleSubmit}
            reportID={reportID}
          />
        </>
      )}
      </Box>
    </div>
  );
};

export default LossControlReport;
