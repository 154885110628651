import BlueButton from "../Components/assets/forms/BlueButton";
import {
    Dangerous as DangerousIcon,
    ArrowBack as ArrowBackIcon,
} from '@mui/icons-material';
import {
  ACCOUNT_ROUTE
} from "../utils/routes";
import { useNavigate } from "react-router-dom";


const NotFound = () => {
  const navigate = useNavigate()

  const handleNavigate = (e) => {
    e.preventDefault();
    navigate(ACCOUNT_ROUTE);
  }

    return (
        <div className="flex w-full justify-center items-center h-[75vh] flex-col">
            <DangerousIcon sx={{ color: 'red', width: 100, height: 100, margin: '0 0 8px' }}  />
            <p className="mb-4" style={{ fontSize: 24 }}>Not Found</p>
            <BlueButton
                onClickFunction={handleNavigate}
                icon={<ArrowBackIcon />}
                text="Go Back To Dashboard"
            />
        </div>
    )
  
};

export default NotFound;
