import { useEffect, useState } from "react";
import {
  activeFilters,
  manageEndorsementOptions,
} from "../../utils/searchFilters";
import useEndorsementsState from "../../Context/actions/useEndorsementsState";
import {
  collection,
  onSnapshot,
  query,
  where,
  limit,
  getDocs,
  startAfter,
} from "firebase/firestore";
import { db } from "../../firebase";
import usePaginationsState from "../../Context/actions/usePaginationsState";
import { setupPagination } from "../../utils/helpers";

const useEndoManager = (
  policyID,
  currentEndoFilters,
  setEndorsements,
  setSkeletonEndoTableLoader
) => {
  const { setEndorsements: setEndorsementsContext } = useEndorsementsState();
  const filters = activeFilters(manageEndorsementOptions, currentEndoFilters);
  const { paginations, setPaginations } = usePaginationsState();
  const [lastVisibleDocs, setLastVisibleDocs] = useState([]);
  const [uniqueDocs, setUniqueDocs] = useState(new Set());

  useEffect(() => {
    setSkeletonEndoTableLoader(true);
    const endorsementsRef = collection(
      db,
      "policy-applications",
      policyID,
      "endorsements"
    );

    const buildQuery1 = () => {
      let q = query(endorsementsRef);
      if (filters.includes("Exception Request")) {
        q = query(q, where("documentType", "==", "Exception Request"));
      }


      const itemsPerPage = paginations?.endoManagerPagination?.itemsPerPage || 10;
      q = query(q, limit(itemsPerPage));
      const currentPage = paginations?.endoManagerPagination?.currentPage || 0;
      if (currentPage > 0 && lastVisibleDocs[currentPage - 1]) {
        q = query(q, startAfter(lastVisibleDocs[currentPage - 1]));
      }
      

      return q;
    };

    const buildQuery2 = () => {
      let q = query(endorsementsRef);
      const otherFilters = filters.filter(
        (filter) => filter !== "Exception Request"
      );
      if (otherFilters.length > 0) {
        q = query(q, where("status", "in", otherFilters));
      }
      const itemsPerPage = paginations?.endoManagerPagination?.itemsPerPage || 10;
      q = query(q, limit(itemsPerPage));

      const currentPage = paginations?.endoManagerPagination?.currentPage || 0;

      if (currentPage > 0 && lastVisibleDocs[currentPage - 1]) {
        q = query(q, startAfter(lastVisibleDocs[currentPage - 1]));
      }

      return q;
    };

    let allResults = [];

    const endoQuery1 = buildQuery1();
    const unsubscribe1 = onSnapshot(endoQuery1, (querySnapshot) => {
      const values = [];
      querySnapshot.forEach((doc) => {
        values.push({ ...doc.data(), id: doc.id });
      });
      if (filters.includes("Exception Request")) {
        allResults = [...allResults, ...values];
        setEndorsements(allResults);
        setEndorsementsContext(allResults);
        setLastVisibleDocs((prev) => [
          ...prev,
          querySnapshot.docs[querySnapshot.docs.length - 1],
        ]);
      }
      setSkeletonEndoTableLoader(false);
    });

    const endoQuery2 = buildQuery2();
    const unsubscribe2 = onSnapshot(endoQuery2, async (querySnapshot) => {
      const values = [];
      querySnapshot.forEach((doc) => {
        values.push({ ...doc.data(), id: doc.id });
      });
      if (values.length > 0) {
        const allResultsToFilterUniqueValues = [...allResults, ...values];
        allResults = Array.from(
          new Set(allResultsToFilterUniqueValues.map((a) => a.id))
        ).map((id) => {
          return allResultsToFilterUniqueValues.find((a) => a.id === id);
        });
        
        // Guardar solo documentos únicos
        allResults.forEach((doc) => uniqueDocs.add(doc.id));
        setUniqueDocs(uniqueDocs);
        
        setEndorsements(allResults);
        setEndorsementsContext(allResults);
        setLastVisibleDocs((prev) => [
          ...prev,
          querySnapshot.docs[querySnapshot.docs.length - 1],
        ]);
      }
      const totalCount = await calculateTotalCount(endorsementsRef, filters);
      setupPagination(
        totalCount,
        paginations?.endoManagerPagination?.currentPage,
        paginations,
        setPaginations,
        "endoManager"
      );
      setSkeletonEndoTableLoader(false);
    });

    return () => {
      unsubscribe1();
      unsubscribe2();
    };
  }, [currentEndoFilters, paginations?.endoManagerPagination?.currentPage]);

  const calculateTotalCount = async (endorsementsRef, filters) => {
    let totalCountQuery = query(endorsementsRef);
    if (filters.includes("Exception Request")) {
      totalCountQuery = query(
        totalCountQuery,
        where("documentType", "==", "Exception Request")
      );
    }

    const otherFilters = filters.filter(
      (filter) => filter !== "Exception Request"
    );
    if (otherFilters.length > 0) {
      totalCountQuery = query(
        totalCountQuery,
        where("status", "in", otherFilters)
      );
    }

    const totalCountSnapshot = await getDocs(totalCountQuery);
    return totalCountSnapshot.size;
  };

  return { lastVisibleDocs, uniqueDocs };
};

export default useEndoManager;