import React, { useState } from "react";
import Breadcrumbs from "../../Components/assets/Breadcrumbs";
import {
  ACCOUNT_ROUTE,
  AGENT_MANAGE_NOTIFICATIONS_ROUTE,
} from "../../utils/routes";
import NotificationManager from "../../Components/NotificationManager";

export default function ManageNotifications({ userInfo }) {
  const breadcrumbs = [
    { label: "Home", link: ACCOUNT_ROUTE },
    { label: "Notifications", link: AGENT_MANAGE_NOTIFICATIONS_ROUTE },
  ];

  const [value, setValue] = useState("");

  const userIsOwner = userInfo.companyOwner;
  const hasPermissionToView = userInfo?.notificationPermissions || userIsOwner || false;

  return (
    <main>
      <div className="my-4 max-w-[1000px] mx-auto bg-white rounded-md shadow-md p-6">
        <Breadcrumbs items={breadcrumbs} />
        <div className="flex flex-col md:flex-row md:items-center md:justify-between mt-1">
          <span className="text-3xl font-semibold mb-4 md:mb-0">
            Manage Notifications
          </span>
        </div>
        <p className="mb-4">
          Here you may adjust notifications, premium information, and more.
        </p>
        {!hasPermissionToView ? <p>You do not have permission to view users. Please contact an administrator.</p> : (
        <fieldset>
        <p className="mb-4">Choose a notification type to get started.</p>
        <select
          onChange={(e) => {
            setValue(e.target.value);
          }}
          className="p-2 w-full border rounded"
        >
          <option value="">Make A Selection</option>
          <option value="System">System Notifications</option>
          <option value="Policies">Policy Notification</option>
          <option value="Endorsements">Endorsement Notifications</option>
          <option value="Underwriting Alerts (Drivers & Units)">
            Underwriting Alerts (Drivers & Units) Notifications
          </option>
        </select>
        {value === "Policies" && (
          <NotificationManager
            userInfo={userInfo}
            notificationType={"Policy"}
          />
        )}
        {value === "Endorsements" && (
          <NotificationManager
            userInfo={userInfo}
            notificationType={"Endorsement"}
          />
        )}
        {value === "System" && (
          <NotificationManager
            userInfo={userInfo}
            notificationType={"System"}
          />
        )}
        {value === "Underwriting Alerts (Drivers & Units)" && (
          <NotificationManager
            userInfo={userInfo}
            notificationType={"Underwriting Alerts (Drivers & Units)"}
          />
        )}
        </fieldset>
        )}
      </div>
    </main>
  );
}
