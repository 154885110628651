// Import necessary modules from firebase
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { db } from "../../../firebase";
import { buildShowingInfoJustActiveOnes } from "../Drivers/setPolicyInfo";

const calculateSubcollectionTotalTruck = async (policyID, subcollectionName) => {
  const policyRef = doc(db, "policy-applications", policyID);
  const policyDocDocSnapshot = await getDoc(policyRef);
  const policyData = {
    id: policyDocDocSnapshot.id,
    ...policyDocDocSnapshot.data()
  }
  const { agencyID, insuredID } = policyData
  const querySnapshot = await getDocs(collection(db, "agencies", agencyID, "insureds", insuredID, subcollectionName));
  let total = 0;
  const values = []
  querySnapshot.forEach((doc) => {
    values.push({
      id: doc.id,
      ...doc.data()
    })
  });
  const filteredValues = buildShowingInfoJustActiveOnes(values, policyID)
  filteredValues.forEach((value) => {
    const policyDetail = value?.policiesDetails.find((detail) => detail?.policyID === policyID || detail?.id === policyID)
    total += parseFloat(policyDetail?.truckACV || 0)
  })
  return total;
};

const calculateSubcollectionTotalTrailer = async (policyID, subcollectionName, fieldName) => {
  const policyRef = doc(db, "policy-applications", policyID);
  const policyDocDocSnapshot = await getDoc(policyRef);
  const policyData = {
    id: policyDocDocSnapshot.id,
    ...policyDocDocSnapshot.data()
  }
  const { agencyID, insuredID } = policyData
  let total = 0;
  const values = []
  const querySnapshot = await getDocs(collection(db, "agencies", agencyID, "insureds", insuredID, subcollectionName));
  querySnapshot.forEach((doc) => {
    values.push({
      id: doc.id,
      ...doc.data()
    })
  });
  const filteredValues = buildShowingInfoJustActiveOnes(values, policyID)
  filteredValues.forEach((value) => {
    const fieldValue = value[fieldName] || 0;
    total += parseFloat(fieldValue);
  });
  return total;
};

// Function to calculate the total of "truckACV" in the "power-units" subcollection
export const calculateTruckACVTotal = async (policyID) => {
  return calculateSubcollectionTotalTruck(policyID, "power-units", "truckACV");
};

// Function to calculate the total of "trailerACV" in the "trailers" subcollection
export const calculateTrailerACVTotal = async (policyID) => {
  return calculateSubcollectionTotalTrailer(policyID, "trailers", "trailerACV");
};

// Function to calculate the grand total of truckACV and trailerACV
const getTiv = async (policyID) => {
  const truckACVTotal = await calculateTruckACVTotal(policyID);
  const trailerACVTotal = await calculateTrailerACVTotal(policyID);
  let grandTotal = truckACVTotal + trailerACVTotal;
  grandTotal = grandTotal.toFixed(2);
  return grandTotal;
};

export default getTiv;