import ApiService from "../../../../services/southern-api/apiService";

const getUnderwriterRecipientList = async (fieldName) => {
  const apiService = new ApiService("southern");
  try {
    const response = await apiService.getNotificationRecipients(fieldName);
    return response;
  } catch (error) {
    throw error;
  }
};

export default getUnderwriterRecipientList;
