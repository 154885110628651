import React from "react";
import ActionButton from "./ActionButton";
import { naftaAlPolicyPayload } from "../../../utils/policiesPdfPayloads";
import usePoliciesPdfState from "../../../Context/actions/usePoliciesPdfState";
import renewPolicy from "../../utils/renewPolicy";
import MenuBar from "../ActionButtons/MenuBar";
import excludeEmptyStrings from "../../utils/excludeEmptyStrings";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import GavelIcon from "@mui/icons-material/Gavel";
import ReplyIcon from "@mui/icons-material/Reply";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import AttachEmailIcon from "@mui/icons-material/AttachEmail";
import PrintIcon from "@mui/icons-material/Print";
import requestBind from "../../utils/requestBind";
import bindPolicy from "../../utils/bindPolicy";
import { isPastSomeMinutes } from "../../../utils/helpers";
import PdfGenerator from "../../generals/PdfGenerator";
import requestQuote from "../../utils/requestQuote";
import { UserAuth } from "../../../Context/AuthContent";
import useUsersState from "../../../Context/actions/useUsersState";
import useFormDataState from "../../../Context/actions/useFormData";
import useTranslations from "../../../Context/actions/useTranslations";

const BottomNavbar = ({
  formSaved,
  isAdminOrUnderwriter,
  isQuoteRequest,
  isApplication,
  isPolicy,
  isSubmitted,
  isInCancellation,
  isMissingAtLeastOneCoverage,
  formData,
  insuredInfo,
  driverList,
  truckList,
  trailerList,
  returnToAgent,
  setDisplayEndorsementManager,
  isBindOrder,
  printPolicy,
  printPolicyProgress,
  policyID,
  setDisplayUnderwritingPanel,
  openBindPolicyModal,
  handleOpenBindPolicyModal,
  handleCloseBindPolicyModal,
  openRequestBindModal,
  handleCloseRequestBindModal,
  handleOpenRequestBindModal,
  handleOpenPrintOptionsModal,
  handleOpenSendQuoteModal,
  powerUnitCount,
  driverCount,
  trailerCount,
  userInfo,
  nextAL,
  nextGL,
  insuredID,
  eligibleForRenewal,
  isSubmittedOrFulfilled,
  isAgentOrSubUser,
}) => {
  const { percentage } = printPolicyProgress;
  const { policiesPdf } = usePoliciesPdfState();
  const { loading, url } = policiesPdf || {
    loading: false,
    url: "",
    error: false,
    cancelRequest: false,
  };
  const { user: userAuth } = UserAuth()
  const { user: userState } = useUsersState()

  const { formDataGL } = useFormDataState()

  const isNotRenewal = formData.status !== 'In-Renewal'

  const { t } = useTranslations();

  const buttonIcons = [
    isApplication ? <RequestQuoteIcon /> : "",
    isPolicy ? <PlaylistAddCheckIcon /> : "",
    (eligibleForRenewal && isPolicy && isNotRenewal) ? <AutorenewIcon /> : "",
    isAdminOrUnderwriter && isSubmittedOrFulfilled ? <AttachEmailIcon /> : "",
    isAgentOrSubUser && isSubmittedOrFulfilled && isQuoteRequest ? <GavelIcon /> : "",
    isSubmitted && isAdminOrUnderwriter ? <ReplyIcon /> : "",
    isSubmitted && isBindOrder && isAdminOrUnderwriter ? (
      <LibraryAddCheckIcon />
    ) : (
      ""
    ),
    isAdminOrUnderwriter && isPolicy ? <AssignmentTurnedInIcon /> : "",
    isPolicy && formData.hasALCoverage ? <PrintIcon /> : "",
  ];

  const buttonLabels = [
    isApplication ? t('plm.navbar.mobile.request-quote') : "",
    isPolicy ? t('plm.navbar.mobile.endorsements') : "",
    (eligibleForRenewal && isPolicy && isNotRenewal) ? t('plm.navbar.mobile.renewal-options') : "",
    isAdminOrUnderwriter && isSubmittedOrFulfilled ? t('plm.navbar.mobile.send-quotes') : "",
    isAgentOrSubUser && isSubmittedOrFulfilled && isQuoteRequest ? t('plm.navbar.mobile.bind-policy') : "",
    isSubmitted && isAdminOrUnderwriter ? t('plm.navbar.mobile.return-to-agent') : "",
    isSubmitted && isBindOrder && isAdminOrUnderwriter ? t('plm.navbar.mobile.bind-uw') : "",
    isAdminOrUnderwriter && isPolicy ? t('plm.navbar.mobile.send-letters') : "",
    isPolicy && formData.hasALCoverage ? t('plm.navbar.mobile.print-policy') : "",
  ];

  const onClickFunctions = [
    isApplication ? (e) => requestQuote(e, formData, policyID, userAuth, userState, insuredInfo) : "",
    isPolicy ? () => setDisplayEndorsementManager(true) : "",
    (eligibleForRenewal && isPolicy && isNotRenewal) ? (e) => renewPolicy(
      e,
      policyID,
      null,
      formData,
      userAuth,
      userState,
      insuredInfo,
      driverList,
      truckList,
      trailerList,
    ) : "",
    isAdminOrUnderwriter && isSubmittedOrFulfilled ? () => handleOpenSendQuoteModal() : "",
    isAgentOrSubUser && isSubmittedOrFulfilled && isQuoteRequest ? () => handleOpenRequestBindModal() : "",
    isSubmitted && isAdminOrUnderwriter ? () => returnToAgent() : "",
    isSubmitted && isBindOrder && isAdminOrUnderwriter
      ? () => bindPolicy(
        formData,
        policyID,
        powerUnitCount,
        driverCount,
        trailerCount,
        userInfo,
        insuredInfo,
        nextAL,
        nextGL,
        insuredID,
        userAuth,
        userState,
        formDataGL
      )
      : "",
    isAdminOrUnderwriter && isPolicy ? () => setDisplayUnderwritingPanel(true) : "",
    isPolicy && formData.hasALCoverage
      ? e => handleOpenPrintOptionsModal()
      : "",
  ];

  const cleanedUpOnClickFunctions = excludeEmptyStrings(onClickFunctions);
  const cleanedUpLabels = excludeEmptyStrings(buttonLabels);
  const cleanedUpIcons = excludeEmptyStrings(buttonIcons);

  return (
    <div className="bottom-navbar">
      <div className="bottom-div">
        {cleanedUpIcons.map((icon, index) => (
          <ActionButton
            key={index}
            onClickFunction={cleanedUpOnClickFunctions[index]}
            label={cleanedUpLabels[index]}
            icon={icon}
            disabled={loading || (url && index === cleanedUpIcons.length - 1)}
          />
        ))}
      </div>
    </div>
  );
};

export default BottomNavbar;