import { useEffect, useState } from "react";

const useGridSystem = (
    xs,
    sm,
    md,
    lg,
    xl,
    xxl,
    spacing
) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  let maxWidth;

  if (windowWidth >= 1400 && xxl) {
    maxWidth = `calc(${(xxl / 12) * 100}% - ${2 * spacing}px)`;
  } else if (windowWidth >= 1200 && xl) {
    maxWidth = `calc(${(xl / 12) * 100}% - ${2 * spacing}px)`;
  } else if (windowWidth >= 992 && lg) {
    maxWidth = `calc(${(lg / 12) * 100}% - ${2 * spacing}px)`;
  } else if (windowWidth >= 768 && md) {
    maxWidth = `calc(${(md / 12) * 100}% - ${2 * spacing}px)`;
  } else if (windowWidth >= 600 && sm) {
    maxWidth = `calc(${(sm / 12) * 100}% - ${2 * spacing}px)`;
  } else if (xs) {
    maxWidth = `calc(${(xs / 12) * 100}% - ${2 * spacing}px)`;
  } else {
    maxWidth = `calc(100% - ${2 * spacing}px)`;
  }

  const style = {
    maxWidth,
    width: "100%",
    margin: `${spacing}px`,
  };

  return { style }
};

export default useGridSystem;
