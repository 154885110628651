import readXlsxFile from "read-excel-file";
import { generateMultiSheetXlsxFile } from "../../utils/excelCreator";

const parseFullName = (fullName) => {
    if (!fullName) return { firstName: "", lastName: "" };
    let nameParts = fullName.trim().split(/\s+/);
    let firstName = "";
    let lastName = "";
    if (nameParts.length === 1) {
      firstName = nameParts[0];
    } else if (nameParts.length === 2) {
      firstName = nameParts[0];
      lastName = nameParts[1];
    } else if (nameParts.length === 3) {
      firstName = nameParts[0];
      lastName = nameParts[1] + " " + nameParts[2];
    } else if (nameParts.length >= 4) {
      firstName = nameParts[0] + " " + nameParts[1];
      lastName = nameParts.slice(2).join(" ");
    }
    return {
      firstName: firstName,
      lastName: lastName,
    };
  };

export const readOldInspasData = async (file, controlToast, returnValues = false) => {
  try {
    if (!file) return console.log("no file");
    const importedData = [];
    const errors = [];
    const rows1 = await readXlsxFile(file, { sheet: 1 });
    const rows2 = await readXlsxFile(file, { sheet: 2 });
    const rows3 = await readXlsxFile(file, { sheet: 3 });
    importedData.push(rows1);
    importedData.push(rows2);
    importedData.push(rows3);

    const powerUnitStyles = {
      rectangle: ["A10", "E10"],
      width: ["A10", "E10", 200],
      titles: ["A10", "E10"],
    };

    const driverStyles = {
      rectangle: ["A8", "I8"],
      width: ["A8", "I8", 200],
      titles: ["A8", "I8"],
    };

    const trailerStyles = {
      rectangle: ["A8", "D8"],
      width: ["A8", "D8", 200],
      titles: ["A8", "D8"],
    };

    const driverDataIndex =
      importedData.findIndex((data) => {
        const listId = data?.[3]?.[1];
        return listId === "Drivers List";
      }) || 0;

    const trailerDataIndex =
      importedData.findIndex((data) => {
        const listId = data?.[3]?.[1];
        return listId === "Trailer List";
      }) || 0;

    const powerUnitDataIndex =
      importedData.findIndex((data) => {
        const listId = data?.[3]?.[1];
        return listId === "Power Units List";
      }) || 0;
    // Power Unit Data
    const startSlicePowerUnit = 7;
    const slicedPowerUnitData =
      importedData[powerUnitDataIndex].slice(startSlicePowerUnit);
    const refactoredPowerUnit = slicedPowerUnitData.map((powerUnit) => {
      return [powerUnit[1], powerUnit[6], powerUnit[2], powerUnit[9]];
    });
    const powerUnitDataFiltered = refactoredPowerUnit.filter((powerUnit) => {
      const thereIsNoValue = powerUnit.every((value) => {
        return !Boolean(value);
      });
      return !thereIsNoValue;
    });
    powerUnitDataFiltered.forEach((powerUnit, powerUnitIndex) => {
      const index = startSlicePowerUnit + powerUnitIndex + 1;
      if (!powerUnit[0]) errors.push(`VIN is missing in row ${index}`);
      if (!powerUnit[1])
        errors.push(`Year is missing in row ${index}`);
      if (!powerUnit[2])
        errors.push(
          `Operational Radius (miles) is missing in row ${index}`
        );
      if (typeof powerUnit?.[3] !== 'number') {
        errors.push(`Actual Cash Value is missing in row ${index}`);
      }
    });
    const powerUnitDataFilteredWrongData = powerUnitDataFiltered.filter(
      (powerUnit) => {
        const necessaryValuesAreThere = powerUnit.every((value) => {
          const newValue = Boolean(typeof value !== 'undefined' || value !== null || value !== '')
          return Boolean(newValue);
        });
        return necessaryValuesAreThere;
      }
    );
    const powerUnitDataRefactored = [
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      ["VIN", "Year", "Radius", "Actual Cash Value"],
      ...powerUnitDataFilteredWrongData,
    ];
    // Driver Data
    const startSliceDriver = 6;
    const slicedDriverData =
      importedData[driverDataIndex].slice(startSliceDriver);
    const refactoredDriver = slicedDriverData.map((driver) => {
      const { firstName, lastName } = parseFullName(driver[1]);
      const dob = driver[2] || "";
      const country = driver[3] ? "USA" : driver[5] ? "MX" : "";
      const stateIfUSA = "";
      const licenseNo = driver[3] ? driver[3] : driver[5] ? driver[5] : "";
      const nmp = driver[7] || "";
      const licenseExpirationDate = driver[4]
        ? driver[4]
        : driver[6]
        ? driver[6]
        : "";
      const yoe =
        driver[10] !== null &&
        typeof driver[10] !== "undefined" &&
        typeof driver[10] === "number"
          ? driver[10]
          : "";
      return [
        firstName,
        lastName,
        dob,
        country,
        stateIfUSA,
        licenseNo,
        nmp,
        licenseExpirationDate,
        yoe,
      ];
    });
    const driverDataFiltered = refactoredDriver.filter((driver) => {
      const thereIsNoValue = driver.every((value) => !Boolean(value));
      return !thereIsNoValue;
    });
    driverDataFiltered.forEach((driver, driverIndex) => {
      const index = startSliceDriver + driverIndex + 1;
      if (!driver[0] && !driver[1])
        errors.push(`Full name is missing in row ${index}`);
      if (!driver[2]) errors.push(`DOB is missing in row ${index}`);
      if (!driver[3])
        errors.push(
          `US Driver License Number and MX Driver License Number is missing in row ${index}`
        );
      if (!driver[5])
        errors.push(
          `US Driver License Number and MX Driver License Number is missing in row ${index}`
        );
      if (!driver[7])
        errors.push(
          `Expiration Date of US License and Expiration Date of MX License is missing in row ${index}`
        );
      const yoe = Boolean(
        driver[8] !== null &&
          typeof driver[8] !== "undefined" &&
          typeof driver[8] === "number"
      );
      if (!yoe)
        errors.push(`Experience (years) is missing in row ${index}`);
    });
    const driverDataRefactored = [
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [
        "First Name",
        "Last Name(s)",
        "DOB",
        "Country",
        "State (If USA)",
        "License No.",
        "NMP (If MX)",
        "License Expiration Date",
        "YOE",
      ],
      ...driverDataFiltered,
    ];
    // Trailer Data
    const startSliceTrailer = 7;
    const slicedTrailerData =
      importedData[trailerDataIndex].slice(startSliceTrailer);
    const refactoredTrailer = slicedTrailerData.map((trailer) => {
      return [trailer[1], trailer[2], trailer[3], trailer[4]];
    });
    const trailerDataFiltered = refactoredTrailer.filter((trailer) => {
      const thereIsNoValue = trailer.every((value) => !Boolean(value));
      return !thereIsNoValue;
    });
    trailerDataFiltered.forEach((trailer, trailerIndex) => {
      const index = startSliceTrailer + trailerIndex + 1;
      if (!Boolean(!trailer[0] && !trailer[1] && !trailer[2] && trailer[3])) {
        if (!trailer[0]) errors.push(`VIN is missing in row ${index}`);
        if (!trailer[1]) errors.push(`Make is missing in row ${index}`);
        if (!trailer[2]) errors.push(`Year is missing in row ${index}`);
        if (!trailer[3])
          errors.push(`Actual Cash Value is missing in row ${index}`);
      }
    });
    const trailerDataFilteredWrongData = trailerDataFiltered.filter(
      (trailer) => {
        const necessaryValuesAreThere = trailer.every((value) =>
          Boolean(value)
        );
        return necessaryValuesAreThere;
      }
    );
    const trailerDataRefactored = [
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      ["VIN", "Make", "Year", "Actual Cash Value"],
      ...trailerDataFilteredWrongData,
    ];
    // File Creation
    if (errors?.length > 0) {
       controlToast && controlToast(true, errors.join(", "), "error");
      console.error("errors in data", errors);
      return errors;
    }
    if (!returnValues) {
      await generateMultiSheetXlsxFile(
        [powerUnitDataRefactored, driverDataRefactored, trailerDataRefactored],
        ["Power Unit Data", "Driver Data", "Trailer Data"],
        "Report.xlsx",
        [powerUnitStyles, driverStyles, trailerStyles],
        0
      );
      controlToast && controlToast(true, "Conversion was successful", "success")
    } else {
      controlToast && controlToast(true, "Xlsx file was correctly read", "success")
      return {
        driverDataRefactored,
        powerUnitDataRefactored,
        trailerDataRefactored,
      };
    }
  } catch (error) {
    console.error(error);
  }
};
