import React, { useState } from "react";
import Breadcrumbs from "../../Components/assets/Breadcrumbs";
import { ACCOUNT_ROUTE } from "../../utils/routes";
import useTranslations from "../../Context/actions/useTranslations";
import ReportList from "../../Components/Finances/ReportList";

export default function Finances({
  userInfo,
  loading,
  setLoading
}) {
  
  const [itemList, setItemList] = useState([]);
  const [section, setSection] = useState("policies");
  const [bordereauxType, setBordereauxType] = useState("main");
  const { t } = useTranslations()

  const breadcrumbs = [
    { label: "Home", link: ACCOUNT_ROUTE },
    { label: t('finances.breadcrumb') },
  ];

  return (
    <div className="my-4 max-w-[1165px] mx-auto bg-white rounded-md shadow-md p-6">
      <Breadcrumbs items={breadcrumbs} />
      <div className="flex flex-col md:flex-row md:items-center md:justify-between mt-1">
        <span className="text-3xl font-semibold mb-4 md:mb-0">
          {t('finances.title')}
        </span>
      </div>
      <p className="mb-4">
      {t(`finances.text-${section}${section === 'bordereaux' && bordereauxType ? `-${bordereauxType}` : ''}`)}
      </p>
        <ReportList
          itemList={itemList}
          setItemList={setItemList}
          loading={loading}
          setLoading={setLoading}
          userInfo={userInfo}
          section={section}
          setSection={setSection}
          bordereauxType={bordereauxType}
          setBordereauxType={setBordereauxType}
        />
    </div>
  );
}
