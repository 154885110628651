import { doc, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../../firebase";
import usePoliciesPdfState from "../../Context/actions/usePoliciesPdfState";
import { UserAuth } from "../../Context/AuthContent";

const useWebSocketSimulator = () => {
  const [progress, setProgress] = useState({
    percentage: 0,
    error: false,
    isStreaming: false,
  });

  const [initialSetup, setInitialSetup] = useState(false)
  const { policiesPdf, setPoliciesPdf } = usePoliciesPdfState();
  const auth = UserAuth()
  const uid = auth?.user?.uid

  useEffect(() => {
    if (!initialSetup) {
      setPoliciesPdf({
        loading: false,
        url: '',
        error: false,
        loadingCancel: false,
      })
      setInitialSetup(true)
    }
  }, [])

  useEffect(() => {
    const docRef = doc(
      db,
      "web-socket-simulator",
      `policy-${policiesPdf?.type}-${uid}`
    );
    const unsubscribe = onSnapshot(
      docRef,
      (docSnapshot) => {
        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          if (
            data.progress === 100 ||
            data.message === 'Initial'
          ) {
            setProgress({
              ...progress,
              percentage: data.progress,
              isStreaming: false,
            });
            setPoliciesPdf({
              loading: false,
              error: false
            })
          } else {
            setProgress({
              ...progress,
              percentage: data.progress,
              isStreaming: true
            });
            setPoliciesPdf({
              loading: true,
              error: false
            })
          }
        }
      },
      (error) => {
        setProgress({
          ...progress,
          error,
          isStreaming: false
        });
      }
    );
    return () => unsubscribe();
  }, [policiesPdf?.type, uid]);

  return { progress, initialSetup };
};

export default useWebSocketSimulator;
