import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

export default function ExpandCollapseArrow({ show, setShow }) {
  return (
    <span className="cursor-pointer float-right" onClick={() => setShow(!show)}>
      {!show ? <ExpandMoreIcon /> : <ExpandLessIcon />}
    </span>
  );
}
