import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";

const convertDate = (secs) => {
  const output = new Date(secs * 1000);
  return output;
};

const getApplication = async (policyID, setGotApplicationData, formData, setFormData) => {
  setGotApplicationData(false);
  const docRef = doc(db, "policy-applications", policyID);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    if (docSnap.data().effectiveDate) {
      setFormData({
        ...formData,
        id: docSnap.id,
        ...docSnap.data(),
        effectiveDate:
          convertDate(docSnap.data()?.effectiveDate?.seconds) || "",
      });
    } else {
      setFormData({
        ...formData,
        id: docSnap.id,
        ...docSnap.data(),
        test: "test",
      });
    }
    setGotApplicationData(true);
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!");
    setGotApplicationData(true);
    return null;
  }
};

export default getApplication;
