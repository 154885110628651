import getStampFee from "./premiumCalculation/getStampFee";
import getSurplusLinesRate from "./premiumCalculation/getSurplusLinesRate";

const calculateFeesAndTaxes = (formData, amount, fees, isGL = false, isEndo) => {
    const stampFee = getStampFee(formData.stateOfEntry, fees);
    const surplusLinesTax = getSurplusLinesRate(formData.stateOfEntry, fees);
    const processingFeeAmount = (amount * fees.processingFee * 100) / 100;
    const premiumPlusFees = amount + processingFeeAmount + (isGL ? 0 : isEndo ? 0 : fees.otherFees);
    const stampTaxAmount = (premiumPlusFees * stampFee * 100) / 100;
    const surplusLinesTaxAmount = (premiumPlusFees * surplusLinesTax * 100) / 100;
  
    const taxes = {
      processingFeeAmount,
      stampTaxAmount,
      surplusLinesTaxAmount,
    };
    
    return {
      taxes,
      stampFee,
      surplusLinesTax,
    
    };
  };

  export default calculateFeesAndTaxes;