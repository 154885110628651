import React, { useState } from "react";
import CreateUserPanel from "../agent/createUsers/CreateUserPanel";
import Breadcrumbs from "../assets/Breadcrumbs";
import { ACCOUNT_ROUTE } from "../../utils/routes";
import useTranslations from "../../Context/actions/useTranslations";
import UserList from "./UserList";

export default function ManageUnderwriters({ userInfo, userRole }) {
  const [showCreateUserPanel, setShowCreateUserPanel] = useState(false);
  const [userList, setUserList] = useState([]);

  const { t } = useTranslations();

  const breadcrumbs = [
    { label: "Admin" },
    { label: "Manage " + userRole + "s" },
  ];

  const userIsOwner = userInfo.companyOwner;
  const hasPermissionToView = userInfo?.userPermissions || userIsOwner || false;

  return (
    <div className="max-w-[1000px] mx-auto bg-white rounded-md shadow-md p-6 my-4">
      <Breadcrumbs items={breadcrumbs} />
      <div className="flex flex-col md:flex-row md:items-center md:justify-between mt-1">
        <span className="text-3xl font-semibold mb-4 md:mb-0">
          Manage {userRole}s
        </span>
        {!hasPermissionToView && !showCreateUserPanel && (
          <button
            type="button"
            className="md:ml-auto md:mt-2 sm:mt-2 bg-[#072a48] hover:bg-[#072a48] text-white py-2 px-4 mb-4 rounded"
            onClick={() => setShowCreateUserPanel(true)}
          >
            {t("agent.add-user")}
          </button>
        )}
      </div>
      <p className="mb-4">
        {" "}
        Here you may modify {userRole.toLowerCase()}s and their permissions.
      </p>
      {!showCreateUserPanel && (
        <UserList
          userRole={"Underwriter"}
          userList={userList}
          setUserList={setUserList}
          userInfo={userInfo}
        />
      )}
      {showCreateUserPanel && (
        <CreateUserPanel
          setShowCreateUserPanel={setShowCreateUserPanel}
          userInfo={userInfo}
        />
      )}
    </div>
  );
}
