import React, { useState, useEffect } from "react";
import { Typography, Box, useMediaQuery } from "@mui/material";
import ApprovalButtons from "../../assets/forms/ApprovalButtons";
import Breadcrumbs from "../../assets/Breadcrumbs";
import { UserAuth } from "../../../Context/AuthContent";
import useUsersState from "../../../Context/actions/useUsersState";
import sendQuote from "../../utils/sendQuote";
import Popup from "../../generals/Popup";
import axios from "axios";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import ApiService from "../../../services/southern-api/apiService";
import { Document, Page, pdfjs } from "react-pdf";

const RequestSignaturesModal = ({
  open,
  onClose,
  policyID,
  formData,
  insuredInfo,
  selectedQuote,
  signatureStatus,
}) => {
  const { user: userAuth } = UserAuth();
  const { user: userState } = useUsersState();

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [signerName, setSignerName] = useState(null);

  const apiService = new ApiService("southern");
  const base64Data = formData?.signedProposalData?.signedDocumentBase64 || "";
  const requestSignature = async () => {
    try {
      console.log("Fetching quote data...");

      // Ensure policyID and selectedQuote are defined
      const quoteRef = await getDoc(
        doc(db, "policy-applications", policyID, "quotes", selectedQuote.id)
      );
      const quoteData = quoteRef.data();

      console.log(quoteRef.data());

      if (!quoteData) {
        console.error("No quote data found for the given ID.");
        return;
      }

      const urlField = quoteData.URL; // Access the URL field from quote data
      console.log("Quote URL:", urlField);

      // Prepare the data object for the request
      const data = {
        signerEmail: email, // Replace with actual signer email
        signerName: signerName, // Replace with actual signer name
        url: urlField, // Include the quote URL
        policyID,
      };
      const response = await apiService.sendEnvelope(data);
      console.log("Envelope ID:", response.envelopeId);
    } catch (error) {
      console.error(
        "Error requesting signature:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await requestSignature(
        e,
        formData,
        policyID,
        userAuth,
        userState,
        insuredInfo,
        email,
        selectedQuote
      );
      setLoading(false);
      setEmail("");
      setSignerName("");
      onClose();
    } catch (error) {
      setLoading(false);
    }
  };

  // Set the workerSrc property for pdf.js
  pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  // Function to handle the loading of the PDF
  const onLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const isMobileScreen = useMediaQuery("(max-width: 600px)");

  const breadcrumbs = [
    { label: "Policy" },
    { label: "Quote Request" },
    { label: "Signatures" },
  ];

  return (
    <Popup
    showExpandButton={true}
    expandByDefault={signatureStatus === 'complete' ? true : false}
      isOpen={open}
      onRequestClose={onClose}
      maxWidth={isMobileScreen ? "100%" : "400px"}
      zIndex={1000}
      contentLabel="Send Quote"
    >
      <form onSubmit={handleSubmit}>
        <Box
          sx={{
            maxHeight: "100vh",
            overflowY: "auto",
            width: "100%",
            borderRadius: isMobileScreen ? 0 : 4,
          }}
        >
          <Breadcrumbs items={breadcrumbs} />
          <Typography className="mt-2" variant="h6" gutterBottom>
            {signatureStatus === "sent"
              ? "Awaiting Signature"
              : signatureStatus === "complete"
              ? "Signature Complete"
              : "Request Signatures"}
          </Typography>
          <p className="my-3">
            {signatureStatus === "sent"
              ? "The signature request has been sent. The status will be updated once the signature is completed."
              : signatureStatus === "complete"
              ? "The requested signature has been completed. View the signed document below."
              : "Enter the email address and full name of the signer below."}
          </p>
          {signatureStatus !== "complete" && signatureStatus !== "sent" && (
            <>
              <div className="mb-2">
                <input
                  required
                  name="extraEmailToSend"
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="mt-1 p-2 border rounded-md w-full focus:outline-none"
                />
              </div>
              <div className="mb-2">
                <input
                  required
                  name="signerName"
                  type="text"
                  value={signerName}
                  onChange={(e) => setSignerName(e.target.value)}
                  className="mt-1 p-2 border rounded-md w-full focus:outline-none"
                />
              </div>
              <ApprovalButtons
                onApprove={null}
                onDecline={onClose}
                forceLoadingAccept={loading}
                titleAccept={"Submit"}
                titleDecline={"Cancel"}
                disabledAccept={false}
                disabledDecline={false}
                handleAsSubmit={true}
              />
            </>
          )}
          {signatureStatus === "complete" && (
            <div>
              <Document
                file={`data:application/pdf;base64,${base64Data}`}
                onLoadSuccess={onLoadSuccess}
              >
                {Array.from(new Array(numPages), (el, index) => (
                  <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                ))}
              </Document>

              <div>
                <p>
                  Page {pageNumber} of {numPages}
                </p>
                <button
                  disabled={pageNumber <= 1}
                  onClick={() => setPageNumber(pageNumber - 1)}
                >
                  Previous
                </button>
                <button
                  disabled={pageNumber >= numPages}
                  onClick={() => setPageNumber(pageNumber + 1)}
                >
                  Next
                </button>
              </div>
            </div>
          )}
        </Box>
      </form>
    </Popup>
  );
};

export default RequestSignaturesModal;
