import React from "react";
import TaskCheckbox from "../TaskCheckbox";
import { Td, Tr } from "react-super-responsive-table";

const ProposalReview = ({
  task,
  formData,
  index,
  policyID,
  insuredInfo,
}) => {
  return (
    <>
      <Tr key={"otherLossRun" + index}>
        <Td valign="top">
          <p className="my-1">
            {formData.underwritingTasks?.[task.description]
              ? "Complete"
              : "Incomplete"}
          </p>
        </Td>
        <Td valign="top">
          <p className="my-1">
            {task.cabURL ? (
              <a
                className="text-blue"
                target="_blank"
                rel="noreferrer"
                href={task.cabURL + insuredInfo.dot}
              >
                {task.description}
              </a>
            ) : (
              <>{task.description}</>
            )}
          </p>
        </Td>
        <Td valign="top">
          <p className="my-1">{task.dueDate}</p>
        </Td>
        <Td valign="top">
          {task.type === "checklist" && (
            <>
              {task?.checkListOptions?.map((checkbox, index) => {
                return (
                  <div className="d-block">
                    <TaskCheckbox
                      label={checkbox.label}
                      field={checkbox.value}
                      checklistOptions={task.checkListOptions}
                      policyID={policyID}
                      formData={formData}
                      index={index}
                    />
                  </div>
                );
              })}
            </>
          )}
        </Td>
      </Tr>
    </>
  );
};

export default ProposalReview;