import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../../../firebase";

const processQueryInChunks = async (licenseArray) => {
  const violationsRef = collection(
    db,
    "loss-control",
    "driverData",
    "violations"
  );
  // Define a function to chunk the array into smaller parts
  const chunkArray = (array, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  };

  // Chunk the licenseArray into smaller parts
  const chunkedLicenseArray = chunkArray(licenseArray, 29);

  // Execute a separate query for each chunk and collect the results
  const queryPromises = chunkedLicenseArray.map(async (chunk) => {
    const querySnapshot = await getDocs(
      query(violationsRef, where("LicenseNumber", "in", chunk))
    );
    console.log(querySnapshot.docs);
    return querySnapshot.docs.map((doc) => {
      return { id: doc.id, ...doc.data() };
    });
  });

  // Combine the results from all queries
  const violations = await Promise.all(queryPromises)
    .then((results) => results.flat()) // Flatten the array of arrays
    .catch((error) => {
      console.error("Error querying violations:", error);
      return []; // Return an empty array if an error occurs
    });

  console.log(violations);
  return violations;
};

export default processQueryInChunks;
