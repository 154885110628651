import readXlsxFile from "read-excel-file";
import updateALLossHistory from "../../../utils/updateALLossHistory";

export const importLossesData = async (file, policyID, controlToast, setLoading) => {
  try {
    if (!file) return console.log("no file");
    setLoading(true)
    const rows = await readXlsxFile(file, { sheet: 1 });
    const filteredRows = rows.filter((_, rowIndex) => {
      if (
        rowIndex === 17 ||
        rowIndex === 18 ||
        rowIndex === 19
      ) {
        return true
      } else {
        return false
      }
    })

    for (let i = 0; i < filteredRows.length; i++) {
      const lossYear = i + 1
      const carrier = filteredRows[i][2]
      const losses = filteredRows[i][6]
      const numberOfUnits = filteredRows[i][3]
      const openAtFaultBIClaims = filteredRows[i][9]
      const openBIClaims = filteredRows[i][8]
      const premiumPPU = filteredRows[i][4]
      const totalOpenClaims = filteredRows[i][10]
      const years = filteredRows[i][1]
      await updateALLossHistory(
        policyID,
        lossYear,
        carrier,
        losses,
        numberOfUnits,
        openAtFaultBIClaims,
        openBIClaims,
        premiumPPU,
        totalOpenClaims,
        years
      )
    }

    controlToast(true, "Data was succesfully transfered from the xlsx to the portal", "success");
    setLoading(false)
  } catch (error) {
    console.error(error);
    setLoading(false)
  }
};
