import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import Button from "@mui/material/Button";
import { Paper } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import handleEdit from "../../utils/handleEdit";
import handleDelete from "../../utils/handleDelete";
import handleSave from "../../utils/handleSave";
import handleExpand from "../../utils/handleExpand";
import Header from "./Header";
import Footer from "./Footer";
import Body from "./Body";

export default function DisplayNote({
  note,
  i,
  documentID,
  notesList,
  editMode,
  editedContent,
  editedSubject,
  setEditedContent,
  setEditMode,
  setEditedSubject,
  expandedNotes,
  setExpandedNotes,
  collectionPath
}) {
  return (
    <li key={i} className="my-2 shadow-xs">
      <Paper
        className={`${
          note.role === "Underwriter" || note.role === "Admin"
            ? "bg-red-100"
            : ""
        }`}
        elevation={3}
        style={{ padding: "16px", marginBottom: "16px" }}
      >
        <Grid container spacing={2}>
          <Header note={note} />

          <Body
            note={note}
            editMode={editMode}
            editedContent={editedContent}
            editedSubject={editedSubject}
            setEditedContent={setEditedContent}
            setEditedSubject={setEditedSubject}
            handleExpand={handleExpand}
            expandedNotes={expandedNotes}
            setExpandedNotes={setExpandedNotes}
          />
          
          <Footer
          collectionPath={collectionPath}
            note={note}
            documentID={documentID}
            notesList={notesList}
            editMode={editMode}
            editedContent={editedContent}
            editedSubject={editedSubject}
            setEditedContent={setEditedContent}
            setEditMode={setEditMode}
            setEditedSubject={setEditedSubject}
            handleEdit={handleEdit}
            handleSave={handleSave}
            handleDelete={handleDelete}
          />
        </Grid>
      </Paper>
    </li>
  );
}
