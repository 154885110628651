import React, { useState, useEffect } from "react";
import {
  convertToNestedString,
  handleDynamicQuestionnaireChange,
} from "../../../../utils/helpers";

const TextInput = (props) => {
  const {
    setFormSaved,
    policyID,
    property,
    formData,
    setFormData,
    doNotDebounce,
    label,
  } = props;

  const [inputValue, setInputValue] = useState(
    convertToNestedString(property, formData)
  );

  const [touched, setTouched] = useState(false);

  const handleChange = (e) => {
    setInputValue(e.target.value);
    setTouched(true);
  };

  useEffect(() => {
    let timeoutId;

    const handleDebouncedChange = async () => {
      try {
        await handleDynamicQuestionnaireChange(
          policyID,
          property,
          inputValue,
          formData,
          setFormData,
          setFormSaved,
          doNotDebounce
        );
      } catch (error) {
        throw error;
      }
    };

    if (!doNotDebounce && touched) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(handleDebouncedChange, 3000);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [inputValue]);

  return (
    <div className="my-2">
      <label
        htmlFor={property}
        className="block text-sm font-medium text-gray-700"
      >
        {label}
      </label>
      <input
        id={property}
        type="text"
        className="mt-1 p-1 block w-full rounded-md border border-gray-300 shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        value={touched ? inputValue : convertToNestedString(property, formData)}
        onChange={handleChange}
      />
    </div>
  );
};

export default TextInput;