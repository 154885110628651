// TODO: MAKE LETTERS RESPONSIVE
import React from "react";
import Pdf from "react-to-pdf";
import logo from "../../../assets/images/sslogowhiteshadow.png";

export default function PNONRPreview(props) {
  const ref = React.createRef();
  return (
    <>
      {props.selected !== "" && props.selectedCompanyInfo && (
        <main
          ref={ref}
          style={{
            padding: "25px",
            maxWidth: "790px",
            fontSize: "14px",
          }}
        >
          <table style={{ width: "100%" }}>
            <tr style={{ backgroundColor: "white" }}>
              <td style={{ width: "50%", verticalAlign: "top" }}>
                <p style={{ marginBottom: "0" }}>Southern Star MGA, LLC</p>
                <p style={{ marginTop: "2px", marginBottom: "0" }}>
                  9703 N Interstate 35
                </p>
                <p style={{ marginTop: "2px", marginBottom: "0" }}>
                  San Antonio, Texas 78233
                </p>
                <p style={{ marginTop: "2px" }}>(210) 948-0402</p>
              </td>
              <td
                style={{
                  width: "50%",
                  textAlign: "right",
                  verticalAlign: "top",
                }}
              >
                <img
                  src={logo}
                  alt="Southern Star Logo"
                  style={{
                    float: "right",
                    maxHeight: "100px",
                    marginBottom: "5px",
                  }}
                />
              </td>
            </tr>
          </table>

          <h2
            style={{
              textAlign: "center",
              verticalAlign: "top",
              fontWeight: "700",
              fontSize: "19px",
            }}
          >
            PROVISIONAL NOTICE OF NON-RENEWAL
          </h2>
          <p style={{ marginBottom: "10px" }}>{new Date().toLocaleString().split(",")[0] || ""}</p>

          <p style={{marginBottom: "0"}}>President/Owner</p>
          <p style={{marginTop: "2px", marginBottom: "0"}}>
            {props.selectedCompanyInfo.company || ""}
          </p>
          <p style={{marginTop: "2px", marginBottom: "0"}}>
            {props.selectedCompanyInfo.address || ""}
          </p>
          <p style={{marginTop: "2px", marginBottom: "10px"}}>
            {" "}
            {props.selectedCompanyInfo.city || ""},{" "}
            {props.selectedCompanyInfo.state || ""}{" "}
            {props.selectedCompanyInfo.zip || ""}
          </p>

          <p style={{marginBottom: "0"}}>
            RE: {props.selectedCompanyInfo.company || ""}
          </p>
          <p style={{marginTop: "2px", marginBottom: "0"}}>
            Policy Number(s): {props.letterPolicyNumber},{" "}
            {props.letter2ndPolicyNumber}
          </p>
          <p style={{marginTop: "2px", marginBottom: "10px"}}>Expiration Date: {props.dateOfReinstatement.toString().slice(4,15)}</p>
          <p style={{ marginBottom: "10px" }}>Dear President/Owner,</p>

          <p style={{ marginBottom: "10px" }}>
            Southern Star MGA, LLC values the relationship that has been
            established with you and your company. We hope to continue the
            relationship. This letter is to remind you that coverage under the
            captioned policy will terminate on the above referenced expiration
            date and will not be automatically renewed. Therefore, we encourage
            you to reach out to your insurance broker to start the renewal
            process. Underwriters will review your renewal application once it
            has been received to evaluate the merit of each individual account
            before determining the renewal terms and conditions. We are
            incapable of rendering this decision until the renewal application
            has been received.
          </p>

          <p style={{ marginBottom: "10px" }}>
            State insurance regulations require an insurer to notify an insured
            when insurance coverage may not be renewed or if coverage will be
            renewed at materially different terms and conditions. Since this
            cannot be determined at this time and in order to comply with these
            regulations, Southern Star MGA, LLC has no choice but to provide you
            with this Provisional Notice of Non-Renewal on behalf of Accredited
            Specialty Insurance Company Inc. Please accept this letter as the
            Provisional Notice of Non-Renewal to be effective 12:01 am on{" "}
            {props.dateOfReinstatement.toLocaleString().split(",")[0] || ""},
            the expiration date of the current policy. Also, please take note
            that all filings and certificates of insurance will be canceled as
            of the effective date of the Provisional Notice of Non-Renewal
            listed above.
          </p>

          <p style={{marginBottom: "10px", fontWeight: "bold"}}>
            Please understand that this Provisional Notice of Non-Renewal is not
            irreversible. Upon our timely receipt, review, and acceptance of the
            above requested underwriting information, we hope to be in a
            position to provide a renewal quotation thereby automatically
            rescinding the aforementioned Provisional Notice of Non-Renewal.
          </p>

          <p style={{ marginBottom: "10px" }}>
            If you should have any questions concerning this Provisional Notice
            of Non-Renewal, you should contact your insurance broker
            immediately.
          </p>

          <p>Sincerely,</p>

          <p>Underwriting Department</p>
          <p>Southern Star MGA, LLC</p>
        </main>
      )}
    </>
  );
}
