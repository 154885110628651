import React, { useEffect, useState } from "react";
import {
  getDocs,
  collection,
  query,
  orderBy,
} from "firebase/firestore";
import { db } from "../../firebase";
import "react-datepicker/dist/react-datepicker.css";
import "../styling/app.css";
import { Table, Thead, Tbody, Tr, Td } from "react-super-responsive-table";
import useUsersState from "../../Context/actions/useUsersState";

function SystemLog() {
  const { user } = useUsersState();
  const userID = user.uid;

  const [entryList, setEntryList] = useState([]);
  const [gotEntries, setGotEntries] = useState(false);
  const getEntries = async () => {
    setGotEntries(false);

    const q = query(collection(db, "system-activity"), orderBy("activityDate"));
    const queryData = await getDocs(q);
    const queryResults = queryData.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    setEntryList(queryResults);
    setGotEntries(true);
  };

  const convertDate = (secs) => {
    const output = new Date(secs * 1000);
    return output.toDateString();
  };

  const convertDateLong = (secs) => {
    const output = new Date(secs * 1000);
    return output.toString();
  };

  useEffect(() => {
    if (gotEntries === false) getEntries();
  }, [userID, gotEntries, user.role]);

  return (
    <>
    {user.role === "Admin" || user.role === "Underwriter" ? (
        <main className="max-w-[800px] mx-auto">
          <div className="my-2">
          <a href="/filinglog" className="text-[#072a48]">Filing Log</a>{" | "}
          <a href="/letterlog" className="text-[#072a48]">Letter Log</a>
          </div>
        <h1 className="text-[#072a48] text-2xl font-bold mb-2">Logged System Actions</h1>
        <Table className="data-table w-full">
          <Thead>
            <Tr className="data-table-header">
              <Td>Log Date</Td>
              <Td width="70%">Description</Td>
            </Tr>
          </Thead>
          <Tbody>
            {entryList.map((entry, i) => {
              return (
                <Tr key={i} title={convertDateLong(entry.activityDate.seconds)} className="mb-2">
                  <Td>{convertDate(entry.activityDate.seconds)}</Td>
                  <Td>{entry.description}</Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </main>
    ) : (
        <p>You must be an underwriter or administrator to view this page.</p>
    )}
    </>
    
  );
}

export default SystemLog;
