import calculateAge, { calculateAge2 } from "./calculateAge";
import camelCaseToTitleCase from "./camelCaseToTitleCase";

const checkDriverForFlags = (driver, t) => {
  const redFlags = [];
  const frontLicense = driver?.licensePhoto
    ? driver?.licensePhoto
    : driver?.driverLicenseFront;

  const sliceDate = (dateString) => {
    if (!dateString) return null;
    // Assuming the date format includes time (like "YYYY-MM-DDTHH:mm:ss"), and you only need the date part.
    return dateString.split("T")[0]; // Slices the date from the time if in ISO format.
  };

  //
  // Check Driver For Red Flags
  //

  if (driver?.flags?.flaggedByUnderwriter) {
    const arrayOfReasons = driver?.flags?.flaggedByUnderwriterReasons;
    arrayOfReasons.forEach((reason) => {
      redFlags.push(camelCaseToTitleCase(reason));
    });
  }
  if (!frontLicense) {
    redFlags.push(t("plm.drivers.copy"));
  }

  if (!driver?.dob || !calculateAge2(driver?.dob)) {
    redFlags.push("Invalid Date of Birth");
  }

  if (driver.licenseCountry === "MX" && !driver?.mxNMP) {
    redFlags.push("Missing Numero Medico Preventivo");
  }

  if (
    driver?.dob &&
    calculateAge2(driver?.dob) > 64 &&
    !driver?.medicalLongForm
  ) {
    redFlags.push(t("plm.drivers.details.older"));
  }

  if (driver?.dob && calculateAge2(driver?.dob) < 23) {
    redFlags.push(t("plm.drivers.details.younger"));
  }

  if (
    driver?.sctResults?.determination ===
    "El operador NO cumple con los requisitos para poder conducir"
  ) {
    redFlags.push(t("plm.drivers.details.no-requirements"));
  }

  if (parseInt(driver?.yoe) < 2) {
    redFlags.push(t("plm.drivers.details.not-enough-experience"));
  }

  if (
    driver.licenseCountry === "USA" &&
    driver.licenseCountry !== "MX" &&
    driver.licenseCountry !== "CA" &&
    !driver.MVR
  ) {
    redFlags.push(t("Driver is missing Motor Vehicle Report."));
  }

  if (
    driver.licenseCountry === "MX" &&
    driver.driverLookupStatus !== "Complete" &&
    driver.driverLookupStatus !== "No information found"
  ) {
    redFlags.push(t("Driver must be verified in the SCT system."));
  }

  if (
    driver.licenseCountry === "MX" &&
    driver.driverLookupStatus === "No information found"
  ) {
    redFlags.push("Not Information Found in SCT System.");
  }

  if (driver.driverLookupStatus !== "Complete") {
    redFlags.push("Driver's SCT lookup is incomplete.");
  }
  if (driver.driverLookupStatus === "No information found") {
    redFlags.push("Driver's SCT lookup returned no information.");
  }

  if (driver.driverLookupStatus === "Complete") {
    if (
      driver.sctResults?.determination ===
      "El operador NO cumple con los requisitos para poder conducir"
    ) {
      redFlags.push("Driver does NOT meet the requirements to drive.");
    }

    if (
      driver.sctResults?.observations !==
      "ESTA LICENCIA ES VÁLIDA EN EUA Y CANADA"
    ) {
      redFlags.push("License not valid.");
    }

    if (new Date(sliceDate(driver.sctResults?.expires)) < new Date()) {
      redFlags.push("Driver's License is Expired.");
    }

    if (new Date(sliceDate(driver.sctResults?.examExpiration)) < new Date()) {
      redFlags.push("Medical Exam is Expired.");
    }

    if (driver.sctResults?.driverFit !== "SI") {
      console.log("data: ", driver.sctResults);
      redFlags.push("Driver failed Medical Exam.");
    }

    if (
      driver.sctResults?.driverName !==
      driver.driverFirst + " " + driver.driverLast
    ) {
      redFlags.push("Driver's name does NOT match SCT records.");
    }
  }

  // TODO: Add a lookup date, and have the SCT expire if it's an application.

  return redFlags;
};

export default checkDriverForFlags;
