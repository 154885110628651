import React, { useState } from "react";
import Breadcrumbs from "../../Components/assets/Breadcrumbs";
import AgencyInfo from "../../Components/agent/agency/AgencyInfo";
import { ACCOUNT_ROUTE } from "../../utils/routes";
import useTranslations from "../../Context/actions/useTranslations";
import styles from "../../assets/styles/pages/agency-profile";

export default function AgencyProfile({userInfo}) {
  const [enableEditing, setEnableEditing] = useState(false);
  const [success, setSuccess] = useState(false);

  const { t } = useTranslations()

  const breadcrumbs = [
    { label: t('breadcrumbs.home'), link: ACCOUNT_ROUTE },
    { label: t('breadcrumbs.agency-profile') },
  ];

  const handleSuccess = () => {
    setEnableEditing(true)
    setSuccess(false)
  }

  const userCanModify = userInfo.userPermissions === true;
  const permissions = userInfo.userPermissions;
  const userIsOwner = userInfo.companyOwner;

  return (
    <div className={styles.wrapper}>
      <Breadcrumbs items={breadcrumbs} />
      <div className={styles.container}>
        <span className={styles.title}>
          {t('agency-profile.title')}
        </span>
        {permissions && (userCanModify || userIsOwner) && !enableEditing ? (
          <button
            type="button"
            className={styles.button}
            onClick={handleSuccess}
          >
            {t('agency-profile.edit-company-info')}
          </button>
        ) : null}
      </div>
      <p className={styles.info}>{t('agency-profile.company-info')}</p>
      <fieldset>
        <AgencyInfo
            success={success}
            setSuccess={setSuccess}
            enableEditing={enableEditing}
            setEnableEditing={setEnableEditing}
            userInfo={userInfo}
        />
      </fieldset>
    </div>
  );
}
