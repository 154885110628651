import React, { useState } from "react";
import FormPopup from "../../../Components/generals/Popup/FormPopup";

const ConfirmModal = ({
  open,
  onClose,
  onConfirm,
  inputs,
  title
}) => {

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e, value) => {
    e.preventDefault();
    setLoading(true);
    await onConfirm(value);
    setLoading(false);
    onClose();
  };

  const formSettingsModal = {
    onSubmit: handleSubmit,
    onDecline: onClose,
    inputs,
    buttonLabel: 'SUBMIT',
    buttonLabelDecline: 'CANCEL',
  };

  return (
    <FormPopup
      loading={loading}
      isOpen={open}
      formSettings={formSettingsModal}
      onRequestClose={onClose}
      title={title}
    />
  );
};

export default ConfirmModal;