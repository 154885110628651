const numberInputs = [
    {
      label: "What percentage of your agency volume is in personal insurance?",
      required: true,
      property: "personalInsurancePercentage",
    },
    {
      label:
        "What percentage of your agency volume is in commercial insurance?",
      required: true,
      property: "commercialInsurancePercentage",
    },
    {
      label: "What percentage of your agency volume is in Truck Only business?",
      required: true,
      property: "truckBusinessPercentage",
    },
    {
      label:
        "What is your total written premium in trucking business? (AL + APD + MTC):",
      required: true,
      property: "writtenTruckingPremium",
    },
    {
      label:
        "What percentage of your total written trucking premium is Auto Liability?",
      required: true,
      property: "autoLiabilityPercentage",
    },
    {
      label:
        "What percentage of your total written trucking premium is Auto Physical Damage?",
      required: true,
      property: "autoPhysicalDamagePercentage",
    },
    {
      label:
        "What percentage of your total written trucking premium is Motor Truck Cargo?",
      required: true,
      property: "motorTruckCargoPercentage",
    },
  ];

    export default numberInputs;