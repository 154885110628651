const styles = {
    wrapper: 'card mb-3',
    container: 'card-body',
    searchInput: 'w-full d-block p-2 border rounded focus:outline-none focus:ring focus:border-blue-300',
    table: 'my-3 rounded-md',
    statusColumn: active => (
        `rounded-tl-md bg-[#072a48] text-white ${
            active ? "bg-[#072a48]" : ""
        }`
    ),
    column: active => (
        `bg-[#072a48] text-white ${
            active ? "bg-[#072a48]" : ""
        }`
    ),
    paginationContainer: 'flex justify-center my-2',
    notCurrentPage: 'mx-2 px-3 py-1 rounded border border-gray-300',
    currentPage: 'bg-blue-500 text-white border-blue-500'
}

export default styles