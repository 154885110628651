const styles = {
    wrapper: 'max-w-[700px] mx-auto mt-3',
    fullWrapper: 'mx-auto max-w-[650]',
    img: 'max-w-[150px] mx-auto mt-3 mb-3',
    titleContainer: 'flex justify-center items-center',
    title: 'text-center text-3xl text-[#072a48] font-bold',
    switch: 'block text-xl text-white bg-[#072a48] hover:bg-blue-700 py-2 px-4 mt-3 mb-3 rounded-md',
    inputContainer: 'flex flex-col py-2',
    inputLabel: 'py-2 font-medium',
    input: 'border-slate-300 border-1 rounded p-3',
    policyFields: 'border-slate-300 border-1 rounded p-3 mb-1',
    removePolicy: 'mb-3 text-[#8B0000] text-sm text-left',
    addPolicy: 'text-left text-[#072a48]',
    commentContainer: 'flex flex-col py-2',
    gridContainer: 'flex flex-col py-2 my-3',
    submitRequest: disabled => (
        `block mx-auto text-xl text-white bg-[#072a48]
        ${disabled ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-700'}
        py-2 px-4 mt-3 mb-3 rounded-md`
    ),
    thankyou: 'text-center text-lg font-bold mt-3',
    thankyouText: 'text-center mt-3',
    thankyouButton: 'text-[#072a48] text-center'
}

export default styles