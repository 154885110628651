import React, { useEffect, useState } from "react";
import { Table, Thead, Tbody, Tr, Td } from "react-super-responsive-table";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import classNames from "classnames";
import {
  collection,
  onSnapshot,
  query,
} from "firebase/firestore";
import { db } from "../../firebase";
import TableSkeleton from "../generals/TableSkeleton";

export default function DriverTrainingDashboard() {
  const [trainingHistory, setTrainingHistory] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTrainingHistory = () => {
      setLoading(true);
      const trainingsCollectionRef = collection(db, "driver-trainings");
      const q = query(trainingsCollectionRef);
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const history = [];
        querySnapshot.forEach((doc) => {
          history.push({ ...doc.data(), id: doc.id });
        });
        setLoading(false);
        setTrainingHistory(history);
      });
      return unsubscribe;
    };
    const unsubscribe = fetchTrainingHistory();
    return () => unsubscribe();
  }, []);

  return (
    <div>
        <h1 className="text-3xl mt-2 font-semibold mb-3">Driver's Trainings</h1>
        <p className="mt-3 mb-4">
          A list of all driver's trainings.
        </p>
      {loading ? (
        <div className="mb-3">
          <TableSkeleton rows={2} columns={7} />
        </div>
      ) : (
        <Table>
          <Thead>
            <Tr>
              <Td
                className={classNames(
                  "bg-[#072a48] min-w-[80px] text-white rounded-tl-md cursor-pointer"
                )}
              >
                Training Name
              </Td>
              <Td
                className={classNames(
                  "bg-[#072a48] text-white cursor-pointer max-w-[275px] min-w-[150px]"
                )}
              >
                License Number
              </Td>
              <Td
                className={classNames("bg-[#072a48] text-white cursor-pointer")}
              >
                Completion Date
              </Td>
              <Td
                className={classNames("bg-[#072a48] text-white cursor-pointer")}
              >
                Notes
              </Td>
              <Td
                className={classNames("bg-[#072a48] text-white cursor-pointer")}
              >
                Institution
              </Td>
              <Td
                className={classNames(
                  "bg-[#072a48] text-white rounded-tr-md cursor-pointer"
                )}
              >
                View Certificate
              </Td>
            </Tr>
          </Thead>
          <Tbody>
            {trainingHistory.map((training, index) => (
              <Tr key={index}>
                <Td>{training.trainingName}</Td>
                <Td>{training?.driversLicenseNumber?.driversLicenseNumber || ''}</Td>
                <Td>
                  {training?.completionDate
                    ? training.completionDate.toDate().toString().slice(4, 15)
                    : ""}
                </Td>
                <Td>{training.notes}</Td>
                <Td>{training.institution}</Td>
                <Td>
                  {training.certificateFileURL?.length > 0 ? (
                    <a
                      href={training.certificateFileURL[0]}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <IconButton>
                        <VisibilityIcon sx={{ color: "#072a48" }} />
                      </IconButton>
                    </a>
                  ) : (
                    "N/A"
                  )}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
    </div>
  );
}
