import React, { createContext, useState } from 'react';
import ConfirmModal from './ConfirmModal';

const ConfirmDeleteContext = createContext();

let globalConfirmDeletePrompt;

export const ConfirmDeleteProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [resolvePromise, setResolvePromise] = useState(null);
  const [returnMessage, setReturnMessage] = useState('DELETE');
  const [content, setContent] = useState('');
  const [type, setType] = useState('message');

  const confirmDeletePrompt = (content, settings) => {
    const { returnMessage, type } = settings
    setType(type);
    setContent(content);
    setReturnMessage(returnMessage);
    setIsOpen(true);
    return new Promise((resolve) => {
      setResolvePromise(() => resolve);
    });
  };

  globalConfirmDeletePrompt = confirmDeletePrompt;

  const handleConfirm = () => {
    setIsOpen(false);
    if (resolvePromise) {
      resolvePromise(returnMessage);
    }
  };

  const handleCancel = () => {
    setIsOpen(false);
    if (resolvePromise) {
      resolvePromise(null);
    }
  };

  return (
    <ConfirmDeleteContext.Provider value={{ confirmDeletePrompt }}>
      {children}
      <ConfirmModal
        open={isOpen}
        onClose={handleCancel}
        onConfirm={handleConfirm}
        content={content}
        type={type}
      />
    </ConfirmDeleteContext.Provider>
  );
};

export const confirmDeletePromptGlobal = (content, config) => { 
  const returnMessage = config?.returnMessage || 'DELETE';
  const type = config?.type || 'message';
  const newSettings = {
    returnMessage,
    type
  };
  if (!globalConfirmDeletePrompt) {
    throw new Error('confirmDeletePrompt is not initialized.');
  }
  return globalConfirmDeletePrompt(content, newSettings);
};