import React, { useEffect, useState } from "react";
import {
  collection,
  query,
  updateDoc,
  doc,
  onSnapshot,
} from "firebase/firestore";
import {
  AppBar,
  Card,
  CardContent,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Edit, Save } from "@mui/icons-material";
import { db } from "../../../firebase";
import camelCaseToTitleCase from "../../utils/camelCaseToTitleCase";

const TableWithFormulas = ({ tableName, getDisplayValue, mainCollection, customOrder }) => {
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingDocument, setEditingDocument] = useState(null);
  const [newValues, setNewValues] = useState({});

  useEffect(() => {
    const unsubscribe = onSnapshot(
      query(collection(db, "loss-control", mainCollection, tableName)),
      (snapshot) => {
        const fetchedDocuments = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setDocuments(fetchedDocuments);
        setLoading(false);
      },
      (error) => {
        console.error("Error fetching documents: ", error);
        setLoading(false);
      }
    );
    return () => unsubscribe();
  }, []);

  const isFormula = (str) => {
    if (
      typeof str === 'string' &&
      str?.includes("formula")
    ) return true;
    else return false;
  };

  const handleEdit = (documentId) => {
    setEditingDocument(documentId);
    setNewValues({
      ...newValues,
      [documentId]: { ...documents.find((doc) => doc.id === documentId) },
    });
  };

  const handleSave = async (tableName, documentId) => {
    try {
      await updateDoc(
        doc(db, "loss-control", mainCollection, tableName, documentId),
        newValues[documentId]
      );
      setEditingDocument(null);
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  };

  const handleChange = (e, fieldName, documentId) => {
    const { value } = e.target;
    setNewValues({
      ...newValues,
      [documentId]: { ...newValues[documentId], [fieldName]: value },
    });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (documents.length === 0) {
    return <div>No documents found.</div>;
  }

  const columnNames = Object.keys(documents[0]);
  const orderToShow = customOrder && Array.isArray(customOrder) ? customOrder : columnNames;

  return (
    <Card variant="outlined" className="my-4" sx={{ overflowX: 'auto' }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          {camelCaseToTitleCase(tableName)}
        </Typography>
        <table className="w-full">
          <thead>
            <tr>
              {orderToShow.map((columnName) => {
                if (columnName === "id" || !columnNames.includes(columnName)) return null;
                return (
                  <th key={columnName}>{camelCaseToTitleCase(columnName)}</th>
                );
              })}
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {documents.map((doc) => (
              <tr key={doc.id}>
                {orderToShow.map((columnName) => {
                  if (columnName === "id" || !columnNames.includes(columnName)) return null;
                  const cellValue =
                    editingDocument === doc.id
                      ? newValues[doc.id][columnName]
                      : doc[columnName];
                  const displayValue = getDisplayValue(cellValue, doc, columnName);
                  return (
                    <td key={columnName}>
                      {editingDocument === doc.id ? (
                        <TextField
                          disabled={isFormula(cellValue)}
                          fullWidth
                          value={
                            isFormula(cellValue) ? "Formula" : displayValue
                          }
                          onChange={(e) =>
                            handleChange(e, columnName, doc.id)
                          }
                        />
                      ) : (
                        displayValue
                      )}
                    </td>
                  );
                })}
                <td>
                  {editingDocument === doc.id ? (
                    <IconButton onClick={() => handleSave(tableName, doc.id)}>
                      <Save />
                    </IconButton>
                  ) : (
                    <IconButton onClick={() => handleEdit(doc.id)}>
                      <Edit />
                    </IconButton>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </CardContent>
    </Card>
  );
};

export default TableWithFormulas;