import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Box,
  TextField,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { doc, updateDoc, onSnapshot, getDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import { numberWithCommas } from "../../../utils/helpers";

const EditDropdownModal = ({ open, onClose, field }) => {
  const [options, setOptions] = useState([]);
  const [newOption, setNewOption] = useState("");

  useEffect(() => {
    if (!field) return;

    const unsubscribe = onSnapshot(
      doc(db, "dropdownData", "mtcDropdowns"),
      (doc) => {
        if (doc.exists()) {
          setOptions(doc.data()[field] || []);
        }
      }
    );

    return () => unsubscribe();
  }, [field, open]);

  const handleDeleteOption = async (valueToDelete) => {
    try {
      const dropdownRef = doc(db, "dropdownData", "mtcDropdowns");
      const dropdownSnap = await getDoc(dropdownRef);
      const dropdownData = dropdownSnap.data()[field];
      const newOptions = dropdownData.filter(
        (option) => option !== valueToDelete
      );

      await updateDoc(dropdownRef, { [field]: newOptions });
    } catch (error) {
      console.error("Error deleting option:", error);
    }
  };

  const handleAddOption = async () => {
    if (!newOption) {
      console.warn("New option is empty");
      return;
    }

    try {
      const dropdownRef = doc(db, "dropdownData", "mtcDropdowns");
      const dropdownSnap = await getDoc(dropdownRef);
      const dropdownData = dropdownSnap.data()[field];
      const newOptions = [...dropdownData, parseInt(newOption)];

      await updateDoc(dropdownRef, { [field]: newOptions });
      setNewOption("");
    } catch (error) {
      console.error("Error adding option:", error);
    }
  };

  // Sort options by largest to smallest
  const sortedOptions = options
    .map(option => parseInt(option)) // Parse options to integers
    .sort((a, b) => a - b) // Sort in descending order
    .map(option => ({ value: option, label: `$${numberWithCommas(option)}` })); // Format options

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="edit-dropdown-modal">
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <h2 className="mb-2">Edit Options</h2>
        <TextField
          label="Add New Option"
          variant="outlined"
          type="number"
          fullWidth
          value={newOption}
          onChange={(e) => setNewOption(e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddOption}
          startIcon={<AddIcon />}
          sx={{ mt: 2 }}
        >
          Add Option
        </Button>
        <Box
          sx={{
            maxHeight: 300,
            overflowY: "auto",
            mt: 2,
          }}
        >
          <List>
            {sortedOptions.map((option) => (
              <ListItem
                key={option.value}
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => handleDeleteOption(option.value)}
                  >
                    <DeleteIcon />
                  </IconButton>
                }
              >
                <ListItemText primary={option.label} />
              </ListItem>
            ))}
          </List>
        </Box>
        <Button
          variant="outlined"
          color="secondary"
          onClick={onClose}
          sx={{ mt: 2 }}
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
};

export default EditDropdownModal;
