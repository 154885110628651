

import React, { useState } from 'react';
import { Card, CardContent, Typography, Grid, TextField } from '@mui/material';

const MCS150 = ({ handleInputChange }) => {
    // Define questions
    const questions = [
      { id: 'lastUpdated', label: 'Last updated (XX/XX/XXXX):', type: 'date' },
      { id: 'ownedUnits', label: 'Owned PU and NO PU units:', type: 'text' },
      { id: 'leasedUnits', label: 'Leased PU and NO PU units:', type: 'text' },
      { id: 'tripLeasedUnits', label: 'Leased PU and NO PU for a trip unit:', type: 'text' },
      { id: 'mileage', label: 'Mileage:', type: 'number' },
      {
        id: 'shareDriversPolicy',
        label: 'In the case of sister companies, do you share drivers? (Policy)',
        type: 'textarea',
      },
      { id: 'totalDrivers', label: 'Total drivers:', type: 'number' },
      { id: 'b1Drivers', label: 'B1:', type: 'number' },
      { id: 'cdlDrivers', label: 'CDL:', type: 'number' },
      { id: 'teamTrucks', label: 'Team trucks:', type: 'number' },
      { id: 'driverRetention', label: 'Driver retention (turnover rate):', type: 'text' },
      { id: 'englishSpeakers', label: 'English speakers:', type: 'number' },
      {
        id: 'issScoreRelation',
        label: 'Directly related to ISS score',
        type: 'textarea',
      },
      {
        id: 'firedDrivers',
        label: 'Fired drivers and reasons (last 6 months):',
        type: 'textarea',
        placeholder: '1. Ex. John Smith – He kicked the owner.',
      },
    ];
  
    // State to manage responses
    const [responses, setResponses] = useState(
      questions.reduce((acc, { id }) => ({ ...acc, [id]: '' }), {}) // Initialize state dynamically based on questions
    );

  return (
    <Card sx={{ maxWidth: 800, margin: 'auto', padding: 2, marginBottom: 2 }}>
      <CardContent>
        <Typography variant="h5" component="div" gutterBottom>
          MCS150
        </Typography>
        <Grid container spacing={2}>
          {questions.map(({ id, label, type }) => (
            <Grid item xs={12} key={id}>
              <TextField
                fullWidth
                multiline={type === 'textarea'}
                rows={type === 'textarea' ? 4 : 1}
                variant="outlined"
                label={label}
                value={responses[id] || ''}
                onChange={(e) => handleInputChange(id, e.target.value)}
              />
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default MCS150;
