import React from 'react'
import useTranslations from "../../../../../Context/actions/useTranslations";

export default function BusinessComposition({coverageCounts, setCoverageCounts}) {
    const { t } = useTranslations();
    const sumCoverageCounts = (coverageCounts) => {
        const countsArray = Object.values(coverageCounts);
        
        const total = countsArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
      
        return total;
      };
      
      const totalCoverageCounts = sumCoverageCounts(coverageCounts);
      const apdPercentage = coverageCounts.APD / totalCoverageCounts;
      const mtcPercentage = coverageCounts.MTC / totalCoverageCounts;
      const alPercentage = coverageCounts.AL / totalCoverageCounts;
      const glPercentage = coverageCounts.GL / totalCoverageCounts;

  return (
    <div className="card mb-4 shadow">
        <div className="card-header bg-[#8B0000]">
          <h2 className="font-medium text-white">
            {t("account.agent-at-a-glance.business-composition")}
          </h2>
        </div>
        <div className="card-body">
          <h1 className="text-center">
            <span className="text-xl">{((alPercentage || 0) * 100).toFixed(0)}%</span>
            <span className="mr-4 ml-1">
              AL
            </span>
            <span className="text-xl">{((glPercentage || 0) * 100).toFixed(0)}%</span>
            <span className="mr-4 ml-1">
              GL
            </span>
            <span className="text-xl">{((apdPercentage || 0) * 100).toFixed(0)}%</span>
            <span className="mr-4 ml-1">
              {t("account.agent-at-a-glance.mtc")}
            </span>
            <span className="text-xl">{((mtcPercentage || 0) * 100).toFixed(0)}%</span>
            <span className="ml-1">{t("account.agent-at-a-glance.apd")}</span>
          </h1>
        </div>
        <div className="card-footer">
          <h4 className="text-[#8B0000] font-medium">
            {t("account.agent-at-a-glance.tier-reached")}
          </h4>
        </div>
      </div>
  )
}
