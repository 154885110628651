import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const SearchType = ({ selectedOption, setSelectedOption }) => {

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <div className='my-4'>
      <FormControl fullWidth>
        <InputLabel id="select-label">Search Type</InputLabel>
        <Select
          labelId="select-label"
          id="select"
          value={selectedOption}
          onChange={handleOptionChange}
          fullWidth
        >
          <MenuItem value={'license'}>Search By License</MenuItem>
          <MenuItem value={'name'}>Search By Name</MenuItem>
          <MenuItem value={'policy'}>Search By Policy</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

export default SearchType;
