import AllLossRunsList from "../../../Components/lossruns/LossRunsList";
import useTranslations from "../../../Context/actions/useTranslations";
import styles from "../../../assets/styles/pages/new-support-ticket";

function LossRunsList(props) {

  const { t } = useTranslations();

  return (
    <main className={styles.fullWrapper}>
      <div className={styles.titleContainer}>
        <h1 className="title-1">{t("loss-runs.title")}</h1>
      </div>
        <AllLossRunsList userInfo={props.userInfo} />
    </main>
  );
}

export default LossRunsList;
