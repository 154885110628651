import React, { useState } from 'react';
import { Button, TextField, Typography, Box, Container, Paper, Link } from '@mui/material';
import { Google as GoogleIcon } from '@mui/icons-material';
import { SIGN_UP_ROUTE } from "../../utils/routes";
import useTranslations from "../../Context/actions/useTranslations";
import useSignIn from "../../hooks/auth/useSignIn";
import { Lock as LockIcon } from '@mui/icons-material';

function Signin() {
  const [showPasswordSignIn, setShowPasswordSignIn] = useState(false);
  const {
    loginErrorMessage,
    handleSubmit,
    setEmail,
    setPassword,
    googleSignIn,
  } = useSignIn();
  const { t } = useTranslations();

  const handleShowPasswordSignIn = () => {
    setShowPasswordSignIn(true);
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <Container maxWidth="sm">
        <Paper elevation={3} sx={{ padding: 4, textAlign: 'center' }}>
          <Typography variant="h4" sx={{ color: '#072a48' }} fontWeight="bold" gutterBottom>
            {t("signin.welcome")}
          </Typography>
          {loginErrorMessage !== "" && (
            <Typography variant="body1" color="error" sx={{ backgroundColor: '#f8d7da', padding: 2, borderRadius: 1 }}>
              {loginErrorMessage}
            </Typography>
          )}
          {!showPasswordSignIn ? (
            <>
              <Button
  onClick={handleShowPasswordSignIn}
  variant="contained"
  color="primary"
  fullWidth
  startIcon={<LockIcon />}
  sx={{ mt: 2 }}
>
  {/* TODO: Multilanguage */}
  Sign In With Password
</Button>

            </>
          ) : (
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
              <TextField
                label={t("signin.email-address")}
                type="email"
                fullWidth
                margin="normal"
                variant="outlined"
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <TextField
                label={t("signin.password")}
                type="password"
                fullWidth
                margin="normal"
                variant="outlined"
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
                {t("signin.sign-in")}
              </Button>
            </Box>
          )}
                        <Button
                onClick={googleSignIn}
                variant="contained"
                color="secondary"
                fullWidth
                startIcon={<GoogleIcon />}
                sx={{ mt: 2 }}
              >
                {t("signin.sign-in-google")}
              </Button>
          <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
            {t("signin.new-user")}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {t("signin.still-sign-in")}
            {" "}
            <Link href={SIGN_UP_ROUTE} color="primary">
              {t("signin.click-here")}
            </Link>{" "}
            {t("signin.create-account")}
          </Typography>
          <Button
            href='/absence'
            variant="text"
            color="primary"
            fullWidth
            sx={{ mt: 2 }}
          >
            {t("Report Absence/Tardy")}
          </Button>
        </Paper>
      </Container>
    </Box>
  );
}

export default Signin;
