import React from "react";
import Table from "./Table.jsx";

const Tables = () => {

  // const driverDataNames = [
  //   'fmcsaViolations',
  //   'fmcsaDriverFitness',
  //   'driverProfile',
  // ];

  const tableNames = [
    "eventualities",
    "classifications",
    "major",
    "minor",
    "notDrivingRisk",
    "notIncluded",
    "age",
    "experience",
    "numberOfClaims",
    "claimsDollarAmount",
    "finalWeight",
  ];

  return (
    <div>
      {/* {driverDataNames.map((tableName, index) => (
        <div className="my-4" key={index}>
          <Table mainCollection='driverData' tableName={tableName} />
        </div>
      ))} */}
      {tableNames.map((tableName, index) => (
        <div className="my-4" key={index}>
          <Table mainCollection='tables' tableName={tableName} />
        </div>
      ))}
    </div>
  );
};

export default Tables;
