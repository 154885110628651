import React, { useEffect, useState } from "react";
import { addDoc, collection, doc, onSnapshot, setDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import useTranslations from "../../../Context/actions/useTranslations";
import { UserAuth } from "../../../Context/AuthContent";
import { useNavigate } from "react-router-dom";
import { ACCOUNT_ROUTE } from "../../../utils/routes";
import useUsersState from "../../../Context/actions/useUsersState";
import useCompaniesState from "../../../Context/actions/useCompaniesState";

export default function AgencyInfo(props) {
  const enableEditing = props.enableEditing;
  const setEnableEditing = props.setEnableEditing;
  const success = props.success;
  const setSuccess = props.setSuccess;
  
  const navigate = useNavigate()
  const { t } = useTranslations();
  const { user } = UserAuth()
  const { setUser, user: userState } = useUsersState()
  const agencyID = userState?.agencyID
  const { setAgency, createAgency } = useCompaniesState()

  const [formData, setFormData] = useState({});
  
  const saveForm = async (e) => {
    e.preventDefault();
    const data = {
      ...formData,
      ownerID: userState?.uid || ''
    }
    try {
      if (!agencyID) {
        const agencyResult = await createAgency(data)
        const newAgencyID = agencyResult.id;
        await setUser(user.uid, { agencyID: newAgencyID })
        navigate(ACCOUNT_ROUTE)
        setEnableEditing(false);
        setSuccess(true);
      } else {
        await setAgency(agencyID, data)
        setEnableEditing(false);
        setSuccess(true);
      }
    } catch (error) {
      throw error
    }
  };

  const getAgencyInfo = (agencyID) => {
    if (!agencyID) {
      console.log("No Agency ID Found")
      setEnableEditing(true)
      return () => {};
    }
    const docRef = doc(db, "agencies", agencyID);
    const unsubscribe = onSnapshot(docRef, (docSnap) => {
      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        setFormData({ ...docSnap.data(), id: docSnap.id });
      } else {
        console.log("No such document!");
      }
    });
    return unsubscribe;
  };

  useEffect(() => {
    const unsubscribe = getAgencyInfo(agencyID);
    return () => unsubscribe();
  }, [agencyID]);

  return (
    <>
      <fieldset disabled={!enableEditing}>
        <form onSubmit={(e) => saveForm(e)}>
          <div>
            <div className="mb-2">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-600"
              >
                {t("agency-profile.agency-name")}:
              </label>
              <input
                required
                name="name"
                type="text"
                value={formData.name}
                onChange={(e) =>
                  setFormData({ ...formData, [e.target.name]: e.target.value })
                }
                className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
              />
            </div>
            <div className="mb-2">
              <label
                htmlFor="primaryContact"
                className="block text-sm font-medium text-gray-600"
              >
                {t("agency-profile.primary-contact-full-name")}:
              </label>
              <input
                required
                name="primaryContact"
                type="text"
                value={formData.primaryContact}
                onChange={(e) =>
                  setFormData({ ...formData, [e.target.name]: e.target.value })
                }
                className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
              />
            </div>
            <div className="mb-2">
              <label
                htmlFor="primaryContactEmail"
                className="block text-sm font-medium text-gray-600"
              >
                {t("agency-profile.primary-contact-email")}:
              </label>
              <input
                required
                name="primaryContactEmail"
                type="text"
                value={formData.primaryContactEmail}
                onChange={(e) =>
                  setFormData({ ...formData, [e.target.name]: e.target.value })
                }
                className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
              />
            </div>
            <div className="mb-2">
              <label
                htmlFor="mailingAddress"
                className="block text-sm font-medium text-gray-600"
              >
                {t("agency-profile.mailing-address")}:
              </label>
              <input
                required
                name="mailingAddress"
                type="text"
                value={formData.mailingAddress}
                onChange={(e) =>
                  setFormData({ ...formData, [e.target.name]: e.target.value })
                }
                className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
              />
            </div>

            <div className="mb-2 grid grid-cols-3 gap-4">
              <div>
                <label
                  htmlFor="city"
                  className="block text-sm font-medium text-gray-600"
                >
                  {t("agency-profile.city")}:
                </label>
                <input
                  required
                  name="city"
                  type="text"
                  value={formData.city}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    })
                  }
                  className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                />
              </div>

              <div>
                <label
                  htmlFor="state"
                  className="block text-sm font-medium text-gray-600"
                >
                  {t("agency-profile.state")}:
                </label>
                <select
                  required
                  name="state"
                  value={formData.state}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    })
                  }
                  className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                >
                  <option value="">{t("agency-profile.select-state")}</option>
                  <option value="AL">Alabama</option>
                  <option value="AK">Alaska</option>
                  <option value="AZ">Arizona</option>
                  <option value="AR">Arkansas</option>
                  <option value="CA">California</option>
                  <option value="CO">Colorado</option>
                  <option value="CT">Connecticut</option>
                  <option value="DE">Delaware</option>
                  <option value="FL">Florida</option>
                  <option value="GA">Georgia</option>
                  <option value="HI">Hawaii</option>
                  <option value="ID">Idaho</option>
                  <option value="IL">Illinois</option>
                  <option value="IN">Indiana</option>
                  <option value="IA">Iowa</option>
                  <option value="KS">Kansas</option>
                  <option value="KY">Kentucky</option>
                  <option value="LA">Louisiana</option>
                  <option value="ME">Maine</option>
                  <option value="MD">Maryland</option>
                  <option value="MA">Massachusetts</option>
                  <option value="MI">Michigan</option>
                  <option value="MN">Minnesota</option>
                  <option value="MS">Mississippi</option>
                  <option value="MO">Missouri</option>
                  <option value="MT">Montana</option>
                  <option value="NE">Nebraska</option>
                  <option value="NV">Nevada</option>
                  <option value="NH">New Hampshire</option>
                  <option value="NJ">New Jersey</option>
                  <option value="NM">New Mexico</option>
                  <option value="NY">New York</option>
                  <option value="NC">North Carolina</option>
                  <option value="ND">North Dakota</option>
                  <option value="OH">Ohio</option>
                  <option value="OK">Oklahoma</option>
                  <option value="OR">Oregon</option>
                  <option value="PA">Pennsylvania</option>
                  <option value="RI">Rhode Island</option>
                  <option value="SC">South Carolina</option>
                  <option value="SD">South Dakota</option>
                  <option value="TN">Tennessee</option>
                  <option value="TX">Texas</option>
                  <option value="UT">Utah</option>
                  <option value="VT">Vermont</option>
                  <option value="VA">Virginia</option>
                  <option value="WA">Washington</option>
                  <option value="WV">West Virginia</option>
                  <option value="WI">Wisconsin</option>
                  <option value="WY">Wyoming</option>
                </select>
              </div>

              <div className="mb-2">
                <label
                  htmlFor="zip"
                  className="block text-sm font-medium text-gray-600"
                >
                  Doing Business As (DBA):
                </label>
                <input
                  name="dba"
                  type="text"
                  value={formData.dba}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    })
                  }
                  className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                />
              </div>
            </div>

            <div className="mb-2 grid grid-cols-3 gap-4">
              <div>
                <label
                  htmlFor="zip"
                  className="block text-sm font-medium text-gray-600"
                >
                  {t("agency-profile.zip-code")}:
                </label>
                <input
                  required
                  name="zip"
                  type="text"
                  value={formData.zip}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    })
                  }
                  className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                />
              </div>

              <div className="mb-2">
                <label
                  htmlFor="countryCode"
                  className="block text-sm font-medium text-gray-600"
                >
                  {t("agency-profile.country-code")}:
                </label>
                <select
                  required
                  name="countryCode"
                  value={formData.countryCode}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    })
                  }
                  className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                >
                  <option value="+1">USA (+1)</option>
                  <option value="+52">Mexico (+52)</option>
                </select>
              </div>

              <div>
                <label
                  htmlFor="phoneNumber"
                  className="block text-sm font-medium text-gray-600"
                >
                  {t("agency-profile.phone-number")}:
                </label>
                <input
                  required
                  name="phoneNumber"
                  type="text"
                  value={formData.phoneNumber}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    })
                  }
                  className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                />
              </div>
            </div>

            <div className="mb-2">
              <label
                htmlFor="businessStructure"
                className="block text-sm font-medium text-gray-600"
              >
                {t("agency-profile.business-structure")}:
              </label>
              <select
                required
                name="businessStructure"
                value={formData.businessStructure}
                onChange={(e) =>
                  setFormData({ ...formData, [e.target.name]: e.target.value })
                }
                className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
              >
                <option value="">{t("agency-profile.choose-one")}</option>
                <option value="Sole Proprietorship">
                  {t("agency-profile.sole-proprietorship")}
                </option>
                <option value="Partnership">
                  {t("agency-profile.partnership")}
                </option>
                <option value="LLC">{t("agency-profile.llc")}</option>
                <option value="Corporation">
                  {t("agency-profile.corporation")}
                </option>
              </select>
            </div>
          </div>
          {enableEditing && (
            <div className="flex justify-end">
              <button
                type="submit"
                className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-[#072a48] focus:outline-none focus:ring focus:border-blue-300"
              >
                {t("agency-profile.submit")}
              </button>
              <button
                type="button"
                className="ml-2 px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 focus:outline-none focus:ring focus:border-gray-500"
                onClick={(e) => {
                  e.preventDefault();
                  setFormData({});
                  setEnableEditing(false);
                }}
              >
                {t("agency-profile.cancel")}
              </button>
            </div>
          )}
        </form>
      </fieldset>
      {success && (
        <main className="bg-green-100 p-6 rounded-md shadow-md text-green-700">
          <p className="text-2xl font-semibold mb-4">
            {t("agency-profile.successfully-updated")}
          </p>
          <button
            onClick={(e) => {
              e.preventDefault();
              setSuccess(false);
            }}
            className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded"
          >
            {t("agency-profile.ok")}
          </button>
        </main>
      )}
    </>
  );
}
