import React, { useState } from 'react';
import { Card, CardContent, Typography, Grid, TextField } from '@mui/material';

const AddedInformationForUnits = () => {
  // Define questions
  const questions = [
    { id: 'safetyAntiCrashSystem', label: 'Do we have any safety anti-crash system installed on your trucks?', type: 'text' },
    { id: 'safetyAntiCrashSystemDetails', label: 'If yes which system (specs can be provided)', type: 'textarea' },
    { id: 'gpsSystemsInstalled', label: 'Do you have GPS systems installed on your equipment?', type: 'text' },
    { id: 'gpsSystemsTruckOrTrailer', label: 'Only trucks or both trailer and truck?', type: 'text' },
    { id: 'gpsContractLength', label: 'Contract length', type: 'text' },
    { id: 'gpsSystemStartDate', label: 'Since when have you been working with this GPS system?', type: 'text' },
    { id: 'truckAdditions', label: 'Do you have any additions to your trucks? (Grill guard)', type: 'text' },
    { id: 'extremeWeatherConditionsProcedures', label: 'Do we have any extreme weather conditions procedures?', type: 'text' },
    { id: 'extremeWeatherConditionsDetails', label: 'What are they?', type: 'textarea' },
  ];

  // State to manage responses
  const [responses, setResponses] = useState(
    questions.reduce((acc, { id }) => ({ ...acc, [id]: '' }), {}) // Initialize state dynamically based on questions
  );

  // Handle input changes
  const handleInputChange = (id, value) => {
    setResponses((prevResponses) => ({
      ...prevResponses,
      [id]: value,
    }));
  };

  return (
    <Card sx={{ maxWidth: 800, margin: 'auto', padding: 2, marginBottom: 2 }}>
      <CardContent>
        <Typography variant="h5" component="div" gutterBottom>
          Added Information for Units
        </Typography>
        <Grid container spacing={2}>
          {questions.map(({ id, label, type }) => (
            <Grid item xs={12} key={id}>
              <TextField
                fullWidth
                multiline={type === 'textarea'}
                rows={type === 'textarea' ? 4 : 1}
                variant="outlined"
                label={label}
                value={responses[id] || ''}
                onChange={(e) => handleInputChange(id, e.target.value)}
              />
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AddedInformationForUnits;
