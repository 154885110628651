import React, { useState, useEffect } from "react";
import { Table, Tbody, Td, Thead, Tr } from "react-super-responsive-table";
import classNames from "classnames";
import {
  collection,
  onSnapshot,
  query,
  orderBy,
  getDoc,
  getDocs,
  collectionGroup,
  where,
  doc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import Pagination from "../assets/lists/DynamicTable/Pagination";
import TableSkeleton from "../generals/TableSkeleton";
import {
  Typography,
  Box,
  Divider,
  CardContent,
  Card,
  Tooltip,
} from "@mui/material";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Visibility as VisibilityIcon } from "@mui/icons-material";
import Popup from "../generals/Popup";
import BlueButton from "../assets/forms/BlueButton";
import useUsersState from "../../Context/actions/useUsersState";
import SendLetterPanel from "../PolicyLifecycleManager/UnderwriterPanel/letters/SendLetterPanel";
import useGeneralState from "../../Context/actions/useGeneralState";
import ApprovalButtons from "../assets/forms/ApprovalButtons";

const todosPerPage = 5;

function ToDoList({ loading, setLoading }) {
  const initialSortConfig = {
    key: "dateCreated",
    direction: "asc",
  };

  const [sortConfig, setSortConfig] = useState(initialSortConfig);
  const [searchTerm, setSearchTerm] = useState("");
  const [todoList, setTodoList] = useState([]);

  const parseRawText = async (rawText) => {
    const accountNumberMatch = rawText.match(/Account Number:\s*([\d]+)/);
    const insuredNameMatch = rawText.match(
      /Insured Name:\s*([A-Za-z\s]+?)(?=\s*Policy Number\(s\):)/
    );
    let policyNumbersMatch;
    policyNumbersMatch = rawText.match(
      /Policy Number\(s\):\s*([^\n]+?)(?=\s*Policy Effective Date\(s\):)/
    );
    if (!policyNumbersMatch) {
      policyNumbersMatch = rawText.match(/Policy Number\(s\):\s*([^\n]+)/);
    }
    const policyDatesMatch = rawText.match(
      /Policy Effective Date\(s\):\s*([^\n]+)/
    );
  
    const accountNumber = accountNumberMatch ? accountNumberMatch[1] : "";
    const insuredName = insuredNameMatch ? insuredNameMatch[1].trim() : "";
    const policyNumbers = policyNumbersMatch
      ? policyNumbersMatch[1].split(",").map((policy) => policy.trim())
      : [];
    const policyDates = policyDatesMatch
      ? policyDatesMatch[1].split(",").map((date) => date.trim())
      : [];
  
    const policies = policyNumbers.map((number, index) => {
      const coverage = number.startsWith("2-SS") ? "AL/GL" : "APD/MTC";
      return {
        number,
        date: policyDates[index] || "",
        coverage,
      };
    });
  
    let activePolicy = null;
    const alPolicyNo = policies?.[0]?.number;
    const policiesCollection = collection(db, "policy-applications");
    const q = query(
      policiesCollection,
      where("alPolicyNo", "==", alPolicyNo),
    );
    const policySnapshot = await getDocs(q);
    for (const doc of policySnapshot.docs) {
      const policyData = {
        id: doc.id,
        ...doc.data(),
      };
      if (policyData?.alPolicyNo === alPolicyNo) {
        activePolicy = policyData;
        break;
      }
    }

    let insuredInfo = null
    if (activePolicy?.insuredID && activePolicy?.agencyID) {
      const insuredRef = doc(
        db,
        "agencies",
        activePolicy?.agencyID,
        "insureds",
        activePolicy?.insuredID
      );
      const insuredDoc = await getDoc(insuredRef);
      if (insuredDoc.exists()) {
        insuredInfo = {
          ...insuredDoc.data(),
          id: insuredDoc.id,
        };
      } else {
        console.log('El documento no existe');
      }
    }
  
    return {
      accountNumber,
      insuredName,
      policies,
      activePolicy,
      insuredInfo,
    };
  };  

  useEffect(() => {
    const fetchData = () => {
      setLoading(true);
      const q = query(
        collection(db, "stonemark"),
        orderBy("dateReceived", "desc")
      );
      const unsubscribe = onSnapshot(q, async (querySnapshot) => {
        let todos = [];
        const promises = querySnapshot.docs.map((doc) => {
          return parseRawText(doc.data().rawText)
            .then((parsedText) => {
              const newData = {
                id: doc.id,
                stonemarkAccountNumber: parsedText.accountNumber,
                namedInsured: parsedText.insuredName,
                policies: parsedText.policies,
                activePolicy: parsedText.activePolicy,
                coverage: parsedText.policies[0]?.coverage,
                insuredInfo: parsedText.insuredInfo,
                ...doc.data(),
              };
              if (doc?.data()?.status !== "Deleted") {
                todos.push(newData);
                console.log("new data: ", newData);
              }
            })
            .catch((error) => {
              console.error("Error parsing text:", error);
            });
        });
        await Promise.all(promises);
        setTodoList(todos);
        setLoading(false);
      });
      return () => unsubscribe();
    };
    fetchData();
  }, [setLoading]);

  const handleSort = (key) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key, direction });
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredData = todoList.filter((todo) =>
    `${todo.title} ${todo.description}`
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  const sortedData = [...filteredData].sort((a, b) => {
    if (sortConfig.direction === "asc") {
      return a[sortConfig.key] > b[sortConfig.key] ? 1 : -1;
    } else {
      return a[sortConfig.key] < b[sortConfig.key] ? 1 : -1;
    }
  });

  const [hoveredRow, setHoveredRow] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredRow(index);
  };

  const handleMouseLeave = () => {
    setHoveredRow(null);
  };

  const [currentPage, setCurrentPage] = useState(0);
  const offset = currentPage * todosPerPage;
  const currentItems = sortedData.slice(offset, offset + todosPerPage);
  const pageCount = Math.ceil(filteredData.length / todosPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const navigate = useNavigate();

  const [stoneMarkData, setStoneMarkData] = useState(null);
  const [openChangeSendLetterModal, setOpenChangeSendLetterModal] =
    useState(false);
  const [openCompletionModal, setOpenCompletionModal] = useState(false);

  const handleChangeSendLetterModal = (todo) => {
    setStoneMarkData(todo?.id ? todo : null);
    setOpenChangeSendLetterModal(!openChangeSendLetterModal);
  };

  const handleCompletionModal = (todo) => {
    setStoneMarkData(todo?.id ? todo : null);
    setOpenCompletionModal(!openCompletionModal);
  };

  const { user: userInfo } = useUsersState();
  const { controlToast } = useGeneralState();

  const submitCompletion = async (e) => {
    e.preventDefault();
    if (stoneMarkData) {
      try {
        const stonemarkRef = doc(db, "stonemark", stoneMarkData?.id);
        await updateDoc(stonemarkRef, {
          status: "Deleted",
        });
        controlToast(
          true,
          "Task was succesfully marked as complete",
          "success"
        );
        handleCompletionModal();
      } catch (error) {
        controlToast(true, "There was an error in the service", "error");
        console.error("Error updating stonemark", error);
      }
    }
  };

  const shouldOfferMarkCompleteForArray = (policies) => {
    const hasInvalidPolicy =
      policies?.some((policy) => {
        if (typeof policy?.number === "string") {
          return (
            !policy?.number?.startsWith("2-SSM") &&
            !policy?.number?.startsWith("2-SSD")
          );
        }
        return true;
      }) || false;
    return hasInvalidPolicy;
  };

  const noToDosAvailable = todoList.length === 0;

  return (
    <main>
      {stoneMarkData?.activePolicy ? (
        <Popup
          isOpen={openCompletionModal}
          onRequestClose={handleCompletionModal}
          maxWidth="50%"
        >
          <p className="text-2xl font-semibold mt-4 mb-7 text-center text-red-700">
            Are you sure you want to mark as complete this task?
          </p>
          <ApprovalButtons
            onApprove={submitCompletion}
            onDecline={handleCompletionModal}
            titleAccept={"SUBMIT"}
            titleDecline={"CANCEL"}
          />
        </Popup>
      ) : null}
      {stoneMarkData?.activePolicy ? (
        <Popup
          overflow="scroll"
          maxWidth="50%"
          isOpen={openChangeSendLetterModal}
          onRequestClose={handleChangeSendLetterModal}
        >
          <SendLetterPanel
            policyID={stoneMarkData?.activePolicy?.id}
            formData={stoneMarkData?.activePolicy}
            userInfo={userInfo}
            insuredInfo={stoneMarkData?.insuredInfo}
            agencyID={userInfo?.agencyID}
            display={true}
            setDisplay={() => {}}
            setDisplayOthers={() => {}}
            initialLetterType={stoneMarkData?.type}
            stoneMarkData={stoneMarkData}
            closeModal={() => {
              handleChangeSendLetterModal();
              controlToast(true, "Letter was sent successfully", "success");
            }}
          />
        </Popup>
      ) : null}
      {loading ? (
        <TableSkeleton rows={5} columns={5} />
      ) : (
        <Box sx={{ maxWidth: 700 }}>
          <Card sx={{ marginBottom: 4 }}>
            <CardContent>
              <Typography variant="h6" component="h6" sx={{ mb: 2 }}>
                Stonemark Notifications
              </Typography>
              <Divider sx={{ my: 2 }} />
              {noToDosAvailable ? (
                <p>No notifications available at this time. Check back soon.</p>
              ) : (
                <>
                  <Table>
                    <Thead>
                      <Tr>
                        <Td
                          className={classNames(
                            "bg-[#072a48] text-white cursor-pointer rounded-tl-md",
                            {
                              "hover:bg-[#072a48]": sortConfig.key !== "title",
                              "bg-blue-500": sortConfig.key === "title",
                            }
                          )}
                          onClick={() => handleSort("title")}
                        >
                          Type
                        </Td>
                        <Td
                          className={classNames(
                            "bg-[#072a48] text-white cursor-pointer",
                            {
                              "hover:bg-[#072a48]":
                                sortConfig.key !== "coverage",
                              "bg-blue-500": sortConfig.key === "coverage",
                            }
                          )}
                          onClick={() => handleSort("coverage")}
                        >
                          Coverage
                        </Td>
                        <Td
                          className={classNames(
                            "bg-[#072a48] text-white cursor-pointer",
                            {
                              "hover:bg-[#072a48]":
                                sortConfig.key !== "description",
                              "bg-blue-500": sortConfig.key === "description",
                            }
                          )}
                          onClick={() => handleSort("description")}
                        >
                          Named Insured
                        </Td>
                        <Td
                          className={classNames(
                            "bg-[#072a48] text-white cursor-pointer rounded-tr-md",
                            {
                              "hover:bg-[#072a48]":
                                sortConfig.key !== "dateCreated",
                              "bg-blue-500": sortConfig.key === "dateCreated",
                            }
                          )}
                          onClick={() => handleSort("dateCreated")}
                        >
                          Details
                        </Td>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {currentItems.map((todo, index) => (
                        <Tr
                          key={todo.id}
                          onMouseEnter={() => handleMouseEnter(index)}
                          onMouseLeave={handleMouseLeave}
                          style={{
                            cursor: "pointer",
                            backgroundColor:
                              hoveredRow === index ? "#e6f7ff" : "inherit",
                          }}
                        >
                          <Td>{todo.type}</Td>
                          <Td>{todo.coverage}</Td>
                          <Td>{todo.namedInsured}</Td>
                          <Td>
                            <DropdownButton
                              variant=""
                              drop="down"
                              style={{ padding: 0 }}
                              title={
                                <div className="flex">
                                  <p className="mr-2">View Details</p>
                                  <VisibilityIcon className="mt-1" />
                                </div>
                              }
                              onClick={(e) => e.stopPropagation()}
                            >
                              <Dropdown.Item
                                onClick={(e) => {
                                  e.stopPropagation();
                                  window.open(
                                    todo.attachmentGoogleDrive,
                                    "_blank"
                                  );
                                }}
                              >
                                View Pdf
                              </Dropdown.Item>
                              {todo.activePolicy?.id ? (
                                <Dropdown.Item
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleChangeSendLetterModal(todo);
                                  }}
                                >
                                  Send Letter
                                </Dropdown.Item>
                              ) : null}
                              {shouldOfferMarkCompleteForArray(
                                todo?.policies
                              ) ? (
                                <Dropdown.Item
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleCompletionModal(todo);
                                  }}
                                >
                                  Mark Complete
                                </Dropdown.Item>
                              ) : null}
                              <Tooltip
                                title={
                                  <Box>
                                    <Typography variant="body2">
                                      Policies:
                                    </Typography>
                                    <ul>
                                      {todo.policies.map((policy, index) => (
                                        <li key={index}>
                                          {policy.number} 
                                          {policy.date && ` - ${policy.date}`}
                                        </li>
                                      ))}
                                    </ul>
                                  </Box>
                                }
                              >
                                <Dropdown.Item>View Policies</Dropdown.Item>
                              </Tooltip>
                            </DropdownButton>
                            {todo.dateReceived.toString().slice(0, 11)}
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                  <Pagination
                    pageCount={pageCount}
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                  />
                </>
              )}
            </CardContent>
          </Card>
        </Box>
      )}
    </main>
  );
}

export default ToDoList;
