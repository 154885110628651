import React from 'react'
import { Container, Grid, Typography, Box } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { styled } from "@mui/system";


export default function CountIcons(
    {driverCount, powerUnitCount, trailerCount}
) {
    
  const CounterItemWrapper = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    backgroundColor: "#0c4a6e",
    padding: "5px",
    height: "100%",
    boxShadow: "none",
    width: "100%",
  }));

  const IconWrapper = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    marginRight: "1rem",
    marginLeft: "1rem",
  });
  return (
    <Container>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <CounterItemWrapper
                  sx={{
                    backgroundColor: "#2C3E50",
                    padding: "12px",
                    borderRadius: "8px",
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    textAlign: "center",
                    "&:hover": {
                      transform: "translateY(-2px)",
                      boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.15)",
                      transition: "0.2s ease-in-out",
                    },
                  }}
                >
                  <IconWrapper
                    sx={{
                      marginBottom: "4px",
                    }}
                  >
                    <PersonIcon sx={{ fontSize: 32, color: "#3498DB" }} />
                  </IconWrapper>
                  <Typography
                    variant="body1"
                    component="div"
                    sx={{
                      color: "white",
                      fontWeight: 500,
                      fontSize: "20px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {driverCount}
                  </Typography>
                </CounterItemWrapper>
              </Grid>
              <Grid item xs={12} md={4}>
                <CounterItemWrapper
                  sx={{
                    backgroundColor: "#2C3E50",
                    padding: "12px",
                    borderRadius: "8px",
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    textAlign: "center",
                    "&:hover": {
                      transform: "translateY(-2px)",
                      boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.15)",
                      transition: "0.2s ease-in-out",
                    },
                  }}
                >
                  <IconWrapper
                    sx={{
                      marginBottom: "4px",
                    }}
                  >
                    <DirectionsBusIcon
                      sx={{ fontSize: 32, color: "#3498DB" }}
                    />
                  </IconWrapper>
                  <Typography
                    variant="body1"
                    component="div"
                    sx={{
                      color: "white",
                      fontWeight: 500,
                      fontSize: "20px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {powerUnitCount}
                  </Typography>
                </CounterItemWrapper>
              </Grid>
              <Grid item xs={12} md={4}>
                <CounterItemWrapper
                  sx={{
                    backgroundColor: "#2C3E50",
                    padding: "12px",
                    borderRadius: "8px",
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    textAlign: "center",
                    "&:hover": {
                      transform: "translateY(-2px)",
                      boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.15)",
                      transition: "0.2s ease-in-out",
                    },
                  }}
                >
                  <IconWrapper
                    sx={{
                      marginBottom: "4px",
                    }}
                  >
                    <LocalShippingIcon
                      sx={{ fontSize: 32, color: "#3498DB" }}
                    />
                  </IconWrapper>
                  <Typography
                    variant="body1"
                    component="div"
                    sx={{
                      color: "white",
                      fontWeight: 500,
                      fontSize: "20px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {trailerCount}
                  </Typography>
                </CounterItemWrapper>
              </Grid>
            </Grid>
          </Container>
  )
}
