import React from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import updatePolicyField from "../../../utils/updatePolicyField";

export default function QuoteSelector({
  policyID,
  formData,
  setFormData,
  label,
  quoteID,
  quoteData,
  applyAdjustments,
}) {
  const quoteSelected = quoteID === formData.selectedQuote;
  return (
    <FormControlLabel
      control={
        <Checkbox
          id={quoteID}
          checked={quoteSelected}
          onChange={(e) => {
            if (!quoteSelected) {
              updatePolicyField(policyID, "selectedQuote", quoteID);
              applyAdjustments(e, quoteData);
            }
          }}
          style={{
            fontSize: "20px",
            color: quoteSelected ? "#116582" : "#072a48",
          }}
          className="larger-checkbox"
          size="large"
        />
      }
      className="ml-2"
    />
  );
}
