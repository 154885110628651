import React, { useState } from "react";
import {
  Button,
  TextField,
  IconButton,
  Typography,
  Box,
  useMediaQuery,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { doc } from "firebase/firestore";
import { db } from "../../../firebase";
import requestBind from "../../utils/requestBind";
import Breadcrumbs from "../../assets/Breadcrumbs";
import { UserAuth } from "../../../Context/AuthContent";
import useUsersState from "../../../Context/actions/useUsersState";
import ApprovalButtons from "../../assets/forms/ApprovalButtons";
import Popup from "../../generals/Popup";

const BindPolicyModal = ({
  open,
  onClose,
  policyID,
  formData,
  insuredInfo,
  bindFunction
}) => {
  const policyRef = doc(db, "policy-applications", policyID);
  const { user: userAuth } = UserAuth();
  const { user: userState } = useUsersState();

  const [comments, setComments] = useState([""]);

  const handleAddComment = () => {
    setComments([...comments, ""]);
  };

  const handleChangeComment = (index, value) => {
    const updatedComments = [...comments];
    updatedComments[index] = value;
    setComments(updatedComments);
  };

  const handleRemoveComment = (index) => {
    const updatedComments = [...comments];
    updatedComments.splice(index, 1);
    setComments(updatedComments);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Handle submission logic here
    bindFunction();
    setComments([""]);
    onClose();
  };

  const isMobileScreen = useMediaQuery("(max-width: 600px)");

  const breadcrumbs = [
    { label: "Policy" },
    { label: "Binding" },
    { label: "Bind Policy" },
  ];

  return (
    <Popup
      isOpen={open}
      onRequestClose={onClose}
      maxWidth={isMobileScreen ? "100%" : "400px"}
      zIndex={1000}
      contentLabel="Bind Policy"
    >
      <Box
        sx={{
          maxHeight: "100vh",
          overflowY: "auto",
          width: "100%",
          borderRadius: isMobileScreen ? 0 : 4,
        }}
      >
        <Breadcrumbs items={breadcrumbs} />
        <Typography className="mt-2" variant="h6" gutterBottom>
          Bind Policy
        </Typography>
        <p className="my-3">
          Continuing will bind the policy with the selected quote premiums. Click the submit button to proceed.
        </p>
        <form onSubmit={handleSubmit}>
          <ApprovalButtons
            onApprove={null}
            onDecline={onClose}
            titleAccept={"Submit"}
            titleDecline={"Cancel"}
            disabledAccept={false}
            disabledDecline={false}
            handleAsSubmit={true}
          />
        </form>
      </Box>
    </Popup>
  );
};

export default BindPolicyModal;
