import { collection, doc, getDocs, query, setDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { generateRandomUid } from "../../utils/helpers";

export const getLossRunsRequest = async () => {
  try {
    const q = query(collection(db, "loss-run-requests"));
    const queryData = await getDocs(q);
    const lossRuns = queryData.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id
    }));
    return lossRuns
  } catch (error) {
    throw error
  }
};

export const createLossRunRequest = async ({
  uid,
  namedInsured,
  status,
  authorization,
  comments,
  name,
  company,
  email,
  phone,
  policyFields,
}) => {
  try {
    await setDoc(doc(db, "loss-run-requests", generateRandomUid(uid)), {
      namedInsured,
      status,
      authorization,
      comments,
      name,
      company,
      email,
      phone,
      policyFields,
    });
  } catch (error) {
    throw error;
  }
};

export const setLossRunRequest = async (payload, id) => {
  try {
    await setDoc(doc(db, "loss-run-requests", id), payload, { merge: true });
  } catch (error) {
    throw error;
  }
};