import React from "react";
import { Button, Grid, Typography, Box, Select, MenuItem, InputLabel, FormControl } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import RestoreIcon from "@mui/icons-material/Restore";
import WarningIcon from "@mui/icons-material/Warning";

const CustomActionButtons = ({ setProofFiles, handleChangeLetterType }) => {
  const [selectedOption, setSelectedOption] = React.useState("");

  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedOption(value);
    setProofFiles([]);
    handleChangeLetterType(event, value);
  };

  return (

        <FormControl fullWidth>
          <InputLabel id="action-buttons-select-label">Select Action</InputLabel>
          <Select
            labelId="action-buttons-select-label"
            value={selectedOption}
            onChange={handleChange}
            displayEmpty
            inputProps={{ "aria-label": "Select Action" }}
            sx={{
              borderRadius: "20px",
              boxShadow: 3,
              textTransform: "none",
              "& .MuiSelect-select": {
                display: "flex",
                alignItems: "center",
              },
            }}
          >
            <MenuItem value="NOC" sx={{ display: 'flex', alignItems: 'center' }}>
              <CancelIcon sx={{ fontSize: "1.5rem", marginRight: "10px" }} />
              <Typography variant="h6">Cancellation</Typography>
            </MenuItem>
            <MenuItem value="NOR" sx={{ display: 'flex', alignItems: 'center' }}>
              <RestoreIcon sx={{ fontSize: "1.5rem", marginRight: "10px" }} />
              <Typography variant="h6">Reinstatement</Typography>
            </MenuItem>
            <MenuItem value="PNONR" sx={{ display: 'flex', alignItems: 'center' }}>
              <WarningIcon sx={{ fontSize: "1.5rem", marginRight: "10px" }} />
              <Typography variant="h6">Prov. Non-Renewal</Typography>
            </MenuItem>
          </Select>
        </FormControl>
  );
};

export default CustomActionButtons;
