import React from "react";
import Checkbox from "../assets/forms/Checkbox";
import StatusOptions from "./StatusOptions";
import { Card, CardContent } from "@mui/material";

export default function SearchFilters({
  currentFilters,
  setCurrentFilters,
  options,
}) {
  return (
    <Card className="mb-2">
      <CardContent
        style={{ cursor: "pointer", fontSize: "14px", padding: "4px" }}
      >
        <div className="pl-3">
          <StatusOptions
            currentFilters={currentFilters}
            setCurrentFilters={setCurrentFilters}
            options={options}
          />
        </div>
      </CardContent>
    </Card>
  );
}
