import React from "react";
import Button from "@mui/material/Button";
import ratePowerUnitGeneral from "../../utils/rateUnits/ratePowerUnitGeneral";

export default function RatePowerUnitEndorsement({
  truckList,
  policyID,
  endoID,
  setSuccess,
  singleUnit,
  formData,
}) {

  const cleanTruckListFunction = (truckList) => {
    let cleanTruckList;
    if (Array.isArray(truckList)) {
      cleanTruckList = truckList.map(truck => {
        const cleanedEntries = Object.entries(truck).filter(([key, value]) => value !== undefined);
        return Object.fromEntries(cleanedEntries);
      });
    } else {
      cleanTruckList = truckList;
    }
    return cleanTruckList;
  };

  const trucks = cleanTruckListFunction(truckList)

  return (
    <Button
      variant="contained"
      color="primary"
      fullWidth
      style={{ borderRadius: "0" }}
      onClick={(e) =>
        ratePowerUnitGeneral(
          e,
          trucks,
          policyID,
          singleUnit,
          endoID,
          setSuccess
        )
      }
    >
      Rate Power Units
    </Button>
  );
}
