import { Pagination, Stack } from '@mui/material';

function CustomPagination({ pageCount, currentPage, handlePageChange }) {

  const handleChange = (_, value) => {
    handlePageChange(value - 1);
  };

  if (pageCount === 0) return null

  return (
    <div className='w-full flex justify-center mt-4 overflow-x-auto'>
      <Stack spacing={5} sx={{ '& > *': { flex: 'none' } }}>
        <Pagination
          count={pageCount}
          page={currentPage + 1}
          onChange={handleChange}
          shape="rounded"
          siblingCount={1}
          boundaryCount={1}
          sx={{
            '& .MuiPaginationItem-root': {
              color: '#072a48',
              backgroundColor: '#e4f3ff',
              minWidth: 'auto',
              padding: '6px 10px',
            },
            '& .MuiPaginationItem-page.Mui-selected': {
              backgroundColor: '#072a48',
              color: '#ffffff',
            },
            '& .MuiPagination-ul': {
              display: 'flex',
              flexWrap: 'nowrap',
              justifyContent: 'center',
              whiteSpace: 'nowrap',
              '& li': {
                margin: '0 0 10px',
              },
            },
          }}
        />
      </Stack>
    </div>
  );
}

export default CustomPagination;