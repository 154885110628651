import React, { useEffect, useState } from "react";
import {
  getDocs,
  addDoc,
  collection,
  query,
  deleteDoc,
  doc,
  getDoc,
  orderBy,
} from "firebase/firestore";
import { db } from "../../firebase";
import "react-datepicker/dist/react-datepicker.css";
import "../styling/app.css";
import { getAuth } from "firebase/auth";
import { Table, Thead, Tbody, Tr, Td } from "react-super-responsive-table";
import useUsersState from "../../Context/actions/useUsersState";
import { confirmDeletePromptGlobal } from "../../layout/ConfirmDeleteProvider";

function ChangeLog() {
  const auth = getAuth();
  const { user } = useUsersState();
  const userID = user.uid;

  const [change, setChange] = useState("");

  const submitChange = async () => {
    await addDoc(collection(db, "changes"), {
      createdOn: new Date(),
      authorName: auth.currentUser.displayName,
      authorID: auth.currentUser.uid,
      change: change,
    });
    getChanges();
    setChange("");
  };

  const deleteChange = async (docID) => {
    let verify = await confirmDeletePromptGlobal(
      "You are about to delete an Change! If you are sure, confirm the action"
    );
    if (verify === "DELETE") {
      await deleteDoc(doc(db, "changes", docID));
      getChanges();
    }
  };

  const [changeList, setChangeList] = useState([]);
  const [gotChanges, setGotChanges] = useState(false);

  const getChanges = async () => {
    setGotChanges(false);

    const q = query(collection(db, "changes"), orderBy("createdOn"));
    const queryData = await getDocs(q);
    const queryResults = queryData.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    setChangeList(queryResults.reverse());
    setGotChanges(true);
  };

  const convertDate = (secs) => {
    const output = new Date(secs * 1000);
    return output.toDateString();
  };

  const convertDateLong = (secs) => {
    const output = new Date(secs * 1000);
    return output.toString();
  };

  useEffect(() => {
    if (gotChanges === false) getChanges();
  }, [userID, gotChanges, user.role]);

  return (
    <>
      <main className="max-w-[800px] mx-auto">
        <h1 className="text-[#072a48] text-2xl font-bold pt-3 mb-2">Changes</h1>
        {user.role === "Admin" && (
          <section className="bg-gray-200 p-3 rounded-md mb-5">
            <h2 className="text-xl font-medium mb-2">New Change</h2>
            <input
              type="text"
              placeholder="Change Description"
              value={change}
              onChange={(e) => setChange(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") submitChange();
              }}
              className="text-input w-full"
            />

            <button
              onClick={() => {
                setChange("");
              }}
              className="button-1"
            >
              Cancel
            </button>
            <button onClick={submitChange} className="button-2">
              Submit
            </button>
          </section>
        )}
        <Table className="data-table w-full">
          <Thead>
            <Tr className="data-table-header">
              <Td>Log Date</Td>
              <Td width="70%">Change Description</Td>
              {user.role === "Admin" && <Td>Actions</Td>}
            </Tr>
          </Thead>
          <Tbody>
            {changeList.map((change, i) => {
              return (
                <Tr
                  key={i}
                  title={convertDateLong(change.createdOn.seconds)}
                  className="mb-2"
                >
                  <Td>{convertDate(change.createdOn.seconds)}</Td>
                  <Td>{change.change}</Td>
                  {user.role === "Admin" && (
                    <Td>
                      <button
                        onClick={(e) => deleteChange(change.id)}
                        className="text-[#072a48]"
                      >
                        Delete
                      </button>
                    </Td>
                  )}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </main>
    </>
  );
}

export default ChangeLog;
