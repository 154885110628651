import React, { useState } from 'react';
import { Card, CardContent, Typography, Grid, TextField } from '@mui/material';

const Audits = () => {
  // Define questions
  const questions = [
    { id: 'complianceReviewOrDOTInvestigation', label: 'Compliance review or DOT investigation?', type: 'text' },
    { id: 'onlineOrOnSite', label: 'Online or on site?', type: 'text' },
    { id: 'reasonWhy', label: 'Reason why.', type: 'textarea' },
    { id: 'officerReview', label: 'What did the officer review?', type: 'textarea' },
    { id: 'driversOrFilesReviewed', label: 'How many drivers or files were reviewed?', type: 'text' },
    { id: 'documentsLeftByOfficer', label: 'Did the officer leave documents?', type: 'text' },
    { id: 'comments', label: 'Comments', type: 'textarea' },
    { id: 'companyCorrectiveActions', label: 'Company corrective actions.', type: 'textarea' },
    { id: 'sentCorrectiveActions', label: 'Sent it already?', type: 'text' },
    { id: 'writtenPolicies', label: 'Written policies regarding BASIC violations and write-ups signed by drivers.', type: 'textarea' },
    { id: 'driverQualificationFileChecklist', label: 'Driver Qualification File Checklist 49 CFR 391. Sample of DQ (Hiring process requirements and post-accident procedure)', type: 'textarea' },
    { id: 'vehicleMaintenanceFile', label: 'Vehicle maintenance file.', type: 'textarea' },
  ];

  // State to manage responses
  const [responses, setResponses] = useState(
    questions.reduce((acc, { id }) => ({ ...acc, [id]: '' }), {}) // Initialize state dynamically based on questions
  );

  // Handle input changes
  const handleInputChange = (id, value) => {
    setResponses((prevResponses) => ({
      ...prevResponses,
      [id]: value,
    }));
  };

  return (
    <Card sx={{ maxWidth: 800, margin: 'auto', padding: 2, marginBottom: 2 }}>
      <CardContent>
        <Typography variant="h5" component="div" gutterBottom>
          Audits
        </Typography>
        <Grid container spacing={2}>
          {questions.map(({ id, label, type }) => (
            <Grid item xs={12} key={id}>
              <TextField
                fullWidth
                multiline={type === 'textarea'}
                rows={type === 'textarea' ? 4 : 1}
                variant="outlined"
                label={label}
                value={responses[id] || ''}
                onChange={(e) => handleInputChange(id, e.target.value)}
              />
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Audits;
