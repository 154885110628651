import React from "react";
import { Table, Thead, Tbody, Tr, Td } from "react-super-responsive-table";
import TableSkeleton from "../../../generals/TableSkeleton";

export default function DriverMapList({ driverMapData, driverMapSkeletonLoader, licenseCountry }) {
  const profileDefinition = driverMapData?.payload?.profileDefinition;
  const basicInformation = driverMapData?.payload?.basicInformation;
  const score = driverMapData?.payload?.score;
  const riskFactor = driverMapData?.payload?.riskFactor;
  const crashes = driverMapData?.payload?.crashes;
  const sct = driverMapData?.payload?.sct;
  const training = driverMapData?.payload?.training;
  const policy = driverMapData?.payload?.policy;
  const inspections = driverMapData?.payload?.inspections;

  return (
    <div>
      {driverMapSkeletonLoader ? (
        <div className="mb-3">
          <TableSkeleton rows={2} columns={6} />
        </div>
      ) : (
        <>
          <Table>
            <Thead>
              <Tr>
                <Td className="bg-[#072a48] text-white" colSpan={6}>Profile Definition</Td>
              </Tr>
            </Thead>
            <Tbody>
              <Tr><Td colSpan={6}>Name: {profileDefinition?.name}</Td></Tr>
              <Tr><Td colSpan={6}>DOB: {profileDefinition?.dob}</Td></Tr>
              <Tr><Td colSpan={6}>License: {profileDefinition?.licenseMX}</Td></Tr>
              <Tr><Td colSpan={6}>Expires On: {profileDefinition?.expiresOn}</Td></Tr>
            </Tbody>

            <Thead>
              <Tr>
                <Td className="bg-[#072a48] text-white" colSpan={6}>Basic Information</Td>
              </Tr>
            </Thead>
            <Tbody>
              <Tr><Td colSpan={6}>License Class: {basicInformation?.licenseClass}</Td></Tr>
              <Tr><Td colSpan={6}>Experience: {basicInformation?.experience}</Td></Tr>
              <Tr><Td colSpan={6}>CURP: {basicInformation?.curp}</Td></Tr>
              <Tr><Td colSpan={6}>Score Status: {basicInformation?.scoreStatus}</Td></Tr>
              <Tr><Td colSpan={6}>No. Medicina Preventiva: {basicInformation?.noMedicinaPreventiva}</Td></Tr>
            </Tbody>

            <Thead>
              <Tr>
                <Td className="bg-[#072a48] text-white" colSpan={6}>Score</Td>
              </Tr>
            </Thead>
            <Tbody>
              <Tr><Td colSpan={6}>Score Points: {score?.scorePoints}</Td></Tr>
              <Tr><Td colSpan={6}>Violations: {score?.violations}</Td></Tr>
              <Tr><Td colSpan={6}>Claims: {score?.claims}</Td></Tr>
              <Tr><Td colSpan={6}>Crashes: {score?.crashes}</Td></Tr>
              <Tr><Td colSpan={6}>Trainings: {score?.training}</Td></Tr>
            </Tbody>

            <Thead>
              <Tr>
                <Td className="bg-[#072a48] text-white" colSpan={6}>Risk Factor</Td>
              </Tr>
            </Thead>
            <Tbody>
              <Tr><Td colSpan={6}>Frequency: {riskFactor?.frequency}</Td></Tr>
              <Tr><Td colSpan={6}>Severity: {riskFactor?.severity}</Td></Tr>
              <Tr><Td colSpan={6}>Combined: {riskFactor?.combined}</Td></Tr>
            </Tbody>

            <Thead>
              <Tr>
                <Td className="bg-[#072a48] text-white" colSpan={6}>Crashes</Td>
              </Tr>
            </Thead>
            <Tbody>
              <Tr><Td colSpan={6}>Crashes: {crashes?.crashes}</Td></Tr>
              <Tr><Td colSpan={6}>Fatalities: {crashes?.fatalities}</Td></Tr>
              <Tr><Td colSpan={6}>Injuries: {crashes?.injuries}</Td></Tr>
            </Tbody>

            {licenseCountry === "MX" ?
              <>
                <Thead>
                  <Tr>
                    <Td className="bg-[#072a48] text-white" colSpan={6}>SCT</Td>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr><Td colSpan={6}>Effective From: {sct?.effectiveFrom}</Td></Tr>
                  <Tr><Td colSpan={6}>Effective Until: {sct?.effectiveUntil}</Td></Tr>
                  <Tr><Td colSpan={6}>Effective Fit: {sct?.fit}</Td></Tr>
                  <Tr><Td colSpan={6}>Effective Exam Type: {sct?.examType}</Td></Tr>
                </Tbody>
              </> : null
            }

            <Thead>
              <Tr>
                <Td className="bg-[#072a48] text-white" colSpan={6}>Training</Td>
              </Tr>
            </Thead>
            {Array.isArray(training) && training?.length > 0 ?
              <Thead>
                <Tr>
                  <Td colSpan={4} className="bg-[#79aedc] text-white">Training Name</Td>
                  <Td colSpan={2} className="bg-[#79aedc] text-white">Completion Date</Td>
                </Tr>
              </Thead> : null
            }
            <Tbody>
              {Array.isArray(training) && training?.length > 0 ? (
                training?.map((item, index) => (
                  <Tr key={index}>
                    <Td colSpan={4}>{item?.training}</Td>
                    <Td colSpan={2}>{item?.completionDate}</Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan={6}>No training records</Td>
                </Tr>
              )}
            </Tbody>

            <Thead>
              <Tr>
                <Td className="bg-[#072a48] text-white" colSpan={6}>Policy</Td>
              </Tr>
            </Thead>
            {Array.isArray(policy) && policy?.length > 0 ?
              <Thead>
                <Tr>
                  <Td colSpan={2} className="bg-[#79aedc] text-white">Policy Number</Td>
                  <Td colSpan={2} className="bg-[#79aedc] text-white">DOT</Td>
                  <Td colSpan={2} className="bg-[#79aedc] text-white">Insured Name</Td>
                </Tr>
              </Thead> : null
            }
            <Tbody>
              {Array.isArray(policy) && policy?.length > 0 ? (
                policy?.map((item, index) => (
                  <Tr key={index}>
                    <Td colSpan={2}>{item?.policyNbr}</Td>
                    <Td colSpan={2}>{item?.dot}</Td>
                    <Td colSpan={2}>{item?.insuredName}</Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan={6}>No policy records</Td>
                </Tr>
              )}
            </Tbody>
            
            <Thead>
              <Tr>
                <Td className="bg-[#072a48] text-white" colSpan={6}>Inspections</Td>
              </Tr>
            </Thead>
            {Array.isArray(inspections) && inspections?.length > 0 ?
              <Thead>
                <Tr>
                  <Td className="bg-[#79aedc] text-white">Report No</Td>
                  <Td className="bg-[#79aedc] text-white">Eventuality</Td>
                  <Td className="bg-[#79aedc] text-white">Expedition Date</Td>
                  <Td className="bg-[#79aedc] text-white">Eventuality Group</Td>
                  <Td className="bg-[#79aedc] text-white">Status</Td>
                  <Td className="bg-[#79aedc] text-white">Description</Td>
                </Tr>
              </Thead>
              : null
            }
            <Tbody>
              {Array.isArray(inspections) && inspections?.length > 0 ? (
                inspections?.map((inspection, index) => (
                  <Tr key={index}>
                    <Td>{inspection?.reportNo}</Td>
                    <Td>{inspection?.eventuality}</Td>
                    <Td>{inspection?.expeditionDate}</Td>
                    <Td>{inspection?.eventualityGroup}</Td>
                    <Td>{inspection?.status}</Td>
                    <Td>{inspection?.description}</Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan={6}>No inspection records</Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </>
      )}
    </div>
  );
}