import { doc, getDoc, updateDoc } from "firebase/firestore";
import { auth, db } from "../../firebase";
import policyStatusChangeNotification from "./notifications/policyStatusChangeNotification";
import updateSystemLog from "./updateSystemLog";

const sendQuote = async (
  e,
  formData,
  policyID,
  userAuth,
  userState,
  insuredInfo,
  email,
  selectedQuote
) => {
  e.preventDefault();
  try {
    const sourceDocRef = doc(db, "policy-applications", policyID);
    const sourceDocSnapshot = await getDoc(sourceDocRef);
    const documentType = "Quote Request";
    const status = "Fulfilled";
    const policyNumber = sourceDocSnapshot.data().alPolicyNo || "";
    const agencyID = sourceDocSnapshot.data().agencyID;
    await updateDoc(doc(db, "policy-applications", policyID), {
        status,
        documentType
    });
    await policyStatusChangeNotification(
        documentType,
        policyNumber,
        status,
        policyID,
        agencyID,
        formData,
        userAuth,
        userState,
        insuredInfo,
        'sendQuote',
        email,
        selectedQuote
    );
    await updateSystemLog(
        auth,
        policyID,
        "Quote Request Fulfilled for " + insuredInfo.company + ".",
        "Quote Request Fulfilled",
        { agencyID, policyID }
    );
  } catch (error) {
    throw error;
  }
};

export default sendQuote;