import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../../firebase";

const getAgentRecipientList = async (agencyID, fieldName) => {
  const docRef = doc(db, "agencies", agencyID);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const values = [];
    docSnap.data()[fieldName].forEach((recipientData) => {
      const email = JSON.parse(recipientData).email;
      values.push(email);
    });
    return values;
  } else {
    console.log("No such document!");
  }
};

export default getAgentRecipientList;
