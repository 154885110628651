import ApprovalButtons from "../../assets/forms/ApprovalButtons";
import DynamicTable from "../../assets/lists/DynamicTable";
import FormPopup from "../../generals/Popup/FormPopup";
import useTrailersLists from "./useTrailersList";

export default function TrailerList(props) {
  
  const {
    openModal,
    handleCloseModal,
    formSettings,
    modalSubmitting,
    columns,
    tableDataInDeletionMode,
    setTableDataInDeletionMode,
    handleSubmit,
    showItem,
    filterKeys,
    tableData,
    deletionMode,
    tableDataShowingDeletion,
    setTableDataShowingDeletion,
    formData,
    isAtLeastOneSelectedInDeletedMode,
    setDeletionMode
  } = props

  return (
    <div>
      <FormPopup
        isOpen={openModal}
        onRequestClose={handleCloseModal}
        title='Select the expiration date of the trailer'
        formSettings={formSettings}
        loading={modalSubmitting}
      />
      <DynamicTable
        deletionMode={deletionMode}
        formData={formData}
        columns={columns}
        tableData={
          !deletionMode ?
            tableData : tableDataShowingDeletion
        }
        setTableData={
          !deletionMode ? () => {} :
            setTableDataShowingDeletion
        }
        alternativeTableData={
          !deletionMode ? [] :
            tableDataInDeletionMode
        }
        setAlternativeTableData={
          !deletionMode ? () => {} :
            setTableDataInDeletionMode
        }
        deleteThisItem={handleSubmit}
        onClickItem={showItem}
        filterKeys={filterKeys}
        // name="trailers"
      />
      {deletionMode ?
        <ApprovalButtons
          onApprove={(e) => {
            handleSubmit(e, 'multiple')
          }}
          onDecline={(e) => {
            e.preventDefault()
            setTableDataInDeletionMode([])
            setTableDataShowingDeletion([])
            setDeletionMode(!Boolean(deletionMode))
          }}
          titleAccept="Submit"
          titleDecline="Cancel"
          disabledAccept={!isAtLeastOneSelectedInDeletedMode}
        /> : null
      }
      {deletionMode ?
        <DynamicTable
          deletionMode={deletionMode}
          formData={formData}
          deleteMode={true}
          columns={columns}
          tableData={tableDataInDeletionMode}
          setTableData={setTableDataInDeletionMode}
          alternativeTableData={tableDataShowingDeletion}
          setAlternativeTableData={setTableDataShowingDeletion}
          deleteThisItem={handleSubmit}
          onClickItem={showItem}
          filterKeys={filterKeys}
          // name="trailersDelete"
        /> : null
      }
    </div>
  );
}
