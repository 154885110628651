import React from "react";
import Breadcrumbs from "../../assets/Breadcrumbs";
import TaskTable from "./TaskTable";
// import updatePolicyField from "../../utils/updatePolicyField";

export default function UnderwriterTasks({ formData, insuredInfo, driverList }) {
  const breadcrumbs = [
    { label: formData.documentType },
    { label: "Underwriting Tasks" },
  ];
  return (
    <div>
      <Breadcrumbs items={breadcrumbs} />
      <h1 className="text-3xl mt-2 font-semibold mb-4">Underwriting Tasks</h1>

      <TaskTable
        policyID={formData.id}
        formData={formData}
        insuredInfo={insuredInfo}
        driverList={driverList}
      />
    </div>
  );
}
