const RequestExceptions = ({ endorsement, color = 'gray', center, mustBeApproved }) => {
  return (
    <>
      {endorsement?.requestException && (
        (
          mustBeApproved &&
          (
            endorsement?.status === 'Approved' ||
            endorsement?.status === 'Issued'
          )
        ) ||
        (
          !mustBeApproved &&
          endorsement?.documentType === 'Exception Request' &&
          endorsement?.status !== 'Issued' &&
          endorsement?.status !== 'Approved'
        )
      ) ? (
        <>
          <p className={`text-lg font-semibold ${center ? 'mt-4' : ''} mb-2 ${
            center ? `text-${color}-700 text-center` : ''}`
          }>
            Exception Request
          </p>
          <div className="mb-4">
            {Object.entries(endorsement?.requestException).map(
              (requestException) => {
                const [key, value] = requestException;
                if (!value || key === "other") return null;
                const keyManaged =
                  key === "other-text-input" ? "Reasons for Exception" : key;
                if (value && !key.includes("other")) {
                  return (
                    <p className={`text-${color}-700 mb-2 ${center ? 'text-center' : ''}`}>
                      <span className="font-semibold">{keyManaged}</span>
                    </p>
                  );
                }
                return (
                  <p className={`text-${color}-700 mb-2 ${center ? 'text-center' : ''}`}>
                    <span className="font-semibold">{keyManaged}:</span> {value}
                  </p>
                );
              }
            )}
          </div>
        </>
      ) : null}
    </>
  );
};

export default RequestExceptions;
