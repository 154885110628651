import { addDoc, collection } from "firebase/firestore";
import { auth, db } from "../../../../firebase";

const submitNote = async (e, policyID, setNoteData, props, value, note, collectionPath) => {
await addDoc(
    collection(db, collectionPath),
    {
        authorID: auth.currentUser.uid,
        author: auth.currentUser.displayName,
        role: props.userInfo.role,
        documentStatus: props?.formData?.status || "",
        documentType: props?.formData?.documentType || "",
        createdOn: new Date(),
        status: "Active",
        note: value,
        subject: note,
    }
);
  setNoteData({});
};

export default submitNote;
