import Modal from "react-modal";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import { useState } from "react";

const customStyles = (isFullScreen, maxWidth, overflow, zIndex) => ({
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent dark overlay
    backdropFilter: "blur(5px)", // Blur the background behind the modal
    zIndex: 1000, // Ensure the overlay is on top
  },
  content: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)", // Center the modal
    width: isFullScreen ? "100%" : "90%", // Full width if full-screen
    maxWidth: isFullScreen ? "100%" : maxWidth || "426px", // Use provided maxWidth or full width
    height: isFullScreen ? "100%" : "min-content", // Full height if full-screen
    maxHeight: isFullScreen ? "100vh" : "90vh", // Full height or fit to screen
    zIndex: zIndex || 2000,
    overflow: overflow || "auto",
    padding: "20px",
    borderRadius: isFullScreen ? "0" : "8px", // No border radius in full-screen
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Optional shadow for depth
    boxSizing: "border-box", // Ensures padding doesn't affect width calculations
    position: "relative", // Ensure the close button is positioned relative to this
  },
});

Modal.setAppElement("#root");

export default function Popup({
  isOpen,
  onRequestClose,
  children,
  contentLabel = "",
  maxWidth,
  overflow,
  zIndex,
  setIsFullScreenActive,
  showExpandButton = false,
  expandByDefault = false,
}) {
  const [isFullScreen, setIsFullScreen] = useState(expandByDefault ? true : false);

  const toggleFullScreen = () => {
    setIsFullScreen((prev) => !prev);
    if (setIsFullScreenActive) {
      setIsFullScreenActive(!isFullScreen)
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={false}
      style={customStyles(isFullScreen, maxWidth, overflow, zIndex)}
      contentLabel={contentLabel}
    >
      {/* Attractive close and expand buttons positioned in the top right */}
      <div style={{ position: "absolute", top: "10px", right: "10px", display: "flex", gap: "10px" }}>
        {showExpandButton && (
          <>
          
        <IconButton
          onClick={toggleFullScreen}
          style={{
            color: "#444",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            borderRadius: "50%",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
            zIndex: 3000,
          }}
          aria-label="Expand"
        >
          {isFullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
        </IconButton>
        </>
        )}
        <IconButton
          onClick={onRequestClose}
          style={{
            color: "#444",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            borderRadius: "50%",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
            zIndex: 3000,
          }}
          aria-label="Close"
        >
          <CloseIcon />
        </IconButton>
      </div>
      <section>{children}</section>
    </Modal>
  );
}
