import React from "react";
import {
  ADMIN_ROUTE,
  CALENDAR_ROUTE
} from "../../../utils/routes";
import { NavDropdown } from "react-bootstrap";
import { 
  Description,   // For "Print My Policy"
  People,        // For "Drivers"
  LocalShipping, // For "Power Units"
  Train,         // For "Trailers"
  Inventory,     // For "Commodities"
  Assignment     // For "Questionnaire"
} from '@mui/icons-material';

export default function ClientManageMenu() {
  return (
    <>
      <NavDropdown.Item href={""}>
        <Description className="mr-2" /> Print My Policy
      </NavDropdown.Item>
      <NavDropdown.Item href={""}>
        <People className="mr-2" /> Drivers
      </NavDropdown.Item>
      <NavDropdown.Item href={""}>
        <Train className="mr-2" /> Power Units
      </NavDropdown.Item>
      <NavDropdown.Item href={""}>
        <LocalShipping className="mr-2" /> Trailers
      </NavDropdown.Item>
      <NavDropdown.Item href={""}>
        <Inventory className="mr-2" /> Commodities
      </NavDropdown.Item>
      <NavDropdown.Item href={""}>
        <Assignment className="mr-2" /> Questionnaire
      </NavDropdown.Item>
    </>
  );
}
