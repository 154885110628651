import React, { useState, useEffect } from "react";
import { Fab, Typography } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { db } from "../../../../firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import AttendeesAndAgents from "../sections/AttendeesAndAgents";
import ActionItemTracker from "../sections/ActionItemTracker"; // Import the ActionItemTracker component
import FinalNotes from "../sections/FinalNotes"; // Import the FinalNotes component
import HiringProcess from "../sections/HiringProcess"; // Import the HiringProcess component
import Training from "../sections/Training"; // Import the Training component
import ClearingHouseRegistration from "../sections/ClearingHouseRegistration"; // Import the ClearingHouseRegistration component
import AlertsOnBASICS from "../sections/AlertsOnBasics"; // Import the AlertsOnBASICS component
import MCS150 from "../sections/MCS150"; // Import the MCS150 component
import VehicleMaintenanceAnalysis from "../sections/VehicleMaintenanceAnalysis"; // Import the VehicleMaintenanceAnalysis component
import HoursOfServiceAnalysis from "../sections/HoursOfServiceAnalysis"; // Import the HoursOfServiceAnalysis component
import SafetyManual from "../sections/SafetyManual"; // Import the SafetyManual component
import CrashAndAccidents from "../sections/CrashAndAccidents"; // Import the CrashAndAccidents component
import Audits from "../sections/Audits"; // Import the Audits component
import AddedInformationForUnits from "../sections/AddedInformationForUnits"; // Import the AddedInformationForUnits component
import Tools from "../sections/Tools"; // Import the Tools component

export default function Sections({ handleInputChange, handleSubmit, reportID }) {
  const [report, setReport] = useState({});
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [lastSaved, setLastSaved] = useState(Date.now());

  useEffect(() => {
    const fetchReport = async () => {
      try {
        console.log("test", reportID);
        const docRef = doc(db, "loss-control-reports", reportID); // Use a DocumentReference
        const docSnapshot = await getDoc(docRef); // Await the getDoc function
        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          console.log(data);
          setReport(data);
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching report:", error);
      }
    };

    if (reportID) {
      fetchReport();
    }
  }, [reportID]);

  const handleSave = async () => {
    // Your save logic here
    console.log("Saving changes...");
    setLastSaved(Date.now());  // Update the last saved time
    setHasUnsavedChanges(false);  // Reset unsaved changes flag
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = Date.now();
      if (hasUnsavedChanges && now - lastSaved > 20000) {
        handleSave();
      }
    }, 5000); // Check every 5 seconds

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [hasUnsavedChanges, lastSaved]);

  const handleInputChangeWithTracking = (key, value) => {
    handleInputChange(key, value);
    setHasUnsavedChanges(true);  // Mark as having unsaved changes
  };

  return (
    <form onSubmit={handleSubmit}>
           {/* Report Info */}
           <Typography variant="h6" align="left">
        {report?.creationData?.namedInsured}
      </Typography>
      <Typography variant="subtitle1" align="left">
        Report No. {report?.lossControlNumber}
      </Typography>
      <Typography variant="subtitle1" align="left">
        Created On{" "}
        {report?.creationData?.createdOn.toDate().toString().slice(0, 15)}
      </Typography>
      <Typography variant="subtitle1" align="left">
        Created By: {report?.creationData?.createdByUser}
      </Typography>
      <p className="mt-2  mb-4">
        Please fill out the following information for the Loss Control Report.
      </p>
      <AttendeesAndAgents handleInputChange={handleInputChangeWithTracking} />
      <HiringProcess handleInputChange={handleInputChangeWithTracking} />
      <Training handleInputChange={handleInputChangeWithTracking} />
      <ClearingHouseRegistration handleInputChange={handleInputChangeWithTracking} />
      <AlertsOnBASICS handleInputChange={handleInputChangeWithTracking} />
      <MCS150 handleInputChange={handleInputChangeWithTracking} />
      <VehicleMaintenanceAnalysis handleInputChange={handleInputChangeWithTracking} />
      <HoursOfServiceAnalysis handleInputChange={handleInputChangeWithTracking} />
      <SafetyManual handleInputChange={handleInputChangeWithTracking} />
      <CrashAndAccidents handleInputChange={handleInputChangeWithTracking} />
      <Audits handleInputChange={handleInputChangeWithTracking} />
      <AddedInformationForUnits handleInputChange={handleInputChangeWithTracking} />
      <Tools handleInputChange={handleInputChangeWithTracking} />
      <ActionItemTracker
        handleInputChange={handleInputChangeWithTracking}
        reportId={reportID}
      />
      <FinalNotes handleInputChange={handleInputChangeWithTracking} />
      {/* Floating Save Button */}
      <Fab
      disabled={!hasUnsavedChanges}  // Disable the button if there are no unsaved changes
        color="primary"
        aria-label="save"
        onClick={handleSave}
        sx={{
          position: "fixed",
          bottom: 16,
          right: 16,
          boxShadow: 3,
          bgcolor: "primary.main",
          "&:hover": {
            bgcolor: "primary.dark",
          },
        }}
      >
        <SaveIcon />
      </Fab>
    </form>
  );
}
