import React, { useState } from 'react';
import { TextField, Button, Grid } from '@mui/material';
import ApprovalButtons from '../../assets/forms/ApprovalButtons';
import { additionalInsuredEndorsementRequest } from '../../../services/firebase-api/additionalInsureds';
import useGeneralState from '../../../Context/actions/useGeneralState';

export default function AddInsured(props) {

  const { goBack } = props;
  const { controlToast } = useGeneralState()

  const [formData, setFormData] = useState({
    effectiveDate: '',
    name: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zip: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const convertDate = (dateString) => {
    const dateParts = dateString.split('-');
    const date = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
    return date;
  }
  
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const convertedDate = convertDate(formData.effectiveDate);
      formData['effectiveDate'] = convertedDate;
      await additionalInsuredEndorsementRequest(formData, props)
      controlToast(true, 'You created an Add Additional Insureds endorsement successfully', 'success')
      goBack()
    } catch (error) {
      controlToast(true, 'There was an error in the service', 'error')
      console.error(error)
    }
  };

  const isValid = Object.values(formData).every((val) => val);

  return (
    <form onSubmit={handleSubmit} className='mt-3'>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            type="date"
            label="Effective Date"
            name="effectiveDate"
            value={formData.effectiveDate}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Name of Additional Insured"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Physical Address"
            name="addressLine1"
            value={formData.addressLine1}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Address Line 2"
            name="addressLine2"
            value={formData.addressLine2}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label="City"
            name="city"
            value={formData.city}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label="State"
            name="state"
            value={formData.state}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label="Zip"
            name="zip"
            value={formData.zip}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      
      <ApprovalButtons
        titleAccept="Submit"
        titleDecline="Cancel"
        onApprove={handleSubmit}
        disabledAccept={!isValid}
        onDecline={goBack}
      />
    </form>
  );
}
