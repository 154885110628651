import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { t } from "i18next";
import ApprovalButtons from "../../assets/forms/ApprovalButtons";
import Breadcrumbs from "../../assets/Breadcrumbs";
import DragDropImportList from "../../assets/forms/DragDropFileUpload/DragDropImportList";
import { generateEmptyFile, templateUpdateText } from "../../../utils/excelCreator";
import { Download as DownloadIcon } from "@mui/icons-material";
import BlueButton from "../../assets/forms/BlueButton";
import useImportTrailers from "./importFn/useImportTrailers";
import { handleExistingOrNewItemsModal } from "../../utils/validateIfBlacklisted";
import "react-datepicker/dist/react-datepicker.css";
import { setTimeToNoonOne } from "../../../utils/helpers";

export default function ImportTrailers(props) {
  const isEndoManager = props.isEndoManager;
  const formData = props.formData;
  const setShowTable = props.setShowTable;
  const setShowImport = props.setShowImport;
  const count = props.count;

  const [endoEffectiveDate, setEndoEffectiveDate] = useState("");
  const [file, setFile] = useState(null);
  const [success, setSuccess] = useState(false);

  const breadcrumbs = [
    { label: formData.documentType },
    { label: "Trailers Lists" },
    { label: "Import" },
  ];

  const goBack = () => {
    setShowImport(false);
    setShowTable(true);
  };

  const { mirrorLogic: mirrorLogicTrailers } = useImportTrailers({
    ...props,
    endoEffectiveDate,
    setSuccess,
    goBack,
  });

  const handleMirrorLogic = async (file, setLoading) => {
    try {
      await mirrorLogicTrailers(file, setLoading);
    } catch (error) {
      console.error(error);
    }
  };

  const handleExistingOrNewItems = async (e, setLoading = () => {}) => {
    try {
      await handleExistingOrNewItemsModal(
        e,
        setLoading,
        formData,
        handleMirrorLogic,
        "trailers",
        count
      );
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {success ? (
        <main className="bg-green-100 p-6 rounded-md shadow-md text-green-700">
          <p className="text-2xl font-semibold mb-4">
            Your endorsement has been successfully submitted.
          </p>
          <p className="mb-4">
            An underwriter will review your endorsement, and you will receive a
            notification when the status is updated.
          </p>
          <button
            onClick={(e) => goBack(e)}
            className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded"
          >
            OK
          </button>
        </main>
      ) : (
        <main>
          <div className="max-w-[550px]">
            {!isEndoManager ? <Breadcrumbs items={breadcrumbs} /> : null}
            <div className="flex flex-col max-w-[220px]">
              <BlueButton
                text="Download Template"
                onClickFunction={(e) => {
                  e.preventDefault();
                  generateEmptyFile();
                }}
                hasContentToTop={true}
                hasContentToBottom={true}
                icon={<DownloadIcon />}
              />
              <span className="text-sm text-gray-500">
                {templateUpdateText}
              </span>
            </div>
            <h1
              className={`${
                isEndoManager
                  ? "text-2xl mt-2.5 font-bold md:mb-0"
                  : "text-3xl mt-2 font-semibold md:mb-0"
              }`}
            >
              {!isEndoManager ? `${t("Import")}` : `${t("Import")}`}
            </h1>
            <p className="mt-2 mb-3">
              {(formData.documentType === "Application" ||
                formData.documentType === "Policy") && (
                <>
                  {t("Enter your trailer's list into the")}{" "}
                  <a href="#" className="text-[#072a48] hover:underline">
                    {t("Trailer's List Template")}
                  </a>
                  , {t("then upload it to add trailers in bulk.")}
                </>
              )}
              {formData.documentType !== "Application" &&
                formData.documentType !== "Policy" && (
                  <>Trailers may not be edited during the quoting phase.</>
                )}
            </p>

            <div className="mb-6">
              <form onSubmit={(e) => handleExistingOrNewItems(e)}>
                {formData.documentType === "Policy" ||
                formData.status === "In-Renewal" ? (
                  <div className="mb-4">
                    <label className="block text-gray-700 text-sm mb-1">
                      {t("Effective Date")}:
                    </label>
                    <DatePicker
                      wrapperClassName="datePicker"
                      required
                      selected={endoEffectiveDate}
                      onChange={(date) => {
                        const newDate = setTimeToNoonOne(date);
                        setEndoEffectiveDate(newDate);
                      }}
                      className="standard-form-input w-full"
                    />
                  </div>
                ) : null}
                {(formData.documentType === "Policy" &&
                  endoEffectiveDate !== "") ||
                (formData.status === "In-Renewal" &&
                  endoEffectiveDate !== "") ||
                (formData.documentType === "Application" &&
                  formData.status !== "In-Renewal") ? (
                  <DragDropImportList
                    file={file}
                    setFile={setFile}
                    handleUpload={handleExistingOrNewItems}
                    fileTypes={["XLSX"]}
                  />
                ) : null}
                <ApprovalButtons
                  onApprove={null}
                  handleAsSubmit={true}
                  onDecline={(e) => {
                    goBack();
                  }}
                  titleAccept="Upload"
                  titleDecline="Go Back"
                  disabledAccept={true}
                />
              </form>
            </div>
          </div>
        </main>
      )}
    </>
  );
}
