import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Box,
  TextField,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { doc, updateDoc, onSnapshot, getDoc } from "firebase/firestore";
import { db } from "../../../../firebase";
import numberWithCommas from "../../../utils/numberWithCommas";

const EditDropdownModal = ({ open, onClose, field }) => {
  const [options, setOptions] = useState([]);
  const [newOption, setNewOption] = useState("");

  useEffect(() => {
    // Real-time listener for the dropdown options
    const unsubscribe = onSnapshot(
      doc(db, "dropdownData", field.parent),
      (doc) => {
        if (doc.exists()) {
          const fieldData = doc
            .data()
            .fields.find((f) => f.field === field.field);
          setOptions(fieldData.options);
        }
      }
    );
    return unsubscribe;
  }, [field, open]);

  const handleAddOption = async () => {
    if (!newOption) {
      console.warn("New option is empty");
      return;
    }

    try {
      // Get the snapshot of the parent document
      const parentDocRef = doc(db, "dropdownData", field.parent);
      const parentSnap = await getDoc(parentDocRef);
      const parentData = parentSnap.data();

      // Find the field object in parentData.fields that matches the current field
      const fieldToUpdate = parentData.fields.find(
        (f) => f.field === field.field
      );

      if (fieldToUpdate && fieldToUpdate.isDropdown) {
        // Create a new option object
        const newOptionObject = {
          value: parseInt(newOption),
          label: `$${numberWithCommas(newOption)}`,
        };

        // Add the new option to the existing options
        const updatedOptions = [...fieldToUpdate.options, newOptionObject];

        // Update the parent document with the new options
        await updateDoc(parentDocRef, {
          fields: parentData.fields.map((f) =>
            f.field === field.field ? { ...f, options: updatedOptions } : f
          ),
        });

        // Clear the newOption state after adding
        setNewOption("");
      } else {
        console.warn("Field not found or is not a dropdown");
      }
    } catch (error) {
      console.error("Error adding option:", error);
    }
  };

  const handleDeleteOption = async (valueToDelete) => {
    return alert("This feature is currently disabled.");
    try {
      // Get the snapshot of the parent document
      const parentDocRef = doc(db, "dropdownData", field.parent);
      const parentSnap = await getDoc(parentDocRef);
      const parentData = parentSnap.data();

      // Find the field object in parentData.fields that matches the current field
      const fieldToUpdate = parentData.fields.find(
        (f) => f.field === field.field
      );

      if (fieldToUpdate && fieldToUpdate.isDropdown) {
        // Filter out the option to be deleted
        const newOptions = fieldToUpdate.options.filter(
          (option) => option.value !== valueToDelete
        );

        // Update the parent document with the new options
        await updateDoc(parentDocRef, {
          fields: parentData.fields.map((f) =>
            f.field === field.field ? { ...f, options: newOptions } : f
          ),
        });
      } else {
        console.warn("Field not found or is not a dropdown");
      }
    } catch (error) {
      console.error("Error deleting option:", error);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="edit-dropdown-modal"
      aria-describedby="edit-dropdown-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <h2 className="mb-2">Edit {field?.label}</h2>
        <TextField
          label="Add New Option"
          variant="outlined"
          fullWidth
          value={newOption}
          type={field?.isNumber ? "number" : "text"}
          onChange={(e) => setNewOption(e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddOption}
          startIcon={<AddIcon />}
          sx={{ mt: 2 }}
        >
          Add Option
        </Button>
        <Box
          sx={{
            maxHeight: 300,
            overflowY: "auto",
            mt: 2,
          }}
        >
          <List>
            {options.map((option) => (
              <ListItem
                key={option.value}
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => handleDeleteOption(option.value)}
                  >
                    <DeleteIcon />
                  </IconButton>
                }
              >
                <ListItemText primary={option.label} />
              </ListItem>
            ))}
          </List>
        </Box>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => {
            setNewOption("");
            onClose();
          }}
          sx={{ mt: 2 }}
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
};

export default EditDropdownModal;
