import React from "react";

const TextInput = ({ property, formData, setFormData, label, required }) => {
  const handleChange = (e) => {
    const value = e.target.value;
    setFormData({
      ...formData,
      [property]: value,
    });
  };

  return (
    <div className="my-2">
      <label
        htmlFor={property}
        className="block text-sm font-medium text-gray-700"
      >
        {label}
      </label>
      <input
      required={required || false}
        id={property}
        type="text"
        className="mt-1 p-1 block w-full rounded-md border border-gray-300 shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        value={formData?.[property]}
        onChange={handleChange}
      />
    </div>
  );
};

export default TextInput;
