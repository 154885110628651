const updateValuesArray = (values, unit, ratingData, policyID) => {
  const newValues = values.map((val) => {
    const policiesDetails = val.policiesDetails.map((detail) => {
      if (detail.id === policyID) {
        return {
          ...detail,
          ...ratingData,
        };
      }
      return detail;
    });
    if (val.id === unit.id) {
      return {
        ...val,
        policiesDetails,
      };
    }
    return val;
  });
  return newValues;
};

export default updateValuesArray;