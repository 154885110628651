import React from "react";
import DatePicker from "react-datepicker";
import { formatPrice, parseIssuedDateFromInspas } from "../Finances/Tables/utils";
import useFormDataState from "../../Context/actions/useFormData";
import useTranslations from "../../Context/actions/useTranslations";

export default function BindDetailsInfo(props) {
  const { formData } = props;
  const { formDataGL } = useFormDataState()
  const isMigrated = formData?.migrated
  const { t } = useTranslations();

  const parseValue = (value, type) => {
    if (type === "A/D") {
      if (value === "A") return t('plm.coverage-side-panel.bind-details-table.yes')
      if (value === "D") return t('plm.coverage-side-panel.bind-details-table.no')
      if (typeof value === 'undefined' || value === null) return "-"
      return value
    }
    if (type === "date") {
        if (typeof value === 'string') {
            const date = new Date(value)
            return date
        } else if (typeof value === 'object') {
            const date = new Date(value?.toDate())
            return date
        } else {
            return null
        }
    }
    if (
        typeof value !== "undefined" &&
        typeof value === "boolean"
    ) {
      return value ? "Yes" : "No";
    }
    if (
      typeof value !== "undefined" &&
      typeof value === "number" &&
      type === "price"
    ) {
      return formatPrice(value);
    }
    if (typeof value !== "undefined") {
      return value;
    }
    return "-";
  };

  return (
    <>
      <fieldset disabled={true}>
        <form>
          <div>
            <>
                <div className="mb-2">
                    <label
                        htmlFor="boundBy"
                        className="block text-sm font-medium text-gray-600"
                    >
                        {t('plm.coverage-side-panel.bind-details-table.bound-by')}
                    </label>
                    <input
                        required
                        name="boundBy"
                        type="text"
                        value={
                          isMigrated ?
                          parseValue(formData?.IssuedUser) :
                          parseValue(formData?.bindData?.boundBy)
                        }
                        className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                    />
                </div>
                <div className="mb-2">
                    <label
                        htmlFor="powerUnitCountAtBind"
                        className="block text-sm font-medium text-gray-600"
                    >
                        {t('plm.coverage-side-panel.bind-details-table.power-unit-count-at-bind')}
                    </label>
                    <input
                        required
                        name="powerUnitCountAtBind"
                        type="text"
                        value={
                          parseValue(formData?.bindData?.powerUnitCountAtBind)
                        }
                        className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                    />
                </div>
                <div className="mb-2">
                    <label
                        htmlFor="bindDate"
                        className="block text-sm font-medium text-gray-600"
                    >
                        {t('plm.coverage-side-panel.bind-details-table.bind-date')}
                    </label>
                    <DatePicker
                        required
                        wrapperClassName="datePicker"
                        selected={
                          isMigrated ?
                          parseValue(parseIssuedDateFromInspas(formData?.IssuedDate), "date") :
                          parseValue(formData?.bindData?.bindDate, "date")
                        }
                        className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                    />
                </div>
            </>
            <>
              <p className="text-lg font-semibold my-4">
              {t('plm.coverage-side-panel.bind-details-table.al.bound-premiums-auto-liability')}
              </p>
              <div className="mb-2">
                <label
                  htmlFor="autoLiabilityCoverage"
                  className="block text-sm font-medium text-gray-600"
                >
                  {t('plm.coverage-side-panel.bind-details-table.al.has-al-coverage')}
                </label>
                <input
                  required
                  name="autoLiabilityCoverage"
                  type="text"
                  value={
                    isMigrated ?
                    parseValue(formData?.PolicyNbr ? formData?.PolicyNbr?.includes("-19-") : false) :
                    parseValue(formData?.bindData?.coverages?.hasALCoverage)
                  }
                  className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                />
              </div>
              <div className="mb-2">
                <label
                  htmlFor="autoLiabilityGWP"
                  className="block text-sm font-medium text-gray-600"
                >
                  {t('plm.coverage-side-panel.bind-details-table.al.gwp')}
                </label>
                <input
                  required
                  name="autoLiabilityGWP"
                  type="text"
                  value={
                    isMigrated ?
                    parseValue(formData?.alPremiums?.GWP, "price") :
                    parseValue(formData?.bindData?.boundPremiums?.autoLiability?.GWP, "price")
                  }
                  className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                />
              </div>
              <div className="mb-2">
                <label
                  htmlFor="autoLiabilityAdjustment"
                  className="block text-sm font-medium text-gray-600"
                >
                  {t('plm.coverage-side-panel.bind-details-table.al.adjustment')}
                </label>
                <input
                  required
                  name="autoLiabilityAdjustment"
                  type="text"
                  value={
                    isMigrated ?
                    parseValue(formData?.inspasData?.adjustments?.totalAdjustment) :
                    parseValue(formData?.bindData?.boundPremiums?.autoLiability?.adjustment)
                  }
                  className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                />
              </div>
              <div className="mb-2">
                <label
                  htmlFor="autoLiabilityDebitOrCredit"
                  className="block text-sm font-medium text-gray-600"
                >
                  {t('plm.coverage-side-panel.bind-details-table.al.debit-credit')}
                </label>
                <input
                  required
                  name="autoLiabilityDebitOrCredit"
                  type="text"
                  value={parseValue(
                    formData?.bindData?.boundPremiums?.autoLiability
                      ?.debitOrCredit
                  )}
                  className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                />
              </div>
              <div className="mb-2">
                <label
                  htmlFor="autoLiabilityOtherFees"
                  className="block text-sm font-medium text-gray-600"
                >
                  {t('plm.coverage-side-panel.bind-details-table.al.other-fees')}
                </label>
                <input
                  required
                  name="autoLiabilityOtherFees"
                  type="text"
                  value={
                    isMigrated ?
                    parseValue(formData?.inspasData?.ALOtherFees, "price") :
                    parseValue(formData?.bindData?.boundPremiums?.autoLiability?.otherFees, "price")
                  }
                  className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                />
              </div>
              <div className="mb-2">
                <label
                  htmlFor="autoLiabilityProcessingFeeAmount"
                  className="block text-sm font-medium text-gray-600"
                >
                  {t('plm.coverage-side-panel.bind-details-table.al.processing-fee-amount')}
                </label>
                <input
                  required
                  name="autoLiabilityProcessingFeeAmount"
                  type="text"
                  value={
                    isMigrated ?
                    parseValue(formData?.inspasData?.ALProcessingFees, "price") :
                    parseValue(formData?.bindData?.boundPremiums?.autoLiability?.processingFeeAmount, "price")
                  }
                  className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                />
              </div>
              <div className="mb-2">
                <label
                  htmlFor="autoLiabilityStampTaxAmount"
                  className="block text-sm font-medium text-gray-600"
                >
                  {t('plm.coverage-side-panel.bind-details-table.al.stamp-fee-amount')}
                </label>
                <input
                  required
                  name="autoLiabilityStampTaxAmount"
                  type="text"
                  value={
                    isMigrated ?
                    parseValue(formData?.inspasData?.ALSFPremium, "price") :
                    parseValue(formData?.bindData?.boundPremiums?.autoLiability?.stampTaxAmount, "price")
                  }
                  className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                />
              </div>
              <div className="mb-2">
                <label
                  htmlFor="autoLiabilitySurplusLinesTaxAmount"
                  className="block text-sm font-medium text-gray-600"
                >
                  {t('plm.coverage-side-panel.bind-details-table.al.surplus-lines-tax-amount')}
                </label>
                <input
                  required
                  name="autoLiabilitySurplusLinesTaxAmount"
                  type="text"
                  value={
                    isMigrated ?
                    parseValue(formData?.inspasData?.ALSLTPremium, "price") :
                    parseValue(formData?.bindData?.boundPremiums?.autoLiability?.surplusLinesTaxAmount, "price")
                  }
                  className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                />
              </div>
              <div className="mb-2">
                <label
                  htmlFor="autoLiabilityTotalPremium"
                  className="block text-sm font-medium text-gray-600"
                >
                  {t('plm.coverage-side-panel.bind-details-table.al.total-premium')}
                </label>
                <input
                  required
                  name="autoLiabilityTotalPremium"
                  type="text"
                  value={
                    isMigrated ?
                    parseValue(formData?.inspasData?.ALTotalDuePremium, "price") :
                    parseValue(formData?.bindData?.boundPremiums?.autoLiability?.totalPremium, "price")
                  }
                  className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                />
              </div>
            </>
            <>
              <p className="text-lg font-semibold my-4">
                {t('plm.coverage-side-panel.bind-details-table.gl.bound-premiums-auto-liability')}
              </p>
              <div className="mb-2">
                <label
                  htmlFor="generalLiabilityCoverage"
                  className="block text-sm font-medium text-gray-600"
                >
                  {t('plm.coverage-side-panel.bind-details-table.gl.has-al-coverage')}
                </label>
                <input
                  required
                  name="generalLiabilityCoverage"
                  type="text"
                  value={
                    isMigrated ?
                    parseValue(formData?.inspasData?.PolicyNbr ? formData?.inspasData?.PolicyNbr?.includes("-17-") : false) :
                    parseValue(formData?.bindData?.coverages?.hasGLCoverage)
                  }
                  className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                />
              </div>
              <div className="mb-2">
                <label
                  htmlFor="generalLiabilityGWP"
                  className="block text-sm font-medium text-gray-600"
                >
                  {t('plm.coverage-side-panel.bind-details-table.gl.gwp')}
                </label>
                <input
                  required
                  name="generalLiabilityGWP"
                  type="text"
                  value={
                    isMigrated ?
                    parseValue(formDataGL?.premium, "price") :
                    parseValue(formDataGL?.bindData?.boundPremiums?.generalLiability?.GWP, "price")
                  }
                  className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                />
              </div>
              <div className="mb-2">
                <label
                  htmlFor="generalLiabilityAdjustment"
                  className="block text-sm font-medium text-gray-600"
                >
                  {t('plm.coverage-side-panel.bind-details-table.gl.adjustment')}
                </label>
                <input
                  required
                  name="generalLiabilityAdjustment"
                  type="text"
                  value={parseValue(formDataGL?.bindData?.boundPremiums?.generalLiability?.adjustment)}
                  className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                />
              </div>
              <div className="mb-2">
                <label
                  htmlFor="generalLiabilityDebitOrCredit"
                  className="block text-sm font-medium text-gray-600"
                >
                  {t('plm.coverage-side-panel.bind-details-table.gl.debit-credit')}
                </label>
                <input
                  required
                  name="generalLiabilityDebitOrCredit"
                  type="text"
                  value={parseValue(
                    formDataGL?.bindData?.boundPremiums?.generalLiability
                      ?.debitOrCredit
                  )}
                  className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                />
              </div>
              <div className="mb-2">
                <label
                  htmlFor="generalLiabilityOtherFees"
                  className="block text-sm font-medium text-gray-600"
                >
                  {t('plm.coverage-side-panel.bind-details-table.gl.other-fees')}
                </label>
                <input
                  required
                  name="generalLiabilityOtherFees"
                  type="text"
                  value={
                    isMigrated ?
                    parseValue(formDataGL?.inspasData?.GLOtherFee, "price") :
                    parseValue(formDataGL?.bindData?.boundPremiums?.generalLiability?.otherFees, "price")
                  }
                  className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                />
              </div>
              <div className="mb-2">
                <label
                  htmlFor="generalLiabilityProcessingFeeAmount"
                  className="block text-sm font-medium text-gray-600"
                >
                  {t('plm.coverage-side-panel.bind-details-table.gl.processing-fee-amount')}
                </label>
                <input
                  required
                  name="generalLiabilityProcessingFeeAmount"
                  type="text"
                  value={
                    isMigrated ?
                    parseValue(formDataGL?.inspasData?.GLProcessingFees, "price") :
                    parseValue(formDataGL?.bindData?.boundPremiums?.generalLiability?.processingFeeAmount, "price")
                  }
                  className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                />
              </div>
              <div className="mb-2">
                <label
                  htmlFor="generalLiabilityStampTaxAmount"
                  className="block text-sm font-medium text-gray-600"
                >
                  {t('plm.coverage-side-panel.bind-details-table.gl.stamp-fee-amount')}
                </label>
                <input
                  required
                  name="generalLiabilityStampTaxAmount"
                  type="text"
                  value={
                    isMigrated ?
                    parseValue(formDataGL?.inspasData?.GLSFPremium, "price") :
                    parseValue(formDataGL?.bindData?.boundPremiums?.generalLiability?.stampTaxAmount, "price")
                  }
                  className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                />
              </div>
              <div className="mb-2">
                <label
                  htmlFor="generalLiabilitySurplusLinesTaxAmount"
                  className="block text-sm font-medium text-gray-600"
                >
                  {t('plm.coverage-side-panel.bind-details-table.gl.surplus-lines-tax-amount')}
                </label>
                <input
                  required
                  name="generalLiabilitySurplusLinesTaxAmount"
                  type="text"
                  value={
                    isMigrated ?
                    parseValue(formDataGL?.inspasData?.GLSLTPremium, "price") :
                    parseValue(formDataGL?.bindData?.boundPremiums?.generalLiability?.surplusLinesTaxAmount, "price")
                  }
                  className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                />
              </div>
              <div className="mb-2">
                <label
                  htmlFor="generalLiabilityTotalPremium"
                  className="block text-sm font-medium text-gray-600"
                >
                  {t('plm.coverage-side-panel.bind-details-table.gl.total-premium')}
                </label>
                <input
                  required
                  name="generalLiabilityTotalPremium"
                  type="text"
                  value={
                    isMigrated ?
                    parseValue(formDataGL?.inspasData?.GLTotalDuePremium, "price") :
                    parseValue(formDataGL?.bindData?.boundPremiums?.generalLiability?.totalPremium, "price")
                  }
                  className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                />
              </div>
            </>
            <>
                <p className="text-lg font-semibold my-4">
                {t('plm.coverage-side-panel.bind-details-table.additional-information.title')}
                </p>
                <div className="mb-2">
                <label
                    htmlFor="hasMCP65"
                    className="block text-sm font-medium text-gray-600"
                >
                    {t('plm.coverage-side-panel.bind-details-table.additional-information.mcp-65')}
                </label>
                <input
                    required
                    name="hasMCP65"
                    type="text"
                    value={
                      isMigrated ?
                      parseValue(formData?.inspasData?.HasMCP65, "A/D") :
                      parseValue(formData?.bindData?.additionalInfo?.hasMCP65)
                    }
                    className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                />
                </div>
                <div className="mb-2">
                <label
                    htmlFor="has91X"
                    className="block text-sm font-medium text-gray-600"
                >
                    {t('plm.coverage-side-panel.bind-details-table.additional-information.91x')}
                </label>
                <input
                    required
                    name="has91X"
                    type="text"
                    value={
                      isMigrated ?
                      parseValue(formData?.inspasData?.Has91X, "A/D") :
                      parseValue(formData?.bindData?.additionalInfo?.has91X)
                    }
                    className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                />
                </div>
                <div className="mb-2">
                <label
                    htmlFor="hasFormE"
                    className="block text-sm font-medium text-gray-600"
                >
                    {t('plm.coverage-side-panel.bind-details-table.additional-information.form-e')}
                </label>
                <input
                    required
                    name="hasFormE"
                    type="text"
                    value={
                      isMigrated ?
                      parseValue(formData?.inspasData?.HasFormE, "A/D") :
                      parseValue(formData?.bindData?.additionalInfo?.hasFormE)
                    }
                    className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                />
                </div>
            </>
          </div>
        </form>
      </fieldset>
    </>
  );
}
