import React, { useState } from 'react';
import { Card, CardContent, Typography, Grid, TextField } from '@mui/material';

const HoursOfServiceAnalysis = ({ handleInputChange }) => {
  // Define questions and state management
  const questions = [
    { id: 'eldProviderCompany', label: 'ELD provider company:', type: 'text' },
    { id: 'eldSinceWhen', label: 'Since when have you been working with your ELD provider?', type: 'date' },
    { id: 'contractLength', label: 'Contract length time:', type: 'text' },
    { id: 'productQuality', label: 'Quality of the product:', type: 'text' },
    { id: 'customerService', label: 'Customer service and hours of service?', type: 'text' },
    { id: 'editOptions', label: 'Edit options for inside drivers profile:', type: 'textarea' },
    { id: 'lookingForOption', label: 'Looking for another option?', type: 'text' },
    { id: 'overseeingDriverLogs', label: 'Who is responsible for overseeing driver logs?', type: 'text' },
    { id: 'overseeingPermanent', label: 'Is it permanently assigned?', type: 'text' },
    { id: 'overseeingFullName', label: 'Full name:', type: 'text' },
    { id: 'overseeingPhoneNumber', label: 'Phone number:', type: 'text' },
    { id: 'overseeingEmail', label: 'Email address:', type: 'email' },
    { id: 'personalConveyance', label: 'What is your Personal Conveyance usage/limit/rules?', type: 'textarea' },
    { id: 'logsOperations', label: 'Mexico/USA logs and operations:', type: 'textarea' },
    { id: 'trainingBeforeHiring', label: 'Training about HOS and software (Before hiring):', type: 'textarea' },
    { id: 'trainingAfterHiring', label: 'Training about HOS and software (After hiring):', type: 'textarea' },
    { id: 'trainingDetails', label: 'What training?', type: 'textarea' },
    { id: 'trainingTime', label: 'Training time:', type: 'text' },
    { id: 'tabletsOrPhones', label: 'Tablets or phones?', type: 'text' },
    { id: 'paperLogTruck', label: 'Any paper log truck? 2000 or older:', type: 'text' },
    { id: 'driversWithBadPoints', label: 'Drivers with bad points (CAB):', type: 'textarea' },
  ];

  // State to manage responses
  const [responses, setResponses] = useState(
    questions.reduce((acc, { id }) => ({ ...acc, [id]: '' }), {})
  );

  return (
    <Card sx={{ maxWidth: 800, margin: 'auto', padding: 2, marginBottom: 2 }}>
      <CardContent>
        <Typography variant="h5" component="div" gutterBottom>
          Hours of Service Analysis
        </Typography>
        <Grid container spacing={2}>
          {questions.map(({ id, label, type }) => (
            <Grid item xs={12} key={id}>
              <TextField
                fullWidth
                multiline={type === 'textarea'}
                rows={type === 'textarea' ? 4 : 1}
                variant="outlined"
                label={label}
                type={type === 'date' ? 'date' : 'text'}
                value={responses[id] || ''}
                onChange={(e) => handleInputChange(id, e.target.value)}
              />
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default HoursOfServiceAnalysis;
