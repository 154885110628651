import React from "react";
import NewApplication from "../../PolicyLifecycleManager/CreateNewApp";
import useTranslations from "../../../Context/actions/useTranslations";

const CreateApplicationPanel = ({
  userInfo,
  setShowCreateApplicationPanel,
  driverList,
  truckList,
  trailerList,
}) => {
  const { t } = useTranslations();

  return (
    <div className="container mx-auto my-8">
      <NewApplication
        agencyID={userInfo.agencyID}
        setShowCreateApplicationPanel={setShowCreateApplicationPanel}
        userInfo={userInfo}
        driverList={driverList}
        truckList={truckList}
        trailerList={trailerList}
      />
    </div>
  );
};

export default CreateApplicationPanel;
