import React, { useEffect, useState } from "react";
import {
  collection,
  doc,
  onSnapshot,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../../../../firebase";
import useTranslations from "../../../../Context/actions/useTranslations";

export default function AgentSelection({ formData, policyID }) {

  const { t } = useTranslations()
  const [userList, setUserList] = useState([]);
  useEffect(() => {
    if (formData.agencyID) {
      const usersCollection = collection(db, "users");
      const q = query(
        usersCollection,
        where("agencyID", "==", formData.agencyID)
      );

      // Subscribe to updates in real-time
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const updatedUserList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setUserList(updatedUserList);
      });

      // Clean up the listener when the component unmounts
      return () => unsubscribe();
    }
  }, [formData.agencyID]);
  return (
    <>
      <label className="mt-1">{t("plm.coverage-side-panel.commisioned-agent")}</label>
      <select
        onChange={async (e) => {
          // Split the selected value into AgentID and AgentName
          const [agentID, agentName, agentEmail] = e.target.value.split("---");
          const policyRef = doc(db, "policy-applications", policyID);
          await updateDoc(policyRef, {
            agentID,
            agentName,
            agentEmail,
          });
        }}
        className="appearance-none block w-full bg-white border border-gray-300 rounded-md py-1 px-1 leading-tight focus:outline-none focus:shadow-outline-blue focus:border-blue-300"
        value={`${formData.agentID}---${formData.agentName}---${formData.agentEmail}`}
      >
        <option value=""></option>
        {userList.map((user, i) => {
          if (user.userIsAgent)
            return (
              <option
                key={"option" + i}
                value={`${user.id}---${user.first + " " + user.last}---${user.email}`}
              >
                {user.first + " " + user.last}
              </option>
            );
        })}
      </select>
    </>
  );
}
