import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../../../firebase";

const countPowerUnits = async (agencyID, insuredID, setPowerUnitCount) => {
    const powerUnitsQuery = query(
      collection(
        db,
        "agencies",
        agencyID,
        "insureds",
        insuredID,
        "power-units"
      ),
      where("status", "!=", "Deleted")
    );
    const powerUnitsSnapshot = await getDocs(powerUnitsQuery);
    setPowerUnitCount(powerUnitsSnapshot.size);
  };

  export default countPowerUnits;