import React from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  Typography
} from '@mui/material';

const Crashes = ({ crashes }) => {
  return (
    <TableContainer component={Paper}>
      <Typography variant="h6" align="center" sx={{ margin: 2 }}>
        Driver Crashes
      </Typography>
      <Table aria-label="driver crashes table">
        <TableHead>
          <TableRow>
            <TableCell>Crash ID</TableCell>
            <TableCell>Driver Name</TableCell>
            <TableCell>License Number</TableCell>
            <TableCell>License State</TableCell>
            <TableCell>Crash Date</TableCell>
            <TableCell>Injuries</TableCell>
            <TableCell>Fatalities</TableCell>
            <TableCell>Weather Condition</TableCell>
            <TableCell>Road Surface Condition</TableCell>
            <TableCell>Light Condition</TableCell>
            <TableCell>Tow Away</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {crashes.map((crash, index) => (
            <TableRow key={index}>
              <TableCell>{crash.crashID}</TableCell>
              <TableCell>{`${crash.drvFirstName} ${crash.drvLastName}`}</TableCell>
              <TableCell>{crash.licenseNumber}</TableCell>
              <TableCell>{crash.licenseState}</TableCell>
              <TableCell>{new Date(crash.crashDate.__time__).toLocaleDateString()}</TableCell>
              <TableCell>{crash.injuries}</TableCell>
              <TableCell>{crash.fatalities}</TableCell>
              <TableCell>{crash.weatherCondition}</TableCell>
              <TableCell>{crash.roadSurfaceCondition}</TableCell>
              <TableCell>{crash.lightCondition}</TableCell>
              <TableCell>{crash.towAway}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Crashes;
