import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { db } from "../../../firebase";

export const getPolicyGLData = async (formData) => {
  const glPolicies = collection(db, "gl-policies");
  const policyGLRef = query(
    glPolicies,
    where("agencyID", "==", formData.agencyID),
    where("insuredID", "==", formData.insuredID)
  );
  const querySnapshot = await getDocs(policyGLRef);
  if (!querySnapshot.empty) {
    const doc = querySnapshot.docs[0];
    const formDataGLFromFirestore = {
      ...doc.data(),
      id: doc.id,
    };
    return formDataGLFromFirestore;
  } else {
    return null;
  }
}

const getPolicyData = async (policyID, formData, isEndorsement, adjustmentPercentage) => {
    let adjustmentPercent;
    let adjustmentType;
    let policyData = {};
    if (!isEndorsement) {
      policyData = formData;
      adjustmentPercent = adjustmentPercentage;
    } else {
      const docSnap = await getDoc(doc(db, "policy-applications", policyID));
      const formDataGL = await getPolicyGLData(formData)
      policyData = {
        ...docSnap.data(),
        id: docSnap.id,
        hasGLCoverage: formDataGL?.hasGLCoverage || false,
        glPolicyNo: formDataGL?.glPolicyNo || "",
        glPremiums: formDataGL?.glPremiums || null,
      }
      adjustmentPercent = docSnap.data().alPremiums.adjustment;
      adjustmentType = docSnap.data().alPremiums.debitOrCredit;
      adjustmentType === 'Debit' ? adjustmentPercent = -adjustmentPercent : adjustmentPercent = adjustmentPercent;
    }
    return [policyData, adjustmentPercent]
  };

  export default getPolicyData;