import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../../firebase";
import React from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

const InputField = ({ label, value, onChange, disabled }) => {
  return (
    <Box py={2}>
      <TextField
        fullWidth
        label={label}
        value={value}
        onChange={onChange}
        disabled={disabled}
        type="number"
        variant="outlined"
      />
    </Box>
  );
};

export default InputField;
