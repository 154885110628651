import React, { useState } from "react";
import DriverPanel from "../../PolicyLifecycleManager/Drivers";
import PowerUnitPanel from "../../PolicyLifecycleManager/Units";
import TrailerPanel from "../../PolicyLifecycleManager/Trailers";
import { useNavigate } from "react-router-dom";
import { UNDERWRITING_ENDORSEMENT_ROUTE } from "../../../utils/routes";
import WaiverOfSubrogation from "../WaiverOfSubrogation";
import { useMediaQuery } from "@mui/material";
import GoBack from "../../generals/GoBack";
import EndoManagerTable from "./EndoManagerTable";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import Breadcrumbs from "../../assets/Breadcrumbs";
import CancelPolicyPanel from "../../CancelPolicyPanel";
import AddInsured from "../AddInsured";
import DriverExclusionsPanel from "../Drivers/DriverExclusionsPanel";
import useTranslations from "../../../Context/actions/useTranslations";

export default function EndoManager({
  setDisplayEndorsementManager,
  endorsements,
  tabProps,
  getEndorsements,
  policyID,
  skeletonEndoTableLoader,
  currentEndoFilters,
  setCurrentEndoFilters,
  insuredInfo,
  agencyInfo
}) {
  const navigate = useNavigate();
  const { t } = useTranslations()

  const newEndorsementsOptions = [
    {
      value: "endorsement-manager",
      label: t('endorsements.plm.endo-manager'),
    },
    {
      value: "waiver-of-subrogation",
      label: t('endorsements.plm.waiver-subrogation'),
    },
    {
      value: "endorse-drivers",
      label: t('endorsements.plm.endorse-drivers'),
    },
    {
      value: "endorse-power-units",
      label: t('endorsements.plm.endorse-power-units'),
    },
    {
      value: "endorse-trailers",
      label: t('endorsements.plm.endorse-trailers'),
    },
    {
      value: "cancel-policy",
      label: t('endorsements.plm.cancel-policy'),
    },
    {

      value: "additional-insured",
      label: t('endorsements.plm.additional-insured'),
    },
    {
      value: "endorse-driver-exclusion",
      label: t('endorsements.plm.endorse-drivers-exclusion'),
    },
    // {
    //   value: "add-coverage",
    //   label: t('endorsements.plm.add-coverage'),
    // },
  ];

  const [endorsementSelectedOption, setEndorsementSelectedOption] =
    useState("");

  const handleEndorsementsOption = (e) => {
    const optionSelected = newEndorsementsOptions.find(
      (option) => option.value === e.target.value
    );
    setEndorsementSelectedOption(optionSelected);
  };

  const goToEndorsement = (endorsementId) => {
    navigate(`${UNDERWRITING_ENDORSEMENT_ROUTE}/${endorsementId}/${policyID}`);
  };

  const isMobileScreen = useMediaQuery("(max-width: 600px)");

  const breadcrumbs = [{ label: t('endorsements.policy') }, { label: t('endorsements.endorsements') }];
  const breadcrumbsSelected = [
    { label: t('endorsements.policy') },
    { label: t('endorsements.endorsements') },
    { label: endorsementSelectedOption?.label },
  ];

  return (
    <div
      className={`card ${
        !isMobileScreen ? "ml-3 mr-3 mb-3 mt-2" : "mt-3"
      } w-full`}
    >
      <div className="card-body">
        <GoBack handleFn={() => setDisplayEndorsementManager(false)} />
        <div className="mt-3">
          <Breadcrumbs
            items={
              endorsementSelectedOption === ""
                ? breadcrumbs
                : breadcrumbsSelected
            }
          />
        </div>
        <h1 className="text-2xl mt-2">
          {endorsementSelectedOption === "" ||
          endorsementSelectedOption.value === "endorsement-manager"
            ? t('endorsements.title')
            : ""}
        </h1>
        <div className="my-2">
          {endorsementSelectedOption === "" ||
          endorsementSelectedOption.value === "endorsement-manager" ? (
            <>
              <p>
                {t('endorsements.plm.text-1')}
              </p>
              <p className="mt-2 mb-4">
                {t('endorsements.plm.text-2')}
              </p>
            </>
          ) : (
            <>
              <p>{endorsementSelectedOption.descriptionLine1}</p>
              <p className="mt-2 mb-4">
                {endorsementSelectedOption.descriptionLine2}
              </p>
            </>
          )}
        </div>
        <div className="max-w-[750px]">
          <FormControl fullWidth>
            <InputLabel id="select-label">{t('endorsements.plm.make-selection')}</InputLabel>
            <Select
              labelId="select-label"
              value={endorsementSelectedOption?.value || ""}
              className="my-2"
              onChange={handleEndorsementsOption}
            >
              {newEndorsementsOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        {endorsementSelectedOption?.value === "waiver-of-subrogation" ? (
          <WaiverOfSubrogation
            endorsements={endorsements}
            getEndorsements={getEndorsements}
            {...tabProps.waiverOfSubrogationPanel}
          />
        ) : endorsementSelectedOption?.value === "endorse-driver-exclusion" ? (
          <DriverExclusionsPanel
            isEndoManager
            showDriverExclusions
            getEndorsements={getEndorsements}
            {...tabProps.driverPanel}
          />
        ) : endorsementSelectedOption?.value === "endorse-drivers" ? (
          <DriverPanel
            isEndoManager
            getEndorsements={getEndorsements}
            {...tabProps.driverPanel}
          />
        ) : endorsementSelectedOption?.value === "endorse-power-units" ? (
          <PowerUnitPanel
            isEndoManager
            getEndorsements={getEndorsements}
            {...tabProps.powerUnitPanel}
          />
        ) : endorsementSelectedOption?.value === "endorse-trailers" ? (
          <TrailerPanel
            isEndoManager
            getEndorsements={getEndorsements}
            {...tabProps.trailerPanel}
          />
        ) : endorsementSelectedOption?.value === "cancel-policy" ? (
          <CancelPolicyPanel
            {...tabProps.cancelPolicyPanel}
            goBack={(e) =>
              setEndorsementSelectedOption({
                value: "endorsement-manager",
                label: "Endorsement Manager",
              })
            }
          />
        ) : endorsementSelectedOption?.value === "additional-insured" ? (
          <AddInsured
            {...tabProps.additionalInsuredsPanel}
            goBack={(e) =>
              setEndorsementSelectedOption({
                value: "endorsement-manager",
                label: "Endorsement Manager",
              })
            }
          />
        ) : (
          <EndoManagerTable
            endorsements={endorsements}
            goToEndorsement={goToEndorsement}
            skeletonEndoTableLoader={skeletonEndoTableLoader}
            currentEndoFilters={currentEndoFilters}
            setCurrentEndoFilters={setCurrentEndoFilters}
            insuredInfo={insuredInfo}
            agencyInfo={agencyInfo}
          />
        )}
      </div>
    </div>
  );
}
