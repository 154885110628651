import { useEffect, useState } from "react";
import { UserAuth } from "../Context/AuthContent";
import useLossRunsState from "../Context/actions/useLossRunsState";

const useAccount = (props) => {
    const { user } = UserAuth();
    const userID = user.uid;
    const userInfo = props.userInfo;
    const noProfile = props.noProfile;

    const { getLossRuns } = useLossRunsState()

    const [approvedSubmissions, setApprovedSubmissions] = useState(0);
    const [incompleteSubmissionCount, setIncompleteSubmissionCount] = useState(0);
    const [pendingQuoteRequestCount, setPendingQuoteRequestCount] = useState(0);
    const incompleteApplications = 0
    const approvedApplications = 0
    const inProcessRequests = 0
    const [sentRequests, setSentRequests] = useState(0)
    const [submittedRequests, setSubmittedRequests] = useState(0)
    const pendingAppointmentCount = 0

    useEffect(() => {
        getLossRuns().then(lossRuns => {
            const withSentStatus = lossRuns.filter(lossRun => lossRun.status === 'Sent')
            const withSubmittedStatus = lossRuns.filter(lossRun => lossRun.status === 'Submitted')
            setSentRequests(withSentStatus.length)
            setSubmittedRequests(withSubmittedStatus.length)
        })
    }, [])

    return {
        userID,
        userInfo,
        noProfile,
        approvedSubmissions,
        incompleteSubmissionCount,
        pendingQuoteRequestCount,
        setApprovedSubmissions,
        setIncompleteSubmissionCount,
        setPendingQuoteRequestCount,
        incompleteApplications,
        approvedApplications,
        inProcessRequests,
        sentRequests,
        submittedRequests,
        pendingAppointmentCount
    }
}

export default useAccount