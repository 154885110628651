import React from 'react'
import { Td } from "react-super-responsive-table";

export default function AgentColumns({
    selectedQuote, quote
}) {
  return (
    <>
    <Td
      className={`text-center ${
        selectedQuote === quote.id && "bg-green-200"
      }`}
    >
      {quote?.adjustmentFormData?.GWP}%
    </Td>
    <Td
      className={`text-center ${
        selectedQuote === quote.id && "bg-green-200"
      }`}
    >
      {quote?.adjustmentFormData
        ?.renewalCreditDebitOrCredit === "Credit" ? (
        <>-</>
      ) : (
        <>+</>
      )}
      {quote?.adjustmentFormData?.renewalCredit}%
    </Td>
    <Td
      className={`text-center ${
        selectedQuote === quote.id && "bg-green-200"
      }`}
    >
      {quote?.adjustmentFormData
        ?.lossRatioAdjustmentDebitOrCredit === "Credit" ? (
        <>-</>
      ) : (
        <>+</>
      )}
      {
        quote?.adjustmentFormData
          ?.lossRatioAdjustmentPercentage
      }
      %
    </Td>
    <Td
      className={`text-center ${
        selectedQuote === quote.id && "bg-green-200"
      }`}
    >
      {quote?.adjustmentFormData
        ?.safetyAdjustmentDebitOrCredit === "Credit" ? (
        <>-</>
      ) : (
        <>+</>
      )}
      {quote?.adjustmentFormData?.safetyAdjustmentPercentage}%
    </Td>
    </>
  )
}
