import React, { useState, useEffect } from 'react';
import { doc, onSnapshot, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PercentIcon from '@mui/icons-material/Percent';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import camelCaseToTitleCase from '../utils/camelCaseToTitleCase';

const TaxManager = ({userInfo}) => {
    const [taxSchedule, setTaxSchedule] = useState({});
    const [editingField, setEditingField] = useState(null);
    const [tempValue, setTempValue] = useState('');
  
    useEffect(() => {
        const docRef = doc(db, "taxes-and-fees", "stateTaxes");
        const unsubscribe = onSnapshot(docRef, (docSnapshot) => {
          if (docSnapshot.exists()) {
            const data = docSnapshot.data();
            const sortedKeys = Object.keys(data).sort(); // Sort keys alphabetically
            const updatedTaxSchedule = sortedKeys.reduce((acc, key) => {
              // Access the value using the sorted key
              const value = data[key];
              // Determine the field type based on the value
              const fieldType = typeof value === 'number' && value < 1 ? 'percent' : 'wholeNumber';
              acc[key] = {
                value: value || 0, // Ensure value is not undefined
                fieldType: fieldType
              };
              return acc;
            }, {});
            setTaxSchedule(updatedTaxSchedule);
          } else {
            console.log("No such document!");
          }
        });
      
        return () => unsubscribe();
      }, []);
      
  
    const handleUpdateTaxSchedule = async () => {
      if (editingField !== null) {
        const updatedData = {
          [editingField]: parseInt(tempValue),
        };
        await updateDoc(doc(db, 'taxes-and-fees', 'stateTaxes'), updatedData);
  
        setTaxSchedule((prevState) => ({
          ...prevState,
          [editingField]: parseInt(tempValue),
        }));
  
        setEditingField(null);
      }
    };
  
    const handleEdit = (field) => {
      setEditingField(field);
      setTempValue(taxSchedule[field].value);
    };
  
    const handleSave = () => {
      handleUpdateTaxSchedule();
    };
  
    const handleCancel = () => {
      setEditingField(null);
    };
  
    const handleChange = (e) => {
      const { value } = e.target;
      setTempValue(value);
    };
  
    const getAdornment = (fieldType) => {
      return (
        <InputAdornment position="end">
          {fieldType === 'percent' ? <PercentIcon /> : <AttachMoneyIcon />}
        </InputAdornment>
      );
    };
  
    return (
      <div className="p-4">
        <h1 className="text-xl font-bold mb-4">Taxes & Fees Manager</h1>
        <form className="space-y-4">
          {Object.entries(taxSchedule).map(([key, { value, fieldType }]) => (
            <div key={key} className="flex items-center">
              <label htmlFor={key} className="w-1/2">
                {camelCaseToTitleCase(key)}
              </label>
              <TextField
                type="number"
                id={key}
                name={key}
                value={editingField === key ? tempValue : fieldType === 'percent' ? (value * 100).toFixed(4) : value.toFixed(4)}
                onChange={handleChange}
                className="w-1/2"
                variant="outlined"
                InputProps={{
                  endAdornment: editingField !== key ? getAdornment(fieldType) : "",
                }}
                inputProps={{
                  maxLength: 10,
                }}
                disabled={editingField !== key}
              />
              {editingField === key ? (
                <>
                  <IconButton onClick={handleSave}>
                    <SaveIcon />
                  </IconButton>
                  <IconButton onClick={handleCancel}>
                    <CancelIcon />
                  </IconButton>
                </>
              ) : (
                <IconButton onClick={() => handleEdit(key)}>
                  <EditIcon />
                </IconButton>
              )}
            </div>
          ))}
        </form>
      </div>
    );
  };
  
  export default TaxManager;
  