import BlueButton from "../Components/assets/forms/BlueButton";
import {
  Engineering as EngineeringIcon,
  ArrowBack as ArrowBackIcon,
} from "@mui/icons-material";
import { NEW_SUPPORT_TICKET_ROUTE } from "../utils/routes";
import { useNavigate } from "react-router-dom";
import { Fragment, useEffect, useState } from "react";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";

const Maintenance = () => {
  const navigate = useNavigate();

  const handleNavigate = (e) => {
    e.preventDefault();
    navigate(NEW_SUPPORT_TICKET_ROUTE);
  };

  const [maintenanceMessage, setMaintenanceMessage] = useState('');
  const [loading, setLoading] = useState(true);

  const handleManaging = () => {
    setLoading(true);
    const docRef = doc(db, "managing", "maintenance-details");
    const unsubscribe = onSnapshot(docRef, (docSnap) => {
      if (docSnap.exists()) {
        const message = docSnap.data()?.message;
        setLoading(false);
        setMaintenanceMessage(message);
      } else {
        setLoading(false);
        setMaintenanceMessage('');
      }
    });
    return unsubscribe;
  };

  useEffect(() => {
    const unsubscribe = handleManaging();
    return () => unsubscribe();
  }, []);

  return (
    <div className="flex w-full justify-center items-center h-[75vh] flex-col">
      <EngineeringIcon
        className="text-[#072a48]"
        sx={{ width: 100, height: 100, margin: "0 0 8px" }}
      />
      <p className="mb-4" style={{ fontSize: 24 }}>
        Maintenance
      </p>
      <p className="mb-4 text-center">
        {!loading && !maintenanceMessage ?
          <Fragment>
            The Southern Star Portal is down for maintenance.
            <br />
            If you need help immediately, please submit a support ticket.
          </Fragment> :
          !loading && maintenanceMessage ?
          maintenanceMessage.split('\\n').map((line, index, array) => (
            <Fragment key={index}>
              {line}
              {index < array.length - 1 && <br />}
            </Fragment>
          )) : <></>
        }
      </p>
      <BlueButton
        onClickFunction={handleNavigate}
        icon={<ArrowBackIcon />}
        text="Submit support ticket"
      />
    </div>
  );
};

export default Maintenance;
