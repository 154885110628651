import countAllViolations from "../countAllViolations";
import getScoreWeights from "../scores/getScoreWeights";
import scoreAge from "../scores/scoreAge";
import scoreClaimsAmount from "../scores/scoreClaimsAmount";
import scoreExp from "../scores/scoreExp";
import scoreMajor from "../scores/scoreMajor";
import scoreMinor from "../scores/scoreMinor";
import scoreNotIncluded from "../scores/scoreNotIncluded";
import scoreNumberOfClaims from "../scores/scoreNumberOfClaims";
import scoreOutOfDriving from "../scores/scoreOutOfDriving";

const aggregateDriverInfo = async (
  violations,
  drivers,
  crashes,
  setDataReady,
  inspections
) => {
  const aggregatedDrivers = {};

  // Step 1: Process violations
  violations.forEach((violation) => {
    const licenseNumber = violation?.LicenseNumber
      ? violation.LicenseNumber
      : null;

    if (!aggregatedDrivers[licenseNumber]) {
      aggregatedDrivers[licenseNumber] = {
        ...violation,
        ...drivers[licenseNumber],
        onPoliciesCount: 0,
        majorViolationsCount: 0,
        minorViolationsCount: 0,
        caseByCaseCount: 0,
        claimsCount: 0,
        claimsCountScore: 0,
        claimsAmountScore: 0,
        claimsAmount: 0,
        crashes: [],
        crashesCount: 0,
        inspections: [],
        inspectionsCount: 0,
      };
    }

    // Aggregate information
    aggregatedDrivers[licenseNumber].onPoliciesCount +=
      drivers[licenseNumber]?.policies?.length;
  });

  // Step 2: Join crashes
  crashes.forEach((crash) => {
    const licenseNumber = crash?.drvLicNbr;
    if (aggregatedDrivers[licenseNumber]) {
      aggregatedDrivers[licenseNumber].crashes.push(crash);
      aggregatedDrivers[licenseNumber].crashesCount += 1;
    } else {
      aggregatedDrivers[licenseNumber] = {
        ...drivers[licenseNumber],
        onPoliciesCount: 0,
        majorViolationsCount: 0,
        minorViolationsCount: 0,
        caseByCaseCount: 0,
        claimsCount: 0,
        claimsCountScore: 0,
        claimsAmountScore: 0,
        claimsAmount: 0,
        crashes: [crash],
        crashesCount: 1,
        inspections: [],
        inspectionsCount: 0,
      };
    }
  });

  // Step 3: Join Inspections
  inspections.forEach((inspection) => {
    const licenseNumber = inspection?.drvLicNbr;
    if (aggregatedDrivers[licenseNumber]) {
      aggregatedDrivers[licenseNumber].inspections.push(inspection);
      aggregatedDrivers[licenseNumber].inspectionsCount += 1;
    } else {
      aggregatedDrivers[licenseNumber] = {
        ...drivers[licenseNumber],
        onPoliciesCount: 0,
        majorViolationsCount: 0,
        minorViolationsCount: 0,
        caseByCaseCount: 0,
        claimsCount: 0,
        claimsCountScore: 0,
        claimsAmountScore: 0,
        claimsAmount: 0,
        crashes: [],
        crashesCount: 0,
        inspections: [inspection],
        inspectionsCount: 1,
      };
    }
  });

  // Step 3: Perform other aggregations
  await Promise.all(
    Object.keys(aggregatedDrivers).map(async (licenseNumber) => {
      const counts = await countAllViolations(violations, licenseNumber, crashes, aggregatedDrivers[licenseNumber].inspectionsCount);
      const weights = await getScoreWeights();
      const ageScore = await scoreAge(aggregatedDrivers[licenseNumber].dob);
      const expScore = await scoreExp(aggregatedDrivers[licenseNumber].yoe);
      const majorScore = await scoreMajor(counts.majorViolationsCount);
      const minorScore = await scoreMinor(counts.minorViolationsCount);
      const outOfDrivingScore = await scoreOutOfDriving(
        counts.outOfDrivingCount
      );
      const notIncludedScore = await scoreNotIncluded(counts.notIncludedCount);

      // Apply weights
      const weightedAge = ageScore * weights.age;
      const weightedExp = expScore * weights.exp;
      const weightedMajor = majorScore * weights.major;
      const weightedMinor = minorScore * weights.minor;
      const weightedOutOfDriving = outOfDrivingScore * weights.outOfDriving;
      const weightedNotIncluded = notIncludedScore * weights.notIncluded;

      const scoreTotal =
        weightedAge +
        weightedExp +
        weightedMajor +
        weightedMinor +
        weightedOutOfDriving +
        weightedNotIncluded;

      const result = scoreTotal > 0.6 ? "Need Training" : "No Training Needed";

      aggregatedDrivers[licenseNumber] = {
        ...aggregatedDrivers[licenseNumber],
        ...counts,
        claimsAmountScore: await scoreClaimsAmount(counts.claimsAmount),
        claimsCountScore: await scoreNumberOfClaims(counts.claimsCount),
        ageScore,
        expScore,
        majorScore,
        minorScore,
        outOfDrivingScore,
        notIncludedScore,
        weightedAge,
        weightedExp,
        weightedMajor,
        weightedMinor,
        weightedOutOfDriving,
        weightedNotIncluded,
        scoreTotal,
        result,
        crashes: aggregatedDrivers[licenseNumber].crashes,
        inspections: aggregatedDrivers[licenseNumber].inspections
      };
    })
  );

  setDataReady(true);
  return Object.values(aggregatedDrivers);
};

export default aggregateDriverInfo;
