import React from "react";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../../firebase";
import ApiService from "../../../../services/southern-api/apiService";

export default function CommodityDetails({
  formData,
  setFormData,
  policyID,
  field,
  conditional,
  label
}) {

  const getModuleValueFromCurrentUrl = () => {
    const url = window.location.href;
    const urlObject = new URL(url);
    return urlObject.searchParams.get("module");
  };
  const moduleValue = getModuleValueFromCurrentUrl();
  const apiService = new ApiService();

  const handleChangeStandardCommodityExplanation = async (e) => {
    const updatedValues = { ...formData.standardCommodityPercentages };
    updatedValues[e.target.name] = e.target.value;

    if (Boolean(moduleValue)) {
      setFormData({
        ...formData,
        standardCommodityPercentages: updatedValues,
      })
      await apiService.renewalProcessUpdatePolicyField({
        policyID,
        payload: {
          standardCommodityPercentages: updatedValues,
        }
      })
    } else {
      await updateDoc(doc(db, "policy-applications", policyID), {
        standardCommodityPercentages: updatedValues,
      });
    }
  };

  return (
    <>
      {conditional && (
        <div className="flex flex-col space-y-2">
          <label className="text-xs font-semibold">{label}:</label>
          <input
            name={field}
            type="text"
            onChange={handleChangeStandardCommodityExplanation}
            className="p-2 border rounded focus:outline-none focus:border-blue-500"
            value={formData.standardCommodityPercentages[field] || ""}
          />
        </div>
      )}
    </>
  );
}
