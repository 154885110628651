import { ACCOUNT_ROUTE } from "../../utils/routes";
import { useNavigate } from "react-router-dom";
import { getAuth, updatePassword } from "firebase/auth";
import useTranslations from "../../Context/actions/useTranslations";

const useChangePassword = () => {
  const navigate = useNavigate();
  const { t } = useTranslations();
  const auth = getAuth();
  const user = auth.currentUser;

  const handleSubmit = async (e, formData) => {
    e.preventDefault();
    const { newPassword, confirmPassword } = formData
    if (newPassword !== confirmPassword)
      return alert("Passwords do not match. Try again");
    updatePassword(user, newPassword)
      .then(() => {
        console.log("Password updated");
        navigate(ACCOUNT_ROUTE);
      })
      .catch((error) => {
        console.log("Error: " + error);
      });
  };

  const formSettings = {
    title: t("change-password.title"),
    onSubmit: handleSubmit,
    inputs: [
      {
        label: t("change-password.new-password"),
        type: "password",
        name: 'newPassword',
        required: true
      },
      {
        label: t("change-password.confirm-password"),
        type: "password",
        name: 'confirmPassword',
        required: true
      },
    ],
    buttonLabel: t("change-password.update-password")
  };

  return {
    handleSubmit,
    formSettings
  };
};

export default useChangePassword;
