import React from "react";
import updatePolicyField, { updatePolicyFieldLosses } from "../../utils/updatePolicyField";

const TextInput = (props) => {
  function debounce(func, delay) {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(this, args), delay);
    };
  }

  const debouncedUpdateField = debounce((value) => {
    if (props.doNotDebounce) return;
    if (props.debounceLosses) {
      updatePolicyFieldLosses(props.policyID, {
        [props.debounceLosses.section]: {
          [props.debounceLosses.category]: {
            [props.property]: value,
          }
        }
      });
      return
    }
    updatePolicyField(props.policyID, [props.property], value);
  }, 300);

  const handleChange = (e) => {
    let inputValue = e.target.value;
    if (props.withoutSpaces) {
      inputValue = inputValue.replace(/\s/g, "");
    }
    props.setFormData({
      ...props.formData,
      [props.property]: inputValue,
    });
    // Ejecuta el debounce al cambiar el valor
    debouncedUpdateField(inputValue);
  };

  const handlePaste = (e) => {
    e.preventDefault(); // Prevent the default paste action

    // Get the pasted text from the clipboard
    const pastedText = (e.clipboardData || window.clipboardData).getData(
      "text"
    );

    // Split the pasted text by spaces
    const words = pastedText.trim().split(/\s+/);

    if (words.length > 0) {
      props.setFormData({
        ...props.formData,
        driverFirst: words[0], // First word goes to the first name
        driverLast: words.slice(1).join(" "), // Remaining words go to the last name
      });
    }
  };

  return (
    <div className="my-2">
      <label
        htmlFor={props?.property}
        className={
          props?.labelCustomClass
            ? props.labelCustomClass
            : "block text-sm font-medium text-gray-700"
        }
      >
        {props?.label}
      </label>
      <input
        id={props?.property}
        type="text"
        className={
          props?.customClass
            ? props.customClass
            : "mt-1 p-1 block w-full rounded-md border border-gray-300 shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        }
        value={props?.formData?.[props?.property] || ""}
        onPaste={props.allowPaste ? handlePaste : null}
        onChange={handleChange}
      />
    </div>
  );
};

export default TextInput;