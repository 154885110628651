import React, { useState } from 'react';
import { Card, CardContent, Typography, Grid, TextField } from '@mui/material';

const SafetyManual = () => {
  // Define questions
  const questions = [
    { id: 'governedPowerUnits', label: 'Governed power units? (Y/N)', type: 'text' },
    { id: 'speedLimit', label: 'Speed limit:', type: 'text' },
    { id: 'wayOfCommunication', label: 'Way of communication with drivers:', type: 'textarea' },
    { id: 'mostCommonRoute', label: 'Most common route, loadboard and/or load agreement? SPECIFY AT LEAST ONE SPECIFIC ROUTE', type: 'textarea' },
    { id: 'statesAndCommodities', label: 'What states do you haul in and-to-and commodities you haul?', type: 'textarea' },
    { id: 'unauthorizedPassengerPolicy', label: 'Unauthorized passenger policy signed by driver:', type: 'text' },
    { id: 'noTextingViolation', label: 'NO TEXTING VIOLATION allowed signed by driver:', type: 'text' },
    { id: 'noPhoneCallViolation', label: 'NO PHONE CALL VIOLATION allowed signed by driver:', type: 'text' },
    { id: 'rewardProgram', label: 'Reward program for drivers:', type: 'textarea' },
    { id: 'incentivesProgramWork', label: 'How does the incentives program work?', type: 'textarea' },
    { id: 'incentivesCriteria', label: 'Criteria for incentives program:', type: 'textarea' },
    { id: 'incentivesFrequency', label: 'How often are incentives given? (Monthly, bimonthly):', type: 'text' },
    { id: 'exampleWriteUp', label: 'Example of the write up:', type: 'textarea' },
    { id: 'documentedTrainingProgram', label: 'Documented training program:', type: 'textarea' },
  ];

  // State to manage responses
  const [responses, setResponses] = useState(
    questions.reduce((acc, { id }) => ({ ...acc, [id]: '' }), {}) // Initialize state dynamically based on questions
  );

  const handleInputChange = (id, value) => {
    setResponses((prevResponses) => ({
      ...prevResponses,
      [id]: value,
    }));
  };

  return (
    <Card sx={{ maxWidth: 800, margin: 'auto', padding: 2, marginBottom: 2 }}>
      <CardContent>
        <Typography variant="h5" component="div" gutterBottom>
          Safety Manual
        </Typography>
        <Grid container spacing={2}>
          {questions.map(({ id, label, type }) => (
            <Grid item xs={12} key={id}>
              <TextField
                fullWidth
                multiline={type === 'textarea'}
                rows={type === 'textarea' ? 4 : 1}
                variant="outlined"
                label={label}
                value={responses[id] || ''}
                onChange={(e) => handleInputChange(id, e.target.value)}
              />
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default SafetyManual;
