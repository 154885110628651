import React, { useMemo, useState } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../../firebase";
import DriverFlagReasonsModal from "./DriverFlagReasonsModal";
import { Button, Box } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import FlagIcon from "@mui/icons-material/Flag";
import checkDriverForFlags from "../../../utils/checkDriverForFlags";
import useTranslations from "../../../../Context/actions/useTranslations";

const CustomTwoButton = ({
  onBlueClick,
  onRedClick,
  blueText = "Accept",
  redText = "Flag",
  blueColor = "#072a48",
  redColor = "#8B0000",
  blueIcon = <CheckIcon />,
  redIcon = <FlagIcon />,
  disabledBlue = false,
}) => {
  return (
    <Box sx={{
      display: 'flex',
      borderRadius: 1,
      overflow: 'hidden',
      marginTop: '10px'
    }}>
      <Button
        onClick={onBlueClick}
        startIcon={blueIcon}
        sx={{
          flex: 1,
          borderRadius: 0,
          backgroundColor: blueColor,
          color: "#fff",
          padding: "3px 12px 3px 12px",
          '&:hover': {
            backgroundColor: blueColor,
            opacity: 0.9,
          },
          '&:disabled': {
            backgroundColor: '#ccc',
            color: '#fff',
            cursor: 'not-allowed',
          }
        }}
        disabled={disabledBlue}
      >
        {blueText}
      </Button>
      <Button
        onClick={onRedClick}
        startIcon={redIcon}
        sx={{
          flex: 1,
          borderRadius: 0,
          backgroundColor: redColor,
          color: "#fff",
          padding: "3px 12px 3px 12px",
          '&:hover': {
            backgroundColor: redColor,
            opacity: 0.9,
          },
        }}
      >
        {redText}
      </Button>
    </Box>
  );
};

export default function RaiseDriverFlag(props) {
  const {
    formData,
    driverID,
    policyID,
    driver,
    handleOpenGoodToGoDriverModal,
    insuredInfo
  } = props;
  const { agencyID, insuredID } = formData;

  const { t } = useTranslations()

  const raiseFlag = () => {
    setOpenRaiseDriverFlagModal(true);
  };

  const driverDocRef = doc(
    db,
    "agencies",
    agencyID,
    "insureds",
    insuredID,
    "drivers",
    driverID
  );

  const removeFlag = async () => {
    // verify user wants to remove the flag
    const proceed = window.confirm(
      "Are you sure you want to remove the flag from this driver?"
    );
    updateDoc(driverDocRef, {
      flags: {
        flaggedByUnderwriter: false,
        flaggedByUnderwriterDate: null,
        flaggedByUnderwriterReasons: [],
        flaggedPolicy: null,
      },
    });
  };

  const [openRaiseDriverFlagModal, setOpenRaiseDriverFlagModal] =
    useState(false);
  const handleCloseRaiseDriverFlagModal = () => {
    setOpenRaiseDriverFlagModal(false);
  };

  const redFlags = checkDriverForFlags(driver, t)

  const flagsInfo = useMemo(() => {
    let totalFlags = []
    if (redFlags.length > 0) {
      totalFlags = [...redFlags]
    }
    if (driver?.flags?.flaggedByUnderwriter) {
      totalFlags = [
        ...totalFlags,
        ...driver?.flags?.flaggedByUnderwriterReasons
      ]
    }
    return {
      totalFlags,
      count: totalFlags.length,
      thereAreFlags: Boolean(totalFlags.length > 0)
    }
  }, [driver])

  return (
    <div>
      <CustomTwoButton
        onBlueClick={handleOpenGoodToGoDriverModal}
        onRedClick={() => {
          !driver?.flags?.flaggedByUnderwriter && raiseFlag();
          driver?.flags?.flaggedByUnderwriter && removeFlag();
        }}
        blueText={
          Boolean(driver?.goodToGo) ? "Undo Accept" : "Accept"
        }
        redText={
          !driver?.flags || driver?.flags?.flaggedByUnderwriter === false
            ? "Flag"
            : "Remove Flag"
        }
        disabledBlue={flagsInfo?.thereAreFlags}
      />
      <DriverFlagReasonsModal
        title="Reasons for Flag"
        text="Please enter the reason(s) why the driver has been flagged."
        addButtonText="Add A Reason"
        open={openRaiseDriverFlagModal}
        onClose={handleCloseRaiseDriverFlagModal}
        driverDocRef={driverDocRef}
        policyID={policyID}
        insuredInfo={insuredInfo}
        formData={formData}
      />
    </div>
  );
}
