import React from "react";
import useTranslations from "../../../../Context/actions/useTranslations";
import MultiUpload from "../../../assets/forms/DynamicPropertiesInputs/MultiUpload";
import calculateAge from "../../../utils/calculateAge";

const LicenseUploadForm = ({
  licenseNumber,
  dob,
  type,
  tutor,
  fileObjectsLicenseFront,
  setFileObjectsLicenseFront,
  fileObjectsLicenseBack,
  setFileObjectsLicenseBack,
  fileObjectsMvr,
  setFileObjectsMvr,
  fileObjectsCaDriverAbstract,
  setFileObjectsCaDriverAbstract,
  fileObjectsMedicalForm,
  setFileObjectsMedicalForm,
  fileObjectsAdditionalForm,
  setFileObjectsAdditionalForm,
  fileObjectsOtherDocuments,
  setFileObjectsOtherDocuments,
  isViewMode = false,
}) => {
  const { t } = useTranslations();

  const uploadConfigs = [
    {
      condition: (type === "CA" && fileObjectsCaDriverAbstract.length > 0) || (!isViewMode && type === "CA"),
      label: t("plm.drivers.details.ca-driver-abstract"),
      property: "caDriverAbstract",
      formData: fileObjectsCaDriverAbstract,
      setFormData: setFileObjectsCaDriverAbstract,
      storageFile: `files/drivers/${licenseNumber}`,
      multiple: false,
    },
    {
      condition: (isViewMode && fileObjectsLicenseFront.length > 0) || !isViewMode,
      label: t("plm.drivers.details.front-license"),
      property: "driverLicenseFront",
      formData: fileObjectsLicenseFront,
      setFormData: setFileObjectsLicenseFront,
      storageFile: `files/drivers/${licenseNumber}`,
      multiple: false,
      isViewMode,
    },
    {
      condition: (isViewMode && fileObjectsLicenseBack.length > 0) || !isViewMode,
      label: t("plm.drivers.details.back-license"),
      property: "driverLicenseBack",
      formData: fileObjectsLicenseBack,
      setFormData: setFileObjectsLicenseBack,
      storageFile: `files/drivers/${licenseNumber}`,
      multiple: false,
      isViewMode,
    },
    {
      condition: (type === "USA" && fileObjectsMvr.length > 0) || (!isViewMode && type === "USA"),
      label: t("plm.drivers.details.mvr"),
      property: "MVR",
      formData: fileObjectsMvr,
      setFormData: setFileObjectsMvr,
      storageFile: `files/drivers/${licenseNumber}`,
      multiple: false,
    },
    {
      condition: (calculateAge(dob) > 64 && fileObjectsMedicalForm.length > 0) || (!isViewMode && calculateAge(dob) > 64),
      label: t("plm.drivers.details.medical-long-form"),
      property: "medicalLongForm",
      formData: fileObjectsMedicalForm,
      setFormData: setFileObjectsMedicalForm,
      storageFile: `files/drivers/${licenseNumber}`,
      multiple: false,
    },
    {
      condition: (tutor && fileObjectsAdditionalForm.length > 0) || (!isViewMode && tutor),
      label: t("plm.drivers.details.driver-additional-documents"),
      property: "additionalDocument",
      formData: fileObjectsAdditionalForm,
      setFormData: setFileObjectsAdditionalForm,
      storageFile: `files/drivers/${licenseNumber}/additional-docs`,
      multiple: true,
    },
    {
      condition: (fileObjectsOtherDocuments.length > 0) || !isViewMode,
      label: t("plm.drivers.details.other-documents"),
      property: "otherDocuments",
      formData: fileObjectsOtherDocuments,
      setFormData: setFileObjectsOtherDocuments,
      storageFile: `files/drivers/${licenseNumber}/other-docs`,
      multiple: true,
    },
  ];

  return (
    <>
      {uploadConfigs.map(({ condition, label, property, formData, setFormData, storageFile, multiple, isViewMode }) =>
        condition ? (
          <div className='mb-2' key={property}>
            <MultiUpload
              label={label}
              property={property}
              formData={formData}
              setFormData={setFormData}
              storageFile={storageFile}
              multiple={multiple}
              changeNormal={true}
              fileInButton={!isViewMode}
              width="100%"
              dissapearWhenImgExists={true}
              showInAPopup={isViewMode ? false : true}
              disabled={isViewMode}
              isViewMode={isViewMode}
            />
          </div>
        ) : null
      )}
    </>
  );
};

export default LicenseUploadForm;
