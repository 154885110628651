// TODO: The styling within this component/its children don't match the rest of the styling elsewhere
import React from "react";
import Notes from "./agent/";
import { auth } from "../../../firebase";

export default function NotesPanel({ formData, userInfo, policyID }) {
  return (
    <div>
      <Notes
        firstBreadcrumb={formData.documentType}
        formData={formData}
        userInfo={userInfo}
        documentID={policyID}
        auth={auth}
        collectionPath={`policy-applications/${policyID}/agent-notes`}
      />
    </div>
  );
}
