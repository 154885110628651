// TODO: MAKE LETTERS RESPONSIVE
import React from 'react'
import logo from "../../../assets/images/sslogowhiteshadow.png";

export default function NORPreview(props) {
    const ref = React.createRef();
    return (
    <>
    {props.selected !== "" && props.selectedCompanyInfo && (
    <main ref={ref}
    style={{
      padding: "25px",
      maxWidth: "790px",
      fontSize: "large",
    }}>
    <table style={{width: "100%"}}>
      <tr style={{backgroundColor: "white"}}>
        <td style={{width: "50%", verticalAlign: "top"}}>
          <p style={{marginBottom: "0"}}>Southern Star MGA, LLC</p>
          <p style={{marginTop: "2px", marginBottom: "0"}}>9703 N Interstate 35</p>
          <p style={{marginTop: "2px", marginBottom: "0"}}>
            San Antonio, Texas 78233
          </p>
          <p style={{marginTop: "2px"}}>(210) 948-0402</p>
        </td>
        <td style={{width: "50%", textAlign: "right", verticalAlign: "top"}}>
          <img
            src={logo}
            alt="Southern Star Logo"
            style={{float: "right", maxHeight: "125px", marginBottom: "15px"}}
          />
        </td>
      </tr>
    </table>

    <h1 style={{textAlign: "center", verticalAlign: "top", fontWeight: "700", fontSize: "24px"}}>
      NOTICE OF REINSTATEMENT
    </h1>
    <p style={{marginLeft: "auto", marginRight: "auto", textAlign: "center"}}>
      <span style={{fontWeight: "600"}}>Insuring Company: </span> Accredited
      Specialty Insurance Company Inc
    </p>

    <p style={{width: "100%", textAlign: "right",marginTop: "15px",marginBottom: "15px",}}>
      Mailing Date: {new Date().toLocaleDateString().split(",")[0]}
    </p>

    <table style={{width: "100%",marginBottom: "25px",}}>
      <tr style={{backgroundColor: "white"}}>
        <td style={{width: "40%", textAlign: "right", verticalAlign: "top"}}>
          <p>INSURED NAME AND ADDRESS:</p>
        </td>
        <td style={{verticalAlign: "top"}}>
          <p style={{marginBottom: "0",}}>{props.selectedCompanyInfo.company || ""}</p>
          <p style={{marginTop: "2px", marginBottom: "0",}}>{props.selectedCompanyInfo.address || ""}</p>
          <p style={{marginTop: "2px",}}>{props.selectedCompanyInfo.city || ""}, {props.selectedCompanyInfo.state || ""} {props.selectedCompanyInfo.zip || ""}</p>
        </td>
      </tr>
      <tr style={{backgroundColor: "white"}}>
        <td style={{textAlign: "right", verticalAlign: "top"}}>
          <p>PRODUCER NAME AND ADDRESS:</p>
        </td>
        <td style={{verticalAlign: "top"}}>
          <p style={{marginBottom: "0",}}>Licona Insurance Group</p>
          <p style={{marginTop: "2px", marginBottom: "0",}}>369 Shadow Mountain Drive</p>
          <p style={{marginTop: "2px",}}>El Paso, TX 79912</p>
        </td>
      </tr>
    </table>

    <p style={{marginBottom: "10px",}}>Prepared by Southern Star MGA, LLC</p>

    <p style={{marginBottom: "20px",}}>
      Cancellation of this policy(ies) is hereby withdrawn so that coverage will
      continue with no interruption until the expiration date or further
      cancellation.
    </p>

    <p>Policy Numbers:</p>
    <ul style={{marginBottom: "10px",}}>
      <li>{props.letterPolicyNumber}</li>
      <li>{props.letter2ndPolicyNumber}</li>
    </ul>

    <p style={{marginBottom: "10px",}}>Policy Term: {props.policyTerm}</p>

    <p>
      Effective Date of Reinstatement: {props.dateOfReinstatement.toLocaleString().split(",")[0] || ""}
      
    </p>
    <p style={{marginBottom: "10px",}}>12:01 A.M. Standard Time at your mailing address</p>

    <p style={{marginTop: "20px", marginBottom: "40px",}}>All other terms and conditions remain unchanged.</p>

    <p>
      For further information, please contact your insurance broker immediately
      or contact us at (210) 948-0402.
    </p>

    <p style={{marginTop: "50px",}}>CAL SS 21 030</p>

    </main>
    )}
    </>
  )
}
