import { useContext } from "react"
import { AppContext, initialState } from "../AppContext"
import { SET_PAGINATIONS } from "../types"

const usePaginationsState = () => {
    const { state, dispatch } = useContext(AppContext) || {
        state: initialState,
        dispatch: () => null
    }
    const { paginations } = state || initialState

    const setPaginations = result => {
        dispatch({ type: SET_PAGINATIONS, payload: result })
    }

    function calculateOffset(currentPage, itemsPerPage) {
        const startAfter = currentPage > 0 ? currentPage * itemsPerPage : 0;
        return startAfter
      }

    return {
        setPaginations,
        paginations,
        calculateOffset
    }
}

export default usePaginationsState