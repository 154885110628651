import React from "react";
import updatePolicyField from "../../utils/updatePolicyField";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Tooltip } from "@mui/material";

export default function SelectDropdown({
  policyID,
  formData,
  options,
  fieldName,
  label,
  customClass,
  disabled,
  labelTitle
}) {
  const optionElements = options.map((option) => (
    <option key={option.value} value={option.value}>
      {option.label}
    </option>
  ));
  return (
    <>
    <Tooltip title={labelTitle || ""}>
      <label  className="text-capitalize mt-1">{label}:</label>
      <select
        disabled={disabled}
        onChange={(e) => {
          updatePolicyField(policyID, fieldName, e.target.value);
        }}
        className={`${
          customClass
            ? customClass
            : `appearance-none block w-full bg-white border border-gray-300 rounded-md py-1 px-1 leading-tight focus:outline-none focus:shadow-outline-blue focus:border-blue-300`
        }`}
        value={formData[fieldName]}
      >
        <option value="">Select</option>
        {optionElements}
      </select>
      </Tooltip>
    </>
  );
}
