import { collection, doc, getDocs } from "firebase/firestore";
import { db } from "../../firebase";

const getCurrentPolicyTotals = async (
  agencyID,
  insuredID,
  policyID,
  endoID
) => {
  try {
    const existingTruckListRef = collection(
      db,
      "agencies",
      agencyID,
      "insureds",
      insuredID,
      "power-units"
    );

    // const endoRef = doc(
    //     db,
    //     "policy-applications",
    //     policyID,
    //     "endorsements",
    //     endoID
    // )
    // const endoTrucksToAmmendToList = endoRef.data().data;
    const truckList = await getDocs(existingTruckListRef);

    // Filter documents to find those that contain the correct policyID and have a status of "Active"
    const activePowerUnits = truckList.docs.filter((doc) => {
      const policiesDetails = doc.data().policiesDetails;
      return policiesDetails.some(
        (policy) => policy.id === policyID && policy.status === "Active"
      );
    });

    // Calculate the total premium for active policies
    const totalPremium = activePowerUnits.reduce((acc, curr) => {
      const policiesDetails = curr.data().policiesDetails;
      const policy = policiesDetails.find(
        (policy) => policy.id === policyID && policy.status === "Active"
      );
      return acc + (policy?.adjustedRate || 0); // Safeguard against undefined rates
    }, 0);

    return totalPremium;
  } catch (error) {
    console.error("Error fetching power units:", error);
  }
};

export default getCurrentPolicyTotals;
