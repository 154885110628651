const styles = {
    wrapper: 'max-w-[700px] mx-auto mt-3',
    fullWrapper: 'mx-auto max-w-[650]',
    titleContainer: 'flex justify-between items-center',
    title: 'title-1',
    switch: 'block text-xl text-white bg-[#072a48] hover:bg-blue-700 py-2 px-4 mt-3 mb-3 rounded-md',
    input: 'block w-full mx-auto rounded-md font-medium border-2 p-3 mt-4 sm:text-xl md:text-xl',
    select: 'block w-full mx-auto rounded-md font-medium border-2 p-3 mt-4 sm:text-xl md:text-xl placeholder-gray-500',
    button: disabled => (
        `block mx-auto text-xl text-white bg-[#072a48]
        ${disabled ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-700'}
        py-2 px-4 mt-3 mb-3 rounded-md`
    )
}

export default styles