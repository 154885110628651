export const filePolicyStructuring = type => {
  const code =
    type === "NAFTA AL"
      ? "nafta-al"
      : type === "NAFTA GL"
      ? "nafta-gl"
      : type === "Domestic AL"
      ? "domestic-al"
      : type === "Domestic GL"
      ? "domestic-gl"
      : "";
  return code;
};

export const USER_INFO_ROLE = {
  HR_ROLE: "HR",
  CLIENT_ROLE: "Client",
  ADMIN_ROLE: "Admin",
  AGENT_ROLE: "Agent",
  SUBUSER_ROLE: "Sub-User",
  UNDERWRITER_ROLE: "Underwriter",
  USER_ROLE: "User",
};

export const USER_INFO_STATUS = {
  APPOINTED_STATUS: "Appointed",
  UNAPPOINTED_STATUS: "Unappointed",
};

export const USER_INFO_STATUS_ES = {
  APPOINTED_STATUS: "Designación",
  UNAPPOINTED_STATUS: "Sin designación",
};

const {
  APPOINTED_STATUS,
  UNAPPOINTED_STATUS
} = USER_INFO_STATUS

const {
  APPOINTED_STATUS: APPOINTED_STATUS_ES,
  UNAPPOINTED_STATUS: UNAPPOINTED_STATUS_ES
} = USER_INFO_STATUS_ES

export const handleStatus = (status, language) => {
  if (language === "en") return status
  switch (status) {
    case APPOINTED_STATUS:
      return APPOINTED_STATUS_ES
    case UNAPPOINTED_STATUS:
      return UNAPPOINTED_STATUS_ES
    default:
      return status
  }
}

const SENT_STATUS = {
  SUBMITTED: "Submitted",
  UNSUBMITTED: "Unsubmitted",
}

const {
  SUBMITTED,
  UNSUBMITTED
} = SENT_STATUS

const SENT_STATUS_ES = {
  SUBMITTED: "Enviado",
  UNSUBMITTED: "No Enviado",
}

const {
  SUBMITTED: SUBMITTED_ES,
  UNSUBMITTED: UNSUBMITTED_ES
} = SENT_STATUS_ES

export const handleSentStatus = (status, language) => {
  if (language === "en") return status
  switch (status) {
    case SUBMITTED:
      return SUBMITTED_ES
    case UNSUBMITTED:
      return UNSUBMITTED_ES
    default:
      return status
  }
}

const DOCUMENT_TYPES = {
  APPLICATION: "Application",
  QUOTE_REQUEST: "Quote Request",
  POLICY: "Policy",
  ENDORSEMENT: "Endorsement",
}

const {
  APPLICATION,
  QUOTE_REQUEST,
  POLICY,
  ENDORSEMENT,
} = DOCUMENT_TYPES

const DOCUMENT_TYPES_ES = {
  APPLICATION: "Solicitud",
  QUOTE_REQUEST: "Solicitud de Cotización",
  POLICY: "Póliza",
  ENDORSEMENT: "Endoso",
}

const {
  APPLICATION: APPLICATION_ES,
  QUOTE_REQUEST: QUOTE_REQUEST_ES,
  POLICY: POLICY_ES,
  ENDORSEMENT: ENDORSEMENT_ES
} = DOCUMENT_TYPES_ES

export const handleDocumentTypeStatus = (status, language) => {
  if (language === "en") return status
  switch (status) {
    case APPLICATION:
      return APPLICATION_ES
    case QUOTE_REQUEST:
      return QUOTE_REQUEST_ES
    case POLICY:
      return POLICY_ES
    case ENDORSEMENT:
      return ENDORSEMENT_ES
    default:
      return status
  }
}

const SUBCOLLECTION_STATUS = {
  ACTIVE: "Active",
  PENDING: "Pending",
  PENDING_DELETION: "Pending Deletion",
  PENDING_EXCLUSION: "Pending Exclusion",
  DELETED: "Deleted",
  REJECTED: "Rejected",
}

const {
  ACTIVE,
  PENDING,
  PENDING_DELETION,
  PENDING_EXCLUSION,
  DELETED,
  REJECTED,
} = SUBCOLLECTION_STATUS

const SUBCOLLECTION_STATUS_ES = {
  ACTIVE: "Activo",
  PENDING: "Pendiente",
  PENDING_DELETION: "Pendiente de eliminación",
  PENDING_EXCLUSION: "Pendiente de exclusión",
  DELETED: "Eliminado",
  REJECTED: "Rechazado",
}

const {
  ACTIVE: ACTIVE_ES,
  PENDING: PENDING_ES,
  PENDING_DELETION: PENDING_DELETION_ES,
  PENDING_EXCLUSION: PENDING_EXCLUSION_ES,
  DELETED: DELETED_ES,
  REJECTED: REJECTED_ES,
} = SUBCOLLECTION_STATUS_ES

export const handleSubcollectionStatus = (status, language) => {
  if (language === "en" || !language) return status
  switch (status) {
    case ACTIVE:
      return ACTIVE_ES
    case PENDING:
      return PENDING_ES
    case PENDING_DELETION:
      return PENDING_DELETION_ES
    case PENDING_EXCLUSION:
      return PENDING_EXCLUSION_ES
    case DELETED:
      return DELETED_ES
    case REJECTED:
      return REJECTED_ES
    default:
      return status
  }
}

const ENDO_TYPE = {
  ADD_DRIVER: "Add Driver",
  ADD_DRIVERS: "Add Drivers",
  REMOVE_DRIVER: "Remove Driver",
  REMOVE_DRIVERS: "Remove Drivers",
  EXCLUDE_DRIVER: "Exclude Driver",
  EXCLUDE_DRIVERS: "Exclude Drivers",
  ADD_POWER_UNIT: "Add Power Unit",
  ADD_POWER_UNITS: "Add Power Units",
  REMOVE_POWER_UNIT: "Remove Power Unit",
  REMOVE_POWER_UNITS: "Remove Power Units",
  ADJUST_RADIUS: "Adjust Radius",
  ADD_WAIVER_OF_SUBROGATION: "Add Waiver Of Subrogation",
  ADD_TRAILER: "Add Trailer",
  ADD_TRAILERS: "Add Trailers",
  REMOVE_TRAILER: "Remove Trailer",
  REMOVE_TRAILERS: "Remove Trailers",
  SWAP_POWER_UNIT: "Swap Power Unit",
  SWAP_POWER_UNITS: "Swap Power Units",
  ADD_ADDITIONAL_INSUREDS: "Add Additional Insureds",
}

const {
  ADD_DRIVER,
  ADD_DRIVERS,
  REMOVE_DRIVER,
  REMOVE_DRIVERS,
  EXCLUDE_DRIVER,
  EXCLUDE_DRIVERS,
  ADD_POWER_UNIT,
  ADD_POWER_UNITS,
  REMOVE_POWER_UNIT,
  REMOVE_POWER_UNITS,
  ADJUST_RADIUS,
  ADD_WAIVER_OF_SUBROGATION,
  ADD_TRAILER,
  ADD_TRAILERS,
  REMOVE_TRAILER,
  REMOVE_TRAILERS,
  SWAP_POWER_UNIT,
  SWAP_POWER_UNITS,
  ADD_ADDITIONAL_INSUREDS
} = ENDO_TYPE

const ENDO_TYPE_ES = {
  ADD_DRIVER: "Agregar Conductor",
  ADD_DRIVERS: "Agregar Conductores",
  REMOVE_DRIVER: "Eliminar Conductor",
  REMOVE_DRIVERS: "Eliminar Conductores",
  EXCLUDE_DRIVER: "Excluir Conductor",
  EXCLUDE_DRIVERS: "Excluir Conductores",
  ADD_POWER_UNIT: "Agregar Unidad de Potencia",
  ADD_POWER_UNITS: "Agregar Unidades de Potencia",
  REMOVE_POWER_UNIT: "Eliminar Unidad de Potencia",
  REMOVE_POWER_UNITS: "Eliminar Unidades de Potencia",
  ADJUST_RADIUS: "Ajustar Radio",
  ADD_WAIVER_OF_SUBROGATION: "Agregar Renuncia de Subrogación",
  ADD_TRAILER: "Agregar Remolque",
  ADD_TRAILERS: "Agregar Remolques",
  REMOVE_TRAILER: "Eliminar Remolque",
  REMOVE_TRAILERS: "Eliminar Remolques",
  SWAP_POWER_UNIT: "Intercambiar Unidad de Potencia",
  SWAP_POWER_UNITS: "Intercambiar Unidades de Potencia",
  ADD_ADDITIONAL_INSUREDS: "Agregar Asegurados Adicionales",
}

const {
  ADD_DRIVER: ADD_DRIVER_ES,
  ADD_DRIVERS: ADD_DRIVERS_ES,
  REMOVE_DRIVER: REMOVE_DRIVER_ES,
  REMOVE_DRIVERS: REMOVE_DRIVERS_ES,
  EXCLUDE_DRIVER: EXCLUDE_DRIVER_ES,
  EXCLUDE_DRIVERS: EXCLUDE_DRIVERS_ES,
  ADD_POWER_UNIT: ADD_POWER_UNIT_ES,
  ADD_POWER_UNITS: ADD_POWER_UNITS_ES,
  REMOVE_POWER_UNIT: REMOVE_POWER_UNIT_ES,
  REMOVE_POWER_UNITS: REMOVE_POWER_UNITS_ES,
  ADJUST_RADIUS: ADJUST_RADIUS_ES,
  ADD_WAIVER_OF_SUBROGATION: ADD_WAIVER_OF_SUBROGATION_ES,
  ADD_TRAILER: ADD_TRAILER_ES,
  ADD_TRAILERS: ADD_TRAILERS_ES,
  REMOVE_TRAILER: REMOVE_TRAILER_ES,
  REMOVE_TRAILERS: REMOVE_TRAILERS_ES,
  SWAP_POWER_UNIT: SWAP_POWER_UNIT_ES,
  SWAP_POWER_UNITS: SWAP_POWER_UNITS_ES,
  ADD_ADDITIONAL_INSUREDS: ADD_ADDITIONAL_INSUREDS_ES
} = ENDO_TYPE_ES

export const handleEndorsementTypeStatus = (type, language) => {
  if (language === "en") return type
  switch (type) {
    case ADD_DRIVER:
      return ADD_DRIVER_ES
    case ADD_DRIVERS:
      return ADD_DRIVERS_ES
    case REMOVE_DRIVER:
      return REMOVE_DRIVER_ES
    case REMOVE_DRIVERS:
      return REMOVE_DRIVERS_ES
    case EXCLUDE_DRIVER:
      return EXCLUDE_DRIVER_ES
    case EXCLUDE_DRIVERS:
      return EXCLUDE_DRIVERS_ES
    case ADD_POWER_UNIT:
      return ADD_POWER_UNIT_ES
    case ADD_POWER_UNITS:
      return ADD_POWER_UNITS_ES
    case REMOVE_POWER_UNIT:
      return REMOVE_POWER_UNIT_ES
    case REMOVE_POWER_UNITS:
      return REMOVE_POWER_UNITS_ES
    case ADJUST_RADIUS:
      return ADJUST_RADIUS_ES
    case ADD_WAIVER_OF_SUBROGATION:
      return ADD_WAIVER_OF_SUBROGATION_ES
    case ADD_TRAILER:
      return ADD_TRAILER_ES
    case ADD_TRAILERS:
      return ADD_TRAILERS_ES
    case REMOVE_TRAILER:
      return REMOVE_TRAILER_ES
    case REMOVE_TRAILERS:
      return REMOVE_TRAILERS_ES
    case SWAP_POWER_UNIT:
      return SWAP_POWER_UNIT_ES
    case SWAP_POWER_UNITS:
      return SWAP_POWER_UNITS_ES
    case ADD_ADDITIONAL_INSUREDS:
      return ADD_ADDITIONAL_INSUREDS_ES
    default:
      return type
  }
}

export const LANGUAGES = {
  spanish: "es",
  english: "en",
};

export const typeSupportTicketOptions = [
  { id: 1, key: "technical-issue", label: "Technical Issue" },
  { id: 2, key: "underwriting-issue", label: "Underwriting Issue" },
];

export const countryCodeOptions = [
  {
    id: 0,
    key: "",
    label: "Choose an extension",
  },
  {
    id: 1,
    key: "+1",
    label: "USA (+1)",
  },
  {
    id: 2,
    key: "+52",
    label: "Mexico (+52)",
  },
];

export const businessStructureOptions = [
  {
    id: 0,
    key: "",
    label: "Choose One",
  },
  {
    id: 1,
    key: "Sole Proprietorship",
    label: "Sole Proprietorship",
  },
  {
    id: 2,
    key: "Partnership",
    label: "Partnership",
  },
  {
    id: 3,
    key: "LLC",
    label: "LLC",
  },
  {
    id: 4,
    key: "Corporation",
    label: "Corporation",
  },
]

export const companyUSStateOptions = [
  {
    id: 0,
    key: "",
    label: "Select an state",
  },
  {
    id: 1,
    key: "AL",
    label: "Alabama",
  },
  {
    id: 2,
    key: "AK",
    label: "Alaska",
  },
  {
    id: 3,
    key: "AZ",
    label: "Arizona",
  },
  {
    id: 4,
    key: "AR",
    label: "Arkansas",
  },
  {
    id: 5,
    key: "CA",
    label: "California",
  },
  {
    id: 6,
    key: "CO",
    label: "Colorado",
  },
  {
    id: 7,
    key: "CT",
    label: "Connecticut",
  },
  {
    id: 8,
    key: "DE",
    label: "Delaware",
  },
  {
    id: 9,
    key: "FL",
    label: "Florida",
  },
  {
    id: 10,
    key: "GA",
    label: "Georgia",
  },
  {
    id: 11,
    key: "HI",
    label: "Hawaii",
  },
  {
    id: 12,
    key: "ID",
    label: "Idaho",
  },
  {
    id: 13,
    key: "IL",
    label: "Illinois",
  },
  {
    id: 14,
    key: "IN",
    label: "Indiana",
  },
  {
    id: 15,
    key: "IA",
    label: "Iowa",
  },
  {
    id: 16,
    key: "KS",
    label: "Kansas",
  },
  {
    id: 17,
    key: "KY",
    label: "Kentucky",
  },
  {
    id: 18,
    key: "LA",
    label: "Louisiana",
  },
  {
    id: 19,
    key: "ME",
    label: "Maine",
  },
  {
    id: 20,
    key: "MD",
    label: "Maryland",
  },
  {
    id: 21,
    key: "MA",
    label: "Massachusetts",
  },
  {
    id: 22,
    key: "MI",
    label: "Michigan",
  },
  {
    id: 23,
    key: "MN",
    label: "Minnesota",
  },
  {
    id: 24,
    key: "MS",
    label: "Mississippi",
  },
  {
    id: 25,
    key: "MO",
    label: "Missouri",
  },
  {
    id: 26,
    key: "MT",
    label: "Montana",
  },
  {
    id: 27,
    key: "NE",
    label: "Nebraska",
  },
  {
    id: 28,
    key: "NV",
    label: "Nevada",
  },
  {
    id: 29,
    key: "NH",
    label: "New Hampshire",
  },
  {
    id: 30,
    key: "NJ",
    label: "New Jersey",
  },
  {
    id: 31,
    key: "NM",
    label: "New Mexico",
  },
  {
    id: 32,
    key: "NY",
    label: "New York",
  },
  {
    id: 33,
    key: "NC",
    label: "North Carolina",
  },
  {
    id: 34,
    key: "ND",
    label: "North Dakota",
  },
  {
    id: 35,
    key: "OH",
    label: "Ohio",
  },
  {
    id: 36,
    key: "OK",
    label: "Oklahoma",
  },
  {
    id: 37,
    key: "OR",
    label: "Oregon",
  },
  {
    id: 38,
    key: "PA",
    label: "Pennsylvania",
  },
  {
    id: 39,
    key: "RI",
    label: "Rhode Island",
  },
  {
    id: 40,
    key: "SC",
    label: "South Carolina",
  },
  {
    id: 41,
    key: "SD",
    label: "South Dakota",
  },
  {
    id: 42,
    key: "TN",
    label: "Tennessee",
  },
  {
    id: 43,
    key: "TX",
    label: "Texas",
  },
  {
    id: 44,
    key: "UT",
    label: "Utah",
  },
  {
    id: 45,
    key: "VT",
    label: "Vermont",
  },
  {
    id: 46,
    key: "VA",
    label: "Virginia",
  },
  {
    id: 47,
    key: "WA",
    label: "Washington",
  },
  {
    id: 48,
    key: "WV",
    label: "West Virginia",
  },
  {
    id: 49,
    key: "WI",
    label: "Wisconsin",
  },
  {
    id: 50,
    key: "WY",
    label: "Wyoming",
  },
]

export const companyWaiverOptions = [
  {
    id: 0,
    key: "",
    label: "Select an state",
  },
  {
    id: 1,
    key: "ARPCO Transport Services",
    label: "ARPCO Transport Services",
  },
  {
    id: 2,
    key: "McKinney Vehicle Services",
    label: "McKinney Vehicle Services",
  },
  {
    id: 3,
    key: "Direct ChassisLink Inc",
    label: "Direct ChassisLink Inc",
  },
  {
    id: 4,
    key: "Other",
    label: "Other",
  }
]

export const companyUSStateOptionsWithPureKeysInTrucks = [
  {
    id: 0,
    key: "",
    label: "Select an state",
  },
  {
    id: 1,
    key: "TX",
    label: "TX",
  },
  {
    id: 2,
    key: "AZ",
    label: "AZ",
  },
  {
    id: 3,
    key: "NM",
    label: "NM",
  },
  {
    id: 4,
    key: "CA",
    label: "CA",
  }
]

export const citiesCAOptions = [
  {
    id: 0,
    key: '',
    label: 'Select an option'
  },
  {
    id: 1,
    key: 'ANDRADE',
    label: 'ANDRADE'
  },
  {
    id: 2,
    key: 'CALEXICO',
    label: 'CALEXICO'
  },
  {
    id: 3,
    key: 'OTAY',
    label: 'OTAY'
  },
  {
    id: 4,
    key: 'MESA',
    label: 'MESA'
  },
  {
    id: 5,
    key: 'SAN DIEGO',
    label: 'SAN DIEGO'
  },
  {
    id: 6,
    key: 'SAN YSIDRO',
    label: 'SAN YSIDRO'
  },
  {
    id: 7,
    key: 'TECATE',
    label: 'TECATE'
  },
  {
    id: 8,
    key: 'N/A',
    label: 'N/A'
  },
]

export const citiesAZOptions = [
  {
    id: 0,
    key: '',
    label: 'Select an option'
  },
  {
    id: 1,
    key: 'DOUGLAS',
    label: 'DOUGLAS'
  },
  {
    id: 2,
    key: 'DOUGLAS',
    label: 'DOUGLAS'
  },
  {
    id: 3,
    key: 'GLENDALE',
    label: 'GLENDALE'
  },
  {
    id: 4,
    key: 'LUKEVILLE',
    label: 'LUKEVILLE'
  },
  {
    id: 5,
    key: 'NACO',
    label: 'NACO'
  },
  {
    id: 6,
    key: 'NOGALES',
    label: 'NOGALES'
  },
  {
    id: 7,
    key: 'RIO RICO',
    label: 'RIO RICO'
  },
  {
    id: 8,
    key: 'SAN LUIS',
    label: 'SAN LUIS'
  },
  {
    id: 9,
    key: 'N/A',
    label: 'N/A'
  },
]

export const citiesNMOptions = [
  {
    id: 0,
    key: '',
    label: 'Select an option'
  },
  {
    id: 1,
    key: 'COLUMBUS',
    label: 'COLUMBUS'
  },
  {
    id: 2,
    key: 'STA TERESA',
    label: 'STA TERESA'
  },
  {
    id: 3,
    key: 'N/A',
    label: 'N/A'
  },
]

export const citiesTXOptions = [
  {
    id: 0,
    key: '',
    label: 'Select an option'
  },
  {
    id: 1,
    key: 'ALTON',
    label: 'ALTON'
  },
  {
    id: 2,
    key: 'BROWNSVILLE',
    label: 'BROWNSVILLE'
  },
  {
    id: 3,
    key: 'CYPRESS',
    label: 'CYPRESS'
  },
  {
    id: 4,
    key: 'DEL RIO',
    label: 'DEL RIO'
  },
  {
    id: 5,
    key: 'EAGLE PASS',
    label: 'EAGLE PASS'
  },
  {
    id: 6,
    key: 'ED COUCH',
    label: 'ED COUCH'
  },
  {
    id: 7,
    key: 'EDINBURG',
    label: 'EDINBURG'
  },
  {
    id: 8,
    key: 'EL PASO',
    label: 'EL PASO'
  },
  {
    id: 9,
    key: 'FABENS',
    label: 'FABENS'
  },
  {
    id: 10,
    key: 'HOUSTON',
    label: 'HOUSTON'
  },
  {
    id: 11,
    key: 'HORIZON',
    label: 'HORIZON'
  },
  {
    id: 12,
    key: 'LA VILLA',
    label: 'LA VILLA'
  },
  {
    id: 13,
    key: 'LAREDO',
    label: 'LAREDO'
  },
  {
    id: 14,
    key: 'LOS INDIOS',
    label: 'LOS INDIOS'
  },
  {
    id: 15,
    key: 'MCALLEN',
    label: 'MCALLEN'
  },
  {
    id: 16,
    key: 'MERCEDES',
    label: 'MERCEDES'
  },
  {
    id: 17,
    key: 'MISSION',
    label: 'MISSION'
  },
  {
    id: 18,
    key: 'OLMITO',
    label: 'OLMITO'
  },
  {
    id: 19,
    key: 'PASADENA',
    label: 'PASADENA'
  },
  {
    id: 20,
    key: 'PHARR',
    label: 'PHARR'
  },
  {
    id: 21,
    key: 'PRESIDIO',
    label: 'PRESIDIO'
  },
  {
    id: 22,
    key: 'PROGRESO',
    label: 'PROGRESO'
  },
  {
    id: 23,
    key: 'ROUND ROCK',
    label: 'ROUND ROCK'
  },
  {
    id: 24,
    key: 'SAN ANTONIO',
    label: 'SAN ANTONIO'
  },
  {
    id: 25,
    key: 'SAN JUAN',
    label: 'SAN JUAN'
  },
  {
    id: 26,
    key: 'STA. TERESA, NM.',
    label: 'STA. TERESA, NM.'
  },
  {
    id: 27,
    key: 'All of Texas & Mexico',
    label: 'All of Texas & Mexico'
  },
  {
    id: 28,
    key: 'All Ports of Entry',
    label: 'All Ports of Entry'
  },
  {
    id: 29,
    key: 'All Texas Ports of Entry',
    label: 'All Texas Ports of Entry'
  },
  {
    id: 30,
    key: 'N/A',
    label: 'N/A'
  },
]

export const lossRunsRequestsStatus = [
  {
    id: 0,
    key: '',
    label: 'Select an option'
  },
  {
    id: 1,
    key: 'Submitted',
    label: 'Submitted'
  },
  {
    id: 2,
    key: 'Sent',
    label: 'Sent'
  }
]

export const supportTicketsStatus = [
  {
    id: 0,
    key: '',
    label: 'Select an option'
  },
  {
    id: 1,
    key: 'Unread',
    label: 'Unread'
  },
  {
    id: 2,
    key: 'Decline',
    label: 'Decline'
  },
  {
    id: 3,
    key: 'In progress',
    label: 'In progress'
  },
  {
    id: 4,
    key: 'Solved',
    label: 'Solved'
  },
]

export const operationRadiusOptions = [
  {
    id: 0,
    key: "",
    label: "Change your operational radius",
  },
  {
    id: 1,
    key: "0-50",
    label: "0-50",
  },
  {
    id: 2,
    key: "0-100",
    label: "0-100",
  },
  {
    id: 3,
    key: "0-250",
    label: "0-250",
  },
  {
    id: 4,
    key: "0-500",
    label: "0-500",
  },
  {
    id: 5,
    key: "0-800",
    label: "0-800",
  },
  {
    id: 6,
    key: "0-1000",
    label: "0-1000",
  },
  {
    id: 7,
    key: "0-1200",
    label: "0-1200",
  },
  {
    id: 8,
    key: "0-1500",
    label: "0-1500",
  },
]