import { useContext } from "react"
import { getLossRunsRequest } from "../../services/firebase-api/lossRuns"
import { GET_LOSS_RUNS } from "../types"
import { AppContext, initialState } from "../AppContext"

const useLossRunsState = () => {

    const { state, dispatch } = useContext(AppContext)
    const { lossRuns } = state || initialState

    const getLossRuns = async () => {
        try {
            const lossRunsResult = await getLossRunsRequest()
            dispatch({ type: GET_LOSS_RUNS, payload: lossRunsResult })
            return lossRunsResult
        } catch (error) {
            throw error
        }
    }

    return {
        getLossRuns,
        lossRuns
    }
}

export default useLossRunsState