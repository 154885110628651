import { Box, Tooltip } from "@mui/material";
import { useState } from "react";
import { Visibility as VisibilityIcon } from "@mui/icons-material";
import Popup from "../../../generals/Popup";
import TrucksUploadForm from "./TrucksUploadForm";

const ModalTruckUploadForm = ({ isApplicationOrPolicy, item }) => {
  const [seeInfo, setSeeInfo] = useState(false);

  return (
    <>
      <Tooltip title={<Box>View Uploaded Images</Box>} placement="top" arrow>
        <button
          onClick={(e) => {
            e.preventDefault();
            setSeeInfo(true);
          }}
          className={`text-[#8B0000] hover:text-[#8B0000] d-inline mx-1
            ${
            !isApplicationOrPolicy
                ? "cursor-not-allowed"
                : ""
            }
          `}
        >
          <VisibilityIcon
            className={!isApplicationOrPolicy ? "opacity-20" : ""}
          />
        </button>
      </Tooltip>
      <Popup isOpen={seeInfo} onRequestClose={() => setSeeInfo(false)}>
        <TrucksUploadForm
          truckVIN={item?.truckVIN}
          fileObjectsTruckDocuments={item?.truckDocuments?.[0] ? item?.truckDocuments : []}
          setFileObjectsTruckDocuments={() => {}}
          isViewMode={true}
        />
      </Popup>
    </>
  );
};

export default ModalTruckUploadForm;
