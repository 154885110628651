import useGridSystem from '../../../../hooks/generals/useGridSystem';

const Col = ({
  xs,
  sm,
  md,
  lg,
  xl,
  xxl,
  as: Component = "div",
  className = "",
  children,
  spacing = 0,
}) => {
  
  const {
    style
  } = useGridSystem(
    xs,
    sm,
    md,
    lg,
    xl,
    xxl,
    spacing
  )

  return <Component className={className} style={style}>{children}</Component>;
};

export default Col;
