import React from 'react';
import { Card, CardContent, Typography, Grid, TextField } from '@mui/material';

const FinalNotes = ({ handleInputChange, finalNotes }) => {
  return (
    <Card sx={{ maxWidth: 800, margin: 'auto', padding: 2, marginBottom: 2 }}>
      <CardContent>
        <Typography variant="h5" component="div" gutterBottom>
          Final Notes
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Final Notes"
              name="finalNotes"
              value={finalNotes}
              onChange={handleInputChange}
              placeholder="Enter your final notes here..."
              multiline
              rows={10}
              fullWidth
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default FinalNotes;
