import React, { useState } from "react";
import { TextField, Button, Typography } from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import ApprovalButtons from "../../../assets/forms/ApprovalButtons";
import useTranslations from "../../../../Context/actions/useTranslations";

export default function AddNote(props) {
  const { t } = useTranslations();
  const { note, setNote, submitNote, value, setValue, setShow, documentID, setNoteData, collectionPath, userInfo, formData, } =
    props;

  const handleNoteChange = (e) => {
    setNote(e.target.value);
  };

  const handleNoteSubmit = (e) => {
    e.preventDefault();
    submitNote(e, documentID, setNoteData, props, value, note, collectionPath);
    setNote("");
    setValue("");
  };
  

  return (
    <div className="mb-2">
      <form onSubmit={handleNoteSubmit}>
        <section>
          <Typography variant="h6" gutterBottom>
            {t("plm.drivers.details.add-note")}
          </Typography>
          <div className="mt-2 flex flex-col">
            <TextField
              type="text"
              label={t("plm.drivers.details.subject")}
              variant="outlined"
              className="mb-2"
              value={note}
              onChange={handleNoteChange}
            />
            <div style={{ backgroundColor: "white", marginBottom: "15px" }}>
              <ReactQuill
                theme="snow"
                value={value}
                onChange={setValue}
                style={{ height: "100px" }}
              />
            </div>
            <div className="mt-4">
              <ApprovalButtons
                onApprove={null}
                handleAsSubmit={true}
                onDecline={(e) => {
                  setShow(false);
                  setNote("");
                  setValue("");
                }}
                titleAccept={t("plm.drivers.submit")}
                titleDecline={t("plm.cancel")}
                disabledAccept={false}
              />
            </div>
          </div>
        </section>
      </form>
    </div>
  );
}
