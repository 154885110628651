import { collection, getDocs, updateDoc, doc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../../firebase";
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  TextField,
} from "@mui/material";
import camelCaseToTitleCase from "../../utils/camelCaseToTitleCase";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";

const DataMatrix = ({ tableName }) => {
  const [dataMatrix, setDataMatrix] = useState([]);
  const [editableRow, setEditableRow] = useState(null);
  const ageRanges = [
    "23-25",
    "26-30",
    "31-35",
    "36-40",
    "41-45",
    "46-50",
    "51-55",
    "56-60",
    "61+",
  ];

  const experienceLevels = [
    "0-5",
    "6-10",
    "11-15",
    "16-20",
    "21-25",
    "26-30",
    "31-35",
    "36-40",
    "41-45",
    "46-50",
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const collectionRef = collection(
          db,
          "loss-control",
          "tables",
          tableName
        );
        const querySnapshot = await getDocs(collectionRef);

        const fetchedDataMatrix = [];
        querySnapshot.forEach((doc) => {
          const rowData = experienceLevels.map((range, index) => {
            const columnName = `col_${index}`;
            return doc.data()[columnName];
          });
          fetchedDataMatrix.push(rowData);
        });

        setDataMatrix(fetchedDataMatrix);
      } catch (error) {
        console.error("Error fetching documents:", error);
      }
    };

    fetchData();
  }, []);

  const handleEditRow = (rowIndex) => {
    setEditableRow(rowIndex);
  };

  const handleSaveRow = async (rowIndex) => {
    try {
      const collectionRef = collection(db, "loss-control", "tables", tableName);
      const docRef = doc(collectionRef, 'row_' + rowIndex.toString());
      const updateData = {};
      dataMatrix[rowIndex].forEach((value, index) => {
        const columnName = `col_${index}`;
        updateData[columnName] = value;
      }); 
      await updateDoc(docRef, updateData);
      setEditableRow(null);
    } catch (error) {
      console.error("Error updating document:", error);
    }
  };

  const handleChangeCell = (rowIndex, colIndex, value) => {
    const newDataMatrix = [...dataMatrix];
    newDataMatrix[rowIndex][colIndex] = value;
    setDataMatrix(newDataMatrix);
  };

  return (
    <Card variant="outlined" className="my-4">
      <CardContent>
        <Typography variant="h6" gutterBottom>
          {camelCaseToTitleCase(tableName)}
        </Typography>
        <table className="data-matrix-table">
          <thead>
            <tr>
              <th></th>
              {experienceLevels.map((range, index) => (
                <th key={index}>{range}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {dataMatrix.map((row, rowIndex) => (
              <tr key={rowIndex}>
                <td>
                  {ageRanges[rowIndex]}
                  {editableRow === rowIndex ? (
                    <IconButton onClick={() => handleSaveRow(rowIndex)}>
                      <SaveIcon />
                    </IconButton>
                  ) : (
                    <IconButton onClick={() => handleEditRow(rowIndex)}>
                      <EditIcon />
                    </IconButton>
                  )}
                </td>
                {row.map((cell, colIndex) => (
                  <td
                    key={colIndex}
                    className={cell === "" ? "blank-cell" : ""}
                  >
                    {editableRow === rowIndex ? (
                      <TextField
                        value={cell || ""}
                        onChange={(e) =>
                          handleChangeCell(rowIndex, colIndex, e.target.value)
                        }
                      />
                    ) : (
                      cell
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </CardContent>
    </Card>
  );
};

export default DataMatrix;
