import React, { useEffect, useMemo, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  collectionGroup,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../../firebase";
import { useParams } from "react-router-dom";
import Popup from "../../generals/Popup";
import Breadcrumbs from "../../assets/Breadcrumbs";

export default function PowerUnitDetailFlags({ powerUnit, policyID }) {
  const { paramPolicyID } = useParams();

  const [loading, setLoading] = useState(false);
  const [openRedFlagPopup, setOpenRedFlagPopup] = useState(false);
  const [powerUnitsFound, setPowerUnitsFound] = useState([]);
  const [popupDataToShow, setPopupDataToShow] = useState([]);

  const handleGetPowerUnits = async () => {
    try {
      setLoading(true);
      const powerUnitsData = [];
      const q = query(
        collectionGroup(db, "power-units"),
        where("truckVIN", "==", powerUnit.truckVIN)
      );
      const truckSnapshot = await getDocs(q);
      truckSnapshot.forEach((doc) => {
        powerUnitsData.push({
          ...doc.data(),
          id: doc.id,
        });
      });
      setPowerUnitsFound(powerUnitsData);
      setLoading(false);
    } catch (error) {
      console.error("Error getting power units: ", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetPowerUnits();
  }, []);

  const handleModalInfo = () => {
    setOpenRedFlagPopup(!openRedFlagPopup);
  };

  const [policiesDetailsData, setPoliciesDetailsData] = useState({
    aPolicyWithDifferentPolicyIdWasFound: false,
    policiesDetailsMixed: [],
  });

  useEffect(() => {
    const fetchPolicies = async () => {
      const policiesDetails = powerUnitsFound.map((powerUnit) => powerUnit.policiesDetails);
      const policiesDetailsMixed = policiesDetails.flat();
      try {
        const fetchedPolicies = await Promise.all(
          policiesDetailsMixed.filter(policy => Boolean(policy?.id)).map(async (policy) => {
            const policyRef = doc(db, "policy-applications", policy.id);
            const policyDoc = await getDoc(policyRef);
            return { ...policyDoc.data(), id: policy.id };
          })
        );
        const policyIDYouAreOn = policyID ? policyID : paramPolicyID;
        const aPolicyWithDifferentPolicyIdWasFound = fetchedPolicies.some(
          (policy) =>
            Boolean(
              policy.id !== policyIDYouAreOn && policy.status === "In-Force"
            )
        );
        setPoliciesDetailsData({
          aPolicyWithDifferentPolicyIdWasFound,
          policiesDetailsMixed: fetchedPolicies,
        });
      } catch (error) {
        console.error('Error fetching policies:', error);
      }
    };
    fetchPolicies();
  }, [powerUnitsFound]);

  const getPoliciesData = async () => {
    const { policiesDetailsMixed } = policiesDetailsData;
    const insuredDataList = [];
    for (const [
      policyDetailsIndex,
      policyDetail,
    ] of policiesDetailsMixed.entries()) {
      try {
        let insuredData = {};
        const agencyID = policyDetail.agencyID;
        const insuredID = policyDetail.insuredID;
        const insuredRef = doc(db, "agencies", agencyID, "insureds", insuredID);
        const insuredSnap = await getDoc(insuredRef);
        if (insuredSnap.exists()) {
          insuredData = {
            ...insuredSnap.data(),
            id: insuredSnap.id,
          };
        }
        insuredDataList.push(insuredData);
      } catch (error) {
        insuredDataList.push({});
        console.error(
          `Error getting insured data of index ${policyDetailsIndex}`,
          error
        );
      }
    }
    const policyData = policiesDetailsMixed.map(
      (policyDetail, policyDetailIndex) => {
        return {
          ...policyDetail,
          insuredData: insuredDataList[policyDetailIndex],
        };
      }
    );
    const filteredPolicyData = policyData.filter((policy) => {
      return policy.id !== paramPolicyID && policy.policyStatus === "In-Force";
    });
    setPopupDataToShow(filteredPolicyData);
  };

  useEffect(() => {
    if (openRedFlagPopup) {
      getPoliciesData();
    }
  }, [openRedFlagPopup]);

  const handleDate = (addedDate) => {
    try {
      // Parse the date from Firestore timestamp
      const parsedDate = new Date(
        addedDate.seconds * 1000 + addedDate.nanoseconds / 1000000
      );

      // Define the date format
      const formatOptions = {
        month: "short",
        day: "2-digit",
        year: "numeric",
      };

      // Format the date using the defined format
      const humanDate = parsedDate.toLocaleDateString("en-US", formatOptions);

      return humanDate;
    } catch (error) {
      console.error("Date formatting error:", error);
      return "";
    }
  };

  const breadcrumbs = [{ label: "Power Unit" }, { label: "Other Policies" }];

  return (
    <>
      <Popup
        isOpen={openRedFlagPopup}
        onRequestClose={handleModalInfo}
        maxWidth="850px"
      >
        <div>
          <Breadcrumbs items={breadcrumbs} />
          <p className="text-2xl font-semibold mb-3 mt-2">
            Other Policies Found
          </p>
          <p className="mb-3">VIN: {powerUnit.truckVIN}</p>
          <table width="100%">
            <thead>
              <tr className="!bg-[#072a48] text-white">
                <td className="rounded-tl-md">Insured Name</td>
                <td>AL Policy No</td>
                <td>Status</td>
                <td className="rounded-tr-md">Effective Date</td>
              </tr>
            </thead>
            {popupDataToShow.map((policy, index) => {
              return (
                <tr key={index}>
                  <td>
                    {policy.insuredData?.company
                      ? policy.insuredData?.company
                      : ""}
                  </td>
                  <td>{policy.alPolicyNo ? policy.alPolicyNo : ""}</td>
                  <td>{policy.policyStatus ? policy.policyStatus : ""}</td>
                  <td>
                    {policy.addedDate ? handleDate(policy.addedDate) : ""}
                  </td>
                </tr>
              );
            })}
          </table>
        </div>
      </Popup>
      {loading || !policiesDetailsData.aPolicyWithDifferentPolicyIdWasFound ? (
        <></>
      ) : (
        <p>
          <Tooltip
            title={
              <Box>
                <Typography component="p">
                  Power unit found on other in force policies
                </Typography>
              </Box>
            }
            placement="top"
            arrow
          >
            <IconButton onClick={handleModalInfo} sx={{ color: "#8B0000" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-flag-fill d-inline"
                viewBox="0 0 16 16"
              >
                <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12.435 12.435 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A19.626 19.626 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a19.587 19.587 0 0 0 1.349-.476l.019-.007.004-.002h.001" />
              </svg>
            </IconButton>
          </Tooltip>
        </p>
      )}
    </>
  );
}
