import React from "react";
import handleEnter from "../../../utils/handleEnter";
import useTranslations from "../../../../Context/actions/useTranslations";
import {
  convertToNestedString,
  handleDynamicQuestionnaireChange,
} from "../../../../utils/helpers";

export default function YesNo(props) {
  const { t } = useTranslations();
  const {
    setFormSaved,
    policyID,
    property,
    formData,
    setFormData
  } = props;

  const handleChange = async (e) => {
    try {
      const { value } = e.target;
      await handleDynamicQuestionnaireChange(
        policyID,
        property,
        value,
        formData,
        setFormData,
        setFormSaved
      );
    } catch (error) {
      throw error
    }
  };

  return (
    <div>
      <label className="d-block mt-1">{props.label}</label>
      <select
        onChange={handleChange}
        
        className="mt-1 p-1 block w-full rounded-md border border-gray-300 shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        value={convertToNestedString(property, formData)}
      >
        <option value=""></option>
        <option value="Yes">{t("plm.yes")}</option>
        <option value="No">{t("plm.no")}</option>
      </select>
    </div>
  );
}
