const getSurplusLinesRate = (state, taxesAndFees) => {
  if (state === "CA") {
    return taxesAndFees.californiaSurplusLinesTax;
  } else if (state === "TX") {
    return taxesAndFees.texasSurplusLinesTax;
  } else if (state === "NM") {
    return taxesAndFees.newMexicoSurplusLinesTax;
  } else if (state === "AZ") {
    return taxesAndFees.arizonaSurplusLinesTax;
  }
};

export default getSurplusLinesRate;
