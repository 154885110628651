import Form from "../../../Components/generals/Forms/Form";
import useSignUp from "../../../hooks/auth/useSignUp";

function SignUpFullData() {
  const { formSettingsSignUpWithCompanyId, loading } = useSignUp();
  return <Form
    formSettings={formSettingsSignUpWithCompanyId}
    loading={loading}
  />;
}

export default SignUpFullData;
