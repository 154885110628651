const findNextPolicyNumbers = async (
  formData,
  insuredInfo,
  uniqueIdentifierAL,
  uniqueIdentifierGL,
  setNextAL,
  setNextGL
) => {
  let nextAL = "";
  let nextGL = "";
  if (formData.program === "NAFTA") {
    nextAL =
      "2-SSM-TX-19-S" +
      (uniqueIdentifierAL === null
        ? insuredInfo?.uniqueIdentifierNAFTAAL
        : uniqueIdentifierAL +
          "-" +
          ((insuredInfo?.alPolicies || []).length === 0
            ? "00"
            : (insuredInfo?.alPolicies?.length + 1).toString()
          ).padStart(2, "0"));
          
    nextGL =
      "2-SSM-TX-17-S" +
      (uniqueIdentifierGL === null
        ? insuredInfo?.uniqueIdentifierNAFTAGL
        : uniqueIdentifierGL +
          "-" +
          ((insuredInfo?.glPolicies || []).length === 0
            ? "00"
            : (insuredInfo?.glPolicies?.length + 1).toString()
          ).padStart(2, "0"));
  } else if (formData.program === "Domestic") {
    nextAL =
      "2-SSD-TX-19-S" +
      (uniqueIdentifierAL === null
        ? insuredInfo?.uniqueIdentifierDomesticAL
        : uniqueIdentifierAL +
          "-" +
          ((insuredInfo?.alPolicies || []).length === 0
            ? "00"
            : (insuredInfo?.alPolicies?.length + 1).toString()
          ).padStart(2, "0"));
    nextGL =
      "2-SSD-TX-17-S" +
      (uniqueIdentifierGL === null
        ? insuredInfo?.uniqueIdentifierDomesticGL
        : uniqueIdentifierGL +
          "-" +
          ((insuredInfo?.glPolicies || []).length === 0
            ? "00"
            : (insuredInfo?.glPolicies?.length + 1).toString()
          ).padStart(2, "0"));
  }
  if (uniqueIdentifierAL === null) {
    setNextAL(nextAL);
    setNextGL(nextGL);
  }
  return {
    nextAL,
    nextGL,
  };
};

export default findNextPolicyNumbers;
