import React from "react";
import { Td, Tr } from "react-super-responsive-table";
import QuillModal from "../../../../QuillModal";
import { Button, ButtonGroup, Grid } from "@mui/material";
import BlueButton from "../../../../assets/forms/BlueButton";
import { CheckCircle as CheckCircleIcon } from "@mui/icons-material";
import FileUploadModal from "../../../../FileUploadModal";

const LossExperienceAnalysis = ({
  task,
  formData,
  index,
  handleUpload,
  setFiles,
  isOpen,
  setIsOpen,
  policyID,
  taskList,
  setFile,
  uploadFile,
  setProgressState,
  updatePolicyField,
  insuredInfo,
}) => {
  return (
    <>
      <Tr key={"otherLossRun" + index}>
        <Td valign="top">
          <p className="my-1">
            {formData.underwritingTasks?.[task.description]?.completed
              ? "Complete"
              : "Incomplete"}
          </p>
        </Td>
        <Td valign="top">
          <p className="my-1">
            {task.cabURL ? (
              <a
                className="text-blue"
                target="_blank"
                rel="noreferrer"
                href={task.cabURL + insuredInfo.dot}
              >
                {task.description}
              </a>
            ) : (
              <>{task.description}</>
            )}
          </p>
        </Td>
        <Td valign="top">
          <p className="my-1">{task.dueDate}</p>
        </Td>
        <Td valign="top">
          {task.type === "note" && (
            <>
              <Grid container spacing={1}>
                <Grid item xs={12} md={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <QuillModal
                        handleUpload={handleUpload}
                        isOpen={isOpen[task.description] || false}
                        setIsOpen={(state) =>
                          setIsOpen((prev) => ({
                            ...prev,
                            [task.description]: state,
                          }))
                        }
                        label={task.description}
                        setFile={(files) =>
                          setFiles((prev) => ({
                            ...prev,
                            [task.description]: files,
                          }))
                        }
                        taskLocation={
                          formData.underwritingTasks?.[task.description]?.note || ""
                        }
                        hideButtonLabel={true}
                      />
                    </Grid>
                  </Grid>
                  {task.type === "file" ? (
                    <FileUploadModal
                      handleUpload={(file) =>
                        uploadFile(
                          file,
                          setProgressState,
                          policyID,
                          "policies",
                          taskList[index]
                        )
                      }
                      isOpen={isOpen}
                      setIsOpen={setIsOpen}
                      label={"Other Loss Runs"}
                      setFile={setFile}
                      taskLocation={task?.fileURL}
                      alreadyUploadedRule={task.fileURL && task.fileURL !== ""}
                      additionalDeleteFunction={() => {
                        const newData = [...taskList];
                        newData.splice(index, 1);
                        updatePolicyField(policyID, "OtherLossRuns", newData);
                      }}
                      hideButtonLabel={true}
                    />
                  ) : null}
                </Grid>
                <Grid item xs={12} md={12}>
                  {formData?.underwritingTasks?.[task.description]?.completed && (
                    <>
                      <ButtonGroup
                        fullWidth
                        disableElevation
                        variant="contained"
                      >
                        <Button
                          style={{
                            backgroundColor: "green",
                            color: "white",
                            flexGrow: 1,
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            padding: "4px 8px",
                            fontSize: "0.75rem",
                          }}
                        >
                          Complete
                        </Button>
                      </ButtonGroup>
                    </>
                  )}
                </Grid>
              </Grid>
            </>
          )}
        </Td>
      </Tr>
    </>
  );
};

export default LossExperienceAnalysis;
