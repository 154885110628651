import findNextPolicyNumbers from "./findNextPolicyNumbers";
import getUniqueIdentifier from "./getUniqueIdentifier";

const assignPolicyNumber = async (formData, formDataGL, insuredID, agencyID, insuredInfo) => {
    const thisPolicyIsDomestic = formData.program === "Domestic";
    const thisPolicyIsNAFTA = formData.program === "NAFTA";
    const hasNAFTAALUniqueIdentifier =
      insuredInfo.uniqueIdentifierNAFTAAL &&
      insuredInfo.uniqueIdentifierNAFTAAL !== null;
    const hasDomesticALUniqueIdentifier =
      insuredInfo.uniqueIdentifierDomesticAL &&
      insuredInfo.uniqueIdentifierDomesticAL !== null;
    const hasNAFTAGLUniqueIdentifier =
      insuredInfo.uniqueIdentifierNAFTAGL &&
      insuredInfo.uniqueIdentifierNAFTAGL !== null;
    const hasDomesticGLUniqueIdentifier =
      insuredInfo.uniqueIdentifierDomesticGL &&
      insuredInfo.uniqueIdentifierDomesticGL !== null;
    let useNewIdentifier = false;
    let uniqueIdentifierAL;
    let uniqueIdentifierGL;
  
    if (thisPolicyIsNAFTA) {
      if (!hasNAFTAALUniqueIdentifier) {
        useNewIdentifier = true;
        uniqueIdentifierAL = await getUniqueIdentifier(
          formData.program,
          insuredID,
          "AL",
          agencyID
        );
      } else {
        uniqueIdentifierAL = insuredInfo.uniqueIdentifierNAFTAAL;
      }
  
      if (formData.hasGLCoverage && !hasNAFTAGLUniqueIdentifier) {
        useNewIdentifier = true;
        uniqueIdentifierGL = await getUniqueIdentifier(
          formData.program,
          insuredID,
          "GL",
          agencyID
        );
      } else if (formData.hasGLCoverage && hasNAFTAGLUniqueIdentifier) {
        uniqueIdentifierGL = insuredInfo.uniqueIdentifierNAFTAGL;
      }
    } else if (thisPolicyIsDomestic) {
      if (!hasDomesticALUniqueIdentifier) {
        useNewIdentifier = true;
        uniqueIdentifierAL = await getUniqueIdentifier(
          formData.program,
          insuredID,
          "AL",
          agencyID
        );
      } else {
        uniqueIdentifierAL = insuredInfo.uniqueIdentifierDomesticAL;
      }
      if (formDataGL.hasGLCoverage && !hasDomesticGLUniqueIdentifier) {
        useNewIdentifier = true;
        uniqueIdentifierGL = await getUniqueIdentifier(
          formDataGL.program,
          insuredID,
          "GL",
          agencyID
        );
      } else if (formData.hasGLCoverage && hasDomesticGLUniqueIdentifier) {
        uniqueIdentifierGL = insuredInfo.uniqueIdentifierDomesticGL;
      }
    }
  
    // Use the newly acquired Unique Identifiers to produce entire policy numbers
    const policyNumberResults = await findNextPolicyNumbers(
      formData,
      insuredInfo,
      uniqueIdentifierAL,
      uniqueIdentifierGL,
      null,
      null
    );

    return [policyNumberResults, useNewIdentifier];
}

export default assignPolicyNumber;