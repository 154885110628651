const humanDate = (dateObj) => {
  if (dateObj?.toDate) {
    return dateObj.toDate();
  }
  if (dateObj?.toLocaleDateString) {
    return dateObj;
  }
  const date = new Date(
    dateObj.seconds * 1000 + Math.floor(dateObj.nanoseconds / 1000000)
  );
  const humanDate = date;
  return humanDate;
};

export default humanDate;