import React, { useEffect, useState } from "react";
import useTranslations from "../../../../../Context/actions/useTranslations";
import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../../../../firebase";

export default function OpenApplications({ userInfo }) {
  const [applicationList, setApplicationList] = useState([]);
  const [submitted, setSubmitted] = useState("");
  const [unsubmitted, setUnsubmitted] = useState("");
  const [returned, setReturned] = useState("");
  const [totalApplications, setTotalApplications] = useState("");
  const { t } = useTranslations();

  useEffect(() => {
    if (userInfo.agencyID) {
      const applicationsCollection = collection(db, "policy-applications");
      const q = query(
        applicationsCollection,
        where("documentType", "in", [
          "Application",
          "Quote Request",
          "Bind Order",
        ]),
        where("agencyID", "==", userInfo.agencyID),
        where("status", "!=", "Not Underwritten")
      );

      const unsubscribe = onSnapshot(q, (snapshot) => {
        let submittedCount = 0;
        let unsubmittedCount = 0;
        let returnedCount = 0;

        const updatedapplicationListPromises = snapshot.docs.map(
          async (applicationDoc) => {
            const applicationData = applicationDoc.data();
            const { status } = applicationData;

            // Count documents based on status
            if (status === "Submitted") {
              submittedCount++;
            } else if (status === "Unsubmitted") {
              unsubmittedCount++;
            } else if (status === "Returned") {
              returnedCount++;
            }

            const { namedInsured, agencyID, ...rest } = applicationData;
            if (agencyID) {
              const insuredDocRef = doc(
                db,
                "agencies",
                userInfo.agencyID,
                "insureds",
                applicationData.insuredID
              );
              const insuredDocSnapshot = await getDoc(insuredDocRef);
              if (insuredDocSnapshot.exists()) {
                const companyName = insuredDocSnapshot.data().company;
                return {
                  id: applicationDoc.id,
                  namedInsured: companyName,
                  ...rest,
                };
              }
            }
            return null;
          }
        );

        Promise.all(updatedapplicationListPromises).then(
          (updatedapplicationList) => {
            setApplicationList(updatedapplicationList.filter(Boolean));

            // Output the counts
            setSubmitted(submittedCount);
            setUnsubmitted(unsubmittedCount);
            setReturned(returnedCount);
            setTotalApplications(
              submittedCount + unsubmittedCount + returnedCount
            );
          }
        );
      });

      return () => unsubscribe();
    }
  }, [userInfo.agencyID]);
  return (
    <div className="card mb-2 shadow">
      <div className="card-header bg-[#116582]">
        <h2 className="font-medium text-white">{t('account.open-applications')}</h2>
      </div>
      <div className="card-body py-2">
        <h1 className="text-center">
          <span className="text-2xl mr-2">{totalApplications}</span>
          {t('account.open-applications')}
        </h1>
      </div>
      <div className="card-footer">
        <h4 className="text-[#8B0000] font-medium">
          {returned} {returned === 1 ? t('account.returned-one') : t('account.returned')}
          <span className="float-right text-green-900">
            {submitted} {submitted === 1 ? t('account.submitted-one') : t('account.submitted')}
          </span>
        </h4>
      </div>
    </div>
  );
}
