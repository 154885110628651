import React, { useState } from "react";
import ExpandCollapseArrow from "../../generals/DesignElements/ExpandCollapseArrow";

export default function RenewalMessage({ className, reasons, nonRenewalDate, policyID }) {
  const [show, setShow] = useState(true);
  const color = "red";
  const setPolicyToInForce = async () => {
    const updatedData = {
        nonRenewal: false,
        nonRenewalReasons: [""],
        nonRenewalDate: "",
        status: "In-Force"
    }
  }
  return (
    <main
      className={`card mt-3 bg-${color}-100 text-${color}-700 ${
        className || ""
      }`}
    >
      <div className="card-header" onClick={(e) => setShow(!show)}>
        <h1 className={`text-lg font-bold text-${color}-700`}>
          <button>Marked As Non-Renewal</button>
          <ExpandCollapseArrow show={show} setShow={setShow} />
        </h1>
      </div>
      {show && (
        <div className="card-body">
          <p className="mb-2">
            This policy was marked as a non-renewal by an underwriter on{" "}
            {nonRenewalDate
              ?.toDate()
              .toLocaleDateString("en-US", {
                month: "short",
                day: "2-digit",
                year: "numeric",
              })}{" "}
            for the following reasons:
          </p>
          <ul className="list-disc ml-6">
            {reasons.map((reason, i) => {
              return (
                <li key={i} className="mb-2">
                  {reason}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </main>
  );
}
