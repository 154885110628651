import React, { useEffect, useState } from "react";
import { t } from "i18next";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import submitAddTruckEndorsement from "./utils/submitAddTruckEndorsement";
import getTruckData from "../../utils/validation/validateTruck/getTruckData";
import useGeneralState from "../../../Context/actions/useGeneralState";
import Breadcrumbs from "../../assets/Breadcrumbs";
import ApprovalButtons from "../../assets/forms/ApprovalButtons";
import {
  validateIfExists,
  validateIfVinExistsInAnotherPolicy,
} from "../../utils/validateIfBlacklisted";
import MultiUpload from "../../assets/forms/DynamicPropertiesInputs/MultiUpload";
import ApiService from "../../../services/southern-api/apiService";
import { setTimeToNoonOne } from "../../../utils/helpers";

export default function AddTruck(props) {
  const formData = props.formData;
  const insuredInfo = props.insuredInfo;
  const isEndoManager = props.isEndoManager;
  const policyID = props.policyID;
  const truckList = props.truckList;

  const apiService = new ApiService("southern");

  const [truckData, setTruckData] = useState({});

  const goBack = (e) => {
    e.preventDefault();
    props.setShowAddTruck(false);
    props.setShowTable(true);
  };

  const [endoEffectiveDate, setEndoEffectiveDate] = useState(new Date());
  const [success, setSuccess] = useState(false);
  const [vinDecoded, setVinDecoded] = useState(false);
  const [vinWrongDecoded, setVinWrongDecoded] = useState(false);
  const [truckDocuments, setTruckDocuments] = useState([]);
  const { controlToast } = useGeneralState();

  const validatePowerUnit = async (truckVIN) => {
    if (truckVIN.length !== 17) {
      controlToast(true, "VIN must be 17 characters", "error");
      return false;
    }
    const truckFound = truckList.find((truck) => {
      return truck.truckVIN === truckVIN;
    });
    if (truckFound?.id) {
      controlToast(true, "VIN already exists on policy", "error");
      return false;
    }
    return true;
  };

  const handleDecodeVIN = async (e, VIN, year) => {
    e.preventDefault();
    const unitIsValid = await validatePowerUnit(VIN);
    if (!unitIsValid) {
      return;
    } else {
      const results = await getTruckData(VIN, year);
      const resultData = results
        ? results
        : {
            ...truckData,
            truckVIN: VIN,
          };
      if (typeof results === "undefined") {
        setVinWrongDecoded(true);
        controlToast(
          true,
          "There was an error filling automatically the data, try with a valid VIN or fill manually the data",
          "error"
        );
      }
      setTruckData(resultData);
      setVinDecoded(true);
    }
  };

  const breadcrumbs = [
    { label: formData.documentType },
    { label: "Power Units" },
    { label: "Add Power Unit" },
  ];

  const mirrorLogic = async (e) => {
    e.preventDefault();
    const isRenewalApplication =
      formData.status === "In-Renewal" &&
      formData.documentType === "Application";
    const isRenewalPolicy =
      formData.status === "In-Renewal" && formData.documentType === "Policy";
    const notARenewalProcess = formData.status !== "In-Renewal";

    const { agencyID } = formData;

    try {
      if (notARenewalProcess) {
        await submitAddTruckEndorsement(
          e,
          policyID,
          truckData,
          insuredInfo,
          props.userInfo,
          setTruckData,
          endoEffectiveDate,
          formData,
          goBack,
          props.getEndorsements,
          formData.documentType,
          false,
          truckList
        );
        const isPolicy = formData.documentType === "Policy";
        const message = isPolicy
          ? "You created an Add Power Unit endorsement successfully!"
          : "You created a Power Unit successfully!";
        controlToast(true, message, "success");
      }
      if (isRenewalApplication) {
        await submitAddTruckEndorsement(
          e,
          policyID,
          truckData,
          insuredInfo,
          props.userInfo,
          setTruckData,
          endoEffectiveDate,
          formData,
          goBack,
          props.getEndorsements,
          "Application",
          true,
          truckList
        );
        await submitAddTruckEndorsement(
          e,
          formData.renewalPolicyID,
          truckData,
          insuredInfo,
          props.userInfo,
          setTruckData,
          endoEffectiveDate,
          formData,
          goBack,
          props.getEndorsements,
          "Policy",
          true,
          truckList
        );
        const message =
          "You created an Add Power Unit endorsement successfully!";
        controlToast(true, message, "success");
      }
      if (isRenewalPolicy) {
        await submitAddTruckEndorsement(
          e,
          formData.renewalApplicationID,
          truckData,
          insuredInfo,
          props.userInfo,
          setTruckData,
          endoEffectiveDate,
          formData,
          goBack,
          props.getEndorsements,
          "Application",
          true,
          truckList
        );
        await submitAddTruckEndorsement(
          e,
          policyID,
          truckData,
          insuredInfo,
          props.userInfo,
          setTruckData,
          endoEffectiveDate,
          formData,
          goBack,
          props.getEndorsements,
          "Policy",
          true,
          truckList
        );
        const message =
          "You created an Add Power Unit endorsement successfully!";
        controlToast(true, message, "success");
      }

      const truckDataPayload = truckData?.[0];
      const existingActiveVinsInDifferentPolicies =
        await validateIfVinExistsInAnotherPolicy(
          truckDataPayload?.truckVIN,
          "power-units",
          "truckVIN",
          policyID
        );

      if (existingActiveVinsInDifferentPolicies?.length > 0) {
        await apiService.sendEmailPoliciesDetails({
          vin: truckDataPayload?.truckVIN,
          policiesDetails: existingActiveVinsInDifferentPolicies,
          agencyID,
        });
      }
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    setTruckData({
      ...truckData,
      truckDocuments: truckDocuments,
    });
  }, [truckDocuments]);

  return (
    <>
      {success ? (
        <main className="bg-green-100 p-6 rounded-md shadow-md text-green-700">
          <p className="text-2xl font-semibold mb-4">
            Your endorsement has been successfully submitted.
          </p>
          <p className="mb-4">
            An underwriter will review your endorsement, and you will receive a
            notification when the status is updated.
          </p>
          <button
            onClick={(e) => goBack(e)}
            className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded"
          >
            OK
          </button>
        </main>
      ) : (
        <main>
          <header>
            {!isEndoManager ? <Breadcrumbs items={breadcrumbs} /> : null}
            <h1
              className={`${
                isEndoManager
                  ? "text-2xl mt-2.5 font-bold md:mb-0"
                  : "text-3xl mt-2 font-semibold md:mb-0"
              }`}
            >
              {!isEndoManager
                ? `${t("Add Power Unit")}`
                : `${t("Add Power Unit")}`}
            </h1>
            <p className="mt-2 mb-3">
              {(formData.documentType === "Application" ||
                formData.documentType === "Policy") &&
                t(
                  "Fill out the following form to Add Unit. Click submit once the form is complete."
                )}
              {formData.documentType !== "Application" &&
                formData.documentType !== "Policy" && (
                  <>Units may not be edited during the quoting phase.</>
                )}
            </p>
          </header>
          {/* Form */}
          <section className="card mx-auto max-w-[750px] mb-4 shadow-lg">
            <div className="card-body">
              <form onSubmit={mirrorLogic}>
                {formData.documentType === "Policy" ||
                formData.status === "In-Renewal" ? (
                  <>
                    <label className="">{t("Effective Date")}:</label>
                    <DatePicker
                      wrapperClassName="datePicker"
                      required
                      selected={endoEffectiveDate}
                      onChange={(date) => {
                        const newDate = setTimeToNoonOne(date);
                        setEndoEffectiveDate(newDate);
                      }}
                      className="standard-form-input"
                    />
                  </>
                ) : null}
                <label className="d-block mt-1">{t("Truck VIN")}:</label>
                {truckData?.truckVIN && truckData?.truckVIN.length !== 17 && (
                  <p className="text-[#8B0000]">VIN must be 17 characters.</p>
                )}
                <input
                  required
                  disabled={vinDecoded}
                  onChange={(e) =>
                    setTruckData({ ...truckData, truckVIN: e.target.value })
                  }
                  value={truckData?.truckVIN}
                  type="text"
                  id="truckVIN"
                  className={`standard-form-input ${
                    vinDecoded ? " bg-gray-100" : ""
                  }`}
                />
                <label className="mt-1">{t("Truck Year")}:</label>
                {truckData?.truckYear && truckData.truckYear.length !== 4 && (
                  <p>Year must be 4 numbers.</p>
                )}
                <input
                  required
                  disabled={vinDecoded}
                  onChange={(e) =>
                    setTruckData({
                      ...truckData,
                      truckYear: e.target.value,
                    })
                  }
                  value={truckData.truckYear}
                  type="number"
                  id="truckYear"
                  className={`standard-form-input ${
                    vinDecoded ? " bg-gray-100" : ""
                  }`}
                />
                {vinDecoded || vinWrongDecoded ? (
                  <>
                    {/* Show this once the VIN and year is decoded */}
                    <label htmlFor="truckMake" className="mt-1">
                      {t("Truck Make")}:
                    </label>
                    <input
                      type="text"
                      required
                      disabled={!vinWrongDecoded}
                      onChange={(e) =>
                        setTruckData({
                          ...truckData,
                          truckMake: e.target.value,
                        })
                      }
                      value={truckData.truckMake}
                      id="truckMake"
                      className="standard-form-input  bg-gray-100"
                    />
                    <label htmlFor="truckModel" className="mt-1">
                      {t("Truck Model")}:
                    </label>
                    <input
                      type="text"
                      required
                      disabled={!vinWrongDecoded}
                      onChange={(e) =>
                        setTruckData({
                          ...truckData,
                          truckModel: e.target.value,
                        })
                      }
                      value={truckData.truckModel}
                      id="truckModel"
                      className="standard-form-input  bg-gray-100"
                    />
                    <label htmlFor="truckClass" className="mt-1">
                      {t("Truck Class")}:
                    </label>
                    <select
                      disabled={!vinWrongDecoded}
                      required
                      onChange={(e) => {
                        let truckWeight;
                        if (e.target.value === "Class I") {
                          truckWeight = "Up to 10,000 lbs";
                        } else if (e.target.value === "Class II") {
                          truckWeight = "10,001 to 19,500 lbs";
                        } else if (e.target.value === "Class III") {
                          truckWeight = "19,501 to 33,000 lbs";
                        } else if (e.target.value === "Class IV") {
                          truckWeight = "33,001+ lbs";
                        } else {
                          alert(
                            "Invalid weight class. Please try again. If the problem persists, please contact support."
                          );
                        }
                        setTruckData({
                          ...truckData,
                          truckClass: e.target.value,
                          truckWeight,
                        });
                      }}
                      value={truckData.truckClass}
                      id="truckClass"
                      className="standard-form-input bg-gray-100"
                    >
                      <option value=""></option>
                      <option value="Class I">
                        {t("Class I (Up to 10,000 lbs)")}
                      </option>
                      <option value="Class II">
                        {t("Class II (10,001 to 19,500 lbs)")}
                      </option>
                      <option value="Class III">
                        {t("Class III (19,501 to 33,000 lbs)")}
                      </option>
                      <option value="Class IV">
                        {t("Class IV (33,001+ lbs)")}
                      </option>
                    </select>
                    <label htmlFor="truckRadius" className="mt-1">
                      {t("Operational Radius")}:
                    </label>
                    <select
                      required
                      onChange={(e) =>
                        setTruckData({
                          ...truckData,
                          truckRadius: e.target.value,
                        })
                      }
                      value={truckData.truckRadius}
                      id="truckRadius"
                      className="standard-form-input"
                    >
                      <option value=""></option>
                      <option value="0-50">0-50</option>
                      <option value="0-100">0-100</option>
                      <option value="0-250">0-250</option>
                      <option value="0-500">0-500</option>
                      <option value="0-800">0-800</option>
                      <option value="0-1000">0-1000</option>
                      <option value="0-1200">0-1200</option>
                      <option value="0-1500">0-1500</option>
                    </select>
                    <label htmlFor="truckACV" className="mt-1">
                      {t("Actual Cash Value")} (USD):
                    </label>
                    <input
                      required
                      onChange={(e) => {
                        let inputValue = e.target.value;
                        inputValue = inputValue.replace(/[^0-9.]/g, "");
                        const parts = inputValue.split(".");
                        if (parts.length === 2 && parts[1] === "") {
                          inputValue += "00";
                        }
                        if (parts.length > 2) {
                          inputValue = parts[0] + "." + parts.slice(1).join("");
                        }
                        if (parts[1] && parts[1].length > 2) {
                          parts[1] = parts[1].slice(0, 2);
                          inputValue = parts.join(".");
                        }
                        setTruckData({ ...truckData, truckACV: inputValue });
                      }}
                      value={truckData.truckACV}
                      type="text"
                      id="truckACV"
                      className="standard-form-input"
                    />
                    <div>
                      <MultiUpload
                        label="Upload a document"
                        property="truckDocuments"
                        formData={truckDocuments}
                        setFormData={setTruckDocuments}
                        storageFile={`files/power-units/${truckData.truckVIN}`}
                        multiple={false}
                        changeNormal={true}
                        showPastingOption={true}
                        fileInButton={true}
                        showInAPopup={true}
                      />
                    </div>
                  </>
                ) : null}
                {vinDecoded ? (
                  <ApprovalButtons
                    onApprove={null}
                    handleAsSubmit={true}
                    onDecline={(e) => {
                      goBack(e);
                    }}
                    titleAccept="Submit"
                    titleDecline="Go Back"
                  />
                ) : (
                  <ApprovalButtons
                    onApprove={(e) =>
                      handleDecodeVIN(
                        e,
                        truckData.truckVIN,
                        truckData.truckYear
                      )
                    }
                    disabledAccept={
                      truckData.truckVIN?.length !== 17 ||
                      truckData.truckYear?.length !== 4
                    }
                    handleAsSubmit={false}
                    onDecline={(e) => {
                      goBack(e);
                    }}
                    titleAccept="Continue"
                    titleDecline="Go Back"
                  />
                )}
              </form>
            </div>
          </section>
        </main>
      )}
    </>
  );
}
