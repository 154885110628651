const getStampFee = (state, taxesAndFees) => {
  if (state === "TX") {
    return taxesAndFees.texasStampFee;
  } else if (state === "CA") {
    return taxesAndFees.californiaStampFee;
  } else if (state === "NM") {
    return taxesAndFees.newMexicoStampFee;
  } else if (state === "AZ") {
    return taxesAndFees.arizonaStampFee;
  }
};

export default getStampFee;
