import Form from "../../Components/generals/Forms/Form";
import useEditProfile from "../../hooks/profile/useEditProfile";

function EditProfile() {
  
  const {
    formSettings,
    loading,
    initialData
  } = useEditProfile()

  const thereIsInitialDataToStart = Boolean(Object.values(initialData)?.length)

  return (
    <>
      {thereIsInitialDataToStart ?
        <Form
          formSettings={formSettings}
          loading={loading}
        /> : null
      }
    </>
  );
}

export default EditProfile;
