import { useContext } from "react"
import { AppContext, initialState } from "../AppContext"
import { SET_POLICIES_PDF } from "../types"

const usePoliciesPdfState = () => {
    const { state, dispatch } = useContext(AppContext)
    const { policiesPdf } = state || initialState

    const setPoliciesPdf = result => {
        dispatch({ type: SET_POLICIES_PDF, payload: result })
    }

    return {
        setPoliciesPdf,
        policiesPdf
    }
}

export default usePoliciesPdfState