import { useContext } from "react"
import { AppContext, initialState } from "../AppContext"
import { SET_WAIVERS_OF_SUBROGATION } from "../types"
import ApiService from "../../services/southern-api/apiService"

const useWaiverOfSubrogation = () => {
    const { state, dispatch } = useContext(AppContext)
    const { waiversOfSubrogation } = state || initialState

    const setWaiversOfSubrogation = async result => {
        dispatch({ type: SET_WAIVERS_OF_SUBROGATION, payload: result })
    }

    return {
        setWaiversOfSubrogation,
        waiversOfSubrogation
    }
}

export default useWaiverOfSubrogation