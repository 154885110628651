import { collectionGroup, getDocs, query, where } from "firebase/firestore";
import processDriverData from "../listeners/processDriverData";
import { db } from "../../../../firebase";

const searchByLicense = async (searchValue) => {
    // Fetch Driver Info
    const q = query(
      collectionGroup(db, "drivers"),
      where("licenseNumber", "==", searchValue)
    );
    const querySnapshot = await getDocs(q);
    const driversMap = processDriverData(querySnapshot);
    return driversMap
  }

export default searchByLicense