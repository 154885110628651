import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

function DragDropImportList({ file, setFile, fileTypes, handleUpload }) {
  const [show, setShow] = useState(true);
  const [loading, setLoading] = useState(false);
  const handleChange = (file) => {
    setFile(file);
    // TODO: SET UP SUPPORT FOR MULTIPLE FILES, SINGLE REQUIRED WORK AROUND, SEE COLT
    handleUpload(file, setLoading);
  };
  return (
    <div>
      {loading ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
          onClick={null}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <div className="my-4 flex justify-center items-center">
          {show && (
            <FileUploader
              handleChange={handleChange}
              name="file"
              types={fileTypes}
              multiple={false}
              label={"Click or drag & drop to upload Import List"}
            />
          )}
          {!show && <p>Uploading file...</p>}
        </div>
      )}
    </div>
  );
}

export default DragDropImportList;
