import React, { useState } from "react";
import { Typography, Box, useMediaQuery } from "@mui/material";
import ApprovalButtons from "../assets/forms/ApprovalButtons";
import Popup from "../generals/Popup";

const ConfirmDeleteModal = ({ open, onClose, onConfirm, fileBeingDeleted }) => {
  
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    await onConfirm();
    setLoading(false);
    onClose();
  };

  const isMobileScreen = useMediaQuery("(max-width: 600px)");

  return (
    <Popup
      isOpen={open}
      onRequestClose={onClose}
      maxWidth={isMobileScreen ? "100%" : "400px"}
      zIndex={1000}
      contentLabel="Confirm Deletion"
    >
      <form onSubmit={handleSubmit}>
        <Box
          sx={{
            maxHeight: "100vh",
            overflowY: "auto",
            width: "100%",
            borderRadius: isMobileScreen ? 0 : 4,
          }}
        >
          <Typography className="mt-2" variant="h6" gutterBottom>
            Confirm Deletion
          </Typography>
          {fileBeingDeleted && (
            <>
              <p className="text-[#8B0000] my-2">File To Be Deleted:</p>
              <p className="my-2">
                <strong>
                  {fileBeingDeleted}
                </strong>
              </p>
            </>
          )}
          <p className="mt-2 mb-4 text-[#8B0000]">
            <strong>Warning</strong>: You are about to <strong>delete data</strong> from the system. This action
            cannot be undone. Are you sure you wish to continue?
          </p>
          <ApprovalButtons
            onApprove={handleSubmit}
            onDecline={onClose}
            forceLoadingAccept={loading}
            titleAccept={"Continue"}
            titleDecline={"Cancel"}
            disabledAccept={false}
            disabledDecline={false}
            handleAsSubmit={true}
          />
        </Box>
      </form>
    </Popup>
  );
};

export default ConfirmDeleteModal;


