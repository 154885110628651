import React from "react";
import {
  AGENT_COMPANY_PROFILE_ROUTE,
  AGENT_MANAGE_USERS_ROUTE,
  AGENT_MANAGE_CLIENTS_ROUTE,
  AGENT_MANAGE_NOTIFICATIONS_ROUTE,
} from "../../../utils/routes";
import { NavDropdown } from "react-bootstrap";
import {
  Business,
  People,
  Notifications,
} from "@mui/icons-material";
import useTranslations from "../../../Context/actions/useTranslations";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";

export default function AgentManageMenu({ userInfo }) {
  const { t } = useTranslations();
  return (
    <>
      {userInfo.userPermissions && (
        <>
        {/* TODO: Translations on this page */}
          <NavDropdown.Item href={AGENT_COMPANY_PROFILE_ROUTE}>
            <Business className="mr-2" /> {t("navbar.company")}
          </NavDropdown.Item>
          <NavDropdown.Item href={AGENT_MANAGE_USERS_ROUTE}>
            <People className="mr-2" /> {t("navbar.users")}
          </NavDropdown.Item>
        </>
      )}
      {userInfo.clientPermissions && (
        <NavDropdown.Item href={AGENT_MANAGE_CLIENTS_ROUTE}>
          <SupervisedUserCircleIcon className="mr-2" /> {t("Clients")}
        </NavDropdown.Item>
      )}
      {userInfo.notificationPermissions && (
        <NavDropdown.Item href={AGENT_MANAGE_NOTIFICATIONS_ROUTE}>
          <Notifications className="mr-2" /> {t("navbar.notifications")}
        </NavDropdown.Item>
      )}
      {/* TODO: This should be conditional only if editing policies are allowed, but i took it out for now
      because I wanted to make sure that they can only see their agencies stuff.
      <NavDropdown.Item href={UNDERWRITING_ENDORSEMENT_ROUTE}>
        <EmojiObjects className="mr-2" /> Endorsements
      </NavDropdown.Item> */}
    </>
  );
}
